import React, { useEffect, useState, useContext, useRef } from "react";
import {
  StyleSheet,
  View,
  Platform,
  Pressable,
  Dimensions,
  SafeAreaView,
} from "react-native";
import { Auth } from "aws-amplify";

import AppContext from "../components/AppContext";
import Blocker from "../components/Blocker";
import Item from "../components/Item";
import { getPin } from "../src/graphql/custom";
import { API, graphqlOperation } from "aws-amplify";
import { findTopActions } from "../utils/TopActions";
import LeftArrow from "../components/LeftArrow";
import { batchPresign } from "../utils/Photo";
import { clog } from "../utils/Log";
import { guestLogin, guestLogout } from "../controllers/AuthController";
import { batchLookupUsers } from "../utils/Cache";
import ConversionBanner from "../components/ConversionBanner";
import { useTheme } from "../theme";
import { adjustPinForRichShareNote } from "../utils/Compatibility";
import PopUp from "../components/PopUp";

const GuestItemDetailsScreen = ({ route, navigation }) => {
  async function getData(Id, callback) {
    clog("asked to get PIN data for ", Id);
    if (!Id) {
      callback();
      return;
    }
    try {
      let data = null;
      const pinData = await API.graphql(graphqlOperation(getPin, { Id: Id }));
      clog("pinData:", pinData);
      data = pinData?.data?.getPin;
      if (data) {
        clog("data is ", data);
        let unknownUserIds = {};
        let mayNeedLookUp = {};
        adjustPinForRichShareNote(data, myContext, mayNeedLookUp);
        let lurl = data.url;
        lurl["comments"] = lurl?.comments?.items;
        lurl["pins"] = [data];
        lurl["topActions"] = findTopActions(lurl, myContext, data?.curator?.Id);
        lurl["justificationAction"] = lurl?.topActions?.[0];
        if (!myContext.users[data.curatorId]) {
          unknownUserIds[data.curatorId] = true;
        }
        if (data?.curator?.avatar) {
          mayNeedLookUp[data.curator.avatar] = true;
        }
        if (lurl?.photo) {
          mayNeedLookUp[lurl.photo] = true;
        }
        if (lurl?.source?.avatar) {
          mayNeedLookUp[lurl.source.avatar] = true;
        }
        lurl?.comments?.forEach((comment) => {
          if (comment?.curator?.avatar) {
            mayNeedLookUp[comment.curator.avatar] = true;
          }
        });
        await batchLookupUsers(
          Object.keys(unknownUserIds),
          myContext.users,
          null
        );
        await batchPresign(
          Object.keys(mayNeedLookUp),
          myContext.presignedUrls,
          null
        );
        callback();
        setUrl(lurl);
      }
    } catch (err) {
      console.log("error fetching pin...", err);
      callback();
    }
  }

  const clickResponder = () => {
    if (Platform.OS == "web") {
      clog("pressed button");
      window.open(url.uri, "_blank");
    } else {
      clog("Pressed button on non-web item");
    }
  };

  const contentModalFunction = (target) => {
    //clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  const findCurrentUser = async () => {
    try {
      let { username, attributes } = await Auth.currentAuthenticatedUser();
      clog("WELCOME User", username, "attributes", attributes);
    } catch (err) {
      console.log("ERROR IN WELCOME USER", err);
    }
  };

  const getDataBasedOnAuthState = async () => {
    try {
      let { username, attributes } = await Auth.currentAuthenticatedUser();
      setLoggedIn(true);
      clog("WELCOME User", username, "attributes", attributes);
      getData(route.params.Id, () => {
        clog("Done fetching data");
      }).catch((err) => {
        console.log("error finding list data: ", err);
      });
    } catch (err) {
      console.log("ERROR IN WELCOME USER", err);
      guestLogin({
        myContext: myContext,
        callback: ({ success, message, error }) => {
          if (success) {
            getData(route.params.Id, () => {
              guestLogout({ myContext: myContext });
            }).catch((err) => {
              console.log("error finding list data: ", err);
            });
          } else {
            clog(message);
          }
        },
      });
    }
  };

  let refererActionId = route?.params?.refererActionId;
  let hostUserId = route?.params?.hostUserId;
  let notFoldedFromParams = route?.params?.notFolded;
  let fromList = route?.params?.fromList;
  let origin = route?.params?.origin;

  const initialState = {};
  const { colors } = useTheme();
  const [url, setUrl] = useState(route?.params?.url);
  const [state, setState] = useState(initialState);
  const [folded, setFolded] = useState(notFoldedFromParams ? false : true);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [showReportPopUp, setShowReportPopUp] = useState(false);
  const [messageReportPopUp, setMessageReportPopUp] = useState("");

  const popUpRef = useRef(null);

  const windowDim = Dimensions.get("window");
  const screenDim = Dimensions.get("screen");
  clog("ORIGINAL WINDOW", windowDim);
  clog("ORIGINAL SCREEN", screenDim);
  const [dimensions, setDimensions] = useState({
    window: windowDim,
    screen: screenDim,
  });

  const myContext = useContext(AppContext);
  let webref = null;

  clog("context", myContext);

  clog("PARAMS", route.params);
  clog("will render url", url);

  findCurrentUser();

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        clog("NEW WINDOW", window);
        clog("NEW SCREEN", screen);
        setDimensions({ window, screen });
      }
    );
    return () => subscription?.remove();
  });

  useEffect(() => {
    if (!url) {
      getDataBasedOnAuthState();
    }
  }, []);

  React.useLayoutEffect(() => {
    if (url) {
      let extraLeft =
        dimensions.window.width >= 556
          ? (dimensions.window.width - 556) / 2
          : 0;
      navigation.setOptions({
        headerLeft: () => {
          return (
            <View
              style={{
                flexDirection: "row",
                height: 66,
                width: 100,
              }}
            >
              {fromList && (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pressable
                    onPress={() => {
                      clog("Pressed left button");
                      navigation.goBack();
                    }}
                  >
                    <View
                      style={{
                        marginLeft: 10 + extraLeft,
                        height: 32,
                        alignItems: "center",
                        flexDirection: "row",
                        backgroundColor: "rgba(0,0,0,0.45)",
                        width: 32,
                        borderRadius: 32,
                        overflow: "hidden",
                      }}
                    >
                      <LeftArrow tintColor={colors.primaryText} />
                    </View>
                  </Pressable>
                </View>
              )}
            </View>
          );
        },
        headerRight: () => {
          return <View></View>;
        },
        headerTitle: "",
      });
    }
  }, [navigation, state, url, dimensions]);

  useEffect(() => {
    if (myContext) {
      setState((prevState) => {
        return {
          ...prevState,
          addFunction: null,
          removeFunction: null,
        };
      });
    }
  }, []);

  useEffect(() => {
    var meta = document.createElement("meta");
    var meta2 = document.createElement("meta");

    meta.name = "title";
    meta.content = !url?.title ? "unknown title" : url?.title;
    document.getElementsByTagName("head")[0].appendChild(meta);

    //document.head.classList.add("bg-black");
    let screenshotUrl = url?.screenshotUrl
      ? url?.screenshotUrl
      : url?.photo
      ? myContext?.presignedUrls[url?.photo]?.uri
        ? myContext?.presignedUrls[url?.photo]?.uri
        : url?.photoUrl
      : url?.photoUrl;
    if (screenshotUrl) {
      meta2.name = "og:image";
      meta2.content = screenshotUrl;
      document.getElementsByTagName("head")[0].appendChild(meta2);
    }
    return () => {
      meta.remove("");
      meta2.remove("");
    };
  }, [url]);

  const windowDimension = Dimensions.get("window");
  const screen = Dimensions.get("screen");

  screenWidth = windowDimension.width;

  let currentTime = Date.now();
  let topActions = url?.topActions;
  let topAction = topActions?.[0]?.action;
  let topTarget = topActions?.[0]?.target;
  let justificationAction = url?.justificationAction?.action;
  let justificationTarget = url?.justificationAction?.target;
  // find the curator closest to the reader
  let curator = justificationTarget?.curator;
  let otherMessages = [];
  topActions?.forEach((a) => {
    let target = a?.target;
    if (
      target &&
      target?.Id != topTarget?.Id &&
      target?.curator?.Id == justificationTarget?.curator?.Id
    ) {
      otherMessages.push({
        type: a?.action?.objectType,
        data: target,
        Id: target.Id,
      });
    }
  });
  //url.otherMessages = otherMessages;

  clog("other messages", otherMessages);
  clog("top target", topTarget);

  console.log("URL", url);
  if (!url) {
    return (
      <SafeAreaView>
        <View>
          <Blocker loading={loading} />
        </View>
      </SafeAreaView>
    );
  } else {
    return (
      <View style={styles.container}>
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          <ConversionBanner
            loggedIn={loggedIn}
            context={myContext}
            sharerId={route?.params?.sharerId}
            objectType={"content"}
            objectId={route?.params?.Id}
            colors={colors}
            myContext={myContext}
          />
          <Pressable onPress={clickResponder}>
            <View>
              <Item
                item={url}
                //itemStyle={itemStyle}
                state={state}
                myContext={myContext}
                screenWidth={screenWidth}
                navigation={navigation}
                origin={origin ? origin : "GuestContentItem"}
                contentModalFunction={contentModalFunction}
                index={0}
                compact={true}
                clickResponder={clickResponder}
                guest={true}
                colors={colors}
                onShowReportPopUp={(message) => {
                  setMessageReportPopUp(message);
                  setShowReportPopUp(true);
                  popUpRef.current?.onAnimatedPopUp();
                }}
              />
            </View>
          </Pressable>

          {showReportPopUp && (
            <PopUp
              ref={popUpRef}
              title={messageReportPopUp}
              onClose={() => setShowReportPopUp(false)}
              colors={colors}
            />
          )}
        </View>
      </View>
    );
  }
};

export default GuestItemDetailsScreen;
let screenWidth = 0;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  superposedDrawer: {
    height: 15,
    width: 21,
    alignSelf: "center",
  },
  noteBackground: {
    padding: 12,
    marginRight: 13,
    marginTop: 5,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  commentBackground: {
    padding: 12,
    marginRight: 13,
    marginTop: 5,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});
