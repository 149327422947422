import { API, graphqlOperation } from "aws-amplify";
import { getUserFollowers, getUsersFollowed } from "../src/graphql/custom";
import { batchPresign } from "../utils/Photo";
import { clog, elog } from "../utils/Log";

export async function getUserListData({
  Id,
  relationship,
  myContext,
  callback,
}) {
  let status = { success: true };
  if (!Id || (relationship !== "Following" && relationship !== "Followers")) {
    status.success = false;
    if (!Id) {
      status["message"] = "Need to specify Id";
    } else if (relationship !== "Following" && relationship !== "Followers") {
      status["message"] = "Relation should be Following or Followers";
    }
    if (callback) {
      callback(status);
    }
    return status;
  }
  try {
    const usersData = await API.graphql(
      graphqlOperation(
        relationship === "Followers" ? getUserFollowers : getUsersFollowed,
        { Id: Id }
      )
    );
    if (usersData.data.getUser) {
      const data = usersData.data.getUser;
      clog("overall data", data);
      let users = [];
      let originalArray = data[
        relationship === "Following" ? "actionsByUser" : "actions"
      ].items.sort((a, b) =>
        a[relationship === "Following" ? "user" : "actor"].createdAt <
        b[relationship === "Following" ? "user" : "actor"].createdAt
          ? -1
          : 1
      );
      let seen = {};
      clog("original array", originalArray);
      originalArray.forEach((element) => {
        clog("element", element);
        let oneUser = element[relationship === "Following" ? "user" : "actor"];
        for (let index in oneUser) {
          if (index.startsWith("num") && oneUser[index] == null) {
            oneUser[index] = 0;
          }
        }
        // This is the relationship of current user with the retrieved user. The relationship Id in the element
        // is irrelevant if the relationship is Followers
        // NOTE(alpha): Accesses relationship Id, How is that information used?
        if (myContext?.actionsByUser["User"]?.["Follow"]?.[oneUser.Id]) {
          oneUser["relationshipId"] = Object.keys(
            myContext?.actionsByUser["User"]?.["Follow"]?.[oneUser.Id]
          )[0];
        }
        clog("ONE USER", oneUser);
        if (!seen[oneUser.Id] && !seen[oneUser.handle]) {
          users.push(oneUser);
          seen[oneUser.Id] = true;
          seen[oneUser.handle] = true;
        } else {
          console.log(
            "ERROR: user showed up multiple times",
            oneUser.Id,
            Id,
            oneUser.handle,
            oneUser.createdAt
          );
        }
      });

      let mayNeedLookUp = {};
      users.forEach((element) => {
        //clog("consider", element);
        if (element.avatar) {
          mayNeedLookUp[element.avatar] = true;
        }
      });
      await batchPresign(
        Object.keys(mayNeedLookUp),
        myContext.presignedUrls,
        null
      );

      let sortedUsers = [];
      users
        .sort((a, b) => (a.handle > b.handle ? 1 : -1))
        .forEach((element) => {
          sortedUsers.push(element);
        });

      clog("users with", relationship, sortedUsers);
      status["total"] =
        data[relationship === "Followers" ? "numFollow" : "numUserFollow"];
      status["users"] = sortedUsers;
    } else {
      status.success = false;
      status["message"] = "No data found for user";
    }
  } catch (err) {
    status.success = false;
    status["message"] = "error fetching user data";
    console.log("error fetching users...", err);
    elog(myContext.Id, "user list", "fetching user data", err.message);
  }
  if (callback) {
    callback(status);
  }
  return status;
}
