import { React, useEffect } from "react";
import { View, Animated, Dimensions, Easing, StyleSheet } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { villageColors } from "../utils/SharedStyles";

const Skeleton = ({ style }) => {
  const { width } = Dimensions.get("window");

  const AnimatedLG = Animated.createAnimatedComponent(LinearGradient);
  const animatedValue = new Animated.Value(0);
  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [-width, width],
  });

  const colors = [
    "rgba(255, 255, 255, 0)",
    villageColors.White50,
    villageColors.White50,
    "rgba(255, 255, 255, 0)",
  ];

  useEffect(() => {
    Animated.loop(
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear.inOut,
        useNativeDriver: true,
      })
    ).start();
  }, []);

  return (
    <View style={style}>
      <AnimatedLG
        colors={colors}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0 }}
        style={{
          ...StyleSheet.absoluteFill,
          transform: [{ translateX: translateX }],
        }}
      />
    </View>
  );
};

export default Skeleton;
