import { API, graphqlOperation } from "aws-amplify";

import { listTopics } from "../src/graphql/custom";

import { batchPresign } from "../utils/Photo";
import { clog } from "../utils/Log";

export async function getTopics({ myContext, callback }) {
  let status = { success: true };
  // TODO(alpha); let caller ask to bypass cache
  if (myContext.topics) {
    status["message"] = "reused from cache";
    status["topicData"] = myContext.topics;
    await getAvatarsIfNeeded(myContext);
  } else {
    clog("will get topic data");
    try {
      let start = performance.now();
      const topicData = await API.graphql(
        graphqlOperation(listTopics, { numRequested: 1000 })
      );
      let fetchDone = performance.now();
      console.log("TIME: topic data fetch", (fetchDone - start) / 1000);
      clog("topicData:", topicData);

      myContext.topics = {};
      topicData.data.listTopics.items.forEach((topic) => {
        ////clog("topic handling now ", topic);
        for (let index in topic) {
          if (index.startsWith("num") && topic[index] == null) {
            topic[index] = 0;
          }
        }
        myContext.topics[topic.Id] = topic;
        ////clog("after adding topic option is", topicOptions);
      });
      status["topicData"] = myContext.topics;
      getAvatars(myContext);
    } catch (err) {
      status.success = false;
      status["message"] = "failed to fetch topic data";
      status["error"] = err;
      console.log("error fetching topic data...", err);
    }
  }
  if (callback) {
    callback(status);
  }
  return status;
}

async function getAvatars(myContext) {
  if (!myContext.presignedUrls) {
    myContext["presignedUrls"] = {};
  }
  let mayNeedLookUp = {};
  Object.keys(myContext.topics).forEach((t) => {
    let topic = myContext.topics[t];
    if (topic?.depth == 0) {
      if (topic?.avatar) {
        mayNeedLookUp[topic.avatar] = true;
      }
    }
  });
  await batchPresign(Object.keys(mayNeedLookUp), myContext.presignedUrls, null);
}

async function getAvatarsIfNeeded(myContext) {
  let needAvatars = false;
  Object.keys(myContext.topics).forEach((topic) => {
    if (!myContext?.presignedUrls?.[topic]) {
      needAvatars = true;
    }
  });
  if (needAvatars) {
    await getAvatars(myContext);
  }
}
