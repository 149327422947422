import React, { useEffect, useState, useContext, useRef } from "react";
import { View, Pressable, Text } from "react-native";
import { API, graphqlOperation } from "aws-amplify";
import { listFeedbacks } from "../src/graphql/custom";
import AppContext from "../components/AppContext";
import { initialUser } from "../utils/InitialValues";
import FeedbackList from "../components/FeedbackList";
import LeftArrow from "../components/LeftArrow";
import { timeStamp } from "../utils/TimeStamp";
import { clog } from "../utils/Log";
import { useTheme } from "../theme";

const FeedbackListScreen = ({ route, navigation }) => {
  async function getData() {
    try {
      const feedbackData = await API.graphql(
        graphqlOperation(listFeedbacks, {})
      );
      console.log("feedback data", feedbackData);
      if (feedbackData.data.listFeedbacks.items) {
        let feedbacks = feedbackData.data.listFeedbacks.items;
        let sortedFeedbacks = [];
        feedbacks
          .sort((a, b) => (a.creationTS > b.creationTS ? -1 : 1))
          .forEach((element) => {
            sortedFeedbacks.push(element);
          });

        setState((currentState) => {
          return {
            ...currentState,
            total: sortedFeedbacks.length,
            feedbacks: sortedFeedbacks,
            fetchTimeStamp: timeStamp(),
            version: myContext.version,
          };
        });
      }
    } catch (err) {
      console.log("error fetching feedbacks...", err);
    }
  }

  const { colors } = useTheme();

  const initialState = {
    feedbacks: [],
    fetchTimeStamp: 0,
    version: 0,
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  React.useLayoutEffect(() => {
    let headerTitleValue = "Feedbacks";
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: headerTitleValue,
    });
  }, [navigation, state]);

  async function dataRefresh() {
    clog("CLICKED ON TAB");
    // do something
    if (
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
      getData().catch((err) => {
        console.log("error getting feedback list ", err);
      });
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
    }
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: colors.background,
      }}
    >
      <View
        style={{ flex: 1, width: "100%", maxWidth: 756, paddingHorizontal: 20 }}
      >
        {myContext?.label?.includes("admin") && (
          <FeedbackList
            state={state}
            myContext={myContext}
            navigation={navigation}
            refererActionId={state.refererActionId}
            hostUserId={state.hostUserId}
            colors={colors}
          />
        )}
      </View>
    </View>
  );
};

export default FeedbackListScreen;
