import React, { useRef, forwardRef, useImperativeHandle } from "react";
import {
  Animated,
  Easing,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { BlurView } from "expo-blur";
import puffTLDR from "../assets/puff-tldr.png";

const PopUp = (props, ref) => {
  const { colors, title, onClose } = props;

  const popUpTransformRef = useRef(new Animated.Value(0)).current;

  const onAnimatedPopUp = () => {
    Animated.timing(popUpTransformRef, {
      toValue: -120,
      duration: 500,
      easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
      useNativeDriver: true,
    }).start();

    setTimeout(() => {
      popUpTransformRef.setValue(0);
      onClose();
    }, 3000);
  };

  useImperativeHandle(
    ref,
    () => ({
      onAnimatedPopUp,
    }),
    []
  );

  return (
    <Animated.View
      style={[
        styles.animatedContainer,
        { transform: [{ translateY: popUpTransformRef }] },
      ]}
    >
      <BlurView intensity={10}>
        <Pressable
          onPress={() => {
            onClose();
            popUpTransformRef.setValue(0);
          }}
          style={[
            styles.container,
            { backgroundColor: colors.topicPopUpBackground },
          ]}
        >
          <Image source={puffTLDR} style={styles.image} />
          <View style={{ marginLeft: 6, flex: 1 }}>
            <Text
              style={[
                styles.title,
                {
                  color: colors.primaryButtonText,
                },
              ]}
            >
              {title}
            </Text>
          </View>
        </Pressable>
      </BlurView>
    </Animated.View>
  );
};

export default forwardRef(PopUp);

const styles = StyleSheet.create({
  animatedContainer: {
    position: "absolute",
    bottom: -35,
    width: "100%",
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 16,
  },
  image: {
    height: 24,
    width: 24,
  },
  title: {
    fontWeight: "700",
    fontSize: 15,
    lineHeight: 22,
  },
});
