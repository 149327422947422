import { performAction, addActionByUser } from "../utils/Action";
import { clog, elog } from "../utils/Log";

export async function addReportForObject({
  objectId,
  objectType,
  note,
  myContext,
  callback,
}) {
  clog("got asked to report", note, "on", objectId, "of type", objectType);
  let newObject = null;
  let payloads = [];
  try {
    let promises = [];
    payloads = [];

    let localCallback = (
      success,
      newRelationshipId,
      createdAt,
      responses,
      creationTS
    ) => {
      if (success) {
        let newAction = null;
        responses.forEach((response) => {
          clog("UPATE RESPONSE:", response);
          let createAction = response.data.createAction;
          if (createAction) {
            newAction = createAction;
          }
        });
        if (newObject) {
          newObject["actions"] = { items: [newAction] };
        }
        addActionByUser(
          myContext.actionsByUser,
          objectType,
          "Report",
          objectId,
          newRelationshipId,
          newObject,
          null,
          null,
          createdAt
        );
        myContext.version++;
        if (callback) {
          callback({ success: true });
        }
      } else {
        if (callback) {
          callback({
            success: false,
            message: "failed to report " + objectType,
          });
        }
      }
    };

    performAction({
      objectType: objectType,
      operation: "Report",
      objectId: objectId,
      actorId: myContext.Id,
      relationshipId: null,
      promises: promises,
      payloads: payloads,
      callback: localCallback,
      actionOnly: true,
      seq: null,
      note: note,
    });
  } catch (err) {
    console.log("ERROR", err);
    console.log("error reporting on object...", err);
    elog(
      myContext.Id,
      "create report",
      "creating report",
      err.message,
      payloads
    );
  }
}
