import React from "react";
import { View, Text, Image } from "react-native";
import link from "../assets/link.png";
import { clog } from "../utils/Log";

const SourceIcon = ({ url, icon, style, textstyle, showText }) => {
  clog("will render icon for", url, icon);
  if (!url) {
    clog("no url");
    return <View></View>;
  }
  const hostPattern = /http.*\/\/(((www|vm|m)\.)?([^\/]*))/;
  let matches = url?.match(hostPattern);
  let host = "";
  if (matches && matches.length >= 5) {
    clog("matches", matches);
    host = matches[4];
    clog("host is(" + host + ")");
  } else {
    clog("no match!!!");
  }
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon && (
        <Image source={{ uri: icon, cache: "force-cache" }} style={style} />
      )}
      {!icon && showText && url && host != "" && (
        <View style={style}>
          {<Text style={textstyle}>{host.charAt(0).toUpperCase()}</Text>}
        </View>
      )}
      {!icon && !showText && (
        <Image
          source={link}
          style={[
            style,
            {
              height: 13.5,
              width: 13.5,
            },
          ]}
        />
      )}
    </View>
  );
};

export default SourceIcon;
