import { StyleSheet } from "react-native";
import { DarkTheme } from "@react-navigation/native";

// rgba(0,0,0,0.45) Background for header buttons and empty image placeholder in Content list screen and guest content list screen
//                  Background for header buttons and open in new tab button in item details and guest item details screens
//                  Background for header buttons in guest user details screen
// rgba(0,0,0,.5)   Activity Indicator wrapper
// rgb(0, 122, 255) Button title color in Share In screen
// rgb(0, 123, 255) Cancel button color for alerts in web
// rgba(22, 22, 22, 0.5) Welcome text color, Placeholder text color and border color of text input box in sign up screen
// rgba(22, 22, 22, 0.75) Text color of call to action header in Sign up screen
// rgb(23, 23, 23)  Shadow color for informational alerts in feed screen
// rgb(32,30,35) background color for (pin) items in collection screen and guest collection screen
// rgb(37,36,40) background color for bottom area containing create pin button in content list screen
// rgb(40,40,40) background color for share in screen
// rgb(46,46,46) background color for text input field in share in screen
// rgba(52,52,52,0.5) Multi-button alert overlay background color (utility used from different screens)
// rgba(52, 52, 52, 0.8) background color for Comment creation modal
// rgb(55, 52, 61) Compact Item Url Metadata background in Content List Screen and Guest Content List Screen
//                 Compact Item Url Metadata background in Create Pin Screen
// rgb(55, 51, 61) Disabled button color in sign up screen
// rgb(59, 55, 65) Used to create a thought bubble around reaction emojis in PinCurator utility
// rgba(60, 60, 60, 0.8) background color for Share In screen??
//                       background color for alert in share in screen
// rgba(60, 60, 67, 0.36) vertical and horizontal separator in Share In screen
// rgba(122,60,227, 0.3) disabled button color for share in screen
// rgb(129, 199, 221)    Text color of mentions in share note and comments
// rgb(173, 173, 173)    Text color of elapsed time for each action in notification screen
// rgb (203,177,255)     Title color of (url) items
//                       Text color of "Start a new conversation" in comment screen
//                       Text color of "Select all interests" in topic selection overlay in feed screen
//                       Text color of "resend text code" in confirm sign up screen
//                       Text color of url title in url metadata for individual pins in content list and guest content list screens
//                       Text color of title, tintColor of plus icon and color of new collection button in create pin screen
//                       Dashed border color of image placeholder in image chooser utility used to upload profile image
// rgb(205, 205, 205)    Placeholder text color in content list creation screen
// rgba(242, 242, 242, 0.8) Notification background in Share In screen
//                          Border color for "Clear" button in filter topic chooser modal
// rgb(251, 197, 59)         Village logo tint color in Welcome Screen
// rgba(255, 255, 255, 0.25) Filter vertical separator in feed screen
//                           Border Color for "join conversation" and url metadata in Comment screen
// rgba(255, 255, 255, 0.3) placeholder text color in comment creation modal
// rgba(255, 255, 255, 0.45) Text color for "join conversation" in Comment screen,
//                           Text color and Icon color for inactive feed (my village vs discover)
//                           Placeholder text and input area border color for collection update screen
//                           Placeholder text and input area border color and Text color for disabled "Create" button in Create Pin Screen
//                           Input area border color for profile update screen
//                           Disabled button text color in Sign Up Screen
// rgba(255, 255, 255, 0.6)  Placeholder text color in share in screen
// rgba(255, 255, 255, 0.75) Text color for Item footer commenter list
//                           Text color for user handle in Profile Card
//                           Text color for url source name in Comment screen
//                           Text color for calls to action and placeholder text color in profile update screen
//                           Text color for call to action in profile screen
// rgba(256, 256, 256, 0.3)  Title of disabled button in share in screen

export const villageColors = {
  White: "rgb(255, 255, 255)",
  Accent: "rgb(255, 190, 24)",
  Stone: "rgb(243, 241, 242)",
  Canary: "rgb(255, 198, 53)",
  Raven: "rgb(22, 22, 22)",
  Raven90: "rgba(22, 22, 22, 0.9)",
  Raven75: "rgba(22, 22, 22, 0.75)",
  Raven50: "rgba(22, 22, 22, 0.5)",
  Raven25: "rgba(22, 22, 22, 0.25)",
  Raven15: "rgba(22, 22, 22, 0.15)",
  RavenMedium: "rgb(32, 33, 35)",
  RavenLight: "rgb(49, 49, 51)",
  White90: "rgba(255, 255, 255, 0.9)",
  White75: "rgba(255, 255, 255, 0.75)",
  White50: "rgba(255, 255, 255, 0.5)",
  White25: "rgba(255, 255, 255, 0.25)",
  White15: "rgba(255, 255, 255, 0.15)",
  Link: "rgb(66, 142, 255)",
  Error: "rgb(223, 74, 42)",
  Flicker: "rgb(255, 122, 92)",
  Transparent: "rgba(0,0,0,0)",

  Charcol: "rgb(32, 33, 35)",
  Cobblestone: "rgb(49, 49, 51)",
  CommonBackground: "rgb(15, 17, 17)", // Update app.json if this value is changed
  ContentBackground: "rgb(33, 30, 35)",
  CommentBackground: "rgb(70, 68, 72)",
  InputBackground: "rgb(45, 43, 50)",
  SecondaryBackground: "rgb(28, 28, 30)",
  ProfileMetricBackground: "rgb(45, 43, 50)", // same as input background color
  Profile: "rgb(15, 17, 17)", // same as common background color
  ProfileShort: "rgb(27, 29, 29)",
  Text: "rgb(255, 255, 255)",
  TextLight: "rgb(22, 22, 22)",
  Button: "rgb(122, 60, 227)",
  CancelButton: "rgb(0, 123, 255)",
  ConfirmButton: "rgb(221, 107, 85)",

  lightOne: "rgb(248, 243, 233)",
  lightTwo: "rgb(255, 238, 171)",
  darkOne: "rgb(119, 56, 56)",
  darkTwo: "rgb(27, 36, 66)",
  darkThree: "rgb(30, 50, 51)",
  darkFour: "rgb(53, 43, 40)",
  darkFive: "rgb(40, 35, 41)",
  Yellow: "yellow",
};

export const lightInputBackgroundColor = villageColors.Canary;

export const villageFontSizes = {
  TitleBold: { fontSize: 20, fontWeight: "700" },
  TitleRegular: { fontSize: 20, fontWeight: "400" },
  BodyBold: { fontSize: 15, fontWeight: "700" },
  BodyRegular: { fontSize: 15, fontWeight: "400" },
  SmallBold: { fontSize: 12, fontWeight: "700" },
  SmallRegular: { fontSize: 12, fontWeight: "400" },
};

export const titleFontSize = 20;
export const titleFontWeight = "700";
export const narrowScreenWidth = 556;

export const chosenTheme = {
  ...DarkTheme,
  dark: true,
  colors: {
    ...DarkTheme.colors,
  },
};

export const alternateTheme = {
  dark: false,
  colors: {
    primary: "blue",
    background: "green",
    card: "orange",
    text: "cyan",
    border: "purple",
    notification: "rgb(255, 69, 58)",
  },
};

export const styleMap = {
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    alignSelf: "center",
    width: "100%",
  },
  list: {
    width: "100%",
  },
  button: {
    alignItems: "center",
    padding: 16,
    backgroundColor: villageColors.Button,
    borderRadius: 16,
    color: "white",
  },
  coloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    fontSize: 20,
    color: "white",
    backgroundColor: villageColors.Button,
    padding: 10,
    borderRadius: 8,
  },
  disabledButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    fontSize: 20,
    color: "white",
    backgroundColor: villageColors.CommonBackground,
    padding: 10,
    borderRadius: 8,
  },
  uncoloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    fontSize: 20,
    color: "black",
    backgroundColor: "white",
    padding: 10,
    borderRadius: 8,
  },
  title: {
    fontSize: 18,
    color: villageColors.Text,
    fontWeight: "700",
    lineHeight: 24,
  },
  body: {
    fontSize: 10,
    color: villageColors.Text,
  },
  bio: {
    fontSize: 10,
    color: villageColors.Text,
  },
  comment: { fontSize: 10, color: villageColors.Text },
  iconWrapper: {
    height: 26,
    width: 26,
    borderRadius: 13,
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: villageColors.Text,
    borderColor: villageColors.Text,
    overflow: "hidden",
  },
  icon: {
    fontSize: 20,
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    color: villageColors.InputBackground,
  },
  /*textInput: {
    height: 59,
    fontSize: 15,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 8,
    color: "white",
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.45)",
  },*/
  textLabel: {
    fontSize: 12,
    fontWeight: "700",
    marginBottom: 8,
    marginTop: 8,
  },
  textInput: {
    fontSize: 15,
    height: 48,
    paddingLeft: 12,
    borderRadius: 8,
    borderWidth: 1,
    flex: 1,
  },
  textInputBox: {
    height: 48,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  placeholder: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 50,
    fontSize: 25,
    paddingLeft: 20,
    paddingRight: 20,
  },
  fact: {
    fontSize: 14,
    paddingRight: 10,
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  coloredFact: {
    fontSize: 14,
    fontWeight: "700",
    paddingRight: 5,
    color: "rgb(203,177,255)",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  screenshot: {
    width: "100%",
    height: 180,
    resizeMode: "cover",
  },
  centeredText: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
  },
  card: {
    backgroundColor: villageColors.CommonBackground,
    marginVertical: 4,
    marginHorizontal: 8,
    borderRadius: 16,
  },
};

export const sharedStyles = StyleSheet.create(styleMap);
