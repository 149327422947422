import { API, graphqlOperation } from "aws-amplify";
import {
  addVisit,
  addVisitToArchive,
  updateUrlCounts,
  updatePinCounts,
  updateUserCounts,
} from "../src/graphql/custom";
import { generateUniqueId } from "../utils/Id";
import { addIds } from "../utils/IdList";

import { clog } from "../utils/Log";
import { timeStamp } from "./TimeStamp";

export function handleVisible(type, key, item, myContext, origin) {
  return;
  const now = Date.now();
  if (!myContext.views) {
    myContext["views"] = {};
  }
  if (!myContext.views[type]) {
    myContext.views[type] = {};
  }
  if (!myContext.views[type][key]) {
    // get type specific attributes
    let details = {};
    if (type == "Url") {
      details["sourceId"] = item?.sourceId;
      details["topicIds"] = item?.topicIds;
    }
    myContext.views[type][key] = { details: details, visits: [] };
  }
  let payload = { start: now };
  myContext.views[type][key].visits.push(payload);
}

export function handleInvisible(type, key, item, myContext, origin) {
  return;
  const now = Date.now();
  // update the list of currently visible items
  let list = myContext?.views?.[type]?.[key]?.visits;
  if (list?.length) {
    // find the last item and update
    let last = list[list.length - 1];
    clog(type, key, "LAST VISIT", last, "history", list);
    if (last.start && !last.finish) {
      last["finish"] = now;
      // Push down all unexpanded cards
      if (!item?.expanded) {
        last.finish = last.start + 1;
      }
      console.log(
        "FINISHED CHANGE",
        type,
        last.finish - last.start,
        last.start,
        last.finish,
        item?.uri
      );
      if (type == "Url") {
        reportUrlVisit(
          item,
          myContext,
          last.finish - last.start,
          Math.floor(last.start / 1000),
          null,
          origin
        );
      }
    }
  }
}

export async function trackViews(type, changes, myContext, origin) {
  if (!myContext) {
    return;
  }
  const now = Date.now();
  clog("CHANGES", changes);
  changes?.forEach((change) => {
    if (change.isViewable) {
      // add this item to list of currently visible items
      if (!myContext.views) {
        myContext["views"] = {};
      }
      if (!myContext.views[type]) {
        myContext.views[type] = {};
      }
      if (!myContext.views[type][change.key]) {
        // get type specific attributes
        let details = {};
        if (type == "Url") {
          details["sourceId"] = change?.item?.sourceId;
          details["topicIds"] = change?.item?.topicIds;
        }
        myContext.views[type][change.key] = { details: details, visits: [] };
        if (origin == "recommendation") {
          reportUrlVisit(
            change.item,
            myContext,
            5,
            Math.floor(now / 1000),
            null,
            origin
          );
        }
      }
      let payload = { start: now };
      myContext.views[type][change.key].visits.push(payload);
      handleVisible(type, change.key, change?.item, myContext, origin);
    } else {
      // update the list of currently visible items
      let list = myContext?.views?.[type]?.[change.key]?.visits;
      if (list?.length) {
        // find the last item and update
        let last = list[list.length - 1];
        if (last.start && !last.finish) {
          last["finish"] = now;
          clog("FINISHED CHANGE", type, change);
          if (type == "Url") {
            clog("REPORTING VISIT", change.item.uri, last.finish - last.start);
            reportUrlVisit(
              change.item,
              myContext,
              last.finish - last.start,
              Math.floor(last.start / 1000),
              null,
              origin
            );
          }
        }
      }
      handleInvisible(type, change.key, change?.item, myContext, origin);
    }
  });
}

// TODO(alpha): Add support for filtering based on type and attributes
export function summarizeViews(myContext) {
  let summary = {};
  if (myContext.views) {
    Object.keys(myContext.views).forEach((type) => {
      let items = myContext.views[type];
      if (items) {
        Object.keys(items).forEach((key) => {
          let details = items[key]?.details;
          let visits = items[key]?.visits;
          let total = 0;
          let count = 0;
          let max = 0;
          let average = 0;
          let numLongVisit = 0;
          let numTotalVisit = 0;
          visits?.forEach((visit) => {
            if (visit.start && visit.finish) {
              let elapsed = visit.finish - visit.start;
              total += elapsed;
              if (elapsed > max) {
                max = elapsed;
              }
              if (elapsed >= myContext.config.longVisitThreshold) {
                numLongVisit++;
              }
              numTotalVisit++;
              count++;
            }
          });
          if (count) {
            average = total / count;
          }
          // now iterate over attributes to update summary
          Object.keys(details).forEach((attribute) => {
            let needToSplit = ["topicIds"].includes(attribute);
            let values = [];
            if (needToSplit) {
              let parts = details[attribute].split(",");
              parts?.forEach((part) => {
                let tuples = part?.split(":");
                if (tuples.length) {
                  if (myContext?.topics?.[tuples[0]]?.name) {
                    values.push(myContext.topics[tuples[0]].name);
                  } else {
                    values.push(tuples[0]);
                  }
                }
              });
            } else {
              values.push(details[attribute]);
            }
            values.forEach((value) => {
              if (!summary[value]) {
                summary[value] = {
                  total: 0,
                  count: 0,
                  max: 0,
                  average: 0,
                  numLongVisit: 0,
                  numTotalVisit: 0,
                  conversion: 0,
                };
              }
              summary[value].total += total;
              summary[value].count += count;
              summary[value].numLongVisit += numLongVisit;
              summary[value].numTotalVisit += numTotalVisit;
              if (summary[value].max < max) {
                summary[value].max = max;
              }
              if (count) {
                summary[value].average =
                  summary[value].total / summary[value].count;
              }
              if (summary[value].numTotalVisit) {
                summary[value].conversion =
                  summary[value].numLongVisit / summary[value].numTotalVisit;
              }
            });
          });
          clog(details, count, max, total, average, visits);
        });
      }
    });
    Object.keys(summary)
      ?.sort((a, b) => (summary[a].conversion < summary[b].conversion ? 1 : -1))
      .forEach((key) => {
        console.log(key, summary[key]);
      });
  }
  return summary;
}

function findPin(url) {
  let pin = null;
  if (
    url?.justificationAction?.action?.objectType == "Pin" &&
    url?.justificationAction?.action?.operation == "Create"
  ) {
    pin = url?.justificationAction?.target;
  }
  // March down top actions to find the pin
  if (!pin) {
    for (let i = 0; i < url?.topActions?.length; i++) {
      let ta = url.topActions[i];
      if (
        ta?.action?.objectType == "Pin" &&
        ta?.action?.operation == "Create"
      ) {
        pin = ta?.target;
        break;
      }
    }
  }
  return pin;
}

export async function reportUrlVisit(
  url,
  myContext,
  duration,
  visitTS,
  type,
  origin
) {
  let pin = findPin(url);
  clog("Spent", duration, "at", url?.Id, "and pin", pin?.Id, "url", url);
  if (url?.Id && pin?.Id) {
    let visitId = generateUniqueId();
    let promises = [];
    promises.push(
      API.graphql(
        graphqlOperation(addVisitToArchive, {
          Id: visitId,
          objectId: url?.Id,
          objectType: "Url",
          actorId: myContext.Id,
          type: type,
          pinId: pin?.Id,
          urlId: url?.Id,
          visitTS: visitTS,
          duration: duration,
          origin: origin,
        })
      )
    );
    let newUrlVisit = type == null && !myContext?.engagements?.visits[url?.Id];
    let newUrlLongVisit =
      type == null &&
      !myContext?.engagements?.longVisits[url?.Id] &&
      duration >= myContext.config.longVisitThreshold;
    let newUrlView = type == "View" && !myContext?.engagements?.views[url?.Id];
    let newUrlLongView =
      type == "View" &&
      !myContext?.engagements?.longViews[url?.Id] &&
      duration >= myContext.config.longViewThreshold;
    let newPinVisit = type == null && !myContext?.engagements?.visits[pin?.Id];
    let newPinLongVisit =
      type == null &&
      !myContext?.engagements?.longVisits[pin?.Id] &&
      duration >= myContext.config.longVisitThreshold;
    let newPinView = type == "View" && !myContext?.engagements?.views[pin?.Id];
    let newPinLongView =
      type == "View" &&
      !myContext?.engagements?.longViews[pin?.Id] &&
      duration >= myContext.config.longViewThreshold;

    if (
      newUrlVisit ||
      newUrlLongVisit ||
      newPinVisit ||
      newPinLongVisit ||
      newUrlView ||
      newUrlLongView ||
      newPinView ||
      newPinLongView
    ) {
      promises.push(
        API.graphql(
          graphqlOperation(addVisit, {
            Id: visitId,
            objectId: url?.Id,
            objectType: "Url",
            actorId: myContext.Id,
            type: type,
            pinId: pin?.Id,
            urlId: url?.Id,
            visitTS: visitTS,
            duration: duration,
            origin: origin,
          })
        )
      );
    }
    if (newUrlVisit) {
      myContext.engagements.visits[url?.Id] = visitTS;
      url["numTotalVisit"] = url?.numTotalVisit ? url?.numTotalVisit + 1 : 1;
    }
    if (newUrlLongVisit) {
      myContext.engagements.longVisits[url?.Id] = visitTS;
      url["numLongVisit"] = url?.numLongVisit ? url?.numLongVisit + 1 : 1;
      if (url?.topicIds) {
        myContext["visitedTopicIds"] = addIds(
          myContext.visitedTopicIds,
          url.topicIds.split(",")
        );
      }
    }
    if (
      (newUrlVisit || newUrlLongVisit) &&
      url?.numLongVisit > url?.numTotalVisit
    ) {
      console.log(
        "new visit",
        newUrlVisit,
        url?.numTotalVisit,
        myContext.engagements.visits[url?.Id],
        "new long visit",
        newUrlLongVisit,
        url?.numLongVisit,
        myContext.engagements.longVisits[url?.Id],
        url?.uri
      );
    }
    if (newUrlView) {
      myContext.engagements.views[url?.Id] = visitTS;
      url["numTotalView"] = url?.numTotalView ? url?.numTotalView + 1 : 1;
    }
    if (newUrlLongView) {
      myContext.engagements.longViews[url?.Id] = visitTS;
      url["numLongView"] = url?.numLongView ? url?.numLongView + 1 : 1;
      myContext["viewedTopicIds"] = addIds(
        myContext.viewedTopicIds,
        url.topicIds.split(",")
      );
    }
    if (newPinVisit) {
      myContext.engagements.visits[pin?.Id] = visitTS;
      pin["numTotalVisit"] = pin?.numTotalVisit ? pin?.numTotalVisit + 1 : 1;
    }
    if (newPinLongVisit) {
      myContext.engagements.longVisits[pin?.Id] = visitTS;
      pin["numLongVisit"] = pin?.numLongVisit ? pin?.numLongVisit + 1 : 1;
    }
    if (newPinView) {
      myContext.engagements.views[pin?.Id] = visitTS;
      pin["numTotalView"] = pin?.numTotalView ? pin?.numTotalView + 1 : 1;
    }
    if (newPinLongView) {
      myContext.engagements.longViews[pin?.Id] = visitTS;
      pin["numLongView"] = pin?.numLongView ? pin?.numLongView + 1 : 1;
    }

    if (
      !myContext.label?.match("test") &&
      !myContext.label?.match("insider") &&
      !myContext.handle?.match("test123")
    ) {
      if (newUrlVisit || newUrlLongVisit || newUrlView || newUrlLongView) {
        let urlPayload = { Id: url?.Id };
        if (newUrlVisit) {
          urlPayload["numTotalVisit"] = url.numTotalVisit;
        }
        if (newUrlLongVisit) {
          urlPayload["numLongVisit"] = url.numLongVisit;
        }
        if (newUrlView) {
          urlPayload["numTotalView"] = url.numTotalView;
        }
        if (newUrlLongView) {
          urlPayload["numLongView"] = url.numLongView;
        }
        promises.push(
          API.graphql(graphqlOperation(updateUrlCounts, urlPayload))
        );
      }
      if (newPinVisit || newPinLongVisit || newPinView || newPinLongView) {
        let pinPayload = { Id: pin?.Id };
        if (newPinVisit) {
          pinPayload["numTotalVisit"] = pin.numTotalVisit;
        }
        if (newPinLongVisit) {
          pinPayload["numLongVisit"] = pin.numLongVisit;
        }
        if (newPinView) {
          pinPayload["numTotalView"] = pin.numTotalView;
        }
        if (newPinLongView) {
          pinPayload["numLongView"] = pin.numLongView;
        }
        promises.push(
          API.graphql(graphqlOperation(updatePinCounts, pinPayload))
        );
      }
    }
    if (newUrlLongVisit || newUrlLongView) {
      promises.push(
        API.graphql(
          graphqlOperation(updateUserCounts, {
            Id: myContext.Id,
            visitedTopicIds: myContext.visitedTopicIds,
            viewedTopicIds: myContext.viewedTopicIds,
          })
        )
      );
    }
    const responses = await Promise.all(promises);
    clog("RESPONSES FROM REPORTING", responses);
  }
}

export function evaluateExperience(myContext) {
  let cutoff =
    (myContext.lastRecommendationCreationTime
      ? myContext.lastRecommendationCreationTime
      : timeStamp()) - myContext.config.qualityAssessmentWindow;
  let totalVisit = 0;
  let longVisit = 0;
  Object.keys(myContext.engagements.visits).forEach((v) => {
    let visitTS = myContext.engagements.visits[v];
    if (visitTS >= cutoff) {
      totalVisit++;
      if (myContext.engagements.longVisits[v]) {
        longVisit++;
      }
    }
  });
  console.log("LONG VISIT", longVisit, "TOTAL VISIT", totalVisit);
  return { longVisit: longVisit, totalVisit: totalVisit };
}

export function sessionQualityAssessment(myContext, num = 10) {
  let totalVisit = 0;
  let longVisit = 0;
  let seq = 0;
  let latest = 0;
  Object.keys(myContext.engagements.visits)
    .sort((a, b) =>
      myContext.engagements.visits[a] > myContext.engagements.visits[b] ? -1 : 1
    )
    .forEach((v) => {
      seq++;
      if (seq < num) {
        if (seq == 1) {
          latest = myContext.engagements.visits[v];
        }
        totalVisit++;
        if (myContext.engagements.longVisits[v]) {
          longVisit++;
        }
      }
    });
  clog("LONG VISIT", longVisit, "TOTAL VISIT", totalVisit, "Latest", latest);
  return { longVisit: longVisit, totalVisit: totalVisit };
}
