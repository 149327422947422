import React, { useState, useContext } from "react";
import {
  Alert,
  Keyboard,
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";

import { API, graphqlOperation } from "aws-amplify";

import AwesomeAlert from "react-native-awesome-alerts";
import AppContext from "../components/AppContext";

import { addGroup, updateGroup } from "../src/graphql/custom";

import { addActionByUser, performAction } from "../utils/Action";
import { generateUniqueId } from "../utils/Id";

import Blocker from "../components/Blocker";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import LeftArrow from "../components/LeftArrow";
import ColoredButton from "../components/ColoredButton";
import { clog } from "../utils/Log";
import { useTheme } from "../theme";

function GroupUpdateScreen({ route, navigation }) {
  async function createAGroup() {
    setLoading(true);
    let group = {};
    if (Id) {
      group["Id"] = Id;
    } else {
      group["Id"] = generateUniqueId();
    }
    if (name) {
      group["name"] = name;
    }
    if (description) {
      group["description"] = description;
    }
    group["creatorId"] = passedId;
    group["curatorId"] = passedId;
    group["implicit"] = false;
    clog("WILL SAVE", group);

    try {
      let promises = [];
      ////clog("list data is ", list);
      promises.push(API.graphql(graphqlOperation(addGroup, group)));

      performAction({
        objectType: "Group",
        operation: "Create",
        objectId: group.Id,
        actorId: passedId,
        relationshipId: 0,
        objectCounter: 0,
        userCounter: myContext.numGroupCreate,
        curatorId: myContext.Id,
        curatorCounter: myContext.numCreateRecursive,
        promises: promises,
        callback: (success, relationshipId, createdAt) => {
          if (success) {
            addActionByUser(
              myContext.actionsByUser,
              "Group",
              "Create",
              group.Id,
              relationshipId,
              group,
              null,
              null,
              createdAt
            );
            myContext.numGroupCreate++;
            myContext.numCreateRecursive++;
            myContext.version++;
            setLoading(false);
            clog("Will go back after change", myContext);
            navigation.goBack();
          } else {
            setLoading(false);
            showMessage("failed to create group");
          }
        },
        refererActionId: null,
        hostUserId: null,
        seq: null,
      });
    } catch (err) {
      setLoading(false);
      showMessage("Failed to create Group!");
      console.log("error creating group...", err);
    }
  }

  async function updateAGroup() {
    setLoading(true);
    let group = {};
    group["Id"] = groupId;
    if (name) {
      group["name"] = name;
    }
    if (description) {
      group["description"] = description;
    }

    try {
      clog("group data is ", group);
      await API.graphql(graphqlOperation(updateGroup, group));
      myContext.version++;
      setLoading(false);
      navigation.goBack();
    } catch (err) {
      setLoading(false);
      showMessage("Failed to update Group!");
      console.log("error updating group...", err);
    }
  }

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed") },
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const handleSubmit = () => {
    let message = "";
    if (!name) {
      message = "Must provide name";
    }
    if (Id && Id.match(" ")) {
      message = "Id should not have any white spaces";
    }
    if (message) {
      showMessage(message);
    } else {
      if (updating) {
        updateAGroup();
      } else {
        createAGroup();
      }
    }
  };

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const { colors } = useTheme();

  const [Id, setId] = useState(
    route?.params?.Id == null ? "" : route.params.Id
  );
  const [name, setName] = useState(
    route?.params?.name == null ? "" : route.params.name
  );
  const [description, setDescription] = useState(
    route?.params?.description == null ? "" : route.params.description
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let passedId = route?.params?.Id;

  if (myContext) {
    if (!passedId) {
      passedId = myContext?.Id;
    }
  }
  let updating = route?.params?.updating;
  let groupId = route?.params?.groupId;

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: updating ? "Edit your group" : "Create a new group",
    });
  }, [navigation]);

  if (!passedId) {
    return <View></View>;
  } else {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: colors.backgroundColor,
        }}
      >
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          <Blocker loading={loading} />
          <KeyboardAwareScrollView>
            <View style={styles.inputContainer}>
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "400",
                  marginTop: 38,
                  marginBottom: 8,
                }}
              >
                Id
              </Text>
              <TextInput
                style={[
                  styles.textInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                  },
                ]}
                placeholder="easy and unique mnemonic without spaces'"
                placeholderTextColor={colors.placeholderText}
                value={Id}
                maxLength={200}
                onChangeText={(newValue) => setId(newValue)}
                onBlur={Keyboard.dismiss}
              />
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "400",
                  marginTop: 38,
                  marginBottom: 8,
                }}
              >
                Name
              </Text>
              <TextInput
                style={[
                  styles.textInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                  },
                ]}
                placeholder="Like 'Family'"
                placeholderTextColor={colors.placeholderText}
                value={name}
                maxLength={200}
                onChangeText={(newValue) => setName(newValue)}
                onBlur={Keyboard.dismiss}
              />
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "400",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                Note
              </Text>
              <TextInput
                style={[
                  styles.textAreaInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                  },
                ]}
                placeholder="Who are in this group?"
                placeholderTextColor={colors.placeholderText}
                value={description}
                maxLength={255}
                multiline={true}
                onChangeText={(newValue) => setDescription(newValue)}
                onBlur={Keyboard.dismiss}
              />
              <ColoredButton
                onPress={handleSubmit}
                buttonStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 160,
                  height: 54,
                  borderRadius: 16,
                  backgroundColor: colors.primaryButtonBackground,
                }}
                textStyle={{
                  textAlign: "center",
                  textAlignVertical: "center",
                  fontSize: 20,
                  fontWeight: "800",
                  color: colors.primaryButtonText,
                }}
                text={updating != null ? "Update" : "Create"}
              ></ColoredButton>
            </View>
            <AwesomeAlert
              show={showAlert}
              showProgress={false}
              title=""
              message={alertMessage}
              closeOnTouchOutside={true}
              closeOnHardwareBackPress={false}
              showCancelButton={true}
              cancelText="Ok"
              cancelButtonColor={colors.cancelButton}
              onCancelPressed={() => {
                setShowAlert(false);
              }}
            />
          </KeyboardAwareScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

export default GroupUpdateScreen;

const styles = StyleSheet.create({
  inputContainer: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  textInput: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 47,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 16,
  },
  textAreaInput: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 150,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    borderRadius: 16,
    alignContent: "flex-start",
  },
});
