import { generateUniqueId } from "./Id";

export const makeText = () => {
  return {
    type: "text",
    content: null,
    key: generateUniqueId(),
  };
};

export const makeBulletedList = () => {
  return {
    type: "bulletedList",
    header: "following is a bulleted list",
    data: [{ content: "", type: "text" }],
    key: generateUniqueId(),
  };
};

export const makeSlidingPanel = (subcomponent) => {
  const slide = {
    type: "slidingPanel",
    data: [subcomponent],
    key: generateUniqueId(),
  };

  return slide;
};

export const makeImage = () => {
  const image = {
    type: "image",
    key: generateUniqueId(),
  };

  return image;
};

export const makeQuote = () => {
  let quote = {
    type: "quote",
    data: {
      type: "text",
      content: null,
    },
    key: generateUniqueId(),
  };
  return quote;
};

export const makeCard = () => {
  const card = {
    type: "textCard",
    data: [
      {
        type: "text",
        content: "",
        style: {
          large: true,
          bold: true,
          reverse: true,
        },
        key: generateUniqueId(),
      },
    ],
    key: generateUniqueId(),
  };

  return card;
};

export const createObjectForCard = ({
  json,
  rowIndex,
  data,
  setData,
  makeSubcomponent,
}) => {
  const subcomponent = makeSubcomponent();
  json.data[rowIndex].data.push(subcomponent);
  const index = data.data.findIndex((item) => item.key === json.key);

  data.data[index] = json;
  setData(data.data);
};

export const toggleTextOrList = ({
  json,
  rowIndex,
  inBulletedList,
  list,
  data,
  setData,
}) => {
  if (!inBulletedList && list) {
    const list = {
      type: "bulletedList",
      header: "following is a bulleted list",
      data: [json],
      key: generateUniqueId(),
    };

    data.data[rowIndex] = list;
    setData(data.data);
  } else if (inBulletedList && !list) {
    data.data[rowIndex] = json;
    setData(data.data);
  }
};

export const onSubmit = ({
  rowIndex,
  inQuote,
  inBulletedList,
  data,
  setData,
}) => {
  if (inQuote) {
    const text = makeText();
    const oldData = data ? data.data : [];
    const newData = [...oldData, text];
    setData(newData);
  } else if (inBulletedList) {
    const subcomponent = makeText();
    data.data[rowIndex].data.push(subcomponent);
    setData(data.data);
  }
};
