// Based on https://github.com/Kimaia/react-native-hyperlinked-text by Kimaia

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Text, Linking } from "react-native";

/**
 * Replaces the string child with a hyperlinked version according to configuration
 */
export default class HyperlinkedText extends Component {
  constructor(props) {
    super(props);
    this._getMatches = this._getMatches.bind(this);
  }

  render() {
    return this._linkify(this);
  }

  _linkify(component) {
    const matches = this._gatherMatches(component.props.children);
    const newElements = this._replaceMatches(component, matches);
    return (
      <Text {...component.props} style={this.props.style}>
        {newElements}
      </Text>
    );
  }

  _gatherMatches(text) {
    const matches = this.props.linkDefs.reduce(
      (matches, linkDef) =>
        [...this._getMatches(linkDef, text)].concat(matches),
      []
    );
    return matches.sort((m1, m2) => m1.index - m2.index);
  }

  _getMatches(linkDef, text) {
    const regex = linkDef.regex;
    regex.lastIndex = 0; // reset the regex in case it was used before
    let matches = [];
    let regexResult;
    while ((regexResult = regex.exec(text)) !== null) {
      matches.push({
        text: regexResult[0],
        groups: regexResult.slice(1),
        index: regexResult.index,
        lastIndex: regex.lastIndex,
        linkDef,
      });
    }
    return matches;
  }

  _replaceMatches(component, matches) {
    const componentProps = {
      ...component.props,
      ref: undefined,
      key: undefined,
    };
    let _lastIndex = 0;
    const elements = [];
    for (let match of matches) {
      const linkDef = match.linkDef;

      let nonLinkedText = component.props.children.substring(
        _lastIndex,
        match.index
      );
      nonLinkedText && elements.push(nonLinkedText);
      _lastIndex = match.lastIndex;
      elements.push(
        <Text
          {...componentProps}
          key={match.index}
          style={[component.props.style, this.props.linkStyle]}
          onPress={() => this.props.onLinkPress(match.text, ...match.groups)}
        >
          {linkDef.replaceText
            ? linkDef.replaceText(match.text, ...match.groups)
            : match.text}
        </Text>
      );
    }
    elements.push(
      component.props.children.substring(
        _lastIndex,
        component.props.children.length
      )
    );
    return elements;
  }

  static _openWebUrl(url) {
    Linking.canOpenURL(url)
      .then((supported) => supported && Linking.openURL(url))
      .catch(console.log("Failed to open url " + url));
  }
}

HyperlinkedText.propTypes = {
  onLinkPress: PropTypes.func,
  linkDefs: PropTypes.array,
  linkStyle: PropTypes.object,
};

// Defaults for props
HyperlinkedText.defaultProps = {
  onLinkPress: HyperlinkedText._openWebUrl,
  linkDefs: [
    {
      regex:
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    },
  ],
  linkStyle: { color: "#0000EE" },
};
