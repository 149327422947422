import { createNotification } from "../controllers/NotificationController";
import {
  addActionByUser,
  removeActionByUser,
  undoAction,
  performAction,
} from "../utils/Action";
import { clog } from "../utils/Log";
import { sendPushNotification } from "../utils/Notification";

export async function toggleFollow({
  following,
  objectType,
  target,
  hostUserId,
  refererActionId,
  myContext,
  callback,
}) {
  if (objectType == "User") {
    if (!myContext.users[target.Id]) {
      myContext.users[target.Id] = target;
    }
  }
  const addObjectFollowed = (
    objectId,
    objectType,
    relationshipId,
    object,
    createdAt
  ) => {
    addActionByUser(
      myContext.actionsByUser,
      objectType,
      "Follow",
      objectId,
      relationshipId,
      object,
      null,
      null,
      createdAt
    );
    myContext["num" + objectType + "Follow"]++;
    myContext.version++;
  };

  const removeObjectFollowed = (
    objectId,
    objectType,
    relationshipId,
    object
  ) => {
    clog("stopped following", objectId, relationshipId);
    removeActionByUser(myContext.actionsByUser, objectType, "Follow", objectId);
    myContext["num" + objectType + "Follow"]--;
    myContext.version++;
  };

  let relationshipId = null;
  let localCallback = null;
  let fn = null;
  if (myContext.actionsByUser?.[objectType]?.["Follow"]?.[target.Id]) {
    following = true;
    relationshipId = Object.keys(
      myContext.actionsByUser[objectType]["Follow"][target.Id]
    )?.[0];
  }
  fn = following ? undoAction : performAction;
  let updatefn = following ? removeObjectFollowed : addObjectFollowed;
  clog("WILL TOGGLE FOLLOW", following);
  localCallback = (
    success,
    modifiedRelationshipId,
    createdAt,
    responses,
    creationTS
  ) => {
    clog("OUTCOME OF TOGGLE", success, modifiedRelationshipId, createdAt);
    if (success) {
      if (following) {
        target.numFollow--;
        if (objectType == "User") {
          target.numFollowRecursive--;
        }
      } else {
        target.numFollow++;
        if (objectType == "User") {
          target.numFollowRecursive++;
          // Notify the person who is being followed now
          console.log(
            "SHOULD NOTIFY",
            target.Id,
            "for follow of",
            objectType,
            "by",
            myContext.handle,
            "using token",
            target?.token
          );
          let data = {
            type: "User",
            userId: myContext.Id,
            action: "Follow",
            target: "User",
            recipientId: target.Id,
            actorId: myContext.Id,
            targetId: target.Id,
            targetType: "User",
            operation: "Follow",
            actionId: modifiedRelationshipId,
            creationTS: creationTS,
          };
          createNotification(data);

          if (target?.token) {
            console.log("CAN NOTIFY", target?.token);
            sendPushNotification(
              target?.token,
              "Village",
              "@" + myContext.handle + " " + "has started following you",
              data
            );
          }
        }
      }
      updatefn(
        target.Id,
        objectType,
        modifiedRelationshipId,
        target,
        createdAt
      );
      if (callback) {
        callback({ success: true });
      }
    } else {
      clog("failed to follow user");
      if (callback) {
        callback({ success: false, message: "failed to follow user" });
      }
    }
    ////clog("app context is", myContext);
  };
  let curatorId = null;
  let curatorCounter = 0;
  if (objectType == "User") {
    curatorId = target.Id;
    curatorCounter = target.numFollowRecursive;
  }
  fn({
    objectType: objectType,
    operation: "Follow",
    objectId: target.Id,
    actorId: myContext.Id,
    relationshipId: relationshipId,
    objectCounter: target.numFollow,
    userCounter: myContext?.["num" + objectType + "Follow"],
    curatorId: curatorId,
    curatorCounter: curatorCounter,
    promises: [],
    payloads: [],
    callback: localCallback,
    refererActionId: refererActionId,
    hostUserId: hostUserId,
    seq: null,
  });
}
