import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Alert,
  Keyboard,
  StyleSheet,
  Image,
  Text,
  TextInput,
  View,
  Platform,
  Pressable,
  Dimensions,
  SafeAreaView,
  Modal,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { WebView } from "react-native-webview";

import AppContext from "../components/AppContext";
import saveMessage from "../assets/aeroplane.png";
import heart from "../assets/heart.png";
import filledHeart from "../assets/filled_heart.png";
import { addCommentForObject } from "../utils/Comment.js";
import cross from "../assets/cross.png";
import Blocker from "../components/Blocker";
import SourceIcon from "../components/SourceIcon";
import { addActionByUser } from "../utils/Action";
import AwesomeAlert from "react-native-awesome-alerts";
import { KeyboardSpacer } from "../utils/Keyboard";
import { findTopActions, reorderActions } from "../utils/TopActions";
import { clog } from "../utils/Log";
import { getUrlPinsAndComments } from "../controllers/UrlController";
import { toggleLike } from "../utils/Like";
import { logEvent } from "../utils/LogEvent";
import { fetchFollowersIfNecessary } from "../utils/DataFetcher";
import { sendPushNotification } from "../utils/Notification";
import PinCurator from "../components/PinCurator";
import CommentCurator from "../components/CommentCurator";
import {
  MENTION_FORMATTER,
  pushNotificationWhenMentioned,
} from "../utils/Mention";
import { useTheme } from "../theme";
import ShareNote from "../components/ShareNote";
import { hashCode } from "../utils/Id";
import { constructRichShareNote } from "../utils/Compatibility";
import ReportSheet from "../components/ReportSheet";
import * as Haptics from "expo-haptics";
import PopUp from "../components/PopUp";
import { createNotification } from "../controllers/NotificationController";

const CommentModal = ({
  loading,
  modalVisible,
  setModalVisible,
  setCommentTarget,
  setCommentTargetType,
  sendComment,
}) => {
  const { colors } = useTheme();

  const [comment, setComment] = useState("");
  const [height, setHeight] = useState(Platform.OS != "web" ? 25 : 50);
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const clearInput = () => {
    setComment("");
    setCommentTarget(null);
    setCommentTargetType("");
  };

  const closeCommentModal = () => {
    if (isChanged) {
      if (Platform.OS !== "web") {
        Alert.alert(
          "Unsaved changes",
          "If you leave without saving, you will lose your changes.",
          [
            {
              style: "cancel",
              text: "Discard changes",
              onPress: () => {
                clearInput();
                setModalVisible(false);
                setIsChanged(false);
              },
            },
            {
              text: "Save changes",
              onPress: () => {
                sendComment(comment, setComment);
                setIsChanged(false);
              },
            },
          ]
        );
      } else {
        setShowUnsavedChangesAlert(true);
      }
    } else {
      setModalVisible(false);
    }
  };

  const UnsavedChangesAlert = () => {
    const clearAndClose = () => {
      clearInput();
      setIsChanged(false);
      setShowUnsavedChangesAlert(false);
      setModalVisible(false);
    };

    return (
      <AwesomeAlert
        show={showUnsavedChangesAlert}
        showProgress={false}
        title={"Unsaved changes"}
        message={"If you leave without saving, you will lose your changes."}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText={"Discard changes"}
        confirmText={"Save changes"}
        confirmButtonColor={colors.cancelButton}
        onConfirmPressed={() => {
          sendComment(comment, setComment);
          clearAndClose();
        }}
        onCancelPressed={clearAndClose}
        onDismiss={() => setShowUnsavedChangesAlert(false)}
      />
    );
  };

  return (
    <Modal
      visible={modalVisible}
      transparent={true}
      onRequestClose={() => {
        clog("Asked to cancel with modal visible", modalVisible);
        Keyboard.dismiss();
        closeCommentModal();
      }}
      animationType={"fade"}
    >
      <View style={{ flex: 1 }}>
        <Pressable
          style={{
            flex: 1,
            backgroundColor: "rgba(52, 52, 52, 0.8)",
          }}
          onPress={() => {
            clog("Asked to cancel with modal visible", modalVisible);
            Keyboard.dismiss();
            closeCommentModal();
          }}
          accessible={false}
        >
          <View
            style={{
              flex: 1,
              paddingVertical: 20,
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Pressable>
              <Pressable
                onPress={() => {
                  clog("Asked to cancel with modal visible", modalVisible);
                  Keyboard.dismiss();
                  closeCommentModal();
                }}
              >
                <View
                  style={{
                    height: 39,
                    marginBottom: 10,
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                    zIndex: 3, // works on ios
                    elevation: 3, // works on android
                  }}
                >
                  <View
                    style={{
                      height: 50,
                      width: 50,
                      padding: 20,
                    }}
                  >
                    <Image
                      source={cross}
                      style={{
                        marginRight: 20,
                        height: 9,
                        width: 8,
                        tintColor: "white", // need the same color to deal with overlay
                      }}
                    />
                  </View>
                </View>
              </Pressable>

              <View>
                <View
                  style={{
                    height: height + 40,
                    borderRadius: 20,
                    backgroundColor: colors.secondaryBackground,
                    flexDirection: "row",
                    overflow: "hidden",
                    paddingVertical: 15,
                    alignSelf: "flex-end",
                    width: "100%",
                    borderWidth: 1,
                    borderColor: colors.primaryText,
                  }}
                >
                  <TextInput
                    style={{
                      marginLeft: 20,
                      alignContent: "center",
                      justifyContent: "center",
                      flex: 1,
                      color: colors.primaryText,
                      borderWidth: 0,
                    }}
                    autoFocus={true}
                    placeholder="Leave a comment.."
                    value={comment}
                    maxLength={250}
                    multiline
                    placeholderTextColor={colors.secondaryText}
                    onChangeText={(newValue) => {
                      setComment(newValue);
                      setIsChanged(true);
                    }}
                    onContentSizeChange={(e) => {
                      if (Platform.OS != "web") {
                        setHeight(e.nativeEvent.contentSize.height);
                      }
                    }}
                    onBlur={() => {
                      console.log("would have dismissed keyboard");
                    }}
                  />

                  <Pressable
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginRight: 10,
                      width: 40,
                    }}
                    onPress={() => {
                      sendComment(comment, setComment);
                      setIsChanged(false);
                    }}
                  >
                    <Image
                      source={saveMessage}
                      style={[
                        styles.superposedSaveMessage,
                        { tintColor: colors.primaryText },
                      ]}
                    />
                  </Pressable>
                </View>

                <View
                  style={{
                    height: 20,
                    alignItems: "flex-end",
                    paddingTop: 5,
                    paddingRight: 10,
                  }}
                >
                  <Text
                    style={
                      comment?.length < 230
                        ? { color: colors.primaryText }
                        : comment?.length <= 250
                        ? { color: "orange" }
                        : { color: "red" }
                    }
                  >
                    {comment?.length ? 250 - comment.length : 250}
                  </Text>
                </View>
              </View>
            </Pressable>
          </View>
        </Pressable>
        <KeyboardSpacer topSpacing={Platform.OS !== "android" ? -10 : -400} />
        <Blocker loading={loading} />
        <UnsavedChangesAlert />
      </View>
    </Modal>
  );
};

const CommentScreen = ({ route, navigation }) => {
  const addObjectComment = (
    success,
    relationshipId,
    newComment,
    createdAt,
    targetType,
    targetId,
    creationTS,
    comment,
    setComment
  ) => {
    if (success) {
      let commentText = comment;
      setComment("");
      setCommentTarget(null);
      setCommentTargetType("");
      setLoading(false);
      setModalVisible(false);
      myContext.numCommentCreate++;
      myContext["urlEngagedWith"] = url;
      let comments = commentTarget?.comments?.items;
      if (!comments) {
        comments = commentTarget?.comments;
      }
      clog("NEW COMMENT", newComment);
      if (comments) {
        comments.push(newComment);
      } else {
        clog("ERROR: cannot find target for comments", commentTarget);
      }
      clog("MODIFIED comment target", commentTarget);
      setUrl({
        ...url,
        topActions: findTopActions(url, myContext, url.actionParams),
      });
      addActionByUser(
        myContext.actionsByUser,
        "Comment",
        "Create",
        newComment.Id,
        relationshipId,
        newComment,
        null,
        null,
        createdAt
      );
      if (flatListRef) {
        flatListRef.scrollToEnd();
      }
      setState((previousState) => {
        return {
          ...previousState,
          numCommentsCreated: previousState.numCommentsCreated + 1,
        };
      });

      // find all the people mentioned in the comment
      let mentionRegex = /@([a-zA-Z0-9]+)/g;

      let result;
      let mentioned = {};
      let hasMention = false;
      while ((result = mentionRegex.exec(commentText))) {
        // Do something with result[0].
        mentioned[result[1].toLowerCase()] = true;
        hasMention = true;
      }

      // Now find the people who should be notified
      let mentionRecipients = {};
      if (hasMention) {
        Object.values(myContext?.followers).forEach((follower) => {
          console.log("Follower", follower);
          if (mentioned[follower.handle] && follower.token) {
            mentionRecipients[follower.Id] = true;
          }
        });
      }

      let interestedUsers = {};
      url.topActions?.forEach((a) => {
        if (a?.action?.actorId && a.action.actorId != myContext.Id) {
          // Suppress comment notification and send mention notification instead
          if (
            myContext?.followers?.[a.action.actorId] &&
            !mentionRecipients?.[a.action.actorId]
          ) {
            interestedUsers[a.action.actorId] = true;
          }
        }
      });
      clog("USERS INTERESTED IN COMMENTS", Object.keys(interestedUsers));
      Object.keys(interestedUsers).forEach((uid) => {
        clog("SHOULD NOTIFY", uid, "for comment by", myContext.handle);
        let data = {
          type: "Url",
          userId: myContext.Id,
          urlId: url.Id,
          action: "Comment",
          target: targetType,
          recipientId: uid,
          actorId: myContext.Id,
          targetId: targetId,
          targetType: targetType,
          operation: "Comment",
          actionId: relationshipId,
          creationTS: creationTS,
        };
        if (targetType == "Pin") {
          data["pinId"] = targetId;
        }
        if (targetType == "Comment") {
          data["commentId"] = newComment.Id;
        }
        createNotification(data);

        if (myContext?.followers?.[uid]?.token) {
          clog("CAN NOTIFY", myContext?.followers?.[uid]?.token);
          sendPushNotification(
            myContext?.followers?.[uid]?.token,
            "Village",
            "@" + myContext.handle + " " + "commented on: " + url.title,
            data
          );
        }
      });

      // find all the people mentioned in the comment
      pushNotificationWhenMentioned(
        myContext,
        comment,
        targetType,
        relationshipId,
        url.title,
        url.Id,
        targetId,
        creationTS
      );

      clog("SUCCESSFULLY ADDED COMMENT");
    } else {
      setLoading(false);
      showMessage("Could not save comment");
    }
    logEvent(
      origin == "myvillage"
        ? "MyVillage_Content_Comment_Send"
        : "Content_Comment_Send",
      {
        userId: myContext?.Id,
        username: myContext?.handle,
        category: origin,
        platform: Platform.OS,
        action: "click",
        target: "send",
        status: success ? "success" : "failure",
        appVersion: myContext.appVersion,
      }
    );
    console.log("DONE ADDING LOG EVENT");
  };

  async function getData(Id) {
    getUrlPinsAndComments({
      Id: Id,
      myContext: myContext,
      callback: ({ success, message, error, annotatedUrl }) => {
        if (success) {
          let lurl = { ...url, ...annotatedUrl };
          lurl["topActions"] = findTopActions(lurl, myContext);
          lurl.topActions = reorderActions(lurl.topActions);
          console.log("RERANKING ACTIONS", Id);
          setUrl(lurl);
          setIncomplete(false);
        } else {
          clog(message);
        }
      },
    });
  }

  const showAlertMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const contentModalFunction = (target) => {
    clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  const logCommentStartEvent = () => {
    logEvent(
      origin == "myvillage" ? "MyVillage_Content_Comment" : "Content_Comment",
      {
        userId: myContext?.Id,
        username: myContext?.handle,
        category: origin,
        platform: Platform.OS,
        action: "click",
        target: "comment",
        appVersion: myContext.appVersion,
      }
    );
  };
  const showChildComment = (sender, type, item, message, numLike) => {
    clog(
      "asked to show child message",
      "sender",
      sender,
      "type",
      type,
      "item",
      item,
      "message",
      message,
      "numLike",
      numLike
    );
    if (!message) {
      message = "";
    }
    //if (sender && message) {
    if (sender) {
      clog("good child message", sender, message, type, item);
      let liked = myContext.actionsByUser?.[type]?.["Like"]?.[item.Id]
        ? true
        : false;

      let likers = [];
      let likerString = "";

      if (
        myContext.actionsByUser?.["Experiment"]?.["Join"]?.["ShowLikers"] !=
        null
      ) {
        item?.actions?.items?.forEach((action) => {
          // NOTE(alpha): Accesses key only
          if (
            action.operation == "Like" &&
            action.actor?.Id &&
            myContext?.actionsByUser?.["User"]?.["Follow"]?.[action.actor.Id]
          ) {
            clog("Liked by", action.actor);
            likers.push(action.actor.handle);
          }
        });
        if (likers.length > 0) {
          if (likers.length == 1) {
            likerString = likers[0] + " liked";
          } else if (likers.length == 2) {
            likerString = likers[0] + " and " + likers[1] + " liked";
          } else if (likers.length == 3) {
            likerString =
              likers[0] + ", " + likers[1] + " and 1 other" + " liked";
          } else {
            let remaining = likers.length - 2;
            likerString =
              likers[0] +
              ", " +
              likers[1] +
              " and " +
              remaining.toString() +
              " others" +
              " liked";
          }
        }
        clog("LIKER STRING", likerString);
      }

      return (
        <Pressable
          key={item.Id}
          style={{
            marginTop: 16,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 12,
            borderRadius: 8,
            flexDirection: "column",
            overflow: "hidden",
          }}
          onLongPress={() => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            setComment(item);
            setContext("comment");
            reportRef.current?.onOpen();
          }}
        >
          <View style={{ height: 24, width: "100%" }}></View>
          <View
            style={{
              height: 36,
              position: "absolute",
              top: 18,
              left: 12,
            }}
          >
            {type == "Comment" && (
              <CommentCurator
                curatorId={sender.Id}
                comment={item}
                myContext={myContext}
                navigation={navigation}
                refererActionId={null}
                hostUserId={null}
                origin={origin}
                colors={colors}
                screen={"comment"}
              />
            )}
            {type == "Pin" && (
              <PinCurator
                curatorId={sender.Id}
                pin={item}
                myContext={myContext}
                navigation={navigation}
                refererActionId={null}
                hostUserId={null}
                origin={origin}
                colors={colors}
                screen={"comment"}
              />
            )}
          </View>
          <View
            style={{
              marginLeft: 48,
            }}
          >
            {message != "" && (
              <View
                style={
                  type == "Pin"
                    ? [
                        styles.noteBackground,
                        {
                          backgroundColor: message
                            ? colors.secondaryBackground
                            : "transparent",
                        },
                      ]
                    : styles.commentBackground
                }
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: colors.primaryText,
                  }}
                >
                  {true &&
                    MENTION_FORMATTER(
                      message,
                      navigation,
                      contentModalFunction
                    )}
                  {false && message}
                </Text>
              </View>
            )}
            <View
              style={{
                marginTop: 8,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Pressable
                onPress={() => {
                  setLoading(true);
                  toggleLike({
                    liked: liked,
                    objectType: type,
                    target: item,
                    refererActionId: refererActionId,
                    hostUserId: hostUserId,
                    myContext: myContext,
                    url: url,
                    callback: ({ success, message, error }) => {
                      setLoading(false);
                      if (success) {
                        let fieldName = "num" + type + "Like";
                        setState((previousState) => {
                          let newState = {
                            ...previousState,
                          };
                          newState[fieldName] = myContext[fieldName];
                          return newState;
                        });
                      } else {
                        clog("Could not execute like");
                      }
                      logEvent(
                        origin == "myvillage"
                          ? type == "Comment"
                            ? "MyVillage_Content_Comment_Like"
                            : "MyVillage_Content_Pin_Like"
                          : type == "Comment"
                          ? "Content_Comment_Like"
                          : "Content_Pin_Like",
                        {
                          userId: myContext?.Id,
                          username: myContext?.handle,
                          category: origin,
                          platform: Platform.OS,
                          action: "click",
                          target: "like",
                          status: success ? "success" : "failure",
                          toggle: liked ? "off" : "on",
                          appVersion: myContext.appVersion,
                        }
                      );
                    },
                  });
                }}
              >
                <View
                  style={{
                    height: 24,
                    width: 24,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    source={liked ? filledHeart : heart}
                    style={[
                      {
                        height: 24,
                        width: 24,
                      },
                      liked
                        ? { tintColor: colors.alert }
                        : { tintColor: colors.footerButtons },
                    ]}
                  />
                </View>
              </Pressable>
              <Text
                style={{
                  color: colors.primaryText,
                  opacity: 0.8,
                  marginLeft: 8,
                }}
              >
                {numLike}
              </Text>
              {likerString != "" && (
                <Text
                  style={{
                    color: colors.primaryText,
                    opacity: 0.8,
                    marginLeft: 16,
                  }}
                >
                  {likerString}
                </Text>
              )}
            </View>
          </View>
        </Pressable>
      );
    } else {
      clog("PROBLEMATIC MESSAGE", sender, message);
      return <View key={item.Id}></View>;
    }
  };

  const showMessage = (sender, type, item, message, numLike) => {
    clog(
      "asked to show message",
      "sender",
      sender,
      "type",
      type,
      "item",
      item,
      "message",
      message,
      "numLike",
      numLike
    );
    if (!message) {
      message = "";
    }
    let sortedChildren = item?.comments?.items?.sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 1
    );
    clog("SORTED CHILDREN", sortedChildren);
    //if (sender && message) {
    if (sender) {
      clog("good message", sender, message, type, item);
      let liked = myContext.actionsByUser?.[type]?.["Like"]?.[item.Id]
        ? true
        : false;

      let likers = [];
      let likerString = "";

      if (
        myContext.actionsByUser?.["Experiment"]?.["Join"]?.["ShowLikers"] !=
        null
      ) {
        item?.actions?.items?.forEach((action) => {
          // NOTE(alpha): Accesses key only
          if (
            action.operation == "Like" &&
            action.actor?.Id &&
            myContext?.actionsByUser?.["User"]?.["Follow"]?.[action.actor.Id]
          ) {
            clog("Liked by", action.actor);
            likers.push(action.actor.handle);
          }
        });
        if (likers.length > 0) {
          if (likers.length == 1) {
            likerString = likers[0] + " liked";
          } else if (likers.length == 2) {
            likerString = likers[0] + " and " + likers[1] + " liked";
          } else if (likers.length == 3) {
            likerString =
              likers[0] + ", " + likers[1] + " and 1 other" + " liked";
          } else {
            let remaining = likers.length - 2;
            likerString =
              likers[0] +
              ", " +
              likers[1] +
              " and " +
              remaining.toString() +
              " others" +
              " liked";
          }
        }
        clog("LIKER STRING", likerString);
      }
      let itemJSON = {};
      if (item?.markup) {
        try {
          itemJSON = JSON.parse(item?.content);
        } catch (err) {
          console.log("error", err);
          item["content"] = "";
          item.markup = false;
        }
      }
      return (
        <View
          style={{
            marginBottom: 16,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 12,
            paddingRight: 12,
            borderRadius: 8,
            flexDirection: "column",
            backgroundColor: colors.conversationBackground,
            overflow: "hidden",
          }}
        >
          <View
            style={{ height: type == "Comment" ? 24 : 36, width: "100%" }}
          />
          <View
            style={{
              height: 36,
              position: "absolute",
              top: 18,
              left: 12,
            }}
          >
            {type == "Comment" && (
              <CommentCurator
                curatorId={sender.Id}
                comment={item}
                myContext={myContext}
                navigation={navigation}
                refererActionId={null}
                hostUserId={null}
                origin={origin}
                colors={colors}
                screen={"comment"}
              />
            )}
            {type == "Pin" && (
              <PinCurator
                curatorId={sender.Id}
                pin={item}
                myContext={myContext}
                navigation={navigation}
                refererActionId={null}
                hostUserId={null}
                origin={origin}
                colors={colors}
                screen={"comment"}
              />
            )}
          </View>
          <Pressable
            onLongPress={() => {
              if (Platform.OS !== "web") {
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
              }
              setContext("sharenote");
              reportRef.current?.onOpen();
            }}
          >
            {message != "" && item.markup ? (
              <View>
                <ShareNote
                  json={itemJSON}
                  colors={colors}
                  myContext={myContext}
                  seed={hashCode(item.Id)}
                  item={item}
                  origin={origin}
                  navigation={navigation}
                  contentModalFunction={contentModalFunction}
                  showExpandedNote={true}
                />
              </View>
            ) : type == "Pin" ? (
              <View>
                <ShareNote
                  json={constructRichShareNote(message)}
                  colors={colors}
                  myContext={myContext}
                  seed={hashCode(item.Id)}
                  item={item}
                  origin={origin}
                  navigation={navigation}
                  contentModalFunction={contentModalFunction}
                  showExpandedNote={true}
                />
              </View>
            ) : (
              <View style={[styles.commentBackground, { marginLeft: 48 }]}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: colors.primaryText,
                  }}
                >
                  {true &&
                    MENTION_FORMATTER(
                      message,
                      navigation,
                      contentModalFunction
                    )}
                  {false && message}
                </Text>
              </View>
            )}
          </Pressable>

          <View
            style={{
              marginTop: message != "" ? 8 : 0,
              marginLeft: message != "" && type == "Pin" ? 0 : 54,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Pressable
              onPress={() => {
                setLoading(true);
                toggleLike({
                  liked: liked,
                  objectType: type,
                  target: item,
                  refererActionId: refererActionId,
                  hostUserId: hostUserId,
                  myContext: myContext,
                  url: url,
                  callback: ({ success, message, error }) => {
                    setLoading(false);
                    if (success) {
                      let fieldName = "num" + type + "Like";
                      setState((previousState) => {
                        let newState = {
                          ...previousState,
                        };
                        newState[fieldName] = myContext[fieldName];
                        return newState;
                      });
                    } else {
                      clog("Could not execute like");
                    }
                    logEvent(
                      origin == "myvillage"
                        ? type == "Comment"
                          ? "MyVillage_Content_Comment_Like"
                          : "MyVillage_Content_Pin_Like"
                        : type == "Comment"
                        ? "Content_Comment_Like"
                        : "Content_Pin_Like",
                      {
                        userId: myContext?.Id,
                        username: myContext?.handle,
                        category: origin,
                        platform: Platform.OS,
                        action: "click",
                        target: "like",
                        status: success ? "success" : "failure",
                        toggle: liked ? "off" : "on",
                        appVersion: myContext.appVersion,
                      }
                    );
                  },
                });
              }}
            >
              <Image
                source={liked ? filledHeart : heart}
                style={[
                  {
                    height: 24,
                    width: 24,
                  },
                  liked
                    ? { tintColor: colors.alert }
                    : { tintColor: colors.footerButtons },
                ]}
              />
            </Pressable>
            <Text
              style={{
                color: colors.primaryText,
                opacity: 0.8,
                marginLeft: 8,
              }}
            >
              {numLike}
            </Text>
            {likerString != "" && (
              <Text
                style={{
                  color: colors.primaryText,
                  opacity: 0.8,
                  marginLeft: 16,
                }}
              >
                {likerString}
              </Text>
            )}
          </View>
          <Pressable
            onPress={() => {
              setCommentTarget(item);
              setCommentTargetType("Comment");
              setModalVisible(true);
              logCommentStartEvent();
            }}
          >
            <View
              style={{
                height: 41,
                borderWidth: 1,
                borderColor: colors.textFieldBorder,
                borderRadius: 100,
                marginTop: 16,
                paddingVertical: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: colors.placeholderText,
                  alignContent: "center",
                  justifyContent: "center",
                  marginLeft: 20,
                }}
              >
                {"Join the conversation..."}
              </Text>
            </View>
          </Pressable>
          {item?.comments?.items
            ?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
            ?.map((c, rowIndex) => {
              clog("Render", c);
              return showChildComment(
                myContext.users[c?.curatorId],
                "Comment",
                c,
                c.content?.trim(),
                c.numLike
              );
            })}
          {item?.comments?.items?.length > 0 && (
            <Pressable
              onPress={() => {
                setCommentTarget(item);
                setCommentTargetType("Comment");
                setModalVisible(true);
                logCommentStartEvent();
              }}
            >
              <View
                style={{
                  height: 41,
                  borderWidth: 1,
                  borderColor: colors.textFieldBorder,
                  borderRadius: 100,
                  //marginTop: 16,
                  paddingVertical: 10,
                  marginLeft: 12,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: colors.placeholderText,
                    alignContent: "center",
                    justifyContent: "center",
                    marginLeft: 20,
                  }}
                >
                  {"Join the conversation..."}
                </Text>
              </View>
            </Pressable>
          )}
        </View>
      );
    } else {
      clog("PROBLEMATIC MESSAGE", sender, message);
      return <View></View>;
    }
  };

  const renderItem = (item) => {
    clog("will render", item);
    return showMessage(
      myContext.users[item.item.data.curatorId],
      item.item.type,
      item.item.data,
      item.item.data?.content?.trim(),
      item.item.data?.numLike
    );
  };

  let refererActionId = route?.params?.refererActionId;
  let hostUserId = route?.params?.hostUserId;
  let notFoldedFromParams = route?.params?.notFolded;
  let origin = route?.params?.origin;

  const initialState = {};
  const [url, setUrl] = useState(route?.params?.url);
  const [state, setState] = useState(initialState);
  const [folded, setFolded] = useState(notFoldedFromParams ? false : true);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");
  const [commentTarget, setCommentTarget] = useState(null);
  const [commentTargetType, setCommentTargetType] = useState("");
  const [incomplete, setIncomplete] = useState(route?.params?.incomplete);
  const [context, setContext] = useState("");
  const [comment, setComment] = useState();
  const [showReportPopUp, setShowReportPopUp] = useState(false);
  const [messageReportPopUp, setMessageReportPopUp] = useState("");

  const popUpRef = useRef(null);
  const reportRef = useRef(null);

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  let flatListRef = null;

  clog("context", myContext);

  clog("PARAMS", route.params);
  clog("will render url", url);

  useEffect(() => {
    if (myContext) {
      setState((prevState) => {
        return {
          ...prevState,
        };
      });
    }
  }, []);

  const windowDimension = Dimensions.get("window");
  const screen = Dimensions.get("screen");

  screenWidth = windowDimension.width;
  const { colors } = useTheme();

  let currentTime = Date.now();
  let topActions = url?.topActions
    ? reorderActions([...url?.topActions])
    : null;
  let topAction = topActions?.[0]?.action;
  let topTarget = topActions?.[0]?.target;
  let justificationAction = url?.justificationAction?.action;
  let justificationTarget = url?.justificationAction?.target;
  // find the curator closest to the reader
  let curator = myContext.users[justificationTarget?.curatorId];
  let otherMessages = [];
  let seenAction = {};
  if (topTarget?.Id) {
    seenAction[topTarget.Id] = true;
  }

  topActions?.forEach((a) => {
    let target = a?.target;
    if (target && target.Id != topTarget.Id && !seenAction[target.Id]) {
      otherMessages.push({
        type: a.action.objectType,
        data: target,
        Id: target.Id,
      });
      seenAction[target.Id] = true;
    }
  });
  //url.otherMessages = otherMessages;
  let selfPin = null;
  let pins = url?.pins?.items;
  if (!pins) {
    pins = url?.pins;
  }
  pins?.forEach((pin) => {
    if (pin?.curator?.Id == myContext.Id) {
      selfPin = pin;
    }
  });
  clog("COMPUTED SELF PIN", selfPin, "from", url);

  clog("other messages", otherMessages);
  clog("top target", topTarget);

  clog("URL", url);
  clog("INCOMPLETE", incomplete);

  useEffect(() => {
    if (url.Id && incomplete) {
      getData(url.Id).catch((err) => {
        console.log("error finding url data: ", err);
      });
    }
  }, []);

  logEvent(
    origin == "myvillage"
      ? "MyVillage_Content_Comment_View"
      : "Content_Comment_View",
    {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: origin,
      platform: Platform.OS,
      appVersion: myContext.appVersion,
    }
  );

  React.useLayoutEffect(() => {
    clog("SELF PIN", selfPin);
    navigation.setOptions({
      headerLeft: () => {
        return (
          <View style={{ paddingLeft: 16 }}>
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 16,
                fontWeight: "700",
              }}
            >
              Conversations
            </Text>
          </View>
        );
      },
      headerRight: () => {
        return (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: 24,
              width: 24,
            }}
          >
            <Pressable
              onPress={() => {
                clog("Pressed left button");
                navigation.goBack();
              }}
            >
              <Image
                source={cross}
                style={{
                  marginRight: 20,
                  height: 16,
                  width: 16,
                  tintColor: colors.primaryText,
                }}
              />
            </Pressable>
          </View>
        );
      },
      headerTitle: "",
    });
  }, [navigation, state]);

  fetchFollowersIfNecessary(myContext);

  const sendComment = (comment, setComment) => {
    setTimeout(() => {
      ////clog("asked to save comment", comment);
      console.log("ASKED TO SAVE COMMENT");
      if (comment) {
        // your navigation or any action here
        setLoading(true);
        addCommentForObject({
          objectId: commentTarget.Id,
          objectType: commentTargetType,
          commenterId: myContext.Id,
          comment: comment,
          curatorId:
            commentTargetType == "Url"
              ? topTarget?.curatorId
              : commentTarget.curatorId,
          urlId: url.Id,
          numCommentsOnObject: 0,
          numCommentsCreated: myContext.numCommentCreate,
          numCommentRecursive: 0,
          myContext: myContext,
          existingCommenterIds: url.commenterIds,
          callback: (
            success,
            relationshipId,
            newComment,
            createdAt,
            targetType,
            targetId,
            creationTS
          ) =>
            addObjectComment(
              success,
              relationshipId,
              newComment,
              createdAt,
              targetType,
              targetId,
              creationTS,
              comment,
              setComment
            ),
          refererActionId: refererActionId,
          hostUserId: hostUserId,
        });
      }
    }, 100);
  };

  const renderHeader = () => (
    <View>
      {showMessage(
        myContext.users[topTarget.curatorId],
        topAction.objectType,
        topTarget,
        topTarget.content,
        topTarget.numLike
      )}
    </View>
  );

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: colors.conversationScreenBackground,
      }}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <Blocker loading={loading} />
        <Pressable
          onPress={() => {
            logEvent(origin == "myvillage" ? "MyVillage_Content" : "Content", {
              userId: myContext?.Id,
              username: myContext?.handle,
              category: origin,
              platform: Platform.OS,
              action: "click",
              target: "content",
              appVersion: myContext.appVersion,
            });
            navigation.push("ItemDetails", {
              url: url,
              curator: myContext.users[topAction?.actorId],
              refererActionId: topAction?.Id,
              hostUserId: null,
              origin: origin,
            });
          }}
          onLongPress={() => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            setContext("original");
            reportRef.current?.onOpen();
          }}
        >
          <View
            style={{
              backgroundColor: colors.compactCardBackground,
              marginTop: 16,
              marginLeft: 16,
              marginRight: 16,
              padding: 8,
              borderRadius: 8,
            }}
          >
            <Text
              style={{
                fontSize: 15,
                fontWeight: "700",
                color: colors.primaryText,
              }}
            >
              {url.title}
            </Text>
            <View
              style={{
                marginTop: 4,
                flexDirection: "row",
              }}
            >
              <SourceIcon
                url={url.uri}
                icon={
                  url?.source
                    ? myContext.presignedUrls[url?.source?.avatar]
                      ? myContext.presignedUrls[url.source.avatar]?.uri
                      : url?.source?.avatarUrl
                    : url?.source?.avatarUrl
                }
                style={{
                  height: 16,
                  width: 16,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                  backgroundColor: colors.primaryText,
                }}
                textstyle={{
                  fontSize: 20,
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              ></SourceIcon>
              <Text
                style={{
                  fontSize: 12,
                  color: colors.secondaryText,
                  marginLeft: 4,
                }}
              >
                {url.sourceId}
              </Text>
            </View>
          </View>
        </Pressable>
        {!folded && !incomplete && (
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <FlatList
              ListHeaderComponent={renderHeader()}
              style={{
                marginTop: 16,
                marginLeft: 16,
                marginRight: 16,
                paddingBottom: 100,
                borderRadius: 8,
                backgroundColor: colors.conversationBackground,
              }}
              data={otherMessages}
              renderItem={renderItem}
              keyExtractor={(m) => {
                //clog("will find key for", m);
                return m.Id;
              }}
              extraData={state}
              ref={(ref) => (flatListRef = ref)} // assign the flatlist's ref to your component's FlatListRef...
              keyboardShouldPersistTaps={"never"}
              showsVerticalScrollIndicator={false}
              //onContentSizeChange={() => flatListRef.scrollToEnd()}
              ListFooterComponent={() =>
                Platform.OS !== "web" ? <View style={{ height: 100 }} /> : null
              }
            />
            <TouchableOpacity
              onPress={() => {
                setCommentTarget(url);
                setCommentTargetType("Url");
                setModalVisible(true);
                logCommentStartEvent();
              }}
              style={[
                {
                  position: "absolute",
                  alignSelf: "center",
                  bottom: 0,
                  width: "100%",
                  paddingLeft: 16,
                  paddingRight: 16,
                },
                Platform.OS !== "web" ? { marginBottom: 80 } : {},
              ]}
            >
              <View
                style={{
                  height: 41,
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  backgroundColor: colors.primaryButtonBackground,
                  borderRadius: 100,
                }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "700",
                    color: colors.primaryButtonText,
                    textAlign: "center",
                  }}
                >
                  Start a new thread
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title=""
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          cancelText="Ok"
          cancelButtonColor={colors.cancelButton}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
        />

        <CommentModal
          loading={loading}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          setCommentTarget={setCommentTarget}
          setCommentTargetType={setCommentTargetType}
          sendComment={(comment, setComment) =>
            sendComment(comment, setComment)
          }
        />

        <Modal
          visible={webModalVisible}
          onRequestClose={() => {
            setWebModalVisible(false);
          }}
        >
          <SafeAreaView style={styles.container}>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  backgroundColor: colors.background,
                  height: 39,
                  paddingVertical: 10,
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Pressable
                  onPress={() => {
                    setWebModalVisible(false);
                  }}
                >
                  <Image
                    source={cross}
                    style={{
                      marginRight: 20,
                      height: 19,
                      width: 17,
                      tintColor: colors.primaryText,
                    }}
                  />
                </Pressable>
              </View>
              <WebView
                style={{ flex: 1 }}
                source={{ uri: webModalUrl }}
                ref={(r) => (webref = r)}
                originWhitelist={["*"]}
                javaScriptEnabled={true}
                domStorageEnabled={true}
              />
            </View>
          </SafeAreaView>
        </Modal>
        <ReportSheet
          ref={reportRef}
          item={context === "comment" ? comment : url}
          context={context}
          myContext={myContext}
          onShowReportPopUp={(message) => {
            setMessageReportPopUp(message);
            setShowReportPopUp(true);
            popUpRef.current?.onAnimatedPopUp();
          }}
        />

        {showReportPopUp && (
          <PopUp
            ref={popUpRef}
            title={messageReportPopUp}
            onClose={() => setShowReportPopUp(false)}
            colors={colors}
          />
        )}
      </View>
    </View>
  );
};

export default CommentScreen;
let screenWidth = 0;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  superposedSaveMessage: {
    height: 17.78,
    width: 20.34,
  },
  noteBackground: {
    padding: 8,
    marginTop: 5,
    marginLeft: 48,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  commentBackground: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
