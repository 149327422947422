import React, { useState } from "react";
import { StyleSheet, Text, View, FlatList, Pressable } from "react-native";

import RequestCard from "./RequestCard";

const RequestList = ({
  state,
  myContext,
  navigation,
  renderHeader,
  refererActionId,
  hostUserId,
  colors,
}) => {
  const [loading, setLoading] = useState(false);

  const renderItem = ({ item }) => {
    return (
      <View
        style={[
          styles.Request,
          { backgroundColor: colors.secondaryBackground },
        ]}
      >
        <View style={{ flex: 1, marginRight: 10 }}>
          <RequestCard
            item={item}
            myContext={myContext}
            colors={colors}
            navigation={navigation}
            fromList={true}
          />
        </View>
      </View>
    );
  };

  const sharedKeyExtractor = (item) => item.Id;
  console.log("Request state", state);
  return (
    <FlatList
      title={state?.relationship}
      data={state?.requests}
      renderItem={renderItem}
      keyExtractor={sharedKeyExtractor}
      extraData={state}
    />
  );
};

export default RequestList;

const styles = StyleSheet.create({
  Request: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 16,
    paddingLeft: 20,
    paddingRight: 25,
    marginTop: 10,
    height: 69,
  },
  coloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
  },
  uncoloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
    borderWidth: 2,
  },
  text: { fontSize: 12 },
});
