import { API, graphqlOperation } from "aws-amplify";

import { addError } from "../src/graphql/custom";
import { generateUniqueId } from "../utils/Id";

let debug = false;
//export var clog = console.log;
export function clog(...args) {
  if (debug) {
    //let text = JSON.stringify(args);
    console.log(...args);
    //clog(text);
  }
}

export async function elog(...args) {
  let Id = generateUniqueId();
  let [userID, screen, operation, ...leftover] = args;
  let text = JSON.stringify(leftover);
  let errorDetails = {
    Id: Id,
    log: text,
    userID: userID,
    screen: screen,
    operation: operation,
  };
  clog("ERROR DETAILS", errorDetails);
  try {
    let response = await API.graphql(graphqlOperation(addError, errorDetails));
    clog("response", response);
  } catch (err) {
    console.log("Could not save error", err);
  }
}
