import { React, useRef, forwardRef } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Pressable,
  Platform,
  Animated,
} from "react-native";

import Item from "./Item";
import { findTopActions } from "../utils/TopActions";
import { clog } from "../utils/Log";
import { logEvent } from "../utils/LogEvent";
import ItemFooter from "../components/ItemFooter";
import { photoIdFromId } from "../utils/RandomFromId";
import Skeleton from "./Skeleton";
import {
  trackViews,
  summarizeViews,
  sessionQualityAssessment,
} from "../utils/Tracker";
import { villageColors } from "../utils/SharedStyles";

const ItemList = (props, ref) => {
  const {
    state,
    panels,
    task,
    myContext,
    navigation,
    isRefreshing,
    isExpanding,
    refreshControl,
    setLoading,
    showAlertMessage,
    origin,
    forceRefresh,
    contentModalFunction,
    footer,
    onScroll,
    onEndReachedThreshold,
    onEndReached,
    onResponderRelease,
    colors,
    onLastItemVisible,
    ListEmptyComponent,
    showJustification,
    qualityWarning,
    showTLDRPopUp,
    setShowTLDRPopUp,
    onShowReportPopUp,
  } = props;

  const onViewableItemsChanged = ({ viewableItems, changed }) => {
    clog("VIEWABLE ITEMS", viewableItems);
    clog("CHANGED", changed);
    let cleanChanged = [];
    changed.forEach((change) => {
      if (change.key != "fake") {
        cleanChanged.push(change);
      } else {
        if (change.isViewable && onLastItemVisible) {
          onLastItemVisible();
        }
      }
    });
    trackViews("Url", cleanChanged, myContext, origin);
    if (qualityWarning) {
      let poorQuality = false;

      let { longVisit, totalVisit } = sessionQualityAssessment(myContext);
      clog("LONG", longVisit, "TOTAL", totalVisit);
      if (totalVisit >= 5) {
        if (
          longVisit / totalVisit <
          myContext.config.qualityAseessmentThreshold
        ) {
          poorQuality = true;
        }
      }
      if (poorQuality) {
        qualityWarning();
      }
    }
    //summarizeViews(myContext);
  };

  const viewabilityConfig = {
    waitForInteraction: false,
    // Exactly one of the viewAreaCoveragePercentThreshold or itemVisiblePercentThreshold is required.
    viewAreaCoveragePercentThreshold: 50,
    //itemVisiblePercentThreshold: 50,
  };

  const viewabilityConfigCallbackPairs = useRef([
    { viewabilityConfig, onViewableItemsChanged },
  ]);

  const uniquePanels = [];
  const windowDimension = Dimensions.get("window");
  screenWidth = windowDimension.width;

  // dedup panels
  let uniqueKeys = {};

  clog("asked to render", panels?.length, "panels", panels);
  const extractKey = (item) => {
    //clog("will extract key from", item);
    let key = "";
    if (!item.Id) {
      if (item.url) {
        key = item.url.Id;
      }
    } else {
      key = item.Id;
    }
    if (!key) {
      clog("ERROR: no key from", item);
    }
    //clog("KEY", key);
    return key;
  };

  for (const panel of panels) {
    let key = extractKey(panel);
    if (uniqueKeys[key] == null) {
      uniqueKeys[key] = 1;
      uniquePanels.push(panel);
    }
  }

  if (panels.length && !isExpanding && !isRefreshing) {
    uniquePanels.push({ Id: "fake" });
  }

  const renderItem = ({ item, index }) => {
    // find the curator closest to the reader
    let backgroundColor = myContext.gettingFeedData
      ? isExpanding
        ? colors.largeDivider
        : villageColors.Canary
      : colors.largeDivider;
    if (item.Id == "fake") {
      return (
        <View
          style={{
            height: 1,
            width: "100%",
            backgroundColor: backgroundColor,
          }}
        />
      );
    }
    clog("will render", item);
    if (item.special && !item.screenshotUrl) {
      let id = photoIdFromId(item.Id);
      let screenshotUrl = null;
      if (item?.photo) {
        if (myContext.presignedUrls[item.photo]) {
          screenshotUrl = myContext.presignedUrls[item.photo]?.uri;
        }
      }
      if (!screenshotUrl && item?.photoUrl) {
        screenshotUrl = item.photoUrl;
      }
      if (!screenshotUrl) {
        clog("ID for picsum", id);
        screenshotUrl = "https://picsum.photos/id/" + id + "/300/200";
      }
      if (item) {
        item.screenshotUrl = screenshotUrl;
      }
    }

    const onPress = () => {
      clog("Pressed on item with top action", item?.topActions?.[0]);
      let topAction = item?.justificationAction?.action;
      let topTarget = item?.justificationAction?.target;
      if (!item.topActions) {
        item["topActions"] = findTopActions(item, myContext, null);
        console.log("Updated top actions", item.topActions);
      }
      clog("URL DETAILS:", item);
      logEvent(
        origin == "myvillage"
          ? "MyVillage_Content"
          : origin == "discover"
          ? "Content"
          : "Feed_Search_Content",
        {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: origin,
          platform: Platform.OS,
          action: "click",
          target: "content",
          url: item?.uri,
          appVersion: myContext.appVersion,
        }
      );
      navigation.push("ItemDetails", {
        url: item,
        curator: myContext.users[topAction?.actorId],
        refererActionId: topAction?.Id,
        hostUserId: null,
        origin: origin,
      });
    };

    return (
      <View
        onLayout={(event) => {
          let { x, y, width, height } = event.nativeEvent.layout;
          clog("Item rendered at", index, y, "to", y + height);
        }}
        style={[{ marginBottom: 0 }, item.special && styles.containerItem]}
      >
        <View style={{ backgroundColor: colors.contentCardBackground }}>
          <Item
            item={item}
            state={state}
            myContext={myContext}
            screenWidth={screenWidth}
            navigation={navigation}
            origin={origin}
            contentModalFunction={contentModalFunction}
            index={index}
            compact={item.special == true}
            colors={colors}
            onPress={onPress}
            showJustification={showJustification}
            showTLDRPopUp={showTLDRPopUp}
            setShowTLDRPopUp={setShowTLDRPopUp}
            onShowReportPopUp={onShowReportPopUp}
          />

          <View
            style={[
              styles.footerItem,
              { backgroundColor: colors.contentCardBackground },
            ]}
            onLayout={(event) => {
              let { x, y, width, height } = event.nativeEvent.layout;
              clog("Item footer at", index, y, "to", y + height);
            }}
          >
            <ItemFooter
              url={item}
              myContext={myContext}
              navigation={navigation}
              setLoading={setLoading}
              showAlertMessage={showAlertMessage}
              origin={origin}
              forceRefresh={forceRefresh}
              colors={colors}
              context={"card"}
            />
          </View>

          <View
            style={{
              height: 8,
              backgroundColor: colors.largeDivider,
              width: "100%",
            }}
          />
        </View>
      </View>
    );
  };

  const SkeletonItem = () => {
    return (
      <View
        style={{
          marginBottom: 0,
          backgroundColor: colors.contentCardBackground,
        }}
      >
        <Skeleton
          style={{
            backgroundColor: "rgba(22, 22, 22, 0.1)",
            width: "100%",
            height: 180,
            overflow: "hidden",
          }}
        />

        <Skeleton
          style={{
            height: 20,
            width: "75%",
            marginTop: 16,
            marginLeft: 16,
            marginRight: 16,
            backgroundColor: "rgba(243, 241, 242, 0.7)",
          }}
        />

        <View
          style={{
            flexDirection: "row",
            marginTop: 15,
            marginLeft: 16,
          }}
        >
          <Skeleton
            style={{
              height: 36,
              width: 36,
              borderRadius: 25,
              backgroundColor: "rgba(243, 241, 242, 0.7)",
            }}
          />

          <View
            style={{
              flex: 1,
              marginLeft: 8,
            }}
          >
            <Skeleton
              style={{
                width: "35%",
                height: 15,
                backgroundColor: "rgba(243, 241, 242, 0.7)",
              }}
            />

            <Skeleton
              style={{
                marginTop: 6,
                width: "55%",
                height: 15,
                backgroundColor: "rgba(243, 241, 242, 0.7)",
              }}
            />
            <Skeleton
              style={{
                marginTop: 6,
                width: "95%",
                height: 40,
                borderRadius: 20,
                backgroundColor: "rgba(243, 241, 242, 0.7)",
              }}
            />
          </View>
        </View>

        <View
          style={[
            styles.footerContainer,
            { backgroundColor: colors.contentCardBackground },
          ]}
        >
          <View
            style={[
              styles.separatorHorizontal,
              { backgroundColor: colors.largeDivider },
            ]}
          />
          <View style={styles.footerItem}>
            <Skeleton
              style={{
                width: "55%",
                height: 15,
                backgroundColor: "rgba(243, 241, 242, 0.7)",
              }}
            />
          </View>
        </View>
      </View>
    );
  };

  const ListFooterComponent = ({ isExpanding, myContext, panels }) => {
    if (footer && !isExpanding && Platform.OS === "web") {
      if (
        myContext?.previousDiscoverDataset?.fetchTimeStamp > 0 &&
        panels?.length == myContext?.previousDiscoverDataset?.panels?.length
      ) {
        return footer;
      } else {
        return <View></View>;
      }
    } else {
      return <SkeletonItem />;
    }
  };

  const renderListEmptyComponent = () => {
    if (ListEmptyComponent) {
      if (
        origin === "search" ||
        (origin !== "search" &&
          myContext?.previousVillageDataset?.panels?.length == 0 &&
          myContext?.previousVillageDataset?.fetchTimeStamp > 0)
      ) {
        return <ListEmptyComponent />;
      } else {
        return (
          <View
            style={{
              flex: 1,
              backgroundColor: colors.background,
            }}
          />
        );
      }
    } else {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: colors.background,
          }}
        />
      );
    }
  };

  return (
    <Animated.FlatList
      showsVerticalScrollIndicator={false}
      style={styles.feed}
      title="Panels"
      data={uniquePanels}
      renderItem={renderItem}
      onLayout={(event) => {}}
      onEndReached={() => {
        clog("REACHED END");
        if (onEndReached) {
          onEndReached();
        } else {
          console.log("end reached");
        }
      }}
      keyExtractor={extractKey}
      extraData={state}
      refreshControl={refreshControl}
      initialNumToRender={3}
      windowSize={100}
      viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
      keyboardShouldPersistTaps="always"
      ListFooterComponent={
        !isExpanding && !footer ? (
          uniquePanels.length === 0 ? (
            <View />
          ) : (
            <View style={{ height: 60, backgroundColor: colors.background }} />
          )
        ) : (
          <ListFooterComponent
            isExpanding={isExpanding}
            myContext={myContext}
            panels={panels}
          />
        )
      }
      ref={ref}
      onEndReachedThreshold={
        onEndReachedThreshold ? onEndReachedThreshold : 1.3
      }
      scrollEventThrottle={1}
      onScroll={onScroll ? onScroll() : () => {}}
      onResponderRelease={onResponderRelease}
      contentContainerStyle={
        uniquePanels.length === 0 && !isRefreshing && { flexGrow: 1 }
      }
      ListEmptyComponent={renderListEmptyComponent()}
      bounces={uniquePanels.length !== 0}
    />
  );
};

let screenWidth = 0;

export default forwardRef(ItemList);

const styles = StyleSheet.create({
  feed: {
    //flex: 1,
    width: "100%",
  },
  containerItem: {
    borderRadius: 12,
    overflow: "hidden",
    marginLeft: 12,
    marginRight: 12,
  },
  separatorHorizontal: {
    marginTop: 16,
    marginBottom: 12,
    height: 1,
    marginLeft: 16,
    marginRight: 10,
    backgroundColor: "white",
    //opacity: 0.25,
  },
  footerContainer: {
    paddingBottom: 12,
  },
  footerItem: { marginLeft: 16, marginRight: 12, marginVertical: 16 },
});
