import React from "react";
import { StyleSheet, View, FlatList, Pressable } from "react-native";

import GroupCard from "./GroupCard";
import { clog } from "../utils/Log";

const GroupList = ({
  state,
  myContext,
  navigation,
  renderHeader,
  refererActionId,
  hostUserId,
  colors,
}) => {
  const renderItem = ({ item }) => {
    return (
      <View
        style={[styles.group, { backgroundColor: colors.secondaryBackground }]}
      >
        <View style={{ flex: 1, marginRight: 10 }}>
          <Pressable
            onPress={() => {
              clog("pressed button for", item);
              navigation.push("Group", {
                Id: item?.Id,
                owner: item?.curator,
                refererActionId: refererActionId,
                hostUserId: hostUserId,
              });
            }}
          >
            <GroupCard item={item} myContext={myContext} colors={colors} />
          </Pressable>
        </View>
      </View>
    );
  };

  const sharedKeyExtractor = (item) => item.Id;
  clog("state", state);
  return (
    <FlatList
      title={state?.relationship}
      data={state?.groups}
      renderItem={renderItem}
      keyExtractor={sharedKeyExtractor}
      extraData={state}
    />
  );
};

export default GroupList;

const styles = StyleSheet.create({
  group: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 16,
    paddingLeft: 20,
    paddingRight: 25,
    marginTop: 10,
    height: 69,
  },
});
