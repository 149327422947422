import { React, useRef, useState } from "react";
import {
  View,
  Text,
  Image,
  Pressable,
  Platform,
  Animated,
  Linking,
} from "react-native";

import share from "../assets/share.png";
import commentIcon from "../assets/comment.png";
import pin from "../assets/save.png";
import cancel from "../assets/cancel.png";
import check from "../assets/check.png";
import bookmarked from "../assets/bookmarked.png";
import pen from "../assets/pen.png";
import question from "../assets/question.png";
import { clog } from "../utils/Log";
import { computeCommenterMessage } from "../utils/Comment";
import { shareIfPossible } from "../utils/ShareOut";
import { deletePin, savePin } from "../controllers/PinController";
import { logEvent } from "../utils/LogEvent";
import { toggleLike } from "../utils/Like";
import heart from "../assets/heart.png";
import filledHeart from "../assets/filled_heart.png";
import * as Haptics from "expo-haptics";
import { visitsOfUrl } from "../controllers/UrlController";
import { useTheme } from "../theme";
import ShareSheet from "./ShareSheet";
import * as Clipboard from "expo-clipboard";

const IconWithCount = ({
  icon,
  iconOutline,
  iconColor,
  countArray,
  isActive,
  isExperiment,
  numObjectLike,
  onPressButton,
}) => {
  const { colors } = useTheme();

  //Count
  const animationCount = useRef(new Animated.Value(isActive ? 7 : -7)).current;
  const opacityCount = useRef(new Animated.Value(isActive ? 0 : 1)).current;
  const opacityCountBigger = useRef(
    new Animated.Value(isActive ? 1 : 0)
  ).current;

  //Icon
  const animationIcon = useRef(new Animated.Value(0)).current;
  const opacityIcon = useRef(new Animated.Value(isActive ? 0 : 3)).current;

  const scaleIcon = animationIcon.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.7],
  });
  const filledIconOpacity = opacityIcon.interpolate({
    inputRange: [0, 0.5, 1, 1.5, 3],
    outputRange: [1, 0.8, 0.6, 0.4, 0],
  });
  const outlineIconOpacity = opacityIcon.interpolate({
    inputRange: [0, 0.8, 0.9, 1],
    outputRange: [0, 0, 0, 1],
  });

  const onPressIn = () => {
    Animated.spring(animationIcon, {
      toValue: 1,
      useNativeDriver: false,
    }).start();
  };

  const onPressOut = () => {
    Animated.spring(animationIcon, {
      toValue: 0,
      friction: isActive ? 7 : 2,
      useNativeDriver: false,
    }).start();

    //Change opacity
    Animated.spring(opacityIcon, {
      toValue: isActive ? 3 : 0,
      useNativeDriver: false,
    }).start();
  };

  const onPress = () => {
    Animated.timing(animationCount, {
      toValue: isActive ? -7 : 7,
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityCount, {
      toValue: isActive ? 1 : 0,
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityCountBigger, {
      toValue: isActive ? 0 : 1,
      useNativeDriver: true,
    }).start();

    onPressButton();
  };

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Pressable
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        onPress={onPress}
      >
        <Animated.Image
          source={icon}
          style={[
            {
              height: 24,
              width: 24,
              tintColor: iconColor,
              opacity: filledIconOpacity,
              transform: [{ scale: scaleIcon }],
            },
          ]}
        />
        <Animated.Image
          source={iconOutline}
          style={[
            {
              position: "absolute",
              height: 24,
              width: 24,
              tintColor: colors.footerButtons,
              opacity: outlineIconOpacity,
              transform: [{ scale: scaleIcon }],
            },
          ]}
        />
      </Pressable>
      <Animated.View
        style={{
          position: "absolute",
          flexDirection: "column",
          left: isExperiment ? 24 : 28,
          transform: [{ translateY: animationCount }],
        }}
      >
        {countArray.map((num, index) => {
          return (
            <Animated.Text
              key={index}
              style={{
                color: colors.placeholderText,
                fontSize: isExperiment ? 10 : 12,
                fontWeight: isExperiment ? "400" : "700",
                opacity: index === 1 ? opacityCount : opacityCountBigger,
              }}
            >
              {isExperiment
                ? `${num === 0 ? " " : num}(${numObjectLike})`
                : `${num === 0 ? " " : num}`}
            </Animated.Text>
          );
        })}
      </Animated.View>
    </View>
  );
};

const ItemFooter = ({
  url,
  myContext,
  navigation,
  setLoading,
  showAlertMessage,
  origin,
  forceRefresh,
  colors,
  context,
}) => {
  clog("context in footer", context);
  async function saveAPin() {
    myContext["urlEngagedWith"] = url;
    let justificationTarget = url?.justificationAction?.target;
    savePin({
      url: url,
      curator: myContext.users[justificationTarget.curatorId],
      myContext: myContext,
      targetId: justificationTarget?.Id,
      callback: ({ success, message, error, newObject }) => {
        delete myContext.inflightSaves[url.Id];
        if (!success) {
          showAlertMessage(
            <Text style={{ color: colors.notificationText }}>{message}</Text>
          );
          // TODO(alpha): Update state and counters because of failure
        } else {
          showAlertMessage(
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Image
                source={check}
                style={{
                  height: 24,
                  width: 24,
                  tintColor: colors.notificationText,
                  marginRight: 4,
                }}
              />
              <Text style={{ fontSize: 16, color: colors.notificationText }}>
                Added to your{" "}
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "700",
                    color: colors.notificationText,
                  }}
                >
                  Saved collection
                </Text>
              </Text>
            </View>
          );
          /*url.pins.push(newObject);
          //selfPin = newObject;
          recomputePins();
          setHasBookmarked(true);
          setSharable(true);*/
          forceRefresh(url, () => {
            console.log("COMPLETED FORCED REFRESH");
            recomputePins();
          });
        }
        logEvent(
          origin == "myvillage" ? "MyVillage_Content_Save" : "Content_Save",
          {
            userId: myContext?.Id,
            username: myContext?.handle,
            category: origin,
            platform: Platform.OS,
            action: "click",
            target: "save",
            status: success ? "success" : "failure",
            appVersion: myContext.appVersion,
          }
        );
      },
    });
  }

  async function deleteAPin() {
    myContext["urlEngagedWith"] = url;
    clog("will try to delete", url);
    if (!bookmarkedPin?.list?.Id) {
      console.log("ERROR: Cannot find bookmarked pin list", bookmarkedPin);
      delete myContext.inflightSaves[url.Id];
      return;
    }
    deletePin({
      url: url,
      pin: bookmarkedPin,
      listId: bookmarkedPin.list.Id,
      listNumPins: 1,
      oldList: bookmarkedPin.list,
      myContext: myContext,
      batch: false,
      stateGetter: () => {
        return state;
      },
      stateSetter: setState,
      callback: ({ success, message, error }) => {
        delete myContext.inflightSaves[url.Id];
        if (!success) {
          // TODO(alpha): Update counters and state due to failure
          showAlertMessage(
            <Text style={{ color: colors.notificationText }}>{message}</Text>
          );
        } else {
          showAlertMessage(
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Image
                source={cancel}
                style={{
                  height: 24,
                  width: 24,
                  tintColor: colors.notificationText,
                  marginRight: 4,
                }}
              />
              <Text style={{ fontSize: 16, color: colors.notificationText }}>
                Removed from your{" "}
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "700",
                    color: colors.notificationText,
                  }}
                >
                  Saved collection
                </Text>
              </Text>
            </View>
          );
          /*url.pins = state.pins;
          recomputePins();
          if (selfPin == null) {
            setSharable(false);
          }
          setHasBookmarked(false);*/
          forceRefresh(url, () => {
            console.log("COMPLETED FORCED REFRESH");
            recomputePins();
          });
        }
      },
    });
  }

  const isRealUser = (userId) => {
    let user = myContext.users[userId];
    if (!user) {
      console.log("ERROR: cannot find user for Id", userId);
      return false;
    }
    return (
      !user.label?.match("test") &&
      !user.label?.match("insider") &&
      !user.handle?.match("test123")
    );
  };

  const getHandle = (userId) => {
    let user = myContext.users[userId];
    if (!user) {
      console.log("ERROR: cannot find user for Id", userId);
      return null;
    }
    return user.handle;
  };

  async function shareItem(
    handle,
    title,
    pinId,
    sharerId,
    sharerHandle,
    sharerName
  ) {
    let { success, message } = await shareIfPossible(
      "Item",
      handle,
      title,
      pinId,
      sharerId,
      sharerHandle,
      sharerName,
      showAlertMessage
    );

    if (!success) {
      showAlertMessage(message);
    }
    logEvent("Share_Content", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: "content",
      platform: Platform.OS,
      pin_id: pinId,
      action: "click",
      target: "share",
      status: success ? "success" : "failure",
      appVersion: myContext.appVersion,
    });
    shareRef.current?.onClose();
  }

  const showCopyAlertMessage = () => {
    if (showAlertMessage) {
      showAlertMessage(
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
            paddingHorizontal: 12,
          }}
        >
          <Image
            source={check}
            style={{
              height: 24,
              width: 24,
              tintColor: colors.primaryText,
              marginRight: 4,
            }}
          />
          <View style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <Text
              style={{
                fontSize: 15,
                fontWeight: "700",
                lineHeight: 22,
                color: colors.primaryText,
              }}
            >
              {"Link copied"}
            </Text>
          </View>
        </View>
      );
    }
  };

  const copyPostLink = async () => {
    let _url = "https://app.projectvillage.io/";
    _url = _url + "item/" + topPin.Id + "/" + myContext.Id;
    await Clipboard.setStringAsync(_url);
    shareRef.current?.onClose();

    showCopyAlertMessage();
  };

  const copyOriginalLink = async () => {
    await Clipboard.setStringAsync(url.uri);
    shareRef.current?.onCLose();

    showCopyAlertMessage();
  };

  const openInBrowser = () => {
    Linking.canOpenURL(url.uri)
      .then((supported) => {
        supported && Linking.openURL(url.uri);
      })
      .catch(() => console.log("Failed to open url " + url.uri));

    shareRef.current?.onClose();
  };

  const shareAction = (key) => {
    switch (key) {
      case "share":
        shareItem(
          myContext.users[topPin.curatorId].handle,
          url.title,
          topPin.Id,
          myContext.Id,
          myContext.handle,
          myContext.name
        );
        break;
      case "copy_link":
        copyPostLink();

        break;
      case "copy_content_link":
        copyOriginalLink();

        break;
      case "open_in_browser":
        openInBrowser();
    }
  };

  function recomputePins() {
    selfPin = null;
    bookmarkedPin = null;
    let pins = url?.pins?.items;
    if (!pins) {
      pins = url?.pins;
    }
    pins?.forEach((pin) => {
      if (pin?.curatorId == myContext.Id) {
        selfPin = pin;
        if (pin.list.name == "Saved") {
          bookmarkedPin = pin;
        }
      }
    });
    clog(
      "RECOMPUTED PINS",
      "self",
      selfPin,
      "bookmarked",
      bookmarkedPin,
      "URL",
      url
    );
  }

  //url.otherMessages = otherMessages;
  let selfPin = null;
  let bookmarkedPin = null;
  let bookmarkState = false;
  let pins = url?.pins?.items;
  if (!pins) {
    pins = url?.pins;
  }
  let numSaveComputed = 0;
  pins?.forEach((pin) => {
    if (pin?.curatorId == myContext.Id) {
      selfPin = pin;
      if (pin?.list?.name == "Saved") {
        bookmarkState = true;
        bookmarkedPin = pin;
      }
    }
  });
  let original = false;
  pins
    ?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
    .forEach((p) => {
      if (!original) {
        original = p;
      } else {
        if (p?.list?.name == "Saved") {
          numSaveComputed++;
        }
      }
    });

  clog("COMPUTED SELF PIN", selfPin, "from", url);
  let topActions = url?.topActions;
  let topAction = topActions?.[0]?.action;
  let topTarget = topActions?.[0]?.target;

  //return <View></View>;

  let topPin = null;
  url?.topActions?.forEach((a) => {
    if (a.action.objectType == "Pin") {
      if (!topPin) {
        topPin = a.target;
      }
    }
  });
  // TODO(alpha): Choose the closest pin

  if (!topPin) {
    url?.pins?.items?.forEach((p) => {
      topPin = p;
    });
  }
  clog("TOP PIN", topPin, "URL", url);
  let likedComputed = false;
  topPin?.actions?.items?.forEach((a) => {
    if (a.operation == "Like" && a.actorId == myContext.Id) {
      likedComputed = true;
    }
  });

  let numComment = 0;
  let numLikeComputed = 0;
  let likers = {};
  let numObjectiveLikeComputed = 0;
  url?.pins?.forEach((p) => {
    let actions = p?.actions?.items;
    if (!actions) {
      actions = p?.actions;
    }
    actions?.forEach((a) => {
      if (a.operation == "Like") {
        numLikeComputed++;
        if (isRealUser(a.actorId)) {
          numObjectiveLikeComputed++;
          likers[getHandle(a.actorId)] = true;
        }
      }
    });

    let comments = p?.comments?.items;
    if (!comments) {
      comments = p?.comments;
    }
    comments?.forEach((c) => {
      numComment++;
      let actions = c?.actions?.items;
      if (!actions) {
        actions = c?.actions;
      }
      actions?.forEach((a) => {
        if (a.operation == "Like") {
          numLikeComputed++;
          if (isRealUser(a.actorId)) {
            numObjectiveLikeComputed++;
            likers[getHandle(a.actorId)] = true;
          }
        }
      });
    });
  });
  url?.comments?.forEach((c) => {
    numComment++;
    let actions = c?.actions?.items;
    if (!actions) {
      actions = c?.actions;
    }
    actions?.forEach((a) => {
      if (a.operation == "Like") {
        numLikeComputed++;
        if (isRealUser(a.actorId)) {
          numObjectiveLikeComputed++;
          likers[getHandle(a.actorId)] = true;
        }
      }
    });
    let comments = c?.comments?.items;
    if (!comments) {
      comments = c?.comments;
    }
    comments?.forEach((c2) => {
      numComment++;
      let actions = c2?.actions?.items;
      if (!actions) {
        actions = c2?.actions;
      }
      actions?.forEach((a) => {
        if (a.operation == "Like") {
          numLikeComputed++;
          if (isRealUser(a.actorId)) {
            numObjectiveLikeComputed++;
            likers[getHandle(a.actorId)] = true;
          }
        }
      });
    });
  });

  // TODO(alpha): Consider updating with likes - low priority
  let likerListComputed = [];
  Object.keys(likers)
    .sort((a, b) => (a < b ? -1 : 1))
    .forEach((h) => {
      if (h) {
        likerListComputed.push(h);
      }
    });
  let commenterMessage = computeCommenterMessage(
    url?.pins,
    url?.comments,
    myContext
  );
  const [hasBookmarked, setHasBookmarked] = useState(bookmarkState);
  const [sharable, setSharable] = useState(true); // selfPin != null if only share own stuff
  const [state, setState] = useState({
    pins: url.pins,
    urls: [url],
    numPins: 1,
  });
  const [likerList, setLikerList] = useState(likerListComputed);
  const [numObjectLike, setNumObjectLike] = useState(numObjectiveLikeComputed);
  const [liked, setLiked] = useState(likedComputed);

  const [numLikes, setNumLikes] = useState(
    liked
      ? [numLikeComputed, numLikeComputed - 1]
      : [numLikeComputed + 1, numLikeComputed]
  );

  const [numBookmarks, setNumBookmarks] = useState(
    hasBookmarked
      ? [numSaveComputed, numSaveComputed - 1]
      : [numSaveComputed + 1, numSaveComputed]
  );

  const shareRef = useRef(null);

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <IconWithCount
          countArray={numLikes}
          isActive={liked}
          iconColor={colors.error}
          onPressButton={() => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            if (myContext?.inflightLikes[url.Id]) {
              console.log("WARNING!!! LIKE IS INFLIGHT");
              return;
            }
            myContext.inflightLikes[url.Id] = true;

            let currentLiked = liked;
            setLiked((prev) => {
              return !prev;
            });
            toggleLike({
              liked: currentLiked,
              objectType: "Pin",
              target: topPin,
              refererActionId: null,
              hostUserId: null,
              myContext: myContext,
              url: url,
              callback: ({ success, message, error }) => {
                //setLoading(false);
                delete myContext.inflightLikes[url.Id];
                if (success) {
                  forceRefresh(url, () => {
                    console.log("COMPLETED FORCED REFRESH");
                  });
                } else {
                  clog("Could not execute like");
                }
                logEvent(
                  origin == "myvillage"
                    ? "MyVillage_Content_Pin_Like"
                    : "Content_Pin_Like",
                  {
                    userId: myContext?.Id,
                    username: myContext?.handle,
                    category: origin,
                    platform: Platform.OS,
                    action: "click",
                    target: "like",
                    status: success ? "success" : "failure",
                    toggle: liked ? "off" : "on",
                    appVersion: myContext.appVersion,
                  }
                );
              },
            });
          }}
          icon={filledHeart}
          iconOutline={heart}
          isExperiment={
            myContext.actionsByUser?.["Experiment"]?.["Join"]?.["Explain"] !=
            null
          }
          numObjectLike={numObjectLike}
        />
        <Pressable
          onPress={() => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            let topAction = url?.justificationAction?.action;
            navigation.push("Comments", {
              url: url,
              curator: myContext.users[topAction?.actorId],
              refererActionId: topAction?.Id,
              hostUserId: null,
              origin: origin,
              notFolded: true,
            });
          }}
          style={{ marginLeft: 24, marginRight: 18 }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: 24,
                height: 24,
                justifyContent: "center",
                alignItems: "center",
                marginRight: 4,
              }}
            >
              <Image
                source={commentIcon}
                style={{
                  height: 24,
                  width: 24,
                  justifyContent: "center",
                  alignItems: "center",
                  tintColor: colors.footerButtons,
                }}
              />
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: colors.placeholderText,
                  alignContent: "center",
                  fontSize: 12,
                  fontWeight: "700",
                }}
              >
                {numComment > 0 ? numComment : ""}
              </Text>
            </View>
          </View>
        </Pressable>
        <IconWithCount
          countArray={numBookmarks}
          isActive={hasBookmarked}
          iconColor={colors.primaryButtonBackground}
          onPressButton={() => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            if (myContext?.inflightSaves[url.Id]) {
              console.log("WARNING!!! SAVE IS INFLIGHT");
              return;
            }
            myContext.inflightSaves[url.Id] = true;
            clog("asked to create pin");
            let currentlyBookmarked = hasBookmarked;

            setHasBookmarked((currentState) => {
              return !currentState;
            });
            if (!currentlyBookmarked) {
              saveAPin();
            } else {
              clog("SHOULD DELETE THIS PIN");
              deleteAPin();
            }
          }}
          icon={bookmarked}
          iconOutline={pin}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          //backgroundColor: "purple",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(myContext.actionsByUser?.["Experiment"]?.["Join"]?.["Explain"] !=
            null ||
            Platform.OS == "web") && (
            <Pressable
              onPress={() => {
                if (Platform.OS !== "web") {
                  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                }
                clog("asked to explain", url);
                visitsOfUrl({
                  Id: url.Id,
                  myContext: myContext,
                  callback: ({ success, message, error }) => {
                    clog("GOT SUCCESS", success, "with message", message);
                    if (success) {
                      let userTopics = {};
                      Object.keys(myContext.interestVector).forEach((tid) => {
                        userTopics[myContext.topics[tid].name] =
                          myContext.interestVector[tid];
                      });
                      let text = "";
                      if (url.explanation) {
                        text =
                          "score:" +
                          url.explanation.score +
                          "\n" +
                          "topic score:" +
                          url.explanation.topicScore.score +
                          "\n" +
                          "contributions:" +
                          JSON.stringify(
                            url.explanation.topicScore.components
                          ) +
                          "\n\n" +
                          "doc topics:" +
                          JSON.stringify(url.explanation.topicScore.docTopics) +
                          "\n\n" +
                          "composite score:" +
                          url.explanation.compositeScore +
                          "\n" +
                          "likers: " +
                          likerList.join(", ") +
                          "\n" +
                          "long visits: " +
                          (url.explanation.counters.numLongVisit
                            ? url.explanation.counters.numLongVisit
                            : 0) +
                          ": " +
                          message.longVisits.join(", ") +
                          "\n\n" +
                          "short visits: " +
                          (url.explanation.counters.numTotalVisit
                            ? url.explanation.counters.numTotalVisit -
                              url.explanation.counters.numLongVisit
                            : 0) +
                          ": " +
                          message.shortVisits.join(", ") +
                          "\n\n" +
                          "total visits: " +
                          (url.explanation.counters.numTotalVisit
                            ? url.explanation.counters.numTotalVisit
                            : 0) +
                          "\n\n" +
                          "long views: " +
                          (url.explanation.counters.numLongView
                            ? url.explanation.counters.numLongView
                            : 0) +
                          ": " +
                          message.longViews.join(", ") +
                          "\n" +
                          "short views: " +
                          (url.explanation.counters.numTotalView
                            ? url.explanation.counters.numTotalView -
                              url.explanation.counters.numLongView
                            : 0) +
                          ": " +
                          message.shortViews.join(", ") +
                          "\n" +
                          "total views: " +
                          (url.explanation.counters.numTotalView
                            ? url.explanation.counters.numTotalView
                            : 0) +
                          ": " +
                          "\n" +
                          "contributions\n" +
                          JSON.stringify(url.explanation.contributions) +
                          "\n\n" +
                          "counters\n" +
                          JSON.stringify(url.explanation.counters) +
                          "user interests:" +
                          JSON.stringify(userTopics) +
                          "\n\n";
                      } else {
                        text = "no explanation available";
                      }
                      alert(text);
                    }
                  },
                });
              }}
            >
              <View
                style={{
                  width: 24,
                  height: 24,
                  marginLeft: 16,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  source={question}
                  style={{
                    height: 17.05,
                    width: 17.55,
                    justifyContent: "center",
                    alignItems: "center",
                    tintColor: colors.placeholderText,
                  }}
                />
              </View>
            </Pressable>
          )}
          {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
            "UpdateUrlMetadata"
          ] != null && (
            <Pressable
              onPress={() => {
                if (Platform.OS !== "web") {
                  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                }
                clog("asked to edit url metadata");
                navigation.push("UrlMetadataUpdate", {
                  url: url,
                  topPin: topPin,
                });
              }}
            >
              <View
                style={{
                  width: 24,
                  height: 24,
                  marginLeft: 16,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  source={pen}
                  style={{
                    height: 17.05,
                    width: 17.55,
                    justifyContent: "center",
                    alignItems: "center",
                    tintColor: colors.footerButtons,
                  }}
                />
              </View>
            </Pressable>
          )}
          {sharable && topPin && (
            <Pressable
              onPress={() => {
                if (Platform.OS !== "web") {
                  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                }
                console.log("ASKED TO SHARE");
                shareRef.current?.onOpen();
              }}
            >
              <View
                style={{
                  width: 24,
                  height: 24,
                  marginLeft: 16,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  source={share}
                  style={{
                    height: 24,
                    width: 24,
                    justifyContent: "center",
                    alignItems: "center",
                    tintColor: colors.footerButtons,
                  }}
                />
              </View>
            </Pressable>
          )}
        </View>
      </View>
      <ShareSheet ref={shareRef} action={(key) => shareAction(key)} />
    </View>
  );
};

export default ItemFooter;
