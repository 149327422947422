import React, { useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  SafeAreaView,
  Image,
  TouchableOpacity,
} from "react-native";
import AppContext from "../components/AppContext";
import rightArrow from "../assets/right-arrow.png";
import LeftArrow from "../components/LeftArrow";
import { useTheme } from "../theme";

const AccountInformationScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const myContext = useContext(AppContext);
  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <TouchableOpacity onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </TouchableOpacity>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: "Account Information",
      headerTitleStyle: {
        fontSize: 15,
        fontWeight: "700",
        lineHeight: 22,
        color: colors.primaryText,
      },
    });
  }, [navigation, colors]);

  return (
    <SafeAreaView
      style={{
        backgroundColor: colors.background,
        flex: 1,
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <ScrollView style={styles.container}>
          <TouchableOpacity onPress={() => navigation.push("DeleteAccount")}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 15,
                  fontWeight: "700",
                  lineHeight: 22,
                }}
              >
                {"Delete Account"}
              </Text>
              <Image
                source={rightArrow}
                style={{ height: 12, width: 7, tintColor: colors.primaryText }}
              />
            </View>
          </TouchableOpacity>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default AccountInformationScreen;

const styles = StyleSheet.create({
  container: {
    marginLeft: 20,
    marginRight: 20,
  },
});
