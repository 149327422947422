import React, { useEffect, useState, useContext, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  FlatList,
  Platform,
  Pressable,
  Image,
  Dimensions,
  Modal,
} from "react-native";

import { API, graphqlOperation, Auth } from "aws-amplify";

import {
  getList,
  getListByCuratorIdAndListNameForSharing,
} from "../src/graphql/custom";
import AppContext from "../components/AppContext";
import SourceIcon from "../components/SourceIcon";
import AwesomeAlert from "react-native-awesome-alerts";
import Blocker from "../components/Blocker";
import { batchPresign } from "../utils/Photo";
import { findTopActions } from "../utils/TopActions";
import { timeStamp } from "../utils/TimeStamp";
import LeftArrow from "../components/LeftArrow";
import { photoIdFromId } from "../utils/RandomFromId";
import { clog } from "../utils/Log";
import { guestLogin, guestLogout } from "../controllers/AuthController";
import PinCurator from "../components/PinCurator";
import ConversionBanner from "../components/ConversionBanner";
import { useTheme } from "../theme";
import { adjustPinForRichShareNote } from "../utils/Compatibility";
import ShareNote from "../components/ShareNote";
import { constructRichShareNote } from "../utils/Compatibility";
import WebView from "react-native-webview";
import cross from "../assets/cross.png";
import { hashCode } from "../utils/Id";
import { getMinimalUserByHandle } from "../controllers/UserController";

const GuestContentListScreen = ({ route, navigation }) => {
  async function getData(Id, name, owner, handle, callback) {
    clog("asked to get CONTENTLIST data for ", Id, name, handle);
    if (!Id && !(name && handle)) {
      callback();
      return;
    }
    setLoading(true);
    try {
      let start = performance.now();
      let data = null;
      if (!Id) {
        let user = await getMinimalUserByHandle(handle);

        if (user?.Id) {
          let curatorId = user.Id;
          Id = curatorId;
          myContext.users[curatorId] = user;
          owner = user;
          const listData = await API.graphql(
            graphqlOperation(getListByCuratorIdAndListNameForSharing, {
              curatorId: curatorId,
              listName: name,
            })
          );
          clog("LIST DATA", listData);
          data = listData?.data?.listByListNameCuratorId?.items?.[0];
          clog("data is", data);
        }
      } else {
        const listData = await API.graphql(
          graphqlOperation(getList, { Id: Id })
        );
        clog("listData:", listData);
        data = listData?.data?.getList;
        if (data?.curator) {
          owner = data.curator;
          myContext.users[owner.Id] = owner;
        }
      }
      let fetchDone = performance.now();
      clog("TIME: fetch", (fetchDone - start) / 1000);

      if (data) {
        clog("data is ", data);
        let mayNeedLookUp = {};
        let pins = [];
        data?.pins?.items
          ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          ?.forEach((element) => {
            for (let index in element) {
              if (index.startsWith("num") && element[index] == null) {
                element[index] = 0;
              }
            }
            element["curator"] = owner;
            adjustPinForRichShareNote(element, myContext, mayNeedLookUp);
            pins.push(element);
          });
        let urls = [];
        data?.pins?.items?.forEach((element) => {
          let url = element.url;
          let pin = { ...element };
          // TODO(alpha): Probably should iterate over pins instead of data?.pins?,items to avoid second update
          adjustPinForRichShareNote(pin, myContext, mayNeedLookUp);
          pin["curator"] = owner;
          pin["curatorId"] = owner.Id;
          pin["url"] = null;
          url["comments"] = url?.comments?.items;
          url["pins"] = [pin];
          url["topActions"] = findTopActions(url, myContext, owner?.Id);
          url["justificationAction"] = url?.topActions?.[0];
          url["actionParams"] = owner?.Id;
          if (url?.topActions?.length > 0) {
            urls.push(url);
          }
          if (url?.photo) {
            mayNeedLookUp[url.photo] = true;
          }
          if (url?.source?.avatar) {
            mayNeedLookUp[url.source.avatar] = true;
          }
          url?.comments?.forEach((comment) => {
            if (comment?.curator?.avatar) {
              mayNeedLookUp[comment.curator.avatar] = true;
            }
          });
        });
        if (owner?.avatar) {
          mayNeedLookUp[owner.avatar] = true;
        }
        await batchPresign(
          Object.keys(mayNeedLookUp),
          myContext.presignedUrls,
          null
        );
        let annotationDone = performance.now();
        clog("TIME: annotation", (annotationDone - fetchDone) / 1000);

        fetchedList = {
          Id: Id,
          name: data?.name,
          owner: owner,
          description: data?.description,
          seq: data?.seq,
          nextPinSeq: data?.nextPinSeq,
          total: data?.numPins,
          pins: pins,
          urls: urls,
          topics: data?.topics?.items,
          topicIds: data?.topicIds,
          actions: data?.actions?.items,
        };
        callback();

        setState((currentState) => {
          ////clog("base state", currentState);
          return {
            ...currentState,
            Id: Id,
            name: data?.name,
            owner: owner,
            description: data?.description,
            seq: data?.seq,
            nextPinSeq: data?.nextPinSeq,
            total: data?.numPins,
            pins: pins,
            urls: urls,
            topics: data?.topics?.items,
            topicIds: data?.topicIds,
            actions: data?.actions?.items,
            fetchTimeStamp: timeStamp(),
            version: myContext.version,
          };
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      callback();
      console.log("error fetching list...", err);
    }
  }

  const sharedKeyExtractor = (item) => item.Id;

  const contentModalFunction = (target) => {
    clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  const renderItem = ({ item }) => {
    clog("rendering item", item);
    let id = photoIdFromId(item.Id);
    let screenshotUrlLocal = "https://picsum.photos/id/" + id + "/300/200";
    //let screenshotUrl = "https://picsum.photos/300/200";
    if (item) {
      if (item.photoUrl) {
        screenshotUrlLocal = item.photoUrl;
      } else if (item.photo) {
        if (myContext.presignedUrls[item.photo]) {
          screenshotUrlLocal = myContext.presignedUrls[item.photo].uri;
        }
      }
    }
    item.screenshotUrl = screenshotUrlLocal;
    ////clog("pressable item is", item);
    let topActions = item?.topActions;
    let topAction = topActions?.[0]?.action;
    let topTarget = topActions?.[0]?.target;
    let topTargetJSON = {};
    if (topTarget?.markup) {
      try {
        topTargetJSON = JSON.parse(topTarget?.content);
      } catch (err) {
        console.log("parse error", err);
        topTarget["content"] = "";
        topTarget.markup = false;
      }
    }

    return (
      <View>
        <Pressable
          onPress={() => {
            clickResponder(item);
          }}
        >
          <View
            style={{
              flexDirection: "column",
              marginLeft: 16,
              marginRight: 16,
              backgroundColor: colors.contentCardBackground,
              padding: 12,
              borderRadius: 8,
              marginBottom: 16,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View style={{ flex: 1 }}>
                <PinCurator
                  curatorId={topTarget.curatorId}
                  pin={topTarget}
                  myContext={myContext}
                  navigation={navigation}
                  refererActionId={null}
                  hostUserId={null}
                  origin={"guestCollection"}
                  skipCollection={true}
                  guest={true}
                  colors={colors}
                />
              </View>
            </View>

            {topTarget?.content != null &&
              topTarget?.content != "" &&
              (topTarget?.markup ? (
                <ShareNote
                  json={topTargetJSON}
                  colors={colors}
                  seed={hashCode(item.Id)}
                  item={item}
                  origin={origin}
                  myContext={myContext}
                  navigation={navigation}
                  contentModalFunction={contentModalFunction}
                />
              ) : (
                <ShareNote
                  json={constructRichShareNote(topTarget?.content)}
                  colors={colors}
                  seed={hashCode(item.Id)}
                  item={item}
                  origin={origin}
                  myContext={myContext}
                  navigation={navigation}
                  contentModalFunction={contentModalFunction}
                />
              ))}
            <View
              style={{
                marginTop: 12,
                flexDirection: "row",
                alignItems: "center",
                padding: 8,
                backgroundColor: colors.cardBackground,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: colors.smallDivider,
              }}
            >
              <View
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: 4,
                  marginRight: 8,
                }}
              >
                {item?.screenshotUrl && (
                  <Image
                    style={{
                      width: 48,
                      height: 48,
                      borderRadius: 4,
                      resizeMode: "cover",
                    }}
                    source={{
                      uri: item.screenshotUrl,
                    }}
                  />
                )}
              </View>
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 14,
                  flexWrap: "wrap",
                  flex: 1,
                }}
                numberOfLines={3}
                ellipsizeMode="tail"
              >
                {item.title}
              </Text>
            </View>
          </View>
        </Pressable>
      </View>
    );
  };

  const renderItem2 = ({ item }) => {
    ////clog("rendering item", item);
    let id = photoIdFromId(item.Id);
    let screenshotUrl = "https://picsum.photos/id/" + id + "/300/200";
    //let screenshotUrl = "https://picsum.photos/300/200";
    if (item) {
      if (item.photoUrl) {
        screenshotUrl = item.photoUrl;
      } else if (item.photo) {
        if (myContext.presignedUrls[item.photo]) {
          screenshotUrl = myContext.presignedUrls[item.photo].uri;
        }
      }
    }
    item.screenshotUrl = screenshotUrl;
    ////clog("pressable item is", item);
    let topActions = item?.topActions;
    let topAction = topActions?.[0]?.action;
    let topTarget = topActions?.[0]?.target;

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: colors.background,
          justifyContent: "space-between",
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 28,
        }}
      >
        <View style={{ flex: 1 }}>
          <Pressable
            onPress={() => {
              clog("pressed button for", item);
              navigation.push("GuestItemDetails", {
                url: item,
                curator: state.owner,
                refererActionId: null,
                hostUserId: state?.owner?.Id,
                fromList: true,
              });
            }}
          >
            <View style={styles.item}>
              <View
                style={{
                  height: 40,
                  width: 40,
                  borderRadius: 40,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SourceIcon
                  url={item.uri}
                  icon={
                    item?.source?.avatar
                      ? myContext.presignedUrls[item.source.avatar]
                        ? myContext.presignedUrls[item.source.avatar].uri
                        : item?.source?.avatarUrl
                      : item?.source?.avatarUrl
                  }
                  style={{
                    height: 40,
                    width: 40,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 40,
                    backgroundColor: "white",
                  }}
                  textstyle={{
                    fontSize: 16,
                    justifyContent: "center",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                ></SourceIcon>
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 9,
                  flexDirection: "column",
                }}
              >
                <Text
                  style={[styles.title, { color: colors.primaryText }]}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  {item.title}
                </Text>
                <View style={{ height: 2 }}></View>
                <Text
                  style={[styles.snippet, { color: colors.primaryText }]}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {item.author != null ? item.author.name : item.authorName}
                </Text>
              </View>
            </View>
          </Pressable>
        </View>
      </View>
    );
  };

  const clickResponder = (item) => {
    if (Platform.OS == "web") {
      clog("pressed button");
      window.open(item.uri, "_blank");
    } else {
      clog("Pressed button on non-web item");
    }
  };

  clog("navigation is in user details ", navigation);
  clog("route is ", route);
  let myContext = useContext(AppContext);
  clog("app context is", myContext);

  clog("Decoded name", decodeURIComponent(route.params.name));
  if (route?.params?.name) {
    route.params.name = decodeURIComponent(route.params.name);
  }
  const initialState = {
    Id: route?.params?.Id ? route.params.Id : "",
    name: route?.params?.name ? decodeURIComponent(route.params.name) : "",
    owner: route?.params?.owner ? route.params.owner : {},
    description: route?.params?.description ? route.params.description : "",
    pins: [],
    urls: [],
    topics: [],
    fetchTimeStamp: 0,
    version: 0,
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  const { colors } = useTheme();
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [screenshotUrl, setScreenshotUrl] = useState(
    route?.params?.screenshotUrl ? route.params.screenshotUrl : ""
  );
  const [loggedIn, setLoggedIn] = useState(false);

  const windowDim = Dimensions.get("window");
  const screenDim = Dimensions.get("screen");
  clog("ORIGINAL WINDOW", windowDim);
  clog("ORIGINAL SCREEN", screenDim);
  const [dimensions, setDimensions] = useState({
    window: windowDim,
    screen: screenDim,
  });
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");

  let fetchedList = null;

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        clog("NEW WINDOW", window);
        clog("NEW SCREEN", screen);
        setDimensions({ window, screen });
      }
    );
    return () => subscription?.remove();
  });

  clog("PARAMS", route.params);
  React.useLayoutEffect(() => {
    if (route.params.fromUser) {
      //if (route.params.Id) {
      //clog("DIMENSION IN HEADER", dimensions.window.width);
      let extraLeft =
        dimensions.window.width >= 556
          ? (dimensions.window.width - 556) / 2
          : 0;
      navigation.setOptions({
        headerShown: true,
        headerTransparent: true,
        headerStyle: {
          height: 66,
          //width: dimensions.window.width < 556 ? 100 : 556,
          //backgroundColor: "blue",
        },
        headerLeft: () => {
          return (
            <Pressable onPress={navigation.goBack}>
              <View
                style={{
                  marginLeft: 10 + extraLeft,
                  height: 32,
                  alignItems: "center",
                  flexDirection: "row",
                  backgroundColor: "rgba(0,0,0,0.45)",
                  width: 32,
                  borderRadius: 32,
                  overflow: "hidden",
                }}
              >
                <LeftArrow />
              </View>
            </Pressable>
          );
        },
        headerBackTitleVisible: false,
        headerTitle: "",
      });
      //}
    } else {
      navigation.setOptions({
        headerShown: false,
      });
    }
  }, [navigation, state, dimensions]);

  clog("Updated value of context", myContext);

  const getDataBasedOnAuthState = async (Id, name, owner, handle) => {
    try {
      let { username, attributes } = await Auth.currentAuthenticatedUser();
      clog("WELCOME User", username, "attributes", attributes);
      setLoggedIn(true);
      getData(Id, name, owner, handle, () => {
        clog("fetched list data");
      }).catch((err) => {
        console.log("error finding list data: ", err);
      });
    } catch (err) {
      console.log("ERROR IN WELCOME USER", err);
      guestLogin({
        myContext: myContext,
        callback: ({ success, message, error }) => {
          if (success) {
            getData(Id, name, owner, handle, () => {
              guestLogout({ myContext: myContext });
            }).catch((err) => {
              console.log("error finding list data: ", err);
            });
          } else {
            clog(message);
          }
        },
      });
    }
  };

  async function dataRefresh() {
    let { Id, name, owner, handle } = route.params;
    if (owner && !handle) {
      handle = owner;
    }
    clog("CLICKED ON TAB with", route.params);

    // do something
    if (
      stateRef.current.Id != Id ||
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef,
        "state Id",
        stateRef.current.Id,
        "Id",
        Id
      );
      getDataBasedOnAuthState(Id, name, owner, handle);
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
    }
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  ////clog("will populate page with", state);
  ////clog("viewer Id", myContext.Id, "owner Id", state.owner.Id);
  if (state.Id) {
    ////clog("REFRESH: viewer Id", myContext.Id, "owner Id", state.owner.Id);
    if (!screenshotUrl) {
      let screenshotUrlLocal = "";
      //let screenshotUrl = "https://picsum.photos/300/200";
      if (state.pins.length != 0) {
        let item = state.pins[0].url;
        if (item.photoUrl) {
          screenshotUrlLocal = item.photoUrl;
        } else if (item.photo) {
          if (myContext.presignedUrls[item.photo]) {
            screenshotUrlLocal = myContext.presignedUrls[item.photo].uri;
          }
        }
      }
      if (screenshotUrlLocal) {
        setScreenshotUrl(screenshotUrlLocal);
      }
    }
    return (
      <View style={styles.container}>
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          <ConversionBanner
            loggedIn={loggedIn}
            navbar={route?.params?.fromUser}
            sharerId={route?.params?.sharerId}
            objectType={"collection"}
            objectId={route?.params?.Id}
            colors={colors}
            myContext={myContext}
          />
          <Blocker loading={loading} />
          <View
            style={{
              height: 180,
              width: "100%",
            }}
          >
            {screenshotUrl != "" && (
              <Image
                style={{
                  width: "100%",
                  height: 180,
                  borderRadius: 4,
                  resizeMode: "cover",
                }}
                source={{
                  uri: screenshotUrl,
                }}
              />
            )}
            <View
              style={
                screenshotUrl != ""
                  ? {
                      position: "absolute",
                      top: 140,
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0,0,0,0.45)",
                      width: "100%",
                      height: 40,
                    }
                  : {
                      position: "absolute",
                      top: 140,
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      //backgroundColor: "rgba(0,0,0,0.45)",
                      width: "100%",
                      height: 40,
                    }
              }
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                  fontWeight: "700",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {state.name ? state.name : ""}
              </Text>
            </View>
          </View>
          <View style={{ marginBottom: 48 }}>
            <Text style={[styles.description, { color: colors.primaryText }]}>
              {state.description}
            </Text>
          </View>
          <FlatList
            style={styles.list}
            data={state.urls}
            renderItem={renderItem}
            keyExtractor={sharedKeyExtractor}
            extraData={state}
          />
          {Platform.OS == "web" && (
            <AwesomeAlert
              show={showInfo}
              showProgress={false}
              title=""
              message={infoMessage}
              closeOnTouchOutside={true}
              closeOnHardwareBackPress={false}
              showCancelButton={true}
              cancelText="Ok"
              cancelButtonColor={colors.cancelButton}
              onCancelPressed={() => {
                setShowInfo(false);
              }}
            />
          )}
          <Modal
            visible={webModalVisible}
            onRequestClose={() => {
              setWebModalVisible(false);
            }}
          >
            <SafeAreaView style={styles.container}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    backgroundColor: colors.background,
                    height: 39,
                    paddingVertical: 10,
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Pressable
                    onPress={() => {
                      setWebModalVisible(false);
                    }}
                  >
                    <Image
                      source={cross}
                      style={{
                        marginRight: 20,
                        height: 19,
                        width: 17,
                        tintColor: colors.primaryText,
                      }}
                    />
                  </Pressable>
                </View>
                <WebView
                  style={{ flex: 1 }}
                  source={{ uri: webModalUrl }}
                  ref={(r) => (webref = r)}
                  originWhitelist={["*"]}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                />
              </View>
            </SafeAreaView>
          </Modal>
        </View>
      </View>
    );
  } else {
    return (
      <SafeAreaView>
        <View>
          <Blocker loading={loading} />
        </View>
      </SafeAreaView>
    );
  }
};

export default GuestContentListScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  list: {
    width: "100%",
  },
  item: {
    flexDirection: "row",
  },
  title: {
    fontSize: 16,
  },
  snippet: {
    fontSize: 16,
    opacity: 0.4,
  },
  description: {
    fontSize: 14,
    alignSelf: "center",
    textAlign: "center",
    marginLeft: 57,
    marginRight: 57,
  },
});
