import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Animated,
  StyleSheet,
  Easing,
  Platform,
  RefreshControl,
  Text,
  Image,
  Pressable,
} from "react-native";
import LoadingDots from "./LoadingDots";
import { useTheme } from "../theme";
import * as Haptics from "expo-haptics";
import edit from "../assets/edit.png";

const PullToRefreshView = ({
  children,
  isRefreshing,
  scrollY,
  panels,
  onRefresh,
  listTranslate,
  openTopicSheet,
  origin,
}) => {
  const startRefreshing = 140;
  const LOADER_HEIGHT = 60;
  const TOPIC_BUTTON_HEIGHT = 60;

  const extraPaddingTop = useRef(new Animated.Value(0)).current;
  const [showMyTopics, setShowMyTopics] = useState(true);

  let offsetY = 0;

  const { colors } = useTheme();

  useEffect(() => {
    if (Platform.OS == "android") {
      return;
    }

    if (!isRefreshing) {
      Animated.timing(extraPaddingTop, {
        toValue: 0,
        duration: 400,
        easing: Easing.elastic(1.3),
        useNativeDriver: true,
      }).start();

      if (origin === "discover") {
        setTimeout(() => {
          setShowMyTopics(true);
        }, 500);
      }
    } else {
      if (origin === "discover") {
        setShowMyTopics(false);
      }
    }
  }, [isRefreshing]);

  const opacityTopic = scrollY.interpolate({
    inputRange: [-140, 0],
    outputRange: [0, 1],
    extrapolate: "clamp",
  });

  const onScroll = () =>
    Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
      listener: (event) => {
        offsetY = event.nativeEvent.contentOffset.y;

        if (origin === "discover") {
          if (
            event.nativeEvent.contentOffset.y < -TOPIC_BUTTON_HEIGHT &&
            !isRefreshing
          ) {
            Animated.timing(extraPaddingTop, {
              toValue: TOPIC_BUTTON_HEIGHT,
              duration: 300,
              useNativeDriver: true,
            }).start();
          }

          if (event.nativeEvent.contentOffset.y > 0 && !isRefreshing) {
            Animated.timing(extraPaddingTop, {
              toValue: 0,
              duration: 200,
              useNativeDriver: true,
            }).start();
          }
        }

        if (
          event.nativeEvent.contentOffset.y < -startRefreshing &&
          !isRefreshing
        ) {
          extraPaddingTop.setValue(LOADER_HEIGHT);

          if (Platform.OS !== "web") {
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
          }
          onRefresh();
        }
      },
      useNativeDriver: true,
    });

  const control = () => {
    return <RefreshControl onRefresh={onRefresh} refreshing={isRefreshing} />;
  };

  const childList = () => {
    if (Platform.OS === "android") {
      const child = React.cloneElement(children, {
        refreshControl: control(),
      });
      return child;
    }
    const child = React.cloneElement(children, {
      onScroll: onScroll,
    });
    return child;
  };

  return (
    <Animated.View
      style={[
        panels.length === 0 && isRefreshing && styles.loaderInTheCenter,
        {
          flex: 1,
          backgroundColor: colors.accent,
        },
        panels.length !== 0 && {
          transform: [
            {
              translateY: listTranslate,
            },
          ],
        },
      ]}
    >
      {isRefreshing && (
        <View
          style={[
            styles.loaderContainer,
            {
              height: LOADER_HEIGHT,
              backgroundColor: colors.accent,
            },
          ]}
        >
          <View
            style={{
              width: 35,
            }}
          >
            <LoadingDots />
          </View>
        </View>
      )}

      {showMyTopics && !isRefreshing && origin === "discover" && (
        <Animated.View
          style={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: TOPIC_BUTTON_HEIGHT,
            opacity: opacityTopic,
          }}
        >
          <Pressable
            style={{
              flexDirection: "row",
            }}
            onPress={openTopicSheet}
          >
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 15,
                lineHeight: 22,
                fontWeight: "700",
              }}
            >
              {"My topics"}
            </Text>
            <View style={{ marginLeft: 8 }}>
              <Image
                source={edit}
                style={{
                  height: 18,
                  width: 18,
                  tintColor: colors.primaryText,
                }}
              />
            </View>
          </Pressable>
        </Animated.View>
      )}

      <Animated.View
        style={{
          flex: 1,
          transform: [{ translateY: extraPaddingTop }],
        }}
      >
        {childList()}
      </Animated.View>
    </Animated.View>
  );
};

export default PullToRefreshView;

const styles = StyleSheet.create({
  loaderContainer: {
    position: "absolute",
    zIndex: 0,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderInTheCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  titleButton: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: "700",
  },
});
