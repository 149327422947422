import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  View,
  Text,
  Image,
  Pressable,
  StyleSheet,
  Platform,
  Animated,
  FlatList,
} from "react-native";

import { villageColors } from "../utils/SharedStyles";
import { clog } from "../utils/Log";
import ShareNote from "../components/ShareNote";
import { hashCode } from "../utils/Id";
import link from "../assets/link.png";
import tldr from "../assets/tldr.png";
import { logEvent } from "../utils/LogEvent";
import { Portal } from "react-native-portalize";
import { Modalize } from "react-native-modalize";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import CloseButton from "./CloseButton";

const TLDRSheet = (props, ref) => {
  const { topPin, myContext, origin, colors, url, openContent } = props;

  const initialData = [
    { name: "summary", title: "📖 TLDR" },
    { name: "mainInsights", title: "💡 Main insights" },
    { name: "quotes", title: "🎙️ Best quotes" },
    { name: "missingPoints", title: "🧩 Missing points" },
  ];

  let topPinJSON = {};
  topPinJSON = JSON.parse(topPin?.tldr);

  const data = initialData.filter((item) => {
    if (topPinJSON?.data[item.name] === null) {
      return false;
    } else if (
      topPinJSON?.data[item.name]?.data.length === 1 &&
      topPinJSON?.data[item.name]?.data[0].type === "text" &&
      !topPinJSON?.data[item.name]?.data[0].content
    ) {
      return false;
    }
    return true;
  });

  const animated = useRef(new Animated.Value(0)).current;
  const modalizeRef = useRef(null);

  const [floatingHeight, setFLoatingHeight] = useState(0);
  const [active, setActive] = useState(data[0]?.name);
  const insets = useSafeAreaInsets();

  const onOpen = () => {
    modalizeRef.current?.open();
  };

  const onClose = () => {
    modalizeRef.current?.close();
  };

  const opacityButton = animated.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    []
  );

  const renderItem = ({ item, index }) => {
    return (
      <Pressable
        onPress={() => {
          clog("ACTIVE TLDR", item.name);
          setActive(item.name);
          let urlToLog = url;
          if (urlToLog?.length > 100) {
            urlToLog = urlToLog.substring(0, 99);
          }
          logEvent(
            item.name == "summary"
              ? "TLDR_Summary"
              : item.name == "mainInsights"
              ? "TLDR_Insights"
              : item.name == "quotes"
              ? "TLDR_Quote"
              : item.name == "missingPoints"
              ? "TLDR_Missing"
              : "TLDR_Unknown",
            {
              userId: myContext?.Id,
              username: myContext?.handle,
              category: origin,
              platform: Platform.OS,
              action: "click",
              target: "content",
              url: urlToLog,
              appVersion: myContext.appVersion,
            }
          );
        }}
        style={[
          styles.tldrButton,
          index % 2 === 0 && { marginRight: 8 },
          active === item.name
            ? {
                backgroundColor: villageColors.Raven,
              }
            : {
                borderColor: villageColors.Raven15,
              },
        ]}
      >
        <Text
          style={[
            styles.titleButton,
            {
              color:
                active === item.name
                  ? villageColors.White
                  : villageColors.Raven,
            },
          ]}
        >
          {item.title}
        </Text>
      </Pressable>
    );
  };

  const HeaderComponent = () => {
    return (
      <View style={styles.headerContainer}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Image source={tldr} style={styles.tldrIcon} />
          <Text style={[styles.header, { color: colors.primaryText }]}>
            {"Explore with Puff"}
          </Text>
        </View>

        <CloseButton onPress={onClose} />
      </View>
    );
  };

  const FloatingComponent = () => {
    return (
      <Animated.View
        onLayout={(event) => {
          let { x, y, width, height } = event.nativeEvent.layout;
          console.log({ height });
          setFLoatingHeight(height);
        }}
        style={[
          styles.floatingContainer,
          {
            opacity: opacityButton,
            backgroundColor: colors.background,
          },
        ]}
      >
        <Pressable
          onPress={() => {
            onClose();
            openContent();
          }}
          style={[
            styles.readOriginalButton,
            { backgroundColor: colors.primaryButtonBackground },
          ]}
        >
          <View style={{ marginRight: 4 }}>
            <Image
              style={{
                width: 24,
                height: 24,
                resizeMode: "cover",
                tintColor: colors.primaryButtonText,
              }}
              source={link}
            />
          </View>
          <Text
            style={[
              styles.titleButton,
              {
                color: colors.primaryButtonText,
              },
            ]}
          >
            {"Read original article"}
          </Text>
        </Pressable>

        {data.length > 1 && (
          <FlatList
            data={data}
            renderItem={renderItem}
            numColumns={2}
            scrollEnabled={false}
          />
        )}

        <View style={{ height: insets.bottom ? insets.bottom : 10 }} />
      </Animated.View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        snapPoint={450}
        modalStyle={[
          styles.modalContainer,
          { backgroundColor: colors.background },
        ]}
        scrollViewProps={{ showsVerticalScrollIndicator: false }}
        withHandle={false}
        HeaderComponent={HeaderComponent}
        FloatingComponent={FloatingComponent}
        panGestureAnimatedValue={animated}
      >
        <ShareNote
          json={topPinJSON?.data[active]}
          colors={colors}
          myContext={myContext}
          seed={hashCode(topPin.Id)}
          item={topPin}
          origin={origin}
          showExpandedNote={true}
        />
        <View style={{ height: floatingHeight }} />
      </Modalize>
    </Portal>
  );
};

export default forwardRef(TLDRSheet);

const styles = StyleSheet.create({
  tldrIcon: {
    height: 44,
    width: 44,
    justifyContent: "center",
    alignItems: "center",
  },
  tldrButton: {
    width: 172,
    height: 54,
    borderWidth: 1,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
  },
  readOriginalButton: {
    height: 54,
    flexDirection: "row",
    borderWidth: 1,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  titleButton: {
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 22,
  },
  modalContainer: {
    flex: 1,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 19,
  },
  header: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 28,
    marginLeft: 12,
  },
  floatingContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
    zIndex: 9000,
    position: "absolute",
    width: "100%",
    bottom: 0,
  },
});
