import { API, graphqlOperation } from "aws-amplify";
import {
  getList,
  addList,
  removeList,
  updateListDetails,
  removeAction,
} from "../src/graphql/custom";
import {
  addActionByUser,
  removeActionByUser,
  performAction,
  undoAction,
} from "../utils/Action";
import { generateUniqueId } from "../utils/Id";
import { batchPresign } from "../utils/Photo";
import { findTopActions } from "../utils/TopActions";
import { timeStamp } from "../utils/TimeStamp";
import { batchLookupUsers } from "../utils/Cache";
import { clog, elog } from "../utils/Log";
import { deletePin } from "./PinController";
import { adjustPinForRichShareNote } from "../utils/Compatibility";
import { cleanObject } from "./CommonFeedController";

export async function getListData({
  Id,
  name,
  owner,
  myContext,
  stateGetter,
  stateSetter,
  callback,
}) {
  let status = { success: true };
  clog("asked to get CONTENTLIST data for ", Id);
  if (!Id) {
    status.success = false;
    status["message"] = "No Id specified";
    if (callback) {
      callback(status);
    }
    return status;
  }
  try {
    let start = performance.now();
    const listData = await API.graphql(graphqlOperation(getList, { Id: Id }));
    let fetchDone = performance.now();
    clog("TIME: fetch", (fetchDone - start) / 1000);
    clog("listData:", listData);
    if (listData?.data?.getList) {
      let mayNeedLookUp = {};
      const data = listData?.data?.getList;
      let duplicateData = { ...data };
      clog("data is ", JSON.stringify(duplicateData));
      let unknownUserIds = {};

      data?.pins?.items?.forEach((pin) => {
        if (!myContext.users[pin.curatorId]) {
          unknownUserIds[pin.curatorId] = true;
        }
        pin?.actions?.items?.forEach((action) => {
          if (!myContext.users[action.actorId]) {
            unknownUserIds[action.actorId] = true;
          }
        });
        if (pin.url) {
          pin.url = cleanObject(pin.url, myContext);
        }
        pin?.url?.comments?.items?.forEach((comment) => {
          if (!myContext.users[comment.curatorId]) {
            unknownUserIds[comment.curatorId] = true;
          }
          comment?.comments?.items.forEach((c2) => {
            if (!myContext.users[c2.curatorId]) {
              unknownUserIds[c2.curatorId] = true;
            }
          });
        });
        pin?.url?.pins?.items?.forEach((p1) => {
          adjustPinForRichShareNote(p1, myContext, mayNeedLookUp);
          if (!myContext.users[p1.curatorId]) {
            // not essential to fetch
            unknownUserIds[p1.curatorId] = true;
          }
          p1?.comments?.items.forEach((c2) => {
            if (!myContext.users[c2.curatorId]) {
              unknownUserIds[c2.curatorId] = true;
            }
          });
        });
      });

      await batchLookupUsers(
        Object.keys(unknownUserIds),
        myContext.users,
        null
      );
      Object.keys(unknownUserIds).forEach((Id) => {
        let user = myContext.users[Id];
        if (user) {
          mayNeedLookUp[user.avatar] = true;
        }
      });

      let pins = [];
      data?.pins?.items
        ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        ?.forEach((element) => {
          for (let index in element) {
            if (index.startsWith("num") && element[index] == null) {
              element[index] = 0;
            }
          }
          adjustPinForRichShareNote(element, myContext, mayNeedLookUp);
          pins.push(element);
        });
      let urls = [];
      data?.pins?.items?.forEach((element) => {
        let url = element.url;
        /*let pin = { ...element };
        pin["curator"] = owner;
        pin["curatorId"] = owner.Id;
        pin["url"] = null;
        url["comments"] = [];
        url["pins"] = [pin];*/
        url["pins"] = url?.pins?.items;
        url["comments"] = url?.comments?.items;
        url["topActions"] = findTopActions(url, myContext, owner?.Id);
        url["justificationAction"] = url?.topActions?.[0];
        url["actionParams"] = owner?.Id;
        if (url?.topActions?.length > 0) {
          urls.push(url);
        }
        if (url?.photo) {
          mayNeedLookUp[url.photo] = true;
        }
        if (url?.source?.avatar) {
          mayNeedLookUp[url.source.avatar] = true;
        }
      });

      await batchPresign(
        Object.keys(mayNeedLookUp),
        myContext.presignedUrls,
        null
      );

      let fetchedList = {
        Id: Id,
        name: data?.name,
        owner: owner,
        description: data?.description,
        seq: data?.seq,
        nextPinSeq: data?.nextPinSeq,
        numPins: data?.numPins,
        pins: pins,
        urls: urls,
        topics: data?.topics?.items,
        topicIds: data?.topicIds,
        actions: data?.actions?.items,
      };

      clog("WIll try to update CACHE");

      stateSetter((currentState) => {
        ////clog("base state", currentState);
        return {
          ...currentState,
          Id: Id,
          name: data?.name,
          owner: owner,
          description: data?.description,
          seq: data?.seq,
          nextPinSeq: data?.nextPinSeq,
          numPins: data?.numPins,
          pins: pins,
          urls: urls,
          topics: data?.topics?.items,
          topicIds: data?.topicIds,
          actions: data?.actions?.items,
          fetchTimeStamp: timeStamp(),
          version: myContext.version,
        };
      });
      status["fetchedListData"] = fetchedList;
      if (callback) {
        callback(status);
      }
      return status;
    } else {
      status.success = false;
      status["message"] = "No data found";
      if (callback) {
        callback(status);
      }
      return status;
    }
  } catch (err) {
    status.success = false;
    status["message"] = "Error fetching list data";
    status["error"] = err;
    console.log("error fetching list...", err);
    if (callback) {
      callback(status);
    }
    elog(myContext.Id, "content list", "data fetch", err.message);
    return status;
  }
}

export async function createList({ name, description, myContext, callback }) {
  let status = { success: true };
  let list = {};
  list["Id"] = generateUniqueId();
  if (name) {
    list["name"] = name;
  }
  if (description) {
    list["description"] = description;
  }
  list["creatorId"] = myContext.Id;
  list["curatorId"] = myContext.Id;
  list["seq"] = myContext.nextListSeq;

  try {
    let promises = [];
    ////clog("list data is ", list);
    promises.push(API.graphql(graphqlOperation(addList, list)));

    performAction({
      objectType: "List",
      operation: "Create",
      objectId: list.Id,
      actorId: myContext.Id,
      relationshipId: 0,
      objectCounter: 0,
      userCounter: myContext.numListCreate,
      curatorId: myContext.Id,
      curatorCounter: myContext.numCreateRecursive,
      promises: promises,
      payloads: [{ addList: list }],
      callback: (success, relationshipId, createdAt) => {
        if (success) {
          list["pins"] = { items: [] };
          list["topics"] = { items: [] };
          list["topicIds"] = "";
          list["nextPinSeq"] = 1;
          list["numView"] = 0;
          list["numLike"] = 0;
          list["numFollow"] = 0;
          list["numShare"] = 0;
          list["numComment"] = 0;
          list["numPins"] = 0;
          addActionByUser(
            myContext.actionsByUser,
            "List",
            "Create",
            list.Id,
            relationshipId,
            list,
            null,
            null,
            createdAt
          );
          myContext.numListCreate++;
          myContext.numCreateRecursive++;
          myContext.nextListSeq++;
          myContext.version++;
          clog("Will go back after change", myContext);
          status["listId"] = list.Id;
          status["list"] = list;
          if (callback) {
            callback(status);
          }
          return status;
        } else {
          status.success = false;
          status["message"] = "Failed to create Collection!";
          if (callback) {
            callback(status);
          }
          return status;
        }
      },
      refererActionId: null,
      hostUserId: null,
      seq: myContext.nextListSeq,
    });
  } catch (err) {
    console.log("Error", err);
    status.success = false;
    status["message"] = "Failed to create Collection!";
    if (callback) {
      callback(status);
    }
    return status;
  }
}

export async function deleteList({ oldList, myContext, callback }) {
  let status = { success: true };
  if (oldList.name == "Saved") {
    status.success = false;
    status["message"] = "Cannot delete this collection!";
    clog("Tried to deleted Saved collection");
    if (callback) {
      callback(status);
    }
    return status;
  }
  let refererActionId = null;
  try {
    let promises = [];
    let payloads = [];
    ////clog("list data is ", list);
    for (let i = 0; oldList.pins?.length && i < oldList.pins.length; i++) {
      let pin = oldList.pins[i];
      clog("DELETEPIN:", pin.url);
      await deletePin({
        url: pin.url,
        pin: pin,
        listId: oldList.Id,
        listNumPins: oldList.numPins,
        oldList: oldList,
        myContext: myContext,
        batch: true,
        stateGetter: () => {
          return oldList;
        },
        stateSetter: (param) => {
          if (typeof param == "function") {
            oldList = param(oldList);
          } else {
            oldList = param;
          }
        },
        callback: ({ success, message, error }) => {
          if (!success) {
            clog("CANNOT DELETE PIN pin");
          }
        },
      });
    }
    oldList.actions?.forEach((action) => {
      // leave the Create action to be cleared by undo action
      if (action.operation != "Create") {
        promises.push(
          API.graphql(
            graphqlOperation(removeAction, {
              Id: action.Id,
            })
          )
        );
        payloads.push({ removeAction: { Id: action.Id } });
      }
    });
    promises.push(
      API.graphql(graphqlOperation(removeList, { Id: oldList.Id }))
    );

    undoAction({
      objectType: "List",
      operation: "Create",
      objectId: oldList.Id,
      actorId: myContext.Id,
      // NOTE(alpha): accesses relationship Id
      relationshipId: Object.keys(
        myContext.actionsByUser?.["List"]?.["Create"]?.[oldList.Id]
      )?.[0],
      objectCounter: 0,
      userCounter: myContext.numListCreate,
      curatorId: myContext.Id,
      curatorCounter: myContext.numCreateRecursive,
      promises: promises,
      payloads: payloads,
      callback: (success, relationshipId) => {
        if (success) {
          removeActionByUser(
            myContext.actionsByUser,
            "List",
            "Create",
            oldList.Id,
            relationshipId,
            oldList
          );
          myContext.numListCreate--;
          myContext.version++;
          if (callback) {
            callback(status);
          }
          return status;
        } else {
          status.success = false;
          status["message"] = "Could not delete collection";
          if (callback) {
            callback(status);
          }
          return status;
        }
      },
      refererActionId: null,
      hostUserId: oldList.owner.Id,
      seq: null,
    });
  } catch (err) {
    console.log("error", err);
    status.success = false;
    status["message"] = "Could not delete collection";
    if (callback) {
      callback(status);
    }
    clog("error deleting list...", err);
    elog(myContext.Id, "content list", "deleting list", err.message);
    return status;
  }
}

export async function updateList({
  listId,
  name,
  description,
  myContext,
  callback,
}) {
  let status = { success: true };
  let list = {};
  list["Id"] = listId;
  if (name) {
    list["name"] = name;
  }
  if (description) {
    list["description"] = description;
  } else {
    list["description"] = "";
  }
  status["list"] = list;

  try {
    clog("list data is ", list);
    await API.graphql(graphqlOperation(updateListDetails, list));
    myContext.version++;
    if (callback) {
      callback(status);
    }
    return status;
  } catch (err) {
    status.success = false;
    status["message"] = "Failed to update Collection!";
    status["error"] = err;
    console.log("error updating list...", err);
    if (callback) {
      callback(status);
    }
    elog(myContext.Id, "content list update", "updating list", err.message);
    return status;
  }
}
