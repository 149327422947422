import uuid from "react-native-uuid";

export function generateUniqueId() {
  let Id = uuid.v4();
  return Id;
}

export function hashCode(str) {
  let chars = str?.split("");
  let hashString = 0;
  chars?.forEach((character) => {
    let charCode = character.charCodeAt(0);
    hashString = (hashString << 5) - hashString + charCode;
    hashString |= hashString;
  });
  return Math.abs(hashString);
}
