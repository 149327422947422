import { API, graphqlOperation } from "aws-amplify";

import {
  findUrl,
  addUrl,
  updateUrlForPinning,
  addPin,
  updateUserCounts,
  updateListCounts,
  updateTopicCounts,
  addSource,
  addError,
} from "../src/graphql/custom";

import { timeStamp } from "../utils/TimeStamp";
import { performAction } from "../utils/Action";
import { addIds } from "../utils/IdList";
import { clog, elog } from "../utils/Log";
import { generateUniqueId } from "../utils/Id";

export async function createPin({
  myContext,
  urlId,
  url,
  content,
  tldr,
  reaction,
  markup,
  title,
  type,
  author,
  snippet,
  photo,
  icon,
  siteName,
  curatorId,
  listId,
  listNumPins,
  oldList,
  topicsOfList,
  topicMap,
  topicOptions,
  pushNotificationWhenMentioned,
  uploadPhoto,
  callback,
}) {
  let pin = {};
  pin["Id"] = generateUniqueId();
  if (content) {
    pin["content"] = content;
  }
  if (tldr) {
    pin["tldr"] = tldr;
  }
  if (reaction) {
    pin["reaction"] = reaction;
  }
  if (markup) {
    pin["markup"] = markup;
  }
  pin["creatorId"] = curatorId;
  pin["curatorId"] = curatorId;

  if (!oldList) {
    if (myContext?.actionsByUser?.["List"]?.["Create"]) {
      // NOTE(alpha): Accesses object
      Object.values(myContext?.actionsByUser?.["List"]?.["Create"])?.map(
        (x) => {
          clog("considering", x);
          clog("values", Object.values(x));
          let list = Object.values(x)?.[0]?.object;
          if (list?.name == "Saved") {
            listId = list.Id;
            oldList = list;
          }
        }
      );
    }
  }

  pin["listId"] = listId;
  if (!oldList.nextPinSeq) {
    oldList.nextPinSeq = 1000;
  }
  pin["seq"] = oldList.nextPinSeq;
  let topicIds = "";
  let expandedMap = {};
  Object.keys(topicMap).forEach((t) => {
    expandedMap[t] = true;
    if (
      myContext?.topics?.[t]?.parentTopicId &&
      myContext.topics[t].parentTopicId != "Topic066"
    ) {
      expandedMap[myContext?.topics?.[t]?.parentTopicId] = true;
    }
  });

  let selectedTopicIds = Object.keys(expandedMap);
  if (selectedTopicIds.length) {
    topicIds = selectedTopicIds.join(",");
    console.log("selected topic ids", selectedTopicIds);
  }

  let urlObject = {};
  let promises = [];
  let payloads = [];
  try {
    let response = await API.graphql(graphqlOperation(findUrl, { uri: url }));
    let newUrl = false;
    if (response.data.byUrl.items.length == 1) {
      urlObject = response.data.byUrl.items[0];
      clog("FOUND URL", urlObject);
      // TODO(alpha): Create an updateUrl request to update searchable metadata and counters
      // update url counters
      let urlCounters = {};
      urlCounters["Id"] = urlObject["Id"];
      urlCounters["numContribute"] = urlObject.numContribute + 1;
      urlCounters["numPins"] = urlObject.numPins + 1;
      urlCounters["curatorIds"] = addIds(urlObject.curatorIds, [pin.curatorId]);
      urlCounters["listIds"] = addIds(urlObject.listIds, [pin.listId]);
      if (selectedTopicIds.length > 0) {
        urlCounters["topicIds"] = addIds(urlObject.topicIds, selectedTopicIds);
      }
      promises.push(
        API.graphql(graphqlOperation(updateUrlForPinning, urlCounters))
      );
      payloads.push({ updateUrlForPinning: urlCounters });
    } else if (response.data.byUrl.items.length == 0) {
      newUrl = true;

      urlObject = await saveUrl({
        myContext,
        url,
        urlObject,
        promises,
        payloads,
        uploadPhoto,
        urlId,
        title,
        type,
        author,
        snippet,
        photo,
        icon,
        siteName,
        curatorId,
        listId,
        topicIds,
      });

      topicOptions?.forEach((t) => {
        if (expandedMap[t.Id]) {
          let payload = {
            Id: t.Id,
            numUrls: t.numUrls + 1,
          };
          promises.push(
            API.graphql(graphqlOperation(updateTopicCounts, payload))
          );
          payloads.push({
            updateTopicCounts: payload,
          });
        }
      });
    } else {
      clog("ERROR: more than 1 url object matched", response);
      return {
        success: false,
        message: "more than 1 url object matched",
      };
    }
    // create the Pin
    pin["urlId"] = urlObject["Id"];
    clog("will create pin with", pin);
    promises.push(API.graphql(graphqlOperation(addPin, pin)));
    payloads.push({ addPin: pin });

    let existingListTopicIds = {};
    if (topicsOfList != null) {
      topicsOfList.forEach((e) => {
        if (e.topic != null) {
          existingListTopicIds[e.topic.Id] = 1;
        }
      });
    }
    let topicsToAddToList = [];
    let urlTopics = [];
    topicOptions?.forEach((element) => {
      if (expandedMap[element.Id]) {
        urlTopics.push(element.Id);
        if (!existingListTopicIds[element.Id]) {
          clog("UNCOVERED TOPIC", element);
          // create topic list
          topicsToAddToList.push(element);
        }
      }
    });
    let listPayload = {
      Id: listId,
      numPins: listNumPins + 1,
      topicIds: addIds(oldList.topicIds, urlTopics),
      nextPinSeq: oldList.nextPinSeq + 1,
    };
    promises.push(API.graphql(graphqlOperation(updateListCounts, listPayload)));
    payloads.push({ updateListCounts: listPayload });

    // update user stats
    ////clog("user Id", myContext.Id);
    let userPayload = {
      Id: curatorId,
      numPinCreate: myContext.numPinCreate + 1,
      numUrlContribute: myContext.numUrlContribute + 1,
      numCreateRecursive: myContext.numCreateRecursive + 1,
      topicIds: addIds(myContext.topicIds, urlTopics),
      curatedTopicIds: addIds(myContext.curatedTopicIds, urlTopics),
    };
    promises.push(API.graphql(graphqlOperation(updateUserCounts, userPayload)));
    payloads.push({ updateUserCounts: userPayload });

    let localCallback = (
      success,
      newRelationshipId,
      createdAt,
      responses,
      creationTS
    ) => {
      if (success) {
        myContext["numPinCreate"]++;
        myContext["numUrlContribute"]++;
        myContext["numCreateRecursive"]++;
        myContext["topicIds"] = addIds(myContext.topicIds, urlTopics);
        myContext["curatedTopicIds"] = addIds(
          myContext.curatedTopicIds,
          urlTopics
        );
        clog("PREVIOUS VERSION", myContext.version);
        myContext.version++;
        clog("UPDATING VERSION TO", myContext.version);
        pin.numView = 0;
        pin.numLike = 0;
        pin.numComment = 0;
        if (newUrl) {
          urlObject.numView = 0;
          urlObject.numShare = 0;
          urlObject.numComment = 0;
          urlObject.numPins = 1;
          urlObject.numContribute = 1;
        }
        pin.url = urlObject;
        if (oldList?.pins == null) {
          oldList.pins = {};
        }
        if (oldList.pins.items == null) {
          oldList.pins.items = [];
        }
        if (oldList?.pins?.items) {
          oldList.pins.items.push(pin);
        }
        if (oldList?.topics == null) {
          oldList.topics = {};
        }
        if (oldList.topics.items == null) {
          oldList.topics.items = [];
        }
        topicsToAddToList.forEach((e) => {
          oldList.topics.items.push({ topic: e });
        });
        oldList.topicIds = addIds(oldList.topicIds, urlTopics);
        oldList.nextPinSeq++;

        // find all the people mentioned in the comment
        if (pushNotificationWhenMentioned) {
          pushNotificationWhenMentioned(
            myContext,
            content,
            "Pin",
            newRelationshipId,
            title,
            urlId,
            pin.Id
          );
        }

        if (callback) {
          callback({ success: true });
        }
        return { success: true };
      } else {
        if (callback) {
          callback({ success: false, message: "Failed to create pin" });
        }
        return { success: false, message: "Failed to create pin" };
      }
    };
    // TODO(alpha): Add ACTION for create pin, url contribute
    performAction({
      objectType: "Pin",
      operation: "Create",
      objectId: pin.Id,
      actorId: myContext.Id,
      relationshipId: null,
      objectCounter: 0,
      userCounter: myContext.numPinCreate,
      curatorId: myContext.Id,
      curatorCounter: myContext.numCreateRecursive,
      promises: promises,
      payloads: payloads,
      callback: localCallback,
      actionOnly: true,
      refererActionId: null,
      hostUserId: null,
      seq: null,
    });
  } catch (err) {
    console.log("error", err);
    if (callback) {
      callback({
        success: false,
        message: "Failed to create pin",
        error: err,
      });
    }
    elog(
      myContext.Id,
      "create pin",
      "pin creation",
      err.message,
      urlId,
      url,
      content,
      tldr,
      title,
      type,
      author,
      snippet,
      photo,
      curatorId,
      listId,
      listNumPins,
      oldList,
      topicsOfList,
      topicMap,
      topicOptions
    );
    return { success: false, message: "Failed to create pin", error: err };
  }
}

export async function saveUrl({
  myContext,
  url,
  promises,
  payloads,
  uploadPhoto,
  urlId,
  title,
  type,
  author,
  snippet,
  photo,
  icon,
  siteName,
  curatorId,
  listId,
  topicIds,
}) {
  let urlObject;
  const hostPattern = /http.*\/\/(((www|vm)\.)?([^\/]*))/i;
  let matches = url.match(hostPattern);
  let host = "";
  if (matches) {
    //clog("matches", matches);
    host = matches[4].toLowerCase();
    // TODO(alpha): Modify logic to extract server name and then use that instead of regular expression match
    const hostPattern =
      /.*\.(mirror\.xyz|notion.site|medium.com|substack.com|a16z.com|spotify.com|fivethirtyeight.com|firstround.com|youtube.com)$/;
    let hostMatch = host.match(hostPattern);
    clog("host match", hostMatch);
    if (hostMatch) {
      host = hostMatch[1];
    }
  }
  // Now create the url object
  urlObject = {
    Id: urlId,
    creatorId: curatorId,
    curatorId: curatorId,
    uri: url,
    title: title,
    type: type,
    sourceId: host,
    duration: 0,
    authorId: "",
    authorName: author,
    photo: "",
    photoUrl: "",
    snippet: snippet,
    curatorIds: addIds(null, [curatorId]),
    topicIds: topicIds ? topicIds : "",
  };
  if (photo) {
    let success = null;
    let key = null;
    let response = await uploadPhoto("submission/", photo);
    success = response[0];
    key = response[1];
    ////clog("back from upload photo with", success, key, response);
    if (success && key) {
      urlObject["photo"] = key;
    } else {
      urlObject["photoUrl"] = photo;
    }
  }
  if (host && siteName && icon && !myContext?.sources?.[host]) {
    let success = null;
    let key = null;
    let response = await uploadPhoto("source/", icon);
    success = response[0];
    key = response[1];
    let sourceObject = {
      Id: host,
      curatorId: curatorId,
      enableJavascript: true,
      name: siteName,
      pattern: host,
    };
    clog("back from upload photo with", success, key, response, icon);
    if (success && key) {
      sourceObject["avatar"] = key;
    } else {
      sourceObject["avatarUrl"] = icon;
    }
    if (!myContext.sources) {
      myContext["sources"] = {};
    }
    myContext.sources[sourceObject.Id] = sourceObject;
    if (payloads) {
      payloads.push({ addSource: sourceObject });
    }
    try {
      await API.graphql(graphqlOperation(addSource, sourceObject));
    } catch (err) {
      console.log("cannot create source", sourceObject);
      let Id = generateUniqueId();
      let errorDetails = {
        Id: Id,
        log: JSON.stringify(sourceObject),
        operation: "addSource",
      };
      clog("ERROR DETAILS", errorDetails);
      try {
        let response = await API.graphql(
          graphqlOperation(addError, errorDetails)
        );
        console.log("log saving response", response);
      } catch (err) {
        console.log("Could not save error", err);
      }
    }
  }

  if (listId) {
    urlObject["listIds"] = addIds(null, [listId]);
  }

  urlObject["creationTS"] = timeStamp();
  urlObject["updateTS"] = urlObject["creationTS"];
  clog("urlObject", urlObject);
  promises.push(API.graphql(graphqlOperation(addUrl, urlObject)));

  if (payloads) {
    payloads.push({ addUrl: urlObject });
  }

  return urlObject;
}
