import React from "react";
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
  Animated,
} from "react-native";
import { BlurView } from "expo-blur";
import puffTLDR from "../assets/puff-tldr.png";
import cross from "../assets/cross.png";

const TopicPopUp = (props) => {
  const { colors, message, popUpTransformRef, openTopicSheet, closePopUp } =
    props;

  return (
    <Animated.View
      style={[
        styles.animatedContainer,
        {
          transform: [{ translateY: popUpTransformRef }],
        },
      ]}
    >
      <BlurView intensity={10}>
        <Pressable
          onPress={() => {
            openTopicSheet();
          }}
          style={[
            styles.container,
            {
              backgroundColor: colors.topicPopUpBackground,
            },
          ]}
        >
          <Image source={puffTLDR} style={{ height: 24, width: 24 }} />
          <View style={{ marginLeft: 6, flex: 1 }}>
            <Text
              style={{
                fontSize: 15,
                lineHeight: 22,
                color: colors.primaryButtonText,
              }}
            >
              {message}
              <Text style={{ fontWeight: "700" }}>{"Edit my topics"}</Text>
            </Text>
          </View>

          <Pressable
            onPress={() => {
              closePopUp();
            }}
            style={styles.crossButton}
          >
            <Image source={cross} style={{ height: 12, width: 12 }} />
          </Pressable>
        </Pressable>
      </BlurView>
    </Animated.View>
  );
};

export default TopicPopUp;

const styles = StyleSheet.create({
  animatedContainer: {
    position: "absolute",
    bottom: -35,
    width: "100%",
  },
  container: {
    flexDirection: "row",
    marginHorizontal: 16,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 16,
  },
  crossButton: {
    height: 24,
    width: 24,
    justifyContent: "center",
    alignItems: "center",
  },
});
