import {
  addActionByUser,
  removeActionByUser,
  performAction,
  undoAction,
} from "../utils/Action";
import { API, graphqlOperation } from "aws-amplify";
import { updateUrlCounts } from "../src/graphql/custom";
import { addIds, removeIds } from "../utils/IdList";
import { clog } from "../utils/Log";
import { sendPushNotification } from "../utils/Notification";
import { downgradePinForRichShareNote } from "./Compatibility";
import { createNotification } from "../controllers/NotificationController";

export async function toggleLike({
  liked,
  objectType,
  target,
  hostUserId,
  refererActionId,
  myContext,
  url,
  callback,
}) {
  const addObjectLiked = (
    objectId,
    objectType,
    relationshipId,
    object,
    createdAt
  ) => {
    ////clog("started liking", objectId, relationshipId);
    addActionByUser(
      myContext.actionsByUser,
      objectType,
      "Like",
      objectId,
      relationshipId,
      object,
      null,
      null,
      createdAt
    );
    let fieldName = "num" + objectType + "Like";
    myContext[fieldName]++;
    if (url?.topicIds) {
      myContext["engagedTopicIds"] = addIds(
        myContext.engagedTopicIds,
        url.topicIds.split(",")
      );
    }
    myContext["urlEngagedWith"] = url;
  };

  const removeObjectLiked = (objectId, objectType, relationshipId, object) => {
    clog("stopped liking", objectId, relationshipId);
    removeActionByUser(myContext.actionsByUser, objectType, "Like", objectId);
    let fieldName = "num" + objectType + "Like";
    myContext[fieldName]--;
    if (url?.topicIds) {
      myContext["engagedTopicIds"] = removeIds(
        myContext.engagedTopicIds,
        url.topicIds.split(",")
      );
    }
    myContext["urlEngagedWith"] = url;
  };

  clog("INCOMING", arguments);
  let relationshipId = null;
  if (myContext.actionsByUser?.[objectType]?.["Like"]?.[target.Id]) {
    relationshipId = Object.keys(
      myContext.actionsByUser[objectType]["Like"][target.Id]
    )[0];
  }
  let urlPayload = {
    Id: url.Id,
    likerIds: liked
      ? removeIds(url.likerIds, [myContext.Id])
      : addIds(url.likerIds, [myContext.Id]),
    numLike: liked
      ? url.numLike
        ? url.numLike - 1
        : 0
      : url.numLike
      ? url.numLike + 1
      : 1,
  };

  clog("Toggled like with underlying url", url);
  let fn = liked ? undoAction : performAction;
  let updatefn = liked ? removeObjectLiked : addObjectLiked;
  let localCallback = (
    success,
    modifiedRelationshipId,
    createdAt,
    responses,
    creationTS
  ) => {
    if (success) {
      if (liked) {
        target.numLike--;
      } else {
        target.numLike++;
      }
      url.numLike = urlPayload.numLike;
      url.likerIds = urlPayload.likerIds;
      updatefn(
        target.Id,
        objectType,
        modifiedRelationshipId,
        target,
        createdAt
      );
      if (callback) {
        callback({ success: true });
      }
      if (!liked) {
        console.log(
          "SHOULD NOTIFY",
          target.curatorId,
          "for like of",
          objectType,
          "by",
          myContext.handle
        );

        let data = {
          type: "Url",
          userId: myContext.Id,
          urlId: url.Id,
          action: "Like",
          target: objectType,
          recipientId: target.curatorId,
          actorId: myContext.Id,
          targetId: target.Id,
          targetType: objectType,
          operation: "Like",
          actionId: modifiedRelationshipId,
          creationTS: creationTS,
        };
        if (objectType == "Pin") {
          data["pinId"] = target.Id;
        } else if (objectType == "Comment") {
          data["commentId"] = target.Id;
        }

        createNotification(data);

        if (myContext?.users?.[target.curatorId]?.token) {
          console.log(
            "CAN NOTIFY",
            myContext?.users?.[target.curatorId]?.token
          );

          let text = target.content;
          if (objectType == "Pin" && target.markup) {
            text = downgradePinForRichShareNote(target);
          }
          sendPushNotification(
            myContext?.users?.[target.curatorId]?.token,
            "Village",
            "@" +
              myContext.handle +
              " " +
              "liked your " +
              (objectType == "Pin"
                ? text
                  ? "Pin: "
                  : "Pin on: "
                : "Comment: ") +
              (text ? text : url.title),
            data
          );
        }
      }
    } else {
      clog("failed to like", objectType);
      if (callback) {
        callback({ success: false, message: "failed to like " + objectType });
      }
    }
  };

  clog("url payload", urlPayload);
  fn({
    objectType: objectType,
    operation: "Like",
    objectId: target.Id,
    actorId: myContext.Id,
    relationshipId: relationshipId,
    objectCounter: target.numLike,
    userCounter: myContext["num" + objectType + "Like"],
    curatorId: target.curatorId,
    curatorCounter: 0,
    promises: [API.graphql(graphqlOperation(updateUrlCounts, urlPayload))],
    payloads: [{ updateUrlCounts: urlPayload }],
    callback: localCallback,
    refererActionId: refererActionId,
    hostUserId: hostUserId,
    seq: null,
    url: url,
  });
}
