import { Platform } from "react-native";
import { batchPresign, uploadPhoto } from "./Photo";
import { uploadPhotoWeb } from "./PhotoWeb";
import * as ImagePicker from "expo-image-picker";


export const hasCameraRollPermission = async (callback) => {
  //clog("will check media permissions");
  let permission = true;
  const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();
  if (status !== "granted") {
    if (Platform.OS === "ios") {
      try {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
          permission = false;
        } else {
          //clog("granted media permissions now!!");
          permission = true;
        }
      } catch (err) {
        console.log("permission check error");
        permission = false;
      }
    }
  } else {
    //clog("Media Permissions already granted");
    permission = true;
  }
  if (callback) {
    callback(permission);
  }
  return permission;
};

export const getLocalImage = async (myContext, callback) => {
  if (hasCameraRollPermission) {
    console.log("launching image picker");
    try {
      let _image = await ImagePicker.launchImageLibraryAsync({
        allowsMultipleSelection: true,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });
      if (!_image.cancelled) {
        const keys = [];
        let success = null;
        for (let index = 0; index < _image.selected.length; index++) {
          let key = null;
          let response;
          if (Platform.OS !== "web") {
            response = await uploadPhoto(
              "submission/",
              _image.selected[index].uri
            );
          } else {
            response = await uploadPhotoWeb(
              "submission/",
              _image.selected[index].uri
            );
          }

          success = response[0];
          key = response[1];
          if (success && key) {
            let mayNeedLookUp = [key];
            await batchPresign(mayNeedLookUp, myContext.presignedUrls, null);
          }
          console.log("response", response);
          keys.push(key);
        }

        if (callback) {
          callback(success, keys, "uploaded successfully");
        }
      } else {
        callback(false, "", "user cancelled upload");
      }
    } catch (err) {
      console.log("error", err);
    }
  } else {
    callback(false, "", "no camera roll permission");
  }
};
