import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Alert,
  AppState,
  Image,
  Keyboard,
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
  Modal,
  FlatList,
  Dimensions,
  ActivityIndicator,
  Animated,
} from "react-native";

import AwesomeAlert from "react-native-awesome-alerts";
import AppContext from "../components/AppContext";
import { sharedStyles, villageColors } from "../utils/SharedStyles";
import Blocker from "../components/Blocker";
import * as Clipboard from "expo-clipboard";
import barcode from "../assets/barcode.png";
import { generateUniqueId } from "../utils/Id";
import { initialUser } from "../utils/InitialValues";

import DOMParser from "react-native-html-parser";
import downArrow from "../assets/downArrow.png";
import cross from "../assets/cross.png";
import plus from "../assets/plus.png";
import ColoredButton from "../components/ColoredButton";
import check from "../assets/check.png";
import link from "../assets/link.png";
import edit from "../assets/edit.png";
import expand from "../assets/expand.png";
import backArrow from "../assets/back-arrow.png";

import { ScrollView } from "react-native-gesture-handler";
import QRScanner from "../utils/QRScanner";
import { clog } from "../utils/Log";
import { getSources } from "../controllers/SourceController";
import { getTopics } from "../controllers/TopicController";
import { getUserDetailsData } from "../controllers/UserController";
import EmojiSelector, { Categories } from "../utils/EmojiSelector";
import { photoIdFromId } from "../utils/RandomFromId";
import { createPin } from "../utils/CreatePin";
import { logEvent } from "../utils/LogEvent";
import { apiKeys } from "../src/constants/apiKeys";
import { useTheme } from "../theme";
import { fetchFollowersIfNecessary } from "../utils/DataFetcher";
import { pushNotificationWhenMentioned } from "../utils/Mention";
import { uploadPhotoWeb } from "../utils/PhotoWeb";
import { uploadPhoto } from "../utils/Photo";
import WebView from "react-native-webview";
import { KeyboardSpacer } from "../utils/Keyboard";
import TopicChooser from "../components/TopicChooser";
import { serializationDataOfTopics } from "../utils/SerializationDataOfTopics";
import ShareNoteEditor from "../components/ShareNoteEditor";
import Avatar from "../components/Avatar";
import { Modalize } from "react-native-modalize";
import { Portal } from "react-native-portalize";
import SourceIcon from "../components/SourceIcon";
import CloseButton from "../components/CloseButton";
import ButtonBar from "../components/ButtonBar";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  loadDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../utils/DataStorage";
import useKeyboardHeight from "../utils/useKeyboardHeight";
import LoadingDots from "../components/LoadingDots";
import AlertService from "../utils/AlertService";
import { makeText } from "../utils/ShareNote";

const Header = ({ colors, fullScreen, onPressBack, onFullView, onPost }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 12,
        paddingHorizontal: 12,
      }}
    >
      <View style={{ flex: 0.5 }}>
        <CloseButton onPress={onPressBack} />
      </View>

      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        {fullScreen ? (
          <Text
            style={{
              fontSize: 15,
              lineHeight: 22,
              fontWeight: "700",
              color: colors.primaryText,
            }}
          >
            {"New post"}
          </Text>
        ) : (
          <Pressable
            onPress={onFullView}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Text
              style={{
                fontSize: 15,
                lineHeight: 22,
                fontWeight: "700",
                color: colors.primaryText,
              }}
            >
              {"Full view"}
            </Text>
            <View style={{ marginLeft: 4 }}>
              <Image
                source={expand}
                style={{
                  width: 24,
                  height: 24,
                  tintColor: colors.primaryText,
                }}
              />
            </View>
          </Pressable>
        )}
      </View>

      <View style={{ flex: 0.5 }}>
        <Pressable onPress={onPost}>
          <View
            style={[
              styles.postButton,
              {
                backgroundColor: colors.primaryButtonBackground,
              },
            ]}
          >
            <Text
              style={[
                styles.postButtonTitle,
                {
                  color: colors.primaryButtonText,
                },
              ]}
            >
              {"Post"}
            </Text>
          </View>
        </Pressable>
      </View>
    </View>
  );
};

function CreatePinScreen({ route, navigation }) {
  async function getData(handle, Id) {
    let { success, message, error } = await getUserDetailsData(
      handle,
      Id,
      myContext,
      () => {
        return state;
      },
      setState
    );
    setRefreshing(false);
  }

  async function getTopicData() {
    if (myContext.topics) {
      serializationDataOfTopics({
        data: myContext.topics,
        onboarding: false,
        callback: (topics) => setTopicOptions(topics),
      });
    } else {
      await getTopics({
        myContext: myContext,
        callback: ({ success, message, error, topicData }) => {
          if (success) {
            serializationDataOfTopics({
              data: topicData,
              onboarding: false,
              callback: (topics) => setTopicOptions(topics),
            });
            clog("TOPIC OPTIONS", Object.values(topicData));
          } else {
            clog(message);
          }
        },
      });
    }
  }

  async function getSourceData() {
    if (!myContext.sources) {
      await getSources({
        myContext: myContext,
        callback: ({ success, message, error, sourceData }) => {
          if (success) {
            clog("fetched source data", sourceData);
          } else {
            clog(message);
          }
        },
      });
    }
  }

  const resetActiveButton = () => {
    setBold(false);
    setList(false);
    setQuote(false);
  };

  const clearAllData = () => {
    setUrlId("");
    setUrl("");
    setPlainText("");
    setReaction("");
    setIsRichShareNote(false);
    setRichShareNote(null);
    setTldrMainInsights(null);
    setTldrSummary(null);
    setTldrQuotes(null);
    setTldrMissingPoints(null);
    setTitle("");
    setSnippet("");
    setPhoto("");
    setType("");
    setAuthor("");
    setIcon("");
    setSiteName("");
    setHost("");
    setScreenshotUrl("");
    setTopicMap({});
    setCollection(null);
    setMetadataAvailable(false);
    resetActiveButton();
  };

  async function createAPin() {
    setLoading(true);
    await fetchFollowersIfNecessary(myContext);
    clog(
      "CREATING PIN",
      "listId",
      listId,
      "oldlist",
      myContext?.selectedList,
      "reaction",
      reaction,
      "markup",
      isRichShareNote,
      "richShareNote",
      richShareNote,
      "topics",
      topicMap
    );

    const tldr = getTLDR();

    createPin({
      myContext: myContext,
      urlId: urlId,
      url: url,
      content: richShareNote ? JSON.stringify(richShareNote) : "",
      tldr: tldr ? JSON.stringify(tldr) : null,
      markup: richShareNote ? true : false,
      reaction: reaction,
      title: title,
      type: type,
      author: author,
      snippet: snippet,
      photo: photo,
      icon: icon,
      siteName: siteName,
      curatorId: myContext.Id,
      listId: listId ? listId : myContext?.selectedList?.Id,
      listNumPins: listNumPins,
      oldList: myContext?.selectedList,
      topicsOfList: topicsOfList,
      topicMap: topicMap,
      topicOptions: topicOptions,
      uploadPhoto: async (prefix, imageURI) => {
        if (Platform.OS === "web") {
          return await uploadPhotoWeb(prefix, imageURI);
        } else {
          return await uploadPhoto(prefix, imageURI);
        }
      },
      pushNotificationWhenMentioned: pushNotificationWhenMentioned,
      callback: ({ success, message, error }) => {
        clog("BACK FROM PINNING", success, message, error);
        setLoading(false);
        logEvent("Pin_CreateContent", {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: category,
          platform: Platform.OS,
          target: "create content",
          action: "click",
          status: success ? "success" : "failure",
          appVersion: myContext.appVersion,
        });
        if (success) {
          clog("CONTEXT", myContext);
          myContext["newlyCreatedPin"] = true;
          clearAllData();
          navigation.goBack();
          AlertService.showDropDownAlert();
        } else {
          showMessage(message);
        }
      },
    });
  }

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const handleSubmit = () => {
    let message = "";
    if (!url) {
      message = "Url is required";
    }
    let duplicate = false;
    // check if the list already has the item
    urls?.forEach((u) => {
      clog("consider existing url", u);
      if (u?.uri == url) {
        duplicate = true;
      }
    });
    clog("duplicate", duplicate);
    if (duplicate) {
      message = "You have already created a pin with this url.";
    }
    //clog("message is", message);
    if (message) {
      showMessage(message);
    } else {
      //clog("would have created a pin if allowed");
      //return;
      createAPin();
    }
  };

  function normalizeUrl(url, hostPrefix) {
    let hostPattern = /^http/;
    let absolutePattern = /^\/[^\/]/;
    let missingProto = /^\/\//;
    if (!url.match(hostPattern)) {
      if (url.match(absolutePattern)) {
        return hostPrefix + url;
      } else if (url.match(missingProto)) {
        return "https:" + url;
      } else {
        return url;
      }
    } else {
      return url;
    }
  }

  function traverseDom(node, depth) {
    let pad = "";
    for (let i = 0; i < depth; i++) {
      pad += "   ";
    }
    let content = "";
    //console.log("VISITED with", node.nodeName);
    if (node.nodeName) {
      clog(
        pad +
          "name:" +
          node.nodeName +
          (node.nodeName == "#text" ? "\t" + node.textContent : "")
      );
      if (node.nodeName == "#text" && node.textContent) {
        let cleanText = node.textContent;
        const regex = /<[^<>]+>/gi;
        cleanText = cleanText.replace(regex, "");
        content = cleanText;
      }
      /*console.log(
        pad + "name:" + node.nodeName + ": " + node.textContent
          ? node.textContent
          : ""
      );*/
    }
    if (
      node &&
      ![
        "script",
        "style",
        "form",
        "label",
        "figcaption",
        "nav",
        "picture",
        "img",
        "svg",
      ].includes(node.nodeName)
    ) {
      //console.log("NODE", node);
      if (node?.childNodes) {
        Object.values(node.childNodes).forEach((cnode) => {
          let childContent = traverseDom(cnode, depth + 1);
          if (childContent) {
            content += " " + childContent;
          }
        });
      }
    }
    return content;
  }

  function extractMetadata(parsed, currentUrl) {
    let keys = [
      {
        value: "titleText",
        key: [
          "og:title",
          "twitter:title",
          "parsely-title",
          "sailthru.title",
          "nonmetaTitle",
          "bodyTitle",
        ],
      },
      {
        value: "descriptionText",
        key: [
          "og:description",
          "twitter:description",
          "sailthru.description",
          "description",
        ],
      },
      {
        value: "category",
        key: [
          "article:section",
          "parsely-section",
          "article.page",
          "article.section",
        ],
      },
      { value: "topics", key: ["lotame-bbg-topic"] },
      { value: "tag", key: ["article:tag"] },
      { value: "contentTier", key: ["article:content_tier", "article.access"] },
      { value: "opinion", key: ["article:opinion"] },
      {
        value: "image",
        key: [
          "og:image",
          "twitter:image",
          "og:image:secure_url",
          "parsely-image-url",
          "twitter:image:src",
          "thumbnail",
        ],
      },
      { value: "type", key: ["og:type", "parsely-type", "page.content.type"] },
      {
        value: "authorName",
        key: [
          "parsely-author",
          "sailthru.author",
          "Written by",
          "author",
          "byl",
        ],
      },
      {
        value: "authorProfile",
        key: ["article:author"],
      },
      {
        value: "userName",
        key: ["profile:username"],
      },
      {
        value: "firstName",
        key: ["profile:first_name"],
      },
      {
        value: "lastName",
        key: ["profile:last_name"],
      },
      {
        value: "keywordsText",
        key: ["keywords", "parsely-tags", "sailthru.tags", "news_keywords"],
      },
      { value: "canonicalUrl", key: ["og:url", "canonical", "parsely-link"] },
      { value: "duration", key: ["Est. reading time"] },
      { value: "wordCount", key: ["article:word_count"] },
      {
        value: "siteName",
        key: ["og:site_name"],
      },
      {
        value: "appName",
        key: [
          "application-name",
          "msapplication-tooltip",
          "twitter:app:name:iphone",
          "al:ios:app_name",
          "al:android:app_name",
          "twitter:app:name:googleplay",
          "al:iphone:app_name",
          "al:ipad:app_name",
        ],
      },
      { value: "siteTweet", key: ["twitter:site"] },
      {
        value: "publicationTime",
        key: [
          "article:published_time",
          "iso-8601-publish-date",
          "parsely-pub-date",
          "sailthru.date",
          "article.published",
          "article.created",
        ],
      },
      {
        value: "modificationTime",
        key: ["article:modified_time", "article.updated"],
      },
    ];

    let goodScriptKeys = {
      "application/ld+json": "class",
    };
    let goodLinkKeys = {
      canonical: "none",
      shortlink: "none",
      icon: "sizes",
      "apple-touch-icon": "sizes",
      "apple-touch-icon-precomposed": "sizes",
      "msapplication-TileImage": "sizes",
      "shortcut icon": "sizes",
    };
    let iconTupleKeys = ["msapplication-TileImage"];
    let iconLinkKeys = [
      "apple-touch-icon",
      "apple-touch-icon-precomposed",
      "icon",
      "shortcut icon",
    ];

    let gtuples = {};
    let gscripts = {};
    let glinks = {};
    let heads = parsed.getElementsByTagName("head");
    clog("HEADS length", heads?.length);
    let metas = null;
    let links = null;
    let scripts = null;
    if (heads && heads.length > 0) {
      let titles = heads[0].getElementsByTagName("title");
      if (titles && titles.length) {
        //clog("TITLE FIELD", titles[0]);
        let title = titles[0].textContent;
        //clog("TITLE", title);
        if (title) {
          gtuples["nonmetaTitle"] = title;
        }
      }
      metas = heads[0].getElementsByTagName("meta");
      links = heads[0].getElementsByTagName("link");
      scripts = heads[0].getElementsByTagName("script");
    }
    if (!metas?.length) {
      metas = parsed.getElementsByTagName("meta");
    }
    if (!links?.length) {
      links = parsed.getElementsByTagName("link");
    }
    if (!scripts?.length) {
      scripts = parsed.getElementsByTagName("script");
    }

    clog("META length", metas?.length);
    for (let i = 0; metas?.length && i < metas.length; i++) {
      let tuples = {};
      //clog("META", i + 1, metas[i]);
      for (let j = 0; j < metas[i].attributes.length; j++) {
        tuples[metas[i].attributes[j].name.toLowerCase()] =
          metas[i].attributes[j].value;
      }
      clog("META", i, "TUPLE", tuples);
      let key = null;
      if (tuples["name"]) {
        key = tuples["name"];
      } else if (tuples["property"]) {
        key = tuples["property"];
      } else if (tuples["http-equiv"]) {
        key = tuples["http-equiv"];
      } else {
        //clog("UNMANAGED KEY", tuples);
      }
      let value = null;
      if (tuples["content"]) {
        value = tuples["content"];
      } else {
        //clog("UNMANAGED VALUE", tuples);
      }
      if (key != null && value != null) {
        if (key == "byl") {
          const regex = /^By\s+/i;
          value = value.replace(regex, "");
        }
        clog("OUTCOME: KEY", key, "VALUE", value);
        if (gtuples[key]) {
          if (typeof gtuples[key] != "object") {
            gtuples[key] = [gtuples[key]];
          }
          gtuples[key].push(value);
        } else {
          gtuples[key] = value;
        }
      }
    }
    for (let i = 0; scripts && i < scripts.length; i++) {
      let tuples = {};
      //clog("SCRIPT", i + 1, scripts[i]);
      for (let j = 0; j < scripts[i].attributes.length; j++) {
        tuples[scripts[i].attributes[j].name.toLowerCase()] =
          scripts[i].attributes[j].value;
      }
      Object.keys(goodScriptKeys).forEach((key) => {
        //clog("KEY", key, "TUPLE", tuples);
        if (tuples.type == key) {
          //clog("GOOD KEY", key);
          let value = tuples[goodScriptKeys[key]];
          let payload = {
            content: scripts[i].textContent,
          };
          payload[goodScriptKeys[key]] = value ? value : "";
          if (gscripts[key]) {
            gscripts[key].push(payload);
          } else {
            gscripts[key] = [payload];
          }
        }
      });
    }
    for (let i = 0; links && i < links.length; i++) {
      let tuples = {};
      //clog("LINK", i + 1, links[i]);
      for (let j = 0; j < links[i].attributes.length; j++) {
        tuples[links[i].attributes[j].name.toLowerCase()] =
          links[i].attributes[j].value;
      }
      //clog("TUPLE", tuples);
      Object.keys(goodLinkKeys).forEach((key) => {
        //clog("KEY", key, "TUPLE", tuples);
        if (tuples.rel == key) {
          //clog("GOOD KEY", key);
          let value = tuples[goodLinkKeys[key]];
          let payload = {
            content: tuples.href,
          };
          payload[goodLinkKeys[key]] = value ? value : "";
          if (glinks[key]) {
            glinks[key].push(payload);
          } else {
            glinks[key] = [payload];
          }
        }
      });
    }
    let bodies = parsed.getElementsByTagName("body");
    if (bodies && bodies.length > 0) {
      for (let i = 0; i < bodies.length; i++) {
        //clog("BODY", i + 1);
      }
      let titles = bodies[0].getElementsByTagName("title");
      if (titles && titles.length) {
        //clog("TITLE FIELD", titles[0]);
        let title = titles[0].textContent;
        //clog("TITLE", title);
        if (title) {
          gtuples["bodyTitle"] = title;
        }
      }
    }
    clog("GLOBAL TUPLES", gtuples);
    clog("GLOBAL SCRIPTS", gscripts);
    clog("GLOBAL LINKS", glinks);

    // popupate iconImage from various options
    let icons = [];
    //let iconTupleKeys = ["msapplication-TileImage"];
    //let iconLinkKeys = ["apple-touch-icon", "icon"];
    const iconRegex = /(\d+)[xX](\d+)\.([A-Za-z]+)$/;
    const iconSuffixRegex = /\.([A-Za-z]+)$/;
    const iconSizeRegex = /^(\d+)[xX](\d+)$/;
    iconTupleKeys.forEach((key) => {
      let urls = [gtuples[key]];
      if (typeof gtuples[key] == "object") {
        urls = gtuples[key];
      }
      if (urls) {
        urls.forEach((url) => {
          if (url) {
            //clog("URL", url);
            let match = url.match(iconRegex);
            let width = 0;
            let height = 0;
            let suffix = "";
            //clog("MATCH", match, "from", url);
            if (match && match.length >= 4) {
              width = Number(match[1]);
              height = Number(match[2]);
              suffix = match[3];
            } else {
              match = url.match(iconSuffixRegex);
              //clog("SUFFIX MATCH", match, "from", url);
              if (match && match.length >= 2) {
                suffix = match[1];
              }
            }
            icons.push({
              key: key,
              width: width,
              height: height,
              suffix: suffix,
              url: url,
            });
          }
        });
      }
    });

    iconLinkKeys.forEach((key) => {
      let tuples = glinks[key];
      if (tuples) {
        tuples.forEach((tuple) => {
          let url = tuple.content;
          if (url) {
            //clog("ICON FROM LINK", url, tuple);
            let width = 0;
            let height = 0;
            let suffix = "";
            let match = url.match(iconRegex);
            if (match && match.length >= 4) {
              width = Number(match[1]);
              height = Number(match[2]);
              suffix = match[3];
            } else {
              match = url.match(iconSuffixRegex);
              //clog("SUFFIX MATCH", match, "from", url);
              if (match && match.length >= 2) {
                suffix = match[1];
              }
            }
            let sizes = tuple["sizes"];
            if (sizes) {
              match = sizes.match(iconSizeRegex);
              if (match && match.length == 3) {
                width = Number(match[1]);
                height = Number(match[2]);
              }
            }
            icons.push({
              key: key,
              width: width,
              height: height,
              suffix: suffix,
              url: url,
            });
          }
        });
      }
    });
    let sortedIcons = icons.sort((a, b) => {
      if (a.suffix != b.suffix) {
        return a.suffix == "png"
          ? -1
          : b.suffix == "png"
          ? 1
          : a.suffix < b.suffix;
      }
      if (a.width != b.width) {
        return a.width > b.width ? -1 : 1;
      }
    });
    clog("ICONS", icons);
    clog("SORTED ICONS", sortedIcons);
    if (sortedIcons.length > 0) {
      gtuples["iconImage"] = sortedIcons[0].url;
    }

    if (glinks["canonical"]) {
      gtuples["canonical"] = glinks["canonical"][0].content;
    }
    if (glinks["shortLink"]) {
      gtuples["shortLink"] = glinks["shortLink"][0].content;
    }

    if (gtuples["twitter:label1"] && gtuples["twitter:data1"]) {
      gtuples[gtuples["twitter:label1"]] = gtuples["twitter:data1"];
    }
    if (gtuples["twitter:label2"] && gtuples["twitter:data2"]) {
      gtuples[gtuples["twitter:label2"]] = gtuples["twitter:data2"];
    }

    keys.forEach((mapping) => {
      let targetName = mapping.value;
      mapping.key.forEach((key) => {
        if (gtuples[key] && !gtuples[targetName]) {
          gtuples[targetName] = gtuples[key];
        }
      });
    });

    let dups = {};
    let counts = {};
    Object.keys(gtuples).forEach((key) => {
      let value = gtuples[key];
      if (dups[value]) {
        dups[value].push(key);
        counts[value]++;
      } else {
        dups[value] = [key];
        counts[value] = 1;
      }
    });

    clog("DUPS", dups);

    Object.keys(dups)
      .sort((a, b) => (counts[a] > counts[b] ? -1 : 1))
      .forEach((key) => {
        clog(counts[key], key, dups[key]);
      });

    if (gtuples["type"]) {
      if (gtuples["type"] == "post") {
        gtuples["type"] = "article";
      }
    }
    if (gtuples["iconImage"]) {
      let hostPattern = /^http[^\/]+\/\/[^\/]+/;
      let match = currentUrl.match(hostPattern);
      if (match) {
        //clog("Host match", match);
        gtuples["iconImage"] = normalizeUrl(gtuples["iconImage"], match[0]);
      }
    }
    if (gtuples["image"]) {
      let hostPattern = /^http[^\/]+\/\/[^\/]+/;
      let image =
        typeof gtuples["image"] == "object"
          ? gtuples["image"][0]
          : gtuples["image"];

      let match = currentUrl.match(hostPattern);
      if (match) {
        //clog("Host match", match);
        let normalizedImage = normalizeUrl(image, match[0]);
        if (typeof gtuples["image"] == "object") {
          gtuples["image"][0] = normalizedImage;
        } else {
          gtuples["image"] = normalizedImage;
        }
      }
    }

    let tags =
      typeof gtuples["tag"] == "object" ? gtuples["tag"] : [gtuples["tag"]];

    let data = {
      title: gtuples["titleText"],
      description: gtuples["descriptionText"],
      keywords: gtuples["keywordsText"],
      image:
        typeof gtuples["image"] == "object"
          ? gtuples["image"][0]
          : gtuples["image"],
      icon: gtuples["iconImage"],
      author: gtuples["authorName"],
      duration: gtuples["duration"],
      canonical: gtuples["canonicalUrl"],
      type: gtuples["type"],
      site: gtuples["siteName"],
      siteTweet: gtuples["siteTweet"],
      publicationTime: gtuples["publicationTime"],
      modificationTime: gtuples["modificationTime"],
      category: gtuples["category"],
      topics: gtuples["topics"],
      tags: tags,
      contentTier: gtuples["contentTier"],
      opinion: gtuples["opinion"],
    };
    console.log("EXTRACTED", data);
    return data;
  }

  const populateMetadata = async (text) => {
    setLoadingMetadata(true);
    let id = generateUniqueId();
    setUrlId(id);
    if (!screenshotUrl) {
      let newUrl = "https://picsum.photos/id/" + photoIdFromId(id) + "/300/200";
      clog("NEW SCREENSHOTURL", newUrl);
      setScreenshotUrl(newUrl);
    }
    resetMetadata();
    let response = null;
    try {
      if (
        (Platform.OS == "ios" && text.match("youtube.com")) ||
        (Platform.OS == "android" && text.match("amazon.com"))
      ) {
        clog("will use proxy for youtube on iOS");
        // pick a random key
        let scrapingKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];
        let proxyUrl = `https://app.scrapingbee.com/api/v1/?api_key=${scrapingKey}&url=${encodeURIComponent(
          text
        )}`;
        clog("proxy url", proxyUrl);
        response = await fetch(proxyUrl);
      } else {
        response = await fetch(text);
      }
      clog("successfully fetched");
    } catch (err) {
      console.log("direct fetch failed");
      if (Platform.OS == "web") {
        console.log("will try proxy fetch");
        let scrapingKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];
        let proxyUrl = `https://app.scrapingbee.com/api/v1/?api_key=${scrapingKey}&url=${encodeURIComponent(
          text
        )}`;
        clog("proxy url", proxyUrl);
        try {
          response = await fetch(proxyUrl);
          clog("sucessfully fetched using proxy");
        } catch (err) {
          console.log("even proxy did not help with url fetch on web");
          response = null;
        }
      } else {
        clog("fetch failed on non-web platform");
        response = null;
      }
    }
    ////clog("fetch response", response);
    if (response != null) {
      try {
        let content = await response.text();
        //clog("content is", content);
        //clog("successfully fetched content");
        const parser = new DOMParser.DOMParser({
          locator: {},
          errorHandler: {
            warning: function (w) {},
            error: function (e) {},
            fatalError: function (e) {
              console.error(e);
            },
          },
        });
        //clog("GOT CONTENT", content);
        const parsed = parser.parseFromString(content, "text/html");
        //clog("PARSED", parsed);
        let cleanText = traverseDom(parsed, 0);
        const regex = /(\s*\n\s*)+/g;
        cleanText = cleanText.replace(regex, "\n");
        clog("CLEAN TEXT\n", cleanText);
        setPlainText(cleanText);
        let d = extractMetadata(parsed, text);
        const hostPattern = /http.*\/\/(((www|vm)\.)?([^\/]*))/i;
        let matches = url?.match(hostPattern);
        if (matches) {
          setHost(matches[4].toLowerCase());
        } else {
          setHost("");
        }

        clog("Received", d);
        if (d.title) {
          clog("Setting title");
          setTitle(d.title);
        }
        if (d.description) {
          setSnippet(d.description);
        }
        if (d.type) {
          if (d.type == "post") {
            d.type = "Article";
          }
          setType(d.type);
        }
        if (d.image) {
          clog("Setting image", d.image);
          setPhoto(d.image);
          setScreenshotUrl(d.image);
        }
        if (d.author) {
          setAuthor(d.author);
        }
        if (d.icon) {
          setIcon(d.icon);
        }
        if (d.site) {
          setSiteName(d.site);
        }
      } catch (err) {
        console.log("cannot get text", err);
      }
    }
    setMetadataAvailable(true);
    setLoadingMetadata(false);
    setShowLinkMessage(false);
  };

  const fetchCopiedText = async () => {
    console.log("WILL CHECK FOR COPIED TEXT");
    try {
      if (url === "") {
        const text = await Clipboard.getStringAsync();
        clog("clipboard has", text, "url", url);
        //clog("url is", url);
        if (text != null && text != "") {
          //clog("it might be useful");
          const hostPattern = /http.*\/\/((www\.)?([^\/]*))/;
          let matches = text.match(hostPattern);
          //clog("clipboard matches", matches);
          if (matches) {
            clog("success with url in clipboard", text);
            setUrl(text);
            setPlainText("");
            populateMetadata(
              text,
              setTitle,
              setSnippet,
              setType,
              setPhoto,
              setAuthor,
              setMetadataAvailable
            );
          }
        }
      }
    } catch (err) {
      console.log("permission check error", err);
    }
  };

  const resetMetadata = () => {
    setMetadataAvailable(false);
    setTitle("");
    setSnippet("");
    setPhoto("");
    setType("");
    setAuthor("");
    setIcon("");
    setSiteName("");
    setHost("");
  };

  const getSummary = async () => {
    setLoading(true);
    const prompt = `What are the top 3 main takeaways from this article ${url}? Make the results more comprehensive but under 500 words. Only show these 3 main takeaways, and remove the rest. Add a new section called TLDR as a new paragraph in the beginning. Add emojis to each section.`;
    clog("PROMPT", prompt);

    const body = {
      model: "gpt-3.5-turbo-instruct",
      prompt,
      temperature: 0.7,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    const bodyJSON = JSON.stringify(body);
    clog("BODY JSON", bodyJSON);

    const req = {
      headers: {
        accept: "text/json",
        "accept-language": "en-US,en;q=0.9,bn;q=0.8,es;q=0.7",
        Authorization:
          "Bearer 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",
        "content-type": "application/json",
      },
      body: bodyJSON,
      method: "POST",
    };

    clog("REQ", req);
    console.log("ASKING CHAT GPT FOR SUMMARY");

    try {
      const response = await fetch(
        "https://api.openai.com/v1/completions",
        req
      );
      console.log("SUMMARY DATA", response);
      const jsonString = await response.text();
      console.log("JSON", jsonString);

      try {
        const json = JSON.parse(jsonString);
        let newData = [];
        const text = makeText();

        if (json?.choices?.[0]?.text) {
          console.log("\nSummary\n" + json?.choices?.[0]?.text + "\n\n");

          const originalText = json?.choices?.[0]?.text;
          let modifiedText;
          if (
            originalText.startsWith("\n\n") ||
            originalText.startsWith("\n")
          ) {
            modifiedText = originalText.substring(2);
          } else {
            modifiedText = originalText;
          }

          newData.push({ ...text, content: modifiedText });
        } else if (json?.error?.message) {
          console.log("\nError:\n" + json?.error?.message + "\n\n");
          newData.push({
            ...text,
            content: "Error:\n" + json.error.message,
            data: [
              { content: "Error:\n", bold: true, type: "text" },
              { content: json.error.message, type: "text" },
            ],
          });
        }

        setTldrProperty(newData, "summary");
      } catch (err) {
        console.log("ERROR: cannot parse json string", err, jsonString);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const { colors } = useTheme();
  const [urlId, setUrlId] = useState();
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [typedShareNote, setTypedShareNote] = useState("");
  const [isRichShareNote, setIsRichShareNote] = useState(false);
  const [snippet, setSnippet] = useState("");
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");
  const [type, setType] = useState("");
  const [author, setAuthor] = useState("");
  const [icon, setIcon] = useState("");
  const [siteName, setSiteName] = useState("");
  const [host, setHost] = useState("");
  const [topicOptions, setTopicOptions] = useState(null);
  const [topicMap, setTopicMap] = useState({});
  const [metadataAvailable, setMetadataAvailable] = useState(false);
  const [plainText, setPlainText] = useState("");
  const [collection, setCollection] = useState(myContext?.selectedList);
  const [showLinkMessage, setShowLinkMessage] = useState(false);

  const [scanState, setScanState] = useState({
    scanned: true,
    hasPermission: Platform.OS == "android" ? false : false,
    type: null,
    data: null,
  });
  const [richShareNote, setRichShareNote] = useState(null);

  const [tldrQuotes, setTldrQuotes] = useState(null);
  const [tldrMainInsights, setTldrMainInsights] = useState(null);
  const [tldrSummary, setTldrSummary] = useState(null);
  const [tldrMissingPoints, setTldrMissingPoints] = useState(null);

  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");

  const [bold, setBold] = useState(false);
  const [list, setList] = useState(false);
  const [quote, setQuote] = useState(false);
  const [length, setLength] = useState(0);
  const [phase, setPhase] = useState(0);
  const [loadingMetadata, setLoadingMetadata] = useState(false);

  const { width, height } = Dimensions.get("window");
  const insets = useSafeAreaInsets();
  const keyboardHeight = useKeyboardHeight();

  const maxHeight = useRef(new Animated.Value(height * 0.25)).current;
  const arrowOpacityRef = useRef(new Animated.Value(0)).current;

  const [fullScreen, setFullScreen] = useState(false);
  const [showExpandPopUp, setShowExpandPopUp] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const shareNoteEditorRef = useRef();
  const collectionRef = useRef(null);
  const topicsRef = useRef(null);
  const reactionRef = useRef(null);
  const collectionAndTopicsRef = useRef(null);

  const getScanState = () => {
    return scanState;
  };

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  if (!myContext["selectedList"]) {
    myContext["selectedList"] = route?.params?.oldList;
  }

  const [showAlert, setShowAlert] = useState(false);
  const [showLinkAlert, setShowLinkAlert] = useState(false);
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const initialState = initialUser;
  const [state, setState] = useState(initialState);
  const [refreshing, setRefreshing] = useState(true);
  const [reaction, setReaction] = useState("");
  const [screenshotUrl, setScreenshotUrl] = useState("");
  const appState = useRef(AppState.currentState);

  const hasUnsavedChanges = Boolean(
    url ||
      plainText ||
      richShareNote ||
      tldrMainInsights ||
      tldrMissingPoints ||
      tldrQuotes ||
      tldrSummary
  );

  ////clog("navigation is in user details ", navigation);
  clog("route is ", route);
  //clog("context is ", myContext);
  let passedId = null;
  let listId = null;
  let listNumPins = null;
  let topicsOfList = null;
  let urls = null;
  let category = "";
  let object = {};

  const updateRecentCreation = () => {
    console.log("RECENTLY CREATED", myContext.recentlyCreatedList);
    if (myContext.recentlyCreatedList) {
      let list = myContext.recentlyCreatedList;
      myContext.recentlyCreatedList = null;
      myContext["selectedList"] = list;
    }
  };

  const keepBooks = () => {
    console.log("WILL KEEP BOOK");
    if (!url) {
      if (category == "main screen") {
        logEvent("Pin", {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: category,
          platform: Platform.OS,
          action: "click",
          target: "pin",
          appVersion: myContext.appVersion,
        });
      }
    }
    updateRecentCreation();
    fetchCopiedText();
  };

  if (route && route.params) {
    if (route.params.Id) {
      passedId = route.params.Id;
    }
    if (route.params.listId) {
      listId = route.params.listId;
    }
    if (route.params.listNumPins) {
      listNumPins = route.params.listNumPins;
    }
    if (route.params.listTopics) {
      topicsOfList = route.params.listTopics;
    }
    if (route.params.urls) {
      urls = route.params.urls;
    }
    if (route.params.category) {
      category = route.params.category;
    }
  }
  clog("OLD LIST", myContext?.selectedList);
  if (myContext) {
    if (!passedId) {
      passedId = myContext.Id;
    }
  }

  useEffect(() => {
    getTopicData();
    getSourceData();
  }, []);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        console.log("App has come to the foreground!");
        Platform.OS !== "web" && keepBooks();
      }

      appState.current = nextAppState;
      clog("AppState", appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    async function checkFirstLaunch() {
      const showedExpandPopupString = await loadDataFromLocalStorage(
        "showedExpandPopup"
      );

      const showedExpandPopup =
        showedExpandPopupString != null
          ? JSON.parse(showedExpandPopupString)
          : null;

      if (!showedExpandPopup) {
        await saveDataToLocalStorage("showedExpandPopup", JSON.stringify(true));
        setShowExpandPopUp(true);
      }
    }

    if (Platform.OS !== "web") {
      checkFirstLaunch();
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", keepBooks);
    return unsubscribe;
  }, [navigation, url]);

  useEffect(() => {
    onAnimatedHeader();
  }, [phase]);

  const setTldrProperty = (data, type) => {
    let note;

    if (data !== null) {
      note = {
        type: "Note",
        data: data,
        fallback: "",
      };
    } else {
      note = null;
    }

    switch (type) {
      case "summary":
        setTldrSummary(note);
        break;
      case "mainInsights":
        setTldrMainInsights(note);
        break;
      case "quotes":
        setTldrQuotes(note);
        break;
      case "missingPoints":
        setTldrMissingPoints(note);
        break;
    }
  };

  const getTLDR = () => {
    let _tldr;

    if (tldrMainInsights || tldrQuotes || tldrSummary || tldrMissingPoints) {
      _tldr = {
        type: "TLDR",
        data: {
          summary: tldrSummary,
          mainInsights: tldrMainInsights,
          missingPoints: tldrMissingPoints,
          quotes: tldrQuotes,
        },
      };
    } else {
      _tldr = null;
    }

    return _tldr;
  };

  const contentModalFunction = (target) => {
    clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  const renderShareNote = () => {
    return (
      <View>
        <>
          {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
            "BarcodeScanForCreatePin"
          ] != null && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 24,
                marginBottom: 8,
              }}
            >
              <Pressable
                onPress={() => {
                  clog("got pressed");
                  setScanState((oldValue) => {
                    return { ...oldValue, scanned: false };
                  });
                }}
              >
                <Image
                  source={barcode}
                  style={{ height: 30, width: 30, marginLeft: 20 }}
                />
              </Pressable>
            </View>
          )}
        </>

        {!metadataAvailable ? (
          loadingMetadata ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "flex-start",
                marginVertical: 24,
              }}
            >
              <View
                style={{
                  width: 35,
                }}
              >
                <LoadingDots color={colors.accent} />
              </View>
            </View>
          ) : (
            <View>
              <TextInput
                style={{
                  marginTop: 12,
                  fontSize: 15,
                  lineHeight: 22,
                  flex: 1,
                  color: colors.primaryText,
                }}
                placeholder={"Paste or type link"}
                placeholderTextColor={colors.placeholderText}
                value={url}
                maxLength={200}
                autoCapitalize={"none"}
                onChangeText={(newValue) => {
                  setUrl(newValue);
                  setPlainText("");

                  setRichShareNote(null);
                  resetMetadata();
                }}
                onBlur={() => {
                  console.log("blurred");
                  if (Platform.OS == "web") {
                    if (
                      !url.toLowerCase().startsWith("http://") &&
                      !url.toLowerCase().startsWith("https://")
                    ) {
                      showMessage("Url should start with http:// or https://");
                    } else {
                      // fetch content and populate metadata
                      populateMetadata(
                        url,
                        setTitle,
                        setSnippet,
                        setType,
                        setPhoto,
                        setAuthor,
                        setMetadataAvailable
                      );
                    }
                  }
                  Keyboard.dismiss();
                }}
                onEndEditing={() => {
                  console.log("finished editing url");
                  // fetch content and populate metadata
                  if (url) {
                    if (Platform.OS != "web") {
                      if (
                        !url.toLowerCase().startsWith("http://") &&
                        !url.toLowerCase().startsWith("https://")
                      ) {
                        showMessage(
                          "Url should start with http:// or https://"
                        );
                      } else {
                        populateMetadata(
                          url,
                          setTitle,
                          setSnippet,
                          setType,
                          setPhoto,
                          setAuthor,
                          setMetadataAvailable
                        );
                      }
                    }
                  }
                }}
              />
              {showLinkMessage && (
                <View style={{ marginTop: 8 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      lineHeight: 22,
                      color: colors.error,
                    }}
                  >
                    {"Your post must include a link"}
                  </Text>
                </View>
              )}
            </View>
          )
        ) : (
          <View>
            <View style={[styles.contentPreviewContainer, { marginTop: 12 }]}>
              <View style={{ flex: 1, marginRight: 8 }}>
                <Text
                  style={[
                    styles.title,
                    {
                      color: colors.primaryText,
                    },
                  ]}
                  numberOfLines={3}
                  ellipsizeMode={"tail"}
                >
                  {title}
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <SourceIcon
                    url={url}
                    icon={icon}
                    style={{
                      marginRight: 4,
                      height: 16,
                      width: 16,
                    }}
                    textstyle={{
                      fontSize: 16,
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                    }}
                    showText={false}
                  />

                  <Text
                    numberOfLines={1}
                    style={{
                      flexShrink: 1,
                      color: colors.placeholderText,
                      fontSize: 12,
                    }}
                  >
                    {host}
                  </Text>

                  {author != null && author != "" && (
                    <>
                      <Text
                        style={{
                          color: colors.placeholderText,
                          fontSize: 12,
                          marginHorizontal: 4,
                        }}
                      >
                        {"·"}
                      </Text>
                      <View
                        style={{
                          flexShrink: 1,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: colors.placeholderText,
                            fontSize: 12,
                          }}
                          numberOfLines={1}
                        >
                          {author?.toString()?.split(",")?.[0]}
                        </Text>
                      </View>
                    </>
                  )}
                </View>
              </View>
              <View
                style={{
                  alignSelf: "flex-start",
                  width: 100,
                  height: 68,
                }}
              >
                {screenshotUrl ? (
                  <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 8,
                      resizeMode: "cover",
                    }}
                    source={{
                      uri: screenshotUrl,
                    }}
                  />
                ) : (
                  <View
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 8,
                      backgroundColor: colors.contentScreenBackground,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{
                        width: 24,
                        height: 24,
                        resizeMode: "cover",
                      }}
                      source={link}
                    />
                  </View>
                )}
              </View>
            </View>
            <Pressable
              onPress={() => {
                if (Platform.OS !== "web") {
                  Alert.alert("Remove this link?", "", [
                    {
                      style: "cancel",
                      text: "Cancel",
                      onPress: () => {},
                    },
                    {
                      text: "Remove",
                      onPress: () => {
                        setUrl();
                        resetMetadata();
                      },
                    },
                  ]);
                } else {
                  setShowLinkAlert(true);
                }
              }}
              style={{
                position: "absolute",
                right: -10,
                height: 25,
                width: 25,
                borderRadius: 100,
                backgroundColor: colors.secondaryBackground,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={cross}
                style={{
                  height: 10,
                  width: 10,
                  tintColor: colors.primaryText,
                }}
              />
            </Pressable>

            <View
              style={{
                width: "100%",
                height: 1,
                backgroundColor: colors.smallDivider,
                marginTop: 24,
              }}
            />
          </View>
        )}

        {(fullScreen || (!fullScreen && metadataAvailable)) && (
          <ShareNoteEditor
            ref={shareNoteEditorRef}
            myContext={myContext}
            navigation={navigation}
            richShareNote={richShareNote}
            setRichShareNote={setRichShareNote}
            colors={colors}
            contentModalFunction={contentModalFunction}
            tldrSummary={tldrSummary}
            tldrMainInsights={tldrMainInsights}
            tldrQuotes={tldrQuotes}
            tldrMissingPoints={tldrMissingPoints}
            setTldrProperty={setTldrProperty}
            bold={bold}
            setBold={setBold}
            list={list}
            setList={setList}
            quote={quote}
            setQuote={setQuote}
            setLength={setLength}
            fullScreen={fullScreen}
            getSummary={getSummary}
            url={url}
          />
        )}
      </View>
    );
  };

  const onFullView = () => {
    Animated.timing(maxHeight, {
      toValue: height,
      duration: 500,
      useNativeDriver: false,
    }).start();
    setFullScreen(!fullScreen);
  };

  const handleHeightWindow = (w, h) => {
    const contentHeight = h + headerHeight;
    if (!fullScreen) {
      if (contentHeight > maxHeight.__getValue()) {
        if (contentHeight + keyboardHeight >= height) {
          onFullView();
        } else {
          if (Platform.OS !== "web") {
            Animated.timing(maxHeight, {
              toValue: contentHeight,
              duration: 100,
              useNativeDriver: false,
            }).start();
          } else {
            maxHeight.setValue(contentHeight);
          }
        }
      }
    }
  };

  const onAnimatedHeader = () => {
    if (phase === 0) {
      Animated.timing(arrowOpacityRef, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(arrowOpacityRef, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start();
    }
  };

  const renderCollection = () => (
    <>
      <Pressable
        onPress={() => {
          collectionRef.current?.close();
          navigation.push("ContentListUpdate", {
            Id: myContext.Id,
          });
        }}
      >
        <View
          style={{
            paddingHorizontal: 16,
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 19,
          }}
        >
          <View
            style={{
              height: 24,
              width: 24,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 3,
            }}
          >
            <Image
              source={plus}
              style={{
                height: 16,
                width: 16,
                tintColor: colors.primaryText,
              }}
            />
          </View>
          <Text
            style={{
              color: colors.primaryText,
              fontSize: 16,
              fontWeight: "700",
            }}
          >
            {"New collection"}
          </Text>
        </View>
      </Pressable>
      <FlatList
        data={state.lists}
        renderItem={({ item }) => {
          clog("RENDER COLLECTION", item);
          let photo = null;
          let i;

          for (i = 0; i < item?.pins?.items?.length && !photo; i++) {
            let url = item?.pins?.items?.[i]?.url;
            if (url) {
              if (url.photo && myContext.presignedUrls[url.photo]) {
                photo = myContext.presignedUrls[url.photo]?.uri;
              }
              if (!photo && url?.photoUrl) {
                photo = url.photoUrl;
              }
              if (photo) {
                break;
              }
            }
          }
          return (
            <Pressable
              onPress={() => {
                myContext["selectedList"] = item;
                setCollection(item);
                clog("NEW OLD LIST", item);
                collectionRef.current?.close();
              }}
            >
              <View
                style={[
                  styles.itemContainer,
                  (item.Id == collection?.Id ||
                    (!collection && item.name == "Saved")) && {
                    backgroundColor: colors.selectedBackground,
                  },
                ]}
              >
                {photo && (
                  <Image
                    source={{
                      uri: photo,
                      cache: "force-cache",
                    }}
                    style={{
                      width: 24,
                      height: 24,
                      resizeMode: "cover",
                      marginRight: 10,
                      borderRadius: 2,
                    }}
                  />
                )}
                {!photo && (
                  <View
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 10,
                    }}
                  />
                )}
                <Text
                  style={[
                    {
                      fontSize: 15,
                      color: colors.primaryText,
                      flexWrap: "wrap",
                    },
                    (item.Id == collection?.Id ||
                      (!collection && item.name == "Saved")) && {
                      fontWeight: "700",
                    },
                  ]}
                  numberOfLines={3}
                  ellipsizeMode="tail"
                >
                  {item.name}
                </Text>
                {(item.Id == collection?.Id ||
                  (!collection && item.name == "Saved")) && (
                  <View
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <Image
                      source={check}
                      style={{
                        height: 24,
                        width: 24,
                        tintColor: colors.primaryText,
                      }}
                    />
                  </View>
                )}
              </View>
            </Pressable>
          );
        }}
        keyExtractor={(item) => item?.Id}
        ListEmptyComponent={() => (
          <View
            style={{
              flex: 1,
            }}
          >
            <ActivityIndicator animating={refreshing} />
          </View>
        )}
      />
    </>
  );

  if (!passedId) {
    return <View></View>;
  } else {
    return (
      <View style={[sharedStyles.container]}>
        <View
          style={{
            flex: 1,
            width: "100%",
            maxWidth: 756,
          }}
        >
          {showExpandPopUp && (
            <Animated.View
              style={{
                maxWidth: 300,
                position: "absolute",
                backgroundColor: colors.link,
                alignSelf: "center",
                paddingVertical: 12,
                paddingHorizontal: 50,
                borderRadius: 16,
                bottom: maxHeight.__getValue() + 16,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: 15,
                  lineHeight: 22,
                  color: colors.primaryButtonText,
                }}
              >
                {"Expand to see more text formatting options"}
              </Text>
            </Animated.View>
          )}
          <Animated.View
            style={[
              styles.animatedView,
              {
                backgroundColor: colors.background,
                maxHeight: maxHeight,
              },
              !fullScreen && {
                borderTopLeftRadius: 32,
                borderTopRightRadius: 32,
              },
            ]}
          >
            <SafeAreaView />
            <View
              onLayout={(event) =>
                setHeaderHeight(event.nativeEvent.layout.height)
              }
            >
              <Header
                colors={colors}
                fullScreen={fullScreen}
                onPressBack={() => {
                  if (hasUnsavedChanges) {
                    if (Platform.OS == "web") {
                      setShowUnsavedChangesAlert(true);
                    } else {
                      Alert.alert(
                        "You have unsaved changes. Would you like to discard them?",
                        "",
                        [
                          {
                            text: "Continue my post",
                            style: "default",
                            onPress: () => {},
                          },
                          {
                            text: "Exit and discard changes",
                            style: "cancel",
                            onPress: () => {
                              navigation.goBack();
                            },
                          },
                        ]
                      );
                    }
                  } else {
                    navigation.goBack();
                  }
                }}
                onPost={() => {
                  if (metadataAvailable) {
                    if (fullScreen) {
                      handleSubmit();
                    } else {
                      collectionAndTopicsRef.current?.open();
                    }
                  } else {
                    setShowLinkMessage(true);
                    clog("metadata not available");
                  }
                }}
                onFullView={onFullView}
              />
            </View>
            <ScrollView
              onContentSizeChange={handleHeightWindow}
              bounces={false}
              nestedScrollEnabled={true}
              horizontal={false}
              showsVerticalScrollIndicator={false}
              style={{
                width: "100%",
                paddingLeft: 16,
                paddingRight: 16,
              }}
              keyboardDismissMode={Platform.OS == "ios" ? "on-drag" : "on-drag"}
              keyboardShouldPersistTaps={"always"}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 15,
                  marginBottom: 12,
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Avatar
                    item={myContext}
                    style={{
                      height: 28,
                      width: 28,
                      borderRadius: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: colors.primaryText,
                    }}
                    textstyle={{
                      fontSize: 14,
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                      color: colors.background,
                    }}
                    myContext={myContext}
                  />

                  <View
                    style={{
                      left: 22,
                      position: "absolute",
                    }}
                  >
                    <Pressable
                      onPress={() => reactionRef.current?.open()}
                      style={{
                        zIndex: 2000,
                        padding: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colors.secondaryBackground,
                        borderRadius: 100,
                      }}
                    >
                      {reaction != "" && (
                        <Text style={{ fontSize: 13 }}>{reaction}</Text>
                      )}
                      {reaction == "" && (
                        <Image
                          source={plus}
                          style={{
                            height: 13,
                            width: 13,
                            tintColor: colors.secondaryButtonText,
                          }}
                        />
                      )}
                    </Pressable>
                    <View
                      style={{
                        zIndex: 1000,
                        top: -7,
                        height: 10,
                        width: 10,
                        borderRadius: 100,
                        backgroundColor: colors.secondaryBackground,
                      }}
                    />
                  </View>
                </View>

                {fullScreen && (
                  <>
                    <Pressable
                      onPress={() => {
                        logEvent("Pin_collection", {
                          userId: myContext?.Id,
                          username: myContext?.handle,
                          category: category,
                          platform: Platform.OS,
                          action: "click",
                          target: "collection",
                          appVersion: myContext.appVersion,
                        });
                        //setCollectionModalVisible(true);
                        collectionRef.current?.open();
                        getData(myContext.handle, myContext.Id).catch((err) => {
                          console.log("error finding data: ", err);
                        });
                      }}
                      style={{
                        marginLeft: 26,
                        padding: 12,
                        backgroundColor: colors.secondaryBackground,
                        borderRadius: 100,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "700",
                          lineHeight: 15,
                          color: colors.secondaryButtonText,
                        }}
                      >
                        {collection ? collection.name : "Saved"}
                      </Text>
                      <Image
                        source={downArrow}
                        style={{
                          marginLeft: 7,
                          height: 5.5,
                          width: 9.5,
                          tintColor: colors.secondaryButtonText,
                        }}
                      />
                    </Pressable>

                    <Pressable
                      onPress={() => topicsRef.current?.open()}
                      style={{
                        marginLeft: 8,
                        padding: 12,
                        backgroundColor: colors.secondaryBackground,
                        borderRadius: 100,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {Object.keys(topicMap).length > 0 ? (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            source={edit}
                            style={{
                              marginRight: 5,
                              height: 12,
                              width: 12,
                              tintColor: colors.secondaryButtonText,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: "700",
                              lineHeight: 15,
                              color: colors.secondaryButtonText,
                            }}
                          >
                            {Object.keys(topicMap).length + " "}
                          </Text>
                        </View>
                      ) : (
                        <Image
                          source={plus}
                          style={{
                            marginRight: 7,
                            height: 12,
                            width: 12,
                            tintColor: colors.secondaryButtonText,
                          }}
                        />
                      )}
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "700",
                          lineHeight: 15,
                          color: colors.secondaryButtonText,
                        }}
                      >
                        {"Topics"}
                      </Text>
                    </Pressable>
                  </>
                )}
              </View>

              {renderShareNote()}
              <SafeAreaView />
            </ScrollView>

            <Blocker loading={loading} />
          </Animated.View>
        </View>

        {!scanState.scanned && (
          <QRScanner
            getter={getScanState}
            setter={setScanState}
            urlSetter={(u) => {
              myContext["creatingPin"] = true;
              setUrl(u);
              setPlainText("");
              populateMetadata(
                u,
                setTitle,
                setSnippet,
                setType,
                setPhoto,
                setAuthor,
                setMetadataAvailable
              );
            }}
          ></QRScanner>
        )}

        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title=""
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          cancelText="Ok"
          cancelButtonColor={colors.cancelButton}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
        />

        <AwesomeAlert
          show={showLinkAlert}
          showProgress={false}
          title={"Remove this link?"}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText={"Cancel"}
          confirmText={"Remove"}
          confirmButtonColor={colors.cancelButton}
          onConfirmPressed={() => {
            setUrl("");
            resetMetadata();
            setShowLinkAlert(false);
          }}
          onCancelPressed={() => {
            setShowLinkAlert(false);
          }}
          onDismiss={() => setShowLinkAlert(false)}
        />

        <AwesomeAlert
          show={showUnsavedChangesAlert}
          showProgress={false}
          message={"You have unsaved changes. Would you like to discard them?"}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText={"Exit and discard changes"}
          confirmText={"Continue my post"}
          confirmButtonColor={colors.cancelButton}
          onConfirmPressed={() => setShowUnsavedChangesAlert(false)}
          onCancelPressed={() => {
            clearAllData();
            setShowUnsavedChangesAlert(false);
            navigation.goBack();
          }}
          onDismiss={() => setShowUnsavedChangesAlert(false)}
        />

        <Portal>
          <Modalize
            ref={collectionRef}
            modalHeight={height * 0.5}
            modalStyle={[
              styles.viewModal,
              {
                backgroundColor: colors.background,
                paddingHorizontal: 0,
              },
            ]}
            scrollViewProps={{ showsVerticalScrollIndicator: false }}
            withHandle={false}
            onClose={() => {
              myContext.updatingCollections = false;
            }}
            HeaderComponent={() => (
              <View
                style={[
                  styles.headerModal,
                  {
                    justifyContent: "flex-end",
                    paddingHorizontal: 16,
                  },
                ]}
              >
                <CloseButton
                  onPress={() => {
                    myContext.updatingCollections = false;
                    collectionRef.current?.close();
                  }}
                />
              </View>
            )}
          >
            {renderCollection()}
          </Modalize>
        </Portal>

        <Portal>
          <Modalize
            ref={collectionAndTopicsRef}
            modalHeight={height * 0.5}
            modalStyle={[
              styles.viewModal,
              {
                paddingHorizontal: 0,
                backgroundColor: colors.background,
              },
            ]}
            scrollViewProps={{ showsVerticalScrollIndicator: false }}
            withHandle={false}
            onClose={() => {
              myContext.updatingCollections = false;
              setPhase(0);
            }}
            HeaderComponent={() => (
              <View
                style={[
                  styles.headerModal,
                  {
                    paddingHorizontal: 16,
                    justifyContent: "flex-end",
                    paddingBottom: 10,
                  },
                ]}
              >
                <Animated.View
                  style={{
                    position: "absolute",
                    opacity: arrowOpacityRef,
                    left: 16,
                  }}
                >
                  <Pressable onPress={() => setPhase(0)} disabled={phase !== 1}>
                    <Image
                      source={backArrow}
                      style={{ height: 24, width: 24 }}
                    />
                  </Pressable>
                </Animated.View>
                <CloseButton
                  onPress={() => {
                    collectionAndTopicsRef.current?.close();
                    setPhase(0);
                  }}
                />
              </View>
            )}
            FloatingComponent={
              phase === 0 && (
                <View
                  style={{
                    backgroundColor: colors.background,
                    paddingTop: 16,
                    paddingHorizontal: 16,
                  }}
                >
                  <ColoredButton
                    onPress={() => {
                      handleSubmit();
                      collectionAndTopicsRef.current?.close();
                    }}
                    buttonStyle={{
                      height: 54,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 100,
                      backgroundColor: colors.primaryButtonBackground,
                    }}
                    text={
                      Object.keys(topicMap).length > 0
                        ? "Post on Village"
                        : "Post without topic tags"
                    }
                    textStyle={{
                      color: colors.primaryButtonText,
                      fontSize: 15,
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  />
                  <View
                    style={{ height: insets.bottom ? insets.bottom : 10 }}
                  />
                </View>
              )
            }
          >
            {phase === 0 ? (
              <View style={{ paddingHorizontal: 16 }}>
                <View style={{ marginBottom: 24 }}>
                  <View style={{ marginBottom: 8 }}>
                    <Text
                      style={{
                        fontSize: 15,
                        fontWeight: "700",
                        lineHeight: 22,
                      }}
                    >
                      {"Collection"}
                    </Text>
                  </View>

                  <Pressable
                    onPress={() => {
                      setPhase(1);
                      getData(myContext.handle, myContext.Id).catch((err) => {
                        console.log("error finding data: ", err);
                      });
                    }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 24,
                      paddingVertical: 16,
                      borderWidth: 1,
                      borderColor: colors.smallDivider,
                      borderRadius: 100,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 15,
                        fontWeight: "700",
                        lineHeight: 22,
                        color: colors.primaryText,
                      }}
                    >
                      {collection ? collection.name : "Saved"}
                    </Text>
                    <View>
                      <Image
                        source={downArrow}
                        style={{
                          height: 8.5,
                          width: 12.5,
                          tintColor: colors.primaryText,
                        }}
                      />
                    </View>
                  </Pressable>
                </View>

                <View style={{ marginBottom: 8 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "700",
                      lineHeight: 22,
                    }}
                  >
                    {"Topic tags"}
                  </Text>
                </View>
                <TopicChooser
                  topics={topicOptions}
                  colors={colors}
                  followTopics={topicMap}
                  myContext={myContext}
                  onPressTopic={({ item, following, setFollowing }) => {
                    clog(
                      "asked to toggle",
                      item.name,
                      "from",
                      topicMap[item.Id]
                    );
                    setFollowing((prev) => {
                      return !prev;
                    });
                    setTopicMap((prev) => {
                      let modified = { ...prev };
                      if (modified[item.Id]) {
                        delete modified[item.Id];
                      } else {
                        modified[item.Id] = true;
                      }
                      return modified;
                    });
                    myContext.versionTemp++;
                  }}
                />
              </View>
            ) : (
              renderCollection()
            )}
          </Modalize>
        </Portal>

        <Portal>
          <Modalize
            ref={topicsRef}
            modalHeight={height * 0.5}
            modalStyle={[
              styles.viewModal,
              {
                backgroundColor: colors.background,
              },
            ]}
            scrollViewProps={{ showsVerticalScrollIndicator: false }}
            withHandle={false}
            HeaderComponent={() => (
              <View style={styles.headerModal}>
                <Text
                  style={[
                    styles.titleHeaderModal,
                    {
                      color: colors.primaryText,
                    },
                  ]}
                >
                  {"Topic tags"}
                </Text>

                <CloseButton onPress={() => topicsRef.current?.close()} />
              </View>
            )}
            FloatingComponent={() => (
              <View
                style={{
                  backgroundColor: colors.background,
                  paddingTop: 16,
                  paddingHorizontal: 16,
                }}
              >
                <ColoredButton
                  onPress={() => {
                    topicsRef.current?.close();
                  }}
                  buttonStyle={{
                    height: 54,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 100,
                    backgroundColor: colors.primaryButtonBackground,
                  }}
                  text={"Done"}
                  textStyle={{
                    color: colors.primaryButtonText,
                    fontSize: 15,
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                />
                <View style={{ height: insets.bottom ? insets.bottom : 10 }} />
              </View>
            )}
          >
            <TopicChooser
              topics={topicOptions}
              colors={colors}
              followTopics={topicMap}
              myContext={myContext}
              onPressTopic={({ item, following, setFollowing }) => {
                clog("asked to toggle", item.name, "from", topicMap[item.Id]);
                setFollowing((prev) => {
                  return !prev;
                });
                setTopicMap((prev) => {
                  let modified = { ...prev };
                  if (modified[item.Id]) {
                    delete modified[item.Id];
                  } else {
                    modified[item.Id] = true;
                  }
                  return modified;
                });
                myContext.versionTemp++;
              }}
            />
          </Modalize>
        </Portal>

        <Portal>
          <Modalize
            ref={reactionRef}
            modalHeight={height * 0.5}
            modalStyle={[
              styles.viewModal,
              {
                alignSelf: "center",
                width: "100%",
                maxWidth: 756,
                backgroundColor: colors.background,
              },
            ]}
            scrollViewProps={{ showsVerticalScrollIndicator: false }}
            withHandle={false}
            HeaderComponent={() => (
              <View style={styles.headerModal}>
                <Text
                  style={[
                    styles.titleHeaderModal,
                    {
                      color: colors.primaryText,
                    },
                  ]}
                >
                  {"What’s your reaction to this?"}
                </Text>

                <CloseButton onPress={() => reactionRef.current?.close()} />
              </View>
            )}
          >
            <EmojiSelector
              category={Categories.emotion}
              onEmojiSelected={(newEmoji) => {
                console.log(newEmoji);
                reactionRef.current?.close();
                setReaction(newEmoji);
              }}
            />
          </Modalize>
        </Portal>

        <Modal
          visible={webModalVisible}
          onRequestClose={() => {
            setWebModalVisible(false);
          }}
        >
          <SafeAreaView style={styles.container}>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  backgroundColor: colors.background,
                  height: 39,
                  paddingVertical: 10,
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Pressable
                  onPress={() => {
                    setWebModalVisible(false);
                  }}
                >
                  <Image
                    source={cross}
                    style={{
                      marginRight: 20,
                      height: 19,
                      width: 17,
                      tintColor: colors.primaryText,
                    }}
                  />
                </Pressable>
              </View>
              <WebView
                style={{ flex: 1 }}
                source={{ uri: webModalUrl }}
                ref={(r) => (webref = r)}
                originWhitelist={["*"]}
                javaScriptEnabled={true}
                domStorageEnabled={true}
              />
            </View>
          </SafeAreaView>
        </Modal>

        {myContext.actionsByUser?.["Experiment"]?.["Join"]?.["RichShareNote"] !=
          null &&
          fullScreen && (
            <ButtonBar
              myContext={myContext}
              richShareNote={richShareNote}
              colors={colors}
              shareNoteEditorRef={shareNoteEditorRef}
              bold={bold}
              setBold={setBold}
              list={list}
              setList={setList}
              quote={quote}
              setQuote={setQuote}
              length={length}
            />
          )}
        <KeyboardSpacer topSpacing={Platform.OS !== "android" ? 0 : -400} />
      </View>
    );
  }
}

export default CreatePinScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  animatedView: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
  },
  itemContainer: {
    width: "100%",
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  postButton: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 11,
    paddingVertical: 11,
    borderRadius: 100,
  },
  postButtonTitle: {
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 15,
    fontWeight: "700",
  },
  viewModal: {
    flex: 1,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
  },
  headerModal: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 19,
    paddingBottom: 16,
  },
  titleHeaderModal: {
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 20,
  },
  contentPreviewContainer: {
    flex: 1,
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "700",
    flexWrap: "wrap",
  },
});
