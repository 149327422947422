// Borrowed heavily from https://thoughtbot.com/blog/make-a-snazzy-code-input-in-react-native

import React, { useState, useContext, useRef } from "react";
import {
  Alert,
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Pressable,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import AwesomeAlert from "react-native-awesome-alerts";
import { Auth } from "aws-amplify";

import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";
import { sharedStyles } from "../utils/SharedStyles";
import ColoredButton from "../components/ColoredButton";
import { updateUserData, getUserData } from "../utils/DataFetcher";
import { CommonActions } from "@react-navigation/native";
import { clog } from "../utils/Log";
import { SignIn } from "../controllers/AuthController";
import { useTheme } from "../theme";
import Blocker from "../components/Blocker";

const ConfirmSignUpScreen = ({ route, navigation }) => {
  const myContext = useContext(AppContext);
  const { colors } = useTheme();

  const [username, setUsername] = useState(
    myContext?.username == null ? "" : myContext?.username
  );
  const [code, setCode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [conditionMet, setConditionMet] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);

  const CODE_LENGTH = 6;
  const codeDigitsArray = new Array(CODE_LENGTH).fill(1);

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  async function fetchDataAndMove() {
    try {
      const { username, attributes } = await Auth.currentAuthenticatedUser();
      clog("myContext before data fetch", myContext);
      let { success, data } = await getUserData(username);
      clog("success", success, "data", data);
      if (success) {
        updateUserData(
          myContext,
          data,
          (askForPermission) => {
            console.log("ask for permission", askForPermission);
            myContext.onboarding = true;
            clog("my context", myContext);
            if (askForPermission) {
              navigation.replace("Permission", {
                nextScreen: "Profile",
              });
            } else {
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Profile" }],
                })
              );
            }
          },
          true
        );
      } else {
        clog("error, failed to fetch user data");
      }
    } catch (err) {
      console.log("not authenticated", err);
    }
  }

  async function resendConfirmationCode() {
    let message = "";
    let success = false;
    if (!myContext.usernameLocal) {
      message = "Must provide username";
    }

    if (message) {
      showMessage(message);
    } else {
      try {
        let data = await Auth.resendSignUp(myContext.usernameLocal);
        success = true;
        showMessage("resent confirmation code");
      } catch (err) {
        if (err.code === "UserNotFoundException") {
          console.log("User not found");
          myContext.authState = "userNotFound";
          showMessage("Username not found");
        } else {
          myContext.authState = err.code;
          console.log("Unknown error", err.message);
          showMessage(err.message);
        }
      }
    }
  }

  async function handleSubmit() {
    setLoading(true);

    let message = "";
    let success = false;
    if (!myContext.usernameLocal || !myContext.codeLocal) {
      if (!myContext.usernameLocal) {
        if (!myContext.codeLocal) {
          message = "Must provide username and code";
        }
      } else {
        message = "Must provide code";
      }
    }
    if (message) {
      showMessage(message);
    } else {
      clog("WILL TRY TO CONFIRM");
      try {
        let data = await Auth.confirmSignUp(
          myContext.usernameLocal,
          myContext.codeLocal
        );
        myContext.authState = "loggedIn";
        //myContext.authenticated = true;
        success = true;
        myContext.username = myContext.usernameLocal;
        console.log(
          "sending",
          myContext.usernameLocal,
          "with",
          myContext.codeLocal
        );
      } catch (err) {
        if (err.code === "PasswordResetRequiredException") {
          console.log("the user requires a new password");
          myContext.username = myContext.usernameLocal;
          myContext.authState = "requireNewPassword";
          showMessage("Need to change password");
        } else if (err.code === "NotAuthorizedException") {
          console.log("Not authorized");
          showMessage("Incorrect username or code");
        } else if (err.code === "UserNotFoundException") {
          console.log("Not found");
          showMessage("User not found");
        } else {
          console.log("Unknown error", err);
          showMessage("Unknown error: " + err.code);
        }
        setLoading(false);
      }
      if (success) {
        if (myContext.password) {
          // Let us try to login transparently
          clog("Will try to log in transparently");
          await SignIn({
            username: myContext.username,
            password: myContext.password,
            myContext: myContext,
            callback: ({ success, message, error, user }) => {
              if (success) {
                fetchDataAndMove();
              } else {
                showMessage(message);
              }
            },
          });
        } else {
          clog("Cannot find password. will send to sign in screen");
          navigation.push("SignIn");
        }
      }
    }
    setLoading(false);
  }

  const toDigitInput = (_value, idx) => {
    const emptyInputChar = " ";
    const digit = code[idx] || emptyInputChar;

    return (
      <View
        key={idx}
        style={{
          height: 48,
          width: 48,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 8,
          borderWidth: digit == emptyInputChar ? 1 : 3,
          borderColor:
            digit == emptyInputChar ? colors.textFieldBorder : colors.accent,
        }}
      >
        <Text
          style={{
            color:
              digit == emptyInputChar
                ? colors.secondaryText
                : colors.primaryText,
            textAlign: "center",
            fontSize: 15,
          }}
        >
          {digit == emptyInputChar ? "" : digit}
        </Text>
      </View>
    );
  };

  const handleOnPress = () => {
    ref?.current?.focus();
  };

  async function getUserInfo() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      clog("USER INFO", user);
    } catch (err) {
      console.log("not authenticated", err);
    }
  }

  const checkConditions = () => {
    return (
      myContext.usernameLocal &&
      myContext.codeLocal &&
      myContext.codeLocal?.length == CODE_LENGTH
    );
  };

  const updateConditions = () => {
    if (checkConditions()) {
      setConditionMet(true);
    } else {
      setConditionMet(false);
    }
  };

  const updateConditionsAndDismissKeyboard = () => {
    updateConditions();
    Keyboard.dismiss;
  };

  if (myContext.username) {
    myContext["usernameLocal"] = myContext.username;
  }
  clog("NAVIGATION", navigation);
  React.useLayoutEffect(() => {
    let showBackButton = navigation.canGoBack();
    clog("Show back button", showBackButton);
    navigation.setOptions({
      headerLeft: () => {
        if (showBackButton) {
          return (
            <Pressable onPress={navigation.goBack}>
              <View style={{ marginLeft: 10 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        } else {
          return <View></View>;
        }
      },
      headerBackTitleVisible: showBackButton,
    });
  }, [navigation]);

  getUserInfo();

  let displayedPhone = "";
  if (myContext.phone && myContext.phone.length >= 10) {
    let startOffset = myContext.phone.length - 10;
    displayedPhone =
      myContext.phone.substring(startOffset, startOffset + 3) +
      " " +
      myContext.phone.substring(startOffset + 3, startOffset + 6) +
      " " +
      myContext.phone.substring(startOffset + 6, startOffset + 10);
  }
  clog("PHONE", myContext.phone == null);
  clog("CONTEXT IN CONFIRM SIGN UP", myContext);
  return (
    <SafeAreaView
      style={[sharedStyles.container, { backgroundColor: colors.background }]}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <KeyboardAwareScrollView
          style={{
            width: "100%",
          }}
          keyboardShouldPersistTaps={"handled"}
        >
          <View style={styles.inputContainer}>
            <View style={styles.messageContainer}>
              <Text style={[styles.message, { color: colors.secondaryText }]}>
                You should get a text from us in the next 30 seconds. Enter the
                6 digit code below.
              </Text>
            </View>
            {!myContext.phone && (
              <View>
                <TextInput
                  style={sharedStyles.textInput}
                  value={username}
                  autoCapitalize="none"
                  maxLength={200}
                  onChangeText={(newValue) => {
                    myContext["usernameLocal"] = newValue.toLowerCase();
                    setUsername(newValue.toLowerCase());
                    updateConditions();
                  }}
                  onBlur={Keyboard.dismiss}
                  returnKeyType={"done"}
                  onSubmitEditing={handleSubmit}
                />
                <Text
                  style={[
                    sharedStyles.textLabel,
                    { color: colors.primaryText },
                  ]}
                >
                  Village username
                </Text>
                <View
                  style={[
                    sharedStyles.textInputBox,
                    {
                      color: colors.primaryText,
                      borderColor: colors.textFieldBorder,
                      height: 48,
                    },
                  ]}
                >
                  <TextInput
                    style={[
                      sharedStyles.textInput,
                      {
                        color: colors.primaryText,
                        borderColor: colors.textFieldBorder,
                        height: 48,
                        borderWidth: 0,
                      },
                    ]}
                    autoCapitalize="none"
                    value={username}
                    maxLength={200}
                    onChangeText={(newValue) => {
                      setUsername(newValue.toLowerCase());
                      myContext["usernameLocal"] = newValue.toLowerCase();
                      updateConditions();
                    }}
                    onBlur={() => {
                      updateConditionsAndDismissKeyboard();
                    }}
                  />
                </View>
              </View>
            )}
            {myContext.phone != "" && myContext.phone != null && (
              <View style={{ marginTop: 100 }}>
                <Pressable
                  style={styles.inputsContainer}
                  onPress={handleOnPress}
                >
                  <View style={styles.containerOfCode}>
                    {codeDigitsArray.map(toDigitInput)}
                  </View>
                </Pressable>
                {Platform.OS == "ios" && (
                  <TextInput
                    ref={ref}
                    //secureTextEntry={true}
                    textContentType={"oneTimeCode"}
                    keyboardType="number-pad"
                    style={styles.hiddenCodeInput}
                    value={code}
                    maxLength={CODE_LENGTH}
                    onChangeText={(newValue) => {
                      myContext["codeLocal"] = newValue;
                      setCode(newValue);
                      updateConditions();
                    }}
                    onBlur={Keyboard.dismiss}
                    returnKeyType={"done"}
                    onSubmitEditing={handleSubmit}
                  />
                )}
                {Platform.OS != "ios" && (
                  <TextInput
                    ref={ref}
                    //secureTextEntry={true}
                    style={styles.hiddenCodeInput}
                    value={code}
                    maxLength={CODE_LENGTH}
                    onChangeText={(newValue) => {
                      myContext["codeLocal"] = newValue;
                      setCode(newValue);
                    }}
                    onBlur={Keyboard.dismiss}
                    returnKeyType={"done"}
                    onSubmitEditing={handleSubmit}
                  />
                )}
              </View>
            )}
            <TouchableOpacity
              style={{ alignItems: "center", marginTop: 24 }}
              onPress={resendConfirmationCode}
            >
              <Text style={[styles.textCode, { color: colors.link }]}>
                {`Resend code ${!displayedPhone ? "" : "to " + displayedPhone}`}
              </Text>
            </TouchableOpacity>
            {myContext.phone != "" && myContext.phone != null && (
              <ColoredButton
                onPress={() => {
                  handleSubmit();
                }}
                disabled={loading}
                buttonStyle={{
                  ...styles.nextButton,
                  backgroundColor: conditionMet
                    ? colors.primaryButtonBackground
                    : colors.disablePrimaryButtonBackground,
                }}
                textStyle={{
                  ...styles.textStyle,
                  color: conditionMet
                    ? colors.primaryButtonText
                    : colors.disabledPrimaryButtonText,
                }}
                text={"Next"}
              />
            )}
          </View>
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title=""
            message={alertMessage}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            cancelText="Ok"
            cancelButtonColor={colors.cancelButton}
            onCancelPressed={() => {
              setShowAlert(false);
            }}
          />
        </KeyboardAwareScrollView>
        <Blocker loading={loading} />
      </View>
    </SafeAreaView>
  );
};

export default ConfirmSignUpScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  messageContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  message: {
    fontSize: 15,
    textAlign: "center",
  },
  inputContainer: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  containerOfCode: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  textCode: { fontSize: 14, fontWeight: "700" },
  nextButton: {
    lignItems: "center",
    justifyContent: "center",
    marginTop: 100,
    height: 43,
    borderRadius: 100,
  },
  textStyle: {
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 16,
    fontWeight: "700",
  },
  hiddenCodeInput: {
    position: "absolute",
    height: 0,
    width: 0,
    opacity: 0,
  },
  inputsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
