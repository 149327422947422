import {
  addActionByUser,
  removeActionByUser,
  undoAction,
  performAction,
} from "../utils/Action";
import { clog } from "../utils/Log";

export async function toggleBlock({
  blocking,
  objectType,
  target,
  hostUserId,
  refererActionId,
  myContext,
  callback,
}) {
  if (objectType == "User") {
    if (!myContext.users[target.Id]) {
      myContext.users[target.Id] = target;
    }
  }
  const addObjectBlocked = (
    objectId,
    objectType,
    relationshipId,
    object,
    createdAt
  ) => {
    addActionByUser(
      myContext.actionsByUser,
      objectType,
      "Block",
      objectId,
      relationshipId,
      object,
      null,
      null,
      createdAt
    );
    myContext.version++;
  };

  const removeObjectBlocked = (
    objectId,
    objectType,
    relationshipId,
    object
  ) => {
    clog("stopped blocking", objectId, relationshipId);
    removeActionByUser(myContext.actionsByUser, objectType, "Block", objectId);
    myContext.version++;
  };

  let relationshipId = null;
  let localCallback = null;
  let fn = null;
  if (myContext.actionsByUser?.[objectType]?.["Block"]?.[target.Id]) {
    blocking = true;
    relationshipId = Object.keys(
      myContext.actionsByUser[objectType]["Block"][target.Id]
    )?.[0];
  }
  fn = blocking ? undoAction : performAction;
  let updatefn = blocking ? removeObjectBlocked : addObjectBlocked;
  clog("WILL TOGGLE BLOCK", blocking);
  localCallback = (
    success,
    modifiedRelationshipId,
    createdAt,
    responses,
    creationTS
  ) => {
    clog("OUTCOME OF TOGGLE", success, modifiedRelationshipId, createdAt);
    if (success) {
      updatefn(
        target.Id,
        objectType,
        modifiedRelationshipId,
        target,
        createdAt
      );
      if (callback) {
        callback({ success: true });
      }
    } else {
      clog("failed to block user");
      if (callback) {
        callback({ success: false, message: "failed to block user" });
      }
    }
    ////clog("app context is", myContext);
  };
  let curatorId = null;
  let curatorCounter = 0;
  if (objectType == "User") {
    curatorId = target.Id;
  }
  fn({
    objectType: objectType,
    operation: "Block",
    objectId: target.Id,
    actorId: myContext.Id,
    relationshipId: relationshipId,
    objectCounter: target.numFollow,
    userCounter: 0,
    curatorId: curatorId,
    curatorCounter: curatorCounter,
    promises: [],
    payloads: [],
    callback: localCallback,
    refererActionId: refererActionId,
    hostUserId: hostUserId,
    seq: null,
  });
}
