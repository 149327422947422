import React, { useState } from "react";
import {
  FlatList,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { clog } from "../utils/Log";

const CustomSectionList = (props) => {
  const { sections, customRenderItem, renderSectionHeader } = props;

  const renderItem = ({ item }) => {
    return (
      <>
        {renderSectionHeader({ section: item.section })}
        <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
          {item.data.map((item, index) => customRenderItem({ item, index }))}
        </View>
      </>
    );
  };

  return <FlatList data={sections} renderItem={renderItem} {...props} />;
};

const TopicChooser = ({
  topics,
  colors,
  myContext,
  followTopics,
  onPressTopic,
}) => {
  const renderSectionHeader = ({ section }) => {
    let imageUrl = section?.avatar
      ? myContext.presignedUrls[section?.avatar]?.uri
      : null;
    clog("imageUrl", imageUrl);

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 16,
          marginBottom: 12,
        }}
      >
        {imageUrl && (
          <View style={{ marginRight: 4 }}>
            <Image
              source={{
                uri: imageUrl,
                cache: "force-cache",
              }}
              style={{
                height: 18,
                width: 18,
              }}
            />
          </View>
        )}
        <Text
          style={{ fontSize: 18, lineHeight: 28, color: colors.primaryText }}
        >
          {section.name}
        </Text>
      </View>
    );
  };

  const OneTopic = ({ item, index, colors, onPress, followTopics }) => {
    // NOTE(alpha): Accesses keys only
    let followingComputed = followTopics?.[item.Id] ? true : false;

    const [following, setFollowing] = useState(followingComputed);
    clog("item", item, "following", following);

    return (
      <View key={index} style={styles.item}>
        <Pressable
          onPress={() => onPress({ item, following, setFollowing })}
          style={[
            following
              ? { backgroundColor: colors.accent }
              : { backgroundColor: colors.secondaryBackground },
            styles.topicButton,
          ]}
        >
          <Text
            style={
              following
                ? {
                    color: colors.primaryText,
                    fontSize: 15,
                    fontWeight: "500",
                  }
                : {
                    color: colors.placeholderText,
                    fontSize: 15,
                    fontWeight: "500",
                  }
            }
          >
            {item.item}
          </Text>
        </Pressable>
      </View>
    );
  };

  return (
    <View style={styles.inputContainer}>
      <CustomSectionList
        sections={topics}
        customRenderItem={({ item, index }) => (
          <OneTopic
            key={index}
            item={item}
            index={index}
            colors={colors}
            onPress={onPressTopic}
            followTopics={followTopics}
          />
        )}
        renderSectionHeader={renderSectionHeader}
        keyExtractor={({ section }) => section.Id}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

export default TopicChooser;

const styles = StyleSheet.create({
  inputContainer: {
    width: "100%",
    flex: 1,
  },
  button: {
    alignItems: "center",
    flexDirection: "row",
    height: 54,
    justifyContent: "center",
    borderRadius: 100,
  },
  titleButton: {
    fontSize: 15,
    fontWeight: "700",
    textAlign: "center",
  },
  item: {
    justifyContent: "space-between",
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 100,
    overflow: "hidden",
  },
  topicButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 100,
  },
});
