import React from "react";
import { View, Text, StyleSheet } from "react-native";
import Avatar from "./Avatar";
import { clog } from "../utils/Log";

const NameCard = ({ item, myContext, interaction, colors }) => {
  if (item == null) {
    return <View></View>;
  }
  clog("will print card for", item);
  return (
    <View style={styles.item}>
      <View style={styles.topline}>
        <View>
          <Avatar
            item={item}
            style={{
              height: 48,
              width: 48,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 48,
              backgroundColor: colors.primaryButtonBackground,
            }}
            textstyle={{
              fontSize: 20,
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
              color: colors.primaryButtonText,
            }}
            myContext={myContext}
          />
        </View>
        <View style={{ marginLeft: 8, flex: 1 }}>
          <Text
            style={[styles.name, { color: colors.primaryText }]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item?.name}
          </Text>
          {item?.headline != "" && item?.headline != null && (
            <View style={{ marginTop: 8 }}>
              <Text
                style={[styles.headline, { color: colors.placeholderText }]}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {item?.headline}
              </Text>
            </View>
          )}
        </View>
        {interaction}
      </View>
    </View>
  );
};

export default NameCard;

const styles = StyleSheet.create({
  item: {
    flexDirection: "column",
  },
  topline: {
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    fontSize: 15,
    fontWeight: "700",
  },
  headline: {
    fontSize: 15,
    lineHeight: 22,
  },
});
