import React, { useContext, useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  Image,
  AppState,
  Platform,
} from "react-native";

import AppContext from "../components/AppContext";
import { clog } from "../utils/Log";
import home2 from "../assets/home_2.png";
import notification from "../assets/notification_village.png";
import { registerAndUpdateNotificationToken } from "../utils/Notification";
import LeftArrow from "../components/LeftArrow";
import { useTheme } from "../theme";
import ColoredButton from "../components/ColoredButton";
import { logEvent } from "../utils/LogEvent";

const OnboardingNotificationScreen = ({ route, navigation }) => {
  clog("navigation is in user details ", navigation);
  console.log("route in permission is ", route);
  let myContext = useContext(AppContext);
  clog("app context is", myContext);
  const { colors } = useTheme();

  const appState = useRef(AppState.currentState);

  async function checkNotifications() {
    let askForPermission = await registerAndUpdateNotificationToken(myContext);
    if (!askForPermission) {
      console.log("HOORAY! Received permission");
    } else {
      console.log("SIGH! Still no permissions");
    }
    logEvent("Onboarding_Allow_Notif_Verification", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: "on-boarding",
      platform: Platform.OS,
      status: !askForPermission ? "success" : "failure",
      action: "verification",
      appVersion: myContext.appVersion,
    });
    myContext["wentToSettings"] = false;
    navigation.push("TopicChooser");
  }

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        console.log("App has come to the foreground!");
        if (myContext.wentToSettings) {
          console.log("BACK FROM SETTINGS");
          checkNotifications();
        }
      }

      appState.current = nextAppState;
      clog("AppState", appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable
            onPress={() => {
              navigation.goBack();
            }}
          >
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>
        );
      },
    });
  }, [navigation, colors]);

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View
        style={{
          flex: 1,
          width: "100%",
          maxWidth: 756,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <View>
          <Text style={[styles.description, { color: colors.secondaryText }]}>
            To get updates on your activity and occasional recommendations on
            the best content, grant Village notification permissions.
          </Text>
        </View>
        <View
          style={{
            marginTop: 50,
            paddingHorizontal: 38,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              padding: 12,
              borderRadius: 16,
              borderWidth: 1,
              borderColor: colors.smallDivider,
            }}
          >
            <View style={{ marginRight: 12 }}>
              <Image
                source={notification}
                style={{
                  height: 36,
                  width: 36,
                }}
              />
            </View>
            <View
              style={[
                {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
                Platform.OS === "web" && { flex: 1 },
              ]}
            >
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: "700",
                  color: colors.primaryText,
                  marginBottom: 4,
                }}
              >
                @jooohnny tagged you
              </Text>
              <View>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "400",
                    color: colors.primaryText,
                  }}
                >
                  @sarah I agree with you, and I think there is even more we can
                  decipher from this quote!
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            height: 450,
            width: 393,
            overflow: "hidden",
          }}
        >
          <Image
            resizeMode="cover"
            source={home2}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </View>
        <View
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        >
          <ColoredButton
            onPress={() => {
              logEvent("Onboarding_Allow_Notif_Yes", {
                userId: myContext?.Id,
                username: myContext?.handle,
                category: "on-boarding",
                platform: Platform.OS,
                action: "click",
                target: "Yes",
                appVersion: myContext.appVersion,
              });
              checkNotifications();
            }}
            buttonStyle={{
              width: 357,
              paddingHorizontal: 16,
              backgroundColor: colors.primaryButtonBackground,
              borderRadius: 100,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              height: 54,
            }}
            textStyle={{
              fontSize: 15,
              fontWeight: "700",
              color: colors.primaryButtonText,
            }}
            text={"Allow Notifications"}
          />
          <View
            style={{ backgroundColor: colors.background, paddingBottom: 20 }}
          >
            <ColoredButton
              onPress={() => {
                logEvent("Onboarding_Allow_Notif_No", {
                  userId: myContext?.Id,
                  username: myContext?.handle,
                  category: "on-boarding",
                  platform: Platform.OS,
                  action: "click",
                  target: "No",
                  appVersion: myContext.appVersion,
                });
                navigation.push("TopicChooser");
              }}
              buttonStyle={{
                height: 54,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              textStyle={{
                fontSize: 15,
                fontWeight: "700",
                color: colors.primaryText,
              }}
              text={"Not now"}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default OnboardingNotificationScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontSize: 15,
    marginBottom: 8,
  },
  description: {
    fontSize: 15,
    fontWeight: "400",
    lineHeight: 22,
    textAlign: "center",
  },
});
