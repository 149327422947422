import uuid from "react-native-uuid";
import { Storage } from "aws-amplify";
import RNImageManipulator from "@oguzhnatly/react-native-image-manipulator";
import { timeStamp } from "../utils/TimeStamp";

export const uploadPhoto = async (prefix, imageURI) => {
  try {
    // new code for images
    let imageExt = "";
    const re = /data:(image\/([^;]+));/;
    const found = imageURI.match(re);
    if (found && found.length > 0) {
      imageExt = found[2];
    } else {
      imageExt = imageURI.split(".").pop();
    }

    const extPattern = /^([^\?]*)\?.*$/;
    const match = imageExt.match(extPattern);
    if (match && match.length > 0) {
      imageExt = match[1];
    }

    let imageKey = prefix + uuid.v4() + "." + imageExt;
    let uri = imageURI;
    const manipResult = await RNImageManipulator.manipulate(imageURI, [], {
      compress: 1,
      format: "jpeg",
    });
    uri = manipResult.uri;

    const photo = await fetch(uri);

    //const photo = await fetch(imageURI);
    ////clog("photo is", photo);
    const photoBlob = await photo.blob();
    let response = await Storage.put(imageKey, photoBlob);
    //clog("response", response);
    return [true, imageKey];
  } catch (err) {
    console.log("error uploading photo:", err);
    return [false, err];
  }
};

export const getPhoto = async (imageKey) => {
  try {
    const url = await Storage.get(imageKey);
    ////clog("signed url", url);
    return [true, url];
  } catch (err) {
    console.log("error creating presigned url ", err);
    return [false, err];
  }
};

export const getSignedUrl = async (imageKey) => {
  ////clog("will try to get signed url for", imageKey);
  try {
    let url = await Storage.get(imageKey, {
      download: false,
      expires: 7 * 86400,
    });
    ////clog("signed url for", imageKey, "is", url);
    return [true, url, imageKey];
  } catch (err) {
    console.log("error creating presigned url ", err);
    return [false, err, imageKey];
  }
};

export async function batchPresign(list, presignedUrls, callback) {
  let ts = timeStamp();
  let period = 1800;

  let promises = [];
  let processing = {};
  list.forEach((key) => {
    if (
      key &&
      !processing[key] &&
      (!presignedUrls[key] || presignedUrls[key].timeStamp < ts - period)
    ) {
      promises.push(getSignedUrl(key));
      processing[key] = 1;
    }
  });
  const responses = await Promise.all(promises);
  responses.forEach((response) => {
    let success = response[0];
    let signedUrl = response[1];
    let key = response[2];
    if (success) {
      presignedUrls[key] = { uri: signedUrl, timestamp: ts };
    }
    if (callback) {
      callback();
    }
  });
}
