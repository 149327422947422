import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Alert,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
  Pressable,
  Keyboard,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import AwesomeAlert from "react-native-awesome-alerts";
import AppContext from "../components/AppContext";

import { sharedStyles } from "../utils/SharedStyles";
import ImageChooser from "../utils/ImageChooser";
import ColoredButton from "../components/ColoredButton";
import LeftArrow from "../components/LeftArrow";
import { clog } from "../utils/Log";
import { addUser, modifyUser } from "../controllers/UserController";
import { useTheme } from "../theme";

function ProfileUpdateScreen({ route, navigation }) {
  async function createAUser() {
    addUser({
      firstName: firstName,
      lastName: lastName,
      headline: headline,
      bio: bio,
      websites: websites,
      location: location,
      imageURI: photoChoosingState.localImage,
      myContext: myContext,
      callback: ({ success, message, error }) => {
        inflight = false;
        if (success) {
          navigation.push("OnboardingNotification");
        } else {
          showMessage(message);
        }
      },
    });
  }

  async function updateAUser() {
    modifyUser({
      firstName: firstName,
      lastName: lastName,
      headline: headline,
      bio: bio,
      websites: websites,
      location: location,
      imageURI: photoChoosingState.localImage,
      myContext: myContext,
      callback: ({ success, message, error }) => {
        if (success) {
          if (myContext.onboarding) {
            inflight = false;
            navigation.push("TopicChooser");
          } else {
            inflight = false;
            navigation.goBack();
          }
        } else {
          clog("FAILURE", error);
          showMessage(message);
        }
      },
    });
  }

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const UnsavedChangesAlert = () => {
    return (
      <AwesomeAlert
        show={showUnsavedChangesAlert}
        showProgress={false}
        title={"Unsaved changes"}
        message={"If you leave without saving, you will lose your changes."}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText={"Discard changes"}
        confirmText={"Save changes"}
        confirmButtonColor={colors.cancelButton}
        onConfirmPressed={() => {
          handleSubmit();
          setShowUnsavedChangesAlert(false);
        }}
        onCancelPressed={() => {
          navigation.goBack();
          setShowUnsavedChangesAlert(false);
        }}
        onDismiss={() => setShowUnsavedChangesAlert(false)}
      />
    );
  };

  const checkConditions = () => {
    clog(
      "INFLIGHT",
      inflight,
      "phase",
      phase,
      "firstName",
      firstName,
      "lastName",
      lastName
    );
    return (
      !inflight &&
      ((phase == 1 && firstName && lastName) ||
        (phase == 2 &&
          (photoChoosingState.image || photoChoosingState.localImage)) ||
        phase == 3)
    );
  };

  const updateConditions = () => {
    const isValid = checkConditions();
    if (isValid && !conditionMet) {
      setConditionMet(true);
    } else if (!isValid && conditionMet) {
      setConditionMet(false);
    }
  };

  const updateConditionsAndDismissKeyboard = () => {
    updateConditions();
    Keyboard.dismiss;
  };

  const handleSubmit = () => {
    let message = "";
    if (phase == 1 && (!firstName || !lastName)) {
      message = "Must provide first name and last name";
    }
    if (message) {
      showMessage(message);
    } else {
      if (!phase || phase == 3) {
        inflight = true;
        if (subsequent || myContext.Id) {
          console.log("DECIDED TO UPDATE");
          updateAUser();
        } else {
          console.log("DECIDED TO CREATE");
          createAUser();
        }
      } else {
        setPhase((prev) => {
          console.log("MOVING PHASE TO", prev + 1);
          return prev + 1;
        });
      }
    }
  };

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const { colors } = useTheme();
  const [conditionMet, setConditionMet] = useState(checkConditions());
  const [firstName, setFirstName] = useState(
    myContext?.firstName == null ? "" : myContext?.firstName
  );
  const [lastName, setLastName] = useState(
    myContext?.lastName == null ? "" : myContext?.lastName
  );
  const [headline, setHeadline] = useState(
    myContext?.headline == null ? "" : myContext?.headline
  );
  const [headlineLength, setHeadlineLength] = useState(
    myContext?.headline == null ? 0 : myContext?.headline.length
  );
  const [bio, setBio] = useState(myContext?.bio == null ? "" : myContext?.bio);
  const [websites, setWebsites] = useState(
    myContext?.websites == null ? "" : myContext?.websites
  );
  const [location, setLocation] = useState(
    myContext?.location == null ? "" : myContext?.location
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [photoChoosingState, setPhotoChoosingState] = useState({
    image:
      myContext.avatar && myContext.presignedUrls[myContext.avatar]
        ? myContext.presignedUrls[myContext.avatar].uri
        : myContext?.avatarUrl
        ? myContext?.avatarUrl
        : "",
    localImage: "",
    choosing: false,
    hasPermission: Platform.OS == "android" ? true : false,
  });

  const [phase, setPhase] = useState(route?.params?.subsequent ? 0 : 1);
  const [isChanged, setIsChanged] = useState(false);
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);

  const lastNameRef = useRef();

  useEffect(() => {
    updateConditions();
  }, [firstName, lastName, headline, location]);

  clog("PHOTO CHOOSING STATE", photoChoosingState);
  React.useLayoutEffect(() => {
    clog("subsequent", subsequent);
    console.log("Refresh nav", phase);

    const onPress = () => {
      if (subsequent) {
        if (!phase && isChanged) {
          if (Platform.OS !== "web") {
            Alert.alert(
              "Unsaved changes",
              "If you leave without saving, you will lose your changes.",
              [
                {
                  style: "cancel",
                  text: "Discard changes",
                  onPress: () => navigation.goBack(),
                },
                {
                  text: "Save changes",
                  onPress: handleSubmit,
                },
              ]
            );
          } else {
            setShowUnsavedChangesAlert(true);
          }
        } else {
          navigation.goBack();
        }
      }
    };

    navigation.setOptions({
      headerLeft: () => {
        if (subsequent) {
          return (
            <Pressable onPress={onPress}>
              <View style={{ marginLeft: 10 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        } else {
          if (phase && phase > 1) {
            return (
              <Pressable
                onPress={() => {
                  setPhase((prev) => {
                    console.log("MOVING PHASE TO", prev + 1);
                    return prev - 1;
                  });
                }}
              >
                <View style={{ marginLeft: 10 }}>
                  <LeftArrow tintColor={colors.primaryText} />
                </View>
              </Pressable>
            );
          } else {
            return <View></View>;
          }
        }
      },
      headerBackTitleVisible: false,
      headerTitle: !phase
        ? "Tell us about yourself"
        : phase == 1
        ? "Who is moving in?"
        : phase == 2
        ? "Welcome to Village, " + firstName + "!"
        : "What's your headline?",
    });
  }, [navigation, phase, isChanged]);

  const getPhotoChoosingState = () => {
    return photoChoosingState;
  };

  let inflight = false;

  clog("params", route?.params);
  let subsequent = route?.params?.subsequent;
  clog("my context", myContext);

  clog(
    "profile update",
    "subsequent",
    subsequent,
    "onboarding",
    myContext?.onboarding,
    "context",
    myContext
  );
  useEffect(() => {
    if (subsequent || myContext?.onboarding) {
      clog("stay here!!");
    } else {
      clog("replace tab");
      navigation.replace("tab");
    }
  }, []);

  const onNext = () => {
    if (phase == 1 && (!firstName || !lastName)) {
      showMessage("Must provide first and last name");
      return;
    }
    if (inflight) {
      showMessage("update in progress");
    } else {
      handleSubmit();
    }
  };

  return (
    <View
      style={[sharedStyles.container, { backgroundColor: colors.background }]}
    >
      <View
        style={{ flex: 1, width: "100%", maxWidth: 756, alignItems: "center" }}
      >
        <KeyboardAwareScrollView
          scrollEnabled={true}
          extraScrollHeight={120}
          keyboardShouldPersistTaps="handled"
          enableAutoAutomaticScroll={false}
          enableOnAndroid={true}
          //keyboardOpeningTime={Number.MAX_SAFE_INTEGER}
          //keyboardOpeningTime={-1}
          enableResetScrollToCoords={false}
          style={{
            width: "100%",
            flex: 1,
            marginBottom: 10,
          }}
        >
          <View style={styles.inputContainer}>
            <View style={{ flex: 1 }}>
              {phase != 0 && (
                <View style={{ marginBottom: phase == 1 ? 36 : 20 }}>
                  <Text
                    style={{
                      color: colors.secondaryText,
                      fontSize: 15,
                      textAlign: "center",
                    }}
                  >
                    {phase == 1
                      ? "Let’s get your basic information down"
                      : phase == 2
                      ? "We recommend adding a photo of you so villagers have a face to the name"
                      : phase == 3
                      ? "Write a short blurb for villagers to learn about who you are"
                      : ""}
                  </Text>
                </View>
              )}
              {(phase == 0 || phase == 2) && (
                <View
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <ImageChooser
                    addMessage={"Upload"}
                    updateMessage={"Change image"}
                    getter={getPhotoChoosingState}
                    setter={setPhotoChoosingState}
                    setIsChanged={(value) => setIsChanged(value)}
                    myContext={myContext}
                    colors={colors}
                  />
                </View>
              )}
              {(phase == 0 || phase == 1) && (
                <View>
                  <Text
                    style={[
                      sharedStyles.textLabel,
                      { color: colors.primaryText },
                    ]}
                  >
                    First Name
                  </Text>
                  <View
                    style={[
                      sharedStyles.textInputBox,
                      {
                        color: colors.primaryText,
                        borderColor: colors.textFieldBorder,
                        height: 48,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        sharedStyles.textInput,
                        {
                          color: colors.primaryText,
                          borderColor: colors.textFieldBorder,
                          height: 48,
                          borderWidth: 0,
                        },
                      ]}
                      autoCapitalize="none"
                      returnKeyType={"next"}
                      value={firstName}
                      maxLength={200}
                      onChangeText={(newValue) => {
                        setFirstName(newValue);
                        setIsChanged(true);
                      }}
                      onBlur={() => {
                        updateConditionsAndDismissKeyboard();
                      }}
                      onSubmitEditing={() => lastNameRef.current.focus()}
                    />
                  </View>
                  <Text
                    style={[
                      sharedStyles.textLabel,
                      { color: colors.primaryText },
                    ]}
                  >
                    Last Name
                  </Text>
                  <View
                    style={[
                      sharedStyles.textInputBox,
                      {
                        color: colors.primaryText,
                        borderColor: colors.textFieldBorder,
                        height: 48,
                      },
                    ]}
                  >
                    <TextInput
                      ref={lastNameRef}
                      style={[
                        sharedStyles.textInput,
                        {
                          color: colors.primaryText,
                          borderColor: colors.textFieldBorder,
                          height: 48,
                          borderWidth: 0,
                        },
                      ]}
                      autoCapitalize="none"
                      returnKeyType={phase === 1 ? "done" : "next"}
                      value={lastName}
                      maxLength={200}
                      onChangeText={(newValue) => {
                        setLastName(newValue);
                        setIsChanged(true);
                      }}
                      onBlur={() => {
                        updateConditionsAndDismissKeyboard();
                      }}
                      onSubmitEditing={() => phase === 1 && onNext()}
                    />
                  </View>
                </View>
              )}
              {(phase == 0 || phase == 3) && (
                <View>
                  <Text
                    style={[
                      sharedStyles.textLabel,
                      { color: colors.primaryText },
                    ]}
                  >
                    Headline
                  </Text>
                  <View
                    style={[
                      sharedStyles.textInputBox,
                      {
                        color: colors.primaryText,
                        borderColor: colors.textFieldBorder,
                        height: 72,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        sharedStyles.textInput,
                        {
                          color: colors.primaryText,
                          borderColor: colors.textFieldBorder,
                          height: 72,
                          borderWidth: 0,
                          padding: 12,
                        },
                      ]}
                      autoCapitalize="none"
                      value={headline}
                      maxLength={150}
                      placeholder={"Write out your headline"}
                      onChangeText={(newValue) => {
                        setHeadline(newValue);
                        setHeadlineLength(newValue.length);
                        setIsChanged(true);
                      }}
                      returnKeyType={"done"}
                      onSubmitEditing={onNext}
                      onBlur={() => {
                        updateConditionsAndDismissKeyboard();
                      }}
                      multiline
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 8,
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text
                      style={{
                        color: colors.secondaryText,
                        fontSize: 15,
                        fontWeight: "400",
                      }}
                    >
                      {headline
                        ? headlineLength + "/150 characters"
                        : "150 characters"}
                    </Text>
                  </View>
                </View>
              )}
              {phase == 0 && subsequent && (
                <View>
                  <Text
                    style={[styles.textLabel, { color: colors.primaryText }]}
                  >
                    Website
                  </Text>
                  <TextInput
                    style={[
                      styles.textInput,
                      {
                        color: colors.primaryText,
                        borderColor: colors.textFieldBorder,
                      },
                    ]}
                    value={websites}
                    maxLength={255}
                    onChangeText={(newValue) => {
                      setWebsites(newValue);
                      setIsChanged(true);
                    }}
                    //autoFocus={true}
                    //onBlur={Keyboard.dismiss}
                  />
                </View>
              )}
              {phase == 0 && subsequent && (
                <View>
                  <Text
                    style={[styles.textLabel, { color: colors.primaryText }]}
                  >
                    Location
                  </Text>
                  <TextInput
                    style={[
                      styles.textInput,
                      {
                        color: colors.primaryText,
                        borderColor: colors.textFieldBorder,
                      },
                    ]}
                    value={location}
                    maxLength={255}
                    //autoFocus={true}
                    onChangeText={(newValue) => {
                      setLocation(newValue);
                      setIsChanged(true);
                    }}
                    //onBlur={Keyboard.dismiss}
                    placeholder={"Enter your city"}
                    placeholderTextColor={colors.secondaryText}
                  />
                </View>
              )}
              <AwesomeAlert
                show={showAlert}
                showProgress={false}
                title=""
                message={alertMessage}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Ok"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  setShowAlert(false);
                }}
              />
              <UnsavedChangesAlert />
            </View>
          </View>
        </KeyboardAwareScrollView>
        <ColoredButton
          onPress={onNext}
          buttonStyle={[
            {
              width: "90%",
              alignItems: "center",
              justifyContent: "center",
              height: 54,
              borderRadius: 100,
              backgroundColor:
                conditionMet || (phase === 0 && isChanged)
                  ? colors.primaryButtonBackground
                  : colors.disabledPrimaryButtonBackground,
            },
            Platform.OS !== "web" ? { marginBottom: 80 } : {},
          ]}
          textStyle={{
            textAlign: "center",
            textAlignVertical: "center",
            fontSize: 15,
            fontWeight: "700",
            color:
              conditionMet || (phase === 0 && isChanged)
                ? colors.primaryButtonText
                : colors.disabledPrimaryButtonText,
          }}
          text={
            subsequent
              ? "Save"
              : phase == 1 ||
                (phase == 2 &&
                  (photoChoosingState.image ||
                    photoChoosingState.localImage)) ||
                (phase == 3 && headline)
              ? "Next"
              : "Do this later"
          }
        />
      </View>
    </View>
  );
}

export default ProfileUpdateScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  inputContainer: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "column",
    justifyContent: "space-around",
  },
  textLabel: {
    fontSize: 14,
    fontWeight: "700",
    marginBottom: 8,
    marginTop: 24,
  },
  textInput: {
    height: 48,
    fontSize: 14,
    padding: 12,
    borderRadius: 8,
    borderWidth: 1,
  },
});
