import React from "react";
import * as ImagePicker from "expo-image-picker";

import {
  Image,
  View,
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
  Pressable,
} from "react-native";

import photoUpload from "../assets/photo-upload.png";
import { clog } from "../utils/Log";

function ImageChooser({
  addMessage,
  updateMessage,
  getter,
  setter,
  setIsChanged,
  myContext,
  colors,
}) {
  const hasCameraRollPermission = async (callback) => {
    //clog("will check media permissions");
    let permission = true;
    const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      if (Platform.OS === "ios") {
        try {
          const { status } =
            await ImagePicker.requestMediaLibraryPermissionsAsync();
          if (status !== "granted") {
            alert("Sorry, we need camera roll permissions to make this work!");
            permission = false;
          } else {
            //clog("granted media permissions now!!");
            permission = true;
          }
        } catch (err) {
          console.log("permission check error");
          permission = false;
        }
      }
    } else {
      //clog("Media Permissions already granted");
      permission = true;
    }
    if (callback) {
      callback(permission);
    }
    //permission = false;
    setter((oldValue) => {
      return { ...oldValue, hasPermission: permission };
    });
  };

  const chooseLocalImage = async () => {
    setter((oldValue) => {
      return { ...oldValue, choosing: false };
    });
    let _image = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });
    if (!_image.cancelled) {
      setIsChanged(true);
      setter((oldValue) => {
        return { ...oldValue, localImage: _image.uri };
      });
    }
    clog("will return empty view");
  };

  const addImage = async () => {
    hasCameraRollPermission((permission) => {
      if (permission) {
        setter((oldValue) => {
          return { ...oldValue, choosing: true, permission: true };
        });
      }
    });
  };

  ////clog("uri for image", image);
  let imageUrl = "";
  if (getter().localImage != "") {
    imageUrl = getter().localImage;
  } else if (getter().image != "") {
    imageUrl = getter().image;
  }

  if (getter().hasPermission && getter().choosing) {
    chooseLocalImage();
  }
  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => {
          if (!getter().choosing && !imageUrl) {
            addImage();
          }
        }}
      >
        <View
          style={{
            borderRadius: 179,
            width: 179,
            height: 179,
            backgroundColor: colors.cardBackground,
            overflow: "hidden",
            alignSelf: "center",
            borderWidth: 1,
            borderStyle: "dashed",
            borderColor: !imageUrl ? colors.primaryText : "",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {imageUrl != "" && (
            <Image
              source={{ uri: imageUrl }}
              resizeMode="cover"
              style={{
                position: "absolute",
                top: -1,
                left: -1,
                width: 179,
                height: 179,
              }}
            />
          )}
          {imageUrl == "" && (
            <View
              style={{
                position: "absolute",
                width: 89,
                height: 89,
                top: 45,
                left: 45,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View style={{ marginRight: 8.25 }}>
                <Image
                  source={photoUpload}
                  style={{
                    width: 15.5,
                    height: 17.48,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
              <Text
                style={{
                  color: colors.primaryText,
                  textAlign: "center",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                {"Upload"}
              </Text>
            </View>
          )}
        </View>
      </Pressable>
      {!getter().choosing && imageUrl != "" && (
        <TouchableOpacity onPress={addImage} style={styles.uploadBtn}>
          <View
            style={[
              styles.uploadBtnContainer,
              {
                borderColor: colors.secondaryButtonBorder,
              },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View style={{ marginRight: 8.25 }}>
                <Image
                  source={photoUpload}
                  style={{
                    width: 15.5,
                    height: 17.48,
                    tintColor: colors.secondaryButtonText,
                  }}
                />
              </View>
              <View>
                <Text
                  style={{
                    color: colors.secondaryButtonText,
                    fontSize: 15,
                    fontWeight: "700",
                  }}
                >
                  {updateMessage == null ? "Update" : updateMessage}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
}

export default ImageChooser;

const styles = StyleSheet.create({
  container: {},
  uploadBtnContainer: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 100,
    marginTop: 20,
    width: 176,
    height: 48,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderWidth: 1,
  },
  uploadBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
