import React, { useState, forwardRef, useImperativeHandle } from "react";
import ShareNote from "./ShareNote";
import { generateUniqueId } from "../utils/Id";
import {
  Pressable,
  Switch,
  Text,
  View,
  StyleSheet,
  ScrollView,
} from "react-native";
import CustomButton from "./CustomButton";
import { villageColors } from "../utils/SharedStyles";
import plus from "../assets/plus.png";
import text_component from "../assets/text_component.png";
import image_component from "../assets/image_component.png";
import trash from "../assets/trash.png";
import quoted from "../assets/quote.png";
import {
  makeText,
  makeBulletedList,
  makeSlidingPanel,
  makeImage,
  makeQuote,
  makeCard,
  createObjectForCard,
} from "../utils/ShareNote";
import { toggleTextOrList } from "../utils/ShareNote";
import { onSubmit } from "../utils/ShareNote";
import { getLocalImage } from "../utils/LocalImage";

const TLDR = ({
  myContext,
  bold,
  medium,
  large,
  list,
  italic,
  highlight,
  tldrSummary,
  tldrMainInsights,
  tldrQuotes,
  tldrMissingPoints,
  colors,
  setTldrProperty,
  setList,
  setMedium,
  setLarge,
  setBold,
  url,
  getSummary,
}) => {
  const [quote, setQuote] = useState(false);
  const [showSlideSubcomponents, setShowSlideSubcomponents] = useState(false);

  const onPressDelete = (rowIndex, itemIndex) => {
    if (tldrSummary.data[rowIndex].type === "bulletedList") {
      tldrSummary.data[rowIndex].data.splice(itemIndex, 1);
      setList(false);
      setTldrProperty(tldrSummary.data, "summary");
    } else if (tldrSummary.data.length === 1) {
      setBold(false);
      setQuote(false);
      setList(false);
      setMedium(false);
      setLarge(false);
      setTldrProperty(null, "summary");
    } else {
      tldrSummary.data.splice(rowIndex, 1);
      setQuote(false);
      setTldrProperty(tldrSummary.data, "summary");
    }
  };

  const renderSummary = () => {
    return (
      <>
        {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
          "RecommendShareNote"
        ] != null &&
          !tldrSummary &&
          url && (
            <Pressable style={{ marginTop: 24 }} onPress={getSummary}>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 11,
                  paddingVertical: 11,
                  borderRadius: 100,
                  backgroundColor: colors.primaryButtonBackground,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    textAlignVertical: "center",
                    fontSize: 15,
                    fontWeight: "700",
                    color: colors.primaryButtonText,
                  }}
                >
                  {"Summary creation"}
                </Text>
              </View>
            </Pressable>
          )}

        {(tldrSummary ||
          myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
            "RecommendShareNote"
          ] == null) && (
          <>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 24,
                marginBottom: 8,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 14,
                  fontWeight: "700",
                }}
              >
                {"📖 Summary"}
              </Text>
            </View>
            {/* Button */}
            <View style={{ flexDirection: "row" }}>
              {!showSlideSubcomponents && (
                <>
                  <CustomButton
                    style={({ pressed }) => [
                      styles.editButton,
                      {
                        marginBottom: 8,
                        width: 32,
                        backgroundColor: pressed
                          ? villageColors.Accent
                          : villageColors.Stone,
                        opacity: pressed ? 0.5 : 1,
                      },
                    ]}
                    onPress={() => {
                      getLocalImage(myContext, (success, key, status) => {
                        if (success) {
                          console.log("uploaded with key", key);
                          const image = makeImage();
                          const text = makeText();

                          const data = tldrSummary ? tldrSummary.data : [];
                          const newData = [
                            ...data,
                            { ...image, photo: key },
                            text,
                          ];

                          setTldrProperty(newData, "summary");
                        } else {
                          console.log("did not updated because", status);
                        }
                      });
                    }}
                    icon={image_component}
                    imageStyle={styles.imageButton}
                  />
                  <CustomButton
                    style={[
                      styles.buttons,
                      {
                        marginRight: 10,
                        backgroundColor: quote
                          ? villageColors.Raven
                          : villageColors.Stone,
                        borderRadius: 100,
                      },
                    ]}
                    onPress={() => {
                      setQuote((prev) => {
                        return !prev;
                      });
                      if (!quote) {
                        const text = makeQuote();
                        let newData;

                        // if the first text component is empty and the user creates a quote
                        // then the text component will be deleted
                        if (
                          tldrSummary?.data.length === 1 &&
                          tldrSummary?.data[0].type === "text" &&
                          tldrSummary?.data[0].content === null
                        ) {
                          newData = [text];
                        } else {
                          const data = tldrSummary ? tldrSummary.data : [];
                          newData = [...data, text];
                        }
                        setTldrProperty(newData, "summary");
                      }
                    }}
                    icon={quoted}
                    imageStyle={[
                      styles.imageButton,
                      {
                        tintColor: quote
                          ? villageColors.White
                          : villageColors.Raven,
                      },
                    ]}
                  />

                  <CustomButton
                    style={({ pressed }) => [
                      styles.editButton,
                      {
                        backgroundColor: pressed
                          ? villageColors.Accent
                          : villageColors.Stone,
                        opacity: pressed ? 0.5 : 1,
                        flexDirection: "row",
                        paddingHorizontal: 12,
                      },
                    ]}
                    onPress={() => {
                      const card = makeCard();
                      const text = makeText();
                      const data = tldrSummary ? tldrSummary.data : [];
                      const newData = [...data, card, text];
                      setTldrProperty(newData, "summary");
                    }}
                    icon={text_component}
                    imageStyle={styles.imageButton}
                    title={"Text card"}
                    titleStyle={{ color: "black" }}
                  />

                  <CustomButton
                    style={({ pressed }) => [
                      {
                        height: 32,
                        backgroundColor: pressed
                          ? villageColors.Accent
                          : villageColors.Stone,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 12,
                        opacity: pressed ? 0.5 : 1,
                        flexDirection: "row",
                        paddingHorizontal: 12,
                      },
                    ]}
                    onPress={() => {
                      setShowSlideSubcomponents(true);
                    }}
                    icon={image_component}
                    imageStyle={styles.imageButton}
                    title={"Slide show"}
                    titleStyle={{ color: "black" }}
                  />
                </>
              )}

              {showSlideSubcomponents && (
                <>
                  <CustomButton
                    style={({ pressed }) => [
                      styles.editButton,
                      {
                        width: 32,
                        backgroundColor: pressed
                          ? villageColors.Accent
                          : villageColors.Stone,
                        opacity: pressed ? 0.5 : 1,
                      },
                    ]}
                    onPress={() => {
                      if (showSlideSubcomponents) {
                        const card = makeCard();
                        const text = makeText();
                        const slide = makeSlidingPanel(card);
                        const data = tldrSummary ? tldrSummary.data : [];
                        const newData = [...data, slide, text];
                        setTldrProperty(newData, "summary");

                        setShowSlideSubcomponents(false);
                      }
                    }}
                    icon={text_component}
                    imageStyle={styles.imageButton}
                  />

                  <CustomButton
                    style={({ pressed }) => [
                      styles.editButton,
                      {
                        width: 32,
                        backgroundColor: pressed
                          ? villageColors.Accent
                          : villageColors.Stone,
                        opacity: pressed ? 0.5 : 1,
                      },
                    ]}
                    onPress={() => {
                      const text = makeText();
                      if (showSlideSubcomponents) {
                        const image = makeImage();
                        const slide = makeSlidingPanel(image);
                        const data = tldrSummary ? tldrSummary.data : [];
                        const newData = [...data, slide, text];
                        setTldrProperty(newData, "summary");
                      } else {
                        const image = makeImage();
                        const data = tldrSummary ? tldrSummary.data : [];
                        const newData = [...data, image, text];
                        setTldrProperty(newData, "summary");
                      }
                      setShowSlideSubcomponents(false);
                    }}
                    icon={image_component}
                    imageStyle={styles.imageButton}
                  />
                </>
              )}
            </View>

            {/* Input field */}
            {tldrSummary ? (
              <ShareNote
                json={tldrSummary}
                colors={colors}
                showEditButtons={true}
                editNote={true}
                onPressDelete={onPressDelete}
                onPressQuote={(json, rowIndex) =>
                  createObjectForCard({
                    json: json,
                    rowIndex: rowIndex,
                    data: tldrSummary,
                    setData: () => setTldrProperty(tldrSummary.data, "summary"),
                    makeSubcomponent: makeQuote,
                  })
                }
                onPressText={(json, rowIndex) =>
                  createObjectForCard({
                    json: json,
                    rowIndex: rowIndex,
                    data: tldrSummary,
                    setData: () => setTldrProperty(tldrSummary.data, "summary"),
                    makeSubcomponent: makeText,
                  })
                }
                onPressList={(json, rowIndex) => {
                  if (json.data[rowIndex].type === "textCard") {
                    createObjectForCard({
                      json: json,
                      rowIndex: rowIndex,
                      data: tldrSummary,
                      setData: () =>
                        setTldrProperty(tldrSummary.data, "summary"),
                      makeSubcomponent: makeBulletedList,
                    });
                  } else {
                    createObjectForCard({
                      json: json,
                      rowIndex: rowIndex,
                      data: tldrSummary,
                      setData: () =>
                        setTldrProperty(tldrSummary.data, "summary"),
                      makeSubcomponent: makeText,
                    });
                  }
                }}
                onPressTextCard={(json, rowIndex) =>
                  createObjectForCard({
                    json: json,
                    rowIndex: rowIndex,
                    data: tldrSummary,
                    setData: () => setTldrProperty(tldrSummary.data, "summary"),
                    makeSubcomponent: makeCard,
                  })
                }
                onPressImage={(json, rowIndex) =>
                  createObjectForCard({
                    json: json,
                    rowIndex: rowIndex,
                    data: tldrSummary,
                    setData: () => setTldrProperty(tldrSummary.data, "summary"),
                    makeSubcomponent: makeImage,
                  })
                }
                myContext={myContext}
                bold={bold}
                medium={medium}
                large={large}
                italic={italic}
                highlight={highlight}
                quote={quote}
                list={list}
                seed={0}
                item={{ Id: 0 }}
                origin={"createPin"}
                showExpandedNote={true}
                onSubmit={(rowIndex, inQuote, inBulletedList) =>
                  onSubmit({
                    rowIndex,
                    inQuote,
                    inBulletedList,
                    data: tldrSummary,
                    setData: (newData) => setTldrProperty(newData, "summary"),
                  })
                }
                setQuote={setQuote}
                setList={setList}
                toggleTextOrList={(json, rowIndex, inBulletedList) =>
                  toggleTextOrList({
                    json,
                    rowIndex,
                    inBulletedList,
                    list,
                    data: tldrSummary,
                    setData: (newData) => setTldrProperty(newData, "summary"),
                  })
                }
              />
            ) : (
              <Pressable
                onPress={() => {
                  const data = [
                    {
                      type: "text",
                      content: null,
                      key: generateUniqueId(),
                    },
                  ];
                  setTldrProperty(data, "summary");
                }}
                style={{ marginTop: 7 }}
              >
                <Text style={{ fontSize: 15, color: colors.placeholderText }}>
                  {"Add summary..."}
                </Text>
              </Pressable>
            )}
          </>
        )}
      </>
    );
  };

  const renderMainInsights = () => {
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: colors.primaryText,
              fontSize: 14,
              fontWeight: "700",
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            {"💡 Main insights"}
          </Text>
        </View>

        {tldrMainInsights ? (
          <ShareNote
            json={tldrMainInsights}
            colors={colors}
            editNote={true}
            onPressDelete={(rowIndex) => {
              if (tldrMainInsights.data.length === 1) {
                setTldrProperty(null, "mainInsights");
              } else {
                tldrMainInsights.data.splice(rowIndex, 1);
                setTldrProperty(tldrMainInsights.data, "mainInsights");
              }
            }}
            myContext={myContext}
            bold={bold}
            medium={medium}
            large={large}
            list={list}
            seed={0}
            item={{ Id: 0 }}
            origin={"createPin"}
            showExpandedNote={true}
            onSubmit={(rowIndex, inQuote, inBulletedList) =>
              onSubmit({
                rowIndex,
                inQuote,
                inBulletedList,
                data: tldrMainInsights,
                setData: (newData) => setTldrProperty(newData, "mainInsights"),
              })
            }
            toggleTextOrList={(json, rowIndex, inBulletedList) =>
              toggleTextOrList({
                json: json,
                rowIndex: rowIndex,
                inBulletedList: inBulletedList,
                list: list,
                data: tldrMainInsights,
                setData: (newData) => setTldrProperty(newData, "mainInsights"),
              })
            }
          />
        ) : (
          <Pressable
            onPress={() => {
              const data = [
                {
                  type: "text",
                  conten: null,
                  key: generateUniqueId(),
                },
              ];
              setTldrProperty(data, "mainInsights");
            }}
            style={{ marginTop: 7 }}
          >
            <Text style={{ fontSize: 15, color: colors.placeholderText }}>
              {"Add main insights..."}
            </Text>
          </Pressable>
        )}
      </>
    );
  };

  const renderQuotes = () => {
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: colors.primaryText,
              fontSize: 14,
              fontWeight: "700",
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            {"🎙️ Best quotes"}
          </Text>
        </View>

        {tldrQuotes ? (
          <ShareNote
            json={tldrQuotes}
            colors={colors}
            editNote={true}
            onPressDelete={(rowIndex) => {
              if (tldrQuotes.data.length === 1) {
                setTldrProperty(null, "quotes");
              } else {
                tldrQuotes.data.splice(rowIndex, 1);
                setTldrProperty(tldrQuotes.data, "quotes");
              }
            }}
            myContext={myContext}
            quote={true}
            seed={0}
            item={{ Id: 0 }}
            origin={"createPin"}
            showExpandedNote={true}
            onSubmit={() => {
              const text = makeQuote();
              const data = tldrQuotes ? tldrQuotes.data : [];
              const newData = [...data, text];
              setTldrProperty(newData, "quotes");
            }}
          />
        ) : (
          <Pressable
            onPress={() => {
              const data = [
                {
                  type: "quote",
                  data: {
                    type: "text",
                    content: null,
                  },
                  key: generateUniqueId(),
                },
              ];

              setTldrProperty(data, "quotes");
            }}
            style={{ marginTop: 7 }}
          >
            <Text style={{ fontSize: 15, color: colors.placeholderText }}>
              {"Add quotes..."}
            </Text>
          </Pressable>
        )}
      </>
    );
  };

  const renderMissingPoints = () => {
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: colors.primaryText,
              fontSize: 14,
              fontWeight: "700",
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            {"🧩 Missing points"}
          </Text>
        </View>

        {tldrMissingPoints ? (
          <ShareNote
            json={tldrMissingPoints}
            colors={colors}
            editNote={true}
            onPressDelete={() => setTldrProperty(null, "missingPoints")}
            myContext={myContext}
            seed={0}
            item={{ Id: 0 }}
            origin={"createPin"}
            showExpandedNote={true}
            onSubmit={() => {}}
          />
        ) : (
          <Pressable
            onPress={() => {
              const data = [
                {
                  type: "urlList",
                  list: [],
                  key: generateUniqueId(),
                },
              ];

              setTldrProperty(data, "missingPoints");
            }}
            style={{ marginTop: 7 }}
          >
            <Text style={{ fontSize: 15, color: colors.placeholderText }}>
              {"Add missing points..."}
            </Text>
          </Pressable>
        )}
      </>
    );
  };

  return (
    <>
      {renderSummary()}
      {/*   {renderMainInsights()}
      {renderQuotes()}
      {renderMissingPoints()} */}
    </>
  );
};

const ShareNoteEditor = (props, ref) => {
  const {
    myContext,
    navigation,
    bold,
    setBold,
    list,
    setList,
    quote,
    setQuote,
    richShareNote,
    setRichShareNote,
    colors,
    contentModalFunction,
    tldrSummary,
    tldrMainInsights,
    tldrQuotes,
    tldrMissingPoints,
    setTldrProperty,
    setLength,
    fullScreen,
    getSummary,
    url,
  } = props;

  const [showSubcomponents, setShowSubcomponents] = useState(false);
  const [showSlideSubcomponents, setShowSlideSubcomponents] = useState(false);
  const [addRichShareNote, setAddRichShareNote] = useState(false);
  const [editingRichShareNote, setEditingRichShareNote] = useState(false);
  const [isRichShareNote, setIsRichShareNote] = useState(false);

  const [medium, setMedium] = useState(false);
  const [large, setLarge] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [highlight, setHighlight] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      setNote,
    }),
    []
  );

  const setNote = (data) => {
    let note = {
      type: "Note",
      data: data,
      fallback: "",
    };

    setRichShareNote(note);
  };

  const calculationOfLength = () => {
    let size = 0;
    richShareNote?.data?.forEach((item) => {
      if (item.type === "quote") {
        if (item.data?.content) {
          size += item.data?.content?.length;
        }
      } else if (item.type === "text") {
        if (item.content) {
          size += item.content?.length;
        }
      } else if (item.type === "bulletedList") {
        item.data.forEach((itemList) => {
          if (itemList.content) {
            size += itemList.content?.length;
          }
        });
      }
    });

    setLength(size);
  };

  const onPressDelete = (rowIndex, itemIndex) => {
    if (richShareNote.data[rowIndex].type === "bulletedList") {
      richShareNote.data[rowIndex].data.splice(itemIndex, 1);
      setList(false);
      setNote(richShareNote.data);
    } else if (richShareNote.data.length === 1) {
      setRichShareNote(null);
      setBold(false);
      setQuote(false);
      setList(false);
      setMedium(false);
      setLarge(false);
    } else {
      richShareNote.data.splice(rowIndex, 1);
      setQuote(false);
      setNote(richShareNote.data);
    }
  };

  const onDeleteRichNote = () => {
    setRichShareNote(null);
    setAddRichShareNote(false);
    setShowSubcomponents(false);
    setShowSlideSubcomponents(false);
  };

  const renderRichShareNoteButtons = () => {
    return (
      <View style={{ marginBottom: 10 }}>
        <View style={{ flexDirection: "row" }}>
          <CustomButton
            style={({ pressed }) => [
              styles.editButton,
              {
                backgroundColor: addRichShareNote
                  ? villageColors.Accent
                  : villageColors.Stone,
                opacity: pressed ? 0.5 : 1,
                width: 32,
              },
            ]}
            onPress={() => {
              setAddRichShareNote(!addRichShareNote);
              setShowSubcomponents(true);
              setShowSlideSubcomponents(false);
            }}
            icon={plus}
            imageStyle={{ width: 25, height: 25, tintColor: "black" }}
          />
          {richShareNote !== null && (
            <>
              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    backgroundColor: editingRichShareNote
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity: pressed ? 0.5 : 1,
                    paddingHorizontal: 10,
                  },
                ]}
                onPress={() => setEditingRichShareNote(!editingRichShareNote)}
                title={"Edit"}
                titleStyle={{ color: "black" }}
              />

              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    backgroundColor: pressed
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity: pressed ? 0.5 : 1,
                    width: 32,
                  },
                ]}
                onPress={onDeleteRichNote}
                icon={trash}
                imageStyle={{
                  width: 15,
                  height: 15,
                  tintColor: "black",
                }}
              />
            </>
          )}
          {isRichShareNote && (
            <>
              <CustomButton
                style={{
                  padding: 4,
                  borderRadius: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: medium
                    ? villageColors.Accent
                    : villageColors.Stone,
                  marginRight: 20,
                }}
                onPress={() => {
                  if (large) {
                    setLarge(false);
                  }
                  setMedium((prev) => {
                    return !prev;
                  });
                }}
                title={"Medium"}
                titleStyle={{
                  color: medium ? villageColors.White : villageColors.Raven,
                }}
              />
              <CustomButton
                style={{
                  padding: 4,
                  borderRadius: 100,
                  justifyContent: "center",
                  backgroundColor: large
                    ? villageColors.Accent
                    : villageColors.Stone,
                  marginRight: 20,
                }}
                onPress={() => {
                  if (medium) {
                    setMedium(false);
                  }
                  setLarge((prev) => {
                    return !prev;
                  });
                }}
                title={"Large"}
                titleStyle={{
                  color: large ? villageColors.White : villageColors.Raven,
                }}
              />
              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    backgroundColor: italic
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity: pressed ? 0.5 : 1,
                    width: 42,
                  },
                ]}
                onPress={() =>
                  setItalic((prev) => {
                    return !prev;
                  })
                }
                title={"Italic"}
                titleStyle={{ color: "black" }}
              />
              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    backgroundColor: highlight
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity: pressed ? 0.5 : 1,
                    width: 72,
                  },
                ]}
                onPress={() =>
                  setHighlight((prev) => {
                    return !prev;
                  })
                }
                title={"Highlight"}
                titleStyle={{ color: "black" }}
              />
            </>
          )}
        </View>
        {/** COMPONENTS */}

        {addRichShareNote && (
          <View
            style={{
              flexDirection: "row",
              marginTop: 5,
            }}
          >
            {showSlideSubcomponents && (
              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    width: 32,
                    backgroundColor: pressed
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity: pressed ? 0.5 : 1,
                  },
                ]}
                onPress={() => {
                  if (showSlideSubcomponents) {
                    const card = makeCard();
                    const slide = makeSlidingPanel(card);
                    const data = richShareNote ? richShareNote.data : [];
                    const newData = [...data, slide];
                    setNote(newData);

                    setAddRichShareNote(false);
                    setShowSlideSubcomponents(false);
                    setEditingRichShareNote(true);
                  }
                }}
                icon={text_component}
                imageStyle={styles.imageButton}
              />
            )}
            {(showSubcomponents || showSlideSubcomponents) && (
              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    width: 32,
                    backgroundColor: pressed
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity: pressed ? 0.5 : 1,
                  },
                ]}
                onPress={() => {
                  if (showSlideSubcomponents) {
                    const image = makeImage();
                    const slide = makeSlidingPanel(image);
                    const data = richShareNote ? richShareNote.data : [];
                    const newData = [...data, slide];
                    setNote(newData);
                  } else {
                    const image = makeImage();
                    const text = makeText();
                    const data = richShareNote ? richShareNote.data : [];
                    const newData = [...data, image, text];
                    setNote(newData);
                  }

                  setAddRichShareNote(false);
                  setEditingRichShareNote(true);
                }}
                icon={image_component}
                imageStyle={styles.imageButton}
              />
            )}

            {showSubcomponents && (
              <>
                <CustomButton
                  style={({ pressed }) => [
                    styles.editButton,
                    {
                      backgroundColor: pressed
                        ? villageColors.Accent
                        : villageColors.Stone,
                      opacity: pressed ? 0.5 : 1,
                      flexDirection: "row",
                      paddingHorizontal: 12,
                    },
                  ]}
                  onPress={() => {
                    const card = makeCard();
                    const data = richShareNote ? richShareNote.data : [];
                    const newData = [...data, card];
                    setNote(newData);

                    setAddRichShareNote(false);
                    setEditingRichShareNote(true);
                  }}
                  icon={text_component}
                  imageStyle={styles.imageButton}
                  title={"Text card"}
                  titleStyle={{ color: "black" }}
                />

                <CustomButton
                  style={({ pressed }) => [
                    {
                      height: 32,
                      backgroundColor: pressed
                        ? villageColors.Accent
                        : villageColors.Stone,
                      borderRadius: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 12,
                      opacity: pressed ? 0.5 : 1,
                      flexDirection: "row",
                      paddingHorizontal: 12,
                    },
                  ]}
                  onPress={() => {
                    setShowSubcomponents(false);
                    setShowSlideSubcomponents(true);
                  }}
                  icon={image_component}
                  imageStyle={styles.imageButton}
                  title={"Slide show"}
                  titleStyle={{ color: "black" }}
                />
              </>
            )}
          </View>
        )}
      </View>
    );
  };

  return (
    <>
      {myContext.actionsByUser?.["Experiment"]?.["Join"]?.["RichShareNote"] !=
        null &&
        fullScreen && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 14,
                fontWeight: "700",
              }}
            >
              {"Sharenote"}
            </Text>

            {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
              "RichShareNote"
            ] != null && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 14,
                    fontWeight: "400",
                    marginRight: 10,
                  }}
                >
                  {"Rich?"}
                </Text>
                <Switch
                  trackColor={{ false: "#767577", true: colors.accent }}
                  thumbColor={isRichShareNote ? "#f5dd4b" : "#f4f3f4"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={() => {
                    setIsRichShareNote((previousState) => {
                      if (previousState) {
                        //setRichShareNote(null);
                      }
                      return !previousState;
                    });
                  }}
                  value={isRichShareNote}
                />
              </View>
            )}
          </View>
        )}

      {isRichShareNote && (
        <ScrollView
          style={{ marginTop: 8 }}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
        >
          {renderRichShareNoteButtons()}
        </ScrollView>
      )}

      <View style={{ marginTop: 24 }}>
        {richShareNote ? (
          <ShareNote
            json={richShareNote}
            colors={colors}
            showEditButtons={editingRichShareNote}
            editNote={true}
            onPressText={(json, rowIndex) =>
              createObjectForCard({
                json: json,
                rowIndex: rowIndex,
                data: richShareNote,
                setData: (newData) => setNote(newData),
                makeSubcomponent: makeText,
              })
            }
            onPressQuote={(json, rowIndex) =>
              createObjectForCard({
                json: json,
                rowIndex: rowIndex,
                data: richShareNote,
                setData: (newData) => setNote(newData),
                makeSubcomponent: makeQuote,
              })
            }
            onPressList={(json, rowIndex) => {
              if (json.data[rowIndex].type === "textCard") {
                createObjectForCard({
                  json: json,
                  rowIndex: rowIndex,
                  data: richShareNote,
                  setData: (newData) => setNote(newData),
                  makeSubcomponent: makeBulletedList,
                });
              } else {
                createObjectForCard({
                  json: json,
                  rowIndex: rowIndex,
                  data: richShareNote,
                  setData: (newData) => setNote(newData),
                  makeSubcomponent: makeQuote,
                });
              }
            }}
            onPressDelete={onPressDelete}
            onPressTextCard={(json, rowIndex) =>
              createObjectForCard({
                json: json,
                rowIndex: rowIndex,
                data: richShareNote,
                setData: (newData) => setNote(newData),
                makeSubcomponent: makeCard,
              })
            }
            onPressImage={(json, rowIndex) =>
              createObjectForCard({
                json: json,
                rowIndex: rowIndex,
                data: richShareNote,
                setData: (newData) => setNote(newData),
                makeSubcomponent: makeImage,
              })
            }
            myContext={myContext}
            bold={bold}
            medium={medium}
            large={large}
            italic={italic}
            highlight={highlight}
            list={list}
            quote={quote}
            seed={0}
            item={{ Id: 0 }}
            origin={"createPin"}
            navigation={navigation}
            contentModalFunction={contentModalFunction}
            showExpandedNote={true}
            onSubmit={(rowIndex, inQuote, inBulletedList) =>
              onSubmit({
                rowIndex,
                inQuote,
                inBulletedList,
                data: richShareNote,
                setData: (newData) => setNote(newData),
              })
            }
            setQuote={setQuote}
            setList={setList}
            calculationOfLength={calculationOfLength}
            toggleTextOrList={(json, rowIndex, inBulletedList) =>
              toggleTextOrList({
                json,
                rowIndex,
                inBulletedList,
                list,
                data: richShareNote,
                setData: (newData) => setNote(newData),
              })
            }
          />
        ) : (
          <Pressable
            onPress={() => setNote([makeText()])}
            style={{ marginTop: 7 }}
          >
            <Text style={{ fontSize: 15, color: colors.placeholderText }}>
              {"Start writing your thoughts..."}
            </Text>
          </Pressable>
        )}
      </View>

      {myContext.actionsByUser?.["Experiment"]?.["Join"]?.["RichShareNote"] !=
        null &&
        fullScreen && (
          <TLDR
            myContext={myContext}
            bold={bold}
            medium={medium}
            large={large}
            list={list}
            italic={italic}
            highlight={highlight}
            tldrSummary={tldrSummary}
            tldrMainInsights={tldrMainInsights}
            tldrQuotes={tldrQuotes}
            tldrMissingPoints={tldrMissingPoints}
            colors={colors}
            setTldrProperty={setTldrProperty}
            setList={setList}
            setMedium={setMedium}
            setLarge={setLarge}
            setBold={setBold}
            url={url}
            getSummary={getSummary}
          />
        )}
    </>
  );
};

export default forwardRef(ShareNoteEditor);

const styles = StyleSheet.create({
  buttons: {
    width: 32,
    height: 32,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  editButton: {
    height: 32,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  imageButton: {
    width: 25,
    height: 25,
  },
});
