import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
  TextInput,
  Dimensions,
} from "react-native";
import { Modalize } from "react-native-modalize";
import { useTheme } from "../theme";
import { Portal } from "react-native-portalize";
import puffTLDR from "../assets/puff-tldr-report.png";
import backArrow from "../assets/back-arrow.png";
import ColoredButton from "./ColoredButton";
import { addReportForObject } from "../utils/Report";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const ReportSheet = (props, ref) => {
  const { item, context, myContext, onShowReportPopUp } = props;

  const reportRef = useRef(null);
  const { colors } = useTheme();

  const [phase, setPhase] = useState(1);
  const [feedback, setFeedback] = useState("");
  const { width, height } = Dimensions.get("window");
  const insets = useSafeAreaInsets();

  const onOpen = () => {
    reportRef.current?.open();
  };

  const onClose = () => {
    reportRef.current?.close();
  };

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    []
  );

  const closeFeedbackSheet = () => {
    reportRef.current?.close();
    setFeedback("");
    setPhase(1);
  };

  const onSubmitFeedback = () => {
    submitReport("Inappropriate");
  };

  const submitReport = async (type) => {
    console.log("submit report for", type, "on", context, "for", item);
    let objectType = null;
    let objectId = null;
    let note = type;
    let message = "";
    switch (context) {
      case "original":
        objectType = "Url";
        objectId = item?.Id;
        break;
      case "sharenote":
        objectType = "Pin";
        objectId = item?.topActions?.[0]?.target?.Id;
        if (type == "Broken") {
          objectType = "Url";
          objectId = item?.Id;
        }
        break;
      case "comment":
        objectType = "Comment";
        objectId = item?.Id;
        break;
    }
    if (feedback) {
      note += ": " + feedback;
    }

    if (objectType && objectId) {
      console.log("ObjectType", objectType, "ObjectId", objectId, "note", note);
      await addReportForObject({
        objectId: objectId,
        objectType: objectType,
        note: note,
        myContext: myContext,
        callback: ({ success, message, error }) => {
          if (success) {
            closeFeedbackSheet();
          } else {
            alert("cannot save report");
          }
        },
      });
    }

    if (type === "Dislike") {
      message = "We’ll show fewer contents like this";
    } else {
      message = "Thanks for your feedback!";
    }

    if (message) {
      onShowReportPopUp(message);
    }
  };

  const HeaderComponent = () => {
    const getTitle = () => {
      switch (context) {
        case "original":
          return "Original Content";
        case "sharenote":
          return "Sharenote";
        case "comment":
          return "Comment";
      }
    };

    return (
      <View>
        {phase === 1 ? (
          <View style={styles.headerContainer}>
            <View style={{ marginRight: 6 }}>
              <Image
                source={puffTLDR}
                style={{ width: 24, height: 24, tintColor: colors.primaryText }}
              />
            </View>
            <Text style={styles.text}>{"Feedback on " + getTitle()}</Text>
          </View>
        ) : (
          <View style={styles.listHeaderContainer}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pressable
                onPress={() => {
                  setPhase(1);
                  setFeedback("");
                }}
              >
                <Image source={backArrow} style={{ height: 24, width: 24 }} />
              </Pressable>

              <View style={{ marginLeft: 12 }}>
                <Text
                  style={[styles.title, { color: colors.primaryText }]}
                  numberOfLines={1}
                >
                  {"Feedback"}
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={reportRef}
        adjustToContentHeight={true}
        modalStyle={[
          styles.modalContainer,
          { backgroundColor: colors.background },
          phase === 2 && { minHeight: height * 0.5 },
        ]}
        scrollViewProps={{ showsVerticalScrollIndicator: false }}
        withHandle={false}
        onClose={() => {
          setFeedback("");
          setPhase(1);
        }}
        HeaderComponent={HeaderComponent}
        FloatingComponent={
          phase === 2 && (
            <View
              style={{
                backgroundColor: colors.background,
                paddingHorizontal: 16,
              }}
            >
              <ColoredButton
                onPress={onSubmitFeedback}
                buttonStyle={[
                  styles.button,
                  { backgroundColor: colors.primaryButtonBackground },
                ]}
                text={"Submit"}
                textStyle={[
                  styles.titleButton,
                  {
                    color: colors.primaryButtonText,
                  },
                ]}
              />

              <View style={{ height: insets.bottom ? insets.bottom : 10 }} />
            </View>
          )
        }
      >
        {phase === 1 ? (
          <View>
            <Pressable
              style={({ pressed }) => ({
                paddingVertical: 16,
                opacity: pressed ? 0.5 : 1,
              })}
              onPress={() => {
                submitReport("Dislike");
              }}
            >
              <Text style={styles.text}>{"Don't like it"}</Text>
            </Pressable>
            {context !== "comment" && (
              <Pressable
                style={({ pressed }) => ({
                  paddingVertical: 16,
                  opacity: pressed ? 0.5 : 1,
                })}
                onPress={() => {
                  submitReport("Broken");
                }}
              >
                <Text style={styles.text}>{"Broken link"}</Text>
              </Pressable>
            )}
            <Pressable
              style={({ pressed }) => ({
                paddingVertical: 16,
                opacity: pressed ? 0.5 : 1,
              })}
              onPress={() => {
                submitReport("Spam");
              }}
            >
              <Text style={styles.text}>{"Spam"}</Text>
            </Pressable>
            <Pressable
              onPress={() => setPhase(2)}
              style={({ pressed }) => ({
                paddingVertical: 16,
                opacity: pressed ? 0.5 : 1,
              })}
            >
              <Text style={styles.text}>
                {"Inappropriate or offensive content"}
              </Text>
            </Pressable>
          </View>
        ) : (
          <View>
            <TextInput
              value={feedback}
              onChangeText={(value) => setFeedback(value)}
              style={{
                minHeight: 180,
                padding: 12,
                borderWidth: 1,
                borderRadius: 8,
                borderColor: colors.smallDivider,
                fontSize: 18,
                fontWeight: "700",
                lineHeight: 28,
                color: colors.primaryText,
              }}
              placeholderTextColor={colors.placeholderText}
              placeholder={"What did you find inappropriate or offensive?"}
              multiline={true}
            />
          </View>
        )}

        <View style={{ height: insets.bottom ? insets.bottom : 10 }} />
      </Modalize>
    </Portal>
  );
};

export default forwardRef(ReportSheet);

const styles = StyleSheet.create({
  modalContainer: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
  },
  headerContainer: {
    marginTop: 21,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 22,
  },
  listHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
    marginBottom: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 28,
  },
  button: {
    height: 54,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    marginTop: 12,
  },
  titleButton: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: "700",
    textAlign: "center",
  },
});
