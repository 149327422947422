import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import {
  Alert,
  AppState,
  StyleSheet,
  Image,
  Text,
  View,
  Platform,
  Pressable,
  Dimensions,
  SafeAreaView,
  Modal,
  Animated,
} from "react-native";
import { WebView } from "react-native-webview";
import AppContext from "../components/AppContext";
import ItemFooter from "../components/ItemFooter";
import PinCurator from "../components/PinCurator";
import cross from "../assets/cross.png";
import playIcon from "../assets/play.png";
import pauseIcon from "../assets/pause.png";
import formatted from "../assets/formatted.png";
import plaintext from "../assets/plaintext.png";

import Blocker from "../components/Blocker";
import AwesomeAlert from "react-native-awesome-alerts";
import { findTopActions, reorderActions } from "../utils/TopActions";
import LeftArrow from "../components/LeftArrow";
import { clog } from "../utils/Log";
import { getUrlPinsAndComments } from "../controllers/UrlController";
import { logEvent } from "../utils/LogEvent";
import { fetchFollowersIfNecessary } from "../utils/DataFetcher";
import * as Speech from "expo-speech";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "../theme";
import { reportUrlVisit } from "../utils/Tracker";

const HeaderBar = ({
  HEADER_BAR_HEIGHT,
  headerBarTranslate,
  navigation,
  myContext,
  justificationTarget,
  origin,
  showPlaintext,
  setShowPlaintext,
  speak,
  pauseSpeaking,
}) => {
  const insets = useSafeAreaInsets();
  const [playing, setPlaying] = useState(false);

  const { colors } = useTheme();

  return (
    <Animated.View
      style={[
        styles.headerBar,
        {
          top: -HEADER_BAR_HEIGHT + insets.top,
          height: HEADER_BAR_HEIGHT,
          transform: [
            {
              translateY: headerBarTranslate,
            },
          ],
          backgroundColor: colors.background,
          width: "100%",
        },
      ]}
    >
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Pressable
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={() => {
              clog("Pressed left button");
              navigation.goBack();
            }}
          >
            <View style={{ marginLeft: 10, marginRight: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>

          <PinCurator
            curatorId={justificationTarget?.curatorId}
            pin={justificationTarget}
            myContext={myContext}
            navigation={navigation}
            refererActionId={null}
            hostUserId={null}
            origin={origin}
            colors={colors}
          />
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
            "ContentChoosePlainText"
          ] != null && (
            <Pressable
              style={{ paddingRight: 10 }}
              onPress={() => {
                console.log("Pressed text button", myContext.speechOngoing);
                setShowPlaintext((prev) => {
                  return !prev;
                });
              }}
            >
              <Image
                source={
                  showPlaintext
                    ? formatted
                    : myContext.content?.length
                    ? plaintext
                    : ""
                }
                style={
                  showPlaintext
                    ? {
                        height: 24,
                        width: 24,
                        marginRight: 12,
                      }
                    : {
                        height: 24,
                        width: 24,
                        marginRight: 12,
                        tintColor: colors.primaryText,
                      }
                }
              />
            </Pressable>
          )}

          {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
            "ContentTextToSpeech"
          ] != null && (
            <Pressable
              style={{ paddingRight: 10 }}
              onPress={() => {
                console.log("Pressed right button", myContext.speechOngoing);
                if (!myContext.speechOngoing) {
                  speak();
                  setPlaying(true);
                } else {
                  pauseSpeaking();
                  setPlaying(false);
                }
              }}
            >
              <Image
                source={
                  !playing
                    ? myContext.content?.length
                      ? playIcon
                      : ""
                    : pauseIcon
                }
                style={{
                  height: 24,
                  width: 24,
                  tintColor: colors.primaryText,
                  marginRight: 12,
                }}
              />
            </Pressable>
          )}
        </View>
      </View>
    </Animated.View>
  );
};

const CommentBar = ({
  item,
  COMMENT_BAR_HEIGHT,
  HEIGHT_BOTTOM_MENU,
  commentBarTranslate,
  myContext,
  origin,
  navigation,
  setLoading,
  showStatusMessage,
  getData,
}) => {
  const { colors } = useTheme();

  return (
    <Animated.View
      style={[
        styles.commentBar,
        {
          backgroundColor: colors.background,
          height: COMMENT_BAR_HEIGHT + HEIGHT_BOTTOM_MENU,
          transform: [
            {
              translateY: commentBarTranslate,
            },
          ],
        },
      ]}
    >
      <ItemFooter
        url={item}
        myContext={myContext}
        navigation={navigation}
        setLoading={setLoading}
        showAlertMessage={showStatusMessage}
        origin={origin}
        forceRefresh={(dummy, callback) => {
          getData(item.Id, callback);
        }}
        colors={colors}
        context={"consumption"}
      />
    </Animated.View>
  );
};

const ItemDetailsScreen = ({ route, navigation }) => {
  const { colors } = useTheme();

  const HEIGHT_BOTTOM_MENU = Platform.OS === "web" ? 0 : 70;

  async function getData(Id, callback = null) {
    getUrlPinsAndComments({
      Id: Id,
      myContext: myContext,
      callback: ({ success, message, error, annotatedUrl }) => {
        if (success) {
          let lurl = { ...url, ...annotatedUrl };
          lurl["topActions"] = findTopActions(lurl, myContext);
          lurl.topActions = reorderActions(lurl.topActions);
          clog("UPDATED URL", lurl);
          if (incomplete) {
            setUrl(lurl);
            setMUrl(lurl);
            setIncomplete(false);
          } else {
            setMUrl(lurl);
          }
        } else {
          clog(message);
        }
        if (callback) {
          callback();
        }
      },
    });
  }

  const showAlertMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const showStatusMessage = (message) => {
    setStatusMessage(message);
    setStatusVisible(true);
    setTimeout(() => {
      setStatusVisible(false);
    }, 3000);
  };

  const canContinueSpeaking = () => {
    if (
      myContext.speechOngoing &&
      myContext.contentIndex < myContext?.content?.length - 1
    ) {
      return true;
    }
    return false;
  };

  const speak = () => {
    myContext.speechOngoing = true;
    if (canContinueSpeaking()) {
      continueSpeaking();
    } else {
      console.log(
        "Cannot speak",
        myContext.contentIndex,
        myContext.content?.length
      );
    }
  };

  const continueSpeaking = () => {
    console.log(
      "Will speak",
      myContext.contentIndex,
      myContext.content[myContext.contentIndex]
    );
    Speech.speak(myContext.content[myContext.contentIndex], {
      onDone: () => {
        console.log("DONE SPEAKING", myContext.contentIndex);
        //setContentIndex(contentIndex + 1);
        myContext.contentIndex++;
        if (canContinueSpeaking()) {
          continueSpeaking();
        } else {
          console.log(
            "CANNOT CONTINUE",
            myContext.contentIndex,
            myContext.content?.length
          );
        }
      },
    });
  };

  const pauseSpeaking = () => {
    myContext.speechOngoing = false;
    Speech.stop();
  };

  const onMessage = (event) => {
    //console.log("Received: ", event.nativeEvent.data);
    let payload = event.nativeEvent.data;
    //console.log(payload);
    let parts = payload.split("\n");
    console.log("parts", parts.length);
    myContext["content"] = parts;
    myContext["contentIndex"] = 0;
    myContext["speechOngoing"] = false;
    if (content.length !== parts.length) {
      setContent(parts);
    }
    //speak();
    //Speech.speak(payload);
  };

  const showContent = (item) => {
    console.log("WILL REFRESH CONTENT", colors.background);
    let rewrittenUrl = item.uri;
    const youtubePattern = /(.*youtube.com\/)watch\?v=(.*)$/;
    //const youtubePattern = "y";
    let matches = item?.uri?.match(youtubePattern);
    if (matches) {
      rewrittenUrl = matches[1] + "embed/" + matches[2];
    }
    clog("WILL RENDER", item);
    let enableJavascript = true;
    if (item?.source?.enableJavascript != null) {
      enableJavascript = item.source.enableJavascript;
    }
    clog("ENABLE JAVASCRIPT", enableJavascript);
    let screenshotUrl = item?.screenshotUrl
      ? item.screenshotUrl
      : item?.photo
      ? myContext.presignedUrls[item.photo]?.uri
        ? myContext.presignedUrls[item.photo]?.uri
        : item.photoUrl
      : item.photoUrl;
    clog("PHOTO URL", screenshotUrl);
    const jsCode = "window.ReactNativeWebView.postMessage(document.innerHTML)";

    if (Platform.OS === "web") {
      return (
        <View
          style={{
            flex: 1,
            paddingTop: 10,
          }}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{ height: "100%", width: "100%", flex: 1 }}
              source={{
                uri: screenshotUrl,
              }}
            />
            <Pressable
              style={{
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                myContext["startContentVisit"] = Date.now();
                window.open(rewrittenUrl, "_blank");
              }}
            >
              <View
                style={{
                  width: 200,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    position: "absolute",
                    fontWeight: "bold",
                    fontSize: 20,
                    color: colors.primaryText,
                    textAlign: "center",
                    padding: 10,
                    borderRadius: 10,
                    backgroundColor: colors.labelBackground,
                  }}
                >
                  open in a new tab
                </Text>
              </View>
            </Pressable>
          </View>
          <View
            style={{
              paddingTop: 12,
              paddingBottom: 20,
              paddingLeft: 16,
              paddingRight: 12,
              backgroundColor: colors.background,
            }}
          >
            <ItemFooter
              url={item}
              myContext={myContext}
              navigation={navigation}
              setLoading={setLoading}
              showAlertMessage={showStatusMessage}
              origin={origin}
              forceRefresh={(dummy, callback) => {
                getData(url.Id, callback);
              }}
              colors={colors}
              context={"consumption"}
            />
          </View>
        </View>
      );
    } else {
      const AnimatedWebView = Animated.createAnimatedComponent(WebView);

      return (
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {!showPlaintext && (
            <WebView
              source={{ uri: rewrittenUrl }}
              ref={(r) => (webref = r)}
              originWhitelist={["*"]}
              javaScriptEnabled={enableJavascript}
              onMessage={onMessage}
              injectedJavaScript={
                myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
                  "ContentTextToSpeech"
                ] != null
                  ? "window.ReactNativeWebView.postMessage(document.body.innerText)"
                  : ""
              }
              domStorageEnabled={true}
              startInLoadingState={true}
              onScroll={Animated.event(
                [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                { useNativeDriver: false }
              )}
              style={{
                height: 200,
                width: "100%",
                zIndex: 0,
                elevation: 0,
              }}
            />
          )}
          {showPlaintext && (
            <Animated.ScrollView
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                transform: [
                  {
                    translateY: headerBarTranslate,
                  },
                ],
              }}
              onScroll={Animated.event(
                [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                { useNativeDriver: true }
              )}
            >
              <Text style={{ color: "white", fontSize: 16, flexWrap: "wrap" }}>
                {myContext.content.join("\n")}
              </Text>
            </Animated.ScrollView>
          )}
        </View>
      );
    }
  };

  const reportVisitIfApplicable = async (myContext, url, origin) => {
    if (myContext["startContentVisit"]) {
      let finishVisit = Date.now();
      let duration = finishVisit - myContext["startContentVisit"];
      reportUrlVisit(
        url,
        myContext,
        duration,
        Math.floor(myContext["startContentVisit"] / 1000),
        "View",
        origin
      );
      myContext["startContentVisit"] = null;
    }
  };

  let refererActionId = route?.params?.refererActionId;
  let hostUserId = route?.params?.hostUserId;
  let origin = route?.params?.origin;

  const initialState = {};
  const [url, setUrl] = useState(route?.params?.url);
  const [mUrl, setMUrl] = useState(route?.params?.url);
  const [incomplete, setIncomplete] = useState(route?.params?.incomplete);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalUrl, setModalUrl] = useState("");
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [content, setContent] = useState([]);
  const [showPlaintext, setShowPlaintext] = useState(false);

  const myContext = useContext(AppContext);
  const appState = useRef(AppState.currentState);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  useEffect(() => {
    if (Platform.OS != "web") {
      myContext["startContentVisit"] = Date.now();
    }
    console.log("Visiting item details screen");
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        console.log("App has come to the foreground!");
        reportVisitIfApplicable(myContext, url, origin);
      }

      appState.current = nextAppState;
      clog("AppState", appState.current);
    });

    return () => {
      console.log("Closed the item details screen");
      reportVisitIfApplicable(myContext, url);
      subscription.remove();
    };
  }, []);

  //Animation
  const COMMENT_BAR_HEIGHT = 55;
  const HEADER_BAR_HEIGHT = 50;

  const scrollY = useRef(new Animated.Value(0)).current;
  const offsetAnim = useRef(new Animated.Value(0)).current;

  const clampedScroll = Animated.diffClamp(
    Animated.add(
      scrollY.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolateLeft: "clamp",
      }),
      offsetAnim
    ),
    0,
    COMMENT_BAR_HEIGHT
  );

  const commentBarTranslate = clampedScroll.interpolate({
    inputRange: [0, COMMENT_BAR_HEIGHT],
    outputRange: [0, COMMENT_BAR_HEIGHT + HEIGHT_BOTTOM_MENU],
    extrapolate: "clamp",
  });

  const headerBarTranslate = clampedScroll.interpolate({
    inputRange: [0, HEADER_BAR_HEIGHT],
    outputRange: [HEADER_BAR_HEIGHT, 0],
    extrapolate: "clamp",
  });

  let webref = null;
  let flatListRef = null;

  clog("context", myContext);

  clog("PARAMS", route.params);
  clog("will render url", url);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", pauseSpeaking);
    return unsubscribe;
  }, [navigation]);

  const windowDimension = Dimensions.get("window");
  const screen = Dimensions.get("screen");

  screenWidth = windowDimension.width;

  let currentTime = Date.now();
  let topActions = url?.topActions
    ? reorderActions([...url?.topActions])
    : null;
  let topAction = topActions?.[0]?.action;
  let topTarget = topActions?.[0]?.target;
  let justificationAction = url?.justificationAction?.action;
  let justificationTarget = url?.justificationAction?.target;
  // find the curator closest to the reader
  let curator = myContext.users[justificationTarget?.curatorId];
  let otherMessages = [];
  let seenAction = {};
  if (topTarget?.Id) {
    seenAction[topTarget.Id] = true;
  }
  topActions?.forEach((a) => {
    let target = a?.target;
    if (target && target.Id != topTarget.Id && !seenAction[target.Id]) {
      otherMessages.push({
        type: a.action.objectType,
        data: target,
        Id: target.Id,
      });
      seenAction[target.Id] = true;
    }
  });

  clog("other messages", otherMessages);
  clog("top target", topTarget);

  clog("URL", url);
  clog("INCOMPLETE", incomplete);

  useEffect(() => {
    clog("URL ID", url.Id, "INCOMPLETE", incomplete);
    if (url.Id && incomplete) {
      clog("Will try to get data");
      getData(url.Id).catch((err) => {
        console.log("error finding url data: ", err);
      });
    }
  }, []);

  logEvent(origin == "myvillage" ? "MyVillage_Content_View" : "Content_View", {
    userId: myContext?.Id,
    username: myContext?.handle,
    category: origin,
    platform: Platform.OS,
    appVersion: myContext.appVersion,
  });

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation, state, content, showPlaintext, colors]);

  fetchFollowersIfNecessary(myContext);

  const memoHeaderBar = useMemo(
    () => (
      <HeaderBar
        HEADER_BAR_HEIGHT={HEADER_BAR_HEIGHT}
        headerBarTranslate={headerBarTranslate}
        navigation={navigation}
        myContext={myContext}
        justificationTarget={justificationTarget}
        origin={origin}
        showPlaintext={showPlaintext}
        setShowPlaintext={setShowPlaintext}
        speak={speak}
        pauseSpeaking={pauseSpeaking}
      />
    ),
    [showPlaintext]
  );

  const memoCommentBar = useMemo(
    () => (
      <CommentBar
        item={mUrl}
        COMMENT_BAR_HEIGHT={COMMENT_BAR_HEIGHT}
        HEIGHT_BOTTOM_MENU={HEIGHT_BOTTOM_MENU}
        commentBarTranslate={commentBarTranslate}
        myContext={myContext}
        origin={origin}
        navigation={navigation}
        setLoading={setLoading}
        showStatusMessage={showStatusMessage}
        getData={getData}
      />
    ),
    [mUrl]
  );

  return (
    <View style={{ flex: 1, alignItems: "center" }}>
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <SafeAreaView
          style={[styles.safeAreaView, { backgroundColor: colors.background }]}
        />
        {statusVisible && (
          <View
            style={{
              height: 48,
              width: "100%",
              paddingLeft: 16,
              paddingRight: 16,
              position: "absolute",
              top: 200,
              zIndex: 3, // works on ios
              elevation: 3, // works on android
            }}
          >
            <Pressable
              onPress={() => {
                setStatusVisible(false);
              }}
            >
              <View
                style={{
                  zIndex: 3, // works on ios
                  elevation: 3, // works on android
                  alignItems: "center",
                  width: "100%",
                  height: 48,
                  backgroundColor: colors.notificationBackground,
                  borderWidth: 1,
                  borderColor: "black",
                  borderRadius: 8,
                  justifyContent: "center",
                }}
              >
                {statusMessage}
              </View>
            </Pressable>
          </View>
        )}
        <Blocker loading={loading} />
        {memoHeaderBar}
        {!incomplete && showContent(url)}
        {!incomplete && memoCommentBar}
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title=""
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          cancelText="Ok"
          cancelButtonColor={colors.cancelButton}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
        />
        <Modal
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(false);
          }}
        >
          <SafeAreaView
            style={[styles.container, { backgroundColor: colors.background }]}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  backgroundColor: colors.background,
                  height: 39,
                  paddingVertical: 10,
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Pressable
                  onPress={() => {
                    clog("will try to open", state.websites);
                    setModalVisible(false);
                  }}
                >
                  <Image
                    source={cross}
                    style={{
                      marginRight: 20,
                      height: 19,
                      width: 17,
                      tintColor: colors.primaryText,
                    }}
                  />
                </Pressable>
              </View>
              <WebView
                style={{ flex: 1 }}
                source={{ uri: modalUrl }}
                ref={(r) => (webref = r)}
                originWhitelist={["*"]}
                javaScriptEnabled={true}
                domStorageEnabled={true}
              />
            </View>
          </SafeAreaView>
        </Modal>
      </View>
    </View>
  );
};

export default ItemDetailsScreen;
let screenWidth = 0;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  commentBar: {
    bottom: 0,
    position: "absolute",
    width: "100%",
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 12,
  },
  headerBar: {
    zIndex: 1000,
    position: "absolute",
    flexDirection: "row",
  },
  safeAreaView: {
    zIndex: 2000,
  },
});
