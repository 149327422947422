import React from "react";
import { View, Text, StyleSheet, Image, Pressable } from "react-native";

const CustomButton = ({
  title,
  disabled,
  icon,
  style,
  imageStyle,
  titleStyle,
  onPress,
}) => (
  <Pressable onPress={onPress} style={style} disabled={disabled}>
    <View
      style={{
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
      }}
    >
      {icon && <Image source={icon} style={imageStyle} />}
      {title && <Text style={[styles.title, titleStyle]}>{title}</Text>}
    </View>
  </Pressable>
);

const styles = StyleSheet.create({
  title: {
    fontSize: 15,
    color: "white",
  },
});

export default CustomButton;
