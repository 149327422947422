import React from "react";
import {
  ActivityIndicator,
  Modal,
  Platform,
  StyleSheet,
  View,
} from "react-native";

const Blocker = (props) => {
  const { loading } = props;

  if (Platform.OS != "web") {
    return (
      <Modal transparent={true} animationType={"none"} visible={loading}>
        <View style={styles.modalBackground}>
          <View style={styles.activityIndicatorWrapper}>
            <ActivityIndicator animating={loading} />
          </View>
        </View>
      </Modal>
    );
  } else {
    if (loading) {
      return (
        <View style={styles.constainer}>
          <View style={styles.modalBackground}>
            <View style={styles.activityIndicatorWrapper}>
              <ActivityIndicator animating={loading} />
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  }
};

const styles = StyleSheet.create({
  constainer: {
    zIndex: 1,
    flex: 1,
    height: "100%",
    width: "100%",
    position: "absolute",
    opacity: 0.8,
  },
  modalBackground: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  activityIndicatorWrapper: {
    height: 40,
    width: 40,
    borderRadius: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
});

Blocker.propTypes = {
  //loading: PropTypes.bool.isRequired,
};

export default Blocker;
