import React from "react";
import { Text, StyleSheet } from "react-native";
import HyperlinkedText from "../components/HyperlinkedText";
import { clog } from "../utils/Log";
import { sendPushNotification } from "../utils/Notification";
import { createNotification } from "../controllers/NotificationController";

const ADD_PRESSABLE_USER = (target, text, navigation, index) => {
  let handle = target.replace(/^\s*@/, "").toLowerCase();
  return (
    <Text
      key={index}
      suppressHighlighting={true}
      onPress={() => {
        ////clog("pressed button for", item);
        //setUserData({ Id: item.Id, handle: item.handle });
        //setModalVisible(true);
        navigation.push("UserDetails", {
          handle: handle,
          fromList: true,
        });
      }}
    >
      {text}
    </Text>
  );
};

export const MENTION_FORMATTER = (string, navigation, contentModalFunction) => {
  let formattedString = string
    .toString()
    .split(/((?:^|\s)(?:@[a-z\d-]+))/gi)
    .filter(Boolean)
    .map((v, index) => {
      if (v.includes("@")) {
        clog("matched", v);
        return ADD_PRESSABLE_USER(
          v,
          <Text
            key={index}
            style={[styles.touchableText, { fontWeight: "600" }]}
          >
            {v}
          </Text>,
          navigation,
          index
        );
      } else {
        return (
          <HyperlinkedText
            key={index}
            linkStyle={styles.touchableText}
            onLinkPress={(url) => {
              console.log("OPEN URL", url);
              contentModalFunction(url);
            }}
          >
            {v}
          </HyperlinkedText>
        );
      }
    });
  //console.log("FORMATTED STRING(" + JSON.stringify(formattedString) + ")");
  return formattedString;
};

export const pushNotificationWhenMentioned = (
  myContext,
  content,
  targetType,
  actionId,
  title,
  urlId,
  targetId,
  creationTS
) => {
  let mentionRegex = /@([a-zA-Z0-9]+)/g;

  let result;
  let mentioned = {};
  let hasMention = false;
  while ((result = mentionRegex.exec(content))) {
    //commentText
    // Do something with result[1].
    if (result?.[1]) {
      mentioned[result[1].toLowerCase()] = true;
      hasMention = true;
    }
  }
  //let mentioned = comment.match(mentionRegex);
  console.log("MENTIONED PEOPLE", mentioned);

  // Now find the people who should be notified
  let mentionRecipients = {};
  if (hasMention) {
    Object.values(myContext?.followers)?.forEach((follower) => {
      console.log("Follower", follower);
      if (mentioned[follower.handle] && follower.token) {
        mentionRecipients[follower.Id] = true;
      }
    });
  }

  Object.keys(mentionRecipients).forEach((uid) => {
    clog("SHOULD NOTIFY", uid, "for comment by", myContext.handle);

    let data = {
      type: "Url",
      userId: myContext.Id,
      urlId: urlId,
      action: "Mention",
      target: targetType,
      recipientId: uid,
      actorId: myContext.Id,
      targetId: targetId,
      targetType: targetType,
      operation: "Mention",
      actionId: actionId,
    };
    if (creationTS) {
      data["creationTS"] = creationTS;
    }
    if (targetType == "Pin") {
      data["pinId"] = targetId;
    }
    if (targetType == "Comment") {
      data["commentId"] = targetId;
    }
    createNotification(data);

    if (myContext?.followers?.[uid]?.token) {
      clog("CAN NOTIFY", myContext?.followers?.[uid]?.token);

      sendPushNotification(
        myContext?.followers?.[uid]?.token,
        "Village",
        "@" + myContext.handle + " " + "mentioned you on: " + title,
        data
      );
    }
  });
};

const styles = StyleSheet.create({
  text: {
    fontSize: 15,
    fontWeight: "400",
    lineHeight: 22,
  },
  touchableText: {
    color: "rgb(129,199,221)",
    fontSize: 15,
    fontWeight: "400",
    lineHeight: 22,
  },
});
