import React from "react";
import { Image, Text, View } from "react-native";
import { clog } from "../utils/Log";

const Avatar = ({ item, style, textstyle, myContext }) => {
  clog("avatar is", item);
  if (item == null) {
    return <View></View>;
  }
  let imageUrl = null;
  if (item?.avatar && myContext.presignedUrls[item?.avatar]) {
    imageUrl = myContext.presignedUrls[item.avatar]?.uri;
  } else if (item?.avatarUrl) {
    imageUrl = item.avatarUrl;
  }
  return (
    <View style={{ alignItems: "center" }}>
      {imageUrl && (
        <Image
          source={{
            uri: imageUrl,
            cache: "force-cache",
          }}
          style={style}
        />
      )}
      {!imageUrl && item?.handle && (
        <View style={style}>
          <Text style={textstyle}>{item?.name?.charAt(0)?.toUpperCase()}</Text>
        </View>
      )}
    </View>
  );
};

export default Avatar;
