import React from "react";
import { Image, View, StyleSheet } from "react-native";
import logo_splash from "../assets/logo_splash.png";
import { useTheme } from "../theme";

const Splash = () => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.containerStyle,
        { backgroundColor: colors.splashBackground },
      ]}
    >
      <Image
        resizeMode="contain"
        source={logo_splash}
        style={styles.logoStyle}
      />
    </View>
  );
};

export default Splash;

const styles = StyleSheet.create({
  containerStyle: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  logoStyle: {
    width: 172,
    height: 141,
  },
});
