export const countriesData = [
  {
    areaCodes: [],
    countryCallingCode: "93",
    countryCode: "AF",
    countryNameEn: "Afghanistan",
    countryNameLocal:
      "د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان",
    currencyCode: "AFN",
    currencyNameEn: "Afghan afghani",
    flag: "🇦🇫",
    officialLanguageCode: "fa",
    officialLanguageNameEn: "Persian",
    officialLanguageNameLocal: "فارسی",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "355",
    countryCode: "AL",
    countryNameEn: "Albania",
    countryNameLocal: "Shqipëria",
    currencyCode: "ALL",
    currencyNameEn: "Albanian lek",
    flag: "🇦🇱",
    officialLanguageCode: "sq",
    officialLanguageNameEn: "Albanian",
    officialLanguageNameLocal: "Shqip",
    region: "Europe",
    tinName: "Numri i Identifikimit për Personin e Tatueshëm",
    tinType: "NIPT",
  },
  {
    areaCodes: [],
    countryCallingCode: "213",
    countryCode: "DZ",
    countryNameEn: "Algeria",
    countryNameLocal: "الجزائر",
    currencyCode: "DZD",
    currencyNameEn: "Algerian dinar",
    flag: "🇩🇿",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1684",
    countryCode: "AS",
    countryNameEn: "American Samoa",
    countryNameLocal: "American Samoa",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇦🇸",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "376",
    countryCode: "AD",
    countryNameEn: "Andorra",
    countryNameLocal: "Andorra",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇦🇩",
    officialLanguageCode: "ca",
    officialLanguageNameEn: "Catalan, Valencian",
    officialLanguageNameLocal: "Català, Valencià",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "244",
    countryCode: "AO",
    countryNameEn: "Angola",
    countryNameLocal: "Angola",
    currencyCode: "AOA",
    currencyNameEn: "Angolan kwanza",
    flag: "🇦🇴",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1264",
    countryCode: "AI",
    countryNameEn: "Anguilla",
    countryNameLocal: "Anguilla",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇦🇮",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "672",
    countryCode: "AQ",
    countryNameEn: "Antarctica",
    countryNameLocal: "Antarctica, Antártico, Antarctique, Антарктике",
    currencyCode: "",
    currencyNameEn: "",
    flag: "🇦🇶",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1268",
    countryCode: "AG",
    countryNameEn: "Antigua and Barbuda",
    countryNameLocal: "Antigua and Barbuda",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇦🇬",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "54",
    countryCode: "AR",
    countryNameEn: "Argentina",
    countryNameLocal: "Argentina",
    currencyCode: "ARS",
    currencyNameEn: "Argentine peso",
    flag: "🇦🇷",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Código Único de Identificación Tributaria",
    tinType: "CUIT",
  },
  {
    areaCodes: [],
    countryCallingCode: "374",
    countryCode: "AM",
    countryNameEn: "Armenia",
    countryNameLocal: "Հայաստան",
    currencyCode: "AMD",
    currencyNameEn: "Armenian dram",
    flag: "🇦🇲",
    officialLanguageCode: "hy",
    officialLanguageNameEn: "Armenian",
    officialLanguageNameLocal: "Հայերեն",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "297",
    countryCode: "AW",
    countryNameEn: "Aruba",
    countryNameLocal: "Aruba",
    currencyCode: "AWG",
    currencyNameEn: "Aruban florin",
    flag: "🇦🇼",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "61",
    countryCode: "AU",
    countryNameEn: "Australia",
    countryNameLocal: "Australia",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇦🇺",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "Australian Business Number",
    tinType: "ABN",
  },
  {
    areaCodes: [],
    countryCallingCode: "43",
    countryCode: "AT",
    countryNameEn: "Austria",
    countryNameLocal: "Österreich",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇦🇹",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    region: "Europe",
    tinName: "Umsatzsteuer-Identifikationsnummer",
    tinType: "UID",
  },
  {
    areaCodes: [],
    countryCallingCode: "994",
    countryCode: "AZ",
    countryNameEn: "Azerbaijan",
    countryNameLocal: "Azərbaycan",
    currencyCode: "AZN",
    currencyNameEn: "Azerbaijani manat",
    flag: "🇦🇿",
    officialLanguageCode: "az",
    officialLanguageNameEn: "Azerbaijani",
    officialLanguageNameLocal: "azərbaycan dili",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "973",
    countryCode: "BH",
    countryNameEn: "Bahrain",
    countryNameLocal: "البحرين",
    currencyCode: "BHD",
    currencyNameEn: "Bahraini dinar",
    flag: "🇧🇭",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "880",
    countryCode: "BD",
    countryNameEn: "Bangladesh",
    countryNameLocal: "গণপ্রজাতন্ত্রী বাংলাদেশ",
    currencyCode: "BDT",
    currencyNameEn: "Bangladeshi taka",
    flag: "🇧🇩",
    officialLanguageCode: "bn",
    officialLanguageNameEn: "Bengali",
    officialLanguageNameLocal: "বাংলা",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1246",
    countryCode: "BB",
    countryNameEn: "Barbados",
    countryNameLocal: "Barbados",
    currencyCode: "BBD",
    currencyNameEn: "Barbados dollar",
    flag: "🇧🇧",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "375",
    countryCode: "BY",
    countryNameEn: "Belarus",
    countryNameLocal: "Беларусь",
    currencyCode: "BYR",
    currencyNameEn: "",
    flag: "🇧🇾",
    officialLanguageCode: "be",
    officialLanguageNameEn: "Belarusian",
    officialLanguageNameLocal: "беларуская мова",
    region: "Europe",
    tinName: "Учетный номер плательщика",
    tinType: "УНП (UNP)",
  },
  {
    areaCodes: [],
    countryCallingCode: "32",
    countryCode: "BE",
    countryNameEn: "Belgium",
    countryNameLocal: "België, Belgique, Belgien",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇧🇪",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "Europe",
    tinName: "BTW identificatienummer / Numéro de TVA",
    tinType: "n° TVABTW-nr Mwst-nr",
  },
  {
    areaCodes: [],
    countryCallingCode: "501",
    countryCode: "BZ",
    countryNameEn: "Belize",
    countryNameLocal: "Belize",
    currencyCode: "BZD",
    currencyNameEn: "Belize dollar",
    flag: "🇧🇿",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "229",
    countryCode: "BJ",
    countryNameEn: "Benin",
    countryNameLocal: "Bénin",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇧🇯",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1441",
    countryCode: "BM",
    countryNameEn: "Bermuda",
    countryNameLocal: "Bermuda",
    currencyCode: "BMD",
    currencyNameEn: "Bermudian dollar",
    flag: "🇧🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "North America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "975",
    countryCode: "BT",
    countryNameEn: "Bhutan",
    countryNameLocal: "འབྲུག་ཡུལ",
    currencyCode: "BTN",
    currencyNameEn: "Bhutanese ngultrum",
    flag: "🇧🇹",
    officialLanguageCode: "dz",
    officialLanguageNameEn: "Dzongkha",
    officialLanguageNameLocal: "རྫོང་ཁ",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "591",
    countryCode: "BO",
    countryNameEn: "Bolivia (Plurinational State of)",
    countryNameLocal: "Bolivia, Bulibiya, Volívia, Wuliwya",
    currencyCode: "BOP",
    currencyNameEn: "",
    flag: "🇧🇴",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Número de Identificación Tributaria",
    tinType: "NIT",
  },
  {
    areaCodes: [],
    countryCallingCode: "5997",
    countryCode: "BQ",
    countryNameEn: "Bonaire, Sint Eustatius and Saba",
    countryNameLocal: "Caribisch Nederland",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇧🇶",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "Unknown",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "387",
    countryCode: "BA",
    countryNameEn: "Bosnia and Herzegovina",
    countryNameLocal: "Bosna i Hercegovina",
    currencyCode: "BAM",
    currencyNameEn: "Bosnia and Herzegovina convertible mark",
    flag: "🇧🇦",
    officialLanguageCode: "bs",
    officialLanguageNameEn: "Bosnian",
    officialLanguageNameLocal: "bosanski jezik",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "267",
    countryCode: "BW",
    countryNameEn: "Botswana",
    countryNameLocal: "Botswana",
    currencyCode: "BWP",
    currencyNameEn: "Botswana pula",
    flag: "🇧🇼",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "47",
    countryCode: "BV",
    countryNameEn: "Bouvet Island",
    countryNameLocal: "Bouvetøya",
    currencyCode: "NOK",
    currencyNameEn: "Norwegian krone",
    flag: "🇧🇻",
    officialLanguageCode: "no",
    officialLanguageNameEn: "Norwegian",
    officialLanguageNameLocal: "Norsk",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "55",
    countryCode: "BR",
    countryNameEn: "Brazil",
    countryNameLocal: "Brasil",
    currencyCode: "BRL",
    currencyNameEn: "Brazilian real",
    flag: "🇧🇷",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "South/Latin America",
    tinName: "Cadastro Nacional de Pessoa Jurídica",
    tinType: "CNPJ",
  },
  {
    areaCodes: [],
    countryCallingCode: "246",
    countryCode: "IO",
    countryNameEn: "British Indian Ocean Territories",
    countryNameLocal: "British Indian Ocean Territories",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇮🇴",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Indian Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "246",
    countryCode: "IO",
    countryNameEn: "British Indian Ocean Territory",
    countryNameLocal: "British Indian Ocean Territory",
    currencyCode: "USD",
    currencyNameEn: "United States Dollar",
    flag: "🇮🇴",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Indian Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "673",
    countryCode: "BN",
    countryNameEn: "Brunei Darussalam",
    countryNameLocal: "Brunei Darussalam",
    currencyCode: "BND",
    currencyNameEn: "Brunei dollar",
    flag: "🇧🇳",
    officialLanguageCode: "ms",
    officialLanguageNameEn: "Malay",
    officialLanguageNameLocal: "Bahasa Melayu, بهاس ملايو‎",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "359",
    countryCode: "BG",
    countryNameEn: "Bulgaria",
    countryNameLocal: "България",
    currencyCode: "BGN",
    currencyNameEn: "Bulgarian lev",
    flag: "🇧🇬",
    officialLanguageCode: "bg",
    officialLanguageNameEn: "Bulgarian",
    officialLanguageNameLocal: "български език",
    region: "Europe",
    tinName: "Идентификационен номер по ДДС",
    tinType: "ДДС номер",
  },
  {
    areaCodes: [],
    countryCallingCode: "226",
    countryCode: "BF",
    countryNameEn: "Burkina Faso",
    countryNameLocal: "Burkina Faso",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇧🇫",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "257",
    countryCode: "BI",
    countryNameEn: "Burundi",
    countryNameLocal: "Burundi",
    currencyCode: "BIF",
    currencyNameEn: "Burundian franc",
    flag: "🇧🇮",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "238",
    countryCode: "CV",
    countryNameEn: "Cabo Verde",
    countryNameLocal: "Cabo Verde",
    currencyCode: "CVE",
    currencyNameEn: "Cape Verdean escudo",
    flag: "🇨🇻",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "855",
    countryCode: "KH",
    countryNameEn: "Cambodia",
    countryNameLocal: "កម្ពុជា",
    currencyCode: "KHR",
    currencyNameEn: "Cambodian riel",
    flag: "🇰🇭",
    officialLanguageCode: "km",
    officialLanguageNameEn: "Central Khmer",
    officialLanguageNameLocal: "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "237",
    countryCode: "CM",
    countryNameEn: "Cameroon",
    countryNameLocal: "Cameroun, Cameroon",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    flag: "🇨🇲",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [
      "403",
      "587",
      "780",
      "825",
      "236",
      "250",
      "604",
      "672",
      "778",
      "204",
      "431",
      "506",
      "709",
      "782",
      "902",
      "226",
      "249",
      "289",
      "343",
      "365",
      "416",
      "437",
      "519",
      "548",
      "613",
      "647",
      "705",
      "807",
      "905",
      "367",
      "418",
      "438",
      "450",
      "514",
      "579",
      "581",
      "819",
      "873",
      "306",
      "639",
      "867",
    ],
    countryCallingCode: "1",
    countryCode: "CA",
    countryNameEn: "Canada",
    countryNameLocal: "Canada",
    currencyCode: "CAD",
    currencyNameEn: "Canadian dollar",
    flag: "🇨🇦",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "North America",
    tinName: "Business Number",
    tinType: "BN / NE",
  },
  {
    areaCodes: [],
    countryCallingCode: "1 345",
    countryCode: "KY",
    countryNameEn: "Cayman Islands",
    countryNameLocal: "Cayman Islands",
    currencyCode: "KYD",
    currencyNameEn: "Cayman Islands dollar",
    flag: "🇰🇾",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Caribbean Sea",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "236",
    countryCode: "CF",
    countryNameEn: "Central African Republic",
    countryNameLocal: "République centrafricaine",
    currencyCode: "XAF",
    currencyNameEn: "Central African CFA",
    flag: "🇨🇫",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "235",
    countryCode: "TD",
    countryNameEn: "Chad",
    countryNameLocal: "Tchad, تشاد",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    flag: "🇹🇩",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "56",
    countryCode: "CL",
    countryNameEn: "Chile",
    countryNameLocal: "Chile",
    currencyCode: "CLP",
    currencyNameEn: "Chilean peso",
    flag: "🇨🇱",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Rol Único Tributario",
    tinType: "RUT",
  },
  {
    areaCodes: [],
    countryCallingCode: "86",
    countryCode: "CN",
    countryNameEn: "China",
    countryNameLocal: "中国",
    currencyCode: "CNY",
    currencyNameEn: "Renminbi (Chinese) yuan",
    flag: "🇨🇳",
    officialLanguageCode: "zh-hans",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "61",
    countryCode: "CX",
    countryNameEn: "Christmas Island",
    countryNameLocal: "Christmas Island",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇨🇽",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "61 891",
    countryCode: "CC",
    countryNameEn: "Cocos (Keeling) Islands",
    countryNameLocal: "Pulu Kokos (Keeling)",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇨🇨",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Australia",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "57",
    countryCode: "CO",
    countryNameEn: "Colombia",
    countryNameLocal: "Colombia",
    currencyCode: "COP",
    currencyNameEn: "Colombian peso",
    flag: "🇨🇴",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Número De Identificación Tributaria",
    tinType: "NIT",
  },
  {
    areaCodes: [],
    countryCallingCode: "1 242",
    countryCode: "BS",
    countryNameEn: "Commonwealth of The Bahamas",
    countryNameLocal: "Commonwealth of The Bahamas",
    currencyCode: "BSD",
    currencyNameEn: "Bahamian dollar",
    flag: "🇧🇸",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Caribbean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1 670",
    countryCode: "MP",
    countryNameEn: "Commonwealth of the Northern Mariana Islands",
    countryNameLocal: "Sankattan Siha Na Islas Mariånas",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇲🇵",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Pacific Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "269",
    countryCode: "KM",
    countryNameEn: "Comoros",
    countryNameLocal: "Umoja wa Komori",
    currencyCode: "KMF",
    currencyNameEn: "Comorian franc",
    flag: "🇰🇲",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Indian Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "682",
    countryCode: "CK",
    countryNameEn: "Cook Islands",
    countryNameLocal: "Kūki 'Āirani",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    flag: "🇨🇰",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South Pacific Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "506",
    countryCode: "CR",
    countryNameEn: "Costa Rica",
    countryNameLocal: "Costa Rica",
    currencyCode: "CRC",
    currencyNameEn: "Costa Rican colon",
    flag: "🇨🇷",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Cédula Jurídica",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "385",
    countryCode: "HR",
    countryNameEn: "Croatia",
    countryNameLocal: "Hrvatska",
    currencyCode: "HRK",
    currencyNameEn: "Croatian kuna",
    flag: "🇭🇷",
    officialLanguageCode: "hr",
    officialLanguageNameEn: "Croatian",
    officialLanguageNameLocal: "hrvatski jezik",
    region: "Europe",
    tinName: "PDV Id. Broj OIB",
    tinType: "PDV-ID; OIB",
  },
  {
    areaCodes: [],
    countryCallingCode: "53",
    countryCode: "CU",
    countryNameEn: "Cuba",
    countryNameLocal: "Cuba",
    currencyCode: "CUC",
    currencyNameEn: "Cuban convertible peso",
    flag: "🇨🇺",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "599",
    countryCode: "CW",
    countryNameEn: "Curaçao",
    countryNameLocal: "Curaçao",
    currencyCode: "ANG",
    currencyNameEn: "Netherlands Antillean guilder",
    flag: "🇨🇼",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "Unknown",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "357",
    countryCode: "CY",
    countryNameEn: "Cyprus",
    countryNameLocal: "Κύπρος, Kibris",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇨🇾",
    officialLanguageCode: "el",
    officialLanguageNameEn: "Greek, Modern (1453-)",
    officialLanguageNameLocal: "ελληνικά",
    region: "Europe",
    tinName: "Αριθμός Εγγραφής Φ.Π.Α.",
    tinType: "ΦΠΑ",
  },
  {
    areaCodes: [],
    countryCallingCode: "420",
    countryCode: "CZ",
    countryNameEn: "Czechia",
    countryNameLocal: "Česká republika",
    currencyCode: "CZK",
    currencyNameEn: "Czech koruna",
    flag: "🇨🇿",
    officialLanguageCode: "cs",
    officialLanguageNameEn: "Czech",
    officialLanguageNameLocal: "Čeština",
    region: "Europe",
    tinName: "Daňové identifikační číslo",
    tinType: "DIČ",
  },
  {
    areaCodes: [],
    countryCallingCode: "225",
    countryCode: "CI",
    countryNameEn: "Côte d'Ivoire",
    countryNameLocal: "Côte d'Ivoire",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇨🇮",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "243",
    countryCode: "CD",
    countryNameEn: "Democratic Republic of the Congo",
    countryNameLocal: "Democratic Republic of the Congo",
    currencyCode: "CDF",
    currencyNameEn: "Congolese franc",
    flag: "🇨🇩",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "45",
    countryCode: "DK",
    countryNameEn: "Denmark",
    countryNameLocal: "Danmark",
    currencyCode: "DKK",
    currencyNameEn: "Danish krone",
    flag: "🇩🇰",
    officialLanguageCode: "da",
    officialLanguageNameEn: "Danish",
    officialLanguageNameLocal: "dansk",
    region: "Europe",
    tinName: "Momsregistreringsnummer",
    tinType: "CVR",
  },
  {
    areaCodes: [],
    countryCallingCode: "253",
    countryCode: "DJ",
    countryNameEn: "Djibouti",
    countryNameLocal: "Djibouti, جيبوتي, Jabuuti, Gabuutih",
    currencyCode: "DJF",
    currencyNameEn: "Djiboutian franc",
    flag: "🇩🇯",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "767",
    countryCode: "DM",
    countryNameEn: "Dominica",
    countryNameLocal: "Dominica",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇩🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1",
    countryCode: "DO",
    countryNameEn: "Dominican Republic",
    countryNameLocal: "República Dominicana",
    currencyCode: "DOP",
    currencyNameEn: "Dominican peso",
    flag: "🇩🇴",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Nacional del Contribuyente",
    tinType: "RNC",
  },
  {
    areaCodes: [],
    countryCallingCode: "593",
    countryCode: "EC",
    countryNameEn: "Ecuador",
    countryNameLocal: "Ecuador",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇪🇨",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Número de Registro Unico de Contribuyentes",
    tinType: "RUC",
  },
  {
    areaCodes: [],
    countryCallingCode: "20",
    countryCode: "EG",
    countryNameEn: "Egypt",
    countryNameLocal: "مصر",
    currencyCode: "EGP",
    currencyNameEn: "Egyptian pound",
    flag: "🇪🇬",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "503",
    countryCode: "SV",
    countryNameEn: "El Salvador",
    countryNameLocal: "El Salvador",
    currencyCode: "SVC",
    currencyNameEn: "Salvadoran colón",
    flag: "🇸🇻",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Número de Identificación Tributaria",
    tinType: "NIT",
  },
  {
    areaCodes: [],
    countryCallingCode: "240",
    countryCode: "GQ",
    countryNameEn: "Equatorial Guinea",
    countryNameLocal: "Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    flag: "🇬🇶",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "291",
    countryCode: "ER",
    countryNameEn: "Eritrea",
    countryNameLocal: "ኤርትራ, إرتريا, Eritrea",
    currencyCode: "ERN",
    currencyNameEn: "Eritrean nakfa",
    flag: "🇪🇷",
    officialLanguageCode: "ti",
    officialLanguageNameEn: "Tigrinya",
    officialLanguageNameLocal: "ትግርኛ",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "372",
    countryCode: "EE",
    countryNameEn: "Estonia",
    countryNameLocal: "Eesti",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇪🇪",
    officialLanguageCode: "et",
    officialLanguageNameEn: "Estonian",
    officialLanguageNameLocal: "eesti, eesti keel",
    region: "Europe",
    tinName: "Käibemaksukohustuslase number",
    tinType: "KMKR",
  },
  {
    areaCodes: [],
    countryCallingCode: "268",
    countryCode: "SZ",
    countryNameEn: "Eswatini",
    countryNameLocal: "Swaziland",
    currencyCode: "SZL",
    currencyNameEn: "Swazi lilangeni",
    flag: "🇸🇿",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "251",
    countryCode: "ET",
    countryNameEn: "Ethiopia",
    countryNameLocal: "ኢትዮጵያ, Itoophiyaa",
    currencyCode: "ETB",
    currencyNameEn: "Ethiopian birr",
    flag: "🇪🇹",
    officialLanguageCode: "am",
    officialLanguageNameEn: "Amharic",
    officialLanguageNameLocal: "አማርኛ",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "500",
    countryCode: "FK",
    countryNameEn: "Falkland Islands",
    countryNameLocal: "Falkland Islands",
    currencyCode: "FKP",
    currencyNameEn: "Falklands pound",
    flag: "🇫🇰",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South Atlantic Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "298",
    countryCode: "FO",
    countryNameEn: "Faroe Islands",
    countryNameLocal: "Færøerne",
    currencyCode: "DKK",
    currencyNameEn: "Faroese króna",
    flag: "🇫🇴",
    officialLanguageCode: "da",
    officialLanguageNameEn: "Danish",
    officialLanguageNameLocal: "dansk",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "679",
    countryCode: "FJ",
    countryNameEn: "Fiji",
    countryNameLocal: "Fiji",
    currencyCode: "FJD",
    currencyNameEn: "Fiji dollar",
    flag: "🇫🇯",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "358",
    countryCode: "FI",
    countryNameEn: "Finland",
    countryNameLocal: "Suomi",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇫🇮",
    officialLanguageCode: "fi",
    officialLanguageNameEn: "Finnish",
    officialLanguageNameLocal: "suomi, suomen kieli",
    region: "Europe",
    tinName: "Arvonlisäveronumero",
    tinType: "ALV nro",
  },
  {
    areaCodes: [],
    countryCallingCode: "33",
    countryCode: "FR",
    countryNameEn: "France",
    countryNameLocal: "France",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇫🇷",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Europe",
    tinName:
      "Numéro d'identification à la taxe sur la valeur ajoutée / Numéro de TVA intracommunautaire",
    tinType: "n° TVA",
  },
  {
    areaCodes: [],
    countryCallingCode: "594",
    countryCode: "GF",
    countryNameEn: "French Guiana",
    countryNameLocal: "Guyane française",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇬🇫",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "689",
    countryCode: "PF",
    countryNameEn: "French Polynesia",
    countryNameLocal: "Polynésie française",
    currencyCode: "XPF",
    currencyNameEn: "CFP franc (franc Pacifique)",
    flag: "🇵🇫",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "672",
    countryCode: "TF",
    countryNameEn: "French Southern and Antarctic Lands",
    countryNameLocal: "Terres australes et antarctiques françaises",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇹🇫",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Indian Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "241",
    countryCode: "GA",
    countryNameEn: "Gabon",
    countryNameLocal: "Gabon",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    flag: "🇬🇦",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "220",
    countryCode: "GM",
    countryNameEn: "Gambia",
    countryNameLocal: "The Gambia",
    currencyCode: "GMD",
    currencyNameEn: "Gambian dalasi",
    flag: "🇬🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "995",
    countryCode: "GE",
    countryNameEn: "Georgia",
    countryNameLocal: "საქართველო",
    currencyCode: "GEL",
    currencyNameEn: "Georgian lari",
    flag: "🇬🇪",
    officialLanguageCode: "ka",
    officialLanguageNameEn: "Georgian",
    officialLanguageNameLocal: "ქართული",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "49",
    countryCode: "DE",
    countryNameEn: "Germany",
    countryNameLocal: "Deutschland",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇩🇪",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    region: "Europe",
    tinName: "Umsatzsteuer-Identifikationsnummer",
    tinType: "USt-IdNr.",
  },
  {
    areaCodes: [],
    countryCallingCode: "233",
    countryCode: "GH",
    countryNameEn: "Ghana",
    countryNameLocal: "Ghana",
    currencyCode: "GHS",
    currencyNameEn: "Ghanaian cedi",
    flag: "🇬🇭",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "350",
    countryCode: "GI",
    countryNameEn: "Gibraltar",
    countryNameLocal: "Gibraltar",
    currencyCode: "GIP",
    currencyNameEn: "Gibraltar pound",
    flag: "🇬🇮",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "30",
    countryCode: "GR",
    countryNameEn: "Greece",
    countryNameLocal: "Ελλάδα",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇬🇷",
    officialLanguageCode: "el",
    officialLanguageNameEn: "Greek, Modern (1453-)",
    officialLanguageNameLocal: "ελληνικά",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "299",
    countryCode: "GL",
    countryNameEn: "Greenland",
    countryNameLocal: "Kalaallit Nunaat, Grønland",
    currencyCode: "DKK",
    currencyNameEn: "Danish krone",
    flag: "🇬🇱",
    officialLanguageCode: "kl",
    officialLanguageNameEn: "Kalaallisut, Greenlandic",
    officialLanguageNameLocal: "kalaallisut, kalaallit oqaasii",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1473",
    countryCode: "GD",
    countryNameEn: "Grenada",
    countryNameLocal: "Grenada",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇬🇩",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "590",
    countryCode: "GP",
    countryNameEn: "Guadeloupe",
    countryNameLocal: "Guadeloupe",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇬🇵",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1",
    countryCode: "GU",
    countryNameEn: "Guam",
    countryNameLocal: "Guam, Guåhån",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇬🇺",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "502",
    countryCode: "GT",
    countryNameEn: "Guatemala",
    countryNameLocal: "Guatemala",
    currencyCode: "GTQ",
    currencyNameEn: "Guatemalan quetzal",
    flag: "🇬🇹",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Número de Identificación Tributaria",
    tinType: "NIT",
  },
  {
    areaCodes: [],
    countryCallingCode: "44",
    countryCode: "GG",
    countryNameEn: "Guernsey",
    countryNameLocal: "Guernsey",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    flag: "🇬🇬",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "224",
    countryCode: "GN",
    countryNameEn: "Guinea",
    countryNameLocal: "Guinée",
    currencyCode: "GNF",
    currencyNameEn: "Guinean franc",
    flag: "🇬🇳",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "245",
    countryCode: "GW",
    countryNameEn: "Guinea-Bissau",
    countryNameLocal: "Guiné-Bissau",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇬🇼",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "592",
    countryCode: "GY",
    countryNameEn: "Guyana",
    countryNameLocal: "Guyana",
    currencyCode: "GYD",
    currencyNameEn: "Guyanese dollar",
    flag: "🇬🇾",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "509",
    countryCode: "HT",
    countryNameEn: "Haiti",
    countryNameLocal: "Haïti, Ayiti",
    currencyCode: "HTG",
    currencyNameEn: "Haitian gourde",
    flag: "🇭🇹",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "39",
    countryCode: "VA",
    countryNameEn: "Holy See",
    countryNameLocal: "Sancta Sedes",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇻🇦",
    officialLanguageCode: "la",
    officialLanguageNameEn: "Latin",
    officialLanguageNameLocal: "lingua latīna",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "504",
    countryCode: "HN",
    countryNameEn: "Honduras",
    countryNameLocal: "Honduras",
    currencyCode: "HNL",
    currencyNameEn: "Honduran lempira",
    flag: "🇭🇳",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Tributario Nacional",
    tinType: "RTN",
  },
  {
    areaCodes: [],
    countryCallingCode: "852",
    countryCode: "HK",
    countryNameEn: "Hong Kong",
    countryNameLocal: "香港, Hong Kong",
    currencyCode: "HKD",
    currencyNameEn: "Hong Kong dollar",
    flag: "🇭🇰",
    officialLanguageCode: "zh-hant",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "36",
    countryCode: "HU",
    countryNameEn: "Hungary",
    countryNameLocal: "Magyarország",
    currencyCode: "HUF",
    currencyNameEn: "Hungarian forint",
    flag: "🇭🇺",
    officialLanguageCode: "hu",
    officialLanguageNameEn: "Hungarian",
    officialLanguageNameLocal: "magyar",
    region: "Europe",
    tinName: "Közösségi adószám",
    tinType: "ANUM",
  },
  {
    areaCodes: [],
    countryCallingCode: "354",
    countryCode: "IS",
    countryNameEn: "Iceland",
    countryNameLocal: "Ísland",
    currencyCode: "ISK",
    currencyNameEn: "Icelandic króna",
    flag: "🇮🇸",
    officialLanguageCode: "is",
    officialLanguageNameEn: "Icelandic",
    officialLanguageNameLocal: "Íslenska",
    region: "Europe",
    tinName: "Virðisaukaskattsnúmer",
    tinType: "VSK / VASK",
  },
  {
    areaCodes: [],
    countryCallingCode: "91",
    countryCode: "IN",
    countryNameEn: "India",
    countryNameLocal: "भारत, India",
    currencyCode: "INR",
    currencyNameEn: "Indian rupee",
    flag: "🇮🇳",
    officialLanguageCode: "hi",
    officialLanguageNameEn: "Hindi",
    officialLanguageNameLocal: "हिन्दी, हिंदी",
    region: "Asia & Pacific",
    tinName:
      "Value Added Tax - Taxpayer Identification Number / Central Sales Tax - Taxpayer Identification Number (In most states)Not applicable",
    tinType: "VAT TIN / CST TIN",
  },
  {
    areaCodes: [],
    countryCallingCode: "62",
    countryCode: "ID",
    countryNameEn: "Indonesia",
    countryNameLocal: "Indonesia",
    currencyCode: "IDR",
    currencyNameEn: "Indonesian rupiah",
    flag: "🇮🇩",
    officialLanguageCode: "id",
    officialLanguageNameEn: "Indonesian",
    officialLanguageNameLocal: "Bahasa Indonesia",
    region: "Asia & Pacific",
    tinName: "Nomor Pokok Wajib Pajak",
    tinType: "NPWP",
  },
  {
    areaCodes: [],
    countryCallingCode: "98",
    countryCode: "IR",
    countryNameEn: "Iran (Islamic Republic of)",
    countryNameLocal: "ایران",
    currencyCode: "IRR",
    currencyNameEn: "Iranian rial",
    flag: "🇮🇷",
    officialLanguageCode: "fa",
    officialLanguageNameEn: "Persian",
    officialLanguageNameLocal: "فارسی",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "964",
    countryCode: "IQ",
    countryNameEn: "Iraq",
    countryNameLocal: "العراق, Iraq",
    currencyCode: "IQD",
    currencyNameEn: "Iraqi dinar",
    flag: "🇮🇶",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "353",
    countryCode: "IE",
    countryNameEn: "Ireland",
    countryNameLocal: "Ireland, Éire",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇮🇪",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Europe",
    tinName: "Value added tax identification no.",
    tinType: "VAT or CBL",
  },
  {
    areaCodes: [],
    countryCallingCode: "44",
    countryCode: "IM",
    countryNameEn: "Isle of Man",
    countryNameLocal: "Isle of Man",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    flag: "🇮🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "972",
    countryCode: "IL",
    countryNameEn: "Israel",
    countryNameLocal: "ישראל",
    currencyCode: "ILS",
    currencyNameEn: "Israeli new shekel",
    flag: "🇮🇱",
    officialLanguageCode: "he",
    officialLanguageNameEn: "Hebrew",
    officialLanguageNameLocal: "עברית",
    region: "Europe",
    tinName: "מס' עוסק מורשה / ח\"פ",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "39",
    countryCode: "IT",
    countryNameEn: "Italy",
    countryNameLocal: "Italia",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇮🇹",
    officialLanguageCode: "it",
    officialLanguageNameEn: "Italian",
    officialLanguageNameLocal: "Italiano",
    region: "Europe",
    tinName: "Partita IVA(IVA = Imposta sul Valore Aggiunto)",
    tinType: "P.IVA",
  },
  {
    areaCodes: [],
    countryCallingCode: "876",
    countryCode: "JM",
    countryNameEn: "Jamaica",
    countryNameLocal: "Jamaica",
    currencyCode: "JMD",
    currencyNameEn: "Jamaican dollar",
    flag: "🇯🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "81",
    countryCode: "JP",
    countryNameEn: "Japan",
    countryNameLocal: "日本",
    currencyCode: "JPY",
    currencyNameEn: "Japanese yen",
    flag: "🇯🇵",
    officialLanguageCode: "ja",
    officialLanguageNameEn: "Japanese",
    officialLanguageNameLocal: "日本語 (にほんご)",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "44",
    countryCode: "JE",
    countryNameEn: "Jersey",
    countryNameLocal: "Jersey",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    flag: "🇯🇪",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "962",
    countryCode: "JO",
    countryNameEn: "Jordan",
    countryNameLocal: "الأُرْدُن",
    currencyCode: "JOD",
    currencyNameEn: "Jordanian dinar",
    flag: "🇯🇴",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "7",
    countryCode: "KZ",
    countryNameEn: "Kazakhstan",
    countryNameLocal: "Қазақстан, Казахстан",
    currencyCode: "KZT",
    currencyNameEn: "Kazakhstani tenge",
    flag: "🇰🇿",
    officialLanguageCode: "kk",
    officialLanguageNameEn: "Kazakh",
    officialLanguageNameLocal: "қазақ тілі",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "254",
    countryCode: "KE",
    countryNameEn: "Kenya",
    countryNameLocal: "Kenya",
    currencyCode: "KES",
    currencyNameEn: "Kenyan shilling",
    flag: "🇰🇪",
    officialLanguageCode: "sw",
    officialLanguageNameEn: "Swahili",
    officialLanguageNameLocal: "Kiswahili",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "686",
    countryCode: "KI",
    countryNameEn: "Kiribati",
    countryNameLocal: "Kiribati",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇰🇮",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "965",
    countryCode: "KW",
    countryNameEn: "Kuwait",
    countryNameLocal: "الكويت",
    currencyCode: "KWD",
    currencyNameEn: "Kuwaiti dinar",
    flag: "🇰🇼",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "996",
    countryCode: "KG",
    countryNameEn: "Kyrgyzstan",
    countryNameLocal: "Кыргызстан, Киргизия",
    currencyCode: "KGS",
    currencyNameEn: "Kyrgyzstani som",
    flag: "🇰🇬",
    officialLanguageCode: "ky",
    officialLanguageNameEn: "Kirghiz, Kyrgyz",
    officialLanguageNameLocal: "Кыргызча, Кыргыз тили",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "856",
    countryCode: "LA",
    countryNameEn: "Lao People's Democratic Republic",
    countryNameLocal: "ປະຊາຊົນລາວ",
    currencyCode: "LAK",
    currencyNameEn: "Lao kip",
    flag: "🇱🇦",
    officialLanguageCode: "lo",
    officialLanguageNameEn: "Lao",
    officialLanguageNameLocal: "ພາສາລາວ",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "371",
    countryCode: "LV",
    countryNameEn: "Latvia",
    countryNameLocal: "Latvija",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇱🇻",
    officialLanguageCode: "lv",
    officialLanguageNameEn: "Latvian",
    officialLanguageNameLocal: "latviešu valoda",
    region: "Europe",
    tinName: "Pievienotās vērtības nodokļa (PVN) reģistrācijas numurs",
    tinType: "PVN",
  },
  {
    areaCodes: [],
    countryCallingCode: "961",
    countryCode: "LB",
    countryNameEn: "Lebanon",
    countryNameLocal: "لبنان, Liban",
    currencyCode: "LBP",
    currencyNameEn: "Lebanese pound",
    flag: "🇱🇧",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "266",
    countryCode: "LS",
    countryNameEn: "Lesotho",
    countryNameLocal: "Lesotho",
    currencyCode: "LSL",
    currencyNameEn: "Lesotho loti",
    flag: "🇱🇸",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "231",
    countryCode: "LR",
    countryNameEn: "Liberia",
    countryNameLocal: "Liberia",
    currencyCode: "LRD",
    currencyNameEn: "Liberian dollar",
    flag: "🇱🇷",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "218",
    countryCode: "LY",
    countryNameEn: "Libya",
    countryNameLocal: "ليبيا",
    currencyCode: "LYD",
    currencyNameEn: "Libyan dinar",
    flag: "🇱🇾",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "423",
    countryCode: "LI",
    countryNameEn: "Liechtenstein",
    countryNameLocal: "Liechtenstein",
    currencyCode: "CHF",
    currencyNameEn: "Swiss franc",
    flag: "🇱🇮",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "370",
    countryCode: "LT",
    countryNameEn: "Lithuania",
    countryNameLocal: "Lietuva",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇱🇹",
    officialLanguageCode: "lt",
    officialLanguageNameEn: "Lithuanian",
    officialLanguageNameLocal: "lietuvių kalba",
    region: "Europe",
    tinName: "PVM (abbrev. Pridėtinės vertės mokestis) mokėtojo kodas",
    tinType: "PVM kodas",
  },
  {
    areaCodes: [],
    countryCallingCode: "352",
    countryCode: "LU",
    countryNameEn: "Luxembourg",
    countryNameLocal: "Lëtzebuerg, Luxembourg, Luxemburg",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇱🇺",
    officialLanguageCode: "lb",
    officialLanguageNameEn: "Luxembourgish, Letzeburgesch",
    officialLanguageNameLocal: "Lëtzebuergesch",
    region: "Europe",
    tinName: "Numéro d'identification à la taxe sur la valeur ajoutée",
    tinType: "No. TVA",
  },
  {
    areaCodes: [],
    countryCallingCode: "853",
    countryCode: "MO",
    countryNameEn: "Macao",
    countryNameLocal: "澳門, Macau",
    currencyCode: "MOP",
    currencyNameEn: "Macanese pataca",
    flag: "🇲🇴",
    officialLanguageCode: "zh-hant",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "261",
    countryCode: "MG",
    countryNameEn: "Madagascar",
    countryNameLocal: "Madagasikara, Madagascar",
    currencyCode: "MGA",
    currencyNameEn: "Malagasy ariary",
    flag: "🇲🇬",
    officialLanguageCode: "mg",
    officialLanguageNameEn: "Malagasy",
    officialLanguageNameLocal: "fiteny malagasy",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "265",
    countryCode: "MW",
    countryNameEn: "Malawi",
    countryNameLocal: "Malawi",
    currencyCode: "MWK",
    currencyNameEn: "Malawian kwacha",
    flag: "🇲🇼",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "60",
    countryCode: "MY",
    countryNameEn: "Malaysia",
    countryNameLocal: "",
    currencyCode: "MYR",
    currencyNameEn: "Malaysian ringgit",
    flag: "🇲🇾",
    officialLanguageCode: "ms",
    officialLanguageNameEn: "Malay",
    officialLanguageNameLocal: "Bahasa Melayu, بهاس ملايو‎",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "960",
    countryCode: "MV",
    countryNameEn: "Maldives",
    countryNameLocal: "",
    currencyCode: "MVR",
    currencyNameEn: "Maldivian rufiyaa",
    flag: "🇲🇻",
    officialLanguageCode: "dv",
    officialLanguageNameEn: "Divehi, Dhivehi, Maldivian",
    officialLanguageNameLocal: "ދިވެހި",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "223",
    countryCode: "ML",
    countryNameEn: "Mali",
    countryNameLocal: "Mali",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇲🇱",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "356",
    countryCode: "MT",
    countryNameEn: "Malta",
    countryNameLocal: "Malta",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇲🇹",
    officialLanguageCode: "mt",
    officialLanguageNameEn: "Maltese",
    officialLanguageNameLocal: "Malti",
    region: "Europe",
    tinName: "Vat reg. no.",
    tinType: "Vat No.",
  },
  {
    areaCodes: [],
    countryCallingCode: "596",
    countryCode: "MQ",
    countryNameEn: "Martinique",
    countryNameLocal: "Martinique",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇲🇶",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "222",
    countryCode: "MR",
    countryNameEn: "Mauritania",
    countryNameLocal: "موريتانيا, Mauritanie",
    currencyCode: "MRU",
    currencyNameEn: "",
    flag: "🇲🇷",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "230",
    countryCode: "MU",
    countryNameEn: "Mauritius",
    countryNameLocal: "Maurice, Mauritius",
    currencyCode: "MUR",
    currencyNameEn: "Mauritian rupee",
    flag: "🇲🇺",
    officialLanguageCode: "mfe",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "262",
    countryCode: "YT",
    countryNameEn: "Mayotte",
    countryNameLocal: "Mayotte",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇾🇹",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "52",
    countryCode: "MX",
    countryNameEn: "Mexico",
    countryNameLocal: "México",
    currencyCode: "MXN",
    currencyNameEn: "Mexican peso",
    flag: "🇲🇽",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Federal de Contribuyentes",
    tinType: "RFC",
  },
  {
    areaCodes: [],
    countryCallingCode: "691",
    countryCode: "FM",
    countryNameEn: "Micronesia (Federated States of)",
    countryNameLocal: "Micronesia",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇫🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "377",
    countryCode: "MC",
    countryNameEn: "Monaco",
    countryNameLocal: "Monaco",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇲🇨",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "976",
    countryCode: "MN",
    countryNameEn: "Mongolia",
    countryNameLocal: "Монгол Улс",
    currencyCode: "MNT",
    currencyNameEn: "Mongolian tögrög",
    flag: "🇲🇳",
    officialLanguageCode: "mn",
    officialLanguageNameEn: "Mongolian",
    officialLanguageNameLocal: "Монгол хэл",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "382",
    countryCode: "ME",
    countryNameEn: "Montenegro",
    countryNameLocal: "Crna Gora, Црна Гора",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇲🇪",
    officialLanguageCode: "srp",
    officialLanguageNameEn: "српски језик",
    officialLanguageNameLocal: "",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1664",
    countryCode: "MS",
    countryNameEn: "Montserrat",
    countryNameLocal: "Montserrat",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇲🇸",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "212",
    countryCode: "MA",
    countryNameEn: "Morocco",
    countryNameLocal: "Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب",
    currencyCode: "MAD",
    currencyNameEn: "Moroccan dirham",
    flag: "🇲🇦",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "258",
    countryCode: "MZ",
    countryNameEn: "Mozambique",
    countryNameLocal: "Mozambique",
    currencyCode: "MZN",
    currencyNameEn: "Mozambican metical",
    flag: "🇲🇿",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "95",
    countryCode: "MM",
    countryNameEn: "Myanmar",
    countryNameLocal: "မြန်မာ",
    currencyCode: "MMK",
    currencyNameEn: "Myanmar kyat",
    flag: "🇲🇲",
    officialLanguageCode: "my",
    officialLanguageNameEn: "Burmese",
    officialLanguageNameLocal: "ဗမာစာ",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "264",
    countryCode: "NA",
    countryNameEn: "Namibia",
    countryNameLocal: "Namibia",
    currencyCode: "NAD",
    currencyNameEn: "Namibian dollar",
    flag: "🇳🇦",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "674",
    countryCode: "NR",
    countryNameEn: "Nauru",
    countryNameLocal: "Nauru",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇳🇷",
    officialLanguageCode: "na",
    officialLanguageNameEn: "Nauru",
    officialLanguageNameLocal: "Dorerin Naoero",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "977",
    countryCode: "NP",
    countryNameEn: "Nepal",
    countryNameLocal: "",
    currencyCode: "NPR",
    currencyNameEn: "Nepalese rupee",
    flag: "🇳🇵",
    officialLanguageCode: "ne",
    officialLanguageNameEn: "Nepali",
    officialLanguageNameLocal: "नेपाली",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "31",
    countryCode: "NL",
    countryNameEn: "Netherlands",
    countryNameLocal: "Nederland",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇳🇱",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "Europe",
    tinName: "Btw-nummer",
    tinType: "Btw-nr.",
  },
  {
    countryCallingCode: "599",
    countryCode: "AN",
    countryNameEn: "Netherlands Antilles",
    countryNameLocal: "Nederlandse Antillen",
    currencyCode: "ANG",
    currencyNameEn: "Netherlands Antillean guilder",
    flag: "🇧🇶",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "687",
    countryCode: "NC",
    countryNameEn: "New Caledonia",
    countryNameLocal: "Nouvelle-Calédonie",
    currencyCode: "XPF",
    currencyNameEn: "CFP franc (franc Pacifique)",
    flag: "🇳🇨",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "64",
    countryCode: "NZ",
    countryNameEn: "New Zealand",
    countryNameLocal: "New Zealand",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    flag: "🇳🇿",
    officialLanguageCode: "mi",
    officialLanguageNameEn: "Maori",
    officialLanguageNameLocal: "te reo Māori",
    region: "Asia & Pacific",
    tinName: "NZ Business Number",
    tinType: "NZBN",
  },
  {
    areaCodes: [],
    countryCallingCode: "505",
    countryCode: "NI",
    countryNameEn: "Nicaragua",
    countryNameLocal: "Nicaragua",
    currencyCode: "NIO",
    currencyNameEn: "Nicaraguan córdoba",
    flag: "🇳🇮",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Unico de Contribuyentes",
    tinType: "RUC",
  },
  {
    areaCodes: [],
    countryCallingCode: "227",
    countryCode: "NE",
    countryNameEn: "Niger",
    countryNameLocal: "Niger",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇳🇪",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "234",
    countryCode: "NG",
    countryNameEn: "Nigeria",
    countryNameLocal: "Nigeria",
    currencyCode: "NGN",
    currencyNameEn: "Nigerian naira",
    flag: "🇳🇬",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "683",
    countryCode: "NU",
    countryNameEn: "Niue",
    countryNameLocal: "Niue",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    flag: "🇳🇺",
    officialLanguageCode: "niu",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "672",
    countryCode: "NF",
    countryNameEn: "Norfolk Island",
    countryNameLocal: "Norfolk Island",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇳🇫",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "850",
    countryCode: "KP",
    countryNameEn: "North Korea",
    countryNameLocal: "조선민주주의인민공화국",
    currencyCode: "KPW",
    currencyNameEn: "North Korean won",
    flag: "🇰🇵",
    officialLanguageCode: "ko",
    officialLanguageNameEn: "Korean",
    officialLanguageNameLocal: "조선어",
    region: "Asia",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "389",
    countryCode: "MK",
    countryNameEn: "North Macedonia",
    countryNameLocal: "Македонија",
    currencyCode: "MKD",
    currencyNameEn: "Macedonian denar",
    flag: "🇲🇰",
    officialLanguageCode: "mk",
    officialLanguageNameEn: "Macedonian",
    officialLanguageNameLocal: "македонски јазик",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "47",
    countryCode: "NO",
    countryNameEn: "Norway",
    countryNameLocal: "Norge, Noreg",
    currencyCode: "NOK",
    currencyNameEn: "Norwegian krone",
    flag: "🇳🇴",
    officialLanguageCode: "nb",
    officialLanguageNameEn: "Norwegian Bokmål",
    officialLanguageNameLocal: "Norsk Bokmål",
    region: "Europe",
    tinName: "Organisasjonsnummer",
    tinType: "Orgnr",
  },
  {
    areaCodes: [],
    countryCallingCode: "968",
    countryCode: "OM",
    countryNameEn: "Oman",
    countryNameLocal: "سلطنة عُمان",
    currencyCode: "OMR",
    currencyNameEn: "Omani rial",
    flag: "🇴🇲",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "92",
    countryCode: "PK",
    countryNameEn: "Pakistan",
    countryNameLocal: "پاکستان",
    currencyCode: "PKR",
    currencyNameEn: "Pakistani rupee",
    flag: "🇵🇰",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "680",
    countryCode: "PW",
    countryNameEn: "Palau",
    countryNameLocal: "Palau",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇵🇼",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "970",
    countryCode: "PS",
    countryNameEn: "Palestine, State of",
    countryNameLocal: "Palestinian Territory",
    currencyCode: "",
    currencyNameEn: "",
    flag: "🇵🇸",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "507",
    countryCode: "PA",
    countryNameEn: "Panama",
    countryNameLocal: "Panama",
    currencyCode: "PAB",
    currencyNameEn: "Panamanian balboa",
    flag: "🇵🇦",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Unico de Contribuyentes",
    tinType: "RUC",
  },
  {
    areaCodes: [],
    countryCallingCode: "675",
    countryCode: "PG",
    countryNameEn: "Papua New Guinea",
    countryNameLocal: "Papua New Guinea",
    currencyCode: "PGK",
    currencyNameEn: "Papua New Guinean kina",
    flag: "🇵🇬",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "595",
    countryCode: "PY",
    countryNameEn: "Paraguay",
    countryNameLocal: "Paraguay",
    currencyCode: "PYG",
    currencyNameEn: "Paraguayan guaraní",
    flag: "🇵🇾",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Unico de Contribuyentes",
    tinType: "RUC",
  },
  {
    areaCodes: [],
    countryCallingCode: "51",
    countryCode: "PE",
    countryNameEn: "Peru",
    countryNameLocal: "Perú",
    currencyCode: "PEN",
    currencyNameEn: "Peruvian sol",
    flag: "🇵🇪",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Unico de Contribuyentes",
    tinType: "RUC",
  },
  {
    areaCodes: [],
    countryCallingCode: "63",
    countryCode: "PH",
    countryNameEn: "Philippines",
    countryNameLocal: "Philippines",
    currencyCode: "PHP",
    currencyNameEn: "Philippine peso",
    flag: "🇵🇭",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "Tax Identification Number",
    tinType: "TIN",
  },
  {
    areaCodes: [],
    countryCallingCode: "64",
    countryCode: "PN",
    countryNameEn: "Pitcairn",
    countryNameLocal: "Pitcairn",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    flag: "🇵🇳",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "48",
    countryCode: "PL",
    countryNameEn: "Poland",
    countryNameLocal: "Polska",
    currencyCode: "PLN",
    currencyNameEn: "Polish złoty",
    flag: "🇵🇱",
    officialLanguageCode: "pl",
    officialLanguageNameEn: "Polish",
    officialLanguageNameLocal: "język polski, polszczyzna",
    region: "Europe",
    tinName: "numer identyfikacji podatkowej",
    tinType: "NIP",
  },
  {
    areaCodes: [],
    countryCallingCode: "351",
    countryCode: "PT",
    countryNameEn: "Portugal",
    countryNameLocal: "Portugal",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇵🇹",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "Europe",
    tinName: "Número de Identificação de Pessoa Coletiva (NIPC)",
    tinType: "NIPC",
  },
  {
    areaCodes: [],
    countryCallingCode: "1",
    countryCode: "PR",
    countryNameEn: "Puerto Rico",
    countryNameLocal: "Puerto Rico",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇵🇷",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "974",
    countryCode: "QA",
    countryNameEn: "Qatar",
    countryNameLocal: "قطر",
    currencyCode: "QAR",
    currencyNameEn: "Qatari riyal",
    flag: "🇶🇦",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    countryCallingCode: "383",
    countryCode: "XK",
    countryNameEn: "Republic of Kosovo",
    countryNameLocal: "Republika e Kosovës",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇽🇰",
    officialLanguageCode: "sq",
    officialLanguageNameEn: "Albanian",
    officialLanguageNameLocal: "Shqip",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "373",
    countryCode: "MD",
    countryNameEn: "Republic of Moldova",
    countryNameLocal: "Moldova, Молдавия",
    currencyCode: "MDL",
    currencyNameEn: "Moldovan leu",
    flag: "🇲🇩",
    officialLanguageCode: "ro",
    officialLanguageNameEn: "Romanian, Moldavian, Moldovan",
    officialLanguageNameLocal: "Română",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "378",
    countryCode: "SM",
    countryNameEn: "Republic of San Marino",
    countryNameLocal: "Repubblica di San Marino",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇸🇲",
    officialLanguageCode: "it",
    officialLanguageNameEn: "Italian",
    officialLanguageNameLocal: "Italiano",
    region: "Europe",
    tinName: "Codice operatore economico",
    tinType: "C.O.E.",
  },
  {
    areaCodes: [],
    countryCallingCode: "242",
    countryCode: "CG",
    countryNameEn: "Republic of the Congo",
    countryNameLocal: "République du Congo",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    flag: "🇨🇬",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "692",
    countryCode: "MH",
    countryNameEn: "Republic of the Marshall Islands",
    countryNameLocal: "Aolepān Aorōkin Ṃajeḷ",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇲🇭",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Pacific Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "40",
    countryCode: "RO",
    countryNameEn: "Romania",
    countryNameLocal: "România",
    currencyCode: "RON",
    currencyNameEn: "Romanian leu",
    flag: "🇷🇴",
    officialLanguageCode: "ro",
    officialLanguageNameEn: "Romanian, Moldavian, Moldovan",
    officialLanguageNameLocal: "Română",
    region: "Europe",
    tinName: "Codul de identificare fiscală",
    tinType: "CIF",
  },
  {
    areaCodes: [],
    countryCallingCode: "7",
    countryCode: "RU",
    countryNameEn: "Russia",
    countryNameLocal: "Россия",
    currencyCode: "RUB",
    currencyNameEn: "Russian ruble",
    flag: "🇷🇺",
    officialLanguageCode: "ru",
    officialLanguageNameEn: "Russian",
    officialLanguageNameLocal: "русский",
    region: "Europe",
    tinName: "Идентификационный номер налогоплательщика",
    tinType: "ИНН",
  },
  {
    areaCodes: [],
    countryCallingCode: "250",
    countryCode: "RW",
    countryNameEn: "Rwanda",
    countryNameLocal: "Rwanda",
    currencyCode: "RWF",
    currencyNameEn: "Rwandan franc",
    flag: "🇷🇼",
    officialLanguageCode: "rw",
    officialLanguageNameEn: "Kinyarwanda",
    officialLanguageNameLocal: "Ikinyarwanda",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "262",
    countryCode: "RE",
    countryNameEn: "Réunion",
    countryNameLocal: "La Réunion",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇷🇪",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "590",
    countryCode: "BL",
    countryNameEn: "Saint Barthélemy",
    countryNameLocal: "Saint-Barthélemy",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇧🇱",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "290",
    countryCode: "SH",
    countryNameEn: "Saint Helena, Ascension and Tristan da Cunha",
    countryNameLocal: "Saint Helena",
    currencyCode: "SHP",
    currencyNameEn: "Saint Helena pound",
    flag: "🇸🇭",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1869",
    countryCode: "KN",
    countryNameEn: "Saint Kitts and Nevis",
    countryNameLocal: "Saint Kitts and Nevis",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇰🇳",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1758",
    countryCode: "LC",
    countryNameEn: "Saint Lucia",
    countryNameLocal: "Saint Lucia",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇱🇨",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "590",
    countryCode: "MF",
    countryNameEn: "Saint Martin (French part)",
    countryNameLocal: "Saint-Martin",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇲🇫",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "508",
    countryCode: "PM",
    countryNameEn: "Saint Pierre and Miquelon",
    countryNameLocal: "Saint-Pierre-et-Miquelon",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇵🇲",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "North America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1784",
    countryCode: "VC",
    countryNameEn: "Saint Vincent and the Grenadines",
    countryNameLocal: "Saint Vincent and the Grenadines",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    flag: "🇻🇨",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "685",
    countryCode: "WS",
    countryNameEn: "Samoa",
    countryNameLocal: "Samoa",
    currencyCode: "WST",
    currencyNameEn: "Samoan tala",
    flag: "🇼🇸",
    officialLanguageCode: "sm",
    officialLanguageNameEn: "Samoan",
    officialLanguageNameLocal: "gagana fa'a Samoa",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "239",
    countryCode: "ST",
    countryNameEn: "Sao Tome and Principe",
    countryNameLocal: "São Tomé e Príncipe",
    currencyCode: "STN",
    currencyNameEn: "",
    flag: "🇸🇹",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "966",
    countryCode: "SA",
    countryNameEn: "Saudi Arabia",
    countryNameLocal: "السعودية",
    currencyCode: "SAR",
    currencyNameEn: "Saudi riyal",
    flag: "🇸🇦",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "221",
    countryCode: "SN",
    countryNameEn: "Senegal",
    countryNameLocal: "Sénégal",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇸🇳",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "381",
    countryCode: "RS",
    countryNameEn: "Serbia",
    countryNameLocal: "Србија",
    currencyCode: "RSD",
    currencyNameEn: "Serbian dinar",
    flag: "🇷🇸",
    officialLanguageCode: "sr",
    officialLanguageNameEn: "Serbian",
    officialLanguageNameLocal: "српски језик",
    region: "Europe",
    tinName: "Poreski identifikacioni broj",
    tinType: "PIB",
  },
  {
    areaCodes: [],
    countryCallingCode: "248",
    countryCode: "SC",
    countryNameEn: "Seychelles",
    countryNameLocal: "Seychelles",
    currencyCode: "SCR",
    currencyNameEn: "Seychelles rupee",
    flag: "🇸🇨",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "232",
    countryCode: "SL",
    countryNameEn: "Sierra Leone",
    countryNameLocal: "Sierra Leone",
    currencyCode: "SLL",
    currencyNameEn: "Sierra Leonean leone",
    flag: "🇸🇱",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "65",
    countryCode: "SG",
    countryNameEn: "Singapore",
    countryNameLocal: "Singapore",
    currencyCode: "SGD",
    currencyNameEn: "Singapore dollar",
    flag: "🇸🇬",
    officialLanguageCode: "zh-hans",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1721",
    countryCode: "SX",
    countryNameEn: "Sint Maarten (Dutch part)",
    countryNameLocal: "Sint Maarten",
    currencyCode: "ANG",
    currencyNameEn: "Netherlands Antillean guilder",
    flag: "🇸🇽",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "Unknown",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "421",
    countryCode: "SK",
    countryNameEn: "Slovakia",
    countryNameLocal: "Slovensko",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇸🇰",
    officialLanguageCode: "sk",
    officialLanguageNameEn: "Slovak",
    officialLanguageNameLocal: "Slovenčina, Slovenský Jazyk",
    region: "Europe",
    tinName: "Identifikačné číslo pre daň z pridanej hodnoty",
    tinType: "IČ DPH",
  },
  {
    areaCodes: [],
    countryCallingCode: "386",
    countryCode: "SI",
    countryNameEn: "Slovenia",
    countryNameLocal: "Slovenija",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇸🇮",
    officialLanguageCode: "sl",
    officialLanguageNameEn: "Slovenian",
    officialLanguageNameLocal: "Slovenski Jezik, Slovenščina",
    region: "Europe",
    tinName: "Davčna številka",
    tinType: "ID za DDV",
  },
  {
    areaCodes: [],
    countryCallingCode: "677",
    countryCode: "SB",
    countryNameEn: "Solomon Islands",
    countryNameLocal: "Solomon Islands",
    currencyCode: "SBD",
    currencyNameEn: "Solomon Islands dollar",
    flag: "🇸🇧",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "252",
    countryCode: "SO",
    countryNameEn: "Somalia",
    countryNameLocal: "Somalia, الصومال",
    currencyCode: "SOS",
    currencyNameEn: "Somali shilling",
    flag: "🇸🇴",
    officialLanguageCode: "so",
    officialLanguageNameEn: "Somali",
    officialLanguageNameLocal: "Soomaaliga, af Soomaali",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "27",
    countryCode: "ZA",
    countryNameEn: "South Africa",
    countryNameLocal: "South Africa",
    currencyCode: "ZAR",
    currencyNameEn: "South African rand",
    flag: "🇿🇦",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "500",
    countryCode: "GS",
    countryNameEn: "South Georgia and the South Sandwich Islands",
    countryNameLocal: "South Georgia and the South Sandwich Islands",
    currencyCode: "",
    currencyNameEn: "",
    flag: "🇬🇸",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "82",
    countryCode: "KR",
    countryNameEn: "South Korea",
    countryNameLocal: "대한민국",
    currencyCode: "KRW",
    currencyNameEn: "South Korean won",
    flag: "🇰🇷",
    officialLanguageCode: "ko",
    officialLanguageNameEn: "Korean",
    officialLanguageNameLocal: "한국어",
    region: "Asia",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "211",
    countryCode: "SS",
    countryNameEn: "South Sudan",
    countryNameLocal: "South Sudan",
    currencyCode: "SSP",
    currencyNameEn: "South Sudanese pound",
    flag: "🇸🇸",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "34",
    countryCode: "ES",
    countryNameEn: "Spain",
    countryNameLocal: "España",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇪🇸",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "Europe",
    tinName:
      "Número de Identificación Fiscal (formerly named Código de Identificación Fiscal)",
    tinType: "NIF (CIF)",
  },
  {
    areaCodes: [],
    countryCallingCode: "94",
    countryCode: "LK",
    countryNameEn: "Sri Lanka",
    countryNameLocal: "ශ්‍රී ලංකා, இலங்கை",
    currencyCode: "LKR",
    currencyNameEn: "Sri Lankan rupee",
    flag: "🇱🇰",
    officialLanguageCode: "si",
    officialLanguageNameEn: "Sinhala, Sinhalese",
    officialLanguageNameLocal: "සිංහල",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "249",
    countryCode: "SD",
    countryNameEn: "Sudan",
    countryNameLocal: "السودان",
    currencyCode: "SDG",
    currencyNameEn: "Sudanese pound",
    flag: "🇸🇩",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "597",
    countryCode: "SR",
    countryNameEn: "Suriname",
    countryNameLocal: "Suriname",
    currencyCode: "SRD",
    currencyNameEn: "Surinamese dollar",
    flag: "🇸🇷",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "4779",
    countryCode: "SJ",
    countryNameEn: "Svalbard and Jan Mayen",
    countryNameLocal: "Svalbard and Jan Mayen",
    currencyCode: "NOK",
    currencyNameEn: "Norwegian krone",
    flag: "🇸🇯",
    officialLanguageCode: "no",
    officialLanguageNameEn: "Norwegian",
    officialLanguageNameLocal: "Norsk",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "46",
    countryCode: "SE",
    countryNameEn: "Sweden",
    countryNameLocal: "Sverige",
    currencyCode: "SEK",
    currencyNameEn: "Swedish krona/kronor",
    flag: "🇸🇪",
    officialLanguageCode: "sv",
    officialLanguageNameEn: "Swedish",
    officialLanguageNameLocal: "Svenska",
    region: "Europe",
    tinName: "VAT-nummer",
    tinType: "Momsnr.",
  },
  {
    areaCodes: [],
    countryCallingCode: "41",
    countryCode: "CH",
    countryNameEn: "Switzerland",
    countryNameLocal: "Schweiz, Suisse, Svizzera, Svizra",
    currencyCode: "CHE",
    currencyNameEn: "WIR Euro (complementary currency)",
    flag: "🇨🇭",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    region: "Europe",
    tinName: "Mehrwertsteuernummer",
    tinType: "MWST/TVA/IVA",
  },
  {
    areaCodes: [],
    countryCallingCode: "963",
    countryCode: "SY",
    countryNameEn: "Syrian Arab Republic",
    countryNameLocal: "سوريا, Sūriyya",
    currencyCode: "SYP",
    currencyNameEn: "Syrian pound",
    flag: "🇸🇾",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "886",
    countryCode: "TW",
    countryNameEn: "Taiwan, Province of China",
    countryNameLocal: "Taiwan",
    currencyCode: "TWD",
    currencyNameEn: "New Taiwan dollar",
    flag: "🇹🇼",
    officialLanguageCode: "zh-hant",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "992",
    countryCode: "TJ",
    countryNameEn: "Tajikistan",
    countryNameLocal: ",",
    currencyCode: "TJS",
    currencyNameEn: "Tajikistani somoni",
    flag: "🇹🇯",
    officialLanguageCode: "tg",
    officialLanguageNameEn: "Tajik",
    officialLanguageNameLocal: "тоҷикӣ, toçikī, تاجیکی‎",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "672",
    countryCode: "HM",
    countryNameEn: "Territory of Heard Island and McDonald Islands",
    countryNameLocal: "Territory of Heard Island and McDonald Islands",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇭🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Indian Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "66",
    countryCode: "TH",
    countryNameEn: "Thailand",
    countryNameLocal: "ประเทศไทย",
    currencyCode: "THB",
    currencyNameEn: "Thai baht",
    flag: "🇹🇭",
    officialLanguageCode: "th",
    officialLanguageNameEn: "Thai",
    officialLanguageNameLocal: "ไทย",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "670",
    countryCode: "TL",
    countryNameEn: "Timor-Leste",
    countryNameLocal: "Timor-Leste, Timor Lorosa'e",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇹🇱",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "228",
    countryCode: "TG",
    countryNameEn: "Togo",
    countryNameLocal: "Togo",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    flag: "🇹🇬",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "690",
    countryCode: "TK",
    countryNameEn: "Tokelau",
    countryNameLocal: "Tokelau",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    flag: "🇹🇰",
    officialLanguageCode: "tkl",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "676",
    countryCode: "TO",
    countryNameEn: "Tonga",
    countryNameLocal: "Tonga",
    currencyCode: "TOP",
    currencyNameEn: "Tongan paʻanga",
    flag: "🇹🇴",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "868",
    countryCode: "TT",
    countryNameEn: "Trinidad and Tobago",
    countryNameLocal: "Trinidad and Tobago",
    currencyCode: "TTD",
    currencyNameEn: "Trinidad and Tobago dollar",
    flag: "🇹🇹",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "216",
    countryCode: "TN",
    countryNameEn: "Tunisia",
    countryNameLocal: "تونس, Tunisie",
    currencyCode: "TND",
    currencyNameEn: "Tunisian dinar",
    flag: "🇹🇳",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "90",
    countryCode: "TR",
    countryNameEn: "Turkey",
    countryNameLocal: "Türkiye",
    currencyCode: "TRY",
    currencyNameEn: "Turkish lira",
    flag: "🇹🇷",
    officialLanguageCode: "tr",
    officialLanguageNameEn: "Turkish",
    officialLanguageNameLocal: "Türkçe",
    region: "Europe",
    tinName: "Vergi Kimlik Numarası",
    tinType: "KDV",
  },
  {
    areaCodes: [],
    countryCallingCode: "993",
    countryCode: "TM",
    countryNameEn: "Turkmenistan",
    countryNameLocal: "Türkmenistan",
    currencyCode: "TMT",
    currencyNameEn: "Turkmenistan manat",
    flag: "🇹🇲",
    officialLanguageCode: "tk",
    officialLanguageNameEn: "Turkmen",
    officialLanguageNameLocal: "Türkmen, Түркмен",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1 649",
    countryCode: "TC",
    countryNameEn: "Turks and Caicos Islands",
    countryNameLocal: "Turks and Caicos Islands",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇹🇨",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Atlantic Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "688",
    countryCode: "TV",
    countryNameEn: "Tuvalu",
    countryNameLocal: "Tuvalu",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    flag: "🇹🇻",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "256",
    countryCode: "UG",
    countryNameEn: "Uganda",
    countryNameLocal: "Uganda",
    currencyCode: "UGX",
    currencyNameEn: "Ugandan shilling",
    flag: "🇺🇬",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "380",
    countryCode: "UA",
    countryNameEn: "Ukraine",
    countryNameLocal: "Україна",
    currencyCode: "UAH",
    currencyNameEn: "Ukrainian hryvnia",
    flag: "🇺🇦",
    officialLanguageCode: "uk",
    officialLanguageNameEn: "Ukrainian",
    officialLanguageNameLocal: "Українська",
    region: "Europe",
    tinName: "Ідентифікаційний номер платника податків",
    tinType: "ІНПП",
  },
  {
    areaCodes: [],
    countryCallingCode: "971",
    countryCode: "AE",
    countryNameEn: "United Arab Emirates",
    countryNameLocal: "دولة الإمارات العربيّة المتّحدة",
    currencyCode: "AED",
    currencyNameEn: "United Arab Emirates dirham",
    flag: "🇦🇪",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "44",
    countryCode: "GB",
    countryNameEn: "United Kingdom",
    countryNameLocal: "Great Britain",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    flag: "🇬🇧",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Europe",
    tinName: "Value added tax registration number",
    tinType: "VAT Reg No",
  },
  {
    areaCodes: [],
    countryCallingCode: "255",
    countryCode: "TZ",
    countryNameEn: "United Republic of Tanzania",
    countryNameLocal: "Tanzania",
    currencyCode: "TZS",
    currencyNameEn: "Tanzanian shilling",
    flag: "🇹🇿",
    officialLanguageCode: "sw",
    officialLanguageNameEn: "Swahili",
    officialLanguageNameLocal: "Kiswahili",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1",
    countryCode: "UM",
    countryNameEn: "United States Minor Outlying Islands",
    countryNameLocal: "United States Minor Outlying Islands",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇺🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Pacific Ocean",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1",
    countryCode: "US",
    countryNameEn: "United States of America",
    countryNameLocal: "United States of America",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇺🇸",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "North America",
    tinName: "Tax Identification Number",
    tinType: "EIN",
  },
  {
    areaCodes: [],
    countryCallingCode: "598",
    countryCode: "UY",
    countryNameEn: "Uruguay",
    countryNameLocal: "Uruguay",
    currencyCode: "UYU",
    currencyNameEn: "Uruguayan peso",
    flag: "🇺🇾",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro Único Tributario",
    tinType: "RUT",
  },
  {
    areaCodes: [],
    countryCallingCode: "998",
    countryCode: "UZ",
    countryNameEn: "Uzbekistan",
    countryNameLocal: "",
    currencyCode: "UZS",
    currencyNameEn: "Uzbekistan som",
    flag: "🇺🇿",
    officialLanguageCode: "uz",
    officialLanguageNameEn: "Uzbek",
    officialLanguageNameLocal: "Oʻzbek, Ўзбек, أۇزبېك‎",
    region: "Asia & Pacific",
    tinName: "Солиқ тўловчиларнинг идентификация рақами",
    tinType: "СТИР",
  },
  {
    areaCodes: [],
    countryCallingCode: "678",
    countryCode: "VU",
    countryNameEn: "Vanuatu",
    countryNameLocal: "Vanuatu",
    currencyCode: "VUV",
    currencyNameEn: "Vanuatu vatu",
    flag: "🇻🇺",
    officialLanguageCode: "bi",
    officialLanguageNameEn: "Bislama",
    officialLanguageNameLocal: "Bislama",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "58",
    countryCode: "VE",
    countryNameEn: "Venezuela (Bolivarian Republic of)",
    countryNameLocal: "Venezuela",
    currencyCode: "VES",
    currencyNameEn: "",
    flag: "🇻🇪",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    region: "South/Latin America",
    tinName: "Registro de Informacion Fiscal",
    tinType: "RIF",
  },
  {
    areaCodes: [],
    countryCallingCode: "84",
    countryCode: "VN",
    countryNameEn: "Vietnam",
    countryNameLocal: "Việt Nam",
    currencyCode: "VND",
    currencyNameEn: "Vietnamese đồng",
    flag: "🇻🇳",
    officialLanguageCode: "vi",
    officialLanguageNameEn: "Vietnamese",
    officialLanguageNameLocal: "Tiếng Việt",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1284",
    countryCode: "VG",
    countryNameEn: "Virgin Islands (British)",
    countryNameLocal: "British Virgin Islands",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇻🇬",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "1340",
    countryCode: "VI",
    countryNameEn: "Virgin Islands (U.S.)",
    countryNameLocal: "United States Virgin Islands",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    flag: "🇻🇮",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "South/Latin America",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "681",
    countryCode: "WF",
    countryNameEn: "Wallis and Futuna",
    countryNameLocal: "Wallis-et-Futuna",
    currencyCode: "XPF",
    currencyNameEn: "CFP franc (franc Pacifique)",
    flag: "🇼🇫",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    region: "Asia & Pacific",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "212",
    countryCode: "EH",
    countryNameEn: "Western Sahara",
    countryNameLocal: "Sahara Occidental",
    currencyCode: "MAD",
    currencyNameEn: "Moroccan dirham",
    flag: "🇪🇭",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "967",
    countryCode: "YE",
    countryNameEn: "Yemen",
    countryNameLocal: "اليَمَن",
    currencyCode: "YER",
    currencyNameEn: "Yemeni rial",
    flag: "🇾🇪",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    region: "Arab States",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "260",
    countryCode: "ZM",
    countryNameEn: "Zambia",
    countryNameLocal: "Zambia",
    currencyCode: "ZMW",
    currencyNameEn: "Zambian kwacha",
    flag: "🇿🇲",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "263",
    countryCode: "ZW",
    countryNameEn: "Zimbabwe",
    countryNameLocal: "Zimbabwe",
    currencyCode: "ZWL",
    currencyNameEn: "Zimbabwean dollar",
    flag: "🇿🇼",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    region: "Africa",
    tinName: "",
    tinType: "",
  },
  {
    areaCodes: [],
    countryCallingCode: "358",
    countryCode: "AX",
    countryNameEn: "Åland Islands",
    countryNameLocal: "Åland",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    flag: "🇦🇽",
    officialLanguageCode: "sv",
    officialLanguageNameEn: "Swedish",
    officialLanguageNameLocal: "Svenska",
    region: "Europe",
    tinName: "",
    tinType: "",
  },
];
