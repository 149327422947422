import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Platform,
  Pressable,
} from "react-native";

import NameCard from "../components/NameCard";
import { clog } from "../utils/Log";
import { toggleFollow } from "../utils/Follow";
import { logEvent } from "../utils/LogEvent";
import Blocker from "./Blocker";

const OneUser = ({
  item,
  colors,
  myContext,
  state,
  navigation,
  refererActionId,
  hostUserId,
  origin,
  showFollowButton,
}) => {
  // NOTE(alpha): key only
  let followingComputed = myContext.actionsByUser?.["User"]?.["Follow"]?.[
    item.Id
  ]
    ? true
    : false;
  const [following, setFollowing] = useState(followingComputed);
  clog("item", item, "following", following);
  let updatefn = followingComputed ? state.removeFunction : state.addFunction;

  const FollowButton = () => {
    const callback = ({ success, message, error }) => {
      delete myContext.inflightFollows[item.Id];

      if (success) {
        updatefn(item?.Id);
      } else {
        // TODO(alpha): Update button state if there is failure
      }
      logEvent(
        following
          ? origin == "search"
            ? "Search_Unfollow"
            : origin + "_Unfollow"
          : origin == "search"
          ? "Search_Follow"
          : origin + "_Follow",
        {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: origin,
          platform: Platform.OS,
          target: following ? "unfollow" : "follow",
          action: "click",
          status: success ? "success" : "failure",
          targetuser: state.handle,
          [following ? "unfollowed" : "followed"]: item.handle,
          appVersion: myContext.appVersion,
        }
      );
    };

    const onPressButton = () => {
      if (myContext?.inflightFollows[item.Id]) {
        console.log("WARNING!!! FOLLOW IS INFLIGHT");
        return;
      }
      myContext.inflightFollows[item.Id] = true;
      let currentFollowing = following;
      setFollowing((prev) => {
        return !prev;
      });
      toggleFollow({
        following: currentFollowing,
        objectType: "User",
        target: item,
        refererActionId: refererActionId,
        hostUserId: hostUserId,
        myContext: myContext,
        callback: callback,
      });
    };

    return (
      <Pressable onPress={onPressButton}>
        <View
          style={
            following
              ? [
                  styles.uncoloredButton,
                  {
                    color: colors.secondaryButtonText,
                    borderColor: colors.secondaryButtonBorder,
                  },
                ]
              : [
                  styles.coloredButton,
                  {
                    color: colors.primaryButtonText,
                    backgroundColor: colors.primaryButtonBackground,
                  },
                ]
          }
        >
          <Text
            style={[
              styles.boldText,
              following
                ? { fontSize: 12, color: colors.secondaryButtonText }
                : { fontSize: 15, color: colors.primaryButtonText },
            ]}
          >
            {following ? "Following" : "Follow"}
          </Text>
        </View>
      </Pressable>
    );
  };

  const onPressCard = () => {
    //setUserData({ Id: item.Id, handle: item.handle });
    //setModalVisible(true);
    logEvent(origin == "search" ? "Search_Profile" : origin + "_Profile", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: origin,
      platform: Platform.OS,
      action: "click",
      target: "profile",
      targetuser: item?.handle,
      appVersion: myContext.appVersion,
    });
    navigation.push("UserDetails", {
      Id: item?.Id,
      handle: item?.handle,
      fromList: true,
      refererActionId: refererActionId,
      hostUserId: hostUserId,
    });
  };

  return (
    <View
      style={[
        styles.followableUser,
        { backgroundColor: colors.cardBackground },
      ]}
    >
      <View style={{ flex: 1 }}>
        <Pressable onPress={onPressCard}>
          <NameCard
            item={item}
            myContext={myContext}
            interaction={
              <View>
                {item?.Id != myContext.Id && showFollowButton ? (
                  <FollowButton />
                ) : null}
              </View>
            }
            colors={colors}
          />
        </Pressable>
      </View>
    </View>
  );
};

const UserList = ({
  state,
  myContext,
  navigation,
  origin,
  renderHeader,
  refererActionId,
  hostUserId,
  listStyle,
  colors,
  showFollowButton,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [userData, setUserData] = useState({ Id: "", handle: "" });

  const sharedKeyExtractor = (item) => item?.Id;

  const renderItem = ({ item }) => {
    return (
      <OneUser
        item={item}
        colors={colors}
        myContext={myContext}
        state={state}
        navigation={navigation}
        refererActionId={refererActionId}
        hostUserId={hostUserId}
        origin={origin}
        showFollowButton={showFollowButton}
      />
    );
  };

  clog("state", state);

  return (
    <>
      <FlatList
        title={state.relationship}
        data={state.users}
        style={listStyle}
        renderItem={renderItem}
        keyExtractor={sharedKeyExtractor}
        extraData={state}
        showsVerticalScrollIndicator={false}
      />
    </>
  );
};

export default UserList;

const center = {
  justifyContent: "center",
  textAlign: "center",
  textAlignVertical: "center",
  alignItems: "center",
};

const styles = StyleSheet.create({
  followableUser: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    marginTop: 16,
  },
  coloredButton: {
    ...center,
    borderRadius: 100,
    width: Platform.OS == "android" ? 69 : 60,
    height: 38,
  },
  uncoloredButton: {
    ...center,
    borderRadius: 100,
    width: Platform.OS == "android" ? 96 : 76,
    height: 30,
    borderWidth: 2,
  },
  boldText: { fontWeight: "700" },
});
