import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";

import {
  Alert,
  StyleSheet,
  View,
  SafeAreaView,
  Platform,
  Text,
  Image,
  TouchableOpacity,
  Modal,
  Pressable,
  FlatList,
  SectionList,
  ActionSheetIOS,
  UIManager,
  Animated,
  Dimensions,
  Easing,
} from "react-native";
import { WebView } from "react-native-webview";

import AppContext from "../components/AppContext";
import ItemList from "../components/ItemList";
import { timeStamp } from "../utils/TimeStamp";
import construction from "../assets/construction.png";
import gear from "../assets/gear.png";
import { clog } from "../utils/Log";
import {
  getFeedData,
  resetRecommendationState,
  refreshSingleUrl,
} from "../controllers/CommonFeedController";
import { logEvent } from "../utils/LogEvent";
import AwesomeAlert from "react-native-awesome-alerts";
import Blocker from "../components/Blocker";
import cross from "../assets/cross.png";
import MultiButtonAlert from "../utils/MultiButtonAlerts";
import CTA from "../utils/CTA";
import ShareMenu from "react-native-share-menu";
import Filter from "../components/Filter";
import { useTheme } from "../theme";
import PullToRefreshView from "../components/PullToRefreshView";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { BlurView } from "expo-blur";
import { useScrollToTop } from "@react-navigation/native";
import search from "../assets/search.png";
import notification from "../assets/notification.png";
import playIcon from "../assets/play.png";

import TopicSheet from "../components/TopicSheet";
import { getTopics } from "../controllers/TopicController";
import { serializationDataOfTopics } from "../utils/SerializationDataOfTopics";
import { generateMapFromIdStrings } from "../utils/IdList";
import { updateTopicUpdateRecommendationCounters } from "../controllers/UserController";
import {
  loadDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../utils/DataStorage";
import PopUp from "../components/PopUp";
import {
  isLowQualityExperience,
  recommendTopicUpdate,
} from "../utils/RecommendTopicUpdate";
import TopicPopUp from "../components/TopicPopUp";
import { Audio } from "expo-av";
import playButton from "../assets/play_button.png";
import pauseButton from "../assets/pause_button.png";
import stopButton from "../assets/stop_button.png";
import backButton from "../assets/back_button.png";
import forwardButton from "../assets/forward_button.png";
import { villageColors } from "../utils/SharedStyles";
import {
  trackViews,
  summarizeViews,
  sessionQualityAssessment,
} from "../utils/Tracker";

const CommonFeedScreen = ({ route, navigation }) => {
  const handleShare = useCallback((item) => {
    if (!item || (Platform.OS === "ios" && !item.extraData)) {
      return;
    }

    navigation.navigate("Share", { sharedExtraData: item });
  }, []);

  useEffect(() => {
    if (Platform.OS != "web") {
      ShareMenu.getInitialShare(handleShare);
    }
  }, []);

  useEffect(() => {
    if (Platform.OS != "web") {
      const listener = ShareMenu?.addNewShareListener(handleShare);
      return () => {
        listener?.remove();
      };
    }
  }, []);

  async function getData(handle, expanding = false, callback = null) {
    console.log("GETTING DATA", myContext["task"]);
    if (myContext["gettingFeedData"] == true) {
      console.log(
        "WARNING: trying to fetch data while fetch is already inflight"
      );
      if (callback) {
        callback(false);
      }
      return;
    }
    myContext["gettingFeedData"] = true;
    if (expanding === true) {
      setIsExpanding(true);
    } else {
      setIsRefreshing(true);
    }

    console.log("HAS SET REFRESH");
    getFeedData({
      handle: handle,
      task: myContext.task,
      myContext: myContext,
      callback: ({ success, message, error, requestingTask, dataset }) => {
        console.log(
          "GOT RESPONSE BACK FOR",
          requestingTask,
          "current",
          myContext["task"]
        );
        if (expanding === true) {
          setIsExpanding(false);
        } else {
          setIsRefreshing(false);
          if (showOnboarding) {
            Animated.timing(cardsAnimationRef, {
              toValue: 0,
              duration: 1000,
              easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
              useNativeDriver: true,
            }).start();
            Animated.timing(cardsOpacityRef, {
              toValue: 1,
              duration: 1000,
              useNativeDriver: true,
            }).start();
            setTimeout(() => setShowOnboarding(false), 2000);
          }
          flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
        }

        myContext["manualFetch"] = false;
        myContext["manualAccess"] = false;
        if (myContext.refreshingRecommendations) {
          if (dataset?.newCount == 0) {
            warnAboutRefreshUnavailable();
          } else {
            let userMessage = "Found more content for you!";
            showStatusMessage(userMessage);
          }
        }
        myContext["feedFetched"] = true;
        myContext["expandingRecommendations"] = false;
        myContext["refreshingRecommendations"] = false;
        myContext["creatingRecommendations"] = false;
        myContext["gettingFeedData"] = false;
        if (success) {
          clog("GOT BACK", dataset);
          if (requestingTask == myContext["task"]) {
            setState(dataset);
            if (callback) {
              console.log(
                "successfully fetched and will call callback",
                dataset?.panels?.length
              );
              callback(true);
            }
          } else {
            myContext.version++;
            dataRefresh();
          }
        }
      },
    });
  }

  const showAlertMessage = (message) => {
    console.log("WILL SHOW MESSAGE", message);
    setAlertMessage(message);
    setAlertVisible(true);
    onAnimatedNotification();
    setTimeout(() => {
      notificationOpacityRef.setValue(0);
      notificationTransformRef.setValue(0);
      setAlertVisible(false);
    }, 3000);
  };

  const showStatusMessage = (message) => {
    console.log("WILL SHOW MESSAGE", message);
    setStatusMessage(message);
    setStatusVisible(true);
    onAnimatedNotification();
    setTimeout(() => {
      notificationOpacityRef.setValue(0);
      notificationTransformRef.setValue(0);
      setStatusVisible(false);
    }, 3000);
  };

  const contentModalFunction = (target) => {
    //clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  const warnAboutRefreshUnavailable = () => {
    let message = "No more for today. Come back tomorrow!";
    showStatusMessage(message);
    logEvent("NoMoreContent", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: task,
      platform: Platform.OS,
      appVersion: myContext.appVersion,
    });
  };

  const refreshRecommendations = () => {
    let currentTime = timeStamp();
    let waitTime = myContext?.config?.recommendationIntervalDuration;
    if (!waitTime) {
      waitTime = 43200;
    }
    console.log(
      "LAST RECOMMENDATION TIME",
      myContext.lastRecommendationCreationTime,
      "CURRENT TIME",
      currentTime,
      "ELAPSED",
      currentTime -
        (myContext.lastRecommendationCreationTime
          ? myContext.lastRecommendationCreationTime
          : 0)
    );
    if (
      myContext.currentRecommendationCount <
      myContext.config.maxRecommendationsPerInterval
    ) {
      myContext["currentRecommendationRefreshCount"] =
        myContext.currentRecommendationRefreshCount
          ? myContext.currentRecommendationRefreshCount + 1
          : 1;
      myContext["currentRecommendationExpansionCount"] = 0;
      myContext["refreshingRecommendations"] = true;
      dataRefresh();
    } else if (
      !myContext.lastRecommendationCreationTime ||
      currentTime > myContext.lastRecommendationCreationTime + waitTime
    ) {
      console.log("SHOULD CREATE NEW RECOMMENDATIONS");
      myContext["creatingRecommendations"] = true;
      myContext["currentRecommendationRefreshCount"] = 0;
      myContext["currentRecommendationExpansionCount"] = 0;
      myContext["currentRecommendationCount"] = 0;
      dataRefresh();
    } else {
      warnAboutRefreshUnavailable();
    }
  };

  const expandRecommendations = (callback = null) => {
    if (!state.panels.length) {
      console.log("IGNORE THIS EXPANSION CALL");
      if (callback) {
        callback(false);
      }
      return;
    }
    if (myContext.declaredTopicsChanged) {
      console.log("IGNORE EXPANSION BECAUSE NEED TO HANDLE TOPIC CHANGE");
      if (callback) {
        callback(false);
      }
      return;
    }
    console.log("WILL EXPAND BECAUSE REACHED END OF LIST");
    console.log(
      "CURRENT RECOMMENDATION COUNT",
      myContext.currentRecommendationCount,
      "new Count",
      state.newCount,
      "fetch timestamp",
      state.fetchTimeStamp,
      "Current timestmap",
      timeStamp()
    );
    myContext["disallowedExpansion"] = false;
    if (
      myContext.currentRecommendationCount <
      myContext.config.maxRecommendationsPerInterval
    ) {
      if (
        !state.fetchTimeStamp ||
        (state.fetchTimeStamp &&
          (state.newCount || timeStamp() - state.fetchTimeStamp > 12 * 3600))
      ) {
        myContext["currentRecommendationExpansionCount"] =
          myContext.currentRecommendationExpansionCount
            ? myContext.currentRecommendationExpansionCount + 1
            : 1;
        myContext["expandingRecommendations"] = true;
        dataRefresh(true, callback);
      } else {
        console.log(
          "deciding not to expand because fetch timestamp",
          state.fetchTimeStamp,
          "new count",
          state.newCount,
          "elapsed",
          timeStamp() - state.fetchTimeStamp > 12 * 3600
        );
        if (callback) {
          callback(false);
        }
      }
    } else {
      console.log(
        "deciding not to expand because already showed",
        myContext.currentRecommendationCount,
        "recommendations when max allowed",
        myContext.config.maxRecommendationsPerInterval
      );
      myContext["disallowedExpansion"] = true;
      if (callback) {
        callback(false);
      }
    }
  };

  const resetRecommendations = async () => {
    myContext["lastRecommendationCreationTime"] = 0;
    myContext["lastRecommendationExpansionTime"] = 0;
    myContext["lastRecommendationRefreshTime"] = 0;
    myContext["currentRecommendationBatchSequence"] = 0;
    myContext["currentRecommendationItemSequence"] = 0;
    myContext["currentRecommendationRefreshCount"] = 0;
    myContext["currentRecommendationExpansionCount"] = 0;
    myContext["currentRecommendationCount"] = 0;

    myContext["expandingRecommendations"] = false;
    myContext["refreshingRecommendations"] = false;
    myContext["recommendationGenerated"] = false;
    myContext["existingRecommendations"] = {};
    myContext["previousDiscoverDataset"] = {};

    await resetRecommendationState(myContext);
    flatListRef?.current?.scrollToOffset({
      animated: true,
      offset: 0,
    });
    dataRefresh();
  };

  const initialState = {
    panels: [],
    fetchTimeStamp: 0,
    newCount: 0,
    version: 0,
  };
  const [state, setState] = useState(initialState);
  const [modalVisible, setModalVisible] = useState(false);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const myContext = useContext(AppContext);

  clog("STATE VERSION", state?.version, "CONTEXT VERSION", myContext?.version);

  if (!myContext.handle) {
    console.log("WILL GO TO AUTHENTICATION AGAIN", myContext.handle);
    navigation.replace("Authentication");
    return <View></View>;
  }

  clog("CONTEXT IS", myContext);
  const { colors, chosenScheme, setScheme, isDark } = useTheme();

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [topicModalVisible, setTopicModalVisible] = useState(false);
  const [topicSheetVisible, setTopicSheetVisible] = useState(false);
  const [topicSections, setTopicSections] = useState([]);
  const [showTopicAlert, setShowTopicAlert] = useState(false);
  const [task, setTask] = useState(route?.params?.taskFromParams);
  const [lastCTADismissal, setLastCTADismissal] = useState(
    myContext?.lastCTADismissal ? myContext.lastCTADismissal : 0
  );
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [playedDuration, setPlayedDuration] = useState(0);
  const [contentIndex, setContentIndex] = useState(null);
  const windowDim = Dimensions.get("window");
  const screenDim = Dimensions.get("screen");
  clog("ORIGINAL WINDOW", windowDim);
  clog("ORIGINAL SCREEN", screenDim);
  const [dimensions, setDimensions] = useState({
    window: windowDim,
    screen: screenDim,
  });

  const flatListRef = React.useRef();

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(
    route?.params?.onboarding ? route?.params?.onboarding : false
  );
  const [topics, setTopics] = useState(null);
  const [topicPopUpMessage, setTopicPopUpMessage] = useState(
    "Are you still interested in these notes? Mix it up! "
  );
  const [showTopicPopUp, setShowTopicPopUp] = useState(false);
  const [showTLDRPopUp, setShowTLDRPopUp] = useState(false);
  const [showReportPopUp, setShowReportPopUp] = useState(false);
  const [messageReportPopUp, setMessageReportPopUp] = useState("");
  const [numNotification, setNumNotification] = useState(
    myContext?.badgeDimension ? myContext.badgeDimension : 0
  );

  const popUpRef = useRef(null);

  const loadNewPlaybackInstance = async () => {
    try {
      if (myContext.playbackInstance != null) {
        await myContext.playbackInstance.unloadAsync();
        myContext.playbackInstance = null;
      }
      setProgress(0);
      setPlayedDuration(0);
      setTotalDuration(0);
      let items = state?.panels;
      clog("Will speak", contentIndex, items[contentIndex]);
      let chosenPin = null;
      items[contentIndex]?.pins?.forEach((pin) => {
        if (
          pin.content &&
          myContext.users[pin.curatorId]?.label?.includes("insider")
        ) {
          chosenPin = pin;
        }
      });
      if (chosenPin?.content) {
        setPlaying(true);
        let url =
          "https://villageaudio.s3.us-west-2.amazonaws.com/" +
          chosenPin.Id +
          ".mp3";
        const source = { uri: url };
        const initialStatus = {
          shouldPlay: true,
        };

        const { sound, status } = await Audio.Sound.createAsync(
          source,
          initialStatus,
          onPlaybackStatusUpdate
        );
        myContext["playbackInstance"] = sound;
        logEvent(
          task == "village" ? "Audio_Village_Play" : "Audio_Discover_Play",
          {
            userId: myContext?.Id,
            username: myContext?.handle,
            category: "audio",
            platform: Platform.OS,
            appVersion: myContext.appVersion,
            url: items[contentIndex]?.uri,
          }
        );
      }
    } catch (err) {
      console.log("something went from with audio playback");
    }
  };

  const onPlaybackStatusUpdate = (status) => {
    clog("status update", status);
    if (status.isLoaded) {
      //console.log("audio loaded successfully");
      /*this.setState({
        playbackInstancePosition: status.positionMillis,
        playbackInstanceDuration: status.durationMillis,
        shouldPlay: status.shouldPlay,
        isPlaying: status.isPlaying,
        isBuffering: status.isBuffering,
        rate: status.rate,
        muted: status.isMuted,
        volume: status.volume,
        loopingType: status.isLooping ? LOOPING_TYPE_ONE : LOOPING_TYPE_ALL,
        shouldCorrectPitch: status.shouldCorrectPitch
      }); */
      if (status.durationMillis) {
        setProgress(
          Math.round((status.positionMillis / status.durationMillis) * 100)
        );
        setTotalDuration(Math.round(status.durationMillis / 1000));
        setPlayedDuration(Math.round(status.positionMillis / 1000));
      }
      if (status.didJustFinish) {
        console.log("finished playing");
        moveIndexForwardConditionally();
      }
    } else {
      if (status.error) {
        console.log(`FATAL PLAYER ERROR: ${status.error}`);
        moveIndexForwardConditionally();
      }
    }
  };

  function moveIndexForwardConditionally() {
    if (contentIndex + 1 < state?.panels?.length) {
      console.log("no problem moving forward");
      moveIndex("forward");
    } else {
      myContext["playWhenAvailable"] = true;
      expandRecommendations((success) => {
        if (success) {
          console.log("expanded feed", state?.panels?.length);
        } else {
          myContext["playWhenAvailable"] = false;
          console.log("could not expand feed");
        }
      });
    }
  }

  function moveIndex(direction, callback = null) {
    let moved = true;
    setContentIndex((prev) => {
      let newValue = prev;
      let items = state?.panels;
      if (direction == "forward" && items?.length > prev + 1) {
        console.log("will use", items[prev + 1]?.screenshotUrl);
        newValue = prev + 1;
      } else if (direction == "back" && prev - 1 >= 0) {
        console.log("will use", items[prev - 1]?.screenshotUrl);
        newValue = prev - 1;
      } else if (direction == "start" && prev == null) {
        newValue = 0;
      } else if (direction == "stop") {
        newValue = null;
      } else {
        console.log(
          "will not move because length",
          items?.length,
          "current",
          contentIndex
        );
        moved = false;
      }
      if (callback) {
        callback(moved);
      }

      // construct
      let changes = [];
      if (prev != null) {
        let prevChange = {
          key: items[prev].Id,
          isViewable: false,
          item: items[prev],
        };
        changes.push(prevChange);
      }
      if (newValue != prev && newValue != null) {
        let newChange = {
          key: items[newValue].Id,
          isViewable: true,
          item: items[newValue],
        };
        changes.push(newChange);
      }
      clog("CHANGES", changes);
      if (changes.length > 0) {
        trackViews(
          "Url",
          changes,
          myContext,
          task == "village" ? "myvillageAudio" : "discoverAudio"
        );
      }
      return newValue;
    });
  }

  function onForwardPressed() {
    if (myContext.playbackInstance != null) {
      moveIndexForwardConditionally();
    }
  }

  const onBackPressed = () => {
    if (myContext.playbackInstance != null) {
      moveIndex("back");
    }
  };

  const stopPlaying = async () => {
    console.log("will stop playing");
    if (myContext.playbackInstance != null) {
      console.log("trying to stop playing");
      await myContext.playbackInstance.stopAsync();
      await myContext.playbackInstance.unloadAsync();
      console.log("unloaded audio");
      myContext.playbackInstance = null;
    }
  };

  const closeModal = async () => {
    console.log("going to close modal");
    myContext["playWhenAvailable"] = false;
    setPlaying(false);
    await stopPlaying();
    moveIndex("stop");
    setModalVisible(false);
  };

  useEffect(() => {
    if (contentIndex != null) {
      loadNewPlaybackInstance();
    }
  }, [contentIndex]);

  useEffect(() => {
    console.log("playing state is now", playing);
    if (myContext.playbackInstance != null) {
      console.log("has playback instace");
      if (!playing) {
        console.log("asked to pause");
        myContext.playbackInstance.pauseAsync();
      } else {
        console.log("asked to play");
        myContext.playbackInstance.playAsync();
      }
    }
  }, [playing]);

  useEffect(() => {
    console.log("number of items in feed", state?.panels?.length);
    if (myContext.playWhenAvailable) {
      myContext["playWhenAvailable"] = false;
      moveIndex("forward");
    }
  }, [state?.panels?.length]);

  //Animation for filters bar
  const insets = useSafeAreaInsets();

  const HEADER_HEIGHT = 47;
  const FILTER_BAR_HEIGHT = 0; // 50 if filters turn on or 0 if filters turn off
  const MARGIN_FOR_FILTER_BAR = HEADER_HEIGHT + insets.top + FILTER_BAR_HEIGHT;

  const MARGIN_FOR_NOTIFICATION = 75 + HEADER_HEIGHT + FILTER_BAR_HEIGHT;

  const scrollY = useRef(new Animated.Value(0)).current;

  const notificationTransformRef = useRef(new Animated.Value(0)).current;
  const notificationOpacityRef = useRef(new Animated.Value(0)).current;
  const popUpTransformRef = useRef(new Animated.Value(0)).current;

  const clampedScroll = Animated.diffClamp(
    scrollY.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolateLeft: "clamp",
    }),
    0,
    MARGIN_FOR_FILTER_BAR
  );

  const blurContainer = clampedScroll.interpolate({
    inputRange: [0, HEADER_HEIGHT + FILTER_BAR_HEIGHT],
    outputRange: [0, -(HEADER_HEIGHT + FILTER_BAR_HEIGHT)],
    extrapolate: "clamp",
  });

  const headerTranslate = clampedScroll.interpolate({
    inputRange: [0, HEADER_HEIGHT + FILTER_BAR_HEIGHT],
    outputRange: [0, -(HEADER_HEIGHT + FILTER_BAR_HEIGHT)],
    extrapolate: "clamp",
  });

  const headerOpacity = clampedScroll.interpolate({
    inputRange: [0, HEADER_HEIGHT + FILTER_BAR_HEIGHT],
    outputRange: [1, 0],
    extrapolate: "clamp",
  });

  const listTranslate = scrollY.interpolate({
    inputRange: [0, (HEADER_HEIGHT + FILTER_BAR_HEIGHT) * 1.5],
    outputRange: [MARGIN_FOR_FILTER_BAR, 0],
    extrapolate: "clamp",
  });

  const onAnimatedNotification = () => {
    Animated.timing(notificationOpacityRef, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();

    Animated.timing(notificationTransformRef, {
      toValue: MARGIN_FOR_NOTIFICATION,
      duration: 800,
      useNativeDriver: true,
    }).start();
  };

  const onAnimatedPopUp = () => {
    Animated.timing(popUpTransformRef, {
      toValue: -120,
      duration: 500,
      easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
      useNativeDriver: true,
    }).start();
  };

  const height = Dimensions.get("screen").height;
  const cardsAnimationRef = useRef(new Animated.Value(height / 4)).current;
  const cardsOpacityRef = useRef(new Animated.Value(0)).current;

  useScrollToTop(flatListRef);

  useEffect(() => {
    async function checkFirstLaunch() {
      const showedTLDRPopupString = await loadDataFromLocalStorage(
        "showedTLDRPopup"
      );

      const showedTLDRPopup =
        showedTLDRPopupString != null
          ? JSON.parse(showedTLDRPopupString)
          : null;

      if (!showedTLDRPopup) {
        if (
          myContext.lastTopicUpdateTime ||
          myContext.lastTopicUpdateRecommendationTime
        ) {
          await saveDataToLocalStorage("showedTLDRPopup", JSON.stringify(true));
          setShowTLDRPopUp(true);
        }
      }
    }

    if (Platform.OS !== "web") {
      checkFirstLaunch();
    }
  }, []);

  useEffect(() => {
    console.log(
      "STORED THEME",
      myContext.chosenTheme,
      "FOUND THEME",
      chosenScheme
    );
    if (myContext.chosenTheme && myContext.chosenTheme != chosenScheme) {
      setScheme(myContext.chosenTheme);
    }
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        clog("NEW WINDOW", window);
        clog("NEW SCREEN", screen);
        setDimensions({ window, screen });
      }
    );
    return () => subscription?.remove();
  }, []);

  useEffect(() => {
    setNumNotification(myContext?.badgeDimension);
  }, [myContext?.badgeDimension]);

  let webref = null;
  if (
    Platform.OS === "android" &&
    UIManager.setLayoutAnimationEnabledExperimental
  ) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  ////clog("context is", myContext);
  let topicsAvailable = [];
  // Note(alpha): Access keys only
  if (myContext.topics && myContext.declaredTopicIds) {
    let followedTopicIds = generateMapFromIdStrings([
      myContext.declaredTopicIds,
    ]);
    Object.values(myContext.topics)
      .map(function (x) {
        clog("returning one item", x);
        let Id = x.Id;
        let name = x.name;
        let frequency = x.numUrls;
        let avatar = x.avatar;
        // NOTE(alpha): keys only
        if (followedTopicIds[Id]) {
          return {
            Id: Id,
            name: name,
            frequency: frequency,
            avatar: avatar,
          };
        }
      })
      .sort((a, b) =>
        a.frequency != b.frequency
          ? a.frequency > b.frequency
            ? -1
            : 1
          : a.name < b.name
          ? -1
          : 1
      )
      .forEach((t) => {
        if (t && t.Id) {
          topicsAvailable.push(t);
        }
      });
  }

  clog("TOPICS", topicsAvailable);
  clog("VERSION", myContext.version);
  let topicsNotFollowed = [];
  if (myContext.topics) {
    let followedTopicIds = {};
    if (myContext.declaredTopicIds) {
      followedTopicIds = generateMapFromIdStrings([myContext.declaredTopicIds]);
    }
    Object.values(myContext.topics)
      .map(function (x) {
        clog("returning one item", x);
        let Id = x.Id;
        let name = x.name;
        let frequency = x.numUrls;
        let avatar = x.avatar;
        // NOTE(alpha): keys only
        if (!followedTopicIds[Id]) {
          return {
            Id: Id,
            name: name,
            frequency: frequency,
            avatar: avatar,
          };
        }
      })
      .sort((a, b) =>
        a.frequency != b.frequency
          ? a.frequency > b.frequency
            ? -1
            : 1
          : a.name < b.name
          ? -1
          : 1
      )
      .forEach((t) => {
        if (t && t.Id) {
          topicsNotFollowed.push(t);
        }
      });
  }
  clog("TOPICS not followed", topicsNotFollowed);
  React.useEffect(() => {
    setTopicSections([
      {
        title: "My interests",
        key: "mytopics",
        data: [{ list: topicsAvailable }],
        version: myContext.version,
      },
      {
        title: "Other topics",
        key: "othertopics",
        data: [{ list: topicsNotFollowed }],
      },
    ]);
    if (!myContext.task) {
      myContext["task"] = "discover";
    }
  }, [navigation]);

  let topicOptions = [];
  topicsAvailable.forEach((t) => {
    topicOptions.push(t);
  });
  // Add a separator
  topicOptions.push({ Id: -1, name: "dummy" });
  topicsNotFollowed.forEach((t) => {
    topicOptions.push(t);
  });
  //clog("FINAL TOPICS", topicOptions);

  clog("SECTION TOPICS", topicSections);

  let handle = myContext.handle;
  clog("ROUTE", route, "NAVIGATION", navigation);

  if (!handle) {
    return <View></View>;
  }

  React.useEffect(() => {
    clog("Will set new topic sections");
    setTopicSections([
      {
        title: "My interests",
        key: "mytopics",
        data: [{ list: topicsAvailable }],
        version: myContext.version,
      },
      {
        title: "Other topics",
        key: "othertopics",
        data: [{ list: topicsNotFollowed }],
      },
    ]);
  }, [myContext.version]);

  React.useEffect(() => {
    recommendTopicUpdate({
      myContext: myContext,
      setTopicPopUpMessage: (value) => setTopicPopUpMessage(value),
      showTopicPopUp: () => {
        setShowTopicPopUp(true);
        onAnimatedPopUp();
      },
    });
  }, []);

  async function dataRefresh(expanding = false, callback = null) {
    console.log("Will refresh data");
    if (myContext.urlEngagedWith) {
      console.log("WILL SKIP REFRESH BECAUSE ENGAGED WITH URL");
      let url = myContext.urlEngagedWith;
      let oldPanels =
        myContext.task == "village"
          ? myContext.previousVillageDataset?.panels
          : myContext.previousDiscoverDataset?.panels;
      clog("ENGAGED WITH URL", url, "panels", oldPanels);
      refreshSingleUrl(
        url,
        myContext,
        oldPanels,
        ({ success, message, requestingTask, panels }) => {
          clog("RESPONSE", success, "message", message, "panels", panels);
          if (success) {
            setState((prevState) => {
              return {
                ...prevState,
                panels: panels,
                version: myContext.version,
              };
            });
          }
          myContext["urlEngagedWith"] = null;
        }
      );
      if (callback) {
        callback(false);
      }
      return;
    }
    if (myContext.declaredTopicsChanged) {
      console.log("Declared topics changed, should recreate recommendations");
      // TODO(alpha): Check if we can filter out unwanted items and expand with
      // new items instead of replacing all existing items
      myContext.creatingRecommendations = true;
    }
    setTopicSections([
      {
        title: "My interests",
        key: "mytopics",
        data: [{ list: topicsAvailable }],
        version: myContext.version,
      },
      {
        title: "Other topics",
        key: "othertopics",
        data: [{ list: topicsNotFollowed }],
      },
    ]);
    logEvent(task == "village" ? "MyVillage_View" : "Discover_View", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: task,
      platform: Platform.OS,
      appVersion: myContext.appVersion,
    });

    // do something
    let currentTime = timeStamp();
    let waitTime = myContext?.config?.recommendationIntervalDuration;
    if (!waitTime) {
      waitTime = 43200;
    }
    if (
      task == "discover" &&
      (!myContext.lastRecommendationCreationTime ||
        currentTime > myContext.lastRecommendationCreationTime + waitTime ||
        !myContext.currentRecommendationCount)
    ) {
      console.log("SHOULD CREATE NEW RECOMMENDATIONS");
      myContext["creatingRecommendations"] = true;
      myContext["currentRecommendationRefreshCount"] = 0;
      myContext["currentRecommendationExpansionCount"] = 0;
      myContext["currentRecommendationCount"] = 0;
    }

    console.log(
      "Manual fetch",
      myContext.manualFetch,
      "manual access",
      myContext.manualAccess
    );
    if (
      myContext.manualFetch ||
      myContext.manualAccess ||
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800 ||
      (task == "discover" &&
        (myContext.creatingRecommendations ||
          !myContext.recommendationGenerated ||
          myContext.expandingRecommendations ||
          myContext.refreshingRecommendations))
    ) {
      console.log(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version
      );
      let sleepDuration = 1;
      if (myContext.newlyCreatedPin) {
        sleepDuration = 1000;
        myContext.newlyCreatedPin = false;
      }
      console.log("WILL SLEEP FOR", sleepDuration);
      setTimeout(function () {
        console.log("WILL START DATA FETCH");
        getData(handle, expanding, callback).catch((err) => {
          console.log("error finding user: ", err);
        });
      }, sleepDuration);
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
      if (callback) {
        callback(false);
      }
    }
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  /*useEffect(() => {
    getData(handle).catch((err) => {
      clog("error finding user: ", err);
    });
  }, []); */

  useEffect(() => {
    if (
      myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
        "RedirectBackToTopicFiltering"
      ] != null
    ) {
      if (myContext.updatingTopics) {
        setTopicModalVisible(true);
      }
    }
  }, [navigation, state, task, colors, dimensions]);

  useEffect(() => {
    if (!topics) {
      getTopicData();
    }
  }, [topics]);

  async function getTopicData() {
    clog("will get topic data");
    await getTopics({
      myContext: myContext,
      callback: ({ success, message, error, topicData }) => {
        if (success) {
          clog("SETTING TOPIC DATA TO", topicData);

          serializationDataOfTopics({
            data: topicData,
            onboarding: false,
            callback: (data) => setTopics(data),
          });
        } else {
          //clog("COULD NOT FETCH TOPICS");
        }
      },
    });
  }

  const onRefreshVillage = () => {
    console.log("got asked to refresh");
    if (!isRefreshing) {
      console.log("refreshing data now");
      myContext["manualFetch"] = true;
      getData(handle);
    } else {
      //clog("refresh data in progress already");
    }
  };

  const onReffreshDiscover = () => {
    //clog("got asked to refresh");
    if (!isRefreshing) {
      refreshRecommendations();
    } else {
      //clog("refresh data in progress already");
    }
  };

  function onLastItemVisible() {
    clog("LAST ITEM IS VISIBLE NOW!!!");
    if (myContext.disallowedExpansion) {
      let lowQuality = isLowQualityExperience(myContext);
      console.log("QUALITY", lowQuality);
      if (lowQuality) {
        console.log("PATH 1");
        setTopicPopUpMessage(
          "Updating followed topics might improve your experience! "
        );
        setShowTopicPopUp(true);
        onAnimatedPopUp();
        updateTopicUpdateRecommendationCounters(myContext, null);
      }
    }
  }

  const renderHeader = () => {
    const margin =
      Platform.OS == "android" ? 100 : (dimensions.window.width - 160) / 2;

    clog("margin", margin, "window", dimensions.window.width);

    const renderNotificationButton = () => {
      const onPressNotification = () => {
        setNumNotification(0);
        myContext.badgeDimension = "";
        navigation.push("Notification");
      };

      return (
        <Pressable
          style={{
            width: 24,
            height: 24,
          }}
          onPress={onPressNotification}
        >
          <View
            style={{
              width: 24,
              height: 24,
            }}
          >
            <Image
              source={notification}
              style={{
                width: "100%",
                height: "100%",
                tintColor: colors.primaryText,
              }}
            />
          </View>
          {!!numNotification && (
            <View
              style={{
                position: "absolute",
                right: -10,
                top: -10,
                width: 22,
                height: 22,
                backgroundColor: colors.primaryText,
                borderRadius: 100,
                alignItems: "center",
                justifyContent: "center",
                lineHeight: 15,
              }}
            >
              <Text style={{ color: "white", fontSize: 12, fontWeight: "700" }}>
                {numNotification}
              </Text>
            </View>
          )}
        </Pressable>
      );
    };

    const MyVillageButton = () => {
      const onPressVillage = () => {
        setIsExpanding(false);
        setIsRefreshing(true);
        console.log("Pressed Village");
        setTask("village");
        //myContext.version++;
        //myContext["manualFetch"] = true;
        myContext["manualAccess"] = true;
        myContext["task"] = "village";
        setState((prevState) => {
          return { ...prevState, panels: [] };
        });
        dataRefresh();
      };

      return (
        <Pressable onPress={onPressVillage} style={styles.tabButton}>
          <Text
            style={{
              color:
                task == "discover" ? colors.secondaryText : colors.primaryText,
              fontSize: 15,
              fontWeight: task == "discover" ? "400" : "700",
            }}
          >
            {"Village"}
          </Text>
          <View
            style={{
              backgroundColor:
                task == "discover" ? "transparent" : colors.activeTabUnderline,
              height: 2,
              width: "100%",
              marginTop: 4,
            }}
          />
        </Pressable>
      );
    };

    const DiscoverButton = () => {
      const onPressDiscover = () => {
        console.log("Pressed Discover");
        setTask("discover");
        //myContext.version++;
        myContext["task"] = "discover";
        myContext["manualFetch"] = true;
        setState((prevState) => {
          return { ...prevState, panels: [] };
        });
        dataRefresh();
      };

      return (
        <Pressable style={styles.tabButton} onPress={onPressDiscover}>
          <Text
            style={{
              color:
                task != "discover" ? colors.secondaryText : colors.primaryText,
              fontSize: 15,
              fontWeight: task != "discover" ? "400" : "700",
            }}
          >
            {"Discover"}
          </Text>
          <View
            style={{
              backgroundColor:
                task != "discover" ? "transparent" : colors.activeTabUnderline,
              height: 2,
              width: "100%",
              marginTop: 4,
            }}
          />
        </Pressable>
      );
    };

    const renderSearchButton = () => {
      const onPressSearch = () => {
        logEvent("Content_Search", {
          userId: myContext?.Id,
          username: myContext?.handle,
          platform: Platform.OS,
          category: task == "village" ? "myvillage" : task,
          appVersion: myContext.appVersion,
        });
        myContext["searchInflight"] = false;
        myContext["query"] = "";
        navigation.push("Search", {
          subsequent: true,
          taskFromParams: task,
          fromTab: false,
        });
      };

      return (
        <View style={{ flexDirection: "row" }}>
          <Pressable
            style={{
              width: 24,
              height: 24,
              marginRight: 20,
            }}
            onPress={() => {
              clog(
                "will use image",
                state?.panels?.[0],
                state?.panels?.[0]?.screenshotUrl
              );
              moveIndex("start");
              setPlaying(true);
              setModalVisible(true);
              logEvent(task == "village" ? "Audio_Village" : "Audio_Discover", {
                userId: myContext?.Id,
                username: myContext?.handle,
                category: "audio",
                platform: Platform.OS,
                appVersion: myContext.appVersion,
              });
            }}
          >
            <View
              style={{
                width: 24,
                height: 24,
              }}
            >
              <Image
                source={playButton}
                style={{
                  width: "100%",
                  height: "100%",
                  tintColor: colors.primaryText,
                }}
              />
            </View>
          </Pressable>
          <Pressable
            style={{
              width: 24,
              height: 24,
            }}
            onPress={onPressSearch}
          >
            <View
              style={{
                width: 24,
                height: 24,
              }}
            >
              <Image
                source={search}
                style={{
                  width: "100%",
                  height: "100%",
                  tintColor: colors.primaryText,
                }}
              />
            </View>
          </Pressable>
        </View>
      );
    };

    return (
      <View
        style={[
          styles.headerContainer,
          {
            height: HEADER_HEIGHT,
          },
        ]}
      >
        <View
          style={{
            flex: 0.5,
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          {renderNotificationButton()}
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <MyVillageButton />
          <DiscoverButton />
        </View>

        <View
          style={{
            flex: 0.5,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {renderSearchButton()}
        </View>
      </View>
    );
  };

  clog("PANELS", state?.panels);

  const ListEmptyComponent = () => {
    return (
      <View
        style={{
          zIndex: 100,
          backgroundColor: colors.background,
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          paddingTop: insets.top,
        }}
      >
        <Image
          source={construction}
          style={{
            height: 240,
            width: 380,
          }}
        ></Image>
        <Text style={{ color: colors.primaryText, fontSize: 14 }}>
          {"To see posts in this feed, try following villagers."}
        </Text>
        <Pressable
          onPress={() => {
            navigation.push("Search", {
              subsequent: true,
            });
          }}
        >
          <Text style={[styles.callToActionEmphasized, { color: colors.link }]}>
            {"See our recommendations."}
          </Text>
        </Pressable>
      </View>
    );
  };

  const OnboardingAnimation = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colors.background,
        }}
      >
        <Image
          style={{ height: 250, width: 250 }}
          source={require("../assets/oboarding-loading.gif")}
        />
        <Text
          style={{
            color: colors.primaryText,
            fontWeight: "700",
            fontSize: 15,
            lineHeight: 22,
          }}
        >
          {"Putting in finishing touches..."}
        </Text>
      </View>
    );
  };

  return (
    <View style={[styles.safeAreaView, { backgroundColor: colors.background }]}>
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        {statusVisible && (
          <Animated.View
            style={[
              styles.notification,
              {
                opacity: notificationOpacityRef,
                transform: [
                  {
                    translateY: notificationTransformRef,
                  },
                ],
              },
            ]}
          >
            <Pressable
              onPress={() => {
                setStatusVisible(false);
              }}
            >
              <View
                style={[
                  styles.notificationView,
                  {
                    backgroundColor: colors.notificationBackground,
                    overflow: "hidden",
                  },
                ]}
              >
                <View
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={[
                      styles.notificationText,
                      { color: colors.notificationText },
                    ]}
                  >
                    {statusMessage}
                  </Text>
                </View>
              </View>
            </Pressable>
          </Animated.View>
        )}
        {alertVisible && (
          <Animated.View
            style={[
              styles.notification,
              {
                opacity: notificationOpacityRef,
                transform: [
                  {
                    translateY: notificationTransformRef,
                  },
                ],
              },
            ]}
          >
            <Pressable
              onPress={() => {
                setAlertVisible(false);
              }}
            >
              <View
                style={[
                  styles.notificationView,
                  { backgroundColor: colors.notificationBackground },
                ]}
              >
                {alertMessage}
              </View>
            </Pressable>
          </Animated.View>
        )}

        <Blocker loading={loading} />

        {false && timeStamp() > lastCTADismissal + 86400 && (
          <View style={{ marginBottom: 16 }}>
            <CTA
              context={myContext}
              timestamp={timeStamp()}
              updateDismissal={setLastCTADismissal}
              navigation={navigation}
            />
          </View>
        )}
        {task == "village" && (
          <PullToRefreshView
            scrollY={scrollY}
            onRefresh={onRefreshVillage}
            isRefreshing={isRefreshing}
            listTranslate={listTranslate}
            panels={state?.panels}
            openTopicSheet={() => {
              setTopicSheetVisible(true);
            }}
            origin={task == "village" ? "myvillage" : "discover"}
          >
            <ItemList
              state={state}
              panels={state?.panels}
              navigation={navigation}
              myContext={myContext}
              task={task}
              forceRefresh={(element, callback) => {
                refreshSingleUrl(
                  element,
                  myContext,
                  state.panels,
                  ({ success, message, requestingTask, panels }) => {
                    if (success) {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          panels: panels,
                          version: myContext.version,
                        };
                      });
                      if (element.Id == myContext.urlEngagedWith?.Id) {
                        console.log("Already refreshed engaged url");
                        myContext["urlEngagedWith"] = null;
                      }
                    }
                    if (callback) {
                      callback();
                    }
                  }
                );
              }}
              showAlertMessage={showAlertMessage}
              setLoading={setLoading}
              origin={task == "village" ? "myvillage" : "discover"}
              contentModalFunction={contentModalFunction}
              ref={flatListRef}
              colors={colors}
              isRefreshing={isRefreshing}
              isExpanding={isExpanding}
              ListEmptyComponent={ListEmptyComponent}
              showJustification={true}
              showTLDRPopUp={showTLDRPopUp}
              setShowTLDRPopUp={setShowTLDRPopUp}
              onShowReportPopUp={(message) => {
                setMessageReportPopUp(message);
                setShowReportPopUp(true);
                popUpRef.current?.onAnimatedPopUp();
              }}
            />
          </PullToRefreshView>
        )}
        {task == "discover" && (
          <Animated.View
            style={[
              {
                flex: 1,
              },
              showOnboarding && {
                opacity: cardsOpacityRef,
                transform: [{ translateY: cardsAnimationRef }],
              },
            ]}
          >
            <PullToRefreshView
              scrollY={scrollY}
              onRefresh={onReffreshDiscover}
              isRefreshing={isRefreshing}
              listTranslate={listTranslate}
              panels={state?.panels}
              openTopicSheet={() => {
                setTopicSheetVisible(true);
              }}
              origin={task == "village" ? "myvillage" : "discover"}
            >
              <ItemList
                state={state}
                panels={state?.panels}
                navigation={navigation}
                myContext={myContext}
                task={task}
                forceRefresh={(element, callback) => {
                  refreshSingleUrl(
                    element,
                    myContext,
                    state.panels,
                    ({ success, message, requestingTask, panels }) => {
                      if (success) {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            panels: panels,
                            version: myContext.version,
                          };
                        });
                        if (element.Id == myContext.urlEngagedWith?.Id) {
                          console.log("Already refreshed engaged url");
                          myContext["urlEngagedWith"] = null;
                        }
                      }
                      if (callback) {
                        callback();
                      }
                    }
                  );
                }}
                showAlertMessage={showAlertMessage}
                setLoading={setLoading}
                origin={task == "village" ? "myvillage" : "discover"}
                contentModalFunction={contentModalFunction}
                footer={
                  Platform.OS == "web" && (
                    <View
                      style={{
                        marginHorizontal: 20,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 50,
                      }}
                    >
                      <Pressable onPress={refreshRecommendations}>
                        <View
                          style={{
                            backgroundColor: colors.primaryButtonBackground,
                            padding: 10,
                          }}
                        >
                          <Text style={{ color: colors.primaryButtonText }}>
                            Refresh
                          </Text>
                        </View>
                      </Pressable>
                      <Pressable onPress={resetRecommendations}>
                        <View
                          style={{
                            backgroundColor: colors.primaryButtonBackground,
                            padding: 10,
                          }}
                        >
                          <Text style={{ color: colors.primaryButtonText }}>
                            Reset
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  )
                }
                ref={flatListRef}
                onEndReached={expandRecommendations}
                colors={colors}
                isRefreshing={isRefreshing}
                isExpanding={isExpanding}
                onLastItemVisible={onLastItemVisible}
                qualityWarning={() => {
                  if (
                    (!myContext.lastTopicUpdateTime ||
                      timeStamp() >
                        myContext.lastTopicUpdateTime + 7 * 86400) &&
                    (!myContext.lastTopicUpdateRecommendationTime ||
                      (myContext.lastTopicUpdateRecommendationTime &&
                        timeStamp() >
                          myContext.lastTopicUpdateRecommendationTime +
                            7 * 86400))
                  ) {
                    console.log(
                      "PATH 3",
                      "last topic update",
                      myContext.lastTopicUpdateTime,
                      "7 days since last update:",
                      timeStamp() > myContext.lastTopicUpdateTime + 7 * 86400
                        ? "yes"
                        : "no",
                      "last recomemndation to update",
                      myContext.lastTopicUpdateRecommendationTime,
                      "7 days since last recommendation:",
                      timeStamp() >
                        myContext.lastTopicUpdateRecommendationTime + 7 * 86400
                        ? "yes"
                        : "no"
                    );
                    setTopicPopUpMessage(
                      "Updating followed topics might improve your experience! "
                    );
                    setShowTopicPopUp(true);
                    onAnimatedPopUp();
                    updateTopicUpdateRecommendationCounters(myContext, null);
                  }
                }}
                showTLDRPopUp={showTLDRPopUp}
                setShowTLDRPopUp={setShowTLDRPopUp}
                onShowReportPopUp={(message) => {
                  setMessageReportPopUp(message);
                  setShowReportPopUp(true);
                  popUpRef.current?.onAnimatedPopUp();
                }}
              />
            </PullToRefreshView>
          </Animated.View>
        )}
        <Modal
          visible={webModalVisible}
          onRequestClose={() => {
            setWebModalVisible(false);
          }}
        >
          <SafeAreaView style={styles.container}>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  backgroundColor: colors.background,
                  height: 39,
                  paddingVertical: 10,
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Pressable
                  onPress={() => {
                    clog("will try to open", state.websites);
                    setWebModalVisible(false);
                  }}
                >
                  <Image
                    source={cross}
                    style={{
                      marginRight: 20,
                      height: 19,
                      width: 17,
                      tintColor: colors.primaryText,
                    }}
                  />
                </Pressable>
              </View>
              <WebView
                style={{ flex: 1 }}
                source={{ uri: webModalUrl }}
                ref={(r) => (webref = r)}
                originWhitelist={["*"]}
                javaScriptEnabled={true}
                domStorageEnabled={true}
              />
            </View>
          </SafeAreaView>
        </Modal>
        <Modal
          visible={topicModalVisible}
          onRequestClose={() => {
            myContext.updatingTopics = false;
            setTopicModalVisible(false);
          }}
        >
          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: colors.background,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 25,
                marginBottom: 24,
                marginHorizontal: 16,
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "700",
                    color: colors.primaryText,
                  }}
                >
                  Filter By Topics
                </Text>
              </View>
              <View
                style={{
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Pressable
                  onPress={() => {
                    // check if there are any unsaved changes
                    clog("INCOMING", myContext.selectedTopics);
                    clog("OUTGOING", myContext.selectedTopicsTemp);
                    let hasChanges = false;
                    Object.keys(myContext.selectedTopics).forEach((k) => {
                      if (
                        myContext.selectedTopics[k] &&
                        !myContext.selectedTopicsTemp[k]
                      ) {
                        hasChanges = true;
                      }
                    });
                    if (!hasChanges) {
                      Object.keys(myContext.selectedTopicsTemp).forEach((k) => {
                        if (
                          myContext.selectedTopicsTemp[k] &&
                          !myContext.selectedTopics[k]
                        ) {
                          hasChanges = true;
                        }
                      });
                    }
                    clog("HAS CHANGES", hasChanges);
                    if (hasChanges) {
                      if (Platform.OS == "ios") {
                        ActionSheetIOS.showActionSheetWithOptions(
                          {
                            title: "Discard Filtering",
                            message:
                              "You will lose your filtering selections if you close this page",
                            options: ["Keep changes", "Discard"],
                            destructiveButtonIndex: 1,
                            cancelButtonIndex: 0,
                            userInterfaceStyle: "dark",
                          },
                          (buttonIndex) => {
                            if (buttonIndex === 0) {
                              // cancel action
                            } else if (buttonIndex === 1) {
                              myContext.updatingTopics = false;
                              setTopicModalVisible(false);
                            }
                          }
                        );
                      } else if (Platform.OS == "android") {
                        clog("will create alert");
                        Alert.alert(
                          "Discard Filtering",
                          "You will lose your filtering selections if you close this page",
                          [
                            {
                              text: "Keep change",
                              onPress: () => clog("Cancel Pressed"),
                              style: "cancel",
                            },
                            {
                              text: "Discard",
                              onPress: () => {
                                myContext.updatingTopics = false;
                                setTopicModalVisible(false);
                              },
                            },
                          ]
                        );
                      } else if (Platform.OS == "web") {
                        clog("will show collection alert");
                        //setShowCollectionAlert(true);
                        setShowTopicAlert(true);
                      }
                    } else {
                      myContext.updatingTopics = false;
                      setTopicModalVisible(false);
                    }
                  }}
                >
                  <View
                    style={{
                      height: 25,
                      width: 25,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={cross}
                      style={{
                        height: 10,
                        width: 10,
                        tintColor: colors.primaryText,
                      }}
                    />
                  </View>
                </Pressable>
              </View>
            </View>
            <SectionList
              sections={topicSections}
              renderSectionHeader={({ section }) => {
                if (section.key == "mytopics") {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "700",
                            color: colors.primaryText,
                          }}
                        >
                          {section.title}
                        </Text>
                        <TouchableOpacity
                          onPress={() => {
                            clog("Asked to go to topic chooser");
                            logEvent(
                              task == "village"
                                ? "MyVillage_Filter_Top_Set"
                                : "Filter_Top_Set",
                              {
                                userId: myContext?.Id,
                                username: myContext?.handle,
                                category:
                                  task == "village" ? "myvillage" : task,
                                platform: Platform.OS,
                                appVersion: myContext.appVersion,
                              }
                            );
                            setTopicModalVisible(false);
                            navigation.push("TopicChooser", {
                              subsequent: true,
                            });
                            myContext["updatingTopics"] = true;
                          }}
                        >
                          <View>
                            <Image
                              source={gear}
                              style={{
                                height: 16,
                                width: 16,
                                marginLeft: 8,
                              }}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>
                      <Pressable
                        onPress={() => {
                          topicsAvailable.forEach((t) => {
                            myContext.selectedTopicsTemp[t.Id] = true;
                          });
                          myContext.versionTemp++;
                          logEvent(
                            task == "village"
                              ? "MyVillage_Filter_Top_SelAll"
                              : "Filter_Top_SelAll",
                            {
                              userId: myContext?.Id,
                              username: myContext?.handle,
                              category: task == "village" ? "myvillage" : task,
                              platform: Platform.OS,
                              appVersion: myContext.appVersion,
                            }
                          );
                          setTopicSections((prev) => {
                            let mod = [...prev];
                            mod[0]["version"] = myContext.versionTemp;
                            clog("modified topic sections", mod);
                            return mod;
                          });
                        }}
                      >
                        <View>
                          <Text style={{ color: "rgb(203,177,255)" }}>
                            Select all interests
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  );
                } else {
                  return (
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "700",
                        color: colors.primaryText,
                      }}
                    >
                      {section.title}
                    </Text>
                  );
                }
              }}
              renderItem={({ item }) => {
                return (
                  <FlatList
                    data={item.list}
                    numColumns={2}
                    renderItem={({ item }) => {
                      clog("Asked to render", item);
                      let imageUrl = item?.avatar
                        ? myContext.presignedUrls[item?.avatar]?.uri
                        : null;
                      return (
                        <View
                          style={{
                            flex: 1,
                            marginTop: 12,
                          }}
                        >
                          <Pressable
                            onPress={() => {
                              logEvent(
                                task == "village"
                                  ? "MyVillage_Filter_Indirectly"
                                  : "Filter_Indirectly",
                                {
                                  userId: myContext?.Id,
                                  username: myContext?.handle,
                                  category:
                                    task == "village" ? "myvillage" : task,
                                  platform: Platform.OS,
                                  topicFilter: item.name,
                                  selected: !myContext.selectedTopicsTemp[
                                    item.Id
                                  ]
                                    ? "true"
                                    : "false",
                                  appVersion: myContext.appVersion,
                                }
                              );
                              clog(
                                "asked to toggle",
                                item.name,
                                "from",
                                myContext.selectedTopicsTemp[item.Id],
                                myContext.selectedTopics[item.Id],
                                "at version",
                                myContext.versionTemp
                              );
                              myContext.selectedTopicsTemp[item.Id] =
                                !myContext.selectedTopicsTemp[item.Id];
                              myContext.versionTemp++;
                              clog(
                                "after toggle",
                                item.name,
                                "to",
                                myContext.selectedTopicsTemp[item.Id],
                                myContext.selectedTopics[item.Id],
                                "at version",
                                myContext.versionTemp
                              );
                              setTopicSections((prev) => {
                                let mod = [...prev];
                                mod[0]["version"] = myContext.versionTemp;
                                clog("modified topic sections", mod);
                                return mod;
                              });
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  backgroundColor: myContext.selectedTopicsTemp[
                                    item.Id
                                  ]
                                    ? colors.primaryButtonBackground
                                    : colors.background,
                                  paddingLeft: myContext.selectedTopicsTemp[
                                    item.Id
                                  ]
                                    ? 8
                                    : 0,
                                  borderRadius: myContext.selectedTopicsTemp[
                                    item.Id
                                  ]
                                    ? 100
                                    : 0,
                                }}
                              >
                                {imageUrl && (
                                  <Image
                                    source={{
                                      uri: imageUrl,
                                      cache: "force-cache",
                                    }}
                                    style={
                                      myContext.selectedTopicsTemp[item.Id]
                                        ? {
                                            height: 14,
                                            width: 14,
                                            backgroundColor: colors.background,
                                          }
                                        : {
                                            height: 14,
                                            width: 14,
                                          }
                                    }
                                  />
                                )}
                                <View
                                  style={
                                    myContext.selectedTopicsTemp[item.Id]
                                      ? {
                                          paddingLeft: 4,
                                          paddingVertical: 4,
                                          paddingRight: 0,
                                          backgroundColor:
                                            colors.primaryButtonBackground,
                                          alignItems: "center",
                                        }
                                      : {
                                          paddingLeft: 4,
                                          paddingVertical: 4,
                                          paddingRight: 0,
                                          alignItems: "center",
                                          backgroundColor: colors.background,
                                        }
                                  }
                                >
                                  <Text
                                    style={
                                      myContext.selectedTopicsTemp[item.Id]
                                        ? {
                                            fontWeight: "400",
                                            color: colors.primaryButtonText,
                                            fontSize:
                                              Platform.OS == "android"
                                                ? 12
                                                : 14,
                                          }
                                        : {
                                            fontSize:
                                              Platform.OS == "android"
                                                ? 12
                                                : 14,
                                            fontWeight: "400",
                                            color: colors.primaryText,
                                          }
                                    }
                                  >
                                    {item.name}
                                  </Text>
                                </View>
                                {myContext.selectedTopicsTemp[item.Id] && (
                                  <View
                                    style={{
                                      height: 25,
                                      width: 25,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        colors.primaryButtonBackground,
                                      borderTopRightRadius: 100,
                                      borderBottomRightRadius: 100,
                                    }}
                                  >
                                    <Image
                                      source={cross}
                                      style={{
                                        height: 8,
                                        width: 8,
                                        tintColor: colors.primaryButtonText,
                                      }}
                                    />
                                  </View>
                                )}
                              </View>
                            </View>
                          </Pressable>
                        </View>
                      );
                    }}
                    keyExtractor={(item) => {
                      return item.Id;
                    }}
                    style={{
                      flex: 1,
                      marginBottom: 25,
                    }}
                    extraData={myContext.versionTemp}
                  />
                );
              }}
              style={{
                paddingLeft: 16,
                paddingRight: 16,
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 16,
              }}
            >
              <Pressable
                onPress={() => {
                  myContext.selectedTopicsTemp = {};
                  myContext.versionTemp++;
                  setTopicSections((prev) => {
                    let mod = [...prev];
                    mod[0]["version"] = myContext.versionTemp;
                    clog("modified topic sections", mod);
                    return mod;
                  });
                }}
                style={{ flex: 1 }}
              >
                <View
                  style={{
                    padding: 10,
                    alignItems: "center",
                    marginRight: 5,
                    borderRadius: 100,
                    borderWidth: 1,
                    borderColor: colors.secondaryButtonBorder,
                  }}
                >
                  <Text style={{ color: colors.primaryText, flexWrap: "wrap" }}>
                    Clear
                  </Text>
                </View>
              </Pressable>
              <Pressable
                onPress={() => {
                  myContext.version++;
                  myContext.selectedTopics = {
                    ...myContext.selectedTopicsTemp,
                  };
                  myContext.updatingTopics = false;
                  logEvent(
                    task == "village"
                      ? "MyVillage_Filter_Top_Fil"
                      : "Filter_Top_Fil",
                    {
                      userId: myContext?.Id,
                      username: myContext?.handle,
                      category: task == "village" ? "myvillage" : task,
                      platform: Platform.OS,
                      appVersion: myContext.appVersion,
                    }
                  );
                  setTopicModalVisible(false);
                  dataRefresh();
                }}
                style={{ flex: 1 }}
              >
                <View
                  style={{
                    backgroundColor: colors.primaryButtonBackground,
                    padding: 10,
                    alignItems: "center",
                    marginLeft: 5,
                    borderRadius: 100,
                    borderWidth: 1,
                    borderColor: colors.primaryButtonBackground,
                  }}
                >
                  <Text style={{ color: colors.primaryButtonText }}>
                    Filter
                  </Text>
                </View>
              </Pressable>
            </View>
            {Platform.OS == "web" && (
              <MultiButtonAlert
                show={showTopicAlert}
                showProgress={false}
                title="Discard Filtering"
                message={
                  "You will lose your filtering selections if you close this page"
                }
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Keep changes"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  clog("cancelled");
                  setShowTopicAlert(false);
                }}
                showConfirmButton={true}
                confirmText="Discard"
                confirmButtonColor={colors.confirmButton}
                onConfirmPressed={() => {
                  clog("confirmed");
                  setShowTopicAlert(false);
                  myContext.updatingTopics = false;
                  setTopicModalVisible(false);
                }}
              />
            )}
          </SafeAreaView>
        </Modal>
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title=""
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          cancelText="Ok"
          cancelButtonColor={colors.cancelButton}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
        />
        <Modal visible={showOnboarding && isRefreshing} animationType={"fade"}>
          <SafeAreaView
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <OnboardingAnimation />
          </SafeAreaView>
        </Modal>

        <TopicSheet
          visible={topicSheetVisible}
          setVisible={(value) => setTopicSheetVisible(value)}
          colors={colors}
          myContext={myContext}
          topicOptions={topics}
          dataRefresh={dataRefresh}
        />

        {showTopicPopUp && (
          <TopicPopUp
            colors={colors}
            message={topicPopUpMessage}
            popUpTransformRef={popUpTransformRef}
            openTopicSheet={() => {
              setTopicSheetVisible(true);
              setShowTopicPopUp(false);
              popUpTransformRef.setValue(0);
            }}
            closePopUp={() => {
              setShowTopicPopUp(false);
              popUpTransformRef.setValue(0);
            }}
          />
        )}

        {showReportPopUp && (
          <PopUp
            ref={popUpRef}
            title={messageReportPopUp}
            onClose={() => setShowReportPopUp(false)}
            colors={colors}
          />
        )}
      </View>

      {topicSheetVisible && (
        <View
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        />
      )}

      {/* Header Animated Bar */}
      <Animated.View
        style={{
          top: 0,
          position: "absolute",
          transform: [{ translateY: blurContainer }],
          backgroundColor: colors.headerBlur,
          width: "100%",
          maxWidth: 756,
        }}
      >
        <BlurView
          intensity={15}
          style={{ height: HEADER_HEIGHT + FILTER_BAR_HEIGHT + insets.top }}
        />
      </Animated.View>

      <Animated.View
        style={{
          top: 0,
          position: "absolute",
          transform: [{ translateY: headerTranslate }],
          opacity: headerOpacity,
          width: "100%",
        }}
      >
        <View style={{ height: insets.top }} />
        {renderHeader()}
        {false && (
          <Filter
            myContext={myContext}
            topicsAvailable={topicsAvailable}
            topicOptions={topicOptions}
            setTopicModalVisible={setTopicModalVisible}
            dataRefresh={dataRefresh}
            colors={colors}
            origin={task == "village" ? "myvillage" : "discover"}
          />
        )}
      </Animated.View>
      <Modal visible={modalVisible} onRequestClose={closeModal}>
        <SafeAreaView
          style={[styles.container, { backgroundColor: colors.background }]}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              marginBottom: 50,
            }}
          >
            <View
              style={{
                backgroundColor: colors.background,
                height: 39,
                paddingVertical: 10,
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pressable onPress={closeModal}>
                <Image
                  source={cross}
                  style={{
                    marginRight: 20,
                    height: 19,
                    width: 17,
                    tintColor: colors.primaryText,
                  }}
                />
              </Pressable>
            </View>
            <View
              style={{
                alignSelf: "center",
                width: "200",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <View>
                <Image
                  source={{
                    uri: state?.panels?.[contentIndex]?.screenshotUrl,
                  }}
                  style={{ height: 200, width: 200 }}
                ></Image>
              </View>
              <View style={{ flex: 1, width: 200 }}>
                <Text
                  numberOfLines={3}
                  ellipsizeMode={"tail"}
                  justifyContent={"center"}
                  style={{ flexWrap: "wrap" }}
                >
                  {state.panels?.[contentIndex]?.title}
                </Text>
              </View>
              {false && (
                <View style={{ marginTop: "10" }}>
                  <Text>
                    {contentIndex + 1}
                    {" / "}
                    {state?.panels?.length}
                  </Text>
                </View>
              )}
              <View
                style={{
                  alignSelf: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: 100,
                  width: 200,
                }}
              >
                <Pressable
                  onPress={() => {
                    console.log("pressed back");
                    onBackPressed();
                  }}
                >
                  <View
                    style={{
                      width: 48,
                      height: 48,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={backButton}
                      style={{
                        width: "50%",
                        height: "50%",
                        tintColor: colors.primaryText,
                        alignSelf: "center",
                      }}
                    />
                  </View>
                </Pressable>
                <Pressable
                  onPress={() => {
                    console.log("pressed play/pause");
                    setPlaying((prev) => {
                      return !prev;
                    });
                  }}
                >
                  <View
                    style={{
                      width: 96,
                      height: 96,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={playing ? pauseButton : playButton}
                      style={{
                        width: "50%",
                        height: "50%",
                        tintColor: colors.primaryText,
                      }}
                    />
                  </View>
                </Pressable>
                <Pressable
                  onPress={() => {
                    console.log("pressed forward");
                    onForwardPressed();
                  }}
                >
                  <View
                    style={{
                      width: 48,
                      height: 48,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={forwardButton}
                      style={{
                        width: "50%",
                        height: "50%",
                        tintColor: colors.primaryText,
                      }}
                    />
                  </View>
                </Pressable>
              </View>
              <View
                style={{
                  backgroundColor: villageColors.Stone,
                  width: 200,
                  height: 10,
                  overflow: "hidden",
                  borderRadius: 10,
                }}
              >
                <View
                  style={[
                    { backgroundColor: villageColors.Canary },
                    { width: 2 * progress, height: 10 },
                  ]}
                ></View>
              </View>
              <View
                style={{
                  width: 200,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Text>
                  {totalDuration
                    ? Math.floor(playedDuration / 60) +
                      ":" +
                      (playedDuration % 60 < 10 ? "0" : "") +
                      (playedDuration % 60)
                    : ""}
                </Text>
                <Text>
                  {totalDuration
                    ? Math.floor(totalDuration / 60) +
                      ":" +
                      (totalDuration % 60 < 10 ? "0" : "") +
                      (totalDuration % 60)
                    : ""}
                </Text>
              </View>
            </View>
          </View>
        </SafeAreaView>
      </Modal>
    </View>
  );
};

export default CommonFeedScreen;

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  notification: {
    alignSelf: "center",
    position: "absolute",
    zIndex: 1000, // works on ios
    elevation: 3, // works on android
    shadowColor: "rgb(23,23,23)",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    borderRadius: 30,
  },
  notificationView: {
    paddingHorizontal: 15,
    zIndex: 3, // works on ios
    elevation: 3, // works on android
    height: 45,
    borderRadius: 30,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  notificationText: {
    fontSize: 14,
    fontWeight: "700",
  },
  callToActionEmphasized: {
    fontSize: 14,
    fontWeight: "600",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingBottom: 10,
    paddingHorizontal: 12,
  },
  tabButton: {
    flexDirection: "column",
    alignItems: "center",
  },
});
