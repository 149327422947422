export const initialUser = {
  Id: "",
  handle: "",
  name: "",
  firstName: "",
  lastName: "",
  headline: "",
  bio: "",
  websites: "",
  acl: "",
  avatar: "",
  avatarUrl: "",
  location: "",
  numView: 0,
  numFollow: 0,
  numShare: 0,
  numViewRecursive: 0,
  numLikeRecursive: 0,
  numPinRecursive: 0,
  numFollowRecursive: 0,
  numCreateRecursive: 0,
  numShareRecursive: 0,
  numJoinRecursive: 0,
  numUserView: 0,
  numUserFollow: 0,
  numUserShare: 0,
  numListView: 0,
  numListLike: 0,
  numListFollow: 0,
  numListShare: 0,
  numListCreate: 0,
  numPinView: 0,
  numPinLike: 0,
  numPinCreate: 0,
  numUrlView: 0,
  numUrlContribute: 0,
  numUrlAuthor: 0,
  numCommentView: 0,
  numCommentLike: 0,
  numCommentCreate: 0,
  numTopicView: 0,
  numTopicFollow: 0,
  numSourceView: 0,
  numSourceLike: 0,
  numSourceFollow: 0,
  numExperimentJoin: 0,
  numExperimentCreate: 0,
  lastRecommendationCreationTime: 0,
  lastRecommendationExpansionTime: 0,
  lastRecommendationRefreshTime: 0,
  currentRecommendationBatchSequence: 0,
  currentRecommendationItemSequence: 0,
  currentRecommendationExpansionCount: 0,
  currentRecommendationRefreshCount: 0,
  lastRecommendationNotificationTime: 0,
  currentRecommendationCount: 0,
  topicIds: "",
  declaredTopicIds: "",
  curatedTopicIds: "",
  consumedTopicIds: "",
  engagedTopicIds: "",
  viewedTopicIds: "",
  visitedTopicIds: "",
  nextListSeq: 1,
  actionsByUser: {},
  actions: {},
  presignedUrls: {},
  users: {},
  selectedTopics: {},
  selectedMyTopics: false,
  topics: null,
  version: 1,
  appVersion: "1.1.280",
  minimumAppVersion: "1.1.246",
  engagements: {
    visits: {},
    longVisits: {},
    views: {},
    longViews: {},
  },
  config: {
    longVisitThreshold: 4736,
    longViewThreshold: 97828,
    maxRecommendationsPerInterval: 33,
    recommendationIntervalDuration: 43200,
    scoringBaselineProjection: 0.55,
    scoringRecencyInterval: 86400,
    scoringRecencyCount: 5,
    scoringTimeDecay: 0.02,
    scoringRequiredTenure: 86400,
    scoringNoProjectionForLowTenure: false,
    scoringTenureCount: 5,
    scoringAssessmentDecay: 0.001,
    scoringAssessmentDecayForNews: 0.05,
    scoringParentTopicMultiplier: 0.1,
    qualityAssessmentWindow: 86400,
    qualityAseessmentThreshold: 0.2,
    discoveryMinimumScoreThreshold: 50,
    dailyBestMinimumScoreThreshold: 40,
    dailyBestWindow: 86400,
    dailyBestCount: 10,
  },
  inflightLikes: {},
  inflightSaves: {},
  inflightFollows: {},
  inflightBlocks: {},
};

export const initialUserForSaving = {
  numView: 0,
  numFollow: 0,
  numShare: 0,
  numViewRecursive: 0,
  numLikeRecursive: 0,
  numPinRecursive: 0,
  numFollowRecursive: 0,
  numCreateRecursive: 0,
  numShareRecursive: 0,
  numJoinRecursive: 0,
  numUserView: 0,
  numUserFollow: 0,
  numUserShare: 0,
  numListView: 0,
  numListLike: 0,
  numListFollow: 0,
  numListShare: 0,
  numListCreate: 0,
  numPinView: 0,
  numPinLike: 0,
  numPinCreate: 0,
  numUrlView: 0,
  numUrlContribute: 0,
  numUrlAuthor: 0,
  numCommentView: 0,
  numCommentLike: 0,
  numCommentCreate: 0,
  numTopicView: 0,
  numTopicFollow: 0,
  numSourceView: 0,
  numSourceLike: 0,
  numSourceFollow: 0,
  numExperimentJoin: 0,
  numExperimentCreate: 0,
  lastRecommendationCreationTime: 0,
  lastRecommendationExpansionTime: 0,
  lastRecommendationRefreshTime: 0,
  currentRecommendationBatchSequence: 0,
  currentRecommendationItemSequence: 0,
  currentRecommendationExpansionCount: 0,
  currentRecommendationRefreshCount: 0,
  lastRecommendationNotificationTime: 0,
  topicIds: "",
  declaredTopicIds: "",
  curatedTopicIds: "",
  consumedTopicIds: "",
  engagedTopicIds: "",
  viewedTopicIds: "",
  visitedTopicIds: "",
  nextListSeq: 1,
};
