import React from "react";
import { Text, View, Pressable, Platform } from "react-native";
import { clog } from "../utils/Log";
import { logEvent } from "../utils/LogEvent";

const ConversionBanner = ({
  loggedIn,
  navbar,
  sharerId,
  objectType,
  objectId,
  colors,
  myContext,
}) => {
  clog("LOGGEDIN IN BANNER", loggedIn);
  if (loggedIn) {
    return <View></View>;
  }
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 12,
        paddingLeft: navbar ? 32 + 12 : 12,
        paddingVertical: 16,
        width: "100%",
        height: 66,
      }}
    >
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          opacity: 0.25,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            paddingLeft: "5%",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🎨</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🎓</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🏠</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🛼</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>👗</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>💼</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>💸</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🏈</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🎮</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            paddingRight: "5%",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ fontSize: 28, fontWeight: "700" }}>📱</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🍦</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🧬</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>⛩</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🎒</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🧪</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🥟</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🪆️</Text>
          <Text style={{ fontSize: 28, fontWeight: "700" }}>🕺🏻</Text>
        </View>
      </View>
      <View style={{ marginRight: 25, flex: 1 }}>
        <Text
          style={{ color: colors.primaryText, flexWrap: "wrap", fontSize: 14 }}
        >
          {"Discover more content like this on the Village app"}
        </Text>
      </View>
      <Pressable
        style={{}}
        onPress={() => {
          let success = window.open(
            "https://www.projectvillage.io/?utm_source=sharing&utm_medium=top_banner",
            "_blank"
          );
          logEvent("Share_HomePage", {
            category: "content",
            platform: Platform.OS,
            action: "click",
            target: "Homepage",
            status: success ? "success" : "failure",
            default_user_id: sharerId,
            objectType: objectType,
            objectId: objectId,
            appVersion: myContext.appVersion,
          });
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: 33,
            borderRadius: 100,
            borderWidth: 1,
            borderColor: colors.secondaryButtonBorder,
            paddingHorizontal: 12,
            paddingVertical: 8,
          }}
        >
          <Text
            style={{
              color: colors.primaryText,
              fontSize: 14,
              fontWeight: "700",
            }}
          >
            {"I’m curious 👀"}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

export default ConversionBanner;
