export const serializationDataOfTopics = ({ data, onboarding, callback }) => {
  const allTopics = Object.values(data).sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const topicOptions = Object.fromEntries(
    allTopics
      .filter((item) => item.depth === 0)
      .map((item) => {
        return [item.Id, { section: item, data: [] }];
      })
  );

  allTopics.forEach((topic) => {
    if (
      topic.depth != 0 &&
      topic.parentTopicId &&
      (!onboarding || (onboarding && topic.onboarding))
    ) {
      let details = {
        item: topic.name,
        name: topic.name, // TODO(alpha): why are we calling it item above?
        Id: topic.Id,
        description: topic.description,
        avatar: topic.avatar,
        topLevel: topic.topLevel,
        depth: topic.depth,
        onboarding: topic.onboarding,
        seq: topic.seq,
        parentTopicId: topic.parentTopicId,
        numFollow: topic.numFollow,
        numUrls: topic.numUrls,
        numView: topic.numView,
        curator: topic.curator,
      };

      if (topicOptions[topic.parentTopicId]) {
        topicOptions[topic.parentTopicId].data.push(details);
      }
    }
  });

  callback(
    Object.values(topicOptions).sort((a, b) =>
      a.section.seq < b.section.seq ? -1 : 1
    )
  );
};
