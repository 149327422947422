import React from "react";
import { View, Text, Pressable, Platform } from "react-native";

import Avatar from "./Avatar";
import { formatTime } from "../utils/TimeFormatting";
import { clog } from "../utils/Log";

import { logEvent } from "../utils/LogEvent";

const CommentCurator = ({
  curatorId,
  comment,
  myContext,
  navigation,
  refererActionId,
  hostUserId,
  origin,
  colors,
  screen,
}) => {
  const goToUserProfile = () => {
    clog("pressed button");

    navigation.push("UserDetails", {
      Id: curatorId,
      handle: myContext.users[curatorId].handle,
      fromList: true,
      refererActionId: refererActionId,
      hostUserId: hostUserId,
    });
  };

  let currentTime = Date.now();

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Pressable onPress={goToUserProfile}>
        <Avatar
          item={myContext.users[curatorId]}
          style={{
            height: 36,
            width: 36,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
            backgroundColor: colors.primaryText,
          }}
          textstyle={{
            fontSize: 20,
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
          }}
          myContext={myContext}
        ></Avatar>
      </Pressable>
      <View
        style={{
          alignSelf: "flex-start",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 10,
        }}
      >
        <Pressable
          onPress={goToUserProfile}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontSize: 12,
              fontWeight: "700",
              color: colors.primaryText,
            }}
          >
            {curatorId == myContext.Id
              ? "You"
              : myContext.users[curatorId]?.name
              ? myContext.users[curatorId]?.name
              : "@" + myContext.users[curatorId]?.handle}
          </Text>
          <Text
            style={{
              fontSize: 12,
              opacity: 0.88,
              color: colors.primaryText,
            }}
          >
            {" commented"}
          </Text>
        </Pressable>
        {(origin != "discover" || screen == "comment") && (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                fontSize: 12,
                opacity: 0.88,
                color: colors.primaryText,
                marginLeft: 4,
                marginRight: 4,
              }}
            >
              {"·"}
            </Text>
            <Text
              style={{
                fontSize: 12,
                opacity: 0.88,
                color: colors.primaryText,
              }}
            >
              {formatTime(comment?.createdAt, currentTime)}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default CommentCurator;
