import { API, graphqlOperation } from "aws-amplify";

import {
  userMinimalByHandle,
  userDetailsById,
  userMinimal,
  followedUsers,
  followingUsers,
  objectsActedOn,
  listTopics,
  addList,
  updateUserCounts,
  updateUserProfile,
} from "../src/graphql/custom";

import { createUser } from "../src/graphql/mutations";

import { batchPresign } from "../utils/Photo";
import { annotateAction } from "../utils/TopActions";
import { timeStamp } from "../utils/TimeStamp";
import { batchLookupUsers } from "../utils/Cache";
import { clog, elog } from "../utils/Log";
import { initialUserForSaving } from "../utils/InitialValues";
import { uploadPhoto } from "../utils/Photo";
import { generateUniqueId } from "../utils/Id";
import { addActionByUser, performAction } from "../utils/Action";
import { adjustPinForRichShareNote } from "../utils/Compatibility";
import { Platform } from "react-native";
import { uploadPhotoWeb } from "../utils/PhotoWeb";

let objectMap = {
  User: "user",
  List: "list",
  Topic: "topic",
  Pin: "pin",
  Url: "url",
  Comment: "comment",
  Source: "source",
};

function constructFullName(firstName, lastName) {
  let constructedName = "";
  if (firstName && firstName.trim()) {
    if (lastName && lastName.trim()) {
      constructedName = firstName.trim() + " " + lastName.trim();
    } else {
      constructedName = firstName.trim();
    }
  }
  return constructedName;
}

function handleNames(user, firstName, lastName) {
  let trimmedFirstName = "";
  if (firstName && firstName.trim()) {
    trimmedFirstName = firstName.trim();
  }
  user["firstName"] = trimmedFirstName;

  let trimmedLastName = "";
  if (lastName && lastName.trim()) {
    trimmedLastName = lastName.trim();
  }
  user["lastName"] = trimmedLastName;

  let trimmedFullName = constructFullName(trimmedFirstName, trimmedLastName);
  user["name"] = trimmedFullName;
}

export async function getMinimalUserByHandle(handle) {
  let user = null;
  try {
    let response = await API.graphql(
      graphqlOperation(userMinimalByHandle, { handle: handle })
    );
    console.log("RESPONSE FOR USER MINIMAL", response);
    if (response?.data?.userByHandle?.items?.length == 1) {
      user = response?.data?.userByHandle?.items?.[0];
    } else if (response?.data?.userByHandle?.items?.length > 1) {
      let users = [];
      response?.data?.userByHandle?.items?.forEach((u) => {
        let metadata = { hasDeclaredTopicIds: false, hasToken: false };
        if (u.declaredTopicIds) {
          metadata["hasDeclaredTopicIds"] = true;
        }
        if (u.token) {
          metadata["hasToken"] = true;
        }
        metadata["u"] = u;
        users.push(metadata);
      });
      users = users.sort((a, b) =>
        a.declaredTopicIds != b.declaredTopicIds
          ? a.declaredTopicIds
            ? -1
            : 1
          : a.hasToken != b.hasToken
          ? a.hasToken
            ? -1
            : 1
          : a.u.Id.localeCompare(b.u.Id) < 0
          ? -1
          : 1
      );
      console.log("sorted metadata", users);
      user = users?.[0]?.u;
    }
  } catch (err) {
    console.log("ERROR: cannot fetch minimal user data", err);
  }
  return user;
}

export async function getUserDetailsData(
  handle,
  Id,
  myContext,
  stateGetter,
  stateSetter,
  guest
) {
  clog("will try to GETDATA for", handle, "and", Id);
  if (!handle) {
    if (!Id) {
      return { success: false, message: "missing handle and Id" };
    } else {
      // look up handle from Id
      const userData = await API.graphql(
        graphqlOperation(userMinimal, { Id: Id })
      );
      if (userData?.data?.getUser?.handle) {
        handle = userData?.data?.getUser?.handle;
      } else {
        return { success: false, message: "could not get handle from Id" };
      }
    }
  }
  try {
    let start = performance.now();
    if (!Id) {
      let user = await getMinimalUserByHandle(handle);
      if (user?.Id) {
        Id = user?.Id;
      }
    }
    if (!Id) {
      return { success: false, message: "invalid handle" };
    }
    let promises = [];
    let unknownUserIds = {};
    promises.push(API.graphql(graphqlOperation(userDetailsById, { Id: Id })));
    // TODO(alpha): switch over to mutationActionsByUser
    promises.push(
      API.graphql(
        graphqlOperation(followedUsers, {
          actorIdOperationObjectType: [Id, "Follow", "User"].join(":"),
        })
      )
    );
    promises.push(
      API.graphql(
        graphqlOperation(followingUsers, {
          objectIdOperation: [Id, "Follow"].join(":"),
        })
      )
    );
    if (!guest) {
      promises.push(
        API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [Id, "Create", "Pin"].join(":"),
          })
        )
      );
      promises.push(
        API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [Id, "Create", "Comment"].join(":"),
          })
        )
      );
      promises.push(
        API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [Id, "Like", "Pin"].join(":"),
          })
        )
      );
      promises.push(
        API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [Id, "Like", "Comment"].join(":"),
          })
        )
      );
      if (!myContext.topics) {
        promises.push(
          API.graphql(graphqlOperation(listTopics, { numRequested: 1000 }))
        );
      }
    }
    let responses = await Promise.all(promises);
    let fetchEnd = performance.now();
    clog("TIME: fetch", (fetchEnd - start) / 1000);
    start = performance.now();
    let followerResponse = null;
    let followingResponse = null;
    let userData = null;
    let topicData = null;
    let activityData = [];
    let mayNeedLookUp = {};
    responses.forEach((response) => {
      clog("RESPONSE=", response);
      if (response?.data?.actionByActorIdOperationObjectType?.items) {
        if (
          response?.data?.actionByActorIdOperationObjectType?.items?.[0]
            ?.operation == "Follow"
        ) {
          followingResponse =
            response?.data?.actionByActorIdOperationObjectType?.items;
        } else {
          response?.data?.actionByActorIdOperationObjectType?.items.forEach(
            (action) => {
              activityData.push(action);
            }
          );
        }
      }
      if (response?.data?.actionByObjectIdOperation?.items) {
        followerResponse = response?.data?.actionByObjectIdOperation?.items;
      }
      if (response?.data?.getUser) {
        userData = response.data.getUser;
      }
      if (response?.data?.listTopics?.items) {
        topicData = response.data.listTopics.items;
      }
    });
    let followers = [];
    let mutualFollowers = [];
    let followings = [];

    if (followingResponse) {
      clog("FOLLOWING FETCHED", followingResponse);
      followingResponse.forEach((action) => {
        clog("FOLLOWED USER", action.user);
        let user = action.user;
        user.name = user.name?.trim();
        followings[user.Id] = user;
      });
    }

    if (followerResponse) {
      followerResponse.forEach((action) => {
        clog("FOLLOWING USER", action.actor);
        let user = action.actor;
        user.name = user.name?.trim();
        followers[user.Id] = user;
      });
    }
    // NOTE(alpha): Accesses key only
    Object.keys(followers).forEach((Id) => {
      if (myContext.actionsByUser?.["User"]?.["Follow"]?.[Id] != null) {
        mutualFollowers.push(followers[Id]);
      }
    });

    clog("all following", followings);
    clog("all followers", followers);
    clog("all mutual followers", mutualFollowers);
    if (topicData) {
      myContext.topics = {};
      topicData.forEach((topic) => {
        myContext.topics[topic.Id] = topic;
        if (topic.avatar) {
          mayNeedLookUp[topic.avatar] = true;
        }
      });
    }

    if (userData) {
      const data = userData;
      clog("new data", data);
      if (data.avatar) {
        mayNeedLookUp[data.avatar] = true;
      }

      let actionsByUser = {};
      let followedTopics = [];
      let users = {};
      if (data.actionsByUser && data.actionsByUser.items) {
        data.actionsByUser.items.forEach((element) => {
          if (actionsByUser[element.objectType] == null) {
            actionsByUser[element.objectType] = {};
          }
          if (actionsByUser[element.objectType][element.operation] == null) {
            actionsByUser[element.objectType][element.operation] = {};
          }
          if (
            actionsByUser[element.objectType][element.operation][
              element.objectId
            ] == null
          ) {
            actionsByUser[element.objectType][element.operation][
              element.objectId
            ] = {};
          }

          actionsByUser[element.objectType][element.operation][
            element.objectId
          ][element.Id] = {
            object: element[objectMap[element.objectType]],
            createdAt: element.createdAt,
          };

          if (element.objectType == "Topic" && element.operation == "Follow") {
            followedTopics.push(element.topic);
          }
        });
      }
      data.lists.items.forEach((list) => {
        let photoCount = 0;
        let pins = [];
        list?.pins?.items
          ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          ?.forEach((pin) => {
            clog("PIN", pin);
            let url = pin.url;
            if (url && photoCount < 4 && !url.photoUrl && url.photo) {
              mayNeedLookUp[url.photo] = true;
            }
            if (url.photo || url.photoUrl) {
              photoCount++;
            }
            pins.push(pin);
          });
        if (pins.length) {
          list.pins.items = pins;
        }
      });

      let activities = [];
      activityData
        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        .forEach((element) => {
          if (element[element.objectType.toLowerCase()] != null) {
            clog("Processing", element.Id, "with", element);
            let target = element[element.objectType.toLowerCase()];
            if (!myContext.users[target.curatorId]) {
              unknownUserIds[target.curatorId] = true;
            }
            element.actorId = Id;
            clog("TARGET", target);
            if (!target.url) {
              if (target?.pin?.url) {
                target.url = target.pin.url;
                target.url["pins"] = { items: [target.pin] };
              } else if (target?.comment?.url) {
                target.url = target.comment.url;
              } else {
                clog("DID NOT FIND URL FOR THE TARGET", target);
              }
            }
            if (target.url) {
              target.url["topActions"] = annotateAction(
                {
                  action: element,
                  target: target,
                  hostUserId: stateGetter().Id, // redundant really
                },
                myContext
              );
              target.url["justificationAction"] = target.url.topActions?.[0];
              activities.push(element);
              target.url.pins?.items?.forEach((p) => {
                if (p?.curatorId) {
                  if (!myContext.users[p.curatorId]) {
                    unknownUserIds[p.curatorId] = true;
                  }
                }
                adjustPinForRichShareNote(p, myContext, mayNeedLookUp);
              });
            } else {
              clog("SKIPPED TARGET", target);
            }
          }
        });

      clog("ACTIVITY DATA", activityData);
      clog("ACTIVITIES", activities);
      clog("UNKNOWN USERS", unknownUserIds);
      await batchLookupUsers(
        Object.keys(unknownUserIds),
        myContext.users,
        null
      );
      Object.keys(unknownUserIds).forEach((Id) => {
        let user = myContext.users[Id];
        if (user) {
          mayNeedLookUp[user.avatar] = true;
        }
      });
      await batchPresign(
        Object.keys(mayNeedLookUp),
        myContext.presignedUrls,
        null
      );

      for (let index in data) {
        if (index.startsWith("num") && data[index] == null) {
          data[index] = 0;
        }
      }

      let completion = performance.now();
      clog("TIME: rest", (completion - fetchEnd) / 1000);
      stateSetter((previousState) => {
        let stateCopy = { ...previousState };
        for (let index in data) {
          stateCopy[index] = data[index];
        }
        if (stateCopy["websites"] != null && stateCopy["websites"] != "") {
          clog(
            "Will check if we need to rewrite website",
            stateCopy["websites"]
          );
          const regex = /^https?:\/\//g;
          const found = stateCopy["websites"].match(regex);
          if (found == null) {
            stateCopy["websites"] = "https://" + stateCopy["websites"];
            clog("Rewrote website to", stateCopy["websites"]);
          }
        }
        stateCopy["fetchTimeStamp"] = timeStamp();
        stateCopy["version"] = myContext.version;
        stateCopy["actionsByUser"] = actionsByUser;
        stateCopy["actions"] = {};
        stateCopy["lists"] = data.lists.items.sort((a, b) =>
          a.seq < b.seq ? -1 : 1
        );
        stateCopy["followedTopics"] = followedTopics;
        stateCopy["activities"] = activities;
        stateCopy["followers"] = followers;
        stateCopy["followings"] = followings;
        stateCopy["mutualFollowers"] = mutualFollowers;
        clog("copied state", stateCopy);
        return stateCopy;
      });
      return { success: true, message: "all good" };
    }
    return { success: false, message: "cannot find data" };
  } catch (err) {
    console.log("error fetching data...", err);
    elog(myContext.Id, "user details", "fetching data", err.message);
    return { success: false, message: "failed to fetch data", error: err };
  }
}

export async function addUser({
  firstName,
  lastName,
  headline,
  bio,
  websites,
  location,
  imageURI,
  myContext,
  callback,
}) {
  let status = { success: true };
  let user = {
    ...initialUserForSaving,
    handle: myContext.handle,
  };
  handleNames(user, firstName, lastName);
  user["headline"] = headline ? headline : "";
  user["bio"] = bio ? bio : "";
  user["websites"] = websites ? websites : "";
  user["location"] = location ? location : "";

  try {
    ////clog("user data is ", user);
    // Save the image and get the imageKey to save in avatar
    if (imageURI) {
      let success = null;
      let key = null;

      let response;
      if (Platform.OS === "web") {
        response = await uploadPhotoWeb("profile/", imageURI);
      } else {
        response = await uploadPhoto("profile/", imageURI);
      }

      success = response[0];
      key = response[1];
      if (success && key) {
        user["avatar"] = key;
        let mayNeedLookUp = [key];
        await batchPresign(mayNeedLookUp, myContext.presignedUrls, null);
      }
    }

    let Id = generateUniqueId();
    user["Id"] = Id;
    // update shared context with user data
    for (let index in user) {
      myContext[index] = user[index];
    }

    let promises = [];
    let payloads = [];

    // Need to create a list called "Saved"
    let list = {};
    list["Id"] = generateUniqueId();
    list["name"] = "Saved";
    list["description"] = "List for saved pins";
    list["creatorId"] = user.Id;
    list["curatorId"] = user.Id;
    list["seq"] = user.nextListSeq;
    promises.push(API.graphql(graphqlOperation(addList, list)));
    payloads.push({ addList: list });

    user["savedListId"] = list.Id;

    clog("create user with id", Id);
    // Update user counters because we are making performAction actionOnly
    user.nextListSeq++;
    user.numListCreate++;
    promises.push(API.graphql(graphqlOperation(createUser, { input: user })));
    payloads.push({ createUser: { input: user } });

    await performAction({
      objectType: "List",
      operation: "Create",
      objectId: list.Id,
      actorId: myContext.Id,
      relationshipId: 0,
      objectCounter: 0,
      userCounter: 0,
      curatorId: myContext.Id,
      curatorCounter: 0,
      promises: promises,
      payloads: payloads,
      callback: (success, relationshipId, createdAt) => {
        list["pins"] = { items: [] };
        list["topics"] = { items: [] };
        list["topicIds"] = "";
        list["nextPinSeq"] = 1;
        list["numView"] = 0;
        list["numLike"] = 0;
        list["numFollow"] = 0;
        list["numShare"] = 0;
        list["numComment"] = 0;
        list["numPins"] = 0;
        addActionByUser(
          myContext.actionsByUser,
          "List",
          "Create",
          list.Id,
          relationshipId,
          list,
          null,
          null,
          createdAt
        );
        myContext.numListCreate++;
        myContext.nextListSeq++;
        myContext.version++;
        myContext["createdAt"] = createdAt;
        status["success"] = success;
        if (callback) {
          callback(status);
        }
      },
      actionOnly: true,
      refererActionId: null,
      hostUserId: null,
      seq: null,
    });
  } catch (err) {
    status.success = false;
    status["message"] = "Failed to create User!";
    status["error"] = err;
    if (callback) {
      callback(status);
    }
    console.log("error creating user...", err);
    elog(myContext?.Id, "profile update", "create user", err.message);
  }
  return status;
}

export async function modifyUser({
  firstName,
  lastName,
  headline,
  bio,
  websites,
  location,
  imageURI,
  myContext,
  callback,
}) {
  let status = { success: true };
  let user = {
    handle: myContext.handle,
  };
  handleNames(user, firstName, lastName);

  user["headline"] = headline ? headline : "";
  user["bio"] = bio ? bio : "";
  user["websites"] = websites ? websites : "";
  user["location"] = location ? location : "";

  try {
    // Save the image and get the imageKey to save in avatar
    if (imageURI) {
      let success = null;
      let key = null;

      let response;
      if (Platform.OS === "web") {
        response = await uploadPhotoWeb("profile/", imageURI);
      } else {
        response = await uploadPhoto("profile/", imageURI);
      }

      success = response[0];
      key = response[1];
      if (success && key) {
        user["avatar"] = key;
        let mayNeedLookUp = [key];
        await batchPresign(mayNeedLookUp, myContext.presignedUrls, null);
      }
    }
    user["Id"] = myContext.Id;
    clog("update user with id", myContext.Id);
    const response = await API.graphql(
      graphqlOperation(updateUserProfile, user)
    );
    clog("done updating user with Id", myContext.Id, response);
    // update shared context with user data
    for (let index in user) {
      myContext[index] = user[index];
    }
    myContext.version++;
  } catch (err) {
    console.log("error", err);
    status.success = false;
    status["message"] = "Failed to update User!";
    status["error"] = err;
  }
  if (callback) {
    callback(status);
  }
  return status;
}

export async function saveExpoToken({ Id, localToken, myContext, callback }) {
  let status = { success: true };
  myContext["localToken"] = localToken;
  try {
    let existingTokens = {};
    if (myContext?.token) {
      let tokens = myContext?.token.split(",");
      tokens.forEach((t) => {
        existingTokens[t] = 1;
      });
    }
    if (!existingTokens[localToken]) {
      existingTokens[localToken] = 1;
      let token = Object.keys(existingTokens).join(",");

      let userPayload = {
        Id: Id,
        token: token,
      };
      let response = await API.graphql(
        graphqlOperation(updateUserCounts, userPayload)
      );
      myContext["token"] = token;
      myContext.version++;
    }
  } catch (err) {
    console.log("error", err);
    status.success = false;
    status["message"] = "Failed to save expo token";
    status["error"] = err;
  }
  if (callback) {
    callback(status);
  }
  return status;
}

export async function updateTopicUpdateRecommendationCounters(
  myContext,
  callback
) {
  let status = { success: true };
  let user = {};

  user["Id"] = myContext.Id;
  user["lastTopicUpdateRecommendationTime"] = timeStamp();
  user["topicUpdateRecommendationCount"] =
    myContext?.topicUpdateRecommendationCount
      ? myContext.topicUpdateRecommendationCount + 1
      : 1;
  try {
    let response = await API.graphql(graphqlOperation(updateUserCounts, user));
    console.log("RESPONSE", response);
    for (let index in user) {
      myContext[index] = user[index];
    }
    myContext.version++;
  } catch (err) {
    console.log("error", err);
    status.success = false;
    status["message"] = "Failed to update User!";
    status["error"] = err;
  }
  if (callback) {
    callback(status);
  }
  return status;
}
