import { call } from "react-native-reanimated";
import { SignIn } from "../controllers/AuthController";
import { addUser } from "../controllers/UserController";
import { clog } from "../utils/Log";
export async function MyVillageTest({ callback }) {
  let myContext = {};
  let done = false;
  await SignIn({
    username: "ztest001",
    password: "ztest001",
    myContext: myContext,
    callback: (success, message, error) => {
      if (!success) {
        clog("Could not log in");
        callback({ success: success, message: message, error: error });
        done = true;
      }
    },
  });
  if (done) {
    return;
  }
  await addUser({
    name: "Z Test One",
    headline: "I am ztest one",
    bio: null,
    websites: "https://www.google.com/",
    location: location,
    imageURI: null,
    myContext: myContext,
    callback: ({ success, message, error }) => {
      if (!success) {
        callback({
          success: success,
          message: message,
          error: error,
        });
        done = true;
      }
    },
  });
  if (!done) {
    callback({ success: true });
  }
}
