import { API, graphqlOperation } from "aws-amplify";

import {
  feed,
  feedWithoutSearch,
  listUrls,
  updateUrlForReindexing,
  recommendationsForUser,
  recommendationCandidates,
  recommendationCandidates2,
  getSingleUrl,
  addRecommendation,
  currentRecommendationsForUser,
  updateUserCounts,
  removeRecommendation,
} from "../src/graphql/custom";
import { batchPresign } from "../utils/Photo";
import { timeStamp } from "../utils/TimeStamp";
import { findTopActions } from "../utils/TopActions";
import { batchLookupUsers } from "../utils/Cache";
import { clog, elog } from "../utils/Log";
import { Platform } from "react-native";
import {
  generateMapFromIdString,
  generateMapFromIdStrings,
} from "../utils/IdList";
import { generateUniqueId } from "../utils/Id";
import { adjustPinForRichShareNote } from "../utils/Compatibility";
import { fetchVisitsIfNecessary } from "../utils/DataFetcher";
import { computeElapsedTime } from "../utils/TimeStamp";
import { refreshConfig } from "../utils/DataFetcher";
import { normalize } from "react-native-elements";

function constructDiscoverFilter(myContext) {
  // generate the filters
  let direct = null;
  let parent = null;
  let forced = null;
  let disjuctions = [];
  let pdisjuctions = [];
  let fdisjuctions = [];
  let parentTopics = {};
  let addedTopics = {};
  let followedTopicIds = {};
  if (Object.keys(myContext.selectedTopics).length > 0) {
    if (Object.keys(myContext.selectedTopics)) {
      Object.keys(myContext.selectedTopics)?.forEach((t) => {
        disjuctions.push({ topicIds: { match: t } });
      });
    }
  } else if (myContext.declaredTopicIds) {
    followedTopicIds = generateMapFromIdStrings([myContext.declaredTopicIds]);
    Object.keys(followedTopicIds).forEach((t) => {
      if (myContext?.topics?.[t]?.parentTopicId) {
        disjuctions.push({ topicIds: { match: t } });
        addedTopics[t] = true;
        clog("DIRECT TOPIC", t);
      }
      if (
        myContext?.topics?.[t]?.parentTopicId &&
        myContext.topics[t].parentTopicId != "Topic066"
      ) {
        parentTopics[myContext?.topics?.[t]?.parentTopicId] =
          myContext.topics[myContext?.topics?.[t]?.parentTopicId].name;
      }
    });
  }

  Object.keys(parentTopics).forEach((pt) => {
    if (!addedTopics[pt]) {
      pdisjuctions.push({ topicIds: { match: pt } });
      addedTopics[pt] = true;
      clog("PARENT TOPIC", pt);
    }
  });

  ["Topic013", "Topic001", "Topic026", "Topic014", "Topic022:"].forEach(
    (ft) => {
      if (!addedTopics[ft]) {
        fdisjuctions.push({ topicIds: { match: ft } });
        addedTopics[ft] = true;
        clog("FORCED TOPIC", ft);
      }
    }
  );
  if (disjuctions.length) {
    let toplevel = {
      or: disjuctions,
      not: { curatorIds: { match: myContext.Id } },
    };
    direct = { filter: toplevel };
  } else {
    direct = { not: { curatorIds: { match: myContext.Id } } };
  }
  if (pdisjuctions.length) {
    let toplevel = {
      or: pdisjuctions,
      not: { curatorIds: { match: myContext.Id } },
    };
    parent = { filter: toplevel };
  }
  if (fdisjuctions.length) {
    let toplevel = {
      or: fdisjuctions,
      not: { curatorIds: { match: myContext.Id } },
    };
    forced = { filter: toplevel };
  }

  return { direct: direct, parent: parent, forced: forced };
}

function constructVillageFilter(myContext) {
  let filter = null;
  let disjuctions = [];
  let ignoreCount = 0;
  let keepCount = 0;
  let overflowCount = 0;
  if (myContext.actionsByUser?.["User"]?.["Follow"]) {
    Object.keys(myContext.actionsByUser["User"]["Follow"])
      ?.sort((a, b) =>
        (a.numPinCreate
          ? a.numPinCreate
          : 0 + a.numPinLike
          ? a.numPinLike
          : 0 + a.numCommentCreate
          ? a.numCommentCreate
          : 0 + a.numCommentLike
          ? a.numCommentLike
          : 0) >
        (b.numPinCreate
          ? b.numPinCreate
          : 0 + b.numPinLike
          ? b.numPinLike
          : 0 + b.numCommentCreate
          ? b.numCommentCreate
          : 0 + b.numCommentLike
          ? b.numCommentLike
          : 0)
          ? -1
          : 1
      )
      .forEach((u) => {
        // NOTE(alpha): accesses only key
        let user = myContext.users[u];
        clog("USER", user);
        if (
          user.numPinCreate ||
          user.numPinLike ||
          user.numCommentCreate ||
          user.numCommentLike
        ) {
          //clog("considering", u);
          if (keepCount < 340) {
            let parts = u.split("-");
            clog("FROM", u, "to", parts[parts.length - 1]);
            u = parts[0];
            disjuctions.push({ curatorIds: { match: u } });
            disjuctions.push({ commenterIds: { match: u } });
            disjuctions.push({ likerIds: { match: u } });
            //clog("got id", u);
            keepCount++;
          } else {
            clog("TOO MANY", user.handle);
            overflowCount++;
          }
        } else {
          clog("IGNORE", user.handle);
          ignoreCount++;
        }
      });
    console.log(
      "keep count",
      keepCount,
      "ignore count",
      ignoreCount,
      "overflow count",
      overflowCount
    );
  }
  // Add the user to own list too
  let parts = myContext.Id.split("-");
  let selfId = parts[parts.length - 1];
  disjuctions.push({ curatorIds: { match: selfId } });
  disjuctions.push({ commenterIds: { match: selfId } });
  disjuctions.push({ likerIds: { match: selfId } });

  if (disjuctions.length) {
    if (Object.keys(myContext.selectedTopics).length > 0) {
      let topicDisjunctions = [];
      if (Object.keys(myContext.selectedTopics)) {
        Object.keys(myContext.selectedTopics)?.forEach((t) => {
          topicDisjunctions.push({ topicIds: { match: t } });
        });
      }
      filter = {
        filter: { and: [{ or: disjuctions }, { or: topicDisjunctions }] },
      };
    } else {
      filter = { filter: { or: disjuctions } };
    }
  }
  console.log("filters", filter);
  return filter;
}

function constructIndexValidityFilter() {
  let filter = null;
  let disjuctions = [];
  disjuctions.push({
    curatorIds: { match: "a81a1211-5066-4995-8785-e7ac30f8d15b" }, // nikhil
  });
  if (disjuctions.length) {
    filter = { filter: { or: disjuctions } };
  }
  clog("filters", filter);
  return filter;
}

function hasAPath(panel, myContext) {
  let users = {};
  // NOTE(alpha): accesses key only
  if (myContext.actionsByUser?.["User"]?.["Follow"]) {
    Object.keys(myContext.actionsByUser["User"]["Follow"])?.forEach((u) => {
      users[u] = true;
    });
  }
  // Add the user to own list too
  users[myContext.Id] = true;
  clog("CHECK", users, "AGAINST", panel);
  let valid = false;
  // consider all curations and pin liking
  for (let i = 0; i < panel.pins.length; i++) {
    let pin = panel.pins[i];
    clog("CURATOR ID", pin.curatorId);
    if (users[pin.curatorId]) {
      clog("VALID");
      //valid = true;
      return true;
    }
    for (let j = 0; j < pin.actions.items.length; j++) {
      let action = pin.actions.items[j];
      if (action.operation == "Create" || action.operation == "Like") {
        if (users[action.actorId]) {
          clog("VALID");
          return true;
        }
      }
    }
    for (let j = 0; j < pin?.comments?.items?.length; j++) {
      let c2 = pin.comments.items[j];
      if (users[c2.curatorId]) {
        clog("VALID");
        //valid = true;
        return true;
      }
      for (let k = 0; k < c2?.actions?.items?.length; k++) {
        let action = c2.actions.items[k];
        if (action.operation == "Create" || action.operation == "Like") {
          if (users[action.actorId]) {
            clog("VALID");
            return true;
          }
        }
      }
    }
  }
  for (let i = 0; i < panel.comments.length; i++) {
    let comment = panel.comments[i];
    clog("CURATOR ID", comment.curatorId);
    if (users[comment.curatorId]) {
      clog("VALID");
      //valid = true;
      return true;
    }
    for (let j = 0; j < comment.actions.items.length; j++) {
      let action = comment.actions.items[j];
      if (action.operation == "Create" || action.operation == "Like") {
        if (users[action.actorId]) {
          clog("VALID");
          return true;
        }
      }
    }
    for (let j = 0; j < comment?.comments?.items?.length; j++) {
      let c2 = comment.comments.items[j];
      if (users[c2.curatorId]) {
        clog("VALID");
        //valid = true;
        return true;
      }
      for (let k = 0; k < c2?.actions?.items?.length; k++) {
        let action = c2.actions.items[k];
        if (action.operation == "Create" || action.operation == "Like") {
          if (users[action.actorId]) {
            clog("VALID");
            return true;
          }
        }
      }
    }
  }
  return valid;
}

// TODO(alpha): Move this duplicate function to utility
async function resendUrlsToIndex(myContext) {
  try {
    const urlsData = await API.graphql(graphqlOperation(listUrls, {}));
    if (urlsData.data.listUrls.items.length > 0) {
      const data = urlsData.data.listUrls.items;
      clog("DATA", data);
      if (data) {
        let promises = [];
        data.forEach((url) => {
          clog("URL", url);
          promises.push(
            API.graphql(
              graphqlOperation(updateUrlForReindexing, {
                Id: url.Id,
                touch: (url.touch ? url.touch : 0) + 1,
              })
            )
          );
        });
        let responses = await Promise.all(promises);
        clog("RESPONSES", responses);
      }
    }
  } catch (err) {
    console.log("Could not get data", err);
    elog(myContext.Id, "settings", "data fetch resendUrlsToIndex", err.message);
  }
}

export async function resetRecommendationState(myContext) {
  let promises = [];
  let oldRecommendationNextToken = null;
  let oldRecommendationContinue = true;
  let oldRecommendations = [];
  promises.push(
    API.graphql(
      graphqlOperation(updateUserCounts, {
        Id: myContext.Id,
        lastRecommendationCreationTime:
          myContext.lastRecommendationCreationTime,
        lastRecommendationExpansionTime:
          myContext.lastRecommendationExpansionTime,
        lastRecommendationRefreshTime: myContext.lastRecommendationRefreshTime,
        currentRecommendationBatchSequence:
          myContext.currentRecommendationBatchSequence,
        currentRecommendationItemSequence:
          myContext.currentRecommendationItemSequence,
        currentRecommendationExpansionCount:
          myContext.currentRecommendationExpansionCount,
        currentRecommendationRefreshCount:
          myContext.currentRecommendationRefreshCount,
        currentRecommendationCount: myContext.currentRecommendationCount,
      })
    )
  );

  do {
    promises = [];
    if (oldRecommendationContinue) {
      // get all recommendations for the user
      promises.push(
        API.graphql(
          graphqlOperation(recommendationsForUser, {
            userId: myContext.Id,
            nextToken: oldRecommendationNextToken,
            limit: 1000,
          })
        )
      );
    }
    try {
      let responses = await Promise.all(promises);
      clog("RESPONSES", responses);
      responses.forEach((response) => {
        if (response?.data?.recommendationByUserId) {
          oldRecommendations = [
            ...oldRecommendations,
            ...response.data.recommendationByUserId.items,
          ];
          oldRecommendationNextToken =
            response.data.recommendationByUserId.nextToken;
          if (!oldRecommendationNextToken) {
            oldRecommendationContinue = false;
          }
        }
      });
    } catch (err) {
      console.log("CANNOT FETCH RECOMMENDATIONS", err);
    }
  } while (oldRecommendationContinue);
  clog("OLD RECOMMENDATIONS", oldRecommendations);
  promises = [];
  oldRecommendations.forEach((r) => {
    promises.push(
      API.graphql(
        graphqlOperation(removeRecommendation, {
          Id: r.Id,
        })
      )
    );
  });
  try {
    let responses = await Promise.all(promises);
    clog("Deletion responses", responses);
  } catch (err) {
    console.log("ERROR: could not delete old recommendations", err);
  }
}

export async function refreshSingleUrl(url, myContext, oldPanels, callback) {
  clog("WILL REFRESH SINGLE URL", url, "OLD PANELS", oldPanels);
  try {
    let response = await API.graphql(
      graphqlOperation(getSingleUrl, {
        Id: url.Id,
      })
    );
    clog("URL RESPONSE", response);
    let panels = [];
    let unknownUserIds = {};
    let mayNeedLookUp = {};

    let element = response?.data?.getUrl;
    handleOneUrl(element, panels, unknownUserIds, myContext, mayNeedLookUp);
    await handleLookups(panels, unknownUserIds, myContext, mayNeedLookUp);
    let modifiedPanel = panels?.[0];

    panels = [];
    oldPanels?.forEach((panel) => {
      if (panel?.Id == modifiedPanel?.Id) {
        clog("MODIFIED PANEL", modifiedPanel);
        modifiedPanel["score"] = panel.score;
        modifiedPanel["explanation"] = panel.explanation;
        panels.push(modifiedPanel);
      } else {
        panels.push(panel);
      }
    });

    if (myContext.task == "discover") {
      myContext["previousDiscoverDataset"] = {
        ...myContext["previousDiscoverDataset"],
        panels: panels,
      };
    }
    if (myContext.task == "village") {
      myContext["previousVillageDataset"] = {
        ...myContext["previousVillageDataset"],
        panels: panels,
      };
    }
    if (callback) {
      callback({
        success: true,
        message: "fetched data",
        panels: panels,
      });
    }
    return { success: true, message: "fetched data", panels: panels };
  } catch (err) {
    console.log("Cannot fetch url details", err);
    if (callback) {
      callback({ success: false, message: "error fetching data" });
    }
    return { success: false, message: "error fetching data" };
  }
}

function recomputeTopActionsIfNecessary(panel, myContext, task) {
  let acceptable = false;
  let hasTestUser = false;
  let hasSelf = false;
  let shouldAdd = false;
  // TODO(alpha): Add a check if the pins are empty in reality
  panel?.topActions?.forEach((info) => {
    if (
      info?.action?.actorId &&
      !myContext.users[info.action.actorId]?.label?.match("test")
    ) {
      acceptable = true;
    } else {
      hasTestUser = true;
    }
    if (info?.action?.actorId == myContext.Id) {
      hasSelf = true;
    }
  });

  if (task == "discover" && hasSelf) {
    acceptable = false;
  }
  clog(
    "OUTCOME",
    panel.uri,
    "acceptable",
    acceptable,
    "hasTestuser",
    hasTestUser
  );
  if (acceptable) {
    if (hasTestUser) {
      clog("RETRY because test user involved");
      panel["topActions"] = findTopActions(panel, myContext, null, task);
      clog("TOP ACTIONS", panel.uri, panel.topActions);
      panel["justificationAction"] = panel.topActions?.[0];
      panel["actionParams"] = null;
    }
    if (panel.topActions?.length > 0) {
      clog("ADD PANEL TO PANELS");
      shouldAdd = true;
    } else {
      clog("NOT ENOUGH TOP ACTIONS", panel);
    }
  }
  return shouldAdd;
}

export function dropTestData(myContext, rawPanels, task) {
  console.log("WILL DROP TEST DATA");
  let panels = [];
  rawPanels?.forEach((panel) => {
    let shouldAdd = recomputeTopActionsIfNecessary(panel, myContext, task);
    if (shouldAdd) {
      panels.push(panel);
    } else {
      clog("WILL DROP", panel);
      if (!myContext.doNoRecommend) {
        myContext["doNoRecommend"] = {};
      }
      myContext.doNoRecommend[panel.Id] = true;
    }
  });
  console.log("RAW", rawPanels.length, "SURVIVED", panels.length);
  clog("STARTED WITH", rawPanels, "LEFT WITH", panels);
  return panels;
}

export function cleanObject(item, myContext) {
  let actorId = item?.curatorId;
  if (!actorId) {
    actorId = item?.actorId;
  }
  if (
    myContext?.actionsByUser?.["User"]?.["Block"]?.[actorId] ||
    myContext?.actions?.["User"]?.["Block"]?.[actorId]
  ) {
    clog("Drop item because of curated by blocked user", item);
    return null;
  }
  // clean pins
  if (item?.pins?.items) {
    let cleanedPins = [];
    item?.pins?.items?.forEach((pin) => {
      let cleanPin = cleanObject(pin, myContext);
      if (cleanPin) {
        cleanedPins.push(cleanPin);
      } else {
        console.log("REMOVED PIN", pin);
      }
    });
    item.pins["items"] = cleanedPins;
  }
  if (item?.comments?.items) {
    let cleanedComments = [];
    item?.comments?.items?.forEach((comment) => {
      let cleanComment = cleanObject(comment, myContext);
      if (cleanComment) {
        cleanedComments.push(cleanComment);
      } else {
        console.log("REMOVED COMMENT", comment);
      }
    });
    item.comments["items"] = cleanedComments;
  }
  if (item?.actions?.items) {
    let cleanedActions = [];
    item?.actions?.items?.forEach((action) => {
      let cleanAction = cleanObject(action, myContext);
      if (cleanAction) {
        cleanedActions.push(cleanAction);
      } else {
        console.log("REMOVED ACTION", action);
      }
    });
    item.actions["items"] = cleanedActions;
  }
  return item;
}

export function handleOneUrl(
  element,
  panels,
  unknownUserIds,
  myContext,
  mayNeedLookUp
) {
  element = cleanObject(element, myContext);
  clog("CLEANED element", element);
  let pins = element?.pins?.items;
  if (Object.keys(myContext.selectedTopics).length > 0) {
    clog("HAS SELECTED TOPICS", myContext.selectedTopics);
    let topicIds = generateMapFromIdStrings([element.topicIds]);
    clog("TOPICS of url", topicIds);
    let shouldDisplay = false;
    Object.keys(topicIds).forEach((topicId) => {
      if (myContext.selectedTopics[topicId]) {
        shouldDisplay = true;
      }
    });
    if (!shouldDisplay) {
      return;
    }
  }
  clog(element.uri, "TOPIC IDS", element.topicIds);
  let comments = element?.comments?.items?.sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1
  );
  clog("considering", element);
  for (let index in element) {
    if (index.startsWith("num") && element[index] == null) {
      element[index] = 0;
    }
  }

  pins?.forEach((pin) => {
    if (!myContext.users[pin.curatorId]) {
      unknownUserIds[pin.curatorId] = true;
    }
    adjustPinForRichShareNote(pin, myContext, mayNeedLookUp);
    pin?.actions?.items?.forEach((action) => {
      if (!myContext.users[action.actorId]) {
        unknownUserIds[action.actorId] = true;
      }
      if (pin.content) {
        action["hasNote"] = true;
        if (pin.markup) {
          action["hasRichShareNote"] = true;
        } else {
          action["hasRichShareNote"] = false;
        }
      } else {
        action["hasNote"] = false;
        action["hasRichShareNote"] = false;
      }
    });
    pin?.comments?.items?.forEach((c2) => {
      if (!myContext.users[c2.curatorId]) {
        unknownUserIds[c2.curatorId] = true;
      }
    });
  });

  comments?.forEach((comment) => {
    if (!myContext.users[comment.curatorId]) {
      unknownUserIds[comment.curatorId] = true;
    }
    comment?.actions?.items?.forEach((action) => {
      if (!myContext.users[action.actorId]) {
        unknownUserIds[action.actorId] = true;
      }
    });
    comment?.comments?.items?.forEach((c2) => {
      if (!myContext.users[c2.curatorId]) {
        unknownUserIds[c2.curatorId] = true;
      }
    });
  });

  let panel = {
    Id: element.Id,
    uri: element.uri,
    title: element.title,
    snippet: element.snippet,
    photo: element.photo,
    photoUrl: element.photoUrl,
    duration: element.duration,
    author: element.author,
    authorName: element.authorName,
    createdAt: element.createdAt,
    source: element.source,
    sourceId: element.sourceId,
    numComment: element.numComment,
    numContribute: element.numContribute,
    numLongView: element.numLongView,
    numTotalView: element.numTotalView,
    numLongVisit: element.numLongVisit,
    numTotalVisit: element.numTotalVisit,
    numLike: element.numLike,
    numPins: element.numPins,
    commenterIds: element.commenterIds,
    curatorIds: element.curatorIds,
    likerIds: element.likerIds,
    listIds: element.listIds,
    topicIds: element.topicIds,
    creationTS: element.creationTS,
    pins: pins,
    comments: comments,
    topics: element.topics?.items,
    tldr: element.tldr,
  };
  panel["topActions"] = findTopActions(panel, myContext, null, "discover"); // host is self really
  clog("TOP ACTIONS", panel.uri, panel.topActions);
  panel["justificationAction"] = panel.topActions?.[0];
  panel["actionParams"] = null;
  if (panel.topActions?.length > 0) {
    clog("ADD PANEL TO PANELS");
    panels.push(panel);
  } else {
    clog("NOT ENOUGH TOP ACTIONS", panel);
  }
}

export async function handleLookups(
  panels,
  unknownUserIds,
  myContext,
  mayNeedLookUp
) {
  clog("UNKNOWN USERS", unknownUserIds);
  await batchLookupUsers(Object.keys(unknownUserIds), myContext.users, null);
  Object.keys(unknownUserIds).forEach((Id) => {
    let user = myContext.users[Id];
    if (user) {
      clog("WILL LOOKUP", user.handle);
      mayNeedLookUp[user.avatar] = true;
    }
  });
  panels.forEach((element) => {
    clog("consider", element);
    if (element?.photo) {
      mayNeedLookUp[element.photo] = true;
    }
    if (element?.source?.avatar) {
      mayNeedLookUp[element.source.avatar] = true;
    }
    element?.pins?.forEach((pin) => {
      if (pin?.curatorId) {
        mayNeedLookUp[myContext.users[pin.curatorId].avatar] = true;
      }
      pin?.comments?.items?.forEach((c2) => {
        if (c2?.curatorId) {
          mayNeedLookUp[myContext.users[c2.curatorId].avatar] = true;
        }
      });
    });
    element?.comments?.forEach((comment) => {
      if (comment?.curatorId) {
        mayNeedLookUp[myContext.users[comment.curatorId].avatar] = true;
      }
      comment?.comments?.items?.forEach((c2) => {
        if (c2?.curatorId) {
          mayNeedLookUp[myContext.users[c2.curatorId].avatar] = true;
        }
      });
    });
  });
  await batchPresign(Object.keys(mayNeedLookUp), myContext.presignedUrls, null);
}

function filterUrls(urls, acceptableTopics) {
  let filteredUrls = [];
  urls?.forEach((url) => {
    let topics = generateMapFromIdStrings([url.topicIds]);
    let add = false;
    Object.keys(topics)?.forEach((t) => {
      if (acceptableTopics[t]) {
        add = true;
      }
    });
    if (add) {
      filteredUrls.push(url);
    }
  });
  return filteredUrls;
}

export function computeTopicScore(topicIds, interestVector, myContext) {
  let docVector = generateDocVector(topicIds, myContext, true);
  let overlap = 0;
  let components = {};
  let docTopics = {};
  Object.keys(docVector).forEach((tid) => {
    if (interestVector[tid]) {
      let contribution = docVector[tid] * interestVector[tid];
      if (!myContext.topics[tid].parentTopicId) {
        contribution *= myContext.config.scoringParentTopicMultiplier;
      }
      overlap += contribution;
      components[myContext.topics[tid].name] =
        Math.round(contribution * 10000) / 100;
    }
    docTopics[myContext.topics[tid].name] = docVector[tid];
  });
  if (!overlap) {
    Object.keys(docVector).forEach((topic) => {
      if (
        ["Topic013", "Topic001", "Topic026", "Topic014", "Topic022:"].includes(
          topic
        )
      ) {
        overlap += 0.0001;
      }
    });
    clog("BOOSTED EMPTY MATCH by", overlap, docVector);
  }
  return { score: overlap * 100, components: components, docTopics: docTopics };
}

function scoreAndExplain(c, myContext, oldScores, currentTime) {
  let participants = generateMapFromIdStrings([
    c.curatorIds,
    c.likerIds,
    c.commenterIds,
  ]);
  let cuparticipants = generateMapFromIdStrings([c.curatorIds]);
  let cparticipants = generateMapFromIdStrings([c.commenterIds]);
  Object.keys(cparticipants).forEach((u) => {
    if (cuparticipants[u]) {
      delete cparticipants[u];
    }
  });
  let lparticipants = generateMapFromIdStrings([c.likerIds]);
  Object.keys(lparticipants).forEach((u) => {
    if (cuparticipants[u]) {
      delete lparticipants[u];
    }
  });
  let vparticipants = generateMapFromIdStrings([c.viewerIds]);
  Object.keys(vparticipants).forEach((u) => {
    if (cuparticipants[u]) {
      delete vparticipants[u];
    }
  });

  clog("PARTICIPANTS", participants);

  let nparticipants = Object.keys(participants).length;
  let ncparticipants = Object.keys(cparticipants).length;
  let ncuparticipants = Object.keys(cuparticipants).length;
  let nlparticipants = Object.keys(lparticipants).length;
  let nvparticipants = Object.keys(vparticipants).length;
  if (!nparticipants) {
    nparticipants = 1;
  }
  if (!ncuparticipants) {
    ncuparticipants = 1;
  }
  let score =
    7.5 * (ncuparticipants - 1) +
    15 * nlparticipants +
    15 * ncparticipants +
    2.5 * nvparticipants +
    3.75 * (nparticipants - 1) +
    7.5 * (c.numPins > 1 ? Math.log10(c.numPins - 1) : 0) +
    7.5 * Math.log10(c.numLike ? c.numLike : 1) +
    6 * Math.log10(c.numComment ? c.numComment : 1) +
    2 * Math.log10(c.numView ? c.numView : 1);

  let projection = myContext.config.scoringBaselineProjection;
  let completed =
    c.numTotalVisit > myContext.config.scoringRecencyCount
      ? 1
      : c.numTotalVisit / myContext.config.scoringRecencyCount;
  let existing = 0;
  let expected = 0;
  let age = currentTime - c.creationTS;
  if (c.numTotalVisit >= myContext.config.scoringRecencyCount) {
    score = (c.numLongVisit / c.numTotalVisit) * 100;
  } else {
    existing = c.numTotalVisit
      ? (c.numLongVisit / c.numTotalVisit) * completed * 100
      : 0;
    if (age < myContext.config.scoringRecencyInterval) {
      projection = 1;
    } else {
      projection = Math.max(
        1 - Math.floor(age / 86400) * myContext.config.scoringTimeDecay,
        projection
      );
      clog("Age", age, "projection", projection);
      // Do not penalize items that are doing well
      /*projection = Math.max(
        projection,
        c.numTotalVisit ? c.numLongVisit / c.numTotalVisit : 0
      );*/
    }
    expected = projection * (1 - completed) * 100;
    score = existing + expected;
  }

  if (!c.numPins) {
    score = 0;
  }
  if (participants[myContext.Id]) {
    score = 0;
  }

  if (oldScores?.[c.Id]) {
    // completely ignore old recommendations
    score = 0;
  }
  if (score && myContext.tenure < myContext.config.scoringRequiredTenure) {
    if (c.numTotalVisit < myContext.config.scoringTenureCount) {
      score = 0;
    } else if (myContext.config.scoringNoProjectionForLowTenure) {
      score = (c.numLongVisit / c.numTotalVisit) * 100;
    }
  }

  score = Math.round(score * 100) / 100;
  clog("COMPUTE TOPIC SCORE FOR", c.title);
  if (!myContext.interestVector) {
    myContext["interestVector"] = generateVector(
      myContext.declaredTopicIds,
      myContext,
      true
    );
  }
  let topicScore = computeTopicScore(
    c.topicIds,
    myContext.interestVector,
    myContext
  );

  let decayRate = myContext.config.scoringAssessmentDecay;
  if (topicScore.docTopics["News"]) {
    decayRate = myContext.config.scoringAssessmentDecayForNews;
  }
  score *= 1 - decayRate * Math.floor(age / 86400);
  if (score < 0) {
    score = 0;
  }

  let compositeScore =
    score >= myContext.config.discoveryMinimumScoreThreshold
      ? (score * topicScore.score) / 100
      : 0;
  clog("SCORE", score, "TOPIC SCORE", topicScore, "COMPOSITE", compositeScore);
  clog(score, c.title);
  let explanation = {
    score: score,
    topicScore: topicScore,
    compositeScore: compositeScore,
    counters: {
      numLongVisit: c.numLongVisit,
      numTotalVisit: c.numTotalVisit,
      numLongView: c.numLongView,
      numTotalView: c.numTotalView,
      completed: completed,
      projection: projection,
      existing: existing,
      expected: expected,
      ncuparticipants: ncuparticipants,
      nlparticipants: nlparticipants,
      ncparticipants: ncparticipants,
      nvparticipants: nvparticipants,
      nparticipants: nparticipants,
      numPins: c.numPins ? c.numPins : 0,
      numLike: c.numLike ? c.numLike : 0,
      numComment: c.numComment ? c.numComment : 0,
      numView: c.numView ? c.numView : 0,
    },
    contributions: {
      ncuparticipants: 7.5 * (ncuparticipants - 1),
      nlparticipants: 15 * nlparticipants,
      ncparticipants: 15 * ncparticipants,
      nvparticipants: 2.5 * nvparticipants,
      nparticipants: 3.75 * (nparticipants - 1),
      numPins: 7.5 * (c.numPins > 1 ? Math.log10(c.numPins - 1) : 0),
      numLike: 7.5 * Math.log10(c.numLike ? c.numLike : 1),
      numComment: 6 * Math.log10(c.numComment ? c.numComment : 1),
      numView: 2 * Math.log10(c.numView ? c.numView : 1),
    },
  };
  return {
    score: score,
    topicScore: topicScore,
    compositeScore: compositeScore,
    explanation: explanation,
  };
}

async function getDiscoverFeedData(handle, myContext, task, callback) {
  let currentTime = timeStamp();
  clog("CONTEXT", myContext);
  let dataset = {};
  clog("Recommendations already generated", myContext.recommendationGenerated);
  clog("Expand recommendations", myContext.expandingRecommendations);
  clog("Refresh recommendations", myContext.refreshingRecommendations);
  myContext["tenure"] = myContext.createdAt
    ? computeElapsedTime(myContext.createdAt, Date.now())
    : 0;
  console.log("TENURE", myContext.tenure);
  if (
    myContext.recommendationGenerated &&
    !myContext.creatingRecommendations &&
    !myContext.expandingRecommendations &&
    !myContext.refreshingRecommendations &&
    myContext.previousDiscoverDataset &&
    myContext.version == myContext.previousDiscoverDataset.version
  ) {
    // reuse already generated recommendations available locally
    console.log("REUSE LOCAL RECOMMENDATIONS");
    dataset = myContext.previousDiscoverDataset;
  } else if (
    myContext.lastRecommendationCreationTime &&
    !myContext.creatingRecommendations &&
    !myContext.expandingRecommendations &&
    !myContext.refreshingRecommendations
  ) {
    // look up already generated recommendations from backend and reuse
    console.log("REUSE CLOUD RECOMMENDATIONS");
    let response = await API.graphql(
      graphqlOperation(currentRecommendationsForUser, {
        userIdBatchId: [
          myContext.Id,
          myContext.currentRecommendationBatchSequence,
        ].join(":"),
      })
    );
    clog("DATA FROM CLOUD:", response);
    let rawPanels = [];
    let unknownUserIds = {};
    let mayNeedLookUp = {};
    let oldScores = {};

    response?.data?.recommendationByUserIdBatchId?.items?.forEach((u) => {
      let element = u?.url;
      oldScores[u.urlId] = u.score / 1000;
      handleOneUrl(
        element,
        rawPanels,
        unknownUserIds,
        myContext,
        mayNeedLookUp
      );
    });
    await handleLookups(rawPanels, unknownUserIds, myContext, mayNeedLookUp);
    let panels = dropTestData(myContext, rawPanels, task);
    console.log("AFTER DROPPING", rawPanels.length, "to", panels.length);
    if (!rawPanels.length || !panels.length) {
      console.log("Used up all candidates because of test data - retry");
      console.log("FORCING REFRESH");
      myContext["refreshingRecommendations"] = true;
      return getDiscoverFeedData(handle, myContext, task, callback);
    }
    panels?.forEach((item) => {
      let { score, topicScore, compositeScore, explanation } = scoreAndExplain(
        item,
        myContext,
        null,
        currentTime
      );
      item["score"] = oldScores[item.Id];
      item["topicScore"] = topicScore;
      item["compositeScore"] = compositeScore;
      item["explanation"] = explanation;
    });
    dataset = {
      panels,
      fetchTimeStamp: timeStamp(),
      newCount: panels.length,
      version: myContext.version,
    };
    if (panels.length && !myContext.existingRecommendations) {
      myContext["existingRecommendations"] = {};
    }
    panels.forEach((panel) => {
      myContext.existingRecommendations[panel.Id] = true;
    });
    myContext["previousDiscoverDataset"] = dataset;
    myContext["recommendationGenerated"] = true;
  } else if (
    !myContext.lastRecommendationCreationTime ||
    myContext.creatingRecommendations ||
    myContext.expandingRecommendations ||
    myContext.refreshingRecommendations
  ) {
    // need to create a new list of recommendations
    let filter = constructDiscoverFilter(myContext);
    console.log("FILTER", filter);
    filter["numRequested"] = 1000;
    let promises = [];
    let oldRecommendationNextToken = null;
    let candidatesNextToken = null;

    let oldRecommendationContinue = true;
    let candidatesContinue =
      filter.direct || filter.parent || filter.forced ? true : false;
    let directCandidatesContinue = filter.direct ? true : false;
    let parentCandidatesContinue = filter.parent ? true : false;
    let forcedCandidatesContinue = filter.forced ? true : false;

    let oldRecommendations = [];
    let candidates = [];
    if (
      myContext.oldRecommendationCandidates &&
      myContext.oldRecommendationCandidateFetchTime > currentTime - 1800 &&
      !myContext.declaredTopicsChanged
    ) {
      candidates = [...myContext.oldRecommendationCandidates];
    } else {
      myContext["oldRecommendationCandidateFetchTime"] = currentTime;
      if (myContext.declaredTopicsChanged) {
        myContext["declaredTopicsChanged"] = false;
      }
      let fetchStart = performance.now();
      let fetchedCandidateCount = 0;
      let acceptedCandidates = {};
      let duplicateCandidates = 0;
      do {
        promises = [];
        if (oldRecommendationContinue) {
          // get all recommendations for the user
          promises.push(
            API.graphql(
              graphqlOperation(recommendationsForUser, {
                userId: myContext.Id,
                nextToken: oldRecommendationNextToken,
                limit: 1000,
              })
            )
          );
        }
        if (candidatesContinue) {
          // get candidate list of recommendations
          //filter["nextToken"] = candidatesNextToken;
          let currentFilter = null;
          if (directCandidatesContinue) {
            currentFilter = filter.direct?.filter;
          } else if (parentCandidatesContinue) {
            currentFilter = filter.parent?.filter;
          } else if (forcedCandidatesContinue) {
            currentFilter = filter.forced?.filter;
          }
          promises.push(
            API.graphql(
              graphqlOperation(recommendationCandidates, {
                filter: currentFilter,
                nextToken: candidatesNextToken,
                numRequested: 1000,
                label: "direct",
              })
            )
          );
        }
        try {
          let responses = await Promise.all(promises);
          clog("RESPONSES", responses);
          responses.forEach((response) => {
            if (response?.data?.recommendationByUserId) {
              oldRecommendations = [
                ...oldRecommendations,
                ...response.data.recommendationByUserId.items,
              ];
              oldRecommendationNextToken =
                response.data.recommendationByUserId.nextToken;
              if (!oldRecommendationNextToken) {
                oldRecommendationContinue = false;
              }
            }

            if (response?.data?.listUrls) {
              let newCandidates = response.data.listUrls.items;
              candidates = [...candidates, ...newCandidates];
              candidatesNextToken = response.data.listUrls.nextToken;
              if (!candidatesNextToken) {
                candidatesContinue = false;
              }
              fetchedCandidateCount += response.data.listUrls.items.length;
            }
            if (response?.data?.searchUrls) {
              let newCandidates = response.data.searchUrls.items;
              newCandidates.forEach((u) => {
                if (!acceptedCandidates[u.Id]) {
                  candidates.push(u);
                  acceptedCandidates[u.Id] = true;
                } else {
                  duplicateCandidates++;
                }
              });
              candidatesNextToken = response.data.searchUrls.nextToken;
              if (!candidatesNextToken) {
                if (directCandidatesContinue) {
                  directCandidatesContinue = false;
                  if (!parentCandidatesContinue && !forcedCandidatesContinue) {
                    candidatesContinue = false;
                  }
                } else if (parentCandidatesContinue) {
                  parentCandidatesContinue = false;
                  if (!forcedCandidatesContinue) {
                    candidatesContinue = false;
                  }
                } else if (forcedCandidatesContinue) {
                  forcedCandidatesContinue = false;
                  candidatesContinue = false;
                }
              }
              fetchedCandidateCount += response.data.searchUrls.items.length;
            }
          });
        } catch (err) {
          console.log("CANNOT FETCH RECOMMENDATIONS", err);
        }
        console.log(
          "NUM CANDIDATES",
          candidates.length,
          "out of",
          fetchedCandidateCount,
          "duplicate",
          duplicateCandidates
        );
        if (
          !oldRecommendationContinue &&
          candidatesContinue &&
          fetchedCandidateCount >= 3000
        ) {
          candidatesContinue = false;
        }
      } while (oldRecommendationContinue || candidatesContinue);
      console.log("OLD RECOMMENDATION COUNT", oldRecommendations.length);
      acceptedCandidates = {};
      let fetchEnd = performance.now();
      console.log("Data fetch time", (fetchEnd - fetchStart) / 1000);
      myContext.oldRecommendationCandidates = [...candidates];
    }
    clog("OLD RECOMMENDATIONS", oldRecommendations);
    clog("CANDIDATES", candidates);
    let oldScores = {};
    if (oldRecommendations.length && !myContext.existingRecommendations) {
      myContext["existingRecommendations"] = {};
    }
    oldRecommendations.forEach((r) => {
      if (r?.type != "Notification") {
        oldScores[r.urlId] = r.score / 1000;
        myContext.existingRecommendations[r.urlId] = true;
      }
    });

    let scores = [];
    let spins = 0;
    let slikes = 0;
    let scomments = 0;
    let sviews = 0;
    let sparticipants = 0;
    let scparticipants = 0;
    let scuparticipants = 0;
    let slparticipants = 0;
    let svparticipants = 0;

    let explanations = {};
    candidates.forEach((c) => {
      for (let index in c) {
        if (index.startsWith("num") && c[index] == null) {
          c[index] = 0;
        }
      }
      let { score, topicScore, compositeScore, explanation } = scoreAndExplain(
        c,
        myContext,
        oldScores,
        currentTime
      );

      spins += explanation?.counters?.numPins;
      slikes += explanation?.counters?.numLike;
      scomments += explanation?.counters?.numComment;
      sviews += explanation?.counters?.numView;
      sparticipants += explanation?.counters?.nparticipants;
      scparticipants += explanation?.counters?.ncparticipants;
      scuparticipants += explanation?.counters?.ncuparticipants;
      slparticipants += explanation?.counters?.nlparticipants;
      svparticipants += explanation?.counters?.nvparticipants;

      scores.push({
        ...c,
        score: score,
        topicScore: topicScore,
        compositeScore: compositeScore,
      });
      explanations[c.Id] = explanation;
    });
    if (candidates.length) {
      let apins = spins / candidates.length;
      let alikes = slikes / candidates.length;
      let acomments = scomments / candidates.length;
      let aviews = sviews / candidates.length;
      let aparticipants = sparticipants / candidates.length;
      let acparticipants = scparticipants / candidates.length;
      let acuparticipants = scuparticipants / candidates.length;
      let alparticipants = slparticipants / candidates.length;
      let avparticipants = svparticipants / candidates.length;

      console.log(
        "AVERAGES:",
        "pin",
        apins,
        "like",
        alikes,
        "comment",
        acomments,
        "view",
        aviews,
        "participant",
        aparticipants,
        "like participant",
        alparticipants,
        "curation participant",
        acuparticipants,
        "comment participant",
        acparticipants,
        "view participant",
        avparticipants
      );
    }
    candidates = scores.sort((a, b) =>
      a.compositeScore > b.compositeScore
        ? -1
        : a.compositeScore == b.compositeScore
        ? explanations[a.Id].counters.numTotalVisit >
          explanations[b.Id].counters.numTotalVisit
          ? -1
          : 1
        : 1
    );
    clog("SORTED CANDIDATES", candidates);

    promises = [];
    let topScores = {};
    let targetCount = 5;
    if (myContext.expandingRecommendations) {
      targetCount = 3;
    } else if (myContext.refreshingRecommendations) {
      targetCount = 5;
    }
    let selectedCount = 0;
    let minScore = 100000;
    let consideredIndex = 0;
    console.log("TARGET COUNT", targetCount, "FROM", candidates.length);
    for (let i = 0; i < candidates.length && selectedCount < targetCount; i++) {
      if (
        !myContext.existingRecommendations?.[candidates[i].Id] &&
        !myContext?.doNoRecommend?.[candidates[i].Id] &&
        candidates[i].compositeScore > 0
      ) {
        selectedCount++;
        promises.push(
          API.graphql(
            graphqlOperation(getSingleUrl, {
              Id: candidates[i].Id,
            })
          )
        );
        clog("MIGHT BE GOOD", candidates[i]);
        topScores[candidates[i].Id] = candidates[i].compositeScore;
        if (candidates[i].compositeScore < minScore) {
          minScore = candidates[i].compositeScore;
        }
      }
      consideredIndex = i;
    }
    console.log("SELECTED COUNT", selectedCount);
    let numAvailable = 0;
    // Find the number of available items with high enough score
    for (let i = consideredIndex; i < candidates.length; i++) {
      if (
        !myContext.existingRecommendations?.[candidates[i].Id] &&
        !myContext?.doNoRecommend?.[candidates[i].Id] &&
        candidates[i].score >= 14.1650317532979
      ) {
        numAvailable++;
      }
    }
    console.log(
      "NUMBER OF ITEMS AVAILABLE FOR FUTURE RECOMMENDATIONS",
      numAvailable,
      "LOWEST SCORE",
      minScore
    );
    try {
      let responses = await Promise.all(promises);
      clog("URL RESPONSES", responses);
      let panels = [];
      let unknownUserIds = {};
      let mayNeedLookUp = {};
      responses?.forEach((u) => {
        let element = u?.data?.getUrl;
        handleOneUrl(element, panels, unknownUserIds, myContext, mayNeedLookUp);
      });
      await handleLookups(panels, unknownUserIds, myContext, mayNeedLookUp);

      let sortedRawPanels = panels.sort((a, b) =>
        topScores[a.Id] > topScores[b.Id]
          ? -1
          : topScores[a.Id] == topScores[b.Id]
          ? explanations[a.Id].counters.numTotalVisit >
            explanations[a.Id].counters.numTotalVisit
            ? -1
            : 1
          : 1
      );
      let sortedPanels = dropTestData(myContext, sortedRawPanels, task);
      console.log(
        "AFTER DROPPING",
        sortedRawPanels.length,
        "to",
        sortedPanels.length
      );

      // Annotate all items with explanations
      sortedPanels?.forEach((item) => {
        clog(
          "SCORE DETAILS",
          item.title,
          explanations[item.Id]?.score,
          explanations[item.Id].counters.numLongVisit,
          explanations[item.Id].counters.numTotalVisit
        );
        item["score"] = explanations[item.Id]?.score;
        item["explanation"] = explanations[item.Id];
      });
      clog("SORTED PANELS", sortedPanels);
      if (sortedRawPanels.length && !sortedPanels.length) {
        console.log("Used up all candidates because of test data - retry");
        return getDiscoverFeedData(handle, myContext, task, callback);
      }
      await handleLookups(
        sortedPanels,
        unknownUserIds,
        myContext,
        mayNeedLookUp
      );

      console.log(
        "WILL CONSTRUCT NEW DATASET WITH",
        myContext.expandingRecommendations ? "expansion" : "refresh"
      );
      dataset = {
        panels: myContext.expandingRecommendations
          ? myContext["previousDiscoverDataset"]?.panels?.length
            ? [...myContext["previousDiscoverDataset"].panels, ...sortedPanels]
            : sortedPanels
          : sortedPanels,
        fetchTimeStamp: timeStamp(),
        newCount: sortedPanels.length,
        version: myContext.version,
      };
      if (sortedPanels.length && !myContext.existingRecommendations) {
        myContext["existingRecommendations"] = {};
      }
      if (
        !myContext.lastRecommendationCreationTime ||
        myContext.creatingRecommendations
      ) {
        myContext["lastRecommendationCreationTime"] = dataset.fetchTimeStamp;
        myContext["currentRecommendationRefreshCount"] = 0;
        myContext["currentRecommendationExpansionCount"] = 0;
        myContext["creatingRecommendations"] = false;
        myContext["currentRecommendationBatchSequence"] =
          myContext.currentRecommendationBatchSequence
            ? myContext.currentRecommendationBatchSequence + 1
            : 1;
        myContext["currentRecommendationCount"] = 0;
      }
      if (myContext.refreshingRecommendations) {
        myContext["currentRecommendationBatchSequence"] =
          myContext.currentRecommendationBatchSequence
            ? myContext.currentRecommendationBatchSequence + 1
            : 1;
      }
      promises = [];
      sortedPanels.forEach((panel) => {
        myContext.existingRecommendations[panel.Id] = true;
        promises.push(
          API.graphql(
            graphqlOperation(addRecommendation, {
              Id: generateUniqueId(),
              batchId: myContext.currentRecommendationBatchSequence,
              score: parseInt(topScores[panel.Id] * 1000, 10),
              sequence: ++myContext.currentRecommendationItemSequence,
              urlId: panel.Id,
              userId: myContext.Id,
              userIdBatchId: [
                myContext.Id,
                myContext.currentRecommendationBatchSequence,
              ].join(":"),
              creationTS: timeStamp(),
              signals: "",
            })
          )
        );
        myContext.currentRecommendationCount++;
      });
      promises.push(
        API.graphql(
          graphqlOperation(updateUserCounts, {
            Id: myContext.Id,
            lastRecommendationCreationTime:
              myContext.lastRecommendationCreationTime,
            lastRecommendationExpansionTime:
              myContext.lastRecommendationExpansionTime,
            lastRecommendationRefreshTime:
              myContext.lastRecommendationRefreshTime,
            currentRecommendationBatchSequence:
              myContext.currentRecommendationBatchSequence,
            currentRecommendationItemSequence:
              myContext.currentRecommendationItemSequence,
            currentRecommendationExpansionCount:
              myContext.currentRecommendationExpansionCount,
            currentRecommendationRefreshCount:
              myContext.currentRecommendationRefreshCount,
            currentRecommendationCount: myContext.currentRecommendationCount,
            numAvailableRecommendations: numAvailable,
            lowestRecommendationScore: parseInt(minScore * 1000, 10),
          })
        )
      );
      responses = await Promise.all(promises);
      clog("RESPONSES FOR RECOMMENDATION", responses);
      console.log(
        "Creating new recommendations",
        myContext.creatingRecommendations
      );

      myContext["previousDiscoverDataset"] = dataset;
      myContext["recommendationGenerated"] = true;
    } catch (err) {
      console.log("Cannot fetch url details", err);
      if (callback) {
        callback({ success: false, message: "error fetching data" });
      }
      return { success: false, message: "error fetching data" };
    }
  } else {
    console.log("NOTHING TO DO FOR RECOMMENDATIONS");
    dataset = {
      panels: [],
      fetchTimeStamp: timeStamp(),
      newCount: 0,
      version: myContext.version,
    };
  }
  clog("DATASET:", dataset);
  if (callback) {
    callback({
      success: true,
      message: "fetched",
      requestingTask: task,
      dataset: dataset,
    });
  }
}

async function getVillageFeedData(handle, myContext, task, callback) {
  let currentTime = timeStamp();
  let dataset = {};
  clog("EXISTING VILLAGE DATA SET", myContext.previousVillageDataset);
  console.log(
    "NUMBER OF PANELS",
    myContext.previousVillageDataset?.panels?.length
  );
  console.log(
    "CURRENT VERSION",
    myContext.version,
    "vs",
    myContext.previousVillageDataset?.version
  );
  if (
    myContext.previousVillageDataset &&
    myContext.version == myContext.previousVillageDataset.version &&
    !myContext.manualFetch
  ) {
    console.log("REUSE previously computed village dataset");
    dataset = myContext.previousVillageDataset;
  } else {
    // generate the filter
    let filter = constructVillageFilter(myContext);
    let payloads = [];
    if (filter) {
      try {
        let start = performance.now();
        let data = null;
        payloads.push({ execute: { filter: filter } });
        let feedData = null;
        if (Platform.OS == "web") {
          filter["numRequested"] = 100;
        }
        feedData = await API.graphql(graphqlOperation(feed, filter));
        payloads.push({ fetchDone: { data: feedData } });
        let fetchDone = performance.now();
        console.log("TIME: fetch", (fetchDone - start) / 1000);
        clog("got feed data", feedData);
        if (feedData?.data?.searchUrls?.items) {
          data = feedData.data.searchUrls.items;
        }
        clog("DATA from SEARCH", data?.length, data);
        let indexDown = false;
        if (!data || !data.length) {
          let probeFilter = constructIndexValidityFilter();
          let probeData = null;
          probeData = await API.graphql(graphqlOperation(feed, probeFilter));
          if (!probeData?.data?.searchUrls?.items) {
            indexDown = true;
            console.log("INDEX IS DOWN");
          } else {
            console.log("INDEX IS NOT DOWN");
            clog("PROBE DATA", probeData);
          }
        }
        if (indexDown) {
          data = null;
          clog("will try SCAN");
          feedData = await API.graphql(
            graphqlOperation(feedWithoutSearch, filter)
          );
          clog("got feed data", feedData);
          if (feedData?.data?.urlSortedByCreationTS?.items) {
            data = feedData.data.urlSortedByCreationTS?.items;
          }
        }
        if (data) {
          let panels = [];
          let unknownUserIds = {};
          let mayNeedLookUp = {};
          data?.forEach((element) => {
            let pins = element?.pins?.items;
            let comments = element?.comments?.items?.sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            for (let index in element) {
              if (index.startsWith("num") && element[index] == null) {
                element[index] = 0;
              }
            }

            pins?.forEach((pin) => {
              adjustPinForRichShareNote(pin, myContext, mayNeedLookUp);
              if (!myContext.users[pin.curatorId]) {
                unknownUserIds[pin.curatorId] = true;
              }
              pin?.actions?.items?.forEach((action) => {
                if (!myContext.users[action.actorId]) {
                  unknownUserIds[action.actorId] = true;
                }
                if (pin.content) {
                  action["hasNote"] = true;
                  if (pin.markup) {
                    action["hasRichShareNote"] = true;
                  } else {
                    action["hasRichShareNote"] = false;
                  }
                } else {
                  action["hasNote"] = false;
                  action["hasRichShareNote"] = false;
                }
              });
              pin?.comments?.items?.forEach((c2) => {
                if (!myContext.users[c2.curatorId]) {
                  unknownUserIds[c2.curatorId] = true;
                }
              });
            });

            comments?.forEach((comment) => {
              if (!myContext.users[comment.curatorId]) {
                unknownUserIds[comment.curatorId] = true;
              }
              comment?.actions?.items?.forEach((action) => {
                if (!myContext.users[action.actorId]) {
                  unknownUserIds[action.actorId] = true;
                }
              });
              comment?.comments?.items?.forEach((c2) => {
                if (!myContext.users[c2.curatorId]) {
                  unknownUserIds[c2.curatorId] = true;
                }
              });
            });

            let panel = {
              Id: element.Id,
              uri: element.uri,
              title: element.title,
              snippet: element.snippet,
              photo: element.photo,
              photoUrl: element.photoUrl,
              duration: element.duration,
              author: element.author,
              authorName: element.authorName,
              createdAt: element.createdAt,
              source: element.source,
              sourceId: element.sourceId,
              numComment: element.numComment,
              numContribute: element.numContribute,
              numLongView: element.numLongView,
              numTotalView: element.numTotalView,
              numLongVisit: element.numLongVisit,
              numTotalVisit: element.numTotalVisit,
              numLike: element.numLike,
              numPins: element.numPins,
              commenterIds: element.commenterIds,
              curatorIds: element.curatorIds,
              likerIds: element.likerIds,
              listIds: element.listIds,
              topicIds: element.topicIds,
              creationTS: element.creationTS,
              pins: pins,
              comments: comments,
              topics: element.topics?.items,
            };
            let viable = true;
            if (task == "village") {
              viable = hasAPath(panel, myContext);
              clog("RESPONSE OF VALID", viable, panel);
            }
            if (viable) {
              clog("PANEL IS VIABLE");
              panel["topActions"] = findTopActions(
                panel,
                myContext,
                null,
                task
              ); // host is self really
              panel["justificationAction"] = panel.topActions?.[0];
              panel["actionParams"] = null;
              if (panel.topActions?.length > 0) {
                clog("ADD PANEL TO PANELS");
                let { score, topicScore, compositeScore, explanation } =
                  scoreAndExplain(panel, myContext, null, currentTime);
                panel["score"] = score;
                panel["topicScore"] = topicScore;
                panel["explanation"] = explanation;
                panel["compositeScore"] = compositeScore;
                panels.push(panel);
              } else {
                clog("NOT ENOUGH TOP ACTIONS", panel);
              }
            }
          });

          clog("UNKNOWN USERS", unknownUserIds);
          await batchLookupUsers(
            Object.keys(unknownUserIds),
            myContext.users,
            null
          );
          let untilLookup = performance.now();
          console.log(
            "TIME: unknown users lookup",
            (untilLookup - fetchDone) / 1000
          );
          Object.keys(unknownUserIds).forEach((Id) => {
            let user = myContext.users[Id];
            if (user) {
              mayNeedLookUp[user.avatar] = true;
            }
          });
          panels.forEach((element) => {
            clog("consider", element);
            if (element?.photo) {
              mayNeedLookUp[element.photo] = true;
            }
            if (element?.source?.avatar) {
              mayNeedLookUp[element.source.avatar] = true;
            }
            element?.pins?.forEach((pin) => {
              if (pin?.curator?.avatar) {
                mayNeedLookUp[pin.curator.avatar] = true;
              }
              pin?.comments?.items?.forEach((c2) => {
                if (c2?.curator?.avatar) {
                  mayNeedLookUp[c2.curator.avatar] = true;
                }
              });
            });
            element?.comments?.forEach((comment) => {
              if (comment?.curator?.avatar) {
                mayNeedLookUp[comment.curator.avatar] = true;
              }
              comment?.comments?.items?.forEach((c2) => {
                if (c2?.curator?.avatar) {
                  mayNeedLookUp[c2.curator.avatar] = true;
                }
              });
            });
          });
          let untilSign = performance.now();
          console.log("TIME: rest", (untilSign - untilLookup) / 1000);
          await batchPresign(
            Object.keys(mayNeedLookUp),
            myContext.presignedUrls,
            null
          );
          let untilSignDone = performance.now();
          console.log("TIME: sign", (untilSignDone - untilSign) / 1000);
          let sortedPanels = [];
          panels.forEach((panel) => {
            let shouldAdd = recomputeTopActionsIfNecessary(
              panel,
              myContext,
              task
            );
            if (shouldAdd || task == "village") {
              // village should not filter out content from test users
              sortedPanels.push(panel);
            }
          });
          clog("SORTED PANELS", sortedPanels);

          let completion = performance.now();
          console.log("TIME: completion", (completion - untilSignDone) / 1000);
          dataset = {
            panels: sortedPanels,
            fetchTimeStamp: timeStamp(),
            newCount: sortedPanels.length,
            version: myContext.version,
          };
          myContext["previousVillageDataset"] = dataset;

          if (indexDown) {
            // Resend all documents to index to fix missing document problem
            resendUrlsToIndex(myContext);
            elog(myContext.Id, "feed", "data fetch", "resending urls to index");
          }
        }
      } catch (err) {
        console.log(
          myContext.Id,
          "feed",
          "data fetch",
          "error fetching ",
          task,
          "feed...",
          err
        );
        elog(
          myContext.Id,
          "feed",
          "data fetch",
          "error fetching ",
          task,
          "feed...",
          err.message,
          payloads
        );
        if (callback) {
          callback({ success: false, message: "error fetching data" });
        }
        return { success: false, message: "error fetching data" };
      }
    }
  }
  clog("GOT DATASET", dataset);
  Object.keys(dataset.panels).forEach((k) => {
    let url = dataset.panels[k];
    let pinLongVisit = 0;
    let pinTotalVisit = 0;
    url.pins.forEach((p) => {
      if (p.numLongVisit) {
        pinLongVisit += p.numLongVisit;
      }
      if (p.numTotalVisit) {
        pinTotalVisit += p.numTotalVisit;
      }
    });
    if (
      url.numLongVisit != pinLongVisit ||
      url.numTotalVisit != pinTotalVisit
    ) {
      console.log(
        "visit counter mismatch",
        url.numLongVisit,
        pinLongVisit,
        url.numTotalVisit,
        pinTotalVisit,
        url.title,
        url
      );
    }
  });
  if (callback) {
    callback({
      success: true,
      message: "fetched",
      requestingTask: task,
      dataset: dataset,
    });
  }
}

function normalizeVector(interests) {
  let total = 0;
  Object.keys(interests).forEach((tid) => {
    total += interests[tid] * interests[tid];
  });
  let length = Math.sqrt(total);
  Object.keys(interests).forEach((tid) => {
    interests[tid] /= length;
  });
  return interests;
}

export function generateVector(topicIds, myContext, normalize = true) {
  let interests = {};
  let topics = generateMapFromIdString(topicIds);
  Object.keys(topics).forEach((tid) => {
    let parentTopicId = myContext.topics[tid].parentTopicId;
    if (parentTopicId) {
      interests[tid] = 1;
      if (parentTopicId != "Topic066") {
        let contribution = 1 / myContext.topics[parentTopicId].numChildren;
        interests[parentTopicId] = interests[parentTopicId]
          ? interests[parentTopicId] + contribution
          : contribution;
      }
    }
  });
  if (normalize) {
    normalizeVector(interests);
  }
  return interests;
}

function generateDocVector(topicIds, myContext, normalize = true) {
  let interests = {};
  let parentInterests = {};
  let topics = generateMapFromIdString(topicIds);
  Object.keys(topics).forEach((tid) => {
    let parentTopicId = myContext.topics[tid].parentTopicId;
    if (parentTopicId) {
      interests[tid] = 1;
      if (parentTopicId != "Topic066") {
        let contribution = 1 / myContext.topics[parentTopicId].numChildren;
        parentInterests[parentTopicId] = parentInterests[parentTopicId]
          ? parentInterests[parentTopicId] + contribution
          : contribution;
      }
    }
  });
  if (normalize) {
    normalizeVector(interests);
    normalizeVector(parentInterests);
  }
  // Add all parent topics except the Other topic
  Object.keys(parentInterests).forEach((tid) => {
    if (tid != "Topic066") {
      interests[tid] = parentInterests[tid];
    }
  });
  return interests;
}

export async function getFeedData({ handle, task, myContext, callback }) {
  if (!myContext.interestVector || myContext.declaredTopicsChanged) {
    let interests = generateVector(myContext.declaredTopicIds, myContext, true);
    myContext["interestVector"] = interests;
  }
  clog("INTERESTS", myContext.interestVector);

  clog("TYPEOF myContent", typeof myContext);
  clog("TOPICS", myContext.topics);
  if (!handle) {
    if (callback) {
      callback({ success: false, message: "no handle" });
    }
    return { success: false, message: "no handle" };
  }
  console.log("WIll get more items");
  if (task == "discover") {
    await getDiscoverFeedData(handle, myContext, task, callback);
    return;
  } else if (task == "village") {
    await getVillageFeedData(handle, myContext, task, callback);
  }
}

export async function getUrl({ Id, myContext, callback }) {
  let status = { success: true };
  if (!Id) {
    status.success = false;
    status["message"] = "No Id provided for url";
    if (callback) {
      callback(status);
    }
    return status;
  }
  try {
    let data = null;
    const urlData = await API.graphql(
      graphqlOperation(getSingleUrl, {
        Id: Id,
      })
    );

    data = urlData?.data?.getUrl;
    if (data) {
      clog("data is ", data);
      let unknownUserIds = {};
      let mayNeedLookUp = {};
      let url = {
        Id: data?.Id,
        title: data?.title,
        uri: data?.uri,
        sourceId: data?.sourceId,
        source: data?.source,
        photo: data?.photo,
        photoUrl: data?.photoUrl,
        topicIds: data?.topicIds,
        numContribute: data?.numContribute,
        numPins: data?.numPins,
        curatorIds: data?.curatorIds,
        listIds: data?.listIds,
        numLongView: data?.numLongView,
        numTotalView: data?.numTotalView,
        numLongVisit: data?.numLongVisit,
        numTotalVisit: data?.numTotalVisit,
      };
      url["comments"] = data?.comments?.items;
      url["pins"] = data?.pins?.items;
      url.pins.forEach((pin) => {
        adjustPinForRichShareNote(pin, myContext, mayNeedLookUp);
        if (!myContext.users[pin.curatorId]) {
          unknownUserIds[pin.curatorId] = true;
        }
        pin.comments?.items?.forEach((c2) => {
          if (!myContext.users[c2.curatorId]) {
            unknownUserIds[c2.curatorId] = true;
          }
        });
        pin?.actions?.items?.forEach((action) => {
          if (pin.content) {
            action["hasNote"] = true;
            if (pin.markup) {
              action["hasRichShareNote"] = true;
            } else {
              action["hasRichShareNote"] = false;
            }
          } else {
            action["hasNote"] = false;
            action["hasRichShareNote"] = false;
          }
        });
      });
      url.comments.forEach((comment) => {
        if (!myContext.users[comment.curatorId]) {
          unknownUserIds[comment.curatorId] = true;
        }
        comment.comments?.items?.forEach((c2) => {
          if (!myContext.users[c2.curatorId]) {
            unknownUserIds[c2.curatorId] = true;
          }
        });
      });
      if (url.source?.avatar) {
        mayNeedLookUp[url.source.avatar] = true;
      }
      await batchLookupUsers(
        Object.keys(unknownUserIds),
        myContext.users,
        null
      );
      Object.keys(unknownUserIds).forEach((Id) => {
        let user = myContext.users[Id];
        if (user) {
          mayNeedLookUp[user.avatar] = true;
        }
      });

      await batchPresign(
        Object.keys(mayNeedLookUp),
        myContext.presignedUrls,
        null
      );
      status["annotatedUrl"] = url;
      if (callback) {
        callback(status);
      }
      return status;
    } else {
      status.success = false;
      status["message"] = "Url not found";
      if (callback) {
        callback(status);
      }
      return status;
    }
  } catch (err) {
    status.success = false;
    status["message"] = "Error fetching url";
    if (callback) {
      callback(status);
    }
    console.log("error fetching pin...", err);
    elog(myContext.Id, "item details", "data fetch", err.message);
    return status;
  }
}
