import React from "react";
import { View, Text, StyleSheet, Pressable, Platform } from "react-native";

import Avatar from "./Avatar";
import { formatTime } from "../utils/TimeFormatting";
import { logEvent } from "../utils/LogEvent";
import { clog } from "../utils/Log";

const PinCurator = ({
  curatorId,
  pin,
  myContext,
  navigation,
  refererActionId,
  hostUserId,
  origin,
  skipCollection,
  guest,
  colors,
  screen,
}) => {
  const goToUserProfile = () => {
    clog("pressed button");
    if (myContext.Id != curatorId) {
      logEvent(
        origin == "myvillage" ? "MyVillage_Content_Profile" : "Content_Profile",
        {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: origin,
          platform: Platform.OS,
          action: "click",
          target: "profile",
          appVersion: myContext.appVersion,
        }
      );
    }
    navigation.push(guest ? "GuestUserDetails" : "UserDetails", {
      Id: curatorId,
      handle: myContext.users[curatorId]?.handle,
      fromList: true,
      refererActionId: refererActionId,
      hostUserId: hostUserId,
    });
  };

  const Collection = () => {
    const onPress = () => {
      navigation.push(guest ? "GuestContentList" : "ContentList", {
        Id: pin?.list?.Id,
        name: pin?.list?.name,
        description: pin?.list?.description,
        owner: myContext.users[curatorId],
      });
      logEvent(
        myContext?.Id == curatorId
          ? "MyProfile_Collection_Detail"
          : "OtherProfile_Collection_Detail",
        {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: myContext?.Id == curatorId ? "myprofile" : "otherprofile",
          platform: Platform.OS,
          action: "click",
          target: "detail",
          targetuser: myContext.users[curatorId]?.handle,
          appVersion: myContext.appVersion,
        }
      );
    };

    return (
      <Pressable
        onPress={onPress}
        style={{
          flex: 1,
          marginLeft: 4,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: colors.secondaryText,
            marginRight: 4,
          }}
        >
          {"·"}
        </Text>
        <View
          style={{
            borderRadius: 25,
            borderWidth: 1,
            borderColor: colors.smallDivider,
            paddingHorizontal: 8,
            paddingVertical: 2,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: colors.secondaryText,
            }}
            numberOfLines={1}
            ellipsizeMode={"tail"}
          >
            {pin?.list?.name}
          </Text>
        </View>
      </Pressable>
    );
  };

  const Reaction = () => {
    return (
      <View
        style={{
          height: 22,
          width: 22,
        }}
      >
        <View
          style={[
            {
              width: 8,
              height: 8,
              borderRadius: 8,
              left: -4.5,
              bottom: 0,
              position: "absolute",
              backgroundColor: colors.commentBackground,
            },
          ]}
        />
        <View
          style={{
            height: 22,
            width: 22,
            borderRadius: 22,
            left: -4,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors.commentBackground,
            position: "absolute",
          }}
        >
          <Text
            style={{
              fontSize: 13,
              justifyContent: "center",
            }}
          >
            {pin.reaction}
          </Text>
        </View>
      </View>
    );
  };

  let currentTime = Date.now();
  clog(
    "PIN TO SHOW CURATOR FOR",
    pin,
    "CuratorId",
    curatorId,
    "CONTEXT",
    myContext
  );
  if (!pin) {
    return <View></View>;
  }

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Pressable onPress={goToUserProfile} style={{ flexDirection: "row" }}>
          <Avatar
            item={
              curatorId == myContext.Id ? myContext : myContext.users[curatorId]
            }
            style={[
              skipCollection
                ? {
                    height: 24,
                    width: 24,
                    borderRadius: 24,
                  }
                : {
                    height: 28,
                    width: 28,
                    borderRadius: 20,
                  },
              {
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primaryText,
              },
            ]}
            textstyle={{
              fontSize: skipCollection ? 14 : 20,
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
              color: colors.background,
            }}
            myContext={myContext}
          />
          {pin?.reaction != null && pin?.reaction != "" && <Reaction />}
        </Pressable>
        <View
          style={{
            flex: 1,
            justifyContent: skipCollection ? "space-around" : "space-between",
            flexDirection: "column",
            paddingLeft: pin?.reaction != null && pin?.reaction != "" ? 4 : 8,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Pressable onPress={goToUserProfile}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: skipCollection ? "400" : "700",
                  color: skipCollection
                    ? colors.secondaryText
                    : colors.primaryText,
                }}
              >
                {curatorId == myContext.Id
                  ? "You"
                  : myContext.users[curatorId]?.name
                  ? myContext.users[curatorId]?.name
                  : "@" + myContext.users[curatorId]?.handle}
              </Text>
            </Pressable>
            {(origin != "discover" || screen == "comment") && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 4,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: colors.secondaryText,
                    marginRight: 4,
                  }}
                >
                  {"·"}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: colors.secondaryText,
                  }}
                >
                  {formatTime(pin?.createdAt, currentTime)}
                </Text>
              </View>
            )}
            {!skipCollection ||
              (screen !== "daily-best" &&
                origin !== "discover" &&
                origin !== "myvillage" && <Collection />)}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PinCurator;

const styles = StyleSheet.create({
  superposedCollection: {
    height: 11.84,
    width: 11.84,
    alignSelf: "center",
  },
});
