import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Pressable,
  Image,
  StyleSheet,
  ActivityIndicator,
  FlatList,
  Text,
  View,
  SafeAreaView,
  Platform,
  AppState,
} from "react-native";

import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";

import Avatar from "../components/Avatar";

import { initialUser } from "../utils/InitialValues";

import { formatTime } from "../utils/TimeFormatting";
import Blocker from "../components/Blocker";
import { findTopActions, detailsFromAction } from "../utils/TopActions";
import { timeStamp } from "../utils/TimeStamp";
import { photoIdFromId } from "../utils/RandomFromId";
import { clog } from "../utils/Log";
import { getNotificationData } from "../controllers/NotificationController";
import { toggleFollow } from "../utils/Follow";
import { logEvent } from "../utils/LogEvent";
import { useTheme } from "../theme";
import ShareNote from "../components/ShareNote";
import { hashCode } from "../utils/Id";
import WebView from "react-native-webview";
import { Modal } from "react-native";
import cross from "../assets/cross.png";
import cloud1 from "../assets/cloud_1.png";
import logo from "../assets/logo.png";
import { API, graphqlOperation } from "aws-amplify";
import { dailyRecommendationsForUser } from "../src/graphql/custom";
import { useIsFocused } from "@react-navigation/native";

const OneUserNotification = ({
  item,
  myContext,
  navigation,
  colors,
  setState,
  currentTime,
}) => {
  let followingComputed =
    item.action.objectType == "User" &&
    myContext.actionsByUser?.["User"]?.["Follow"]?.[item.target.Id] !=
      undefined &&
    myContext.actionsByUser?.["User"]?.["Follow"]?.[item.target.Id] != null;
  const [following, setFollowing] = useState(followingComputed);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setFollowing(followingComputed);
    }
  }, [isFocused]);

  return (
    <View
      style={{
        flexDirection: "row",
        paddingRight: 20,
      }}
    >
      <View
        style={{
          flex: 1,
          paddingRight: 16,
        }}
      >
        <Pressable
          onPress={() => {
            ////clog("pressed button for", item);
            navigation.push("UserDetails", {
              Id: item.action.actor.Id,
              handle: item.action.actor.handle,
              fromList: true,
              refererActionId: item.action.Id,
              hostUserId: myContext.Id,
            });
          }}
        >
          <Text
            style={{
              color: colors.primaryText,
              fontSize: 15,
              flexWrap: "wrap",
              flex: 1,
              fontWeight: "700",
            }}
          >
            {item.action.actor.name
              ? item.action.actor.name
              : "@" + item.action.actor.handle}
            <Text
              style={{
                color: colors.secondaryText,
                fontWeight: "400",
              }}
            >
              {" started following you "}
              <Text style={styles.elapsed}>
                {formatTime(item.action.createdAt, currentTime)}
              </Text>
            </Text>
          </Text>
        </Pressable>
      </View>
      <Pressable
        onPress={() => {
          if (myContext?.inflightFollows[item.Id]) {
            console.log("WARNING!!! FOLLOW IS INFLIGHT");
            return;
          }
          myContext.inflightFollows[item.Id] = true;
          let currentFollowing = following;
          setFollowing((prev) => {
            return !prev;
          });
          toggleFollow({
            following: currentFollowing,
            objectType: "User",
            target: item.target,
            refererActionId: item.action.Id,
            hostUserId: null, // give credit to action instead
            myContext: myContext,
            callback: ({ success, message, error }) => {
              delete myContext.inflightFollows[item.Id];
              if (success) {
                setState((previousState) => {
                  ////clog("updated state");
                  return {
                    ...previousState,
                    actionsByUser: myContext.actionsByUser,
                    numUserFollow: myContext.numUserFollow,
                  };
                });
              } else {
                //TODO(alpha): Change button state in case of failure
              }
              logEvent(
                following ? "Notification_Unfollow" : "Notification_Follow",
                {
                  userId: myContext?.Id,
                  username: myContext?.handle,
                  category: "notification",
                  platform: Platform.OS,
                  target: following ? "unfollow" : "follow",
                  action: "click",
                  status: success ? "success" : "failure",
                  [following ? "unfollowed" : "followed"]: item.handle,
                  appVersion: myContext.appVersion,
                }
              );
            },
          });
        }}
      >
        <View
          style={
            following
              ? [
                  styles.uncoloredButton,
                  {
                    color: colors.secondaryButtonText,
                    borderColor: colors.secondaryButtonBorder,
                  },
                ]
              : [
                  styles.coloredButton,
                  {
                    color: colors.primaryButtonText,
                    backgroundColor: colors.primaryButtonBackground,
                  },
                ]
          }
        >
          <Text
            style={
              following
                ? [
                    styles.text,
                    {
                      color: colors.secondaryButtonText,
                      fontWeight: "bold",
                    },
                  ]
                : [
                    styles.text,
                    {
                      color: colors.primaryButtonText,
                      fontWeight: "bold",
                    },
                  ]
            }
          >
            {following ? "Following" : "Follow"}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

const DailyBest = ({ item, colors, navigation, currentTime }) => {
  return (
    <Pressable onPress={() => navigation.push("DailyBest")}>
      <View style={{ flex: 1, flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <Text
            style={{
              fontSize: 15,
              lineHeight: 20,
              fontWeight: "600",
            }}
          >
            {"Must-reads for you 🔥"}
          </Text>

          <Text
            style={{
              color: colors.placeholderText,
              flex: 1,
              flexWrap: "wrap",
              fontSize: 15,
              lineHeight: 22,
            }}
          >
            <Text>{item.url.title}</Text>
            <Text>{" · "}</Text>
            <Text>{formatTime(item.createdAt, currentTime)}</Text>
          </Text>
        </View>
        <View
          style={{
            paddingRight: 20,
            paddingLeft: 16,
          }}
        >
          <Image
            source={{
              uri: item.url.screenshotUrl,
            }}
            style={{
              height: 50,
              width: 50,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 8,
            }}
          />
        </View>
      </View>
    </Pressable>
  );
};

const NotificationScreen = ({ route, navigation }) => {
  async function getData(handle) {
    setLoading(true);
    getNotificationData({
      handle: handle,
      myContext: myContext,
      callback: async ({
        success,
        message,
        error,
        actionsOfInterest,
        activities,
      }) => {
        if (success) {
          let promises = [];

          promises.push(
            API.graphql(
              graphqlOperation(dailyRecommendationsForUser, {
                userIdType: [myContext.Id, "Notification"].join(":"),
                limit: myContext.config.dailyBestCount,
                nextToken: null,
              })
            )
          );

          const responses = await Promise.all(promises);

          let currentTime = timeStamp();

          if (
            responses[0].data?.recommendationByUserIdType?.items?.[0] &&
            responses[0].data?.recommendationByUserIdType?.items?.[0]
              ?.creationTS >
              currentTime - myContext.config.dailyBestWindow
          ) {
            const lastRecommendations =
              responses[0].data.recommendationByUserIdType.items;

            lastRecommendations.sort((a, b) =>
              a.creationTS != b.creationTS
                ? a.creationTS > b.creationTS
                  ? -1
                  : 1
                : a.score != b.score
                ? a.score > b.score
                  ? -1
                  : 1
                : a.urlId > b.urlId
                ? -1
                : 1
            );
            const mustReads = {
              ...lastRecommendations[0],
              objectType: "DailyBest",
            };

            actionsOfInterest.push(mustReads);
            actionsOfInterest.sort((a, b) =>
              a.createdAt > b.createdAt ? -1 : 1
            );
          }

          setRefreshing(false);
          setActions(actionsOfInterest);
          setState((previousState) => {
            let stateCopy = { ...previousState };
            stateCopy["Id"] = myContext.Id;
            stateCopy["fetchTimeStamp"] = timeStamp();
            stateCopy["version"] = myContext.version;
            stateCopy["actions"] = actions;
            stateCopy["activities"] = activities;
            clog("copied state", stateCopy);
            return stateCopy;
          });
        } else {
          clog(message);
          clog("ERROR:", error);
        }
        setLoading(false);
      },
    });
    clog("will try to GETDATA for", handle);
    setRefreshing(false);
  }

  async function dataRefresh() {
    clog("CLICKED ON TAB");
    logEvent("Notification_View", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: "notification",
      platform: Platform.OS,
      appVersion: myContext.appVersion,
    });
    // do something
    if (
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 300 ||
      !stateRef.current.Id
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef.current
      );
      getData(handle).catch((err) => {
        console.log("error finding data: ", err);
      });
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef.current
      );
    }
  }

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const { colors } = useTheme();

  let handle = route?.params?.handle ? route.params.handle : myContext?.handle;
  let Id = route?.params?.Id ? route.params.Id : myContext?.Id;
  let fromList = route?.params?.fromList ? route.params.fromList : false;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  React.useLayoutEffect(() => {
    let canGoBack = navigation.canGoBack();

    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <Pressable
          onPress={() => {
            if (canGoBack) {
              navigation.goBack();
            } else {
              navigation.replace("Village");
            }
          }}
        >
          <View style={{ marginLeft: 10 }}>
            <LeftArrow tintColor={colors.primaryText} />
          </View>
        </Pressable>
      ),
      headerBackTitleVisible: false,
    });
  }, [navigation]);

  const initialState = initialUser;

  const [state, setState] = useState(initialState);
  const [refreshing, setRefreshing] = useState(true);
  const stateRef = useRef(state);
  const appState = useRef(AppState.currentState);
  const [notificationPayload, setNotificationPayload] = useState(null);
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  const [actions, setActions] = useState([]);

  clog("CAME TO NOTIFICATION SCREEN WITH", myContext.notificationPayload);

  if (myContext.notificationPayload) {
    setNotificationPayload(myContext.notificationPayload);
    myContext["notificationPayload"] = null;
  }

  useEffect(() => {
    if (notificationPayload && !refreshing && state.fetchTimeStamp) {
      let payload = notificationPayload;
      setNotificationPayload(null);
      if (payload.urlId) {
        navigation.push("Comments", {
          url: { Id: payload.urlId },
          curator: null,
          refererActionId: null,
          hostUserId: null,
          origin: null,
          notFolded: true,
          incomplete: true,
        });
      } else if (payload.userId) {
        navigation.push("UserDetails", {
          Id: payload.actorId,
          handle: myContext?.users[payload.actorId]?.handle,
          fromList: true,
          refererActionId: null,
          hostUserId: null,
        });
      } else if (payload.type === "DailyBest") {
        navigation.push("DailyBest", { urlId: payload.Id });
      }
    }
  }, [notificationPayload, refreshing, state]);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        console.log("App has come to the foreground!");
        if (myContext.notificationPayload) {
          setNotificationPayload(myContext.notificationPayload);
          myContext["notificationPayload"] = null;
        }
      }

      appState.current = nextAppState;
      console.log("AppState", appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  clog("CONTEXT VERSION", myContext.version);
  clog("CONTEXT", myContext);

  let currentTime = Date.now();
  clog("ACTIONS:", actions);

  const contentModalFunction = (target) => {
    clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  const ListEmptyComponent = () => {
    return (
      <View style={styles.containerListEmpty}>
        <Image
          resizeMode="cover"
          source={cloud1}
          style={{
            height: 85,
            width: 125,
          }}
        />
        <Text style={[styles.textListEmpty, { color: colors.primaryText }]}>
          {"Nothing to know about yet!"}
        </Text>
      </View>
    );
  };

  const LoadingView = () => {
    return (
      <View style={styles.containerListEmpty}>
        <ActivityIndicator />
      </View>
    );
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        {refreshing ? <ActivityIndicator /> : null}
        <FlatList
          style={{
            flex: 1,
            paddingBottom: 54,
            paddingTop: 20,
          }}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          data={actions}
          renderItem={({ item }) => {
            clog(
              "Will render",
              item.action?.objectType,
              item?.target?.url,
              item
            );
            if (
              (item.action?.objectType == "Comment" ||
                item.action?.objectType == "Pin") &&
              item.action?.operation == "Create"
            ) {
              if (!item.target.screenshotUrl) {
                let screenshotUrl = "";
                if (
                  item.target.photo &&
                  myContext.presignedUrls[item.target.photo]
                ) {
                  screenshotUrl =
                    myContext.presignedUrls[item.target.photo]?.uri;
                } else if (item.target.photoUrl) {
                  screenshotUrl = item.target.photoUrl;
                }
                if (!screenshotUrl) {
                  let id = photoIdFromId(item.target.Id);
                  screenshotUrl = "https://picsum.photos/id/" + id + "/300/200";
                  //clog("ScreenshotUrl", screenshotUrl);
                }
                item.target.screenshotUrl = screenshotUrl;
              }
            }
            if (
              (item.action?.objectType == "Pin" ||
                item.action?.objectType == "Comment") &&
              item.action?.operation == "Like"
            ) {
              if (!item.target.screenshotUrl) {
                let screenshotUrl = "";
                if (
                  item.target.photo &&
                  myContext.presignedUrls[item.target.photo]
                ) {
                  screenshotUrl =
                    myContext.presignedUrls[item.target.photo]?.uri;
                } else if (item.target.photoUrl) {
                  screenshotUrl = item.target.photoUrl;
                }
                if (!screenshotUrl) {
                  let id = photoIdFromId(item.target.Id);
                  screenshotUrl = "https://picsum.photos/id/" + id + "/300/200";
                  //clog("ScreenshotUrl", screenshotUrl);
                }
                item.target.screenshotUrl = screenshotUrl;
              }
            }

            if (item.objectType === "DailyBest") {
              if (!item.url.screenshotUrl) {
                let screenshotUrl = "";
                if (item.url.photo && myContext.presignedUrls[item.url.photo]) {
                  screenshotUrl = myContext.presignedUrls[item.url.photo]?.uri;
                } else if (item.url.photoUrl) {
                  screenshotUrl = item.url.photoUrl;
                }
                if (!screenshotUrl) {
                  let id = photoIdFromId(item.urlId);
                  screenshotUrl = "https://picsum.photos/id/" + id + "/300/200";
                  //clog("ScreenshotUrl", screenshotUrl);
                }
                item.url.screenshotUrl = screenshotUrl;
              }
            }

            return (
              <View
                style={{
                  marginBottom: 30,
                  flexDirection: "row",
                  flex: 1,
                  paddingLeft: 20,
                }}
              >
                {item.objectType == "DailyBest" ? (
                  <View style={{ alignSelf: "flex-start" }}>
                    <Image
                      source={logo}
                      style={{
                        height: 30,
                        width: 30,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        backgroundColor: colors.primaryButtonBackground,
                      }}
                    />
                  </View>
                ) : (
                  <Pressable
                    onPress={() => {
                      navigation.push("UserDetails", {
                        Id: item.action.actor.Id,
                        handle: item.action.actor.handle,
                        fromList: true,
                        refererActionId: item.action.Id,
                        hostUserId: myContext.Id,
                      });
                    }}
                  >
                    <View style={{ alignSelf: "flex-start" }}>
                      <Avatar
                        item={item.action.actor}
                        style={{
                          height: 30,
                          width: 30,
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 15,
                          backgroundColor: colors.primaryButtonBackground,
                        }}
                        textstyle={{
                          fontSize: 15,
                          justifyContent: "center",
                          alignContent: "center",
                          textAlign: "center",
                          color: colors.primaryButtonText,
                        }}
                        myContext={myContext}
                      />
                    </View>
                  </Pressable>
                )}

                <View style={{ flex: 1, paddingLeft: 20 }}>
                  {((item.action?.objectType == "Pin" &&
                    item.justificationTarget?.content) ||
                    item.action?.objectType == "Comment") &&
                    item.action?.operation == "Like" && (
                      <Pressable
                        onPress={() => {
                          clog("WILL SEND TO", item.target);
                          let url = item.target;
                          url["topActions"] = findTopActions(
                            url,
                            myContext,
                            url.actionParams
                          );
                          // ItemDetails screen dedups, so there is no need to dedup here

                          let details = detailsFromAction(
                            item.action,
                            myContext,
                            myContext.Id
                          );
                          let extraAction = {
                            action: item.action,
                            target: item.target,
                            details: details,
                          };
                          clog("DETAILS FROM ACTION", details, item.action);
                          /*extraAction["details"] = details;*/
                          //url.topActions.unshift(extraAction);
                          url["justificationAction"] = {
                            action: item.action,
                            target: item.justificationTarget,
                          };
                          clog("URL DETAILS:", item.target);
                          logEvent("Notification_Content", {
                            userId: myContext?.Id,
                            username: myContext?.handle,
                            category: "notification",
                            platform: Platform.OS,
                            target: "content",
                            action: "click",
                            appVersion: myContext.appVersion,
                          });
                          navigation.push("Comments", {
                            url: item.target,
                            curator: item.action.actor,
                            refererActionId: item.action.Id,
                            hostUserId: null,
                            origin: "notification",
                            notFolded: true,
                            incomplete: true,
                          });
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            paddingRight: 20,
                          }}
                        >
                          <Text
                            style={{
                              color: colors.secondaryText,
                              fontSize: 15,
                            }}
                          >
                            <Text
                              style={{
                                color: colors.primaryText,
                                fontWeight: "700",
                              }}
                            >
                              {item.action.actor.name
                                ? item.action.actor.name
                                : "@" + item.action.actor.handle}
                            </Text>
                            {" liked your " +
                              (item.action.objectType == "Pin"
                                ? "pin"
                                : "comment") +
                              ": "}
                          </Text>
                          {item.justificationTarget.markup ? (
                            <View>
                              <ShareNote
                                json={JSON.parse(
                                  item.justificationTarget.content
                                )}
                                colors={colors}
                                myContext={myContext}
                                seed={hashCode(item.Id)}
                                item={item}
                                origin={"notification"}
                                navigation={navigation}
                                contentModalFunction={contentModalFunction}
                              />
                              <Text style={styles.elapsed}>
                                {formatTime(item.action.createdAt, currentTime)}
                              </Text>
                            </View>
                          ) : (
                            <Text
                              style={{
                                color: colors.primaryText,
                                flex: 1,
                                flexWrap: "wrap",
                                fontSize: 16,
                              }}
                            >
                              {item.justificationTarget.content
                                ? item.justificationTarget.content
                                : "" + " "}
                              <Text style={styles.elapsed}>
                                {formatTime(item.action.createdAt, currentTime)}
                              </Text>
                            </Text>
                          )}
                        </View>
                      </Pressable>
                    )}
                  {(((item.action?.objectType == "Comment" ||
                    item.action?.objectType == "Pin") &&
                    item.action?.operation == "Create") ||
                    (item.action?.objectType == "Pin" &&
                      item.action?.operation == "Like" &&
                      !item.justificationTarget?.content)) && (
                    <Pressable
                      onPress={() => {
                        clog("WILL SEND TO", item.target);
                        let url = item.target;
                        url["topActions"] = findTopActions(
                          url,
                          myContext,
                          url.actionParams
                        );
                        let details = detailsFromAction(
                          item.action,
                          myContext,
                          myContext.Id
                        );
                        let extraAction = {
                          action: item.action,
                          target: item.justificationTarget,
                          details: details,
                        };
                        clog("DETAILS FROM ACTION", details, item.action);
                        /*extraAction["details"] = details;*/
                        //url.topActions.unshift(extraAction);

                        url["justificationAction"] = {
                          action: item.action,
                          target: item.justificationTarget,
                        };
                        logEvent("Notification_Content", {
                          userId: myContext?.Id,
                          username: myContext?.handle,
                          category: "notification",
                          platform: Platform.OS,
                          target: "content",
                          action: "click",
                          appVersion: myContext.appVersion,
                        });

                        clog("URL DETAILS:", item.target);

                        navigation.push(
                          item.action.objectType == "Comment" ||
                            (item.action.objectType == "Pin" &&
                              item.action.operation == "Like" &&
                              !item.justificationTarget.content)
                            ? "Comments"
                            : "ItemDetails",
                          {
                            url: item.target,
                            curator: item.action.actor,
                            refererActionId: item.action.Id,
                            hostUserId: null,
                            origin: "notification",
                            notFolded: true,
                            incomplete: true,
                          }
                        );
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              color: colors.secondaryText,
                              fontSize: 15,
                            }}
                          >
                            <Text
                              style={{
                                color: colors.primaryText,
                                fontSize: 15,
                                fontWeight: "700",
                              }}
                            >
                              {item.action.actor.name
                                ? item.action.actor.name
                                : "@" + item.action.actor.handle}
                            </Text>
                            {item.action.objectType == "Comment"
                              ? item.mention
                                ? " mentioned you on: "
                                : " commented on: "
                              : item.action.objectType == "Pin" &&
                                item.action.operation == "Like" &&
                                !item.justificationTarget.content
                              ? " liked your pin: "
                              : " pinned: "}
                          </Text>
                          <Text
                            style={{
                              color: colors.primaryText,
                              flex: 1,
                              flexWrap: "wrap",
                              fontSize: 15,
                            }}
                          >
                            <Text
                              style={{
                                color: colors.primaryText,
                                flex: 1,
                                flexWrap: "wrap",
                                fontSize: 15,
                                fontWeight: "700",
                              }}
                            >
                              {item.target.title + ". "}
                            </Text>
                            <Text style={styles.elapsed}>
                              {formatTime(item.action.createdAt, currentTime)}
                            </Text>
                          </Text>
                        </View>
                        <View
                          style={{
                            paddingRight: 20,
                            paddingLeft: 16,
                          }}
                        >
                          <Image
                            style={{ height: 50, width: 50, borderRadius: 8 }}
                            source={{
                              uri: item.target.screenshotUrl,
                            }}
                          />
                        </View>
                      </View>
                    </Pressable>
                  )}
                  {item.action?.objectType == "User" && (
                    <OneUserNotification
                      item={item}
                      myContext={myContext}
                      navigation={navigation}
                      colors={colors}
                      setState={setState}
                      currentTime={currentTime}
                    />
                  )}
                  {item.objectType == "DailyBest" && (
                    <DailyBest
                      item={item}
                      colors={colors}
                      navigation={navigation}
                      currentTime={currentTime}
                    />
                  )}
                </View>
              </View>
            );
          }}
          keyExtractor={(item) => {
            //clog("asked to extract key for", item);
            return item.action?.Id ? item.action.Id : item.Id;
          }}
          numColumns={1}
          ListEmptyComponent={
            loading ? <LoadingView /> : <ListEmptyComponent />
          }
          ListFooterComponent={() =>
            Platform.OS !== "web" ? <View style={{ height: 70 }} /> : null
          }
        />

        <Modal
          visible={webModalVisible}
          onRequestClose={() => {
            setWebModalVisible(false);
          }}
        >
          <SafeAreaView style={styles.container}>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  backgroundColor: colors.background,
                  height: 39,
                  paddingVertical: 10,
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Pressable
                  onPress={() => {
                    setWebModalVisible(false);
                  }}
                >
                  <Image
                    source={cross}
                    style={{
                      marginRight: 20,
                      height: 19,
                      width: 17,
                      tintColor: colors.primaryText,
                    }}
                  />
                </Pressable>
              </View>
              <WebView
                style={{ flex: 1 }}
                source={{ uri: webModalUrl }}
                ref={(r) => (webref = r)}
                originWhitelist={["*"]}
                javaScriptEnabled={true}
                domStorageEnabled={true}
              />
            </View>
          </SafeAreaView>
        </Modal>
      </View>
    </View>
  );
};

export default NotificationScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  coloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
  },
  uncoloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
    borderWidth: 2,
  },
  text: { fontSize: 12 },
  elapsed: { color: "rgb(173,173,173)", fontSize: 14 },
  containerListEmpty: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  textListEmpty: {
    fontSize: 15,
    marginTop: 16,
  },
});
