import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Alert,
  Dimensions,
  Keyboard,
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";

import { timeStamp } from "../utils/TimeStamp";
import { uploadPhoto } from "../utils/Photo";

import { API, graphqlOperation } from "aws-amplify";

import AwesomeAlert from "react-native-awesome-alerts";
import AppContext from "../components/AppContext";
import { sharedStyles, villageColors } from "../utils/SharedStyles";
import Blocker from "../components/Blocker";
import LeftArrow from "../components/LeftArrow";
import ColoredButton from "../components/ColoredButton";
import plus from "../assets/plus.png";
import edit from "../assets/edit.png";
import cross from "../assets/cross.png";

import {
  updateUrl,
  updateUserCounts,
  updateListCounts,
  updateTopicCounts,
  getUrlForUpdate,
  updatePin,
} from "../src/graphql/custom";

import { ScrollView } from "react-native-gesture-handler";
import { addIds, removeIds } from "../utils/IdList";
import Item from "../components/Item";
import ItemCompact from "../components/ItemCompact";
import { clog, elog } from "../utils/Log";
import { getTopics } from "../controllers/TopicController";
import { useTheme } from "../theme";
import { uploadPhotoWeb } from "../utils/PhotoWeb";
import TopicChooser from "../components/TopicChooser";
import { serializationDataOfTopics } from "../utils/SerializationDataOfTopics";
import ShareNoteEditor from "../components/ShareNoteEditor";
import WebView from "react-native-webview";
import { Image } from "react-native";
import { Modal } from "react-native";
import { Portal } from "react-native-portalize";
import { Modalize } from "react-native-modalize";
import CloseButton from "../components/CloseButton";
import { KeyboardSpacer } from "../utils/Keyboard";
import ButtonBar from "../components/ButtonBar";
import { useSafeAreaInsets } from "react-native-safe-area-context";

function UrlMetadataUpdateScreen({ route, navigation }) {
  async function getTopicData() {
    await getTopics({
      myContext: myContext,
      callback: ({ success, message, error, topicData }) => {
        if (success) {
          serializationDataOfTopics({
            data: topicData,
            onboarding: false,
            callback: (topics) => setTopicOptions(topics),
          });
          let ids = {};
          clog("TOPIC IDS", topicIds);
          topicIds?.split(",")?.forEach((tuple) => {
            if (tuple) {
              let parts = tuple.split(":");
              if (parts.length > 0) {
                let topicId = parts[0];
                if (topicData[parts[0]]?.depth > 0) {
                  ids[topicId] = true;
                }
              }
            }
          });
          setTopicMap(ids);
        } else {
          clog(message);
        }
      },
    });
  }

  async function getUrlData(Id) {
    clog("will get url data");
    try {
      const urlData = await API.graphql(
        graphqlOperation(getUrlForUpdate, { Id: Id })
      );
      clog("urlData:", urlData);
      let details = {};
      let weights = {};
      urlData.data.getUrl?.pins?.items?.forEach((pin) => {
        if (pin?.curator?.Id) {
          if (!details[pin.curator.Id]) {
            details[pin.curator.Id] = {
              Id: pin.curator.Id,
              objectType: "User",
              object: pin.curator,
            };
          }
          if (!weights[pin.curator.Id]) {
            weights[pin.curator.Id] = 1;
          } else {
            weights[pin.curator.Id]++;
          }
        }
        if (pin?.list?.Id) {
          if (!details[pin.list.Id]) {
            details[pin.list.Id] = {
              Id: pin.list.Id,
              objectType: "List",
              object: pin.list,
            };
          }
          if (!weights[pin.list.Id]) {
            weights[pin.list.Id] = 1;
          } else {
            weights[pin.list.Id]++;
          }
        }
      });
      clog("Object weights", weights);
      clog("Object Details", details);
      setObjectDetails(details);
      setObjectWeights(weights);
    } catch (err) {
      console.log("error fetching url data...", err);
      elog(
        myContext.Id,
        "url metadata update",
        "fetching url data",
        err.message
      );
    }
  }

  async function updateUrlMetadata() {
    setLoading(true);
    try {
      let urlObject = { Id: route.params.url.Id };
      let photoChanged = false;
      clog("will do something");
      if (title != route.params.url.title) {
        urlObject["title"] = title;
        clog("Changed title");
      }
      if (authorName != route.params.url.authorName) {
        urlObject["authorName"] = authorName;
        clog("Changed author");
      }
      if (duration != route.params.url.duration) {
        urlObject["duration"] = duration;
        clog("Changed duration");
      }
      if (photoUrl && photoUrl != route.params.url.photoUrl) {
        clog("changed photo url");
        photoChanged = true;
        // Need to upload the photo
        let success = null;
        let key = null;

        let response;
        if (Platform.OS === "web") {
          response = await uploadPhotoWeb("submission/", photoUrl);
        } else {
          response = await uploadPhoto("submission/", photoUrl);
        }

        success = response[0];
        key = response[1];
        clog("back from upload photo with", success, key, response);
        if (success && key) {
          urlObject["photo"] = key;
        } else {
          urlObject["photoUrl"] = photoUrl;
        }
      }
      let ids = {};
      route.params.url?.topicIds?.split(",")?.forEach((tuple) => {
        let parts = tuple.split(":");
        if (parts.length > 0) {
          let topicId = parts[0];
          ids[topicId] = true;
        }
      });
      let additions = {};
      let removals = {};
      let added = false;
      let removed = false;

      let expandedMap = {};
      Object.keys(topicMap).forEach((t) => {
        expandedMap[t] = true;
        if (
          myContext?.topics?.[t]?.parentTopicId &&
          myContext.topics[t].parentTopicId != "Topic066"
        ) {
          expandedMap[myContext?.topics?.[t]?.parentTopicId] = true;
        }
      });

      Object.keys(ids).forEach((Id) => {
        if (!expandedMap[Id]) {
          removals[Id] = true;
          removed = true;
        }
      });
      Object.keys(expandedMap).forEach((Id) => {
        if (!ids[Id]) {
          additions[Id] = true;
          added = true;
        }
      });
      let promises = [];

      const tldr = getTLDR();

      let pin = {
        Id: topPin.Id,
        content: richShareNote ? JSON.stringify(richShareNote) : "",
        tldr: tldr ? JSON.stringify(tldr) : null,
        markup: richShareNote ? true : false,
      };

      promises.push(API.graphql(graphqlOperation(updatePin, pin)));

      if (added || removed) {
        clog("added:", added, additions, "removed:", removed, removals);
        urlObject["topicIds"] = route.params.url.topicIds;
        if (added) {
          urlObject["topicIds"] = addIds(
            urlObject["topicIds"],
            Object.keys(additions)
          );
        }
        if (removed) {
          urlObject["topicIds"] = removeIds(
            urlObject["topicIds"],
            Object.keys(removals)
          );
        }
        // Need to update topicIds for curators of pins
        // Need to update topicIds for lists holding pins involving the url
        Object.values(objectDetails).forEach((item) => {
          let topicIds = item.object.topicIds;
          if (added) {
            topicIds = addIds(
              topicIds,
              Object.keys(additions),
              objectWeights[item.Id]
            );
          }
          if (removed) {
            topicIds = removeIds(
              topicIds,
              Object.keys(removals),
              objectWeights[item.Id]
            );
          }
          promises.push(
            API.graphql(
              graphqlOperation(
                item.objectType == "User" ? updateUserCounts : updateListCounts,
                {
                  Id: item.Id,
                  topicIds: topicIds,
                }
              )
            )
          );
        });
        Object.values(objectDetails).forEach((item) => {
          if (item.objectType == "List") {
            let existingListTopicIds = {};
            item.object.topics.items.forEach((e) => {
              if (e.topic != null) {
                existingListTopicIds[e.topic.Id] = 1;
              }
            });
          }
        });

        // Need to update numUrls of topics themselves
        topicOptions.forEach((t) => {
          if (additions[t.Id] || removals[t.Id]) {
            promises.push(
              API.graphql(
                graphqlOperation(updateTopicCounts, {
                  Id: t.Id,
                  numUrls: t.numUrls + (additions[t.Id] ? 1 : -1),
                })
              )
            );
          }
        });
      } else {
        // We only need to update the Url and no need to change topicIds for users and lists
      }
      urlObject["updateTS"] = timeStamp();
      clog("urlObject", urlObject);
      promises.push(API.graphql(graphqlOperation(updateUrl, urlObject)));
      let responses = await Promise.all(promises);
      clog("Responses", responses);
      myContext.version++;
      setLoading(false);
      navigation.goBack();
    } catch (err) {
      console.log("error", err);
      elog(
        myContext.Id,
        "url metadata update",
        "update url metadata",
        err.message
      );

      showMessage("Failed to update url metadata");
      setLoading(false);
    }
  }

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const handleSubmit = () => {
    if (inflight) {
      return;
    }
    let message = "";
    if (!url) {
      message = "Url is required";
    }

    if (message) {
      showMessage(message);
    } else {
      updateUrlMetadata();
    }
  };

  const { colors } = useTheme();
  const [url, setUrl] = useState(route.params.url.uri);
  const [title, setTitle] = useState(route.params.url.title);
  const [topicIds, setTopicIds] = useState(route.params.url.topicIds);
  const [content, setContent] = useState(route.params.url.content);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");
  const [photoUrl, setPhotoUrl] = useState(route.params.url.photoUrl);
  const [duration, setDuration] = useState(route.params.url.duration);
  const [source, setSource] = useState(route.params.url.source);
  const [screenshotUrl, setScreenshotUrl] = useState(
    route.params.url.screenshotUrl
  );
  const [type, setType] = useState(route.params.url.type);
  const [author, setAuthor] = useState(route.params.url.author);
  const [authorName, setAuthorName] = useState(
    route.params.url.author?.name
      ? route.params.url.author.name
      : route.params.url.authorName
  );
  const [topActions, setTopActions] = useState(route.params.url.topActions);
  const [topicOptions, setTopicOptions] = useState(null);
  const [topicMap, setTopicMap] = useState({});
  const [objectDetails, setObjectDetails] = useState({});
  const [objectWeights, setObjectWeights] = useState({});

  const [inflight, setInflight] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const myContext = useContext(AppContext);

  const shareNoteEditorRef = useRef();
  const topicsRef = useRef(null);

  const [richShareNote, setRichShareNote] = useState(null);
  const [tldrQuotes, setTldrQuotes] = useState(null);
  const [tldrMainInsights, setTldrMainInsights] = useState(null);
  const [tldrSummary, setTldrSummary] = useState(null);
  const [tldrMissingPoints, setTldrMissingPoints] = useState(null);

  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");

  const [bold, setBold] = useState(false);
  const [list, setList] = useState(false);
  const [quote, setQuote] = useState(false);
  const [length, setLength] = useState(0);

  const [topPin, setTopPin] = useState(route.params.topPin);

  const { width, height } = Dimensions.get("window");
  const insets = useSafeAreaInsets();

  useEffect(() => {
    let topPinJSON = null;

    if (topPin?.markup) {
      try {
        topPinJSON = JSON.parse(topPin?.content);
      } catch (err) {
        topPin["content"] = "";
        topPin.markup = false;
      }
    }

    setRichShareNote(topPinJSON);

    if (topPin?.tldr) {
      let tldrJSON = {};

      try {
        tldrJSON = JSON.parse(topPin?.tldr);
      } catch (err) {
        console.log("Error parse:", err);
      }

      if (tldrJSON.data.mainInsights) {
        setTldrMainInsights(tldrJSON.data.mainInsights);
      }
      if (tldrJSON.data.missingPoints) {
        setTldrMissingPoints(tldrJSON.data.missingPoints);
      }
      if (tldrJSON.data.quotes) {
        setTldrQuotes(tldrJSON.data.quotes);
      }
      if (tldrJSON.data.summary) {
        setTldrSummary(tldrJSON.data.summary);
      }
    }
  }, []);

  const getTLDR = () => {
    let _tldr;

    if (tldrMainInsights || tldrQuotes || tldrSummary || tldrMissingPoints) {
      _tldr = {
        type: "TLDR",
        data: {
          summary: tldrSummary,
          mainInsights: tldrMainInsights,
          missingPoints: tldrMissingPoints,
          quotes: tldrQuotes,
        },
      };
    } else {
      _tldr = null;
    }

    return _tldr;
  };

  const setTldrProperty = (data, type) => {
    let note;

    if (data !== null) {
      note = {
        type: "Note",
        data: data,
        fallback: "",
      };
    } else {
      note = null;
    }

    switch (type) {
      case "summary":
        setTldrSummary(note);
        break;
      case "mainInsights":
        setTldrMainInsights(note);
        break;
      case "quotes":
        setTldrQuotes(note);
        break;
      case "missingPoints":
        setTldrMissingPoints(note);
        break;
    }
  };

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  ////clog("navigation is in user details ", navigation);
  clog("route is ", route);
  //clog("context is ", myContext);

  useEffect(() => {
    clog("will FETCH DATA for", route.params.url);
    getTopicData();
    getUrlData(route.params.url.Id);
  }, [url]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>
        );
      },
      headerTitle: "Update Url Metadata",
      headerTitleStyle: {
        fontSize: 15,
        fontWeight: "700",
        lineHeight: 22,
        color: colors.primaryText,
      },
      headerBackTitleVisible: false,
      headerStyle: {
        backgroundColor: colors.background,
      },
      headerRight: () => {
        return (
          <View style={{ marginRight: 12 }}>
            <ColoredButton
              onPress={handleSubmit}
              buttonStyle={[
                styles.updateButton,
                {
                  backgroundColor: colors.primaryButtonBackground,
                },
              ]}
              textStyle={[
                styles.updateButtonTitle,
                {
                  color: colors.primaryButtonText,
                },
              ]}
              text={"Update"}
            />
          </View>
        );
      },
    });
  }, [
    navigation,
    authorName,
    title,
    duration,
    photoUrl,
    richShareNote,
    tldrMainInsights,
    tldrQuotes,
    tldrSummary,
    tldrMissingPoints,
    topicMap,
  ]);

  const contentModalFunction = (target) => {
    clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  return (
    <View
      style={[sharedStyles.container, { backgroundColor: colors.background }]}
    >
      <ScrollView
        style={{ flex: 1, width: "100%", maxWidth: 756, paddingHorizontal: 20 }}
      >
        <Blocker loading={loading} />
        {true && (
          <View style={styles.inputContainer}>
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 16,
                fontWeight: "400",
                marginTop: 10,
                marginBottom: 8,
              }}
            >
              Title
            </Text>
            <TextInput
              style={[
                sharedStyles.textInput,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                },
              ]}
              value={title}
              maxLength={200}
              autoCapitalize={"none"}
              onChangeText={(newValue) => setTitle(newValue)}
              onBlur={() => {
                clog("blurred");
                Keyboard.dismiss;
              }}
            />
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 16,
                fontWeight: "400",
                marginTop: 10,
                marginBottom: 8,
              }}
            >
              Author
            </Text>
            <TextInput
              style={[
                sharedStyles.textInput,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                },
              ]}
              value={authorName}
              maxLength={200}
              autoCapitalize={"none"}
              onChangeText={(newValue) => setAuthorName(newValue)}
              onBlur={() => {
                clog("blurred");
                Keyboard.dismiss;
              }}
            />
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 16,
                fontWeight: "400",
                marginTop: 10,
                marginBottom: 8,
              }}
            >
              Duration
            </Text>
            <TextInput
              style={[
                sharedStyles.textInput,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                },
              ]}
              value={duration}
              maxLength={200}
              autoCapitalize={"none"}
              onChangeText={(newValue) => setDuration(newValue)}
              onBlur={() => {
                clog("blurred");
                Keyboard.dismiss;
              }}
            />
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 16,
                fontWeight: "400",
                marginTop: 10,
                marginBottom: 8,
              }}
            >
              Photo Url
            </Text>
            <TextInput
              style={[
                sharedStyles.textInput,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                },
              ]}
              value={photoUrl}
              maxLength={200}
              autoCapitalize={"none"}
              onChangeText={(newValue) => setPhotoUrl(newValue)}
              onBlur={() => {
                clog("blurred");
                Keyboard.dismiss;
              }}
            />
          </View>
        )}
        {true && (
          <ScrollView
            style={{
              width: "100%",
            }}
          >
            <View
              style={{
                marginTop: 5,
                padding: 5,
                backgroundColor: colors.background,
              }}
            >
              <ItemCompact
                item={{
                  Id: "1",
                  uri: url,
                  title: title,
                  photoUrl: photoUrl ? photoUrl : screenshotUrl,
                  duration: duration,
                  author: author,
                  authorName: authorName,
                  source: source,
                  topActions: topActions,
                }}
                myContext={myContext}
              />
            </View>
            <Item
              item={{
                Id: "1",
                uri: url,
                title: title,
                photoUrl: photoUrl ? photoUrl : screenshotUrl,
                duration: duration,
                author: author,
                authorName: authorName,
                source: source,
                topActions: topActions,
                origin: "UrlMetadataUpdate",
                topicIds: topicIds,
              }}
              myContext={myContext}
              itemStyle={{
                flex: 1,
                paddingBottom: 20,
                borderRadius: 20,
                overflow: "hidden",
                width: "100%",
                backgroundColor: colors.secondaryBackground,
              }}
              index={0}
              colors={colors}
            />
          </ScrollView>
        )}

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Pressable
            onPress={() => topicsRef.current?.open()}
            style={{
              padding: 12,
              backgroundColor: colors.secondaryBackground,
              borderRadius: 100,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {Object.keys(topicMap).length > 0 ? (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={edit}
                  style={{
                    marginRight: 5,
                    height: 12,
                    width: 12,
                    tintColor: colors.secondaryButtonText,
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "700",
                    lineHeight: 15,
                    color: colors.secondaryButtonText,
                  }}
                >
                  {Object.keys(topicMap).length + " "}
                </Text>
              </View>
            ) : (
              <Image
                source={plus}
                style={{
                  marginRight: 7,
                  height: 12,
                  width: 12,
                  tintColor: colors.secondaryButtonText,
                }}
              />
            )}
            <Text
              style={{
                fontSize: 12,
                fontWeight: "700",
                lineHeight: 15,
                color: colors.secondaryButtonText,
              }}
            >
              {"Topics"}
            </Text>
          </Pressable>
        </View>

        <ShareNoteEditor
          ref={shareNoteEditorRef}
          myContext={myContext}
          navigation={navigation}
          colors={colors}
          richShareNote={richShareNote}
          setRichShareNote={setRichShareNote}
          contentModalFunction={contentModalFunction}
          tldrSummary={tldrSummary}
          tldrMainInsights={tldrMainInsights}
          tldrQuotes={tldrQuotes}
          tldrMissingPoints={tldrMissingPoints}
          setTldrProperty={setTldrProperty}
          bold={bold}
          setBold={setBold}
          list={list}
          setList={setList}
          quote={quote}
          setQuote={setQuote}
          setLength={setLength}
          fullScreen={true}
        />

        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title=""
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          cancelText="Ok"
          cancelButtonColor={colors.cancelButton}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
        />
      </ScrollView>

      {myContext.actionsByUser?.["Experiment"]?.["Join"]?.["RichShareNote"] !=
        null && (
        <ButtonBar
          myContext={myContext}
          richShareNote={richShareNote}
          colors={colors}
          shareNoteEditorRef={shareNoteEditorRef}
          bold={bold}
          setBold={setBold}
          list={list}
          setList={setList}
          quote={quote}
          setQuote={setQuote}
          length={length}
        />
      )}
      <KeyboardSpacer topSpacing={Platform.OS !== "android" ? -10 : -400} />

      <Modal
        visible={webModalVisible}
        onRequestClose={() => {
          setWebModalVisible(false);
        }}
      >
        <SafeAreaView style={styles.container}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
            }}
          >
            <View
              style={{
                backgroundColor: colors.background,
                height: 39,
                paddingVertical: 10,
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pressable
                onPress={() => {
                  setWebModalVisible(false);
                }}
              >
                <Image
                  source={cross}
                  style={{
                    marginRight: 20,
                    height: 19,
                    width: 17,
                    tintColor: colors.primaryText,
                  }}
                />
              </Pressable>
            </View>
            <WebView
              style={{ flex: 1 }}
              source={{ uri: webModalUrl }}
              ref={(r) => (webref = r)}
              originWhitelist={["*"]}
              javaScriptEnabled={true}
              domStorageEnabled={true}
            />
          </View>
        </SafeAreaView>
      </Modal>

      <Portal>
        <Modalize
          ref={topicsRef}
          modalHeight={height * 0.5}
          modalStyle={[
            styles.viewModal,
            {
              backgroundColor: colors.background,
            },
          ]}
          scrollViewProps={{ showsVerticalScrollIndicator: false }}
          withHandle={false}
          HeaderComponent={() => (
            <View style={styles.headerModal}>
              <Text
                style={[
                  styles.titleHeaderModal,
                  {
                    color: colors.primaryText,
                  },
                ]}
              >
                {"Topic tags"}
              </Text>

              <CloseButton onPress={() => topicsRef.current?.close()} />
            </View>
          )}
          FloatingComponent={() => (
            <View
              style={{
                backgroundColor: colors.background,
                paddingTop: 16,
                paddingHorizontal: 16,
              }}
            >
              <ColoredButton
                onPress={() => {
                  topicsRef.current?.close();
                }}
                buttonStyle={{
                  height: 54,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 100,
                  backgroundColor: colors.primaryButtonBackground,
                }}
                text={"Done"}
                textStyle={{
                  color: colors.primaryButtonText,
                  fontSize: 15,
                  fontWeight: "700",
                  textAlign: "center",
                }}
              />
              <View style={{ height: insets.bottom ? insets.bottom : 10 }} />
            </View>
          )}
        >
          <TopicChooser
            topics={topicOptions}
            colors={colors}
            followTopics={topicMap}
            myContext={myContext}
            onPressTopic={({ item, following, setFollowing }) => {
              clog("asked to toggle", item.name, "from", topicMap[item.Id]);
              setFollowing((prev) => {
                return !prev;
              });
              setTopicMap((prev) => {
                let modified = { ...prev };
                if (modified[item.Id]) {
                  delete modified[item.Id];
                } else {
                  modified[item.Id] = true;
                }
                return modified;
              });
              myContext.versionTemp++;
            }}
          />
        </Modalize>
      </Portal>
    </View>
  );
}

export default UrlMetadataUpdateScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inputContainer: {
    width: "100%",
    borderRadius: 8,
  },
  viewModal: {
    flex: 1,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
  },
  headerModal: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 19,
    paddingBottom: 16,
  },
  titleHeaderModal: {
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 20,
  },
  updateButton: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 11,
    borderRadius: 100,
  },
  updateButtonTitle: {
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 15,
    fontWeight: "700",
  },
});
