import { API, graphqlOperation } from "aws-amplify";

import {
  searchUsersByName,
  listUsers,
  searchUrlsByTitle,
  listUrls,
  getSingleUrl,
} from "../src/graphql/custom";
import { batchPresign } from "../utils/Photo";
import { clog, elog } from "../utils/Log";
import {
  handleLookups,
  handleOneUrl,
  dropTestData,
} from "../controllers/CommonFeedController";
import { timeStamp } from "../utils/TimeStamp";
import { generateMapFromIdStrings } from "../utils/IdList";

export async function searchUsers({
  pattern,
  includeSelf,
  myContext,
  callback,
}) {
  console.log("SEARCH with (" + pattern + ")");
  let trimmedPattern = pattern.trim("");
  let status = { success: true, users: [] };
  try {
    let params = {};
    if (trimmedPattern) {
      let parts = trimmedPattern.split(" ");

      if (parts.length == 1) {
        params = {
          filter: {
            name: { matchPhrasePrefix: parts[0] },
          },
        };
      } else if (parts.length >= 2) {
        let conditionArray = [];
        for (let i = 0; i < parts.length - 1; i++) {
          conditionArray.push({ name: { match: parts[i] } });
        }
        conditionArray.push({
          name: {
            matchPhrasePrefix: parts[parts.length - 1],
          },
        });
        params = {
          filter: {
            and: conditionArray,
          },
        };
      }
    }
    params["numRequested"] = 1000;
    clog("params", params);
    let start = performance.now();
    let allData = null;
    let fetchContinue = true;
    let nextToken = null;
    do {
      params["nextToken"] = nextToken;
      const userData = await API.graphql(
        graphqlOperation(pattern ? searchUsersByName : listUsers, params)
      );
      let batchData = null;
      clog("userData:", userData);
      if (userData?.data?.listUsers) {
        batchData = userData.data.listUsers;
      } else if (userData?.data?.searchUsers) {
        batchData = userData.data.searchUsers;
      } else {
        console.log("NO DATA");
      }
      if (batchData?.nextToken) {
        nextToken = batchData.nextToken;
      } else {
        fetchContinue = false;
      }
      if (batchData?.items) {
        if (!allData) {
          allData = [];
        }
        batchData.items.forEach((u) => {
          let metadata = { hasDeclaredTopicIds: false, hasToken: false };
          if (u.declaredTopicIds) {
            metadata["hasDeclaredTopicIds"] = true;
          }
          if (u.token) {
            metadata["hasToken"] = true;
          }
          metadata["u"] = u;
          allData.push(metadata);
        });
      }
    } while (fetchContinue);
    let uniqueData = null;
    if (allData) {
      console.log("TOTAL USERS", allData.length);
      uniqueData = [];
      let added = {};
      allData
        .sort((a, b) =>
          a.declaredTopicIds != b.declaredTopicIds
            ? a.declaredTopicIds
              ? -1
              : 1
            : a.hasToken != b.hasToken
            ? a.hasToken
              ? -1
              : 1
            : a.u.Id.localeCompare(b.u.Id) < 0
            ? -1
            : 1
        )
        .forEach((meta) => {
          if (!added[meta?.u?.handle]) {
            added[meta.u.handle] = meta;
            uniqueData.push(meta.u);
          } else {
            clog("DROPPED", meta, "BECAUSE OF", added[meta.u.handle]);
          }
        });
    }
    let untilFetch = performance.now();
    console.log("TIME: fetch", (untilFetch - start) / 1000);
    if (uniqueData) {
      console.log("TOTAL UNIQUE USERS", uniqueData.length);
      ////clog("user data is ", data);
      // suppress duplicate users
      let data = [];
      let users = [];
      console.log("All data", uniqueData.length);
      uniqueData
        .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
        .forEach((element) => {
          if (myContext?.actions?.["User"]?.["Block"]?.[element.Id]) {
            console.log("hide blocker user", element);
            return;
          }
          data.push(element);
        });
      let followedTopics = [];
      // NOTE(alpha): Access keys only
      if (myContext.topics && myContext.declaredTopicIds) {
        let followedTopicIds = generateMapFromIdStrings([
          myContext.declaredTopicIds,
        ]);
        Object.values(myContext.topics)
          .map(function (x) {
            clog("returning one item", x);
            let Id = x.Id;
            let name = x.name;
            let frequency = x.numUrls;
            let avatar = x.avatar;
            // NOTE(alpha): keys only
            if (followedTopicIds[Id]) {
              return {
                Id: Id,
                name: name,
                frequency: frequency,
                avatar: avatar,
              };
            }
          })
          .sort((a, b) =>
            a.frequency != b.frequency
              ? a.frequency > b.frequency
                ? -1
                : 1
              : a.name < b.name
              ? -1
              : 1
          )
          .forEach((t) => {
            if (t && t.Id) {
              followedTopics.push(t);
            }
          });
      }

      if (followedTopics.length == 0) {
        followedTopics = [
          { Id: "Topic013" },
          { Id: "Topic001" },
          { Id: "Topic026" },
          { Id: "Topic014" },
          { Id: "Topic022" },
        ];
      }
      let declaredTopics = {};
      followedTopics.forEach((topic) => {
        declaredTopics[topic.Id] = true;
      });
      clog("declared topics", declaredTopics);
      let matchTotalScores = {}; // total number of content overlapping
      let matchTopicScores = {}; // total number of topics overlapping
      let mayNeedLookUp = {};
      data.forEach((u) => {
        let totalScore = 0;
        let topicScore = 0;
        if (u.declaredTopicIds) {
          //console.log("topic ids", u.topicIds);
          let tuples = u.declaredTopicIds.split(",");
          tuples.forEach((tuple) => {
            let parts = tuple.split(":");
            if (declaredTopics[parts[0]]) {
              topicScore++;
            }
          });
        }
        if (u.curatedTopicIds) {
          //console.log("topic ids", u.topicIds);
          let tuples = u.curatedTopicIds.split(",");
          tuples.forEach((tuple) => {
            let parts = tuple.split(":");
            if (declaredTopics[parts[0]]) {
              if (parts.length == 2) {
                totalScore += Number(parts[1]);
              } else {
                totalScore++;
              }
            }
          });
        }
        matchTotalScores[u.Id] = totalScore;
        matchTopicScores[u.Id] = topicScore;
        clog("SCORE", u.handle, topicScore, totalScore);
      });
      let numSelected = 0;
      let numTargeted = 100;
      data
        .sort((a, b) =>
          matchTotalScores[a.Id] != matchTotalScores[b.Id]
            ? matchTotalScores[a.Id] < matchTotalScores[b.Id]
              ? 1
              : -1
            : matchTopicScores[a.Id] != matchTopicScores[b.Id]
            ? matchTopicScores[a.Id] < matchTopicScores[b.Id]
              ? 1
              : -1
            : a.name != b.name
            ? a.name > b.name
              ? 1
              : -1
            : a.handle != b.handle
            ? a.handle > b.handle
            : a.Id < b.Id
        )
        .forEach((element) => {
          if (
            numSelected < numTargeted &&
            !element.label?.match("test") &&
            !element.handle?.match("test123") &&
            (myContext.Id != element.Id || includeSelf)
          ) {
            for (let index in element) {
              if (index.startsWith("num") && element[index] == null) {
                element[index] = 0;
              }
            }
            users.push(element);
            numSelected++;
            clog(
              "ADDED",
              element.name,
              "total score",
              matchTotalScores[element.Id],
              "topic score",
              matchTopicScores[element.Id]
            );
            if (element.avatar) {
              mayNeedLookUp[element.avatar] = true;
            }
            if (!myContext.users[element.Id]) {
              myContext.users[element.Id] = element;
            }
          }
        });
      ////clog("final users", users);
      status.users = users;
      let untilScore = performance.now();

      console.log("TIME: score", (untilScore - untilFetch) / 1000);
      await batchPresign(
        Object.keys(mayNeedLookUp),
        myContext.presignedUrls,
        null
      );
      let untilSign = performance.now();
      console.log("TIME: sign", (untilSign - untilScore) / 1000);
    }
  } catch (err) {
    status.success = false;
    status["message"] = "error fetching users...";
    status["error"] = err;
    console.log("error fetching users...", err);
    elog(myContext.Id, "search", "data fetch", err.message);
  }
  if (callback) {
    callback(status);
  }
  return status;
}

export async function searchUrls({ pattern, myContext, callback }) {
  console.log("SEARCH with (" + pattern + ")");
  let trimmedPattern = pattern.trim("");
  let status = { success: true, urls: [] };
  let searching = false;
  try {
    let params = {};
    if (trimmedPattern) {
      let parts = trimmedPattern.split(" ");

      if (parts.length == 1) {
        if (parts[0]?.length >= 2) {
          params = {
            filter: {
              title: { match: parts[0] },
            },
          };
          searching = true;
        }
      } else if (parts.length >= 2) {
        let conditionArray = [];
        for (let i = 0; i < parts.length - 1; i++) {
          conditionArray.push({ title: { match: parts[i] } });
        }
        conditionArray.push({
          title: {
            matchPhrasePrefix: parts[parts.length - 1],
          },
        });
        params = {
          filter: {
            and: conditionArray,
          },
        };
        searching = true;
      }
    }
    console.log("params", params);
    let start = performance.now();
    let data = null;
    let untilFetch = 0;
    if (searching) {
      const urlData = await API.graphql(
        graphqlOperation(searchUrlsByTitle, params)
      );
      untilFetch = performance.now();
      console.log("TIME: fetch", (untilFetch - start) / 1000);
      clog("urlData:", urlData);
      if (urlData?.data?.listUrls) {
        data = urlData.data.listUrls;
      } else if (urlData?.data?.searchUrls) {
        data = urlData.data.searchUrls;
      } else {
        console.log("NO DATA");
      }
    }
    if (data) {
      ////clog("user data is ", data);
      let mayNeedLookUp = {};
      data.items.forEach((element) => {
        //clog("consider", element);
        if (element.photo) {
          mayNeedLookUp[element.photo] = true;
        }
      });
      await batchPresign(
        Object.keys(mayNeedLookUp),
        myContext.presignedUrls,
        null
      );
      let untilSign = performance.now();
      console.log("TIME: sign", (untilSign - untilFetch) / 1000);
      let followedTopics = [];
      // NOTE(alpha): Access keys only
      if (myContext.topics && myContext.declaredTopicIds) {
        let followedTopicIds = generateMapFromIdStrings([
          myContext.declaredTopicIds,
        ]);
        Object.values(myContext.topics)
          .map(function (x) {
            clog("returning one item", x);
            let Id = x.Id;
            let name = x.name;
            let frequency = x.numUrls;
            let avatar = x.avatar;
            // NOTE(alpha): keys only
            if (followedTopicIds[Id]) {
              return {
                Id: Id,
                name: name,
                frequency: frequency,
                avatar: avatar,
              };
            }
          })
          .sort((a, b) =>
            a.frequency != b.frequency
              ? a.frequency > b.frequency
                ? -1
                : 1
              : a.name < b.name
              ? -1
              : 1
          )
          .forEach((t) => {
            if (t && t.Id) {
              followedTopics.push(t);
            }
          });
      }

      if (followedTopics.length == 0) {
        followedTopics = [
          { Id: "Topic013" },
          { Id: "Topic001" },
          { Id: "Topic026" },
          { Id: "Topic014" },
          { Id: "Topic022" },
        ];
      }
      let declaredTopics = {};
      followedTopics.forEach((topic) => {
        declaredTopics[topic.Id] = true;
      });
      clog("declared topics", declaredTopics);
      let matchTotalScores = {}; // total number of content overlapping
      let matchTopicScores = {}; // total number of topics overlapping
      let currentTime = timeStamp();
      let explanations = {};
      data.items.forEach((u) => {
        clog("U", u);
        let totalScore = 0;
        let topicScore = 0;
        let score = 0;
        if (u.topicIds) {
          //console.log("topic ids", u.topicIds);
          let tuples = u.topicIds.split(",");
          tuples.forEach((tuple) => {
            let parts = tuple.split(":");
            if (declaredTopics[parts[0]]) {
              topicScore++;
            }
          });
        }
        let projection = myContext.config.scoringBaselineProjection;
        let completed =
          u.numTotalVisit > myContext.config.scoringRecencyCount
            ? 1
            : u.numTotalVisit / myContext.config.scoringRecencyCount;
        let existing = 0;
        let expected = 0;
        let age = currentTime - u.creationTS;
        if (u.numTotalVisit >= myContext.config.scoringRecencyCount) {
          score = (u.numLongVisit / u.numTotalVisit) * 100;
        } else {
          existing = u.numTotalVisit
            ? (u.numLongVisit / u.numTotalVisit) * completed * 100
            : 0;
          if (age < myContext.config.scoringRecencyInterval) {
            projection = 1;
          } else {
            projection = Math.max(
              1 - Math.floor(age / 86400) * myContext.config.scoringTimeDecay,
              projection
            );
            clog("Age", age, "projection", projection);
            // Do not penalize items that are doing well
            /*projection = Math.max(
            projection,
            c.numTotalVisit ? c.numLongVisit / c.numTotalVisit : 0
            );*/
          }
          expected = projection * (1 - completed) * 100;
          score = existing + expected;
        }
        u["score"] = score;
        explanations[u.Id] = {
          score: score,
          counters: {
            numLongVisit: u.numLongVisit,
            numTotalVisit: u.numTotalVisit,
            numLongView: u.numLongView,
            numTotalView: u.numTotalView,
            completed: completed,
            projection: projection,
            existing: existing,
            expected: expected,
            numPins: u.numPins ? u.numPins : 0,
            numLike: u.numLike ? u.numLike : 0,
            numComment: u.numComment ? u.numComment : 0,
            numView: u.numView ? u.numView : 0,
          },
          contributions: {
            //ncuparticipants: 7.5 * (ncuparticipants - 1),
            //nlparticipants: 15 * nlparticipants,
            //ncparticipants: 15 * ncparticipants,
            //nvparticipants: 2.5 * nvparticipants,
            //nparticipants: 3.75 * (nparticipants - 1),
            numPins: 7.5 * (u.numPins > 1 ? Math.log10(u.numPins - 1) : 0),
            numLike: 7.5 * Math.log10(u.numLike ? u.numLike : 1),
            numComment: 6 * Math.log10(u.numComment ? u.numComment : 1),
            numView: 2 * Math.log10(u.numView ? u.numView : 1),
          },
        };
        matchTotalScores[u.Id] = score;
        matchTopicScores[u.Id] = score;
        clog("SCORE", u.handle, topicScore, totalScore);
      });
      let candidates = [];
      let promises = [];
      data.items
        .sort((a, b) =>
          matchTotalScores[a.Id] != matchTotalScores[b.Id]
            ? matchTotalScores[a.Id] < matchTotalScores[b.Id]
              ? 1
              : -1
            : matchTopicScores[a.Id] != matchTopicScores[b.Id]
            ? matchTopicScores[a.Id] < matchTopicScores[b.Id]
              ? 1
              : -1
            : a.name > b.name
            ? 1
            : -1
        )
        .forEach((element) => {
          for (let index in element) {
            if (index.startsWith("num") && element[index] == null) {
              element[index] = 0;
            }
          }
          if (candidates.length < 100) {
            candidates.push(element);
            promises.push(
              API.graphql(
                graphqlOperation(getSingleUrl, {
                  Id: element.Id,
                })
              )
            );
            clog(
              "ADDED",
              element.name,
              "total score",
              matchTotalScores[element.Id],
              "topic score",
              matchTopicScores[element.Id]
            );
          }
        });
      let sortedPanels = [];
      try {
        let responses = await Promise.all(promises);
        clog("URL RESPONSES", responses);
        let panels = [];
        let unknownUserIds = {};
        let mayNeedLookUp = {};
        responses?.forEach((u) => {
          let element = u?.data?.getUrl;
          handleOneUrl(
            element,
            panels,
            unknownUserIds,
            myContext,
            mayNeedLookUp
          );
        });
        await handleLookups(panels, unknownUserIds, myContext, mayNeedLookUp);

        sortedPanels = dropTestData(myContext, panels, "search");
        clog("AFTER DROPPING", panels.length, "to", sortedPanels.length);
      } catch (err) {
        console.log("ERROR", err);
      }
      ////clog("final users", users);
      status.urls = [];
      sortedPanels.forEach((u) => {
        clog("CONSIDER", u, explanations[u.Id]);
        u["explanation"] = explanations[u.Id];
        if (u?.pins?.length > 0) {
          status.urls.push(u);
        } else {
          console.log("DROPPING URL BECAUSE NO PINS", u.uri);
        }
      });
      let untilScore = performance.now();

      console.log("TIME: score", (untilScore - untilSign) / 1000);
    } else {
      status.urls = [];
    }
    clog("URLS", status.urls);
  } catch (err) {
    status.success = false;
    status["message"] = "error fetching users...";
    status["error"] = err;
    console.log("error fetching users...", err);
    elog(myContext.Id, "search", "data fetch", err.message);
  }

  if (myContext.newSearch) {
    let newSearch = myContext.newSearch;
    myContext["newSearch"] = null;
    return searchUrls({ pattern: newSearch, myContext, callback });
  } else {
    if (callback) {
      callback(status);
    }
    return status;
  }
}
