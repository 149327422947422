import { Platform } from "react-native";
import DOMParser from "react-native-html-parser";
import { clog } from "./Log";

import { apiKeys } from "../src/constants/apiKeys";

export const populateMetadata = async (
  text,
  setTitle,
  setSnippet,
  setType,
  setPhoto,
  setAuthor,
  setMetadataAvailable
) => {
  let response = null;
  try {
    if (
      (Platform.OS == "ios" && text.match("youtube.com")) ||
      (Platform.OS == "android" && text.match("amazon.com"))
    ) {
      clog("will use proxy for youtube on iOS");
      // pick a random key
      let scrapingKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];
      let proxyUrl = `https://app.scrapingbee.com/api/v1/?api_key=${scrapingKey}&url=${encodeURIComponent(
        text
      )}`;
      clog("proxy url", proxyUrl);
      response = await fetch(proxyUrl);
    } else {
      response = await fetch(text);
    }
    clog("successfully fetched");
  } catch (err) {
    console.log("direct fetch failed");
    if (Platform.OS == "web") {
      clog("will try proxy fetch");
      let scrapingKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];
      let proxyUrl = `https://app.scrapingbee.com/api/v1/?api_key=${scrapingKey}&url=${encodeURIComponent(
        text
      )}`;
      clog("proxy url", proxyUrl);
      try {
        response = await fetch(proxyUrl);
        clog("sucessfully fetched using proxy");
      } catch (err) {
        console.log("even proxy did not help with url fetch on web");
        response = null;
      }
    } else {
      clog("fetch failed on non-web platform");
      response = null;
    }
  }
  ////clog("fetch response", response);
  if (response != null) {
    try {
      let content = await response.text();
      //clog("content is", content);
      //clog("successfully fetched content");
      const parser = new DOMParser.DOMParser({
        locator: {},
        errorHandler: {
          warning: function (w) {},
          error: function (e) {},
          fatalError: function (e) {
            console.error(e);
          },
        },
      });
      //clog("GOT CONTENT", content);
      const parsed = parser.parseFromString(content, "text/html");
      //clog("PARSED", parsed);

      let d = extractMetadata(parsed, text);
      clog("Received", d);
      if (d.title) {
        clog("Setting title");
        setTitle(d.title);
      }
      if (d.description) {
        setSnippet(d.description);
      }
      if (d.type) {
        if (d.type == "post") {
          d.type = "Article";
        }
        setType(d.type);
      }
      if (d.image) {
        clog("Setting image", d.image);
        setPhoto(d.image);
      }
      if (d.author) {
        setAuthor(d.author);
      }
    } catch (err) {
      console.log("cannot get text", err);
    }
  }
  setMetadataAvailable(true);
};

function extractMetadata(parsed, currentUrl) {
  let keys = [
    {
      value: "titleText",
      key: [
        "og:title",
        "twitter:title",
        "parsely-title",
        "sailthru.title",
        "nonmetaTitle",
        "bodyTitle",
      ],
    },
    {
      value: "descriptionText",
      key: [
        "og:description",
        "twitter:description",
        "sailthru.description",
        "description",
      ],
    },
    {
      value: "category",
      key: [
        "article:section",
        "parsely-section",
        "article.page",
        "article.section",
      ],
    },
    { value: "topics", key: ["lotame-bbg-topic"] },
    { value: "tag", key: ["article:tag"] },
    { value: "contentTier", key: ["article:content_tier", "article.access"] },
    { value: "opinion", key: ["article:opinion"] },
    {
      value: "image",
      key: [
        "og:image",
        "twitter:image",
        "og:image:secure_url",
        "parsely-image-url",
        "twitter:image:src",
        "thumbnail",
      ],
    },
    { value: "type", key: ["og:type", "parsely-type", "page.content.type"] },
    {
      value: "authorName",
      key: ["parsely-author", "sailthru.author", "Written by", "author", "byl"],
    },
    {
      value: "authorProfile",
      key: ["article:author"],
    },
    {
      value: "userName",
      key: ["profile:username"],
    },
    {
      value: "firstName",
      key: ["profile:first_name"],
    },
    {
      value: "lastName",
      key: ["profile:last_name"],
    },
    {
      value: "keywordsText",
      key: ["keywords", "parsely-tags", "sailthru.tags", "news_keywords"],
    },
    { value: "canonicalUrl", key: ["og:url", "canonical", "parsely-link"] },
    { value: "duration", key: ["Est. reading time"] },
    { value: "wordCount", key: ["article:word_count"] },
    {
      value: "siteName",
      key: ["og:site_name"],
    },
    {
      value: "appName",
      key: [
        "application-name",
        "msapplication-tooltip",
        "twitter:app:name:iphone",
        "al:ios:app_name",
        "al:android:app_name",
        "twitter:app:name:googleplay",
        "al:iphone:app_name",
        "al:ipad:app_name",
      ],
    },
    { value: "siteTweet", key: ["twitter:site"] },
    {
      value: "publicationTime",
      key: [
        "article:published_time",
        "iso-8601-publish-date",
        "parsely-pub-date",
        "sailthru.date",
        "article.published",
        "article.created",
      ],
    },
    {
      value: "modificationTime",
      key: ["article:modified_time", "article.updated"],
    },
  ];

  let goodScriptKeys = {
    "application/ld+json": "class",
  };
  let goodLinkKeys = {
    canonical: "none",
    shortlink: "none",
    icon: "sizes",
    "apple-touch-icon": "sizes",
    "apple-touch-icon-precomposed": "sizes",
    "msapplication-TileImage": "sizes",
    "shortcut icon": "sizes",
  };
  let iconTupleKeys = ["msapplication-TileImage"];
  let iconLinkKeys = [
    "apple-touch-icon",
    "apple-touch-icon-precomposed",
    "icon",
    "shortcut icon",
  ];

  let gtuples = {};
  let gscripts = {};
  let glinks = {};
  let heads = parsed.getElementsByTagName("head");
  clog("HEADS length", heads?.length);
  let metas = null;
  let links = null;
  let scripts = null;
  if (heads && heads.length > 0) {
    let titles = heads[0].getElementsByTagName("title");
    if (titles && titles.length) {
      //clog("TITLE FIELD", titles[0]);
      let title = titles[0].textContent;
      //clog("TITLE", title);
      if (title) {
        gtuples["nonmetaTitle"] = title;
      }
    }
    metas = heads[0].getElementsByTagName("meta");
    links = heads[0].getElementsByTagName("link");
    scripts = heads[0].getElementsByTagName("script");
  }
  if (!metas?.length) {
    metas = parsed.getElementsByTagName("meta");
  }
  if (!links?.length) {
    links = parsed.getElementsByTagName("link");
  }
  if (!scripts?.length) {
    scripts = parsed.getElementsByTagName("script");
  }

  clog("META length", metas?.length);
  for (let i = 0; metas?.length && i < metas.length; i++) {
    let tuples = {};
    //clog("META", i + 1, metas[i]);
    for (let j = 0; j < metas[i].attributes.length; j++) {
      tuples[metas[i].attributes[j].name.toLowerCase()] =
        metas[i].attributes[j].value;
    }
    clog("META", i, "TUPLE", tuples);
    let key = null;
    if (tuples["name"]) {
      key = tuples["name"];
    } else if (tuples["property"]) {
      key = tuples["property"];
    } else if (tuples["http-equiv"]) {
      key = tuples["http-equiv"];
    } else {
      //clog("UNMANAGED KEY", tuples);
    }
    let value = null;
    if (tuples["content"]) {
      value = tuples["content"];
    } else {
      //clog("UNMANAGED VALUE", tuples);
    }
    if (key != null && value != null) {
      if (key == "byl") {
        const regex = /^By\s+/i;
        value = value.replace(regex, "");
      }
      clog("OUTCOME: KEY", key, "VALUE", value);
      if (gtuples[key]) {
        if (typeof gtuples[key] != "object") {
          gtuples[key] = [gtuples[key]];
        }
        gtuples[key].push(value);
      } else {
        gtuples[key] = value;
      }
    }
  }
  for (let i = 0; scripts && i < scripts.length; i++) {
    let tuples = {};
    //clog("SCRIPT", i + 1, scripts[i]);
    for (let j = 0; j < scripts[i].attributes.length; j++) {
      tuples[scripts[i].attributes[j].name.toLowerCase()] =
        scripts[i].attributes[j].value;
    }
    Object.keys(goodScriptKeys).forEach((key) => {
      //clog("KEY", key, "TUPLE", tuples);
      if (tuples.type == key) {
        //clog("GOOD KEY", key);
        let value = tuples[goodScriptKeys[key]];
        let payload = {
          content: scripts[i].textContent,
        };
        payload[goodScriptKeys[key]] = value ? value : "";
        if (gscripts[key]) {
          gscripts[key].push(payload);
        } else {
          gscripts[key] = [payload];
        }
      }
    });
  }
  for (let i = 0; links && i < links.length; i++) {
    let tuples = {};
    //clog("LINK", i + 1, links[i]);
    for (let j = 0; j < links[i].attributes.length; j++) {
      tuples[links[i].attributes[j].name.toLowerCase()] =
        links[i].attributes[j].value;
    }
    //clog("TUPLE", tuples);
    Object.keys(goodLinkKeys).forEach((key) => {
      //clog("KEY", key, "TUPLE", tuples);
      if (tuples.rel == key) {
        //clog("GOOD KEY", key);
        let value = tuples[goodLinkKeys[key]];
        let payload = {
          content: tuples.href,
        };
        payload[goodLinkKeys[key]] = value ? value : "";
        if (glinks[key]) {
          glinks[key].push(payload);
        } else {
          glinks[key] = [payload];
        }
      }
    });
  }
  let bodies = parsed.getElementsByTagName("body");
  if (bodies && bodies.length > 0) {
    for (let i = 0; i < bodies.length; i++) {
      //clog("BODY", i + 1);
    }
    let titles = bodies[0].getElementsByTagName("title");
    if (titles && titles.length) {
      //clog("TITLE FIELD", titles[0]);
      let title = titles[0].textContent;
      //clog("TITLE", title);
      if (title) {
        gtuples["bodyTitle"] = title;
      }
    }
  }
  clog("GLOBAL TUPLES", gtuples);
  clog("GLOBAL SCRIPTS", gscripts);
  clog("GLOBAL LINKS", glinks);

  // popupate iconImage from various options
  let icons = [];
  //let iconTupleKeys = ["msapplication-TileImage"];
  //let iconLinkKeys = ["apple-touch-icon", "icon"];
  const iconRegex = /(\d+)[xX](\d+)\.([A-Za-z]+)$/;
  const iconSuffixRegex = /\.([A-Za-z]+)$/;
  const iconSizeRegex = /^(\d+)[xX](\d+)$/;
  iconTupleKeys.forEach((key) => {
    let urls = [gtuples[key]];
    if (typeof gtuples[key] == "object") {
      urls = gtuples[key];
    }
    if (urls) {
      urls.forEach((url) => {
        if (url) {
          //clog("URL", url);
          let match = url.match(iconRegex);
          let width = 0;
          let height = 0;
          let suffix = "";
          //clog("MATCH", match, "from", url);
          if (match && match.length >= 4) {
            width = Number(match[1]);
            height = Number(match[2]);
            suffix = match[3];
          } else {
            match = url.match(iconSuffixRegex);
            //clog("SUFFIX MATCH", match, "from", url);
            if (match && match.length >= 2) {
              suffix = match[1];
            }
          }
          icons.push({
            key: key,
            width: width,
            height: height,
            suffix: suffix,
            url: url,
          });
        }
      });
    }
  });

  iconLinkKeys.forEach((key) => {
    let tuples = glinks[key];
    if (tuples) {
      tuples.forEach((tuple) => {
        let url = tuple.content;
        if (url) {
          //clog("ICON FROM LINK", url, tuple);
          let width = 0;
          let height = 0;
          let suffix = "";
          let match = url.match(iconRegex);
          if (match && match.length >= 4) {
            width = Number(match[1]);
            height = Number(match[2]);
            suffix = match[3];
          } else {
            match = url.match(iconSuffixRegex);
            //clog("SUFFIX MATCH", match, "from", url);
            if (match && match.length >= 2) {
              suffix = match[1];
            }
          }
          let sizes = tuple["sizes"];
          if (sizes) {
            match = sizes.match(iconSizeRegex);
            if (match && match.length == 3) {
              width = Number(match[1]);
              height = Number(match[2]);
            }
          }
          icons.push({
            key: key,
            width: width,
            height: height,
            suffix: suffix,
            url: url,
          });
        }
      });
    }
  });
  let sortedIcons = icons.sort((a, b) => {
    if (a.suffix != b.suffix) {
      return a.suffix == "png"
        ? -1
        : b.suffix == "png"
        ? 1
        : a.suffix < b.suffix;
    }
    if (a.width != b.width) {
      return a.width > b.width ? -1 : 1;
    }
  });
  clog("ICONS", icons);
  clog("SORTED ICONS", sortedIcons);
  if (sortedIcons.length > 0) {
    gtuples["iconImage"] = sortedIcons[0].url;
  }

  if (glinks["canonical"]) {
    gtuples["canonical"] = glinks["canonical"][0].content;
  }
  if (glinks["shortLink"]) {
    gtuples["shortLink"] = glinks["shortLink"][0].content;
  }

  if (gtuples["twitter:label1"] && gtuples["twitter:data1"]) {
    gtuples[gtuples["twitter:label1"]] = gtuples["twitter:data1"];
  }
  if (gtuples["twitter:label2"] && gtuples["twitter:data2"]) {
    gtuples[gtuples["twitter:label2"]] = gtuples["twitter:data2"];
  }

  keys.forEach((mapping) => {
    let targetName = mapping.value;
    mapping.key.forEach((key) => {
      if (gtuples[key] && !gtuples[targetName]) {
        gtuples[targetName] = gtuples[key];
      }
    });
  });

  let dups = {};
  let counts = {};
  Object.keys(gtuples).forEach((key) => {
    let value = gtuples[key];
    if (dups[value]) {
      dups[value].push(key);
      counts[value]++;
    } else {
      dups[value] = [key];
      counts[value] = 1;
    }
  });

  clog("DUPS", dups);

  Object.keys(dups)
    .sort((a, b) => (counts[a] > counts[b] ? -1 : 1))
    .forEach((key) => {
      clog(counts[key], key, dups[key]);
    });

  if (gtuples["type"]) {
    if (gtuples["type"] == "post") {
      gtuples["type"] = "article";
    }
  }
  if (gtuples["iconImage"]) {
    let hostPattern = /^http[^\/]+\/\/[^\/]+/;
    let match = currentUrl.match(hostPattern);
    if (match) {
      //clog("Host match", match);
      gtuples["iconImage"] = normalizeUrl(gtuples["iconImage"], match[0]);
    }
  }
  if (gtuples["image"]) {
    let hostPattern = /^http[^\/]+\/\/[^\/]+/;
    let image =
      typeof gtuples["image"] == "object"
        ? gtuples["image"][0]
        : gtuples["image"];

    let match = currentUrl.match(hostPattern);
    if (match) {
      //clog("Host match", match);
      let normalizedImage = normalizeUrl(image, match[0]);
      if (typeof gtuples["image"] == "object") {
        gtuples["image"][0] = normalizedImage;
      } else {
        gtuples["image"] = normalizedImage;
      }
    }
  }

  let tags =
    typeof gtuples["tag"] == "object" ? gtuples["tag"] : [gtuples["tag"]];

  let data = {
    title: gtuples["titleText"],
    description: gtuples["descriptionText"],
    keywords: gtuples["keywordsText"],
    image:
      typeof gtuples["image"] == "object"
        ? gtuples["image"][0]
        : gtuples["image"],
    icon: gtuples["iconImage"],
    author: gtuples["authorName"],
    duration: gtuples["duration"],
    canonical: gtuples["canonicalUrl"],
    type: gtuples["type"],
    site: gtuples["siteName"],
    siteTweet: gtuples["siteTweet"],
    publicationTime: gtuples["publicationTime"],
    modificationTime: gtuples["modificationTime"],
    category: gtuples["category"],
    topics: gtuples["topics"],
    tags: tags,
    contentTier: gtuples["contentTier"],
    opinion: gtuples["opinion"],
  };
  clog("EXTRACTED", data);
  return data;
}

function normalizeUrl(url, hostPrefix) {
  let hostPattern = /^http/;
  let absolutePattern = /^\/[^\/]/;
  let missingProto = /^\/\//;
  if (!url.match(hostPattern)) {
    if (url.match(absolutePattern)) {
      return hostPrefix + url;
    } else if (url.match(missingProto)) {
      return "https:" + url;
    } else {
      return url;
    }
  } else {
    return url;
  }
}
