export function adjustPinForRichShareNote(pin, myContext, mayNeedLookUp) {
  if (pin?.content && pin?.markup) {
    try {
      let json = JSON.parse(pin.content);
      traverse(json, myContext, mayNeedLookUp);
    } catch (err) {
      console.log("Cannot parse pin");
    }
  }
  return;
}

function traverse(json, myContext, mayNeedLookUp) {
  json?.data?.map((c, rowIndex) => {
    if (c.type == "image") {
      if (c.photo) {
        mayNeedLookUp[c.photo] = true;
      }
    } else if (c.type == "slidingPanel") {
      traverse(c, myContext, mayNeedLookUp);
    }
  });
}

export function downgradePinForRichShareNote(pin) {
  let content = "";
  if (pin.content) {
    if (pin.markup) {
      try {
        let json = JSON.parse(pin.content);
        if (json.fallback) {
          content = json.fallback;
        }
      } catch (err) {
        console.log("Cannot parse pin");
      }
    }
  }
  return content;
}

export function constructRichShareNote(text) {
  return {
    type: "Note",
    fallback: text,
    data: [
      {
        type: "text",
        content: text,
      },
    ],
  };
}

const someText = (f) => {
  let response = "";
  if (f.type == "text") {
    response += text(f);
  } else if (f.type == "quote") {
    response += quote(f);
  } else if (f.type == "bulletedList") {
    response += bulletedList(f);
  } else {
    console.log("UNKNOWN TYPE", f.type, "IN TEXT", f);
  }
  return response;
};

const text = (f) => {
  let response = "";
  if (f.type == "text") {
    response += f.content;
  }
  return response;
};

const quote = (f) => {
  let response = "";
  if (f?.data?.type == "text") {
    response += text(f.data);
  }
  if (f?.attribution?.type == "text") {
    response += text(f.attribution);
  }
  return response;
};

const bulletedList = (f) => {
  let response = "";
  if (f.header) {
    //console.log("LIST HEADER", f.header);
    response += f.header + "\n";
  }
  f?.data?.forEach((f2) => {
    response += someText(f2) + "\n";
  });
  return response;
};

const image = (f) => {
  let response = "";
  //console.log("IMAGE", f);
  return response;
};

const textCard = (f) => {
  let response = "";
  f?.data?.forEach((f2) => {
    response += someText(f2) + "\n";
  });
  return response;
};

const slidingPanel = (f) => {
  let response = "";
  if (f.header) {
    response += f.header;
  }
  f?.data?.forEach((f2) => {
    if (f2.type == "textCard") {
      response += textCard(f2);
    } else if (f2.type == "image") {
      response += image(f2);
    } else {
      console.log("UNKNOWN TYPE IN SLIDING PANEL", f2);
    }
  });
  return response;
};

const note = (f) => {
  let response = "";
  f?.data?.forEach((f2) => {
    //console.log("FIELD", f);
    if (f2.type == "text") {
      response += text(f2);
    } else if (f2.type == "quote") {
      response += quote(f2);
    } else if (f2.type == "textCard") {
      response += textCard(f2);
    } else if (f2.type == "bulletedList") {
      response += bulletedList(f2);
    } else if (f2.type == "slidingPanel") {
      response += slidingPanel(f2);
    } else if (f2.type == "image") {
      response += image(f2);
    } else {
      console.log("UNKNOWN TYPE", f2.type, "AT TOP LEVEL", f2);
    }
  });
  return response;
};

export function plainTextFromShareNote(json) {
  let plainText = note(json);
  return plainText;
}
