import React, { useEffect, useRef } from "react";
import { StyleSheet, View, Animated, Easing } from "react-native";

const PageIndicator = ({
  count,
  current = 0,
  gap = 12,
  size = 6,
  color = "black",
  scale = 1,
  opacity = 0.15,
  duration = 500,
  activeColor = color,
  borderRadius = size / 2,
  easing = Easing.out(Easing.cubic),
  style,
  ...props
}) => {
  const shouldAnimate = typeof current === "number";

  const animatedValue = useRef(
    shouldAnimate ? new Animated.Value(clamp(current, 0, count)) : current
  ).current;

  const clamp = (value, min, max) => {
    return Math.max(min, Math.min(value, max));
  };

  useEffect(() => {
    if (shouldAnimate) {
      Animated.timing(animatedValue, {
        toValue: clamp(current, 0, count),
        duration,
        easing,
        useNativeDriver: true,
      }).start();
    }
  }, [shouldAnimate, animatedValue, current, count, duration, easing]);

  return (
    <View
      {...props}
      style={[styles.root, style, { flexDirection: "row" }]}
      pointerEvents="none"
    >
      {[...Array(count).keys()].map((index) => (
        <IndicatorBeads
          key={index}
          gap={gap}
          size={size}
          color={color}
          index={index}
          scale={scale}
          activeColor={activeColor}
          opacity={clamp(opacity, 0, 1)}
          borderRadius={clamp(borderRadius, 0, size / 2)}
          animatedValue={animatedValue}
        />
      ))}
    </View>
  );
};

const IndicatorBeads = ({
  gap,
  size,
  color,
  index,
  scale,
  opacity,
  activeColor,
  borderRadius,
  animatedValue,
}) => {
  const overlay = activeColor !== color;

  const scaleTransform = animatedValue.interpolate({
    inputRange: [index - 1, index, index + 1],
    outputRange: [1, scale, 1],
    extrapolate: "clamp",
  });

  const baseStyles = {
    width: size,
    height: size,
    backgroundColor: color,
    borderRadius: borderRadius,
    transform: [{ scale: scaleTransform }],
    opacity: animatedValue.interpolate({
      inputRange: [index - 1, index, index + 1],
      outputRange: [opacity, overlay ? 0 : 1, opacity],
      extrapolate: "clamp",
    }),
  };

  const overlayStyles = {
    ...baseStyles,
    ...StyleSheet.absoluteFillObject,
    backgroundColor: activeColor,
    opacity: animatedValue.interpolate({
      inputRange: [index - 1, index, index + 1],
      outputRange: [0, 1, 0],
      extrapolate: "clamp",
    }),
  };

  return (
    <View style={{ marginHorizontal: gap / 2 }}>
      <Animated.View style={baseStyles} />
      {overlay && <Animated.View style={overlayStyles} />}
    </View>
  );
};

export default PageIndicator;

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    justifyContent: "center",
  },
});
