import React from "react";
import {
  View,
  Platform,
  Text,
  Image,
  TouchableOpacity,
  Pressable,
  FlatList,
  StyleSheet,
} from "react-native";
import downArrow from "../assets/downArrow.png";
import { logEvent } from "../utils/LogEvent";

const Filter = (props) => {
  const {
    myContext,
    topicsAvailable,
    topicOptions,
    setTopicModalVisible,
    dataRefresh,
    colors,
    origin,
  } = props;

  const Topics = () => {
    const onPress = () => {
      myContext["selectedTopicsTemp"] = {
        ...myContext.selectedTopics,
      };
      myContext["selectedMyTopicsTemp"] = myContext.selectedMyTopics;
      myContext["versionTemp"] = myContext.version;
      logEvent(origin == "myvillage" ? "MyVillage_Filter_Top" : "Filter_Top", {
        userId: myContext?.Id,
        username: myContext?.handle,
        category: origin,
        platform: Platform.OS,
        appVersion: myContext.appVersion,
      });
      setTopicModalVisible(true);
    };

    return (
      <TouchableOpacity
        onPress={onPress}
        style={[styles.button, { backgroundColor: colors.filterBackground }]}
      >
        <Text style={[styles.textButton, { color: colors.primaryText }]}>
          {"Topics"}
        </Text>
        <View style={styles.imageContainer}>
          <Image
            source={downArrow}
            style={{ height: 5.5, width: 9.5, tintColor: colors.primaryText }}
          />
        </View>
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item }) => {
    const onPress = () => {
      logEvent(
        origin == "myvillage" ? "MyVillage_Filter_Directly" : "Filter_Directly",
        {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: origin,
          platform: Platform.OS,
          topicFilter: item.name,
          selected: !myContext.selectedTopics[item.Id] ? "true" : "false",
          appVersion: myContext.appVersion,
        }
      );
      if (myContext.selectedMyTopics) {
        myContext.selectedMyTopics = false;
        myContext.selectedTopics = {};
      }
      if (myContext.selectedTopics[item.Id]) {
        delete myContext.selectedTopics[item.Id];
      } else {
        myContext.selectedTopics[item.Id] = true;
      }
      myContext.version++;
      myContext["feedFetched"] = false;
      dataRefresh();
    };

    return (
      <View style={styles.item}>
        {item.Id != -1 && (
          <Pressable
            onPress={onPress}
            style={[
              styles.button,
              {
                backgroundColor: myContext.selectedTopics[item.Id]
                  ? colors.primaryButtonBackground
                  : colors.filterBackground,
              },
            ]}
          >
            <Text
              style={[
                styles.textButton,
                myContext.selectedTopics[item.Id]
                  ? {
                      color: colors.primaryButtonText,
                      fontWeight: "700",
                    }
                  : {
                      color: colors.primaryText,
                      fontWeight: "400",
                    },
              ]}
            >
              {item.name}
            </Text>
          </Pressable>
        )}
        {item.Id == -1 && (
          <View
            style={[
              styles.separatorVertical,
              { backgroundColor: colors.smallDivider },
            ]}
          />
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Topics />
      <View
        style={[
          styles.separatorVertical,
          { backgroundColor: colors.smallDivider },
        ]}
      />
      <FlatList
        data={topicOptions}
        style={styles.list}
        renderItem={renderItem}
        keyExtractor={(item) => item?.id}
        horizontal={true}
      />
    </View>
  );
};

export default Filter;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 4,
    marginRight: 4,
    height: 30,
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 100,
  },
  imageContainer: {
    height: 24,
    width: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  textButton: {
    fontSize: 15,
  },
  separatorVertical: {
    width: 1,
    height: 16,
    marginLeft: 4,
    marginRight: 4,
  },
  list: {
    height: 30,
    flexDirection: "row",
  },
  item: { flexDirection: "row", alignItems: "center" },
});
