import { API, graphqlOperation } from "aws-amplify";
import { generateUniqueId } from "../utils/Id";

import {
  updateUrlForPinning,
  addPin,
  removePin,
  updateUserCounts,
  updateListCounts,
  removeAction,
  updatePinCounts,
} from "../src/graphql/custom";

import { performAction } from "../utils/Action";
import { addIds, removeIds } from "../utils/IdList";
import { clog, elog } from "../utils/Log";
import { refreshUserData } from "../utils/DataFetcher";
import { sendPushNotification } from "../utils/Notification";
import { fetchFollowersIfNecessary } from "../utils/DataFetcher";
import { createNotification } from "./NotificationController";

export async function deletePin({
  url,
  pin,
  listId,
  listNumPins,
  oldList,
  myContext,
  batch,
  stateGetter,
  stateSetter,
  callback,
}) {
  clog("ARGUMENTS", arguments);
  let status = { success: true };
  // TODO(alpha): Populate curator field of pin and enable this check
  /* if (pin?.curator?.Id != myContext.Id) {
    clog("Tried to delete somebody else's pin");
    status.success = false;
    status["message"] = "Tried to delete somebody else's pin";
    if (callback) {
      callback(status);
    }
    return status;
  }*/
  let payloads = [];

  try {
    let promises = [];
    let urlCounters = {};
    urlCounters["Id"] = url?.Id;
    urlCounters["numContribute"] = url.numContribute - 1;
    urlCounters["numPins"] = url.numPins - 1;

    // TODO(alpha): Move to Action framework to avoid getting stale counters
    let likerIds = [];
    let curatorIds = [];
    pin?.actions?.items?.forEach((action) => {
      if (action?.operation == "Like") {
        likerIds.push(action?.actorId);
      }
      if (action?.operation == "Create") {
        curatorIds.push(action?.actorId);
      }
      promises.push(
        API.graphql(
          graphqlOperation(removeAction, {
            Id: action?.Id,
          })
        )
      );
      payloads.push({
        removeAction: { Id: action?.Id },
      });
    });
    clog("WILL DELETE CURATORS", curatorIds, "from", url?.curatorIds);
    if (curatorIds.length) {
      urlCounters["curatorIds"] = removeIds(url?.curatorIds, curatorIds);
    }
    if (likerIds.length) {
      urlCounters["likerIds"] = removeIds(url?.likerIds, likerIds);
    }
    urlCounters["listIds"] = removeIds(url?.listIds, [listId]);

    clog("URL COUNTERS", urlCounters);
    promises.push(
      API.graphql(graphqlOperation(updateUrlForPinning, urlCounters))
    );
    payloads.push({ updateUrlForPinning: urlCounters });

    // delete the Pin
    promises.push(API.graphql(graphqlOperation(removePin, { Id: pin.Id })));
    payloads.push({ removePin: { Id: pin.Id } });
    // TODO(alpha): update topics for the list by recomputing topics based on remaining pins
    clog(
      "ORIGINAL LIST TOPICS",
      oldList?.topicIds,
      "will remove",
      url?.topicIds?.split(","),
      "will end up with",
      removeIds(oldList?.topicIds, url?.topicIds?.split(","))
    );
    let listPayload = {
      Id: listId,
      numPins: oldList.NumPins - 1,
      topicIds: removeIds(oldList?.topicIds, url?.topicIds?.split(",")),
    };
    promises.push(API.graphql(graphqlOperation(updateListCounts, listPayload)));
    payloads.push({ updateListCounts: listPayload });
    // update user stats
    ////clog("user Id", myContext.Id);
    let userPayload = {
      Id: myContext.Id,
      numPinCreate: myContext.numPinCreate - 1,
      numUrlContribute: myContext.numUrlContribute - 1,
      numCreateRecursive: myContext.numCreateRecursive - 1,
      topicIds: removeIds(myContext.topicIds, url?.topicIds?.split(",")),
      curatedTopicIds: removeIds(
        myContext.curatedTopicIds,
        url?.topicIds?.split(",")
      ),
    };
    promises.push(API.graphql(graphqlOperation(updateUserCounts, userPayload)));
    payloads.push({ updateUserCounts: userPayload });

    // TODO(alpha): Add ACTION for delete pin, delete url contribute
    const responses = await Promise.all(promises);
    clog("responses", responses);
    myContext["numPinCreate"]--;
    myContext["numUrlContribute"]--;
    myContext["numCreateRecursive"]--;
    myContext["topicIds"] = removeIds(
      myContext.topicIds,
      url.topicIds.split(",")
    );
    myContext["curatedTopicIds"] = removeIds(
      myContext.curatedTopicIds,
      url.topicIds.split(",")
    );
    myContext.version++;
    if (!batch) {
      await refreshUserData(myContext);
    }
    stateSetter((currentState) => {
      clog("base state", currentState);
      let remainingPins = [];
      currentState.pins.map((p) => {
        clog("consider pin", p);
        if (p && p.Id != pin.Id) {
          remainingPins.push(p);
        }
      });
      let remainingUrls = [];
      currentState.urls.map((u) => {
        clog("consider url", u);
        if (u.Id != url.Id) {
          remainingUrls.push(u);
        }
      });
      clog("remaining pins", remainingPins);
      clog("remaining urls", remainingUrls);
      return {
        ...currentState,
        numPins: currentState.numPins - 1,
        pins: remainingPins,
        urls: remainingUrls,
        topicIds: removeIds(currentState?.topicIds, url?.topicIds?.split(",")),
      };
    });
    if (callback) {
      callback(status);
    }
    return status;
  } catch (err) {
    status.success = false;
    status["message"] = "Failed to delete Pin!";
    status["error"] = err;
    if (callback) {
      callback(status);
    }
    console.log("error deleting pin...", err);
    elog(myContext.Id, "content list", "deleting pin", err.message, payloads);
    return status;
  }
}

export async function copyPin({ url, listId, curator, myContext, callback }) {
  let status = { success: true };
  //setLoading(true);
  let promises = [];
  let payloads = [];
  let topicsOfList = [];
  let listNumPins = 0;
  let oldList = null;
  // NOTE(alpha): Access object (any collection metadata, not individual pins)
  if (myContext?.actionsByUser?.["List"]?.["Create"]) {
    Object.values(myContext?.actionsByUser?.["List"]?.["Create"])?.map((x) => {
      clog("considering", x);
      clog("values", Object.values(x));
      let list = Object.values(x)?.[0]?.object;
      if (list?.Id == listId) {
        listNumPins = list?.numPins == null ? 0 : list.numPins;
        topicsOfList = list?.topics?.items == null ? [] : list.topics.items;
        oldList = list;
      }
    });
  }
  clog(
    "list Id",
    listId,
    "listNumPins",
    listNumPins,
    "list topics",
    topicsOfList,
    "for url",
    url
  );

  let duplicate = false;
  // check if the list already has the item
  clog("Old list", oldList);
  oldList?.pins?.items?.forEach((pin) => {
    clog("consider existing pin", pin);
    if (pin?.url?.Id == url.Id) {
      duplicate = true;
    }
  });
  clog("duplicate", duplicate);
  if (duplicate) {
    status.success = false;
    status["message"] = "The collection already has this content.";
    if (callback) {
      callback(status);
    }
    return status;
  }
  clog("listId", listId);
  let pin = {};
  pin["Id"] = generateUniqueId();
  pin["creatorId"] = myContext.Id;
  pin["curatorId"] = myContext.Id;
  pin["listId"] = listId;
  pin["seq"] = oldList.nextPinSeq;
  try {
    // update url counters
    let urlCounters = {};
    urlCounters["Id"] = url["Id"];
    urlCounters["numContribute"] = url.numContribute + 1;
    urlCounters["numPins"] = url.numPins + 1;
    urlCounters["curatorIds"] = addIds(url.curatorIds, [pin.curatorId]);
    urlCounters["listIds"] = addIds(url.listIds, [pin.listId]);

    promises.push(
      API.graphql(graphqlOperation(updateUrlForPinning, urlCounters))
    );
    payloads.push({ updateUrlForPinning: urlCounters });
    // create the Pin
    pin["urlId"] = url["Id"];
    clog("will create pin with", pin);
    promises.push(API.graphql(graphqlOperation(addPin, pin)));
    payloads.push({ addPin: pin });
    let existingListTopicIds = {};
    topicsOfList.forEach((e) => {
      if (e.topic != null) {
        existingListTopicIds[e.topic.Id] = 1;
      }
    });
    clog("EXISTING IDS", existingListTopicIds);
    let listPayload = {
      Id: listId,
      numPins: listNumPins + 1,
      topicIds: addIds(oldList.topicIds, url.topicIds.split(",")),
      nextPinSeq: oldList.nextPinSeq + 1,
    };
    promises.push(API.graphql(graphqlOperation(updateListCounts, listPayload)));
    payloads.push({ updateListCounts: listPayload });

    // update user stats
    ////clog("user Id", myContext.Id);
    let userPayload = {
      Id: myContext.Id,
      numPinCreate: myContext.numPinCreate + 1,
      numUrlContribute: myContext.numUrlContribute + 1,
      numCreateRecursive: myContext.numCreateRecursive + 1,
      topicIds: addIds(myContext.topicIds, url.topicIds.split(",")),
      curatedTopicIds: addIds(
        myContext.curatedTopicIds,
        url.topicIds.split(",")
      ),
    };
    console.log("USER PAYLOAD", userPayload);
    promises.push(API.graphql(graphqlOperation(updateUserCounts, userPayload)));
    payloads.push({ updateUserCounts: userPayload });

    // update curator stats
    ////clog("user Id", myContext.Id);
    let curatorPayload = {
      Id: curator.Id,
      numPinRecursive: !curator.numPinRecursive
        ? 1
        : curator.numPinRecursive + 1,
    };
    promises.push(
      API.graphql(graphqlOperation(updateUserCounts, curatorPayload))
    );
    payloads.push({ "curator:updateUserCounts": curatorPayload });

    let localCallback = (
      success,
      newRelationshipId,
      createdAt,
      responses,
      creationTS
    ) => {
      if (success) {
        clog("responses", responses);
        myContext["numPinCreate"]++;
        myContext["numUrlContribute"]++;
        myContext["numCreateRecursive"]++;
        myContext["topicIds"] = addIds(
          myContext.topicIds,
          url.topicIds.split(",")
        );
        myContext["curatedTopicIds"] = addIds(
          myContext.curatedTopicIds,
          url.topicIds.split(",")
        );
        myContext.version++;
        if (oldList?.pins == null) {
          oldList.pins = {};
        }
        if (oldList.pins.items == null) {
          oldList.pins.items = [];
        }
        if (oldList?.pins?.items) {
          pin["url"] = url;
          oldList.pins.items.push(pin);
        }
        if (oldList?.topics == null) {
          oldList.topics = {};
        }
        if (oldList.topics.items == null) {
          oldList.topics.items = [];
        }
        oldList.topicIds = addIds(oldList.topicIds, url.topicIds.split(","));
        oldList.nextPinSeq++;
        pin.list = oldList;
        status["newObject"] = pin;
        status["newRelationshipId"] = newRelationshipId;
        status["creationTS"] = creationTS;
        if (callback) {
          callback(status);
        }
        return status;
      } else {
        status.success = false;
        status["message"] = "Failed to copy pin";
        if (callback) {
          callback(status);
        }
        return status;
      }
    };

    // TODO(alpha): Add ACTION for create pin, url contribute
    performAction({
      objectType: "Pin",
      operation: "Create",
      objectId: pin.Id,
      actorId: myContext.Id,
      relationshipId: null,
      objectCounter: 0,
      userCounter: myContext.numPinCreate,
      curatorId: myContext.Id,
      curatorCounter: myContext.numCreateRecursive,
      promises: promises,
      payloads: payloads,
      callback: localCallback,
      actionOnly: true,
      refererActionId: null,
      // TODO(alpha): Let the caller set refererActionId vs hostUserId
      hostUserId: curator.Id,
      seq: null,
    });
  } catch (err) {
    status.success = false;
    status["message"] = "Failed to save pin";
    status["error"] = err;
    if (callback) {
      callback(status);
    }
    console.log("error creating pin...", err);
    elog(myContext.Id, "content list", "creating pin", err.message);
    return status;
  }
}

export async function savePin({ url, curator, myContext, callback }) {
  let listId = null;
  clog("SAVE PIN", url, "CURATED BY", curator);
  if (myContext?.actionsByUser?.["List"]?.["Create"]) {
    // NOTE(alpha): Access object to find the Id of Saved collection
    // TODO(alpha): Add check to preventing saving the same url multiple times
    Object.values(myContext?.actionsByUser?.["List"]?.["Create"])?.map((x) => {
      clog("considering", x);
      clog("values", Object.values(x));
      let list = Object.values(x)?.[0]?.object;
      if (list?.name == "Saved") {
        listId = list.Id;
      }
    });
  }
  if (!listId) {
    let status = {
      success: false,
      message: "No collection called Saved found",
    };
    if (callback) {
      callback(status);
    }
    return status;
  }
  await fetchFollowersIfNecessary(myContext);

  return await copyPin({
    url: url,
    listId: listId,
    curator: curator,
    myContext: myContext,
    callback: ({
      success,
      message,
      error,
      newObject,
      newRelationshipId,
      creationTS,
    }) => {
      if (callback) {
        callback({
          success,
          message,
          error,
          newObject,
          newRelationshipId,
          creationTS,
        });
      }
      if (success) {
        // TODO(alpha): Why not notify all followers who have pinned the url?
        let targetId = "";
        url?.pins?.forEach((pin) => {
          if (pin?.curatorId == curator.Id) {
            targetId = pin.Id;
          }
        });

        let data = {
          type: "Url",
          userId: myContext.Id,
          urlId: url.Id,
          action: "Save",
          target: "Pin",
          recipientId: curator.Id,
          actorId: myContext.Id,
          targetId: targetId,
          targetType: "Pin",
          operation: "Save",
          pinId: targetId,
          actionId: newRelationshipId,
          creationTS: creationTS,
        };
        createNotification(data);

        if (curator?.token && myContext?.followers?.[curator?.Id]) {
          clog("CAN NOTIFY", curator?.token);
          // find the targetId
          sendPushNotification(
            curator?.token,
            "Village",
            "@" + myContext.handle + " " + "pinned: " + url.title,
            data
          );
        }
      }
    },
  });
}

export async function movePin({
  url,
  pin,
  listId,
  sourceList,
  myContext,
  callback,
}) {
  let status = { success: true };
  let promises = [];
  let payloads = [];
  let topicsOfList = [];
  let listNumPins = 0;
  let oldList = null;
  if (myContext?.actionsByUser?.["List"]?.["Create"]) {
    // NOTE(alpha): Accesses object to find target list metadata and pins in it
    Object.values(myContext?.actionsByUser?.["List"]?.["Create"])?.map((x) => {
      clog("considering", x);
      clog("values", Object.values(x));
      let list = Object.values(x)?.[0]?.object;
      if (list?.Id == listId) {
        listNumPins = list?.numPins == null ? 0 : list.numPins;
        topicsOfList = list?.topics?.items == null ? [] : list.topics.items;
        oldList = list;
      }
    });
  }
  clog(
    "list Id",
    listId,
    "listNumPins",
    listNumPins,
    "list topics",
    topicsOfList,
    "for url",
    url
  );

  let duplicate = false;
  // check if the list already has the item
  clog("Old list", oldList);
  oldList?.pins?.items?.forEach((pin) => {
    clog("consider existing pin", pin);
    if (pin?.url?.Id == url.Id) {
      duplicate = true;
    }
  });
  clog("duplicate", duplicate);
  if (duplicate) {
    status.success = false;
    status["message"] = "The collection already has this content.";
    if (callback) {
      callback(status);
    }
    return status;
  }
  clog("listId", listId);
  try {
    let existingListTopicIds = {};
    topicsOfList.forEach((e) => {
      if (e.topic != null) {
        existingListTopicIds[e.topic.Id] = 1;
      }
    });
    clog("EXISTING IDS", existingListTopicIds);
    let pinParams = {
      Id: pin.Id,
      listId: listId,
    };
    clog("PIN PARAMS", pinParams);
    promises.push(API.graphql(graphqlOperation(updatePinCounts, pinParams)));
    payloads.push({ updatePinCounts: pinParams });

    let targetListPayload = {
      Id: listId,
      numPins: listNumPins + 1,
      topicIds: addIds(oldList?.topicIds, url?.topicIds?.split(",")),
      nextPinSeq: oldList.nextPinSeq + 1,
    };

    promises.push(
      API.graphql(graphqlOperation(updateListCounts, targetListPayload))
    );
    payloads.push({ "targetList:updateListCounts": targetListPayload });

    let sourceListPayload = {
      Id: sourceList.Id,
      numPins: sourceList.numPins - 1,
      topicIds: removeIds(sourceList.topicIds, url?.topicIds?.split(",")),
    };
    promises.push(
      API.graphql(graphqlOperation(updateListCounts, sourceListPayload))
    );
    payloads.push({
      updateListCounts: sourceListPayload,
    });
    let responses = await Promise.all(promises);
    clog("responses", responses);
    myContext.version++;
    if (oldList?.pins == null) {
      oldList.pins = {};
    }
    if (oldList.pins.items == null) {
      oldList.pins.items = [];
    }
    if (oldList?.pins?.items) {
      oldList.pins.items.push(pin);
    }
    if (oldList?.topics == null) {
      oldList.topics = {};
    }
    if (oldList.topics.items == null) {
      oldList.topics.items = [];
    }
    oldList.topicIds = addIds(oldList?.topicIds, url.topicIds.split(","));
    oldList.nextPinSeq++;
    status.message = "Moved Pin!";
    if (callback) {
      callback(status);
    }
    return status;
  } catch (err) {
    status.success = false;
    status["message"] = "Failed to move Pin!";
    status["error"] = err;
    if (callback) {
      callback(status);
    }
    console.log("error moving pin...", err);
    elog(myContext.Id, "content list", "moving pin", err.message, payloads);
    return status;
  }
}
