import React, { useState, useContext } from "react";
import { StyleSheet, Text, View, SafeAreaView, Pressable } from "react-native";

import AppContext from "../components/AppContext";
import { villageColors } from "../utils/SharedStyles";
import LeftArrow from "../components/LeftArrow";
import { clog } from "../utils/Log";
import { useTheme } from "../theme";
import { updateUserCounts } from "../src/graphql/custom";
import { API, graphqlOperation } from "aws-amplify";

const AppearanceScreen = ({ route, navigation }) => {
  clog("navigation is in user details ", navigation);
  clog("route is ", route);
  let myContext = useContext(AppContext);
  clog("app context is", myContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const { colors, chosenScheme, setScheme, isDark } = useTheme();

  const [mode, setMode] = useState(isDark ? "dark" : "light");

  async function updateTheme(newTheme) {
    myContext["theme"] = newTheme;
    let response = await API.graphql(
      graphqlOperation(updateUserCounts, {
        Id: myContext.Id,
        chosenTheme: newTheme,
      })
    );
    console.log("AFTER THEME UPDATE", response);
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View
              style={{
                width: 32,
                height: 32,
                borderRadius: 32,
                overflow: "hidden",
                marginLeft: 8,
              }}
            >
              <LeftArrow tintColor={mode == "dark" ? "white" : "black"} />
            </View>
          </Pressable>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: "Appearance", //state.name, // TODO: pull the list name
    });
  }, [navigation, mode]);

  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: colors.background }]}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <Text
            style={[
              styles.text,
              {
                color: colors.primaryText,
                fontWeight: mode == "light" ? "700" : "400",
              },
            ]}
          >
            Light mode
          </Text>
          <Pressable
            onPress={() => {
              setMode("light");
              setScheme("light");
              myContext["dark"] = false;
              updateTheme("light");
            }}
            style={{ marginBottom: 24 }}
          >
            <View
              style={{
                backgroundColor: villageColors.Stone,
                height: 92,
                width: 358,
                borderRadius: 16,
                borderWidth: 2,
                borderColor:
                  mode == "dark" ? villageColors.White15 : villageColors.Canary,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  backgroundColor: villageColors.White,
                  height: 76,
                  width: 286,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 36,
                    fontWeight: "700",
                    color: "black",
                    //backgroundColor: "yellow",
                  }}
                >
                  Aa
                </Text>
              </View>
            </View>
          </Pressable>
          <Text
            style={[
              styles.text,
              {
                color: colors.primaryText,
                fontWeight: mode == "dark" ? "700" : "400",
              },
            ]}
          >
            Dark mode
          </Text>
          <Pressable
            onPress={() => {
              setMode("dark");
              setScheme("dark");
              myContext["dark"] = true;
              updateTheme("dark");
            }}
            style={{ marginBottom: 24 }}
          >
            <View
              style={{
                backgroundColor: villageColors.Raven,
                height: 92,
                width: 358,
                borderRadius: 16,
                borderWidth: 2,
                borderColor:
                  mode == "dark" ? villageColors.Canary : villageColors.White15,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  backgroundColor: villageColors.Cobblestone,
                  height: 76,
                  width: 286,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 36,
                    fontWeight: "700",
                    color: "white",
                    //backgroundColor: "yellow",
                  }}
                >
                  Aa
                </Text>
              </View>
            </View>
          </Pressable>
          {false && (
            <Text style={[styles.text, { color: colors.primaryText }]}>
              System mode
            </Text>
          )}
          {false && (
            <View
              style={{
                height: 92,
                width: 358,
                borderRadius: 16,
                borderWidth: 2,
                borderColor: villageColors.White15,
                flexDirection: "row",
                alignItems: "center",
                //backgroundColor: "yellow",
                justifyContent: "space-between",
                overflow: "hidden",
              }}
            >
              <View
                style={{
                  backgroundColor: villageColors.Stone,
                  height: 92,
                  width: 179,
                  //borderRadius: 16,
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <View
                  style={{
                    backgroundColor: villageColors.White,
                    height: 76,
                    width: 143,
                    borderTopLeftRadius: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 36,
                      fontWeight: "700",
                      color: "black",
                      //backgroundColor: "yellow",
                    }}
                  >
                    Aa
                  </Text>
                </View>
              </View>
              <View
                style={{
                  backgroundColor: villageColors.Raven,
                  height: 92,
                  width: 179,
                  borderColor:
                    mode == "dark"
                      ? villageColors.Canary
                      : villageColors.White15,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <View
                  style={{
                    backgroundColor: villageColors.Cobblestone,
                    height: 76,
                    width: 143,
                    borderTopRightRadius: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 36,
                      fontWeight: "700",
                      color: "white",
                      //backgroundColor: "yellow",
                    }}
                  >
                    Aa
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};

export default AppearanceScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },
  text: {
    fontSize: 15,
    marginBottom: 8,
  },
});
