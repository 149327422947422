import { React, useEffect, useRef, useState } from "react";
import {
  Animated,
  Image,
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
} from "react-native";
import backArrow from "../assets/back-arrow.png";
import { initialUser } from "../utils/InitialValues";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import TopicEditor from "./TopicEditor";
import { KeyboardSpacer } from "../utils/Keyboard";
import CloseButton from "./CloseButton";

const TopicSheet = ({
  visible,
  setVisible,
  topicOptions,
  colors,
  myContext,
  dataRefresh,
}) => {
  const initialState = {
    ...initialUser,
  };

  const [phase, setPhase] = useState(0);
  const [state, setState] = useState(initialState);
  const [isChanged, setIsChanged] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [changedTopics, setChangedTopics] = useState({});

  const insets = useSafeAreaInsets();

  // Animation
  const arrowOpacityRef = useRef(new Animated.Value(0)).current;
  const titleTranslateRef = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    onAnimatedHeader();
  }, [phase]);

  const onAnimatedHeader = () => {
    if (phase === 0) {
      Animated.timing(arrowOpacityRef, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(titleTranslateRef, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(arrowOpacityRef, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(titleTranslateRef, {
        toValue: 36,
        duration: 500,
        useNativeDriver: true,
      }).start();
    }
  };

  useEffect(() => {
    if (myContext && !state.Id) {
      setState((prevState) => {
        return {
          ...prevState,
          Id: myContext.Id,
          numTopicFollow: myContext.numTopicFollow,
          actionsByUser: myContext.actionsByUser,
          wishList: myContext.wishList,
        };
      });
    }
  }, []);

  const closeShareSheet = () => {
    arrowOpacityRef.resetAnimation();
    titleTranslateRef.resetAnimation();
    setVisible(false);
    setPhase(0);
    setIsChanged(false);
    setIsShowAlert(0);

    if (Object.keys(changedTopics).length !== 0) {
      setChangedTopics({});
    }
  };

  const renderHeader = () => {
    return (
      <View style={styles.listHeaderContainer}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Animated.View
            style={{
              position: "absolute",
              opacity: arrowOpacityRef,
              left: 0,
            }}
          >
            <Pressable onPress={() => setPhase(0)} disabled={phase !== 1}>
              <Image source={backArrow} style={{ height: 24, width: 24 }} />
            </Pressable>
          </Animated.View>

          <Animated.View
            style={{
              position: "absolute",
              left: 0,
              transform: [
                {
                  translateX: titleTranslateRef,
                },
              ],
            }}
          >
            <Text
              style={[styles.title, { color: colors.primaryText }]}
              numberOfLines={1}
            >
              {"My topics"}
            </Text>
          </Animated.View>
        </View>

        <CloseButton
          onPress={() => {
            if (isChanged) {
              setIsShowAlert(true);
            } else {
              closeShareSheet();
            }
          }}
        />
      </View>
    );
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType={"slide"}
      useNativeDriver={true}
    >
      <View style={styles.container}>
        <View
          style={[
            styles.actionSheetContainer,
            {
              backgroundColor: colors.background,
              paddingBottom: insets.bottom ? insets.bottom : 10,
            },
          ]}
        >
          {renderHeader()}

          {phase === 1 && (
            <View style={{ marginTop: 8 }}>
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 22,
                  color: colors.primaryText,
                }}
              >
                {
                  "In your own words, what kind of content do you want to see in Village? Consider mentioning: publications, people, sites, places, niches, trends"
                }
              </Text>
            </View>
          )}

          <TopicEditor
            topicOptions={topicOptions}
            colors={colors}
            myContext={myContext}
            phase={phase}
            setPhase={setPhase}
            goBack={closeShareSheet}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            isShowAlert={isShowAlert}
            dataRefresh={dataRefresh}
            changedTopics={changedTopics}
          />
          <KeyboardSpacer topSpacing={Platform.OS !== "android" ? -20 : -400} />
        </View>
      </View>
    </Modal>
  );
};

export default TopicSheet;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  actionSheetContainer: {
    flex: 1,
    maxHeight: "80%",
    marginTop: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
  },
  listHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
    marginBottom: 12,
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 28,
  },
});
