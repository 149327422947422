// Based on https://kps250.medium.com/creating-image-slider-with-flatlist-in-react-native-1815d3793d99

import React, { useState, useEffect, useRef } from "react";

import { Platform, View, Pressable, Text, Image } from "react-native";

import { FlatList } from "react-native-gesture-handler";
import { useTheme } from "../theme";
import pause from "../assets/pause.png";
import play from "../assets/play.png";
import { clog } from "../utils/Log";

export const Slide = ({
  data,
  width,
  height,
  renderItem,
  keyExtractor,
  autoscroll,
  duration,
  indicator,
  indicatorStyle,
  indicatorContainerStyle,
  indicatorActiveColor,
  indicatorInactiveColor,
  indicatorActiveWidth,
}) => {
  clog("RECEIVED WIDTH", width, "height", height);
  const [slideIndex, setSlideIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [intervalId, setIntervalId] = useState(0);

  const { colors } = useTheme();
  const slideRef = useRef();

  clog("CURRENT INTERVAL ID", intervalId);
  clog("CURRENT SLIDE INDEX", slideIndex);

  useEffect(() => {
    clog("playing", playing, "slideIndex", slideIndex);
    if (slideRef) {
      slideRef.current.scrollToOffset({
        offset: slideIndex * width,
        animated: true,
      });
      if (playing && slideIndex == data?.length - 1) {
        console.log("Will stop autoplay");
        stopAutoPlay();
      }
    }
  }, [slideIndex, slideRef, playing]);

  function changeSlideIndex() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeIn); //Not working
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + 1;
      if (newIndex < data.length) {
        return newIndex;
      } else {
        return prevIndex;
      }
    });
  }

  const startAutoPlay = () => {
    setSlideIndex((prevIndex) => {
      if (prevIndex + 1 == data?.length) {
        return 0;
      } else {
        return prevIndex + 1;
      }
    });
    setPlaying(true);
    let newIntervalId = setInterval(changeSlideIndex, duration);
    clog("INTERVAL ID", newIntervalId);
    setIntervalId(newIntervalId);
  };

  function stopAutoPlay() {
    if (intervalId) {
      clog("WILL CLEAR INTERVAL ID", intervalId);
      clearInterval(intervalId);
    }
    setPlaying(false);
  }

  useEffect(() => {
    if (autoscroll) {
      startAutoPlay();
    }

    return () => {
      stopAutoPlay();
    };
  }, []);

  const onViewableItemsChanged = ({ viewableItems, changed }) => {
    clog("VIEWABLE ITEMS", viewableItems);
    clog("CHANGED", changed);
    if (Platform.OS == "web") {
      let status = [];
      if (changed) {
        changed.forEach((element) => {
          if (element.isViewable) {
            setSlideIndex((prev) => {
              if (element.index > prev) {
                return prev + 1;
              } else if (element.index < prev) {
                return prev - 1;
              } else {
                return prev;
              }
            });
            clog("will slide to", element.index);
          }
          status.push({ index: element.index, visible: element.isViewable });
        });
      }
      clog("status", status);
    }
  };

  const viewabilityConfig = {
    viewAreaCoveragePercentThreshold: 0.1,
  };

  const viewabilityConfigCallbackPairs = useRef([
    { viewabilityConfig, onViewableItemsChanged },
  ]);

  return (
    <View style={{ marginTop: 10 }}>
      <FlatList
        ref={slideRef}
        horizontal
        //pagingEnabled={Platform.OS == "web" ? true : false}
        //snapToInterval={width}
        decelerationRate="fast"
        disableIntervalMomentum={true}
        nestedScrollEnabled={true}
        bounces={false}
        data={data}
        extraData={slideIndex}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => (
          <Pressable>{renderItem({ item, index })}</Pressable>
        )}
        keyExtractor={keyExtractor}
        getItemLayout={(data, index) => ({
          length: width,
          offset: width * index,
          index,
        })}
        onTouchStart={() => {
          clog("Began touch");
        }}
        onTouchEnd={() => {
          clog("Ended touch");
        }}
        onScrollBeginDrag={() => {
          clog("Began drag");
          stopAutoPlay();
        }}
        onScrollEndDrag={(event) => {
          clog("Ended drag");
          let targetLocation = 0;
          if (width && event?.nativeEvent?.contentOffset) {
            let x = event.nativeEvent.contentOffset.x;
            setSlideIndex((prevIndex) => {
              targetLocation = x / width;
              if (targetLocation > prevIndex) {
                targetLocation = prevIndex + 1;
              } else if (targetLocation < prevIndex) {
                targetLocation = prevIndex - 1;
              } else {
                targetLocation = prevIndex;
              }
              setSlideIndex(targetLocation);
            });
            clog("Ended drag at", x, x / (width ? width : 1), targetLocation);
          }
        }}
        onMomentumScrollBegin={() => {
          clog("Began momentum scroll");
        }}
        onMomentumScrollEnd={(event) => {
          clog("Ended momentum scroll");
        }}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        //windowSize={2}
        initialNumToRender={3}
        //maxToRenderPerBatch={1}
        removeClippedSubviews={true}
      />
      {indicator && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 502,
          }}
        >
          {slideIndex != null && data?.length != null && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 503,
              }}
            >
              <Text style={{ color: colors.placeholderText }}>
                {slideIndex + 1}
              </Text>
              <Text style={{ color: colors.placeholderText }}>{"/"}</Text>
              <Text style={{ color: colors.placeholderText }}>
                {data.length}
              </Text>
            </View>
          )}
          <Indicator
            itemCount={data.length}
            currentIndex={slideIndex}
            indicatorStyle={indicatorStyle}
            indicatorContainerStyle={[{}, indicatorContainerStyle]}
            indicatorActiveColor={indicatorActiveColor}
            indicatorInactiveColor={indicatorInactiveColor}
            indicatorActiveWidth={indicatorActiveWidth}
            style={indicatorStyle}
          />
          <Pressable
            onPress={() => {
              clog("CLICKED THE PLAY/PAUSE BUTTON", playing);
              playing ? stopAutoPlay() : startAutoPlay();
            }}
          >
            <View
              style={{
                height: 30,
                width: 30,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: 20,
              }}
            >
              <Image
                source={playing ? pause : play}
                style={[
                  { tintColor: colors.primaryText },
                  playing
                    ? { width: 13.52, height: 12 }
                    : { width: 14.57, height: 14.4 },
                ]}
              />
            </View>
          </Pressable>
        </View>
      )}
    </View>
  );
};

const Indicator = ({
  itemCount,
  currentIndex,
  indicatorStyle,
  indicatorContainerStyle,
  indicatorActiveColor,
  indicatorInactiveColor,
  indicatorActiveWidth = 6,
}) => {
  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        },
        indicatorContainerStyle,
      ]}
    >
      {renderIndicator(
        itemCount,
        currentIndex,
        indicatorStyle,
        indicatorActiveColor,
        indicatorInactiveColor,
        indicatorActiveWidth
      )}
    </View>
  );
};

const renderIndicator = (
  count,
  currentIndex,
  indicatorStyle,
  indicatorActiveColor,
  indicatorInactiveColor,
  indicatorActiveWidth
) => {
  let indicators = [];
  for (let i = 0; i < count; i++) {
    let style = [
      {
        width: 6,
        height: 6,
        borderRadius: 3,
        marginRight: 5,
      },
      indicatorStyle,
      i === currentIndex
        ? {
            backgroundColor: indicatorActiveColor,
            width: indicatorActiveWidth,
          }
        : {
            backgroundColor: indicatorInactiveColor,
            width: indicatorActiveWidth,
          },
    ];
    indicators.push(
      <View
        key={i}
        style={[
          {
            width: 6,
            height: 6,
            borderRadius: 3,
            marginRight: 5,
          },
          indicatorStyle,
          i === currentIndex
            ? {
                backgroundColor: indicatorActiveColor,
                width: indicatorActiveWidth,
              }
            : {
                backgroundColor: indicatorInactiveColor,
                width: indicatorActiveWidth,
              },
        ]}
      />
    );
  }
  return indicators;
};

export default Slide;
