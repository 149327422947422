import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Animated, Image, StyleSheet, Text, View } from "react-native";
import CloseButton from "./CloseButton";
import check from "../assets/check.png";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "../theme";

const DropDownAlert = (props, ref) => {
  const { message } = props;

  const insets = useSafeAreaInsets();
  const MARGIN_FOR_NOTIFICATION = insets.top  + 65;

  const { colors } = useTheme();

  const [showNotification, setShowNotification] = useState(false);
  const notificationTransformRef = useRef(new Animated.Value(0)).current;

  const onOpen = () => {
    setShowNotification(true);
    Animated.timing(notificationTransformRef, {
      toValue: MARGIN_FOR_NOTIFICATION,
      duration: 800,
      useNativeDriver: true,
    }).start();
  };

  const onClose = () => {
    Animated.timing(notificationTransformRef, {
      toValue: 0,
      duration: 800,
      useNativeDriver: true,
    }).start(() => setShowNotification(false));
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        onOpen,
        onClose,
      };
    },
    []
  );

  return (
    <>
      {showNotification ? (
        <Animated.View
          style={[
            styles.animatedContainer,
            {
              transform: [
                {
                  translateY: notificationTransformRef,
                },
              ],
            },
          ]}
        >
          <View
            style={[
              styles.container,
              {
                backgroundColor: colors.bottomNavBar,
              },
            ]}
          >
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View style={{ marginRight: 4 }}>
                <Image
                  source={check}
                  style={{
                    width: 24,
                    height: 24,
                    tintColor: colors.notificationText,
                  }}
                />
              </View>

              <Text
                style={[
                  styles.text,
                  {
                    color: colors.notificationText,
                  },
                ]}
              >
                {message}
              </Text>
            </View>

            <CloseButton
              onPress={onClose}
              buttonStyle={{ width: 24, height: 24 }}
              imageStyle={{ width: 10, height: 10 }}
            />
          </View>
        </Animated.View>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(DropDownAlert);

const styles = StyleSheet.create({
  animatedContainer: {
    position: "absolute",
    alignSelf: "center",
    top: -50,
    width: "90%",
    shadowColor: "rgb(23,23,23)",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },
  container: {
    padding: 12,
    zIndex: 3, // works on ios
    elevation: 3, // works on android
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    fontSize: 15,
    fontWeight: "400",
    lineHeight: 22,
  },
});
