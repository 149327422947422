import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Pressable,
  Image,
  StyleSheet,
  ActivityIndicator,
  FlatList,
  Modal,
  Text,
  View,
  RefreshControl,
  Platform,
  LayoutAnimation,
  Dimensions,
} from "react-native";

import AppContext from "../components/AppContext";
import gear from "../assets/gear.png";
import LeftArrow from "../components/LeftArrow";

import { WebView } from "react-native-webview";

import ProfileCard from "../components/ProfileCard";
import ConversionBanner from "../components/ConversionBanner";
import List from "../components/List";
import { Auth } from "aws-amplify";

import { initialUser } from "../utils/InitialValues";
import { sharedStyles } from "../utils/SharedStyles";

import cross from "../assets/cross.png";
import link from "../assets/link.png";
import folddown from "../assets/folddown.png";

import Blocker from "../components/Blocker";
import { timeStamp } from "../utils/TimeStamp";
import { clog } from "../utils/Log";
import { getUserDetailsData } from "../controllers/UserController";
import { logEvent } from "../utils/LogEvent";

import { guestLogin, guestLogout } from "../controllers/AuthController";
import { useTheme } from "../theme";

const GuestUserDetailsScreen = ({ route, navigation }) => {
  async function getData(handle, Id, callback) {
    setLoading(true);
    let { success, message, error } = await getUserDetailsData(
      handle,
      Id,
      myContext,
      () => {
        return state;
      },
      setState,
      true
    );
    setLoading(false);
    setRefreshing(false);
    if (callback) {
      callback();
    }
  }

  const getDataBasedOnAuthState = async (handle, Id) => {
    try {
      let { username, attributes } = await Auth.currentAuthenticatedUser();
      clog("WELCOME User", username, "attributes", attributes);
      setLoggedIn(true);
      getData(handle, Id, () => {
        clog("Done fetching data");
      }).catch((err) => {
        console.log("error finding list data: ", err);
      });
    } catch (err) {
      console.log("ERROR IN WELCOME USER", err);
      guestLogin({
        myContext: myContext,
        callback: ({ success, message, error }) => {
          if (success) {
            getData(handle, Id, () => {
              clog("DONE GETTING DATA");
              guestLogout({ myContext: myContext });
            }).catch((err) => {
              console.log("error finding list data: ", err);
              guestLogout({ myContext: myContext });
            });
          } else {
            clog("CANNOT LOGIN", message);
            guestLogout({ myContext: myContext });
            clog(message);
          }
        },
      });
    }
  };

  async function dataRefresh() {
    clog("CLICKED ON TAB");
    logEvent(myContext?.Id == Id ? "MyProfile_View" : "OtherProfile_View", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: myContext?.Id == Id ? "myprofile" : "otherprofile",
      platform: Platform.OS,
      targetuser: handle,
      appVersion: myContext.appVersion,
    });

    // do something
    if (
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800 ||
      !stateRef.current.Id
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef.current
      );
      getDataBasedOnAuthState(handle, Id);
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef.current
      );
    }
  }

  const myContext = useContext(AppContext);
  clog("CONTEXT IN GUEST USER", myContext);
  const { colors } = useTheme();

  let handle = route?.params?.handle ? route.params.handle : myContext?.handle;
  let Id = route?.params?.Id
    ? route.params.Id
    : route?.params?.handle == myContext?.handle
    ? myContext?.Id
    : "";
  let fromList = route?.params?.fromList ? route.params.fromList : false;

  clog("route", route);
  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  const initialState = initialUser;

  const [state, setState] = useState(initialState);
  const [tab, setTab] = useState(
    myContext.actionsByUser?.["Experiment"]?.["Join"]?.["TopicsInProfile"] !=
      null
      ? 1
      : 2
  );
  const [subtab, setSubtab] = useState(1);
  const [refreshing, setRefreshing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [folded, setFolded] = useState(false);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  const [topics, setTopics] = useState(null);
  const [guest, setGuest] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const windowDim = Dimensions.get("window");
  const screenDim = Dimensions.get("screen");
  clog("ORIGINAL WINDOW", windowDim);
  clog("ORIGINAL SCREEN", screenDim);
  const [dimensions, setDimensions] = useState({
    window: windowDim,
    screen: screenDim,
  });

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        clog("NEW WINDOW", window);
        clog("NEW SCREEN", screen);
        setDimensions({ window, screen });
      }
    );
    return () => subscription?.remove();
  });

  React.useLayoutEffect(() => {
    let extraLeft =
      dimensions.window.width >= 556 ? (dimensions.window.width - 556) / 2 : 0;
    clog("EXTRA LEFT", extraLeft);
    navigation.setOptions({
      headerShown: fromList ? true : false,
      title: "",
      headerStyle: {
        height: fromList ? 66 : 0,
        backgroundColor: "transparent",
      },
      headerTransparent: true,
      headerLeft: () => {
        if (fromList) {
          return (
            <Pressable onPress={navigation.goBack}>
              <View
                style={{
                  marginLeft: 10 + extraLeft,
                  height: 32,
                  alignItems: "center",
                  flexDirection: "row",
                  backgroundColor: "rgba(0,0,0,0.45)",
                  width: 32,
                  borderRadius: 32,
                  overflow: "hidden",
                }}
              >
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        }
      },
      headerBackTitleVisible: false,
      headerRight: () => {
        {
          if (handle == myContext.handle) {
            return (
              <Pressable
                onPress={() => {
                  navigation.push("Settings", {
                    Id: myContext.Id,
                  });
                  logEvent("OwnProfile_Settings", {
                    userId: myContext?.Id,
                    username: myContext?.handle,
                    category: "myprofile",
                    platform: Platform.OS,
                    action: "click",
                    target: "settings",
                    appVersion: myContext.appVersion,
                  });
                }}
              >
                <Image
                  source={gear}
                  style={{
                    marginRight: 26,
                    height: 19,
                    width: 20,
                  }}
                />
              </Pressable>
            );
          }
        }
      },
    });
  }, [navigation, dimensions]);

  clog("CONTEXT VERSION", myContext.version);
  /*useEffect(() => {
    clog("check for data", handle, Id, myContext.version);
    clog("WILL GETDATA with", handle, Id, myContext.version);
    getData(handle).catch((err) => {
      clog("error finding user: ", err);
    });
  }, [handle, Id, myContext.version]); */

  // Determine top followers

  const regex = /.*\/\//;
  let displayedWebsite = state?.websites?.replace(regex, "");
  if (displayedWebsite == null) {
    displayedWebsite = "";
  }

  let topicsAggregated = [];
  clog("topics", topics);

  let currentTime = Date.now();
  //return <SimpleCanvas></SimpleCanvas>;
  if (!state.Id || !state.handle) {
    return (
      <View>
        <View>
          <Blocker loading={loading} />
        </View>
      </View>
    );
  } else {
    return (
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          <ConversionBanner
            loggedIn={loggedIn}
            navbar={fromList}
            sharerId={route?.params?.sharerId}
            objectType={"profile"}
            objectId={route?.params?.Id}
            colors={colors}
            myContext={myContext}
          />
          {loggedIn && fromList != "" && <View style={{ height: 66 }}></View>}
          <Blocker loading={loading} />
          <View
            style={{
              backgroundColor: colors.background,
              borderBottomLeftRadius: 29,
              borderBottomRightRadius: 29,
              marginTop: 24,
            }}
          >
            {folded && (
              <Pressable
                onPress={() => {
                  LayoutAnimation.spring();
                  setFolded((prevFolded) => {
                    return !prevFolded;
                  });
                }}
              >
                <Image source={folddown} style={styles.superposedFolddown} />
              </Pressable>
            )}
            {!folded && (
              <View
                style={{
                  backgroundColor: colors.background,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <ProfileCard
                  item={state}
                  follows={
                    myContext.actions?.["User"]?.["Follow"]?.[state.Id] != null
                      ? true
                      : false
                  }
                  followers={
                    myContext.Id != state.Id ? state.mutualFollowers : []
                  }
                  facts={
                    <View style={styles.factlist}>
                      <Pressable
                        style={styles.pressableContainer}
                        onPress={() => {}}
                      >
                        <View style={styles.multipartFact}>
                          <Text style={sharedStyles.coloredFact}>
                            {myContext.actionsByUser?.["Experiment"]?.[
                              "Join"
                            ]?.["ShowProblems"] == null
                              ? Object.keys(state.followings).length
                              : state.numUserFollow}
                          </Text>
                          <Text style={sharedStyles.fact}>{"Following"}</Text>
                        </View>
                      </Pressable>
                      <Pressable
                        style={styles.pressableContainer}
                        onPress={() => {}}
                      >
                        <View style={styles.multipartFact}>
                          <Text style={sharedStyles.coloredFact}>
                            {myContext.actionsByUser?.["Experiment"]?.[
                              "Join"
                            ]?.["ShowProblems"] == null
                              ? Object.keys(state.followers).length
                              : state.numFollow}
                          </Text>
                          <Text style={sharedStyles.fact}>{"Followers"}</Text>
                        </View>
                      </Pressable>
                      <View style={{ flex: 1 }}>
                        {displayedWebsite != "" && (
                          <Pressable
                            style={styles.pressableContainer}
                            onPress={() => {
                              clog("asked to open website", state.websites);
                              if (Platform.OS == "web") {
                                window.open(state.websites, "_blank");
                              } else {
                                setModalVisible(true);
                              }
                              logEvent(
                                myContext?.Id == state.Id
                                  ? "MyProfile_Website"
                                  : "OtherProfile_Website",
                                {
                                  userId: myContext?.Id,
                                  username: myContext?.handle,
                                  category:
                                    myContext?.Id == state.Id
                                      ? "myprofile"
                                      : "otherprofile",
                                  platform: Platform.OS,
                                  action: "click",
                                  target: "content",
                                  targetuser: state.handle,
                                  appVersion: myContext.appVersion,
                                }
                              );
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <View style={sharedStyles.coloredFact}>
                                <Image
                                  source={link}
                                  style={{
                                    height: 19,
                                    width: 19,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                />
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 16,
                                    color: "white",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  numberOfLines={1}
                                  ellipsizeMode="tail"
                                >
                                  {displayedWebsite}
                                </Text>
                              </View>
                            </View>
                          </Pressable>
                        )}
                      </View>
                    </View>
                  }
                  myContext={myContext}
                  colors={colors}
                ></ProfileCard>
              </View>
            )}
          </View>
          {refreshing ? <ActivityIndicator /> : null}
          <View style={{ flex: 1 }}>
            {true &&
              tab == 1 &&
              myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
                "TopicBubblesInProfile"
              ] == null && (
                <View style={{ paddingLeft: 13, flex: 1 }}>
                  <FlatList
                    data={topicsAggregated}
                    style={{
                      flex: 1,
                      marginBottom: 54,
                    }}
                    onScrollEndDrag={() => clog("ended scrolling")}
                    onScrollBeginDrag={() => {
                      clog("starting to scroll");
                      if (
                        !folded &&
                        myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
                          "ProfileMinimizationDuringFlatlistScroll"
                        ]
                      ) {
                        LayoutAnimation.spring();
                        setFolded(true);
                      }
                    }}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshing}
                        onRefresh={() => {
                          //clog("got asked to refresh");
                          if (!refreshing) {
                            //clog("refreshing data now");
                            setRefreshing(true);
                            getData(handle, Id).catch((err) => {
                              console.log("error finding user: ", err);
                            });
                          } else {
                            clog("refresh data in progress already");
                          }
                        }}
                      />
                    }
                    renderItem={({ item }) => {
                      let length = item.keyword.length;
                      let fontSize = Math.floor(Number(200 / length));
                      fontSize = 14;
                      let targetLength = 150;
                      return (
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 10,
                            marginVertical: 10,
                            width: targetLength,
                            height: targetLength,
                            borderRadius: targetLength,
                            padding: 10,
                            overflow: "hidden",
                            backgroundColor: colors.primaryButtonBackground,
                          }}
                        >
                          <Text
                            style={{
                              flexWrap: "wrap",
                              alignSelf: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              textAlignVertical: "center",
                              alignItems: "center",
                              fontSize: fontSize,
                              fontWeight: "500",
                              color: "white",
                              borderWidth: 1,
                              borderColor: colors.secondaryButtonBorder,
                              backgroundColor: colors.background,
                            }}
                          >
                            {item.keyword}
                          </Text>
                        </View>
                      );
                    }}
                    keyExtractor={(item) => item?.Id}
                    numColumns={2}
                  />
                </View>
              )}
            <FlatList
              data={state.lists}
              style={{
                flex: 1,
                marginBottom: 54,
              }}
              onScrollEndDrag={() => clog("ended scrolling")}
              onScrollBeginDrag={() => {
                clog("starting to scroll");
                if (
                  !folded &&
                  myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
                    "ProfileMinimizationDuringFlatlistScroll"
                  ]
                ) {
                  LayoutAnimation.spring();
                  setFolded(true);
                }
              }}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => {
                    //clog("got asked to refresh");
                    if (!refreshing) {
                      //clog("refreshing data now");
                      setRefreshing(true);
                      getData(handle, Id).catch((err) => {
                        console.log("error finding user: ", err);
                      });
                    } else {
                      clog("refresh data in progress already");
                    }
                  }}
                />
              }
              renderItem={({ item }) => {
                return (
                  <Pressable
                    onPress={() => {
                      //clog("pressed for content list", item.Id);
                      navigation.push("GuestContentList", {
                        Id: item.Id,
                        name: item.name,
                        description: item.description,
                        owner: state,
                        screenshotUrl: item.screenshotUrl,
                        fromUser: true,
                      });
                      logEvent(
                        myContext?.Id == state.Id
                          ? "MyProfile_Collection_Detail"
                          : "OtherProfile_Collection_Detail",
                        {
                          userId: myContext?.Id,
                          username: myContext?.handle,
                          category:
                            myContext?.Id == state.Id
                              ? "myprofile"
                              : "otherprofile",
                          platform: Platform.OS,
                          action: "click",
                          target: "detail",
                          targetuser: state.handle,
                          appVersion: myContext.appVersion,
                        }
                      );
                    }}
                  >
                    <List item={item} myContext={myContext} colors={colors} />
                  </Pressable>
                );
              }}
              keyExtractor={(item) => item?.Id}
              numColumns={1}
            />
          </View>
          <Modal
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(false);
            }}
          >
            <View
              style={[styles.container, { backgroundColor: colors.background }]}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    backgroundColor: colors.background,
                    height: 39,
                    paddingVertical: 10,
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Pressable
                    onPress={() => {
                      clog("will try to open", state.websites);
                      setModalVisible(false);
                    }}
                  >
                    <Image
                      source={cross}
                      style={{
                        marginRight: 20,
                        height: 19,
                        width: 17,
                        tintColor: colors.primaryText,
                      }}
                    />
                  </Pressable>
                </View>
                <WebView
                  style={{ flex: 1 }}
                  source={{ uri: state.websites }}
                  ref={(r) => (webref = r)}
                  originWhitelist={["*"]}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                />
              </View>
            </View>
          </Modal>
        </View>
      </View>
    );
  }
};

export default GuestUserDetailsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  multipartFact: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: 19,
  },
  factlist: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
    paddingRight: 20,
    width: "100%",
    height: 29,
    justifyContent: "space-between",
  },
  pressableContainer: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
  },
  superposedFolddown: {
    height: 57,
    width: 54,
    alignSelf: "center",
  },
});
