import React, { useState } from "react";
import { StyleSheet, Text, View, FlatList, Pressable } from "react-native";

import { performAction, undoAction } from "../utils/Action";
import ExperimentCard from "./ExperimentCard";
import { clog } from "../utils/Log";

const ExperimentList = ({
  state,
  myContext,
  navigation,
  renderHeader,
  refererActionId,
  hostUserId,
  colors,
}) => {
  const [loading, setLoading] = useState(false);

  const renderItem = ({ item }) => {
    let joined = false;
    let relationshipId = null;
    if (myContext?.actionsByUser?.["Experiment"]?.["Join"]?.[item.Id]) {
      joined = true;
      relationshipId = Object.keys(
        myContext.actionsByUser["Experiment"]["Join"][item.Id]
      )?.[0];
    }
    clog("item", item, "joined", joined);
    let fn = joined ? undoAction : performAction;
    ////clog("state is", state);
    let updatefn = joined ? state.removeFunction : state.addFunction;
    ////clog("log update function", updatefn);
    let callback = (success, modifiedRelationshipId, createdAt) => {
      if (success) {
        if (joined) {
          item.numJoin--;
        } else {
          item.numJoin++;
        }
        updatefn(item.Id, modifiedRelationshipId, item, createdAt);
        setLoading(false);
      } else {
        clog("failed to peform action");
      }
      ////clog("app context is", myContext);
    };
    return (
      <View
        style={[
          styles.joinableExperiment,
          { backgroundColor: colors.secondaryBackground },
        ]}
      >
        <View style={{ flex: 1, marginRight: 10 }}>
          <Pressable
            onPress={() => {
              ////clog("pressed button for", item);
              navigation.push("ExperimentDetails", {
                Id: item?.Id,
                handle: item?.handle,
                fromList: true,
                refererActionId: refererActionId,
                hostUserId: hostUserId,
              });
            }}
          >
            <ExperimentCard item={item} myContext={myContext} colors={colors} />
          </Pressable>
        </View>
        <View style={{}}>
          <Pressable
            onPress={() => {
              setLoading(true);
              fn({
                objectType: "Experiment",
                operation: "Join",
                objectId: item?.Id,
                actorId: myContext?.Id,
                relationshipId: relationshipId,
                objectCounter: item?.numJoin,
                userCounter: myContext?.numExperimentJoin,
                curatorId: item?.curatorId,
                curatorCounter: item?.curator?.numJoinRecursive,
                promises: [],
                payloads: [],
                callback: callback,
                refererActionId: refererActionId,
                hostUserId: hostUserId,
                seq: null,
              });
            }}
          >
            <View
              style={
                joined
                  ? [
                      styles.uncoloredButton,
                      {
                        color: colors.secondayButtonText,
                        borderColor: colors.secondaryButtonBorder,
                      },
                    ]
                  : [
                      styles.coloredButton,
                      {
                        color: colors.primaryButtonText,
                        backgroundColor: colors.primaryButtonBackground,
                      },
                    ]
              }
            >
              <Text
                style={[
                  styles.text,
                  {
                    color: joined
                      ? colors.secondaryButtonText
                      : colors.primaryButtonText,
                  },
                ]}
              >
                {joined ? "Joined" : "Join"}
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
    );
  };

  const sharedKeyExtractor = (item) => item.Id;
  clog("state", state);
  return (
    <FlatList
      title={state?.relationship}
      data={state?.experiments}
      renderItem={renderItem}
      keyExtractor={sharedKeyExtractor}
      extraData={state}
    />
  );
};

export default ExperimentList;

const styles = StyleSheet.create({
  joinableExperiment: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 16,
    paddingLeft: 20,
    paddingRight: 25,
    marginTop: 10,
    height: 69,
  },
  coloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
  },
  uncoloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
    borderWidth: 2,
  },
  text: { fontSize: 12 },
});
