import uuid from "react-native-uuid";
import { Storage } from "aws-amplify";
import * as ImageManipulator from "expo-image-manipulator";

export const uploadPhotoWeb = async (prefix, imageURI) => {
  try {
    // new code for images
    let imageExt = "";
    const re = /data:(image\/([^;]+));/;
    const found = imageURI.match(re);
    if (found && found.length > 0) {
      imageExt = found[2];
    } else {
      imageExt = imageURI.split(".").pop();
    }

    const extPattern = /^([^\?]*)\?.*$/;
    const match = imageExt.match(extPattern);
    if (match && match.length > 0) {
      imageExt = match[1];
    }

    let imageKey = prefix + uuid.v4() + "." + imageExt;
    let uri = imageURI;

    const manipResult = await ImageManipulator.manipulateAsync(imageURI, [], {
      compress: 1,
      format: ImageManipulator.SaveFormat.JPEG,
    });
    uri = manipResult.uri;

    const photo = await fetch(uri);

    //const photo = await fetch(imageURI);
    ////clog("photo is", photo);
    const photoBlob = await photo.blob();
    let response = await Storage.put(imageKey, photoBlob);
    //clog("response", response);
    return [true, imageKey];
  } catch (err) {
    console.log("error uploading photo:", err);
    return [false, err];
  }
};
