import { React, forwardRef, useImperativeHandle, useRef } from "react";
import {
  FlatList,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useTheme } from "../theme";
import share from "../assets/share.png";
import copy from "../assets/copy.png";
import browser from "../assets/browser.png";
import CloseButton from "./CloseButton";
import { Portal } from "react-native-portalize";
import { Modalize } from "react-native-modalize";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const ShareSheet = (props, ref) => {
  const { action } = props;

  const { colors } = useTheme();
  const modalizeRef = useRef(null);
  const insets = useSafeAreaInsets();

  const shareActions = [
    { key: "share", label: "Share post link", icon: share },
    { key: "copy_link", label: "Copy post link", icon: copy },
    {
      key: "copy_content_link",
      label: "Copy original content link",
      icon: copy,
    },
    {
      key: "open_in_browser",
      label: "Open original content in browser",
      icon: browser,
    },
  ];

  const onOpen = () => {
    modalizeRef.current?.open();
  };

  const onClose = () => {
    modalizeRef.current?.close();
  };

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
      onClose,
    }),
    []
  );

  const ListHeaderComponent = () => {
    return (
      <View style={styles.listHeaderContainer}>
        <Text style={[styles.title, { color: colors.primaryText }]}>
          {"Share outside village"}
        </Text>

        <CloseButton onPress={onClose} />
      </View>
    );
  };

  const renderItem = ({ item }) => {
    return (
      <Pressable style={styles.itemContainer} onPress={() => action(item.key)}>
        <Image
          source={item.icon}
          style={[
            styles.actionIcon,
            {
              tintColor: colors.primaryText,
            },
          ]}
        />
        <Text style={[styles.label, { color: colors.primaryText }]}>
          {item.label}
        </Text>
      </Pressable>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight={true}
        modalStyle={[
          styles.modalContainer,
          { backgroundColor: colors.background },
        ]}
        scrollViewProps={{ showsVerticalScrollIndicator: false }}
        withHandle={false}
      >
        <FlatList
          data={shareActions}
          renderItem={renderItem}
          ListHeaderComponent={ListHeaderComponent}
        />
        <View style={{ height: insets.bottom ? insets.bottom : 10 }} />
      </Modalize>
    </Portal>
  );
};

export default forwardRef(ShareSheet);

const styles = StyleSheet.create({
  modalContainer: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 22,
  },
  label: {
    fontSize: 15,
    fontWeight: "400",
    lineHeight: 22,
  },
  listHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 21,
    marginBottom: 11,
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 16,
  },
  actionIcon: {
    height: 24,
    width: 24,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
});
