export const listTopics = /* GraphQL */ `
  query ListTopics($numRequested: Int, $nextToken: String) {
    listTopics(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        name
        description
        avatar
        topLevel
        depth
        onboarding
        seq
        parentTopicId
        numView
        numFollow
        numUrls
        curator {
          Id
          acl
          handle
          name
          firstName
          lastName
          avatar
          avatarUrl
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
        }
      }
    }
  }
`;

export const addAction = /* GraphQL */ `
  mutation AddAction(
    $Id: String!
    $objectType: String!
    $operation: String!
    $objectId: String!
    $actorId: String!
    $userId: String
    $listId: String
    $pinId: String
    $urlId: String
    $commentId: String
    $topicId: String
    $sourceId: String
    $mutationActorId: String
    $queryActorId: String
    $refererActionId: String
    $hostUserId: String
    $actorIdOperationObjectType: String
    $actorIdOperationObjectId: String
    $objectIdOperation: String
    $creationTS: Int
    $note: String
  ) {
    createAction(
      input: {
        Id: $Id
        objectType: $objectType
        operation: $operation
        objectId: $objectId
        actorId: $actorId
        userId: $userId
        listId: $listId
        pinId: $pinId
        urlId: $urlId
        commentId: $commentId
        topicId: $topicId
        sourceId: $sourceId
        mutationActorId: $mutationActorId
        queryActorId: $queryActorId
        refererActionId: $refererActionId
        hostUserId: $hostUserId
        actorIdOperationObjectType: $actorIdOperationObjectType
        actorIdOperationObjectId: $actorIdOperationObjectId
        objectIdOperation: $objectIdOperation
        creationTS: $creationTS
        note: $note
      }
    ) {
      Id
      objectType
      operation
      userId
      objectId
      listId
      commentId
      actorId
      pinId
      sourceId
      topicId
      urlId
      mutationActorId
      queryActorId
      refererActionId
      hostUserId
      actorIdOperationObjectType
      actorIdOperationObjectId
      objectIdOperation
      creationTS
      createdAt
      note
    }
  }
`;

export const removeAction = /* GraphQL */ `
  mutation RemoveAction($Id: String!) {
    deleteAction(input: { Id: $Id }) {
      Id
    }
  }
`;

export const addNotification = /* GraphQL */ `
  mutation AddNotification(
    $Id: String!
    $recipientId: String!
    $actorId: String!
    $actionId: String!
    $targetId: String!
    $targetType: String!
    $operation: String!
    $userId: String
    $urlId: String
    $pinId: String
    $commentId: String
    $creationTS: Int
  ) {
    createNotification(
      input: {
        Id: $Id
        recipientId: $recipientId
        actorId: $actorId
        actionId: $actionId
        targetId: $targetId
        targetType: $targetType
        operation: $operation
        userId: $userId
        urlId: $urlId
        pinId: $pinId
        commentId: $commentId
        creationTS: $creationTS
      }
    ) {
      Id
      recipientId
      actorId
      actionId
      targetId
      targetType
      operation
      userId
      urlId
      pinId
      commentId
      creationTS
      createdAt
    }
  }
`;

export const removeNotification = /* GraphQL */ `
  mutation RemoveNotification($Id: String!) {
    deleteNotification(input: { Id: $Id }) {
      Id
    }
  }
`;

export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $Id: String!
    $recipientId: String
    $actorId: String
    $actionId: String
    $targetId: String
    $targetType: String
    $operation: String
    $userId: String
    $urlId: String
    $pinId: String
    $commentId: String
    $creationTS: Int
  ) {
    updateNotification(
      input: {
        Id: $Id
        recipientId: $recipientId
        actorId: $actorId
        actionId: $actionId
        targetId: $targetId
        targetType: $targetType
        operation: $operation
        userId: $userId
        urlId: $urlId
        pinId: $pinId
        commentId: $commentId
        creationTS: $creationTS
      }
    ) {
      Id
      recipientId
      actorId
      actionId
      targetId
      targetType
      operation
      userId
      urlId
      pinId
      commentId
      creationTS
      createdAt
    }
  }
`;

export const getNotifications = /* GraphQL */ `
  query GetNotifications($recipientId: String!, $nextToken: String) {
    notificationForRecipient(
      recipientId: $recipientId
      sortDirection: DESC
      nextToken: $nextToken
      limit: 200
    ) {
      items {
        Id
        recipientId
        actorId
        actionId
        targetId
        targetType
        operation
        userId
        urlId
        pinId
        commentId
        creationTS
        createdAt
        actor {
          Id
          avatar
          avatarUrl
          handle
          name
        }
        user {
          Id
          avatar
          avatarUrl
          handle
          name
        }
        url {
          Id
          title
          type
          uri
          sourceId
          source {
            Id
            name
            enableJavascript
            avatar
            avatarUrl
          }
          photo
          photoUrl
        }
        pin {
          Id
          curatorId
          content
          markup
          list {
            Id
            name
          }
        }
        comment {
          Id
          curatorId
          content
        }
        action {
          Id
          actorId
          actor {
            Id
            handle
            avatar
            name
          }
          operation
          objectType
          createdAt
          refererActionId
          hostUserId
          createdAt
        }
      }
    }
  }
`;

export const getNotificationByActionId = /* GraphQL */ `
  query GetNotificationByActionId($actionId: String!) {
    notificationByActionId(actionId: $actionId) {
      items {
        Id
        actionId
      }
    }
  }
`;

export const getMinimalNotifications = /* GraphQL */ `
  query GetMinimalNotifications($recipientId: String!, $nextToken: String) {
    notificationForRecipient(
      recipientId: $recipientId
      sortDirection: DESC
      nextToken: $nextToken
      limit: 10000
    ) {
      items {
        Id
        recipientId
        actorId
        actionId
        targetId
        targetType
        operation
        userId
        urlId
        pinId
        commentId
        creationTS
        createdAt
      }
    }
  }
`;

export const getTopicCounts = /* GraphQL */ `
  query getTopicCounts($Id: String!) {
    getTopic(Id: $Id) {
      numFollow
      numUrls
      numView
    }
  }
`;

export const updateTopicCounts = /* GraphQL */ `
  mutation UpdateTopicCounts(
    $Id: String!
    $numFollow: Int
    $numView: Int
    $numUrls: Int
  ) {
    updateTopic(
      input: {
        Id: $Id
        numFollow: $numFollow
        numUrls: $numUrls
        numView: $numView
      }
    ) {
      Id
      numFollow
      numUrls
      numView
    }
  }
`;

export const getUserCounts = /* GraphQL */ `
  query GetUserCounts($Id: String!) {
    getUser(Id: $Id) {
      Id
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numReportRecursive
      numPinRecursive
      numCommentRecursive
      numFollowRecursive
      numCreateRecursive
      numShareRecursive
      numJoinRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numCommentView
      numCommentLike
      numCommentCreate
      numTopicView
      numTopicFollow
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      nextListSeq
      lastRecommendationCreationTime
      lastRecommendationExpansionTime
      lastRecommendationRefreshTime
      currentRecommendationBatchSequence
      currentRecommendationItemSequence
      currentRecommendationExpansionCount
      currentRecommendationRefreshCount
      lastRecommendationNotificationTime
      currentRecommendationCount
      numAvailableRecommendations
      lowestRecommendationScore
      lastNotificationPermissionRequestTime
      notificationPermissionRequestCount
      lastAppUpgradeRecommendationTime
      appUpgradeRecommendationCount
      lastTopicUpdateTime
      lastTopicUpdateRecommendationTime
      topicUpdateRecommendationCount
      chosenTheme
    }
  }
`;

export const updateUserCounts = /* GraphQL */ `
  mutation UpdateUserCounts(
    $Id: String!
    $token: String
    $numView: Int
    $numFollow: Int
    $numShare: Int
    $numViewRecursive: Int
    $numLikeRecursive: Int
    $numPinRecursive: Int
    $numCommentRecursive: Int
    $numFollowRecursive: Int
    $numCreateRecursive: Int
    $numShareRecursive: Int
    $numJoinRecursive: Int
    $numReportRecursive: Int
    $numUserView: Int
    $numUserFollow: Int
    $numUserShare: Int
    $numListView: Int
    $numListLike: Int
    $numListFollow: Int
    $numListShare: Int
    $numListCreate: Int
    $numPinView: Int
    $numPinLike: Int
    $numPinCreate: Int
    $numUrlView: Int
    $numUrlContribute: Int
    $numUrlAuthor: Int
    $numCommentView: Int
    $numCommentLike: Int
    $numCommentCreate: Int
    $numTopicView: Int
    $numTopicFollow: Int
    $numSourceView: Int
    $numSourceLike: Int
    $numSourceFollow: Int
    $numExperimentJoin: Int
    $numExperimentCreate: Int
    $topicIds: String
    $declaredTopicIds: String
    $curatedTopicIds: String
    $consumedTopicIds: String
    $engagedTopicIds: String
    $viewedTopicIds: String
    $visitedTopicIds: String
    $nextListSeq: Int
    $lastRecommendationCreationTime: Int
    $lastRecommendationExpansionTime: Int
    $lastRecommendationRefreshTime: Int
    $currentRecommendationBatchSequence: Int
    $currentRecommendationItemSequence: Int
    $currentRecommendationExpansionCount: Int
    $currentRecommendationRefreshCount: Int
    $lastRecommendationNotificationTime: Int
    $currentRecommendationCount: Int
    $numAvailableRecommendations: Int
    $lowestRecommendationScore: Int
    $lastNotificationPermissionRequestTime: Int
    $notificationPermissionRequestCount: Int
    $lastAppUpgradeRecommendationTime: Int
    $appUpgradeRecommendationCount: Int
    $lastTopicUpdateTime: Int
    $lastTopicUpdateRecommendationTime: Int
    $topicUpdateRecommendationCount: Int
    $chosenTheme: String
    $wishList: String
  ) {
    updateUser(
      input: {
        Id: $Id
        token: $token
        numView: $numView
        numFollow: $numFollow
        numShare: $numShare
        numViewRecursive: $numViewRecursive
        numLikeRecursive: $numLikeRecursive
        numReportRecursive: $numReportRecursive
        numPinRecursive: $numPinRecursive
        numCommentRecursive: $numCommentRecursive
        numFollowRecursive: $numFollowRecursive
        numCreateRecursive: $numCreateRecursive
        numShareRecursive: $numShareRecursive
        numJoinRecursive: $numJoinRecursive
        numUserView: $numUserView
        numUserFollow: $numUserFollow
        numUserShare: $numUserShare
        numListView: $numListView
        numListLike: $numListLike
        numListFollow: $numListFollow
        numListShare: $numListShare
        numListCreate: $numListCreate
        numPinView: $numPinView
        numPinLike: $numPinLike
        numPinCreate: $numPinCreate
        numUrlView: $numUrlView
        numUrlContribute: $numUrlContribute
        numUrlAuthor: $numUrlAuthor
        numCommentView: $numCommentView
        numCommentLike: $numCommentLike
        numCommentCreate: $numCommentCreate
        numTopicView: $numTopicView
        numTopicFollow: $numTopicFollow
        numSourceView: $numSourceView
        numSourceLike: $numSourceLike
        numSourceFollow: $numSourceFollow
        numExperimentJoin: $numExperimentJoin
        numExperimentCreate: $numExperimentCreate
        topicIds: $topicIds
        declaredTopicIds: $declaredTopicIds
        curatedTopicIds: $curatedTopicIds
        consumedTopicIds: $consumedTopicIds
        engagedTopicIds: $engagedTopicIds
        viewedTopicIds: $viewedTopicIds
        visitedTopicIds: $visitedTopicIds
        nextListSeq: $nextListSeq
        lastRecommendationCreationTime: $lastRecommendationCreationTime
        lastRecommendationExpansionTime: $lastRecommendationExpansionTime
        lastRecommendationRefreshTime: $lastRecommendationRefreshTime
        currentRecommendationBatchSequence: $currentRecommendationBatchSequence
        currentRecommendationItemSequence: $currentRecommendationItemSequence
        currentRecommendationExpansionCount: $currentRecommendationExpansionCount
        currentRecommendationRefreshCount: $currentRecommendationRefreshCount
        lastRecommendationNotificationTime: $lastRecommendationNotificationTime
        currentRecommendationCount: $currentRecommendationCount
        numAvailableRecommendations: $numAvailableRecommendations
        lowestRecommendationScore: $lowestRecommendationScore
        lastNotificationPermissionRequestTime: $lastNotificationPermissionRequestTime
        notificationPermissionRequestCount: $notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime: $lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount: $appUpgradeRecommendationCount
        lastTopicUpdateTime: $lastTopicUpdateTime
        lastTopicUpdateRecommendationTime: $lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount: $topicUpdateRecommendationCount
        chosenTheme: $chosenTheme
        wishList: $wishList
      }
    ) {
      Id
      token
      label
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numReportRecursive
      numPinRecursive
      numCommentRecursive
      numFollowRecursive
      numCreateRecursive
      numShareRecursive
      numJoinRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numCommentView
      numCommentLike
      numCommentCreate
      numTopicView
      numTopicFollow
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      nextListSeq
      lastRecommendationCreationTime
      lastRecommendationExpansionTime
      lastRecommendationRefreshTime
      currentRecommendationBatchSequence
      currentRecommendationItemSequence
      currentRecommendationExpansionCount
      currentRecommendationRefreshCount
      lastRecommendationNotificationTime
      currentRecommendationCount
      numAvailableRecommendations
      lowestRecommendationScore
      lastNotificationPermissionRequestTime
      notificationPermissionRequestCount
      lastAppUpgradeRecommendationTime
      appUpgradeRecommendationCount
      lastTopicUpdateTime
      lastTopicUpdateRecommendationTime
      topicUpdateRecommendationCount
      chosenTheme
      wishList
    }
  }
`;

export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $Id: String!
    $handle: String
    $name: String
    $firstName: String
    $lastName: String
    $headline: String
    $websites: String
    $location: String
    $avatar: String
  ) {
    updateUser(
      input: {
        Id: $Id
        handle: $handle
        name: $name
        firstName: $firstName
        lastName: $lastName
        headline: $headline
        websites: $websites
        location: $location
        avatar: $avatar
      }
    ) {
      Id
      handle
      name
      firstName
      lastName
      headline
      websites
      location
      avatar
    }
  }
`;

export const getListsList = /* GraphQL */ `
  query GetListsList($Id: String!) {
    getUser(Id: $Id) {
      numListCreate
      actionsByUser(
        filter: { operation: { eq: "Create" }, objectType: { eq: "List" } }
      ) {
        items {
          Id
          operation
          objectId
          objectType
          list {
            Id
            name
            description
            seq
            numView
            numLike
            numReport
            numFollow
            numShare
            numComment
            numPins
            topicIds
            nextPinSeq
          }
        }
      }
    }
  }
`;

export const getList = /* GraphQL */ `
  query GetList($Id: String!) {
    getList(Id: $Id) {
      Id
      creatorId
      acl
      curatorId
      curator {
        Id
        handle
        name
        firstName
        lastName
        avatar
      }
      name
      description
      seq
      nextPinSeq
      numView
      numLike
      numReport
      numFollow
      numShare
      numComment
      numPins
      topicIds
      pins(limit: 1000) {
        items {
          Id
          acl
          seq
          content
          reaction
          markup
          createdAt
          url {
            Id
            uri
            title
            type
            sourceId
            source {
              Id
              name
              enableJavascript
              avatar
              avatarUrl
            }
            photo
            photoUrl
            snippet
            duration
            authorName
            curatorIds
            listIds
            topicIds
            commenterIds
            likerIds
            viewerIds
            createdAt
            creationTS
            numComment
            numLike
            numReport
            numContribute
            numPins
            numShare
            numView
            numLongView
            numTotalView
            numLongVisit
            numTotalVisit
            comments {
              items {
                curatorId
                content
                Id
                numLike
                numReport
                createdAt
                actions {
                  items {
                    Id
                    actorId
                    operation
                    objectType
                    createdAt
                    refererActionId
                    hostUserId
                  }
                }
                comments {
                  items {
                    curatorId
                    content
                    Id
                    numLike
                    numReport
                    createdAt
                    actions {
                      items {
                        Id
                        actorId
                        operation
                        objectType
                        createdAt
                        refererActionId
                        hostUserId
                      }
                    }
                  }
                }
              }
            }
            pins {
              items {
                Id
                seq
                content
                reaction
                createdAt
                curatorId
                numView
                numLike
                numReport
                numComment
                numLongView
                numTotalView
                numLongVisit
                numTotalVisit
                markup
                list {
                  Id
                  name
                }
                actions {
                  items {
                    Id
                    actorId
                    operation
                    objectType
                    createdAt
                    refererActionId
                    hostUserId
                  }
                }
                comments {
                  items {
                    curatorId
                    content
                    Id
                    numLike
                    numReport
                    createdAt
                    actions {
                      items {
                        Id
                        actorId
                        operation
                        objectType
                        createdAt
                        refererActionId
                        hostUserId
                      }
                    }
                  }
                }
              }
            }
          }
          list {
            Id
            name
          }
          numComment
          numLike
          numReport
          numView
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          actions {
            items {
              Id
              actorId
              operation
              objectType
              createdAt
              refererActionId
              hostUserId
            }
          }
        }
      }
    }
  }
`;

export const getListByCuratorIdAndListName = /* GraphQL */ `
  query GetListByCuratorIdAndListName($curatorId: String!, $listName: String!) {
    listByListNameCuratorId(name: $listName, curatorId: { eq: $curatorId }) {
      items {
        Id
        creatorId
        creator {
          Id
          handle
        }
        acl
        curatorId
        curator {
          Id
          acl
          handle
          name
          firstName
          lastName
          avatar
          avatarUrl
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
        }
        name
        description
        seq
        nextPinSeq
        numView
        numLike
        numReport
        numFollow
        numShare
        numComment
        numPins
        topicIds
        pins {
          items {
            Id
            acl
            seq
            content
            createdAt
            url {
              Id
              uri
              title
              type
              sourceId
              source {
                Id
                name
                enableJavascript
                avatar
                avatarUrl
              }
              photo
              photoUrl
              snippet
              duration
              authorId
              author {
                Id
                name
              }
              authorName
              topics {
                items {
                  topic {
                    name
                    Id
                    avatar
                  }
                }
              }
              curatorIds
              listIds
              topicIds
              commenterIds
              likerIds
              viewerIds
              comments {
                items {
                  Id
                  curator {
                    Id
                    acl
                    handle
                    name
                    firstName
                    lastName
                    avatar
                    avatarUrl
                    numView
                    numFollow
                    numShare
                    numViewRecursive
                    numLikeRecursive
                    numReportRecursive
                    numPinRecursive
                    numCommentRecursive
                    numFollowRecursive
                    numCreateRecursive
                    numShareRecursive
                    numJoinRecursive
                    numUserView
                    numUserFollow
                    numUserShare
                    numListView
                    numListLike
                    numListFollow
                    numListShare
                    numListCreate
                    numPinView
                    numPinLike
                    numPinCreate
                    numUrlView
                    numUrlContribute
                    numUrlAuthor
                    numCommentView
                    numCommentLike
                    numCommentCreate
                    numTopicView
                    numTopicFollow
                    numSourceView
                    numSourceLike
                    numSourceFollow
                    numExperimentJoin
                    numExperimentCreate
                  }
                  content
                  createdAt
                  numLike
                  numReport
                  actions {
                    items {
                      Id
                      actorId
                      operation
                      objectType
                      actor {
                        Id
                        handle
                        name
                        firstName
                        lastName
                        avatar
                        avatarUrl
                      }
                      createdAt
                      refererActionId
                      hostUserId
                    }
                  }
                }
              }
              createdAt
              creationTS
              numComment
              numContribute
              numPins
              numShare
              numView
              numLike
              numReport
              numLongView
              numTotalView
              numLongVisit
              numTotalVisit
              actions {
                items {
                  Id
                  actorId
                  operation
                  objectType
                  actor {
                    Id
                    name
                    firstName
                    lastName
                  }
                  createdAt
                  refererActionId
                  hostUserId
                }
              }
            }
            numComment
            numLike
            numReport
            numView
            list {
              Id
              name
            }
            actions {
              items {
                Id
                actorId
                operation
                objectType
                actor {
                  Id
                  name
                  firstName
                  lastName
                }
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
        comments {
          items {
            Id
          }
        }
        topics {
          items {
            topic {
              Id
              name
              avatar
            }
          }
        }
        actions {
          items {
            Id
            operation
            actor {
              Id
              handle
            }
            createdAt
            refererActionId
            hostUserId
          }
        }
      }
    }
  }
`;

export const getListByCuratorIdAndListNameMinimal = /* GraphQL */ `
  query GetListByCuratorIdAndListName($curatorId: String!, $listName: String!) {
    listByListNameCuratorId(name: $listName, curatorId: { eq: $curatorId }) {
      items {
        Id
        creatorId
        creator {
          Id
          handle
        }
        acl
        curatorId
        name
        description
        seq
        nextPinSeq
        numView
        numLike
        numReport
        numFollow
        numShare
        numComment
        numPins
        topicIds
      }
    }
  }
`;

export const getListByCuratorIdAndListNameForSharing = /* GraphQL */ `
  query GetListByCuratorIdAndListName($curatorId: String!, $listName: String!) {
    listByListNameCuratorId(name: $listName, curatorId: { eq: $curatorId }) {
      items {
        Id
        creatorId
        creator {
          Id
          handle
        }
        acl
        curatorId
        curator {
          Id
          acl
          handle
          name
          firstName
          lastName
          avatar
          avatarUrl
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
        }
        name
        description
        seq
        nextPinSeq
        numView
        numLike
        numReport
        numFollow
        numShare
        numComment
        numPins
        topicIds
        pins {
          items {
            Id
            acl
            seq
            content
            createdAt
            url {
              Id
              uri
              title
              type
              sourceId
              source {
                Id
                name
                enableJavascript
                avatar
                avatarUrl
              }
              photo
              photoUrl
              snippet
              duration
              authorId
              author {
                Id
                name
              }
              authorName
              topics {
                items {
                  topic {
                    name
                    Id
                    avatar
                  }
                }
              }
              curatorIds
              listIds
              topicIds
              commenterIds
              likerIds
              viewerIds
              createdAt
              creationTS
              numComment
              numContribute
              numPins
              numShare
              numView
              numLike
              numReport
              numLongView
              numTotalView
              numLongVisit
              numTotalVisit
            }
            numComment
            numLike
            numReport
            numView
            list {
              Id
              name
            }
            actions {
              items {
                Id
                actorId
                operation
                objectType
                actor {
                  Id
                  name
                  firstName
                  lastName
                }
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
      }
    }
  }
`;

export const addList = /* GraphQL */ `
  mutation AddList(
    $Id: String!
    $creatorId: String!
    $curatorId: String!
    $name: String!
    $description: String
    $seq: Int!
  ) {
    createList(
      input: {
        Id: $Id
        creatorId: $creatorId
        curatorId: $curatorId
        name: $name
        description: $description
        seq: $seq
        numView: 0
        numLike: 0
        numFollow: 0
        numShare: 0
        numComment: 0
        numPins: 0
        topicIds: ""
        nextPinSeq: 1
      }
    ) {
      Id
      name
      seq
    }
  }
`;

export const removeList = /* GraphQL */ `
  mutation RemoveList($Id: String!) {
    deleteList(input: { Id: $Id }) {
      Id
      name
    }
  }
`;

export const updateListDetails = /* GraphQL */ `
  mutation UpdateList($Id: String!, $name: String!, $description: String) {
    updateList(input: { Id: $Id, name: $name, description: $description }) {
      Id
      name
      description
    }
  }
`;

export const getListCounts = /* GraphQL */ `
  query getListCounts($Id: String!) {
    getList(Id: $Id) {
      Id
      numView
      numLike
      numReport
      numFollow
      numShare
      numComment
      numPins
      topicIds
      seq
      nextPinSeq
    }
  }
`;

export const updateListCounts = /* GraphQL */ `
  mutation UpdateListCounts(
    $Id: String!
    $numView: Int
    $numLike: Int
    $numReport: Int
    $numFollow: Int
    $numShare: Int
    $numComment: Int
    $numPins: Int
    $topicIds: String
    $nextPinSeq: Int
    $seq: Int
  ) {
    updateList(
      input: {
        Id: $Id
        numView: $numView
        numLike: $numLike
        numReport: $numReport
        numFollow: $numFollow
        numShare: $numShare
        numComment: $numComment
        numPins: $numPins
        topicIds: $topicIds
        nextPinSeq: $nextPinSeq
        seq: $seq
      }
    ) {
      Id
      numView
      numLike
      numReport
      numFollow
      numShare
      numComment
      numPins
      topicIds
      nextPinSeq
      seq
    }
  }
`;

export const getPin = /* GraphQL */ `
  query GetPin($Id: String!) {
    getPin(Id: $Id) {
      Id
      acl
      seq
      content
      markup
      createdAt
      curatorId
      curator {
        Id
        handle
        avatar
      }
      url {
        Id
        uri
        title
        type
        sourceId
        source {
          Id
          name
          enableJavascript
          avatar
          avatarUrl
        }
        photo
        photoUrl
        snippet
        duration
        authorId
        author {
          Id
          name
        }
        authorName
        topics {
          items {
            topic {
              name
              Id
              avatar
            }
          }
        }
        curatorIds
        listIds
        topicIds
        commenterIds
        likerIds
        viewerIds
        comments {
          items {
            Id
            curator {
              Id
              acl
              handle
              name
              firstName
              lastName
              avatar
              avatarUrl
              numView
              numFollow
              numShare
              numViewRecursive
              numLikeRecursive
              numReportRecursive
              numPinRecursive
              numCommentRecursive
              numFollowRecursive
              numCreateRecursive
              numShareRecursive
              numJoinRecursive
              numUserView
              numUserFollow
              numUserShare
              numListView
              numListLike
              numListFollow
              numListShare
              numListCreate
              numPinView
              numPinLike
              numPinCreate
              numUrlView
              numUrlContribute
              numUrlAuthor
              numCommentView
              numCommentLike
              numCommentCreate
              numTopicView
              numTopicFollow
              numSourceView
              numSourceLike
              numSourceFollow
              numExperimentJoin
              numExperimentCreate
            }
            content
            createdAt
            numLike
            numReport
            actions {
              items {
                Id
                actorId
                operation
                objectType
                actor {
                  Id
                  handle
                  name
                  firstName
                  lastName
                  avatar
                  avatarUrl
                }
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
        createdAt
        creationTS
        numComment
        numContribute
        numPins
        numShare
        numView
        numLike
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        actions {
          items {
            Id
            actorId
            operation
            objectType
            actor {
              Id
              name
              firstName
              lastName
            }
            createdAt
            refererActionId
            hostUserId
          }
        }
      }
      numComment
      numLike
      numReport
      numView
      list {
        Id
        name
      }
      actions {
        items {
          Id
          actorId
          operation
          objectType
          actor {
            Id
            name
            firstName
            lastName
          }
          createdAt
          refererActionId
          hostUserId
        }
      }
      tldr
    }
  }
`;

export const addPin = /* GraphQL */ `
  mutation CreatePin(
    $Id: String!
    $acl: String
    $creatorId: String!
    $curatorId: String!
    $listId: String!
    $urlId: String!
    $content: String
    $reaction: String
    $seq: Int!
    $markup: Boolean
    $tldr: String
  ) {
    createPin(
      input: {
        Id: $Id
        acl: $acl
        creatorId: $creatorId
        curatorId: $curatorId
        listId: $listId
        urlId: $urlId
        content: $content
        reaction: $reaction
        seq: $seq
        numComment: 0
        numLike: 0
        numView: 0
        numLongView: 0
        numTotalView: 0
        numLongVisit: 0
        numTotalVisit: 0
        markup: $markup
        tldr: $tldr
      }
    ) {
      Id
      tldr
    }
  }
`;

export const removePin = /* GraphQL */ `
  mutation RemovePin($Id: String!) {
    deletePin(input: { Id: $Id }) {
      Id
    }
  }
`;

export const getPinCounts = /* GraphQL */ `
  query getPinCounts($Id: String!) {
    getPin(Id: $Id) {
      Id
      numComment
      numLike
      numReport
      numView
      seq
    }
  }
`;

export const updatePinCounts = /* GraphQL */ `
  mutation UpdatePinCounts(
    $Id: String!
    $numComment: Int
    $numLike: Int
    $numReport: Int
    $numView: Int
    $numLongView: Int
    $numTotalView: Int
    $numLongVisit: Int
    $numTotalVisit: Int
    $seq: Int
    $listId: String
  ) {
    updatePin(
      input: {
        Id: $Id
        numComment: $numComment
        numLike: $numLike
        numReport: $numReport
        numView: $numView
        numLongView: $numLongView
        numTotalView: $numTotalView
        numLongVisit: $numLongVisit
        numTotalVisit: $numTotalVisit
        seq: $seq
        listId: $listId
      }
    ) {
      Id
      numComment
      numLike
      numReport
      numView
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      seq
      listId
    }
  }
`;

export const updatePin = /* GraphQL */ `
  mutation UpdatePin(
    $Id: String!
    $content: String
    $markup: Boolean
    $tldr: String
  ) {
    updatePin(
      input: { Id: $Id, content: $content, markup: $markup, tldr: $tldr }
    ) {
      Id
      content
      markup
      tldr
    }
  }
`;

export const findUrl = /* GraphQL */ `
  query FindUrl($uri: String!) {
    byUrl(uri: $uri) {
      items {
        Id
        acl
        creatorId
        creator {
          Id
          handle
        }
        curatorId
        curator {
          Id
          acl
          handle
          name
          firstName
          lastName
          avatar
          avatarUrl
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
        }
        title
        type
        uri
        sourceId
        source {
          Id
          name
          enableJavascript
          avatar
          avatarUrl
        }
        photo
        photoUrl
        snippet
        duration
        authorId
        author {
          Id
          name
        }
        authorName
        topics {
          items {
            topic {
              name
              Id
              avatar
            }
          }
        }
        creationTS
        numComment
        numContribute
        numPins
        numShare
        numView
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        actions {
          items {
            Id
            actorId
            operation
            objectType
            actor {
              Id
              name
              firstName
              lastName
            }
            createdAt
            refererActionId
            hostUserId
          }
        }
        authorIds
        curatorIds
        listIds
        commenterIds
        likerIds
        viewerIds
        listIds
        topicIds
      }
    }
  }
`;

export const getUrl = /* GraphQL */ `
  query GetUrl($Id: String!) {
    getUrl(Id: $Id) {
      Id
      acl
      creatorId
      creator {
        Id
        handle
      }
      curatorId
      curator {
        Id
        acl
        handle
        name
        firstName
        lastName
        avatar
        avatarUrl
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
      }
      title
      type
      uri
      sourceId
      source {
        Id
        name
        enableJavascript
        avatar
        avatarUrl
      }
      photo
      photoUrl
      snippet
      duration
      authorId
      author {
        Id
        name
      }
      authorName
      topics {
        items {
          topic {
            name
            Id
            avatar
          }
        }
      }
      creationTS
      numComment
      numContribute
      numPins
      numShare
      numView
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      actions {
        items {
          Id
          actorId
          operation
          objectType
          actor {
            Id
            name
            firstName
            lastName
          }
          createdAt
          refererActionId
          hostUserId
        }
      }
      authorIds
      curatorIds
      listIds
      commenterIds
      likerIds
      viewerIds
      topicIds
    }
  }
`;

export const addUrl = /* GraphQL */ `
  mutation AddUrl(
    $Id: String!
    $acl: String
    $creatorId: String!
    $curatorId: String!
    $uri: String!
    $title: String
    $type: String
    $sourceId: String
    $duration: Int
    $photo: String
    $photoUrl: String
    $authorId: String
    $authorName: String
    $snippet: String
    $curatorIds: String!
    $commenterIds: String
    $likerIds: String
    $viewerIds: String
    $topicIds: String
    $listIds: String
    $creationTS: Int!
  ) {
    createUrl(
      input: {
        Id: $Id
        acl: $acl
        creatorId: $creatorId
        curatorId: $curatorId
        uri: $uri
        title: $title
        type: $type
        sourceId: $sourceId
        duration: $duration
        photo: $photo
        photoUrl: $photoUrl
        authorId: $authorId
        authorName: $authorName
        snippet: $snippet
        authorIds: $authorId
        curatorIds: $curatorIds
        commenterIds: $commenterIds
        likerIds: $likerIds
        viewerIds: $viewerIds
        topicIds: $topicIds
        listIds: $listIds
        numComment: 0
        numContribute: 1
        numPins: 1
        numShare: 0
        numView: 0
        numLongView: 0
        numTotalView: 0
        numLongVisit: 0
        numTotalVisit: 0
        dummy: 0
        creationTS: $creationTS
      }
    ) {
      Id
    }
  }
`;

export const removeUrl = /* GraphQL */ `
  mutation RemoveUrl($Id: String!) {
    deleteUrl(input: { Id: $Id }) {
      Id
    }
  }
`;

export const getUrlCounts = /* GraphQL */ `
  query getUrlCounts($Id: String!) {
    getUrl(Id: $Id) {
      Id
      numComment
      numContribute
      numPins
      numShare
      numView
      curatorIds
      likerIds
      viewerIds
      commenterIds
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      topicIds
      listIds
    }
  }
`;

export const updateUrlCounts = /* GraphQL */ `
  mutation UpdateUrlCounts(
    $Id: String!
    $numComment: Int
    $numContribute: Int
    $numPins: Int
    $numShare: Int
    $numView: Int
    $numLike: Int
    $numReport: Int
    $numLongView: Int
    $numTotalView: Int
    $numLongVisit: Int
    $numTotalVisit: Int
    $curatorIds: String
    $likerIds: String
    $viewerIds: String
    $commenterIds: String
    $viewerIds: String
    $topicIds: String
    $listIds: String
  ) {
    updateUrl(
      input: {
        Id: $Id
        numComment: $numComment
        numContribute: $numContribute
        numPins: $numPins
        numShare: $numShare
        numView: $numView
        numLike: $numLike
        numReport: $numReport
        numLongView: $numLongView
        numTotalView: $numTotalView
        numLongVisit: $numLongVisit
        numTotalVisit: $numTotalVisit
        curatorIds: $curatorIds
        likerIds: $likerIds
        viewerIds: $viewerIds
        commenterIds: $commenterIds
        topicIds: $topicIds
        listIds: $listIds
      }
    ) {
      Id
      numComment
      numContribute
      numPins
      numShare
      numView
      numLike
      numReport
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      curatorIds
      likerIds
      viewerIds
      commenterIds
      topicIds
      listIds
    }
  }
`;

export const getUrlForUpdate = /* GraphQL */ `
  query GetUrl($Id: String!) {
    getUrl(Id: $Id) {
      Id
      title
      type
      uri
      sourceId
      source {
        Id
        name
        enableJavascript
        avatar
        avatarUrl
      }
      photo
      photoUrl
      snippet
      duration
      authorId
      author {
        Id
        name
      }
      authorName
      topics {
        items {
          topic {
            Id
            name
            avatar
            numUrls
          }
        }
      }
      authorIds
      topicIds
      pins {
        items {
          Id
          curatorId
          curator {
            Id
            handle
            topicIds
          }
          list {
            Id
            name
            topicIds
            topics {
              items {
                topic {
                  Id
                  name
                  avatar
                  numUrls
                }
              }
            }
          }
        }
      }
      topics {
        items {
          topic {
            Id
            name
            avatar
            numUrls
          }
        }
      }
    }
  }
`;

export const updateUrl = /* GraphQL */ `
  mutation UpdateUrl(
    $Id: String!
    $acl: String
    $title: String
    $type: String
    $sourceId: String
    $duration: Int
    $photo: String
    $photoUrl: String
    $authorName: String
    $authorId: String
    $snippet: String
    $curatorIds: String
    $commenterIds: String
    $likerIds: String
    $viewerIds: String
    $topicIds: String
    $listIds: String
    $updateTS: Int
    $numLike: Int
    $numReport: Int
    $numView: Int
    $numLongView: Int
    $numTotalView: Int
    $numLongVisit: Int
    $numTotalVisit: Int
  ) {
    updateUrl(
      input: {
        Id: $Id
        acl: $acl
        title: $title
        type: $type
        sourceId: $sourceId
        duration: $duration
        photo: $photo
        photoUrl: $photoUrl
        authorId: $authorId
        authorName: $authorName
        snippet: $snippet
        authorIds: $authorId
        curatorIds: $curatorIds
        commenterIds: $commenterIds
        likerIds: $likerIds
        viewerIds: $viewerIds
        topicIds: $topicIds
        listIds: $listIds
        updateTS: $updateTS
        numLike: $numLike
        numReport: $numReport
        numView: $numView
        numLongView: $numLongView
        numTotalView: $numTotalView
        numLongVisit: $numLongVisit
        numTotalVisit: $numTotalVisit
      }
    ) {
      Id
      title
      authorName
      duration
      topicIds
    }
  }
`;

export const addComment = /* GraphQL */ `
  mutation AddComment(
    $Id: String!
    $creatorId: String!
    $curatorId: String!
    $acl: String
    $content: String
    $objectId: String!
    $objectType: String!
    $listId: String
    $pinId: String
    $urlId: String
    $commentId: String
    $topLevel: Boolean
  ) {
    createComment(
      input: {
        Id: $Id
        creatorId: $creatorId
        curatorId: $curatorId
        acl: $acl
        content: $content
        numView: 0
        numLike: 0
        numComment: 0
        objectId: $objectId
        objectType: $objectType
        listId: $listId
        pinId: $pinId
        urlId: $urlId
        commentId: $commentId
        topLevel: $topLevel
      }
    ) {
      Id
      curatorId
      curator {
        Id
        acl
        handle
        name
        firstName
        lastName
        avatar
        avatarUrl
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
      }
      content
      numView
      numLike
      numReport
      numComment
      createdAt
    }
  }
`;

export const getCommentCounts = /* GraphQL */ `
  query getCommentCounts($Id: String!) {
    getComment(Id: $Id) {
      Id
      numComment
      numView
      numLike
      numReport
    }
  }
`;

export const updateCommentCounts = /* GraphQL */ `
  mutation UpdateCommentCounts(
    $Id: String!
    $objectId: String
    $numComment: Int
    $numView: Int
    $numLike: Int
    $numReport: Int
  ) {
    updateComment(
      input: {
        Id: $Id
        objectId: $objectId
        numComment: $numComment
        numView: $numView
        numLike: $numLike
        numReport: $numReport
      }
    ) {
      Id
      numComment
      numView
      numLike
      numReport
    }
  }
`;

export const getSourceCounts = /* GraphQL */ `
  query getSourceCounts($Id: String!) {
    getSource(Id: $Id) {
      Id
      numView
      numFollow
      numLike
      numReport
      numUrls
    }
  }
`;

export const updateSourceCounts = /* GraphQL */ `
  mutation UpdateSourceCounts(
    $Id: String!
    $numView: Int
    $numFollow: Int
    $numLike: Int
    $numReport: Int
    $numUrls: Int
  ) {
    updateSource(
      input: {
        Id: $Id
        numView: $numView
        numFollow: $numFollow
        numLike: $numLike
        numReport: $numReport
        numUrls: $numUrls
      }
    ) {
      Id
      numView
      numFollow
      numLike
      numReport
      numUrls
    }
  }
`;

export const userDetailsById = /* GraphQL */ `
  query userDetails($Id: String!) {
    getUser(Id: $Id) {
      Id
      name
      firstName
      lastName
      headline
      bio
      websites
      acl
      avatar
      avatarUrl
      handle
      location
      token
      label
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numReportRecursive
      numPinRecursive
      numCommentRecursive
      numFollowRecursive
      numCreateRecursive
      numShareRecursive
      numJoinRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numCommentView
      numCommentLike
      numCommentCreate
      numTopicView
      numTopicFollow
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      nextListSeq
      lists {
        items {
          Id
          creatorId
          acl
          curatorId
          name
          description
          seq
          nextPinSeq
          numView
          numLike
          numReport
          numFollow
          numShare
          numComment
          numPins
          topicIds
          pins {
            items {
              Id
              acl
              seq
              content
              createdAt
              url {
                Id
                uri
                title
                type
                sourceId
                source {
                  Id
                  name
                  enableJavascript
                  avatar
                  avatarUrl
                }
                photo
                photoUrl
                snippet
                duration
                authorName
                curatorIds
                listIds
                topicIds
                commenterIds
                likerIds
                viewerIds
                createdAt
                creationTS
                numComment
                numContribute
                numPins
                numShare
                numView
                numLike
                numReport
                numLongView
                numTotalView
                numLongVisit
                numTotalVisit
              }
              numComment
              numLike
              numReport
              numView
              numLongView
              numTotalView
              numLongVisit
              numTotalVisit
            }
          }
        }
      }
      memberOfGroups {
        items {
          Id
          groupId
          group {
            Id
            name
            description
            implicit
          }
        }
      }
      curatedGroups {
        items {
          Id
          name
          description
          implicit
        }
      }
      wishList
    }
  }
`;

export const userDetailsById2 = /* GraphQL */ `
  query userDetails($Id: String!) {
    getUser(Id: $Id) {
      Id
      name
      firstName
      lastName
      headline
      bio
      websites
      acl
      avatar
      avatarUrl
      handle
      location
      token
      label
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numReportRecursive
      numPinRecursive
      numCommentRecursive
      numFollowRecursive
      numCreateRecursive
      numShareRecursive
      numJoinRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numCommentView
      numCommentLike
      numCommentCreate
      numTopicView
      numTopicFollow
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      lastRecommendationCreationTime
      lastRecommendationExpansionTime
      lastRecommendationRefreshTime
      currentRecommendationBatchSequence
      currentRecommendationItemSequence
      currentRecommendationExpansionCount
      currentRecommendationRefreshCount
      lastRecommendationNotificationTime
      currentRecommendationCount
      numAvailableRecommendations
      lowestRecommendationScore
      lastNotificationPermissionRequestTime
      notificationPermissionRequestCount
      lastAppUpgradeRecommendationTime
      appUpgradeRecommendationCount
      lastTopicUpdateTime
      lastTopicUpdateRecommendationTime
      topicUpdateRecommendationCount
      chosenTheme
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      nextListSeq
      createdAt
      memberOfGroups {
        items {
          Id
          groupId
          group {
            Id
            name
            description
            implicit
          }
        }
      }
      curatedGroups {
        items {
          Id
          name
          description
          implicit
        }
      }
      wishList
    }
  }
`;

export const actionsByUser = /* GraphQL */ `
  query ActionsByUser($Id: String!, $nextToken: String, $limit: Int) {
    actionByUser(actorId: $Id, nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        Id
        operation
        objectId
        objectType
        refererActionId
        hostUserId
        actorId
        createdAt
        list {
          Id
          name
          description
          seq
          acl
          createdAt
          topicIds
          nextPinSeq
          numPins
          pins {
            items {
              Id
              url {
                Id
                uri
                topicIds
                numLike
                numReport
              }
            }
          }
        }
        user {
          Id
          name
          firstName
          lastName
          headline
          bio
          websites
          acl
          avatar
          avatarUrl
          handle
          location
          token
          label
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          nextListSeq
        }
      }
    }
  }
`;

export const actionsOnUser = /* GraphQL */ `
  query ActionsOnUser($Id: String!, $nextToken: String, $limit: Int) {
    actionOnObject(objectId: $Id, nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        Id
        operation
        actor {
          Id
          name
          firstName
          lastName
          headline
          bio
          websites
          acl
          avatar
          avatarUrl
          handle
          location
          token
          label
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          nextListSeq
        }
        objectId
        objectType
        createdAt
        refererActionId
        hostUserId
      }
    }
  }
`;

export const getUserFollowers = /* GraphQL */ `
  query GetUserFollowers($Id: String!) {
    getUser(Id: $Id) {
      numFollow
      actions(
        filter: { operation: { eq: "Follow" }, objectType: { eq: "User" } }
      ) {
        items {
          Id
          operation
          objectId
          objectType
          refererActionId
          hostUserId
          actor {
            Id
            avatar
            avatarUrl
            bio
            headline
            acl
            handle
            name
            firstName
            lastName
            token
            label
            numView
            numFollow
            numShare
            numViewRecursive
            numLikeRecursive
            numReportRecursive
            numPinRecursive
            numCommentRecursive
            numFollowRecursive
            numCreateRecursive
            numShareRecursive
            numJoinRecursive
            numUserView
            numUserFollow
            numUserShare
            numListView
            numListLike
            numListFollow
            numListShare
            numListCreate
            numPinView
            numPinLike
            numPinCreate
            numUrlView
            numUrlContribute
            numUrlAuthor
            numCommentView
            numCommentLike
            numCommentCreate
            numTopicView
            numTopicFollow
            numSourceView
            numSourceLike
            numSourceFollow
            numExperimentJoin
            numExperimentCreate
            topicIds
            declaredTopicIds
            curatedTopicIds
            consumedTopicIds
            engagedTopicIds
            viewedTopicIds
            visitedTopicIds
            nextListSeq
            createdAt
            actions {
              items {
                Id
                actorId
                operation
                objectType
                actor {
                  Id
                  handle
                  name
                  firstName
                  lastName
                  avatar
                  avatarUrl
                }
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
      }
    }
  }
`;

export const getUsersFollowed = /* GraphQL */ `
  query GetUsersFollowed($Id: String!) {
    getUser(Id: $Id) {
      numUserFollow
      actionsByUser(
        filter: { operation: { eq: "Follow" }, objectType: { eq: "User" } }
        limit: 10000
      ) {
        items {
          Id
          operation
          objectId
          objectType
          refererActionId
          hostUserId
          user {
            Id
            avatar
            avatarUrl
            bio
            headline
            acl
            handle
            name
            firstName
            lastName
            token
            label
            numView
            numFollow
            numShare
            numViewRecursive
            numLikeRecursive
            numReportRecursive
            numPinRecursive
            numCommentRecursive
            numFollowRecursive
            numCreateRecursive
            numShareRecursive
            numJoinRecursive
            numUserView
            numUserFollow
            numUserShare
            numListView
            numListLike
            numListFollow
            numListShare
            numListCreate
            numPinView
            numPinLike
            numPinCreate
            numUrlView
            numUrlContribute
            numUrlAuthor
            numCommentView
            numCommentLike
            numCommentCreate
            numTopicView
            numTopicFollow
            numSourceView
            numSourceLike
            numSourceFollow
            numExperimentJoin
            numExperimentCreate
            topicIds
            declaredTopicIds
            curatedTopicIds
            consumedTopicIds
            engagedTopicIds
            viewedTopicIds
            visitedTopicIds
            nextListSeq
            actions {
              items {
                Id
                actorId
                operation
                objectType
                actor {
                  Id
                  handle
                  name
                  firstName
                  lastName
                  avatar
                  avatarUrl
                }
                createdAt
                refererActionId
                hostUserId
              }
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const findUsers = /* GraphQL */ `
  query FindUsers($pattern: String) {
    listUsers(filter: { handle: { beginsWith: $pattern } }, limit: 1000) {
      items {
        Id
        avatar
        avatarUrl
        bio
        headline
        name
        firstName
        lastName
        handle
        token
        label
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        actions(filter: { operation: { eq: "Follow" } }) {
          items {
            Id
            actorId
            operation
            objectType
            actor {
              Id
              handle
              name
              firstName
              lastName
              avatar
              avatarUrl
            }
            createdAt
            refererActionId
            hostUserId
          }
        }
        createdAt
      }
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query listUsers($numRequested: Int, $nextToken: String) {
    listUsers(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        avatar
        avatarUrl
        bio
        headline
        name
        firstName
        lastName
        handle
        token
        label
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        createdAt
      }
    }
  }
`;

export const searchUsersByName = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $numRequested: Int
    $nextToken: String
  ) {
    searchUsers(filter: $filter, limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        avatar
        avatarUrl
        bio
        headline
        name
        firstName
        lastName
        handle
        token
        label
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        createdAt
      }
    }
  }
`;

export const listUsersForReindexing = /* GraphQL */ `
  query ListUsers {
    listUsers(limit: 1000) {
      items {
        Id
        touch
      }
    }
  }
`;

export const updateUserForReindexing = /* GraphQL */ `
  mutation UpdateUser($Id: String!, $touch: Int) {
    updateUser(input: { Id: $Id, touch: $touch }) {
      Id
      touch
    }
  }
`;

export const feed = /* GraphQL */ `
  query Feed($filter: SearchableUrlFilterInput, $numRequested: Int) {
    searchUrls(
      sort: { field: creationTS, direction: desc }
      filter: $filter
      limit: $numRequested
    ) {
      items {
        Id
        uri
        title
        snippet
        photo
        photoUrl
        duration
        createdAt
        numComment
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numLike
        numReport
        numPins
        curatorIds
        listIds
        commenterIds
        likerIds
        viewerIds
        topicIds
        creationTS
        sourceId
        source {
          Id
          name
          enableJavascript
          avatar
          avatarUrl
        }
        authorName
        comments {
          items {
            curatorId
            content
            Id
            numLike
            numReport
            createdAt
            actions {
              items {
                Id
                actorId
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
              }
            }
            comments {
              items {
                curatorId
                content
                Id
                numLike
                numReport
                createdAt
                actions {
                  items {
                    Id
                    actorId
                    operation
                    objectType
                    createdAt
                    refererActionId
                    hostUserId
                  }
                }
              }
            }
          }
        }
        pins {
          items {
            Id
            seq
            content
            reaction
            createdAt
            curatorId
            numView
            numLike
            numReport
            numComment
            numLongView
            numTotalView
            numLongVisit
            numTotalVisit
            markup
            list {
              Id
              name
            }
            actions {
              items {
                Id
                actorId
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
              }
            }
            comments {
              items {
                curatorId
                content
                Id
                numLike
                numReport
                createdAt
                actions {
                  items {
                    Id
                    actorId
                    operation
                    objectType
                    createdAt
                    refererActionId
                    hostUserId
                  }
                }
              }
            }
            tldr
          }
        }
      }
    }
  }
`;

export const feedWithoutSearch = /* GraphQL */ `
  query FeedWithoutSearch {
    urlSortedByCreationTS(
      dummy: 0
      creationTS: { gt: 0 }
      sortDirection: DESC
      limit: 1000
    ) {
      items {
        Id
        uri
        title
        snippet
        photo
        photoUrl
        duration
        createdAt
        numComment
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numPins
        curatorIds
        listIds
        commenterIds
        likerIds
        viewerIds
        topicIds
        creationTS
        sourceId
        source {
          Id
          name
          enableJavascript
          avatar
          avatarUrl
        }
        authorName
        comments {
          items {
            curatorId
            content
            Id
            numLike
            numReport
            createdAt
            actions {
              items {
                Id
                actorId
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
        pins {
          items {
            Id
            seq
            content
            createdAt
            curatorId
            numView
            numLike
            numReport
            numComment
            numLongView
            numTotalView
            numLongVisit
            numTotalVisit
            actions {
              items {
                Id
                actorId
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
        tldr
      }
    }
  }
`;

export const updateUrlForPinning = /* GraphQL */ `
  mutation UpdateUrlForPinning(
    $Id: String!
    $numContribute: Int!
    $numLongView: Int
    $numTotalView: Int
    $numLongVisit: Int
    $numTotalVisit: Int
    $numPins: Int!
    $curatorIds: String!
    $listIds: String!
    $commenterIds: String
    $likerIds: String
    $viewerIds: String
    $topicIds: String
    $numLike: Int
    $numReport: Int
    $numView: Int
  ) {
    updateUrl(
      input: {
        Id: $Id
        numContribute: $numContribute
        numPins: $numPins
        curatorIds: $curatorIds
        listIds: $listIds
        commenterIds: $commenterIds
        likerIds: $likerIds
        viewerIds: $viewerIds
        topicIds: $topicIds
        numLike: $numLike
        numReport: $numReport
        numView: $numView
        numLongView: $numLongView
        numTotalView: $numTotalView
        numLongVisit: $numLongVisit
        numTotalVisit: $numTotalVisit
      }
    ) {
      Id
    }
  }
`;

export const updateUrlForConsuming = /* GraphQL */ `
  mutation UpdateUrlForConsuming(
    $Id: String!
    $numComments: Int!
    $numLikers: Int!
    $numLike: Int
    $numReport: Int
    $numView: Int
  ) {
    updateUrl(
      input: { Id: $Id, numComments: $numComments, numLikers: $numLikers. numLike: $numLike, numReport: $numReport, numView: $numView }
    ) {
      Id
      numLikers
      numComments
      numLike
      numReport
      numView
    }
  }
`;

export const updateUrlForLiking = /* GraphQL */ `
  mutation UpdateUrlForLiking(
    $Id: String!
    $numLikers: Int!
    $numLike: Int
    $numReport: Int
    $numView: Int
  ) {
    updateUrl(
      input: {
        Id: $Id
        numLikers: $numLikers
        numLike: $numLike
        numReport: $numReport
        numView: $numView
      }
    ) {
      Id
      numLikers
      numLike
      numReport
      numView
    }
  }
`;

export const createUrlContribution = /* GraphQL */ `
  mutation CreateUrlContribution(
    $Id: String!
    $urlId: String!
    $contributorId: String!
  ) {
    createUrlContribution(
      input: { Id: $Id, urlId: $urlId, contributorId: $contributorId }
    ) {
      Id
    }
  }
`;

export const deleteUrlContribution = /* GraphQL */ `
  mutation DeleteUrlContribution($Id: String!) {
    deleteUrlContribution(input: { Id: $Id }) {
      Id
      contributorId
      urlId
    }
  }
`;

export const createTopicUrl = /* GraphQL */ `
  mutation CreateTopicUrl($Id: String!, $topicId: String!, $urlId: String!) {
    createTopicUrl(input: { Id: $Id, topicId: $topicId, urlId: $urlId }) {
      Id
    }
  }
`;

export const deleteTopicUrl = /* GraphQL */ `
  mutation DeleteTopicUrl($Id: String!) {
    deleteTopicUrl(input: { Id: $Id }) {
      topicId
      urlId
      Id
    }
  }
`;

export const updateTopicForPinning = /* GraphQL */ `
  mutation UpdateTopicForPinning($Id: String!, $numPins: Int!, $numUrls: Int!) {
    updateTopic(input: { Id: $Id, numPins: $numPins, numUrls: $numUrls }) {
      Id
      numPins
      numUrls
    }
  }
`;

export const updateListForPinning = /* GraphQL */ `
  mutation UpdateListForPinning($Id: String!, $numPins: Int!) {
    updateList(input: { Id: $Id, numPins: $numPins }) {
      Id
      numPins
    }
  }
`;

export const createTopicList = /* GraphQL */ `
  mutation CreateTopicList($Id: String!, $topicId: String!, $listId: String!) {
    createTopicList(input: { Id: $Id, topicId: $topicId, listId: $listId }) {
      Id
    }
  }
`;

export const deleteTopicList = /* GraphQL */ `
  mutation DeleteTopicList($Id: String!) {
    deleteTopicList(input: { Id: $Id }) {
      topicId
      listId
      Id
    }
  }
`;

export const updatePinForCommenting = /* GraphQL */ `
  mutation UpdatePinForCommenting($Id: String!, $numComments: Int!) {
    updatePin(input: { Id: $Id, numComments: $numComments }) {
      Id
      numComments
    }
  }
`;

export const addUrlLiking = /* GraphQL */ `
  mutation AddUrlLiking($Id: String!, $urlId: String!, $likerId: String!) {
    createUrlLiking(input: { Id: $Id, urlId: $urlId, likerId: $likerId }) {
      Id
      urlId
      likerId
    }
  }
`;

export const removeUrlLiking = /* GraphQL */ `
  mutation RemoveUrlLiking($Id: String!) {
    deleteUrlLiking(input: { Id: $Id }) {
      Id
      urlId
      likerId
    }
  }
`;

export const listExperiments = /* GraphQL */ `
  query ListExperiments {
    listExperiments {
      items {
        Id
        name
        description
        curatorId
        curator {
          Id
          acl
          handle
          name
          firstName
          lastName
          avatar
          avatarUrl
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
        }
        acl
        aces {
          items {
            Id
            objectId
            capability
            principalId
            principalType
          }
        }
        numJoin
      }
    }
  }
`;

export const addExperiment = /* GraphQL */ `
  mutation AddExperiment(
    $Id: String!
    $creatorId: String!
    $curatorId: String!
    $name: String!
    $description: String
  ) {
    createExperiment(
      input: {
        Id: $Id
        creatorId: $creatorId
        curatorId: $curatorId
        name: $name
        description: $description
        numJoin: 0
      }
    ) {
      Id
      name
      description
      numJoin
    }
  }
`;

export const removeExperiment = /* GraphQL */ `
  mutation RemoveExperiment($Id: String!) {
    deleteExperiment(input: { Id: $Id }) {
      Id
      name
    }
  }
`;

export const updateExperiment = /* GraphQL */ `
  mutation UpdateExperiment(
    $Id: String!
    $name: String!
    $description: String
  ) {
    updateExperiment(
      input: { Id: $Id, name: $name, description: $description }
    ) {
      Id
      name
      description
    }
  }
`;

export const getExperimentCounts = /* GraphQL */ `
  query getExperimentCounts($Id: String!) {
    getExperiment(Id: $Id) {
      Id
      numJoin
    }
  }
`;

export const updateExperimentCounts = /* GraphQL */ `
  mutation UpdateExperimentCounts($Id: String!, $numJoin: Int) {
    updateExperiment(input: { Id: $Id, numJoin: $numJoin }) {
      Id
      numJoin
    }
  }
`;

export const listGroups = /* GraphQL */ `
  query ListGroups {
    listGroups {
      items {
        Id
        name
        implicit
        description
        curatorId
        curator {
          Id
          acl
          handle
          name
          firstName
          lastName
          avatar
          avatarUrl
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numReportRecursive
          numPinRecursive
          numCommentRecursive
          numFollowRecursive
          numCreateRecursive
          numShareRecursive
          numJoinRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numCommentView
          numCommentLike
          numCommentCreate
          numTopicView
          numTopicFollow
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
        }
        acl
      }
    }
  }
`;

export const addGroup = /* GraphQL */ `
  mutation AddGroup(
    $Id: String!
    $creatorId: String!
    $curatorId: String!
    $name: String!
    $description: String
    $implicit: Boolean
  ) {
    createGroup(
      input: {
        Id: $Id
        creatorId: $creatorId
        curatorId: $curatorId
        name: $name
        description: $description
        implicit: $implicit
      }
    ) {
      Id
      name
      description
      implicit
    }
  }
`;

export const removeGroup = /* GraphQL */ `
  mutation RemoveGroup($Id: String!) {
    deleteGroup(input: { Id: $Id }) {
      Id
      name
    }
  }
`;

export const getGroup = /* GraphQL */ `
  query GetGroup($Id: String!) {
    getGroup(Id: $Id) {
      Id
      creatorId
      creator {
        Id
        handle
      }
      acl
      curatorId
      curator {
        Id
        acl
        handle
        name
        firstName
        lastName
        avatar
        avatarUrl
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
      }
      name
      description
      implicit
      memberUsers {
        items {
          Id
          user {
            Id
            handle
            name
            firstName
            lastName
            avatar
            avatarUrl
            bio
            token
            label
          }
        }
      }
    }
  }
`;

export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($Id: String!, $name: String!, $description: String) {
    updateGroup(input: { Id: $Id, name: $name, description: $description }) {
      Id
      name
      description
    }
  }
`;

export const getGroupCounts = /* GraphQL */ `
  query getGroupCounts($Id: String!) {
    getGroup(Id: $Id) {
      Id
    }
  }
`;

export const updateGroupCounts = /* GraphQL */ `
  mutation UpdateGroupCounts($Id: String!) {
    updateGroup(input: { Id: $Id }) {
      Id
    }
  }
`;

export const userMinimalByHandle = /* GraphQL */ `
  query userDetails($handle: String!) {
    userByHandle(handle: $handle) {
      items {
        Id
        name
        firstName
        lastName
        headline
        bio
        websites
        acl
        avatar
        avatarUrl
        handle
        location
        token
        label
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        nextListSeq
      }
    }
  }
`;

export const listUrls = /* GraphQL */ `
  query ListUrls($nextToken: String) {
    listUrls(limit: 10000, nextToken: $nextToken) {
      items {
        Id
        topicIds
        touch
      }
      nextToken
    }
  }
`;

export const searchUrlsByTitle = /* GraphQL */ `
  query SearchUrls($filter: SearchableUrlFilterInput, $numRequested: Int) {
    searchUrls(filter: $filter, limit: $numRequested) {
      items {
        Id
        uri
        title
        photo
        topicIds
        numLongVisit
        numTotalVisit
        creationTS
      }
      nextToken
    }
  }
`;

export const listPins = /* GraphQL */ `
  query ListPins($nextToken: String) {
    listPins(limit: 10000, nextToken: $nextToken) {
      items {
        Id
      }
      nextToken
    }
  }
`;

export const updateUrlForReindexing = /* GraphQL */ `
  mutation UpdateUrl($Id: String!, $touch: Int) {
    updateUrl(input: { Id: $Id, touch: $touch }) {
      Id
      touch
    }
  }
`;

export const actionListing = /* GraphQL */ `
  query ActionListing {
    listActions(limit: 10000) {
      items {
        Id
        actorId
        objectType
        objectId
        operation
        createdAt
        actorIdOperationObjectType
        actorIdOperationObjectId
        mutationActorId
        queryActorId
        creationTS
      }
    }
  }
`;

export const actionListingByUser = /* GraphQL */ `
  query ActionListing {
    listUsers(limit: 1000) {
      items {
        Id
        actionsByUser(limit: 10000) {
          items {
            Id
            actorId
            objectType
            objectId
            operation
            createdAt
            actorIdOperationObjectType
            actorIdOperationObjectId
            mutationActorId
            queryActorId
            objectIdOperation
            creationTS
          }
        }
      }
    }
  }
`;

export const actionListingByUserWithDetails = /* GraphQL */ `
  query ActionListing {
    listUsers {
      items {
        Id
        actionsByUser(limit: 1000) {
          items {
            Id
            actorId
            objectType
            objectId
            operation
            refererActionId
            hostUserId
            createdAt
            actorIdOperationObjectType
            actorIdOperationObjectId
            mutationActorId
            queryActorId
            objectIdOperation
            creationTS
            list {
              Id
              curatorId
              name
            }
            pin {
              Id
              curatorId
            }
            comment {
              Id
              curatorId
              objectType
            }
          }
        }
      }
    }
  }
`;

export const listUsersMinimal = /* GraphQL */ `
  query ListUsersMinimal($nextToken: String) {
    listUsers(limit: 1000, nextToken: $nextToken) {
      nextToken
      items {
        Id
        name
        firstName
        lastName
        headline
        bio
        websites
        acl
        avatar
        avatarUrl
        handle
        location
        token
        label
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationNotificationTime
        lastRecommendationRefreshTime
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numReportRecursive
        numPinRecursive
        numCommentRecursive
        numFollowRecursive
        numCreateRecursive
        numShareRecursive
        numJoinRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numCommentView
        numCommentLike
        numCommentCreate
        numTopicView
        numTopicFollow
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        nextListSeq
        createdAt
      }
    }
  }
`;
export const patchAction = /* GraphQL */ `
  mutation Action(
    $Id: String!
    $actorIdOperationObjectType: String
    $actorIdOperationObjectId: String
    $objectIdOperation: String
    $mutationActorId: String
    $queryActorId: String
    $creationTS: Int
  ) {
    updateAction(
      input: {
        Id: $Id
        actorIdOperationObjectType: $actorIdOperationObjectType
        actorIdOperationObjectId: $actorIdOperationObjectId
        objectIdOperation: $objectIdOperation
        mutationActorId: $mutationActorId
        queryActorId: $queryActorId
        creationTS: $creationTS
      }
    ) {
      Id
      actorIdOperationObjectType
      actorIdOperationObjectId
      objectIdOperation
      mutationActorId
      queryActorId
      creationTS
    }
  }
`;

export const userExists = /* GraphQL */ `
  query UserExists($Id: String!) {
    getUser(Id: $Id) {
      Id
      handle
    }
  }
`;

export const userMinimal = /* GraphQL */ `
  query UserMinimal($Id: String!) {
    getUser(Id: $Id) {
      Id
      name
      firstName
      lastName
      headline
      bio
      websites
      acl
      avatar
      avatarUrl
      handle
      location
      token
      label
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numReportRecursive
      numPinRecursive
      numCommentRecursive
      numFollowRecursive
      numCreateRecursive
      numShareRecursive
      numJoinRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numCommentView
      numCommentLike
      numCommentCreate
      numTopicView
      numTopicFollow
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      nextListSeq
    }
  }
`;

export const pinListing = /* GraphQL */ `
  query PinListing {
    listPins(limit: 10000) {
      items {
        Id
        creatorId
        curatorId
        urlId
        actions {
          items {
            Id
          }
        }
      }
    }
  }
`;

export const listListing = /* GraphQL */ `
  query ListListing {
    listLists(limit: 10000) {
      items {
        Id
        creatorId
        curatorId
      }
    }
  }
`;

export const followedUsers = /* GraphQL */ `
  query FollowedUsers($actorIdOperationObjectType: String!) {
    actionByActorIdOperationObjectType(
      actorIdOperationObjectType: $actorIdOperationObjectType
      sortDirection: DESC
      limit: 10000
    ) {
      items {
        Id
        operation
        objectId
        objectType
        user {
          Id
          avatar
          avatarUrl
          bio
          acl
          handle
          name
          firstName
          lastName
          token
          label
        }
        createdAt
        creationTS
      }
    }
  }
`;

export const followingUsers = /* GraphQL */ `
  query FollowingUsers($objectIdOperation: String!, $nextToken: String) {
    actionByObjectIdOperation(
      objectIdOperation: $objectIdOperation
      sortDirection: DESC
      limit: 10000
      nextToken: $nextToken
    ) {
      nextToken
      items {
        Id
        operation
        objectId
        objectType
        actor {
          Id
          avatar
          avatarUrl
          bio
          acl
          handle
          name
          firstName
          lastName
          token
          label
        }
        createdAt
        creationTS
      }
    }
  }
`;

export const objectsActedOn = /* GraphQL */ `
  query ObjectsActedOn(
    $actorIdOperationObjectType: String!
    $nextToken: String
    $limit: Int
  ) {
    actionByActorIdOperationObjectType(
      actorIdOperationObjectType: $actorIdOperationObjectType
      sortDirection: DESC
      nextToken: $nextToken
      limit: $limit
    ) {
      nextToken
      items {
        Id
        operation
        objectId
        objectType
        list {
          Id
          name
          description
          seq
          acl
          createdAt
          topicIds
          nextPinSeq
          numPins
        }
        pin {
          Id
          curatorId
          content
          markup
          createdAt
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          url {
            Id
            uri
            title
            snippet
            photo
            photoUrl
            duration
            createdAt
            numComment
            numContribute
            numLongView
            numTotalView
            numLongVisit
            numTotalVisit
            numPins
            curatorIds
            listIds
            commenterIds
            likerIds
            viewerIds
            topicIds
            numLike
            numReport
            creationTS
            sourceId
            source {
              Id
              name
              enableJavascript
              avatar
              avatarUrl
            }
            authorName
          }
          list {
            Id
            name
          }
        }
        comment {
          Id
          curatorId
          content
          createdAt
          objectType
          url {
            Id
            uri
            title
            snippet
            photo
            photoUrl
            duration
            createdAt
            numComment
            numContribute
            numLongView
            numTotalView
            numLongVisit
            numTotalVisit
            numPins
            curatorIds
            listIds
            commenterIds
            likerIds
            viewerIds
            topicIds
            numLike
            numReport
            creationTS
            sourceId
            source {
              Id
              name
              enableJavascript
              avatar
              avatarUrl
            }
            authorName
            pins {
              items {
                Id
                curatorId
                content
                markup
                createdAt
                numLongView
                numTotalView
                numLongVisit
                numTotalVisit
                list {
                  Id
                  name
                }
              }
            }
          }
          pin {
            Id
            curatorId
            content
            markup
            createdAt
            numLongView
            numTotalView
            numLongVisit
            numTotalVisit
            list {
              Id
              name
            }
            url {
              Id
              uri
              title
              snippet
              photo
              photoUrl
              duration
              createdAt
              numComment
              numContribute
              numLongView
              numTotalView
              numLongVisit
              numTotalVisit
              numPins
              curatorIds
              listIds
              commenterIds
              likerIds
              viewerIds
              topicIds
              numLike
              numReport
              creationTS
              sourceId
              source {
                Id
                name
                enableJavascript
                avatar
                avatarUrl
              }
              authorName
            }
          }
          comment {
            Id
            curatorId
            content
            createdAt
            objectType
            url {
              Id
              uri
              title
              snippet
              photo
              photoUrl
              duration
              createdAt
              numComment
              numContribute
              numLongView
              numTotalView
              numLongVisit
              numTotalVisit
              numPins
              curatorIds
              listIds
              commenterIds
              likerIds
              viewerIds
              topicIds
              numLike
              numReport
              creationTS
              sourceId
              source {
                Id
                name
                enableJavascript
                avatar
                avatarUrl
              }
              authorName
              pins {
                items {
                  Id
                  curatorId
                  content
                  markup
                  createdAt
                  numLongView
                  numTotalView
                  numLongVisit
                  numTotalVisit
                  list {
                    Id
                    name
                  }
                }
              }
            }
          }
        }
        user {
          Id
          avatar
          avatarUrl
          bio
          acl
          handle
          name
          firstName
          lastName
          token
          label
          numPinCreate
          numPinLike
          numCommentCreate
          numCommentLike
        }
        createdAt
      }
    }
  }
`;

export const contentOfInterest = /* GraphQL */ `
  query ContentOfInterest($filter: SearchableUrlFilterInput) {
    searchUrls(
      sort: { field: creationTS, direction: desc }
      filter: $filter
      limit: 200
    ) {
      items {
        Id
        uri
        title
        snippet
        photo
        photoUrl
        duration
        createdAt
        numComment
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numPins
        curatorIds
        listIds
        commenterIds
        likerIds
        viewerIds
        topicIds
        creationTS
        sourceId
        source {
          Id
          name
          enableJavascript
          avatar
          avatarUrl
        }
        authorName
        comments {
          items {
            curatorId
            curator {
              Id
              acl
              avatar
              handle
              name
              firstName
              lastName
            }
            content
            Id
            numLike
            numReport
            createdAt
            actions {
              items {
                Id
                actorId
                actor {
                  Id
                  handle
                  avatar
                }
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
                creationTS
              }
            }
            comments {
              items {
                curatorId
                curator {
                  Id
                  acl
                  avatar
                  handle
                  name
                  firstName
                  lastName
                }
                content
                Id
                numLike
                numReport
                createdAt
                actions {
                  items {
                    Id
                    actorId
                    actor {
                      Id
                      handle
                      avatar
                    }
                    operation
                    objectType
                    createdAt
                    refererActionId
                    hostUserId
                    creationTS
                  }
                }
              }
            }
          }
        }
        pins {
          items {
            Id
            seq
            content
            markup
            createdAt
            curatorId
            curator {
              Id
              acl
              avatar
              handle
              name
              firstName
              lastName
            }
            list {
              Id
              name
            }
            numView
            numLike
            numReport
            numComment
            actions {
              items {
                Id
                actorId
                actor {
                  Id
                  handle
                  avatar
                }
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
                creationTS
              }
            }
            comments {
              items {
                curatorId
                curator {
                  Id
                  acl
                  avatar
                  handle
                  name
                  firstName
                  lastName
                }
                content
                Id
                numLike
                numReport
                createdAt
                actions {
                  items {
                    Id
                    actorId
                    actor {
                      Id
                      handle
                      avatar
                    }
                    operation
                    objectType
                    createdAt
                    refererActionId
                    hostUserId
                    creationTS
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listUrlsWithPinsAndComments = /* GraphQL */ `
  query ListUrlsWithPinsAndComments($nextToken: String) {
    listUrls(limit: 10000, nextToken: $nextToken) {
      nextToken
      items {
        Id
        uri
        title
        snippet
        photo
        photoUrl
        duration
        createdAt
        numComment
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numPins
        curatorIds
        listIds
        commenterIds
        likerIds
        viewerIds
        topicIds
        creationTS
        sourceId
        source {
          Id
          name
          enableJavascript
          avatar
          avatarUrl
        }
        author {
          Id
          name
        }
        authorName
        comments {
          items {
            curator {
              Id
              acl
              handle
              name
              firstName
              lastName
              avatar
              avatarUrl
              numView
              numFollow
              numShare
              numViewRecursive
              numLikeRecursive
              numReportRecursive
              numPinRecursive
              numCommentRecursive
              numFollowRecursive
              numCreateRecursive
              numShareRecursive
              numJoinRecursive
              numUserView
              numUserFollow
              numUserShare
              numListView
              numListLike
              numListFollow
              numListShare
              numListCreate
              numPinView
              numPinLike
              numPinCreate
              numUrlView
              numUrlContribute
              numUrlAuthor
              numCommentView
              numCommentLike
              numCommentCreate
              numTopicView
              numTopicFollow
              numSourceView
              numSourceLike
              numSourceFollow
              numExperimentJoin
              numExperimentCreate
            }
            content
            Id
            numLike
            numReport
            createdAt
            actions {
              items {
                Id
                actorId
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
        pins {
          items {
            Id
            seq
            content
            markup
            createdAt
            listId
            curator {
              Id
              acl
              handle
              name
              firstName
              lastName
              avatar
              avatarUrl
              numView
              numFollow
              numShare
              numViewRecursive
              numLikeRecursive
              numReportRecursive
              numPinRecursive
              numCommentRecursive
              numFollowRecursive
              numCreateRecursive
              numShareRecursive
              numJoinRecursive
              numUserView
              numUserFollow
              numUserShare
              numListView
              numListLike
              numListFollow
              numListShare
              numListCreate
              numPinView
              numPinLike
              numPinCreate
              numUrlView
              numUrlContribute
              numUrlAuthor
              numCommentView
              numCommentLike
              numCommentCreate
              numTopicView
              numTopicFollow
              numSourceView
              numSourceLike
              numSourceFollow
              numExperimentJoin
              numExperimentCreate
            }
            numView
            numLike
            numReport
            numComment
            numLongView
            numTotalView
            numLongVisit
            numTotalVisit
            actions {
              items {
                Id
                actorId
                operation
                objectType
                createdAt
                refererActionId
                hostUserId
              }
            }
          }
        }
      }
    }
  }
`;

export const getPinsAndCommentsForUrl = /* GraphQL */ `
  query GetPinsAndCommentsForUrl($Id: String!) {
    getUrl(Id: $Id) {
      Id
      title
      type
      uri
      sourceId
      source {
        Id
        name
        enableJavascript
        avatar
        avatarUrl
      }
      photo
      photoUrl
      numPins
      numLike
      numReport
      numView
      numComment
      numContribute
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      curatorIds
      commenterIds
      likerIds
      viewerIds
      comments {
        items {
          curatorId
          content
          Id
          numLike
          numReport
          createdAt
          actions {
            items {
              Id
              actorId
              operation
              objectType
              createdAt
              refererActionId
              hostUserId
            }
          }
          comments {
            items {
              curatorId
              content
              Id
              numLike
              numReport
              createdAt
              actions {
                items {
                  Id
                  actorId
                  operation
                  objectType
                  createdAt
                  refererActionId
                  hostUserId
                }
              }
            }
          }
        }
      }
      pins {
        items {
          Id
          seq
          content
          markup
          createdAt
          curatorId
          numView
          numLike
          numReport
          numComment
          list {
            Id
            name
          }
          actions {
            items {
              Id
              actorId
              operation
              objectType
              createdAt
              refererActionId
              hostUserId
            }
          }
          comments {
            items {
              curatorId
              content
              Id
              numLike
              numReport
              createdAt
              actions {
                items {
                  Id
                  actorId
                  operation
                  objectType
                  createdAt
                  refererActionId
                  hostUserId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const addError = /* GraphQL */ `
  mutation AddError(
    $Id: String!
    $userID: String
    $screen: String
    $operation: String
    $log: String
  ) {
    createError(
      input: {
        Id: $Id
        userID: $userID
        screen: $screen
        operation: $operation
        log: $log
      }
    ) {
      Id
      userID
      screen
      operation
      log
    }
  }
`;

export const listUsersWithListsAndPins = /* GraphQL */ `
  query ListUsersWithListsAndPins {
    listUsers(limit: 1000) {
      items {
        Id
        handle
        nextListSeq
        lists {
          items {
            Id
            name
            seq
            nextPinSeq
            createdAt
            pins {
              items {
                Id
                seq
                createdAt
              }
            }
          }
        }
        createdAt
      }
    }
  }
`;

export const listUsersWithListsAndTopics = /* GraphQL */ `
  query ListUsersWithListsAndTopics($nextToken: String) {
    listUsers(limit: 10000, nextToken: $nextToken) {
      nextToken
      items {
        Id
        handle
        label
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lists {
          items {
            Id
            name
            topicIds
          }
        }
        createdAt
      }
    }
  }
`;

export const operationsOnObjectByUser = /* GraphQL */ `
  query OperationsOnObjectByUser($pattern: String!) {
    actionByActorIdOperationObjectType(
      actorIdOperationObjectType: $pattern
      sortDirection: DESC
    ) {
      items {
        Id
        actorId
        operation
        objectType
        creationTS
        createdAt
      }
    }
  }
`;

export const addRecommendation = /* GraphQL */ `
  mutation AddRecommendation(
    $Id: String!
    $batchId: Int!
    $score: Int
    $sequence: Int
    $urlId: String!
    $userId: String!
    $userIdBatchId: String!
    $creationTS: Int
    $signals: String
  ) {
    createRecommendation(
      input: {
        Id: $Id
        batchId: $batchId
        score: $score
        sequence: $sequence
        urlId: $urlId
        userId: $userId
        userIdBatchId: $userIdBatchId
        creationTS: $creationTS
        signals: $signals
      }
    ) {
      Id
      urlId
      batchId
    }
  }
`;

export const removeRecommendation = /* GraphQL */ `
  mutation RemoveRecommendation($Id: String!) {
    deleteRecommendation(input: { Id: $Id }) {
      Id
    }
  }
`;

export const updateRecommendation = /* GraphQL */ `
  mutation UpdateRecommendation(
    $Id: String!
    $batchId: Int
    $score: Int
    $sequence: Int
    $urlId: String
    $userId: String
    $userIdBatchId: String
    $creationTS: Int
    $signals: String
  ) {
    updateRecommendation(
      input: {
        Id: $Id
        batchId: $batchId
        score: $score
        sequence: $sequence
        urlId: $urlId
        userId: $userId
        userIdBatchId: $userIdBatchId
        creationTS: $creationTS
        signals: $signals
      }
    ) {
      Id
      urlId
      batchId
    }
  }
`;

export const addNotificationRecommendation = /* GraphQL */ `
  mutation AddNotificationRecommendation(
    $Id: String!
    $urlId: String!
    $userId: String!
    $creationTS: Int
    $score: Int
    $userIdType: String
  ) {
    createRecommendation(
      input: {
        Id: $Id
        urlId: $urlId
        userId: $userId
        creationTS: $creationTS
        score: $score
        type: "Notification"
        userIdType: $userIdType
      }
    ) {
      Id
      urlId
      userId
      score
    }
  }
`;

export const recommendationsForUser = /* GraphQL */ `
  query RecommendationsForUserId(
    $userId: String!
    $nextToken: String
    $limit: Int
  ) {
    recommendationByUserId(
      userId: $userId
      nextToken: $nextToken
      limit: $limit
    ) {
      nextToken
      items {
        Id
        urlId
        score
        type
        creationTS
        userIdType
      }
    }
  }
`;

export const dailyRecommendationsForUser = /* GraphQL */ `
  query DailyRecommendationsForUser(
    $userIdType: String
    $nextToken: String
    $limit: Int
  ) {
    recommendationByUserIdType(
      userIdType: $userIdType
      nextToken: $nextToken
      limit: $limit
      sortDirection: DESC
    ) {
      nextToken
      items {
        Id
        urlId
        score
        type
        creationTS
        userIdType
        createdAt
        url {
          title
          photo
          photoUrl
        }
      }
    }
  }
`;

export const currentRecommendationsForUser = /* GraphQL */ `
  query CurrentRecommendationsForUserId(
    $userIdBatchId: String!
    $nextToken: String
    $limit: Int
  ) {
    recommendationByUserIdBatchId(
      userIdBatchId: $userIdBatchId
      nextToken: $nextToken
      limit: $limit
    ) {
      nextToken
      items {
        sequence
        batchId
        urlId
        url {
          Id
          uri
          title
          snippet
          photo
          photoUrl
          duration
          createdAt
          numComment
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numLike
          numReport
          numPins
          curatorIds
          listIds
          commenterIds
          likerIds
          viewerIds
          topicIds
          creationTS
          sourceId
          source {
            Id
            name
            enableJavascript
            avatar
            avatarUrl
          }
          authorName
          comments {
            items {
              curatorId
              content
              Id
              numLike
              numReport
              createdAt
              actions {
                items {
                  Id
                  actorId
                  operation
                  objectType
                  createdAt
                  refererActionId
                  hostUserId
                }
              }
              comments {
                items {
                  curatorId
                  content
                  Id
                  numLike
                  numReport
                  createdAt
                  actions {
                    items {
                      Id
                      actorId
                      operation
                      objectType
                      createdAt
                      refererActionId
                      hostUserId
                    }
                  }
                }
              }
            }
          }
          pins {
            items {
              Id
              seq
              content
              reaction
              createdAt
              curatorId
              numView
              numLike
              numReport
              numComment
              numLongView
              numTotalView
              numLongVisit
              numTotalVisit
              markup
              list {
                Id
                name
              }
              actions {
                items {
                  Id
                  actorId
                  operation
                  objectType
                  createdAt
                  refererActionId
                  hostUserId
                }
              }
              comments {
                items {
                  curatorId
                  content
                  Id
                  numLike
                  numReport
                  createdAt
                  actions {
                    items {
                      Id
                      actorId
                      operation
                      objectType
                      createdAt
                      refererActionId
                      hostUserId
                    }
                  }
                }
              }
              tldr
            }
          }
        }
        score
      }
    }
  }
`;

export const recommendationCandidates = /* GraphQL */ `
  query RecommendationCandidates(
    $filter: SearchableUrlFilterInput
    $numRequested: Int
    $nextToken: String
  ) {
    searchUrls(
      sort: { field: creationTS, direction: desc }
      filter: $filter
      limit: $numRequested
      nextToken: $nextToken
    ) {
      nextToken
      items {
        Id
        numContribute
        numPins
        numLike
        numReport
        numComment
        numView
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        curatorIds
        commenterIds
        likerIds
        viewerIds
        listIds
        topicIds
        creationTS
      }
    }
  }
`;

export const recommendationCandidates2 = /* GraphQL */ `
  query RecommendationCandidates($numRequested: Int, $nextToken: String) {
    listUrls(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        uri
        title
        numContribute
        numPins
        numLike
        numReport
        numComment
        numView
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        curatorIds
        commenterIds
        likerIds
        viewerIds
        listIds
        topicIds
        creationTS
      }
    }
  }
`;

export const getSingleUrl = /* GraphQL */ `
  query GetSingleUrl($Id: String!) {
    getUrl(Id: $Id) {
      Id
      uri
      title
      snippet
      photo
      photoUrl
      duration
      createdAt
      numComment
      numLike
      numReport
      numContribute
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      numPins
      curatorIds
      listIds
      commenterIds
      likerIds
      viewerIds
      topicIds
      creationTS
      sourceId
      source {
        Id
        name
        enableJavascript
        avatar
        avatarUrl
      }
      authorName
      tldr
      comments {
        items {
          curatorId
          content
          Id
          numLike
          numReport
          createdAt
          actions {
            items {
              Id
              actorId
              operation
              objectType
              createdAt
              refererActionId
              hostUserId
            }
          }
          comments {
            items {
              curatorId
              content
              Id
              numLike
              numReport
              createdAt
              actions {
                items {
                  Id
                  actorId
                  operation
                  objectType
                  createdAt
                  refererActionId
                  hostUserId
                }
              }
            }
          }
        }
      }
      pins {
        items {
          Id
          seq
          content
          reaction
          createdAt
          curatorId
          numView
          numLike
          numReport
          numComment
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          markup
          list {
            Id
            name
          }
          actions {
            items {
              Id
              actorId
              operation
              objectType
              createdAt
              refererActionId
              hostUserId
            }
          }
          comments {
            items {
              curatorId
              content
              Id
              numLike
              numReport
              createdAt
              actions {
                items {
                  Id
                  actorId
                  operation
                  objectType
                  createdAt
                  refererActionId
                  hostUserId
                }
              }
            }
          }
          tldr
        }
      }
    }
  }
`;

export const notificationCandidates = /* GraphQL */ `
  query NotificationCandidates($numRequested: Int, $nextToken: String) {
    listUrls(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        uri
        title
        numContribute
        numPins
        numLike
        numReport
        numComment
        numView
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        curatorIds
        commenterIds
        likerIds
        viewerIds
        listIds
        topicIds
        creationTS
      }
    }
  }
`;

export const addVisit = /* GraphQL */ `
  mutation AddVisit(
    $Id: String!
    $objectType: String!
    $objectId: String!
    $actorId: String!
    $type: String
    $pinId: String
    $urlId: String
    $visitTS: Int
    $duration: Int
    $origin: String
  ) {
    createVisit(
      input: {
        Id: $Id
        objectId: $objectId
        objectType: $objectType
        actorId: $actorId
        type: $type
        pinId: $pinId
        urlId: $urlId
        visitTS: $visitTS
        duration: $duration
        origin: $origin
      }
    ) {
      Id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      createdAt
    }
  }
`;

export const removeVisit = /* GraphQL */ `
  mutation RemoveVisit($Id: String!) {
    deleteVisit(input: { Id: $Id }) {
      Id
    }
  }
`;

export const visitsByUser = /* GraphQL */ `
  query visitsByUser($Id: String!, $numRequested: Int, $nextToken: String) {
    visitByUser(actorId: $Id, limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        objectType
        objectId
        type
        visitTS
        duration
        urlId
        pinId
      }
    }
  }
`;

export const visitsByUserComplete = /* GraphQL */ `
  query visitsByUserComplete(
    $Id: String!
    $numRequested: Int
    $nextToken: String
  ) {
    visitByUser(actorId: $Id, limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        objectId
        objectType
        actorId
        type
        pinId
        urlId
        visitTS
        duration
        origin
      }
    }
  }
`;

export const listVisits = /* GraphQL */ `
  query listVisits($numRequested: Int, $nextToken: String) {
    listVisits(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        actorId
        objectType
        objectId
        type
        visitTS
        duration
        urlId
        pinId
      }
    }
  }
`;

export const visitsOfObject = /* GraphQL */ `
  query VisitsOfObject($objectId: String!, $nextToken: String) {
    visitOfObject(objectId: $objectId, limit: 1000, nextToken: $nextToken) {
      nextToken
      items {
        actorId
        visitTS
        type
        duration
        actor {
          Id
          handle
          label
        }
      }
    }
  }
`;

export const addVisitToArchive = /* GraphQL */ `
  mutation AddVisitToArchive(
    $Id: String!
    $objectType: String!
    $objectId: String!
    $actorId: String!
    $type: String
    $pinId: String
    $urlId: String
    $visitTS: Int
    $duration: Int
    $origin: String
  ) {
    createVisitArchive(
      input: {
        Id: $Id
        objectId: $objectId
        objectType: $objectType
        actorId: $actorId
        type: $type
        pinId: $pinId
        urlId: $urlId
        visitTS: $visitTS
        duration: $duration
        origin: $origin
      }
    ) {
      Id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      createdAt
    }
  }
`;

export const removeVisitFromArchive = /* GraphQL */ `
  mutation RemoveVisitFromArchive($Id: String!) {
    deleteVisitArchive(input: { Id: $Id }) {
      Id
    }
  }
`;

export const archivedVisitsByUser = /* GraphQL */ `
  query ArchivedVisitsByUser(
    $Id: String!
    $numRequested: Int
    $nextToken: String
  ) {
    visitArchiveByUser(
      actorId: $Id
      limit: $numRequested
      nextToken: $nextToken
    ) {
      nextToken
      items {
        Id
        objectType
        objectId
        type
        visitTS
        duration
        urlId
        pinId
      }
    }
  }
`;

export const archivedVisitsByUserComplete = /* GraphQL */ `
  query ArchivedVisitsByUserComplete(
    $Id: String!
    $numRequested: Int
    $nextToken: String
  ) {
    visitArchiveByUser(
      actorId: $Id
      limit: $numRequested
      nextToken: $nextToken
    ) {
      nextToken
      items {
        Id
        objectId
        objectType
        actorId
        type
        pinId
        urlId
        visitTS
        duration
        origin
      }
    }
  }
`;

export const listVisitArchives = /* GraphQL */ `
  query listVisitArchives($numRequested: Int, $nextToken: String) {
    listVisitArchives(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        actorId
        objectType
        objectId
        type
        visitTS
        duration
        urlId
        pinId
      }
    }
  }
`;

export const listConfigs = /* GraphQL */ `
  query listConfigs($numRequested: Int, $nextToken: String) {
    listConfigs(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        name
        value
        type
      }
    }
  }
`;

export const addFeedback = /* GraphQL */ `
  mutation AddFeedback(
    $Id: String!
    $userId: String!
    $creationTS: Int
    $note: String!
  ) {
    createFeedback(
      input: { Id: $Id, userId: $userId, creationTS: $creationTS, note: $note }
    ) {
      Id
      userId
      creationTS
      createdAt
      note
    }
  }
`;

export const removeFeedback = /* GraphQL */ `
  mutation RemoveFeedback($Id: String!) {
    deleteFeedback(input: { Id: $Id }) {
      Id
    }
  }
`;

export const feedbacksByUser = /* GraphQL */ `
  query feedbacksByUser(
    $userId: String!
    $numRequested: Int
    $nextToken: String
  ) {
    feedbackByUser(
      userId: $userId
      limit: $numRequested
      nextToken: $nextToken
    ) {
      nextToken
      items {
        Id
        creationTS
        note
        createdAt
      }
    }
  }
`;

export const listFeedbacks = /* GraphQL */ `
  query listFeedbacks($numRequested: Int, $nextToken: String) {
    listFeedbacks(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        userId
        creationTS
        note
        createdAt
        user {
          Id
          handle
          name
        }
      }
    }
  }
`;

export const addRequest = /* GraphQL */ `
  mutation AddRequest(
    $Id: String!
    $userId: String!
    $creationTS: Int
    $type: String!
    $note: String
  ) {
    createRequest(
      input: {
        Id: $Id
        userId: $userId
        creationTS: $creationTS
        type: $type
        note: $note
      }
    ) {
      Id
      userId
      creationTS
      createdAt
      type
      note
    }
  }
`;

export const removeRequest = /* GraphQL */ `
  mutation RemoveRequest($Id: String!) {
    deleteRequest(input: { Id: $Id }) {
      Id
    }
  }
`;

export const requestsByUser = /* GraphQL */ `
  query requestsByUser(
    $userId: String!
    $numRequested: Int
    $nextToken: String
  ) {
    requestByUser(
      userId: $userId
      limit: $numRequested
      nextToken: $nextToken
    ) {
      nextToken
      items {
        Id
        userId
        creationTS
        type
        note
        createdAt
      }
    }
  }
`;

export const requestsByType = /* GraphQL */ `
  query requestsByUser($type: String!, $numRequested: Int, $nextToken: String) {
    requestByUser(type: $type, limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        userId
        creationTS
        type
        note
        createdAt
      }
    }
  }
`;

export const listRequests = /* GraphQL */ `
  query listRequests($numRequested: Int, $nextToken: String) {
    listRequests(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        userId
        creationTS
        type
        note
        createdAt
        user {
          Id
          handle
          name
        }
      }
    }
  }
`;

export const listReports = /* GraphQL */ `
  query listReports($numRequested: Int, $nextToken: String) {
    actionByOperation(
      operation: "Report"
      sortDirection: DESC
      limit: $numRequested
      nextToken: $nextToken
    ) {
      nextToken
      items {
        objectType
        createdAt
        creationTS
        note
        Id
        actor {
          Id
          handle
          name
        }
        pin {
          Id
          content
          curator {
            Id
            handle
            name
          }
          url {
            Id
            title
          }
        }
        comment {
          Id
          content
        }
        url {
          Id
          title
          uri
        }
      }
    }
  }
`;

export const listSources = /* GraphQL */ `
  query ListSources($numRequested: Int, $nextToken: String) {
    listSources(limit: $numRequested, nextToken: $nextToken) {
      nextToken
      items {
        Id
        name
        avatar
        avatarUrl
        pattern
        enableJavascript
      }
    }
  }
`;

export const addSource = /* GraphQL */ `
  mutation AddSource(
    $Id: String!
    $curatorId: String!
    $avatar: String
    $avatarUrl: String
    $enableJavascript: Boolean!
    $name: String!
    $pattern: String!
  ) {
    createSource(
      input: {
        Id: $Id
        curatorId: $curatorId
        avatar: $avatar
        avatarUrl: $avatarUrl
        enableJavascript: $enableJavascript
        name: $name
        pattern: $pattern
      }
    ) {
      Id
      curatorId
      avatar
      avatarUrl
      enableJavascript
      name
      pattern
    }
  }
`;
