import React, { useRef, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Pressable,
  StyleSheet,
  Platform,
} from "react-native";

import { sharedStyles } from "../utils/SharedStyles";
import PinCurator from "./PinCurator";
import CommentCurator from "./CommentCurator";
import SourceIcon from "../components/SourceIcon";
import { formatTime } from "../utils/TimeFormatting";
import { photoIdFromId } from "../utils/RandomFromId";
import { clog } from "../utils/Log";
import { MENTION_FORMATTER } from "../utils/Mention";
import ShareNote from "../components/ShareNote";
import { hashCode } from "../utils/Id";
import { constructRichShareNote } from "../utils/Compatibility";
import { timeStamp } from "../utils/TimeStamp";
import link from "../assets/link.png";
import puffTLDR from "../assets/puff-tldr.png";
import { logEvent } from "../utils/LogEvent";
import TLDRSheet from "./TLDRSheet";
import CloseButton from "./CloseButton";
import ReportSheet from "./ReportSheet";
import * as Haptics from "expo-haptics";

const Item = ({
  state,
  item,
  itemStyle,
  myContext,
  navigation,
  refreshControl,
  screenWidth,
  origin,
  showJustification,
  contentModalFunction,
  incomplete,
  index,
  compact,
  clickResponder,
  guest,
  colors,
  onPress,
  skipCollection,
  renderActions,
  showTLDRPopUp,
  setShowTLDRPopUp,
  onShowReportPopUp,
}) => {
  const tldrRef = useRef(null);
  const reportRef = useRef(null);

  const [context, setContext] = useState("");

  clog("asked to render item", item);
  clog("CONTEXT", myContext);

  if (itemStyle == null) {
    itemStyle = {
      //flex: 1,
      backgroundColor: colors.cardBackground,
    };
  }
  if (screenWidth == null) {
    const windowDimension = Dimensions.get("window");
    screenWidth = windowDimension?.width;
  }
  const showImage = (urlItem) => {
    //return <View></View>;
    ////clog("will show image for", urlItem);
    return (
      <View
        style={{
          width: "100%",
          height: 180,
          overflow: "hidden",
        }}
      >
        {urlItem?.screenshotUrl && (
          <Image
            style={sharedStyles.screenshot}
            source={{
              uri: urlItem.screenshotUrl,
            }}
          />
        )}
        {true && urlItem?.duration != null && urlItem?.duration != 0 && (
          <View
            style={{
              marginTop: 188,
              paddingRight: 13,
              position: "absolute",
              alignSelf: "flex-end",
            }}
          >
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 12,
                fontWeight: "400",
              }}
            >
              {String(urlItem.duration) + " minute"}
            </Text>
          </View>
        )}
      </View>
    );
  };

  const showNote = (content, type, compact) => {
    clog("NOTE", content);
    if (content?.trim()) {
      return (
        <View
          style={
            type == "Pin"
              ? {
                  backgroundColor: compact
                    ? colors.commentBackground
                    : colors.commentBackground,
                  padding: compact ? 0 : 8,
                  marginLeft: compact ? 0 : 48,
                  marginTop: compact ? 12 : 4,
                  borderTopRightRadius: compact ? 0 : 16,
                  borderBottomLeftRadius: compact ? 0 : 16,
                  borderBottomRightRadius: compact ? 0 : 16,
                  overflow: "hidden",
                }
              : {
                  backgroundColor: compact
                    ? "purple"
                    : colors.commentBackground,
                  padding: compact ? 0 : 8,
                  marginLeft: compact ? 0 : 48,
                  marginTop: compact ? 12 : 4,
                  borderTopRightRadius: compact ? 0 : 16,
                  borderBottomLeftRadius: compact ? 0 : 16,
                  borderBottomRightRadius: compact ? 0 : 16,
                  overflow: "hidden",
                }
          }
        >
          <Text
            style={{
              fontSize: 15,
              fontWeight: "400",
              lineHeight: 22,
              color: colors.primaryText,
              flexWrap: "wrap",
            }}
          >
            {true &&
              MENTION_FORMATTER(
                content?.trim(),
                navigation,
                contentModalFunction
              )}
            {false && content?.trim()}
          </Text>
        </View>
      );
    } else {
      return <View></View>;
    }
  };

  let topPin = null;

  const renderItem = ({ item, index }) => {
    // find the curator closest to the reader
    clog("will render", item);
    let topActions = item?.topActions;
    let topAction = topActions?.[0]?.action;
    let topTarget = topActions?.[0]?.target;

    //return <View></View>;
    if (!topAction) {
      clog("no top action");
      return <View></View>;
    }

    item.topActions?.forEach((a) => {
      if (a.action.objectType == "Pin") {
        if (!topPin) {
          topPin = a.target;
        }
      }
    });
    // TODO(alpha): Choose the closest pin
    if (!topPin) {
      item.pins?.items?.forEach((p) => {
        topPin = p;
      });
    }
    clog("TOP PIN", topPin);
    let justification = "";
    if (showJustification) {
      if (topAction?.operation == "Create" && topAction?.objectType == "Pin") {
        justification = "";
      } else if (
        topAction?.operation == "Create" &&
        topAction?.objectType == "Comment"
      ) {
        justification = " commented on";
      }
      if (topAction?.operation == "Like" && topAction?.objectType == "Pin") {
        justification = " liked";
      } else if (
        topAction?.operation == "Like" &&
        topAction?.objectType == "Comment"
      ) {
        justification = " liked a comment";
      }
    }
    clog("ACTION", topAction);
    clog("TARGET", topTarget);
    clog("TOP PIN", topPin);
    //clog("JUSTIFICATION", justification);

    // https://picsum.photos/id/1/200/300
    let id = photoIdFromId(item.Id);
    let screenshotUrl = null;
    if (item?.photo) {
      if (myContext.presignedUrls[item.photo]) {
        screenshotUrl = myContext.presignedUrls[item.photo]?.uri;
      }
    }
    if (!screenshotUrl && item?.photoUrl) {
      screenshotUrl = item.photoUrl;
    }
    if (!screenshotUrl) {
      clog("ID for picsum", id);
      screenshotUrl = "https://picsum.photos/id/" + id + "/300/200";
    }
    if (item) {
      item.screenshotUrl = screenshotUrl;
    }
    clog("ITEM:", item);
    //console.log("SHOW COMPACT", compact);
    let topPinJSON = {};
    if (topPin?.markup) {
      try {
        topPinJSON = JSON.parse(topPin?.content);
      } catch (err) {
        topPin["content"] = "";
        topPin.markup = false;
      }
    }
    let topTargetJSON = {};
    if (topTarget?.markup) {
      try {
        topTargetJSON = JSON.parse(topTarget?.content);
      } catch (err) {
        topTarget["content"] = "";
        topTarget.markup = false;
      }
    }

    const onPressDefault = () => {
      if (clickResponder) {
        clickResponder();
      } else {
        console.log("Nobody to handle press");
      }
    };

    const onPressCustom = () => {
      if (setShowTLDRPopUp) {
        setShowTLDRPopUp(false);
      }
      if (topPin?.tldr) {
        clog("PRESSED TLDR");
        tldrRef.current?.onOpen();
        let urlToLog = item?.uri;
        if (urlToLog?.length > 100) {
          urlToLog = urlToLog.substring(0, 99);
        }
        logEvent("TLDR", {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: origin,
          platform: Platform.OS,
          action: "click",
          target: "content",
          url: urlToLog,
          appVersion: myContext.appVersion,
        });
      } else {
        onPress();
      }
    };

    const renderContentPreview = () => {
      return (
        <Pressable
          onPress={onPress ? onPressCustom : onPressDefault}
          onLongPress={() => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            setContext("original");
            reportRef.current?.onOpen();
          }}
          style={[
            styles.contentPreviewContainer,
            origin !== "discover" &&
              origin !== "myvillage" &&
              origin !== "search" && {
                marginTop: 16,
                padding: 8,
                borderRadius: 12,
                borderWidth: 1,
                borderColor: colors.smallDivider,
              },
          ]}
        >
          <View style={{ flex: 1, marginRight: 8 }}>
            <Text
              style={[
                styles.title,
                {
                  color: colors.primaryText,
                },
              ]}
              numberOfLines={3}
              ellipsizeMode={"tail"}
            >
              {item.title}
            </Text>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <SourceIcon
                url={item?.uri}
                icon={
                  item?.source?.avatar
                    ? myContext.presignedUrls[item.source.avatar]
                      ? myContext.presignedUrls[item.source.avatar]?.uri
                      : item?.source?.avatarUrl
                    : item?.source?.avatarUrl
                }
                style={{
                  marginRight: 4,
                  height: 16,
                  width: 16,
                }}
                textstyle={{
                  fontSize: 16,
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
                showText={false}
              />

              <Text
                numberOfLines={1}
                style={{
                  flexShrink: 1,
                  color: colors.placeholderText,
                  fontSize: 12,
                }}
              >
                {item?.sourceId}
              </Text>

              {item?.authorName != null && item?.authorName != "" && (
                <>
                  <Text
                    style={{
                      color: colors.placeholderText,
                      fontSize: 12,
                      marginHorizontal: 4,
                    }}
                  >
                    {"·"}
                  </Text>
                  <View
                    style={{
                      flexShrink: 1,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: colors.placeholderText,
                        fontSize: 12,
                      }}
                      numberOfLines={1}
                    >
                      {item?.authorName?.split(",")?.[0]}
                    </Text>
                  </View>
                </>
              )}

              {topPin?.tldr !== null &&
                (origin === "discover" ||
                  origin === "myvillage" ||
                  origin === "search") && (
                  <>
                    <Text
                      style={{
                        color: colors.placeholderText,
                        fontSize: 12,
                        marginHorizontal: 4,
                      }}
                    >
                      {"·"}
                    </Text>
                    <View
                      style={{
                        flexShrink: 0,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        source={puffTLDR}
                        style={{
                          width: 18,
                          height: 18,
                          tintColor: colors.primaryText,
                        }}
                      />
                    </View>
                  </>
                )}
            </View>
          </View>
          <View
            style={{
              alignSelf: "flex-start",
              width: 100,
              height: 68,
            }}
          >
            {item.screenshotUrl ? (
              <Image
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 8,
                  resizeMode: "cover",
                }}
                source={{
                  uri: item.screenshotUrl,
                }}
              />
            ) : (
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 8,
                  backgroundColor: colors.contentScreenBackground,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: 24,
                    height: 24,
                    resizeMode: "cover",
                  }}
                  source={link}
                />
              </View>
            )}
          </View>
        </Pressable>
      );
    };

    let currentTimeStamp = timeStamp();
    let currentTime = Date.now();
    if (compact) {
      console.log("WILL SHOW", topPinJSON);
      return (
        <View
          key={index}
          style={{
            paddingTop: 12,
            paddingLeft: 12,
            paddingRight: 12,
            backgroundColor: colors.cardBackground,
          }}
        >
          <View style={itemStyle}>
            {topAction != null && justification != "" && (
              <View
                style={{
                  paddingTop: 4,
                  paddingBottom: 16,
                  backgroundColor: colors.background,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: colors.background,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "400",
                      opacity: 0.6,
                      color: colors.primaryText,
                    }}
                  >
                    {(topAction?.actorId == myContext.Id
                      ? "You"
                      : myContext.users[topAction?.actorId]?.name) +
                      justification}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "400",
                      opacity: 0.6,
                      color: colors.primaryText,
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                  >
                    {"·"}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "400",
                      opacity: 0.6,
                      color: colors.primaryText,
                    }}
                  >
                    {formatTime(topAction?.createdAt, currentTime)}
                  </Text>
                </View>
              </View>
            )}
            <Pressable
              onPress={() => {
                if (clickResponder) {
                  clickResponder();
                } else {
                  let topAction = item?.justificationAction?.action;
                  navigation.push("Comments", {
                    url: item,
                    curator: myContext.users[topAction?.actorId],
                    refererActionId: topAction?.Id,
                    hostUserId: null,
                    origin: origin,
                    notFolded: true,
                    incomplete: incomplete,
                  });
                }
              }}
              onLongPress={() => {
                if (Platform.OS !== "web") {
                  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                }
                setContext("sharenote");
                reportRef.current?.onOpen();
              }}
            >
              {topAction?.objectType == "Comment" &&
                topTarget?.content != "" && (
                  <View
                    style={{
                      marginTop: 0,
                      marginLeft: 0,
                      flexDirection: "row",
                    }}
                    onLayout={(event) => {
                      let { x, y, width, height } = event.nativeEvent.layout;
                      clog(
                        "Item optional curator rendered at",
                        index,
                        y,
                        "to",
                        y + height
                      );
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <PinCurator
                        curatorId={topPin?.curatorId}
                        pin={topPin}
                        myContext={myContext}
                        navigation={navigation}
                        refererActionId={null}
                        hostUserId={null}
                        origin={origin}
                        guest={guest}
                        colors={colors}
                        skipCollection={skipCollection}
                      />
                      {topPin?.markup ? (
                        <ShareNote
                          json={topPinJSON}
                          colors={colors}
                          myContext={myContext}
                          seed={hashCode(item.Id)}
                          item={item}
                          origin={origin}
                          navigation={navigation}
                          contentModalFunction={contentModalFunction}
                        />
                      ) : (
                        <ShareNote
                          json={constructRichShareNote(topPin?.content)}
                          colors={colors}
                          myContext={myContext}
                          seed={hashCode(item.Id)}
                          item={item}
                          origin={origin}
                          navigation={navigation}
                          contentModalFunction={contentModalFunction}
                        />
                      )}
                    </View>
                  </View>
                )}
              <View
                style={{
                  marginTop: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  flexDirection: "row",
                }}
                onLayout={(event) => {
                  let { x, y, width, height } = event.nativeEvent.layout;
                  clog(
                    "Item",
                    topAction?.objectType == "Pin" ? "curator" : "commenter",
                    "rendered at",
                    index,
                    y,
                    "to",
                    y + height
                  );
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  {topAction?.objectType == "Pin" && (
                    <PinCurator
                      curatorId={topPin?.curatorId}
                      pin={topPin}
                      myContext={myContext}
                      navigation={navigation}
                      refererActionId={null}
                      hostUserId={null}
                      origin={origin}
                      guest={guest}
                      colors={colors}
                      skipCollection={skipCollection}
                    />
                  )}
                  {topAction?.objectType == "Comment" && (
                    <View style={{}}>
                      <CommentCurator
                        curatorId={topTarget?.curatorId}
                        comment={topTarget}
                        myContext={myContext}
                        navigation={navigation}
                        refererActionId={null}
                        hostUserId={null}
                        origin={origin}
                        colors={colors}
                      />
                    </View>
                  )}
                  {topTarget?.content != "" &&
                    (topPin?.markup ? (
                      <ShareNote
                        json={topPinJSON}
                        colors={colors}
                        myContext={myContext}
                        seed={hashCode(item.Id)}
                        item={item}
                        origin={origin}
                        navigation={navigation}
                        contentModalFunction={contentModalFunction}
                      />
                    ) : (
                      <ShareNote
                        json={constructRichShareNote(topPin?.content)}
                        colors={colors}
                        myContext={myContext}
                        seed={hashCode(item.Id)}
                        item={item}
                        origin={origin}
                        navigation={navigation}
                        contentModalFunction={contentModalFunction}
                      />
                    ))}
                </View>
              </View>
            </Pressable>
            <Pressable
              onPress={
                onPress
                  ? onPress
                  : () => {
                      if (clickResponder) {
                        clickResponder();
                      } else {
                        console.log("Nobody to handle press");
                      }
                    }
              }
            >
              <View
                style={{
                  marginTop: 12,
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 8,
                  backgroundColor: colors.secondaryBackground,
                  borderRadius: 8,
                }}
              >
                <View
                  style={{
                    width: 48,
                    height: 48,
                    borderRadius: 4,
                    marginRight: 8,
                  }}
                >
                  {item?.screenshotUrl && (
                    <Image
                      style={{
                        width: 48,
                        height: 48,
                        borderRadius: 4,
                        resizeMode: "cover",
                      }}
                      source={{
                        uri: item.screenshotUrl,
                      }}
                    />
                  )}
                </View>
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 14,
                    flexWrap: "wrap",
                    flex: 1,
                  }}
                  numberOfLines={3}
                  ellipsizeMode="tail"
                >
                  {item.title}
                </Text>
              </View>
            </Pressable>
          </View>
        </View>
      );
    }

    return (
      <View>
        {topAction != null && justification != "" && (
          <View
            style={{
              paddingTop: 16,
              backgroundColor: colors.background,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: colors.background,
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  opacity: 0.6,
                  color: colors.primaryText,
                }}
              >
                {(topAction?.actorId == myContext.Id
                  ? "You"
                  : myContext.users[topAction?.actorId]?.name) + justification}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  opacity: 0.6,
                  color: colors.primaryText,
                  marginLeft: 2,
                  marginRight: 2,
                }}
              >
                {"·"}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  opacity: 0.6,
                  color: colors.primaryText,
                }}
              >
                {formatTime(topAction?.createdAt, currentTime)}
              </Text>
            </View>
          </View>
        )}
        <View style={itemStyle}>
          {!item?.topicIds &&
            myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
              "FlagContentWithoutTopics"
            ] != null && (
              <View
                style={{ backgroundColor: "red", height: 20, width: "100%" }}
              />
            )}
          {(myContext.actionsByUser?.["Experiment"]?.["Join"]?.["Explain"] !=
            null ||
            Platform.OS == "web") && (
            <View
              style={{
                backgroundColor:
                  item?.creationTS < currentTimeStamp - 86400
                    ? "yellow"
                    : "#90ee90",
                height: 20,
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ color: colors.primaryText }}>
                {Math.floor((currentTimeStamp - item?.creationTS) / 86400) +
                  " days score " +
                  (item?.explanation?.score
                    ? Math.round(item.explanation.score * 100) / 100
                    : 0) +
                  " tscore " +
                  (item?.explanation?.topicScore?.score
                    ? Math.round(item.explanation.topicScore.score * 100) / 100
                    : 0) +
                  " cscore " +
                  (item?.explanation?.compositeScore
                    ? Math.round(item.explanation.compositeScore * 100) / 100
                    : 0) +
                  " conv " +
                  (item?.explanation?.counters?.numTotalVisit
                    ? Math.round(
                        (item.explanation.counters.numLongVisit /
                          item.explanation.counters.numTotalVisit) *
                          10000
                      ) / 100
                    : 0) +
                  "%" +
                  " visit " +
                  (item?.explanation?.counters?.numLongVisit
                    ? item.explanation.counters.numLongVisit
                    : 0) +
                  "/" +
                  (item?.explanation?.counters?.numTotalVisit
                    ? item.explanation.counters.numTotalVisit
                    : 0) +
                  " view " +
                  (item?.explanation?.counters?.numLongView
                    ? item.explanation.counters.numLongView
                    : 0) +
                  "/" +
                  (item?.explanation?.counters?.numTotalView
                    ? item.explanation.counters.numTotalView
                    : 0)}
              </Text>
            </View>
          )}
          <Pressable
            onPress={() => {
              if (clickResponder) {
                clickResponder();
              } else {
                let topAction = item?.justificationAction?.action;
                navigation.push("Comments", {
                  url: item,
                  curator: myContext.users[topAction?.actorId],
                  refererActionId: topAction?.Id,
                  hostUserId: null,
                  origin: origin,
                  notFolded: true,
                  incomplete: incomplete,
                });
              }
            }}
            onLongPress={() => {
              if (Platform.OS !== "web") {
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
              }
              setContext("sharenote");
              reportRef.current?.onOpen();
            }}
            style={{
              marginTop: 16,
              marginLeft: 16,
              marginRight: 16,
              flexDirection: "column",
            }}
            onLayout={(event) => {
              let { x, y, width, height } = event.nativeEvent.layout;
              clog(
                "Item optional curator rendered at",
                index,
                y,
                "to",
                y + height
              );
            }}
          >
            {origin !== "discover" &&
            origin !== "myvillage" &&
            origin !== "search" ? (
              <>
                <View style={{ flexDirection: "row" }}>
                  <PinCurator
                    curatorId={topPin?.curatorId}
                    pin={topPin}
                    myContext={myContext}
                    navigation={navigation}
                    refererActionId={null}
                    hostUserId={null}
                    origin={origin}
                    guest={guest}
                    colors={colors}
                    skipCollection={skipCollection}
                  />
                  {renderActions && renderActions()}
                </View>

                {renderContentPreview()}
              </>
            ) : (
              <>
                {renderContentPreview()}
                <View
                  style={{
                    height: 0.5,
                    marginBottom: 16,
                    backgroundColor: colors.smallDivider,
                  }}
                />

                <View style={{ flexDirection: "row", marginBottom: 9 }}>
                  <PinCurator
                    curatorId={topPin?.curatorId}
                    pin={topPin}
                    myContext={myContext}
                    navigation={navigation}
                    refererActionId={null}
                    hostUserId={null}
                    origin={origin}
                    guest={guest}
                    colors={colors}
                    skipCollection={skipCollection}
                  />
                  {renderActions && renderActions()}
                </View>
              </>
            )}

            {topPin?.markup ? (
              <ShareNote
                json={topPinJSON}
                colors={colors}
                myContext={myContext}
                seed={hashCode(item.Id)}
                item={item}
                origin={origin}
                navigation={navigation}
                contentModalFunction={contentModalFunction}
                onLongPress={() => {
                  if (Platform.OS !== "web") {
                    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                  }
                  setContext("sharenote");
                  reportRef.current?.onOpen();
                }}
              />
            ) : (
              <ShareNote
                json={constructRichShareNote(topPin?.content)}
                colors={colors}
                myContext={myContext}
                seed={hashCode(item.Id)}
                item={item}
                origin={origin}
                navigation={navigation}
                contentModalFunction={contentModalFunction}
                onLongPress={() => {
                  if (Platform.OS !== "web") {
                    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                  }
                  setContext("sharenote");
                  reportRef.current?.onOpen();
                }}
              />
            )}
            {topAction?.objectType == "Comment" && topTarget?.content != "" && (
              <View
                style={{
                  marginTop: 20,
                  marginLeft: 15,
                  flexDirection: "row",
                }}
                onLayout={(event) => {
                  let { x, y, width, height } = event.nativeEvent.layout;
                  clog(
                    "Item",
                    topAction?.objectType == "Pin" ? "curator" : "commenter",
                    "rendered at",
                    index,
                    y,
                    "to",
                    y + height
                  );
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  {topAction?.objectType == "Pin" && (
                    <PinCurator
                      curatorId={topPin?.curatorId}
                      pin={topPin}
                      myContext={myContext}
                      navigation={navigation}
                      refererActionId={null}
                      hostUserId={null}
                      origin={origin}
                      guest={guest}
                      colors={colors}
                      skipCollection={skipCollection}
                    />
                  )}
                  {topAction?.objectType == "Comment" && (
                    <View>
                      <View style={{ height: 18 }}></View>
                      <View style={{ position: "absolute", top: 0 }}>
                        <CommentCurator
                          curatorId={topTarget?.curatorId}
                          comment={topTarget}
                          myContext={myContext}
                          navigation={navigation}
                          refererActionId={null}
                          hostUserId={null}
                          origin={origin}
                          colors={colors}
                        />
                      </View>
                    </View>
                  )}
                  {topTarget?.content != "" && topTarget?.markup ? (
                    <ShareNote
                      json={topTargetJSON}
                      colors={colors}
                      myContext={myContext}
                      seed={hashCode(item.Id)}
                      item={item}
                      origin={origin}
                      navigation={navigation}
                      contentModalFunction={contentModalFunction}
                    />
                  ) : (
                    <View style={{}}>
                      {topAction?.objectType == "Pin" ? (
                        <ShareNote
                          json={constructRichShareNote(topTarget?.content)}
                          colors={colors}
                          myContext={myContext}
                          seed={hashCode(item.Id)}
                          item={item}
                          origin={origin}
                          navigation={navigation}
                          contentModalFunction={contentModalFunction}
                        />
                      ) : (
                        showNote(
                          topTarget?.content,
                          topAction?.objectType,
                          compact
                        )
                      )}
                    </View>
                  )}
                </View>
              </View>
            )}
          </Pressable>
        </View>
      </View>
    );
  };

  //clog("found", uniquePanels.length, "unique panels");

  const TLDRPopUp = () => {
    return (
      <View
        style={{
          zIndex: 1000,
          position: "absolute",
          top: 80,
          width: "100%",
        }}
      >
        <View style={[styles.arrowUp, { borderBottomColor: colors.link }]} />

        <View
          style={{
            flexDirection: "row",
            backgroundColor: colors.link,
            marginHorizontal: 34,
            borderRadius: 16,
            padding: 12,
          }}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <View
                style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
              >
                <Text
                  style={{
                    fontWeight: "700",
                    fontSize: 15,
                    lineHeight: 22,
                    color: colors.primaryButtonText,
                  }}
                >
                  {"Read in less time"}
                </Text>
                <View
                  style={{
                    marginLeft: 4,
                    backgroundColor: "white",
                    paddingHorizontal: 8,
                    paddingVertical: 5,
                    borderRadius: 100,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "700",
                      fontSize: 12,
                      lineHeight: 15,
                      color: colors.link,
                    }}
                  >
                    {"NEW"}
                  </Text>
                </View>
              </View>

              <CloseButton
                onPress={() => setShowTLDRPopUp(false)}
                buttonStyle={{ height: 24, width: 24 }}
                imageStyle={{ tintColor: colors.primaryButtonText }}
              />
            </View>

            <Text
              style={{
                fontSize: 15,
                lineHeight: 22,
                color: colors.primaryButtonText,
              }}
            >
              {
                "Puff now retells articles, so you can read in comfort, while catching all the details. Click any article with Puff to try it! "
              }
              <Image
                source={puffTLDR}
                style={{
                  width: 18,
                  height: 18,
                  tintColor: colors.primaryButtonText,
                }}
              />
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      {renderItem({ item, index })}

      {topPin?.tldr && (
        <TLDRSheet
          ref={tldrRef}
          topPin={topPin}
          myContext={myContext}
          origin={origin}
          colors={colors}
          url={item?.uri}
          openContent={onPress}
        />
      )}
      {index === 0 && showTLDRPopUp && <TLDRPopUp />}

      <ReportSheet
        ref={reportRef}
        context={context}
        item={item}
        myContext={myContext}
        onShowReportPopUp={onShowReportPopUp}
      />
    </>
  );
};

export default Item;

const styles = StyleSheet.create({
  contentPreviewContainer: {
    flex: 1,
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "700",
    flexWrap: "wrap",
  },
  arrowUp: {
    alignSelf: "center",
    width: 0,
    height: 0,
    borderTopWidth: 0,
    borderRightWidth: 9,
    borderBottomWidth: 11,
    borderLeftWidth: 9,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderLeftColor: "transparent",
  },
});
