import React, { useEffect, useState, useContext, useRef } from "react";
import { View, Image, Pressable, Text } from "react-native";

import { API, graphqlOperation } from "aws-amplify";
import { listGroups } from "../src/graphql/custom";
import AppContext from "../components/AppContext";
import { initialUser } from "../utils/InitialValues";
import GroupList from "../components/GroupList";
import LeftArrow from "../components/LeftArrow";
import plus from "../assets/plus.png";
import { timeStamp } from "../utils/TimeStamp";
import { clog } from "../utils/Log";
import { useTheme } from "../theme";

const GroupListScreen = ({ route, navigation }) => {
  async function getData() {
    try {
      const groupsData = await API.graphql(graphqlOperation(listGroups, {}));
      clog("group data", groupsData);
      if (groupsData.data.listGroups.items) {
        let groups = groupsData.data.listGroups.items;
        let sortedGroups = [];
        groups
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .forEach((element) => {
            sortedGroups.push(element);
          });

        setState((currentState) => {
          return {
            ...currentState,
            total: sortedGroups.length,
            groups: sortedGroups,
            fetchTimeStamp: timeStamp(),
            version: myContext.version,
          };
        });
      }
    } catch (err) {
      console.log("error fetching talks...", err);
    }
  }

  const initialState = {
    ...initialUser,
    groups: [],
    fetchTimeStamp: 0,
    version: 0,
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  const { colors } = useTheme();

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  let GroupCreator = false;
  myContext?.memberOfGroups?.items?.forEach((group) => {
    if (group.groupId == "GroupCreator") {
      GroupCreator = true;
    }
  });

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>
        );
      },
      headerBackTitleVisible: false,
    });
  }, [navigation, state, colors]);

  async function dataRefresh() {
    clog("CLICKED ON TAB");
    // do something
    if (
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
      getData().catch((err) => {
        console.log("error getting experiment list ", err);
      });
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
    }
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: colors.background,
      }}
    >
      <View
        style={{
          flex: 1,
          width: "100%",
          maxWidth: 756,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        {GroupCreator && (
          <View
            style={{
              alignSelf: "flex-start",
            }}
          >
            <Pressable
              onPress={() => {
                navigation.push("GroupUpdate", {});
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 40,
                  marginBottom: 38,
                }}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    alignContent: "center",
                    alignItems: "center",
                    backgroundColor: colors.secondaryBackground,
                  }}
                >
                  <Image
                    source={plus}
                    style={{
                      height: 30,
                      width: 30,
                      marginTop: 5,
                      tintColor: colors.primaryText,
                    }}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      color: colors.primaryText,
                      fontSize: 16,
                      marginLeft: 9,
                    }}
                  >
                    Create a new group
                  </Text>
                </View>
              </View>
            </Pressable>
          </View>
        )}
        <GroupList
          state={state}
          myContext={myContext}
          navigation={navigation}
          refererActionId={state.refererActionId}
          hostUserId={state.hostUserId}
          colors={colors}
        />
      </View>
    </View>
  );
};

export default GroupListScreen;
