import React, { useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  Pressable,
} from "react-native";
import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";
import { useTheme } from "../theme";
import { API, graphqlOperation } from "aws-amplify";
import { addRequest } from "../src/graphql/custom";
import { generateUniqueId } from "../utils/Id";
import { timeStamp } from "../utils/TimeStamp";

const DeleteAccountScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const myContext = useContext(AppContext);
  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const onSubmitDeletion = async () => {
    const response = await API.graphql(
      graphqlOperation(addRequest, {
        Id: generateUniqueId(),
        userId: myContext.Id,
        type: "AccountDeletion",
        creationTS: timeStamp(),
      })
    );
    console.log("Saved request", response);
    navigation.goBack();
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <TouchableOpacity onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </TouchableOpacity>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: "Delete Account",
      headerTitleStyle: {
        fontSize: 15,
        fontWeight: "700",
        lineHeight: 22,
        color: colors.primaryText,
      },
    });
  }, [navigation, colors]);

  return (
    <SafeAreaView
      style={{
        backgroundColor: colors.background,
        flex: 1,
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <ScrollView style={styles.container} bounces={false}>
          <View style={{ marginTop: 20 }}>
            <Text style={[styles.text, { color: colors.primaryText }]}>
              {"Your profile, comments, and other data will be permanently deleted in 14 days." +
                "\n\n" +
                "If you choose to delete your account, you won’t be able to recover it after 14 days."}
            </Text>
          </View>
        </ScrollView>
        <Pressable
          onPress={() => {
            onSubmitDeletion();
          }}
          style={[
            styles.button,
            { backgroundColor: colors.primaryButtonBackground },
          ]}
        >
          <Text
            style={[
              styles.titleButton,
              {
                color: colors.primaryButtonText,
              },
            ]}
          >
            {"Delete Account"}
          </Text>
        </Pressable>
        <View style={{ height: 60 }} />
      </View>
    </SafeAreaView>
  );
};

export default DeleteAccountScreen;

const styles = StyleSheet.create({
  container: {
    marginLeft: 20,
    marginRight: 20,
  },
  text: {
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 22,
  },
  button: {
    marginHorizontal: 16,
    height: 54,
    flexDirection: "row",
    borderWidth: 1,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  titleButton: {
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 22,
  },
});
