import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { updateUserCounts } from "../src/graphql/custom";
import { toggleFollow } from "../utils/Follow";
import { addIds, removeIds } from "../utils/IdList";
import { initialUser } from "../utils/InitialValues";
import { clog } from "../utils/Log";
import { villageColors } from "../utils/SharedStyles";
import Blocker from "./Blocker";
import ColoredButton from "./ColoredButton";
import TopicChooser from "./TopicChooser";
import { generateMapFromIdStrings } from "../utils/IdList";
import { timeStamp } from "../utils/TimeStamp";

const TopicEditor = ({
  topicOptions,
  myContext,
  colors,
  phase,
  setPhase,
  goBack,
  isShowAlert,
  isChanged,
  setIsChanged,
  dataRefresh,
  changedTopics,
}) => {
  const initialState = {
    ...initialUser,
  };
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(myContext);
  const [wishList, setWishList] = useState(
    myContext.wishList ? myContext.wishList : ""
  );

  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
  const [numChooseTopic, setNumChooseTopic] = useState(0);
  const [activeTopics, setActiveTopics] = useState({});

  useEffect(() => {
    if (myContext && !state.Id) {
      setState((prevState) => {
        return {
          ...prevState,
          Id: myContext.Id,
          numTopicFollow: myContext.numTopicFollow,
          actionsByUser: myContext.actionsByUser,
          wishList: myContext.wishList,
        };
      });
    }
  }, []);

  useEffect(() => {
    if (state.declaredTopicIds) {
      const followTopics = {};
      let followedTopicIds = generateMapFromIdStrings([state.declaredTopicIds]);
      Object.keys(followedTopicIds).forEach((item) => {
        followTopics[item] = true;
      });

      setActiveTopics(followTopics);
    }
  }, [state]);

  useEffect(() => {
    if (state.declaredTopicIds) {
      let followedTopicIds = generateMapFromIdStrings([state.declaredTopicIds]);
      const _chooseTopics = Object.keys(followedTopicIds).length;

      setNumChooseTopic(_chooseTopics);
    }
  }, [state]);

  useEffect(() => {
    if (isShowAlert) {
      if (Platform.OS !== "web") {
        Alert.alert(
          "Unsaved changes",
          "If you leave without saving, you will lose your changes.",
          [
            {
              style: "cancel",
              text: "Discard changes",
              onPress: goBack,
            },
            {
              text: "Save changes",
              onPress: handleSubmit,
            },
          ]
        );
      } else {
        setShowUnsavedChangesAlert(true);
      }
    }
  }, [isShowAlert]);

  const onPressTopic = ({ item, following, setFollowing }) => {
    if (changedTopics[item.Id]) {
      delete changedTopics[item.Id];
    } else {
      changedTopics[item.Id] = { item, following: following };
    }

    if (!following) {
      setNumChooseTopic((prev) => {
        return prev + 1;
      });

      setActiveTopics((prevState) => {
        return { ...prevState, [item.Id]: true };
      });
    } else {
      setNumChooseTopic((prev) => {
        return prev - 1;
      });
      const newItems = { ...activeTopics };
      delete newItems[item.Id];
      setActiveTopics(newItems);
    }

    setFollowing((prev) => {
      return !prev;
    });

    if (Object.keys(changedTopics).length !== 0) {
      !isChanged && setIsChanged(true);
    } else {
      isChanged && setIsChanged(false);
    }
  };

  const sendChoosenTopic = async ({ item, following }) => {
    if (myContext?.inflightFollows[item.Id]) {
      console.log("WARNING!!! FOLLOW IS INFLIGHT");
      return;
    }

    myContext.inflightFollows[item.Id] = true;
    let currentFollowing = following;

    await toggleFollow({
      following: currentFollowing,
      objectType: "Topic",
      target: item,
      refererActionId: null,
      hostUserId: null,
      myContext: myContext,
      callback: ({ success, message, error }) => {
        delete myContext.inflightFollows[item.Id];
        console.log("Done updating", item.Id);
        if (success) {
          // Update the declaredTopicIds field
          myContext.declaredTopicIds = following
            ? removeIds(myContext.declaredTopicIds, [item.Id])
            : addIds(myContext.declaredTopicIds, [item.Id]);
          myContext["declaredTopicsChanged"] = true;
          setState((previousState) => {
            return {
              ...previousState,
              actionsByUser: myContext.actionsByUser,
              numTopicFollow: myContext.numTopicFollow,
            };
          });
        } else {
          //TODO(alpha): undo button state if there is failure
        }
      },
    });
  };

  const handleSubmit = async () => {
    if (isChanged) {
      setLoading(true);

      try {
        const topics = Object.values(changedTopics);

        for (const value of topics) {
          let topic = value.item;
          let following = value.following;
          myContext.declaredTopicIds = following
            ? removeIds(myContext.declaredTopicIds, [topic.Id])
            : addIds(myContext.declaredTopicIds, [topic.Id]);
          myContext.numTopicFollow += following ? -1 : 1;
        }
        myContext["declaredTopicsChanged"] = true;
        myContext["lastTopicUpdateTime"] = timeStamp();
        myContext["wishList"] = wishList;

        let response = await API.graphql(
          graphqlOperation(updateUserCounts, {
            Id: myContext.Id,
            declaredTopicIds: myContext.declaredTopicIds,
            numTopicFollow: myContext.numTopicFollow,
            lastTopicUpdateTime: myContext.lastTopicUpdateTime,
            wishList: wishList,
          })
        );

        clog("RESPONSE", response);

        setState((prevState) => {
          return { ...prevState, wishList: wishList };
        });

        if (dataRefresh) {
          dataRefresh();
        }
      } catch (error) {
        console.log("Error:", error);
      }

      setLoading(false);
    }
    goBack();
  };

  const UnsavedChangesAlert = () => {
    return (
      <AwesomeAlert
        show={showUnsavedChangesAlert}
        showProgress={false}
        title={"Unsaved changes"}
        message={"If you leave without saving, you will lose your changes."}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText={"Discard changes"}
        confirmText={"Save changes"}
        confirmButtonColor={colors.cancelButton}
        onConfirmPressed={() => {
          handleSubmit();
          setShowUnsavedChangesAlert(false);
        }}
        onCancelPressed={() => {
          goBack();
          setShowUnsavedChangesAlert(false);
        }}
        onDismiss={() => setShowUnsavedChangesAlert(false)}
      />
    );
  };

  const renderTopicCreator = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          bounces={false}
          contentContainerStyle={{ flexGrow: 1 }}
          keyboardShouldPersistTaps="handled"
        >
          <View style={{ flex: 1 }}>
            <TextInput
              value={wishList}
              onChangeText={(newValue) => {
                setWishList(newValue);
                setIsChanged(true);
              }}
              multiline={true}
              placeholder={
                "The more details you give, the better your feed will get!"
              }
              placeholderTextColor={colors.placeholderText}
              style={[
                styles.textInput,
                {
                  color: villageColors.Link,
                  borderColor: colors.smallDivider,
                },
              ]}
            />
          </View>
        </ScrollView>
        <ColoredButton
          onPress={handleSubmit}
          buttonStyle={[
            styles.button,
            wishList !== ""
              ? { backgroundColor: colors.primaryButtonBackground }
              : {
                  borderColor: colors.secondaryButtonBorder,
                  borderWidth: 1,
                },
          ]}
          text={wishList === "" ? "Not now" : "Save"}
          textStyle={[
            styles.titleButton,
            {
              color:
                wishList !== ""
                  ? colors.primaryButtonText
                  : colors.secondaryButtonText,
            },
          ]}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1, width: "100%" }}>
      {phase === 0 ? (
        <View style={{ flex: 1 }}>
          <TopicChooser
            topics={topicOptions}
            colors={colors}
            followTopics={activeTopics}
            myContext={myContext}
            onPressTopic={onPressTopic}
          />
          <ColoredButton
            onPress={() => setPhase(1)}
            disabled={numChooseTopic < 3}
            buttonStyle={[
              styles.button,
              {
                backgroundColor:
                  numChooseTopic >= 3
                    ? colors.primaryButtonBackground
                    : colors.disabledPrimaryButtonBackground,
              },
            ]}
            text={numChooseTopic >= 3 ? "Next" : "Select at least 3"}
            textStyle={[
              styles.titleButton,
              {
                color:
                  numChooseTopic >= 3
                    ? colors.primaryButtonText
                    : colors.disabledPrimaryButtonText,
              },
            ]}
          />
        </View>
      ) : (
        renderTopicCreator()
      )}

      <Blocker loading={loading} />
      <UnsavedChangesAlert />
    </View>
  );
};

export default TopicEditor;

const styles = StyleSheet.create({
  button: {
    height: 54,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    marginTop: 12,
  },
  titleButton: {
    fontSize: 15,
    fontWeight: "700",
    textAlign: "center",
  },
  inputContainer: {
    width: "100%",
    flex: 1,
  },
  textInput: {
    fontSize: 18,
    lineHeight: 28,
    padding: 12,
    paddingTop: 12,
    minHeight: 180,
    width: "100%",
    borderWidth: 1,
    borderRadius: 8,
    marginTop: 24,
    marginBottom: 16,
  },
});
