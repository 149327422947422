import React, { useContext } from "react";
import { TouchableOpacity, View, StyleSheet, SafeAreaView } from "react-native";
import LeftArrow from "../components/LeftArrow";
import { useTheme } from "../theme";
import AppContext from "../components/AppContext";
import WebView from "react-native-webview";

const WebViewScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const myContext = useContext(AppContext);

  const { webUrl, title } = route?.params;

  let webref = null;

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <TouchableOpacity onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </TouchableOpacity>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: title,
      headerTitleStyle: { fontSize: 15, fontWeight: "700", lineHeight: 22 },
    });
  }, [navigation, colors]);

  return (
    <View
      style={{
        backgroundColor: colors.background,
        flex: 1,
        alignItems: "center",
      }}
    >
      <SafeAreaView />
      <View style={styles.container}>
        <WebView
          style={{ flex: 1 }}
          source={{ uri: webUrl }}
          ref={(r) => (webref = r)}
          originWhitelist={["*"]}
          javaScriptEnabled={true}
          domStorageEnabled={true}
        />
      </View>
    </View>
  );
};

export default WebViewScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 756,
  },
});
