import { Auth } from "aws-amplify";
import { resetUserData } from "../utils/DataFetcher";
import { clog } from "../utils/Log";

export async function SignIn({ username, password, myContext, callback }) {
  let user = null;
  let status = { success: true, user: null };
  try {
    user = await Auth.signIn(username, password);
    if (user) {
      clog(user);
      const requireMFA = user.Session !== null;
      clog("Requires MFA", requireMFA);
      if (user.challengeName === "SMS_MFA") {
        myContext.authState = "confirmSignIn";
        status.success = false;
        status["message"] = "Need to use Multi Factor Authentication";
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        clog("require new password", user.challengeParam);
        myContext.authState = "requireNewPassword";
        status.success = false;
        status["message"] = "Need to change password";
      } else {
        myContext.authState = "loggedIn";
        myContext.authenticated = true;
        status.user = user;
      }
    } else {
      status.success = false;
      status["message"] = "Incorrect username or password";
    }
  } catch (err) {
    if (err.code === "PasswordResetRequiredException") {
      console.log("the user requires a new password");
      myContext.authState = "requireNewPassword";
      status.success = false;
      status["message"] = "the user requires a new password";
    } else if (err.code === "NotAuthorizedException") {
      console.log("Not authorized");
      myContext.authState = "notAuthorized";
      status.success = false;
      status["message"] = "Incorrect username or password";
    } else if (err.code === "UserNotFoundException") {
      console.log("Not found");
      myContext.authState = "notFound";
      status.success = false;
      status["message"] = "User not found";
    } else {
      console.log("Unknown error", err.code, err);
      status.success = false;
      status["message"] = "Unknown error: " + err.code;
    }
  }
  if (callback) {
    callback(status);
  }
  return status;
}

export async function SignOut({ myContext }) {
  myContext.authenticated = false;
  resetUserData(myContext);
  try {
    await Auth.signOut();
  } catch (err) {
    console.log("not authenticated", err);
  }
}

export async function guestLogin({ myContext, callback }) {
  await SignIn({
    username: "guest",
    password: "hello123",
    myContext: myContext,
    callback: ({ success, message, error, user }) => {
      if (success) {
        myContext.Id = "unknown";
      } else {
        clog(message);
      }
      if (callback) {
        callback({ success: success, message: message, error: error });
      }
    },
  });
}

export async function guestLogout({ myContext }) {
  myContext.authenticated = false;
  try {
    await Auth.signOut();
  } catch (err) {
    console.log("not authenticated", err);
  }
  myContext.Id = "";
  clog("LOGGED OUT THE GUEST");
}
