import React, { useEffect, useState, useContext, useRef } from "react";
import {
  ActionSheetIOS,
  Alert,
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  View,
  SafeAreaView,
  FlatList,
  Image,
  Platform,
  Pressable,
  Modal,
} from "react-native";

import AppContext from "../components/AppContext";
import SourceIcon from "../components/SourceIcon";
import pen from "../assets/pen.png";
import plus from "../assets/plus.png";
import threeDots from "../assets/three-dots.png";
import share from "../assets/share.png";
import AwesomeAlert from "react-native-awesome-alerts";
import MultiButtonAlert from "../utils/MultiButtonAlerts";
import Blocker from "../components/Blocker";
import { timeStamp } from "../utils/TimeStamp";
import LeftArrow from "../components/LeftArrow";
import List from "../components/List";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import ColoredButton from "../components/ColoredButton";
import { refreshUserData } from "../utils/DataFetcher";
import { photoIdFromId } from "../utils/RandomFromId";
import { useDebounce } from "../utils/Debounce";
import { shareIfPossible } from "../utils/ShareOut";
import { clog } from "../utils/Log";
import {
  copyPin,
  savePin,
  deletePin,
  movePin,
} from "../controllers/PinController";
import { createList, deleteList } from "../controllers/ListController";
import { getListData } from "../controllers/ListController";
import { logEvent } from "../utils/LogEvent";
import ItemFooter from "../components/ItemFooter";
import { useTheme } from "../theme";
import WebView from "react-native-webview";
import cross from "../assets/cross.png";
import Item from "../components/Item";
import PopUp from "../components/PopUp";

const ContentListScreen = ({ route, navigation }) => {
  async function getData(Id, name, owner, callback = null) {
    await getListData({
      Id: Id,
      name: name,
      owner: owner,
      myContext: myContext,
      stateGetter: () => {
        return state;
      },
      stateSetter: setState,
      callback: ({ success, message, error, fetchedListData }) => {
        if (success) {
          fetchedList = fetchedListData;
          clog("LIST", fetchedList);
        }
        if (callback) {
          if (callback instanceof Function) {
            callback();
          } else {
            console.log(
              "ERROR: received non-empty non-function for callback",
              callback
            );
          }
        }
      },
    });
  }

  async function shareList(
    handle,
    name,
    Id,
    sharerId,
    sharerHandle,
    sharerName,
    ownerName,
    own,
    size
  ) {
    let { success, message } = await shareIfPossible(
      "List",
      handle,
      name,
      Id,
      sharerId,
      sharerHandle,
      sharerName,
      showStatusMessage,
      ownerName,
      own,
      size
    );
    if (!success) {
      showStatusMessage(message);
    }
    logEvent("Share_Collection", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: "collection",
      platform: Platform.OS,
      collection_id: Id,
      action: "click",
      target: "share",
      status: success ? "success" : "failure",
      appVersion: myContext.appVersion,
    });
  }

  async function deleteAPin(directPinUrl, batch = false) {
    setLoading(true);
    if (directPinUrl == null) {
      directPinUrl = selectedPinUrl;
    }
    // Find the right pin to delete
    let pin = null;
    directPinUrl?.pins?.forEach((p) => {
      if (p?.list?.Id == state.Id) {
        p?.actions?.items?.forEach((a) => {
          if (
            a.objectType == "Pin" &&
            a.operation == "Create" &&
            a.actorId == myContext.Id
          ) {
            pin = p;
          }
        });
      }
    });
    if (pin.Id != directPinUrl?.pins?.[0]?.Id) {
      clog(
        "WOULD HAVE DELETED WRONG PIN!",
        directPinUrl?.pins?.[0],
        "instead of",
        pin
      );
    }
    if (pin) {
      deletePin({
        url: directPinUrl,
        pin: pin,
        listId: state.Id,
        listNumPins: state.numPins,
        oldList: state,
        myContext: myContext,
        batch: batch,
        stateGetter: () => {
          return state;
        },
        stateSetter: setState,
        callback: ({ success, message, error }) => {
          setLoading(false);
          if (!success) {
            showMessage(message);
          }
        },
      });
    }
  }

  async function refreshFromCallback() {
    await refreshUserData(myContext);
  }

  async function deleteAList() {
    setLoading(true);
    deleteList({
      oldList: state,
      myContext: myContext,
      callback: ({ success, message, error }) => {
        logEvent("MyProfile_CollectionDetail_Edit_Delete", {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: "myprofile",
          platform: Platform.OS,
          target: "collection",
          action: "click",
          status: success ? "success" : "failure",
          name: state.name,
          appVersion: myContext.appVersion,
        });
        if (success) {
          refreshFromCallback();
          setLoading(false);
          navigation.goBack();
        } else {
          setLoading(false);
          showMessage("Failed to delete Collection!");
        }
      },
    });
  }

  async function createAList() {
    setLoading(true);
    createList({
      name: name,
      description: description,
      myContext: myContext,
      callback: ({ success, message, error, listId }) => {
        clog("FINISHED CREATING LIST", success, listId);
        if (success) {
          setTargetListId(listId);
          setModalVisible(false);
          refreshFromCallback();
          setLoading(false);
          if (selectedPinUrl) {
            if (chosenOperation == "Copy") {
              copyAPin(selectedPinUrl, listId);
            } else if (chosenOperation == "Move") {
              clog("Asked to MOVE");
              moveAPin(selectedPinUrl, listId);
            } else {
              clog("Uknown operation", chosenOperation);
              showMessage("Unknown operation: " + chosenOperation);
            }
          } else {
            showMessage("EMPTY PIN URL");
            clog("EMPTY PIN URL");
          }
        } else {
          setLoading(false);
          showMessage(message);
          myContext.createListButtonDisabled = false;
          setCreateListButtonDisabled(false);
        }
      },
    });
  }

  async function saveAPin(item) {
    let targetId = "";
    let url = item ? item : selectedPinUrl;
    url?.pins?.forEach((pin) => {
      if (state.owner.Id == pin?.curatorId) {
        targetId = pin?.Id;
      }
    });
    savePin({
      url: url,
      curator: state.owner,
      myContext: myContext,
      targetId: targetId,
      callback: ({ success, message, error }) => {
        setLoading(false);
        if (!success) {
          showInfoMessage(message);
        } else {
          dataRefresh();
          refreshFromCallback();
          showInfoMessage("Saved Pin!");
        }
      },
    });
  }

  async function copyAPin(item, listId) {
    copyPin({
      url: item ? item : selectedPinUrl,
      listId: listId,
      curator: state.owner,
      myContext: myContext,
      callback: ({ success, message, error }) => {
        setLoading(false);
        if (!success) {
          showInfoMessage(message);
        } else {
          dataRefresh();
          refreshFromCallback();
          showInfoMessage("Saved Pin!");
        }
      },
    });
  }

  async function moveAPin(item, listId) {
    if (item == null) {
      item = selectedPinUrl;
    }
    let url = item;
    let pin = null;
    url?.pins?.forEach((p) => {
      if (p?.list?.Id == state.Id) {
        p?.actions?.items?.forEach((a) => {
          if (
            a.objectType == "Pin" &&
            a.operation == "Create" &&
            a.actorId == myContext.Id
          ) {
            pin = p;
          }
        });
      }
    });
    if (pin.Id != url?.pins?.[0]?.Id) {
      clog("WOULD HAVE MOVED WRONG PIN!", url?.pins?.[0], "instead of", pin);
    }
    if (pin) {
      movePin({
        url: url,
        pin: pin,
        listId: listId,
        sourceList: state,
        myContext: myContext,
        callback: ({ success, message, error }) => {
          if (success) {
            dataRefresh();
            setLoading(false);
            showInfoMessage("Moved Pin!");
            refreshFromCallback();
          } else {
            setLoading(false);
            showInfoMessage(message);
          }
        },
      });
    }
  }

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const showStatusMessage = (message) => {
    setStatusMessage(message);
    setStatusVisible(true);
    setTimeout(() => {
      setStatusVisible(false);
    }, 3000);
  };

  const handleSubmit = () => {
    let message = "";
    if (!name) {
      message = "Must provide name";
    }
    if (message) {
      myContext["createListButtonDisabled"] = false;
      setCreateListButtonDisabled(false);
      showMessage(message);
    } else {
      clog("Will create a list to copy or move pin to");
      createAList();
    }
  };

  const renderItem = ({ item, index }) => {
    clog("rendering item", item);
    let id = photoIdFromId(item.Id);
    let screenshotUrlLocal = "https://picsum.photos/id/" + id + "/300/200";
    //let screenshotUrl = "https://picsum.photos/300/200";
    if (item) {
      if (item.photoUrl) {
        screenshotUrlLocal = item.photoUrl;
      } else if (item.photo) {
        if (myContext.presignedUrls[item.photo]) {
          screenshotUrlLocal = myContext.presignedUrls[item.photo].uri;
        }
      }
    }
    item.screenshotUrl = screenshotUrlLocal;
    ////clog("pressable item is", item);
    let topActions = item?.topActions;
    let topAction = topActions?.[0]?.action;
    let topTarget = topActions?.[0]?.target;

    let topTargetJSON = {};
    if (topTarget?.markup) {
      try {
        topTargetJSON = JSON.parse(topTarget?.content);
      } catch (err) {
        console.log("parse error", err);
        topTarget["content"] = "";
        topTarget.markup = false;
      }
    }

    const contentModalFunction = (target) => {
      clog("asked to open website", target);
      if (Platform.OS == "web") {
        window.open(target, "_blank");
      } else {
        setWebModalUrl(target);
        setWebModalVisible(true);
      }
    };

    const onPress = () => {
      clog("pressed button for", item);
      clog("URL DETAILS:", item);
      navigation.push("ItemDetails", {
        url: item,
        curator: state.owner,
        refererActionId: null,
        hostUserId: state.owner.Id,
        incomplete: true,
        origin: "collection",
      });
    };

    const renderActions = () => {
      const onPress = () => {
        clog("got pressed");
        setSelectedPinUrl(item);
        if (Platform.OS == "ios") {
          ActionSheetIOS.showActionSheetWithOptions(
            {
              options:
                myContext.Id == state.owner.Id
                  ? [
                      "Cancel",
                      "Move to another collection",
                      "Add to another collection",
                      "Remove from this collection",
                    ]
                  : ["Cancel", "Save to my collection"],
              destructiveButtonIndex: 3,
              cancelButtonIndex: 0,
              userInterfaceStyle: "dark",
            },
            (buttonIndex) => {
              if (buttonIndex === 0) {
                // cancel action
              } else if (buttonIndex === 1) {
                clog("asked to remove/save", selectedPinUrl);

                if (myContext.Id == state.owner.Id) {
                  clog("Will move the pin");
                  //moveAPin(item);
                  setChosenOperation("Move");
                  setModalVisible(true);
                } else {
                  saveAPin(item);
                }
              } else if (buttonIndex === 2) {
                clog("asked to copy", selectedPinUrl);
                setChosenOperation("Copy");
                setModalVisible(true);
              } else if (buttonIndex === 3) {
                clog("asked to remove/save", selectedPinUrl);
                deleteAPin(item);
              }
            }
          );
        } else if (Platform.OS == "android") {
          clog("will create alert");
          Alert.alert(
            "Alert Title",
            "My Message",
            myContext.Id == state.owner.Id
              ? [
                  {
                    text: "Move to another collection",
                    onPress: () => {
                      clog("asked to move");
                      //deleteAPin(item);
                      setChosenOperation("Move");
                      setModalVisible(true);
                    },
                  },
                  {
                    text: "Add to another collection",
                    onPress: () => {
                      clog("asked to add");
                      //deleteAPin(item);
                      setChosenOperation("Copy");
                      setModalVisible(true);
                    },
                  },
                  {
                    text: "Remove from this collection",
                    onPress: () => {
                      clog("asked to remove");
                      deleteAPin(item);
                    },
                  },
                  {
                    text: "Cancel",
                    onPress: () => clog("Cancel Pressed"),
                    style: "cancel",
                  },
                ]
              : [
                  {
                    text: "Save to my collection",
                    onPress: () => {
                      clog("asked to save");
                      saveAPin(item);
                    },
                  },
                  {
                    text: "Cancel",
                    onPress: () => clog("Cancel Pressed"),
                    style: "cancel",
                  },
                ]
          );
        } else if (Platform.OS == "web") {
          clog("will show alert");
          if (myContext.Id == state.owner.Id) {
            setShowMovementAlert(true);
          } else {
            showMessage("Save to my collection");
          }
        }
      };

      return (
        <View style={{ position: "absolute", right: 0 }}>
          {topTarget?.curatorId == myContext.Id && (
            <Pressable
              onPress={onPress}
              style={{
                alignSelf: "center",
                marginLeft: 8,
              }}
            >
              <Image
                source={threeDots}
                style={{
                  height: 24,
                  width: 24,
                  alignContent: "center",
                  tintColor: colors.primaryText,
                }}
              />
            </Pressable>
          )}
        </View>
      );
    };

    return (
      <View>
        <Pressable onPress={onPress}>
          <View
            style={{
              flexDirection: "column",
              marginLeft: 16,
              marginRight: 16,
              backgroundColor: colors.contentCardBackground,

              borderRadius: 8,
              marginBottom: 16,
            }}
          >
            <Item
              item={item}
              state={state}
              myContext={myContext}
              navigation={navigation}
              origin={"profile"}
              showJustification={true}
              index={index}
              onPress={onPress}
              colors={colors}
              contentModalFunction={contentModalFunction}
              skipCollection={true}
              renderActions={() => renderActions()}
              onShowReportPopUp={(message) => {
                setMessageReportPopUp(message);
                setShowReportPopUp(true);
                popUpRef.current?.onAnimatedPopUp();
              }}
            />

            <View style={{ margin: 16 }}>
              <ItemFooter
                url={item}
                myContext={myContext}
                navigation={navigation}
                setLoading={setLoading}
                showAlertMessage={showStatusMessage}
                origin={"collection"}
                forceRefresh={(element, callback) => {
                  console.log("Asked to force refresh");
                  // TODO(alpha): consider opimizing refreshing only the modified element
                  dataRefresh(callback);
                }}
                colors={colors}
                context={"card"}
              />
            </View>
          </View>
        </Pressable>
      </View>
    );
  };

  const showInfoMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setInfoMessage(message);
      setShowInfo(true);
    }
  };

  const showAlertMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  clog("navigation is in user details ", navigation);
  clog("route is ", route);
  let myContext = useContext(AppContext);
  clog("app context is", myContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const initialState = {
    Id: route?.params?.Id ? route.params.Id : "",
    name: route?.params?.name ? route.params.name : "",
    owner: route?.params?.owner ? route.params.owner : {},
    description: route?.params?.description ? route.params.description : "",
    screenshotUrl: route?.params?.screenshotUrl
      ? route.params.screenshotUrl
      : "",
    pins: [],
    urls: [],
    topics: [],
    fetchTimeStamp: 0,
    version: 0,
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);

  const { colors } = useTheme();
  const [screenshotUrl, setScreenshotUrl] = useState(
    route?.params?.screenshotUrl ? route.params.screenshotUrl : ""
  );
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showCollectionAlert, setShowCollectionAlert] = useState(false);
  const [showCollectionConfirmationAlert, setShowCollectionConfirmationAlert] =
    useState(false);
  const [showMovementAlert, setShowMovementAlert] = useState(false);
  const [showMovementConfirmationAlert, setShowMovementConfirmationAlert] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedPinUrl, setSelectedPinUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [targetListId, setTargetListId] = useState(null);
  const [chosenOperation, setChosenOperation] = useState(null);
  const [creatingList, setCreatingList] = useState(false);
  const [createListButtonDisabled, setCreateListButtonDisabled] =
    useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [statusVisible, setStatusVisible] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");

  const [showReportPopUp, setShowReportPopUp] = useState(false);
  const [messageReportPopUp, setMessageReportPopUp] = useState("");

  const popUpRef = useRef(null);

  const { debounce } = useDebounce();
  let fetchedList = null;

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  React.useLayoutEffect(() => {
    if (state.Id) {
      navigation.setOptions({
        headerLeft: () => {
          return (
            <Pressable onPress={navigation.goBack}>
              <View
                style={{
                  backgroundColor: "rgba(0,0,0,0.45)",
                  width: 32,
                  height: 32,
                  borderRadius: 32,
                  overflow: "hidden",
                  marginLeft: 8,
                }}
              >
                <LeftArrow />
              </View>
            </Pressable>
          );
        },
        headerBackTitleVisible: false,
        headerTitle: "", //state.name, // TODO: pull the list name
        headerTransparent: true,
        headerRight: () => (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {state.fetchTimeStamp != 0 && (
              <Pressable
                onPress={() => {
                  clog("ASKED TO SHARE");
                  shareList(
                    state.owner.handle,
                    state.name,
                    state.Id,
                    myContext.Id,
                    myContext.handle,
                    myContext.name,
                    state.owner.name,
                    state?.owner?.Id == myContext?.Id,
                    state?.pins?.length
                  );
                }}
              >
                <View
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0,0,0,0.45)",
                    marginRight: 8,
                  }}
                >
                  <Image
                    source={share}
                    style={{
                      height: 24,
                      width: 24,
                      tintColor: "white",
                    }}
                  />
                </View>
              </Pressable>
            )}
            {state?.name != "Saved" && myContext.Id == state?.owner?.Id && (
              <Pressable
                onPress={() => {
                  clog("got pressed");
                  if (Platform.OS == "ios") {
                    ActionSheetIOS.showActionSheetWithOptions(
                      {
                        options: ["Cancel", "Delete collection", "Edit"],
                        destructiveButtonIndex: 1,
                        cancelButtonIndex: 0,
                        userInterfaceStyle: "dark",
                      },
                      (buttonIndex) => {
                        if (buttonIndex === 0) {
                          // cancel action
                        } else if (buttonIndex === 1) {
                          clog("asked to remove");
                          Alert.alert(
                            "Are you sure",
                            "Do you want to delete this collection?",
                            [
                              {
                                text: "Cancel",
                                onPress: () => clog("Cancel Pressed"),
                                style: "cancel",
                              },
                              {
                                text: "Delete",
                                onPress: () => {
                                  clog("OK Pressed");
                                  deleteAList();
                                },
                              },
                            ]
                          );
                        } else if (buttonIndex === 2) {
                          clog("asked to edit");
                          logEvent("MyProfile_CollectionDetail_Edit", {
                            userId: myContext?.Id,
                            username: myContext?.handle,
                            category: "myprofile",
                            platform: Platform.OS,
                            target: "edit",
                            action: "click",
                            appVersion: myContext.appVersion,
                          });
                          navigation.push("ContentListUpdate", {
                            updating: true,
                            listId: state.Id,
                            name: state.name,
                            description: state.description,
                            origin: "collection",
                          });
                        }
                      }
                    );
                  } else if (Platform.OS == "android") {
                    clog("will create alert");
                    Alert.alert(
                      "Collection Management",
                      "How would you like to change this collection?",
                      [
                        {
                          text: "Delete collection",
                          onPress: () => {
                            clog("asked to remove");
                            Alert.alert(
                              "Are you sure",
                              "Do you want to delete this collection?",
                              [
                                {
                                  text: "Cancel",
                                  onPress: () => clog("Cancel Pressed"),
                                  style: "cancel",
                                },
                                {
                                  text: "Delete",
                                  onPress: () => {
                                    clog("OK Pressed");
                                    deleteAList();
                                  },
                                },
                              ]
                            );
                          },
                        },
                        {
                          text: "Edit",
                          onPress: () => {
                            clog("asked to edit");
                            logEvent("MyProfile_CollectionDetail_Edit", {
                              userId: myContext?.Id,
                              username: myContext?.handle,
                              category: "myprofile",
                              platform: Platform.OS,
                              target: "edit",
                              action: "click",
                              appVersion: myContext.appVersion,
                            });
                            navigation.push("ContentListUpdate", {
                              updating: true,
                              listId: state.Id,
                              name: state.name,
                              description: state.description,
                              origin: "MyProfile_CollectionDetail_Edit",
                            });
                          },
                        },
                        {
                          text: "Cancel",
                          onPress: () => clog("Cancel Pressed"),
                          style: "cancel",
                        },
                      ]
                    );
                  } else if (Platform.OS == "web") {
                    clog("will show collection alert");
                    setShowCollectionAlert(true);
                  }
                }}
              >
                <View
                  style={{
                    backgroundColor: "rgba(0,0,0,0.45)",
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 8,
                  }}
                >
                  <Image
                    source={pen}
                    style={{
                      height: 15,
                      width: 13,
                      tintColor: "white",
                    }}
                  />
                </View>
              </Pressable>
            )}
          </View>
        ),
      });
    }
  }, [navigation, state]);

  clog("Updated value of context", myContext);

  async function dataRefresh(callback = null) {
    const { Id, name, owner } = route.params;
    clog("CLICKED ON TAB with", route.params);
    // do something
    if (
      stateRef.current.Id != Id ||
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef,
        "state Id",
        stateRef.current.Id,
        "Id",
        Id
      );
      getData(Id, name, owner, callback).catch((err) => {
        console.log("error finding list data: ", err);
      });
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
      if (callback) {
        if (callback instanceof Function) {
          callback();
        } else {
          console.log("ERROR: received non-empty non-function for callback");
        }
      }
    }
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  clog("operation", chosenOperation, "target collection", targetListId);

  ////clog("will populate page with", state);
  ////clog("viewer Id", myContext.Id, "owner Id", state.owner.Id);
  if (state.Id) {
    ////clog("REFRESH: viewer Id", myContext.Id, "owner Id", state.owner.Id);
    if (!screenshotUrl) {
      let screenshotUrlLocal = "";
      //let screenshotUrl = "https://picsum.photos/300/200";
      if (state.pins.length != 0) {
        let item = state.pins[0].url;
        if (item.photoUrl) {
          screenshotUrlLocal = item.photoUrl;
        } else if (item.photo) {
          if (myContext.presignedUrls[item.photo]) {
            screenshotUrlLocal = myContext.presignedUrls[item.photo].uri;
          }
        }
      }
      if (screenshotUrlLocal) {
        setScreenshotUrl(screenshotUrlLocal);
      }
    }
    console.log("Will render screen");
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: colors.contentScreenBackground,
        }}
      >
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          {statusVisible && (
            <View
              style={{
                height: 48,
                width: "100%",
                paddingLeft: 16,
                paddingRight: 16,
                position: "absolute",
                top: 200,
                zIndex: 3, // works on ios
                elevation: 3, // works on android
              }}
            >
              <Pressable
                onPress={() => {
                  setStatusVisible(false);
                }}
              >
                <View
                  style={{
                    zIndex: 3, // works on ios
                    elevation: 3, // works on android
                    alignItems: "center",
                    width: "100%",
                    height: 48,
                    backgroundColor: colors.notificationBackground,
                    borderWidth: 1,
                    borderColor: "black",
                    borderRadius: 8,
                    justifyContent: "center",
                  }}
                >
                  {statusMessage}
                </View>
              </Pressable>
            </View>
          )}
          <View
            style={{
              height: 180,
              width: "100%",
            }}
          >
            {screenshotUrl != "" && (
              <Image
                style={{
                  width: "100%",
                  height: 180,
                  borderRadius: 4,
                  resizeMode: "cover",
                }}
                source={{
                  uri: screenshotUrl,
                }}
              />
            )}
            <View
              style={
                screenshotUrl != ""
                  ? {
                      position: "absolute",
                      top: 140,
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: colors.secondaryBackground,
                      width: "100%",
                      height: 40,
                    }
                  : {
                      position: "absolute",
                      top: 140,
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: 40,
                    }
              }
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 20,
                  fontWeight: "700",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {state.name ? state.name : ""}
              </Text>
            </View>
          </View>
          <View style={styles.container}>
            <Blocker loading={loading} />
            <View style={{ marginBottom: 36 }}>
              <Text style={[styles.description, { color: colors.primaryText }]}>
                {state.description}
              </Text>
            </View>
            <FlatList
              style={styles.list}
              data={state.urls}
              renderItem={renderItem}
              keyExtractor={(item, index) => `${index}`}
              extraData={state}
              ListFooterComponent={() =>
                myContext.Id !== state.owner.Id && Platform.OS !== "web" ? (
                  <View style={{ height: 70 }} />
                ) : null
              }
            />
            {myContext.Id == state.owner.Id && (
              <View
                style={[
                  {
                    backgroundColor: colors.secondaryBackground,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 12,
                    paddingBottom: 12,
                  },
                  Platform.OS !== "web" ? { marginBottom: 70 } : {},
                ]}
              >
                <Pressable
                  onPress={() => {
                    ////clog("create pin!!");
                    navigation.push("CreatePin", {
                      listId: state.Id,
                      listNumPins: state.numPins,
                      listTopics: state.topics,
                      urls: state.urls,
                      oldList: fetchedList ? fetchedList : state,
                      category: "myprofile",
                    });
                    logEvent("MyProfile_CollectionDetail_AddContent", {
                      userId: myContext?.Id,
                      username: myContext?.handle,
                      category: "myprofile",
                      platform: Platform.OS,
                      action: "click",
                      target: "addcontent",
                      appVersion: myContext.appVersion,
                    });
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      height: 43,
                      backgroundColor: colors.primaryButtonBackground,
                      borderRadius: 100,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          width: 24,
                          height: 24,
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 5,
                        }}
                      >
                        <Image
                          source={plus}
                          style={{
                            height: 24,
                            width: 24,
                            tintColor: colors.primaryButtonText,
                          }}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            color: colors.primaryButtonText,
                            fontSize: 16,
                            fontWeight: "700",
                          }}
                        >
                          New content
                        </Text>
                      </View>
                    </View>
                  </View>
                </Pressable>
              </View>
            )}
            {Platform.OS == "web" && (
              <AwesomeAlert
                show={showInfo}
                showProgress={false}
                title=""
                message={infoMessage}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Ok"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  setShowInfo(false);
                }}
              />
            )}
            {Platform.OS == "web" && (
              <AwesomeAlert
                show={showAlert}
                showProgress={false}
                title=""
                message={alertMessage}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Cancel"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  clog("cancelled");
                  setShowAlert(false);
                }}
                showConfirmButton={true}
                confirmText="Yes"
                confirmButtonColor={colors.confirmButton}
                onConfirmPressed={() => {
                  clog("confirmed");
                  setShowAlert(false);
                  state.owner.Id == myContext.Id
                    ? deleteAPin(null)
                    : saveAPin(null);
                }}
              />
            )}
            {Platform.OS == "web" && (
              <AwesomeAlert
                show={showCollectionConfirmationAlert}
                showProgress={false}
                title="Are you Sure?"
                message={"Do you want to delete this collection?"}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Cancel"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  clog("cancelled");
                  setShowCollectionConfirmationAlert(false);
                }}
                showConfirmButton={true}
                confirmText="Delete"
                confirmButtonColor={colors.confirmButton}
                onConfirmPressed={() => {
                  clog("confirmed");
                  setShowCollectionConfirmationAlert(false);
                  if (state.owner.Id == myContext.Id) {
                    deleteAList();
                  }
                }}
              />
            )}
            {Platform.OS == "web" && (
              <MultiButtonAlert
                show={showCollectionAlert}
                showProgress={false}
                title=""
                message={"Manage Collection"}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Cancel"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  clog("cancelled");
                  setShowCollectionAlert(false);
                }}
                showConfirmButton={true}
                confirmText="Delete Collection"
                confirmButtonColor={colors.confirmButton}
                onConfirmPressed={() => {
                  clog("confirmed");
                  setShowCollectionAlert(false);
                  setShowCollectionConfirmationAlert(true);
                }}
                showExtraButton={true}
                extraText="Edit"
                extraButtonColor={colors.cancelButton}
                onExtraPressed={() => {
                  clog("extraed");
                  setShowCollectionAlert(false);
                  logEvent("MyProfile_CollectionDetail_Edit", {
                    userId: myContext?.Id,
                    username: myContext?.handle,
                    category: "myprofile",
                    platform: Platform.OS,
                    target: "edit",
                    action: "click",
                    appVersion: myContext.appVersion,
                  });
                  navigation.push("ContentListUpdate", {
                    updating: true,
                    listId: state.Id,
                    name: state.name,
                    description: state.description,
                    origin: "MyProfile_CollectionDetail_Edit",
                  });
                }}
              />
            )}
            {Platform.OS == "web" && (
              <MultiButtonAlert
                show={showMovementAlert}
                showProgress={false}
                title=""
                message={"Manage Pin"}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Cancel"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  clog("cancelled");
                  setShowMovementAlert(false);
                }}
                showConfirmButton={true}
                confirmText="Remove from this Collection"
                confirmButtonColor={colors.confirmButton}
                onConfirmPressed={() => {
                  clog("confirmed");
                  setShowMovementAlert(false);
                  showMessage("Remove from this collection");
                }}
                showExtraButton={true}
                extraText="Move to another collection"
                extraButtonColor={colors.cancelButton}
                onExtraPressed={() => {
                  clog("extraed");
                  setShowMovementAlert(false);
                  setChosenOperation("Move");
                  setModalVisible(true);
                }}
                showExtra2Button={true}
                extra2Text="Add to another collection"
                extra2ButtonColor={colors.cancelButton}
                onExtra2Pressed={() => {
                  clog("extra2ed");
                  setShowMovementAlert(false);
                  setChosenOperation("Copy");
                  setModalVisible(true);
                }}
                actionContainerStyle={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
          </View>

          {showReportPopUp && (
            <PopUp
              ref={popUpRef}
              title={messageReportPopUp}
              onClose={() => setShowReportPopUp(false)}
              colors={colors}
            />
          )}

          <Modal
            visible={modalVisible}
            transparent={true}
            onRequestClose={() => {
              setModalVisible(false);
            }}
          >
            <SafeAreaView style={styles.container}>
              <Blocker loading={loading} />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colors.background,
                  height: 45,
                }}
              >
                <View
                  style={{
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Pressable
                    onPress={() => {
                      if (creatingList) {
                        setCreatingList(false);
                      } else {
                        setModalVisible(false);
                      }
                    }}
                  >
                    <LeftArrow />
                  </Pressable>
                </View>
                <View
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "800",
                      color: colors.primaryText,
                    }}
                  >
                    {creatingList
                      ? "Create a new collection"
                      : chosenOperation == "Copy"
                      ? "Add to collection"
                      : "Move to collection"}
                  </Text>
                </View>
                <View style={{ width: 20, height: 1 }}></View>
              </View>
              {!creatingList && (
                <View
                  style={{
                    backgroundColor: colors.background,
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <Pressable
                    style={styles.pressableContainer}
                    onPress={() => {
                      clog("create a new collection");
                      myContext.createListButtonDisabled = false;
                      setCreateListButtonDisabled(false);
                      setName("");
                      setDescription("");
                      setCreatingList(true);
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 50,
                        marginTop: 50,
                      }}
                    >
                      <View
                        style={{
                          height: 30,
                          width: 30,
                          borderRadius: 30,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: colors.secondaryBackground,
                          marginRight: 10,
                        }}
                      >
                        <Image
                          source={plus}
                          style={{
                            height: 19,
                            width: 17,
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          color: colors.primaryText,
                          justifyContent: "center",
                        }}
                      >
                        New Collection
                      </Text>
                    </View>
                  </Pressable>
                  {myContext?.actionsByUser?.["List"]?.["Create"] != null && (
                    <FlatList
                      data={Object.values(
                        myContext?.actionsByUser["List"]["Create"]
                      )
                        ?.map((x) => Object.values(x)?.[0]?.object)
                        ?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))}
                      style={{
                        flex: 1,
                        marginBottom: 54,
                        // NOTE(alpha): Accesses object
                      }}
                      renderItem={({ item }) => {
                        if (item.Id == state.Id) {
                          return <View></View>;
                        }
                        return (
                          <Pressable
                            onPress={() => {
                              setTargetListId(item.Id);
                              setModalVisible(false);
                              if (selectedPinUrl) {
                                if (chosenOperation == "Copy") {
                                  copyAPin(selectedPinUrl, item.Id);
                                } else if (chosenOperation == "Move") {
                                  clog("ASKED TO MOVE");
                                  moveAPin(selectedPinUrl, item.Id);
                                } else {
                                  clog("UNKNOWN OPERATION");
                                }
                              } else {
                                clog("EMPTY PIN URL");
                              }
                            }}
                          >
                            <List
                              item={item}
                              myContext={myContext}
                              colors={colors}
                            />
                          </Pressable>
                        );
                      }}
                      keyExtractor={(item) => item?.Id}
                      numColumns={1}
                    />
                  )}
                </View>
              )}
              {creatingList && (
                <KeyboardAwareScrollView
                  style={{ backgroundColor: colors.background }}
                >
                  <View style={styles.inputContainer}>
                    <Text
                      style={{
                        color: colors.primaryText,
                        fontSize: 16,
                        fontWeight: "400",
                        marginTop: 38,
                        marginBottom: 8,
                      }}
                    >
                      Name
                    </Text>
                    <TextInput
                      style={[
                        styles.textInput,
                        {
                          color: colors.primaryText,
                          backgroundColor: colors.secondaryBackground,
                        },
                      ]}
                      placeholder="Like 'All-time favorites'"
                      placeholderTextColor={colors.secondaryText}
                      value={name}
                      maxLength={200}
                      onChangeText={(newValue) => setName(newValue)}
                      onBlur={Keyboard.dismiss}
                    />
                    <Text
                      style={{
                        color: colors.primaryText,
                        fontSize: 16,
                        fontWeight: "400",
                        marginTop: 8,
                        marginBottom: 8,
                      }}
                    >
                      Note
                    </Text>
                    <TextInput
                      style={[
                        styles.textAreaInput,
                        {
                          color: colors.primaryText,
                          backgroundColor: colors.secondaryBackground,
                        },
                      ]}
                      placeholder="What ties all of the content together?"
                      placeholderTextColor={colors.secondaryText}
                      value={description}
                      maxLength={255}
                      multiline={true}
                      onChangeText={(newValue) => setDescription(newValue)}
                      onBlur={Keyboard.dismiss}
                    />
                    <ColoredButton
                      onPress={() => {
                        if (!createListButtonDisabled) {
                          myContext["createListButtonDisabled"] = true;
                          setCreateListButtonDisabled(true);
                          handleSubmit();
                        } else {
                          clog("Clicked again");
                        }
                      }}
                      buttonStyle={
                        !createListButtonDisabled
                          ? {
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 160,
                              height: 54,
                              borderRadius: 16,
                              backgroundColor: colors.primaryButtonBackground,
                            }
                          : {
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 160,
                              height: 54,
                              borderRadius: 16,
                              backgroundColor:
                                colors.disabledPrimaryButtonBackground,
                            }
                      }
                      textStyle={{
                        textAlign: "center",
                        textAlignVertical: "center",
                        fontSize: 20,
                        fontWeight: "800",
                        color: colors.primaryText,
                      }}
                      text={"Create"}
                    ></ColoredButton>
                  </View>
                  <AwesomeAlert
                    show={showAlert}
                    showProgress={false}
                    title=""
                    message={alertMessage}
                    closeOnTouchOutside={true}
                    closeOnHardwareBackPress={false}
                    showCancelButton={true}
                    cancelText="Ok"
                    cancelButtonColor={colors.cancelButton}
                    onCancelPressed={() => {
                      setShowAlert(false);
                    }}
                  />
                </KeyboardAwareScrollView>
              )}
            </SafeAreaView>
          </Modal>

          <Modal
            visible={webModalVisible}
            onRequestClose={() => {
              setWebModalVisible(false);
            }}
          >
            <SafeAreaView style={styles.container}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    backgroundColor: colors.background,
                    height: 39,
                    paddingVertical: 10,
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Pressable
                    onPress={() => {
                      setWebModalVisible(false);
                    }}
                  >
                    <Image
                      source={cross}
                      style={{
                        marginRight: 20,
                        height: 19,
                        width: 17,
                        tintColor: colors.primaryText,
                      }}
                    />
                  </Pressable>
                </View>
                <WebView
                  style={{ flex: 1 }}
                  source={{ uri: webModalUrl }}
                  ref={(r) => (webref = r)}
                  originWhitelist={["*"]}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                />
              </View>
            </SafeAreaView>
          </Modal>
        </View>
      </View>
    );
  } else {
    return <View></View>;
  }
};

export default ContentListScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  list: {
    width: "100%",
  },
  item: {
    flexDirection: "row",
  },
  title: {
    fontSize: 16,
  },
  snippet: {
    fontSize: 16,
    opacity: 0.4,
  },
  description: {
    fontSize: 14,
    alignSelf: "center",
    textAlign: "center",
    marginLeft: 57,
    marginRight: 57,
  },
  inputContainer: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  textInput: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 47,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 16,
  },
  textAreaInput: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 150,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    borderRadius: 16,
    alignContent: "flex-start",
  },
});
