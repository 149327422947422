import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Pressable,
  Image,
  StyleSheet,
  ActivityIndicator,
  Modal,
  Text,
  View,
  RefreshControl,
  Platform,
  Dimensions,
  Animated,
  SafeAreaView,
  Alert,
} from "react-native";

import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";

import { WebView } from "react-native-webview";

import ProfileCard from "../components/ProfileCard";
import List from "../components/List";
import Avatar from "../components/Avatar";

import { initialUser } from "../utils/InitialValues";
import { sharedStyles } from "../utils/SharedStyles";

import cross from "../assets/cross.png";
import link from "../assets/link.png";
import plus from "../assets/plus.png";
import settings from "../assets/settings.png";
import edit from "../assets/edit.png";
import cloud1 from "../assets/cloud_1.png";
import threeDots from "../assets/three-dots.png";
import userBlock from "../assets/user-block.png";
import user from "../assets/user.png";

import Blocker from "../components/Blocker";
import { timeStamp } from "../utils/TimeStamp";
import { clog } from "../utils/Log";
import { getUserDetailsData } from "../controllers/UserController";
import { toggleBlock } from "../utils/Block";
import { toggleFollow } from "../utils/Follow";
import CTA from "../utils/CTA";
import { logEvent } from "../utils/LogEvent";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "../theme";
import { Portal } from "react-native-portalize";
import { Modalize } from "react-native-modalize";
import CloseButton from "../components/CloseButton";
import AwesomeAlert from "react-native-awesome-alerts";
import PopUp from "../components/PopUp";

const UserDetailsScreen = ({ route, navigation }) => {
  const initialState = initialUser;
  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const { colors } = useTheme();

  const [state, setState] = useState(initialState);
  const [refreshing, setRefreshing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  const [guest, setGuest] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [lastCTADismissal, setLastCTADismissal] = useState(
    myContext?.lastCTADismissal ? myContext.lastCTADismissal : 0
  );

  const [showAlert, setShowAlert] = useState(false);
  const [showBlockPopUp, setShowBlockPopUp] = useState(false);

  const menuRef = useRef(null);
  const popUpRef = useRef(new Animated.Value(0)).current;

  // Take passed Id
  // Otherwise
  //    if handle is passed
  //        If handle is the same as current user
  //           use current user Id
  //        Othereise
  //           do not set handle
  //   Otherwise set current user Id
  let Id = route?.params?.Id
    ? route.params.Id
    : route?.params?.handle
    ? route?.params?.handle == myContext?.handle
      ? myContext?.Id
      : ""
    : myContext?.Id;

  let handle = route?.params?.handle
    ? route.params.handle
    : Id == myContext.Id
    ? myContext?.handle
    : "";

  let fromList = route?.params?.fromList ? route.params.fromList : false;

  //Animation for avatar and username
  const insets = useSafeAreaInsets();

  const [userInfoHeight, setUserInfoHeight] = useState(0);

  const scrollY = useRef(new Animated.Value(0)).current;

  const HEADER_HEIGHT = 50;
  const WINDOW_HEIGHT = Dimensions.get("window").height;

  //

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation, state, myContext, userInfoHeight]);

  async function getData(handle, Id) {
    let start = performance.now();
    let { success, message, error } = await getUserDetailsData(
      handle,
      Id,
      myContext,
      () => {
        return state;
      },
      setState
    );
    let fetchDone = performance.now();
    console.log("TIME: user profile fetch", (fetchDone - start) / 1000);
    setRefreshing(false);
  }

  async function dataRefresh() {
    clog("CLICKED ON TAB");
    logEvent(myContext?.Id == Id ? "MyProfile_View" : "OtherProfile_View", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: myContext?.Id == Id ? "myprofile" : "otherprofile",
      platform: Platform.OS,
      targetuser: handle,
      appVersion: myContext.appVersion,
    });

    // do something
    if (
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800 ||
      !stateRef.current.Id
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef.current
      );
      getData(handle, Id).catch((err) => {
        console.log("error finding data: ", err);
      });
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef.current
      );
    }
  }

  const showStatusMessage = (message) => {
    setStatusMessage(message);
    setStatusVisible(true);
    setTimeout(() => {
      setStatusVisible(false);
    }, 3000);
  };

  clog("route", route);
  clog("CONTEXT VERSION", myContext.version);

  let style = { flex: 1, backgroundColor: colors.background };
  // Determine top followers

  const regex = /.*\/\//;
  let displayedWebsite = state?.websites?.replace(regex, "");
  if (displayedWebsite == null) {
    displayedWebsite = "";
  }

  // NOTE(alpha): Accesses key only
  const [following, setFollowing] = useState(false);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    setFollowing(
      myContext.actionsByUser?.["User"]?.["Follow"]?.[state?.Id] ? true : false
    );
    setBlocking(
      myContext.actionsByUser?.["User"]?.["Block"]?.[state?.Id] ? true : false
    );
  }, [state.Id]);

  let followFn = () => {
    if (myContext?.inflightFollows[state.Id]) {
      console.log("WARNING!!! FOLLOW IS INFLIGHT", state.Id);
      return;
    }
    myContext.inflightFollows[state.Id] = true;
    let currentFollowing = following;
    setFollowing((prev) => {
      return !prev;
    });
    toggleFollow({
      following: currentFollowing,
      objectType: "User",
      target: state,
      refererActionId: null,
      hostUserId: state.Id,
      myContext: myContext,
      callback: ({ success, message, error }) => {
        delete myContext.inflightFollows[state.Id];
        if (success) {
          setState((previousState) => {
            ////clog("updated state");
            let numFollow = previousState.numFollow + (following ? -1 : 1);
            return {
              ...previousState,
              numFollow: numFollow, // TODO(alpha): this is probably a bug
            };
          });
        } else {
          // TODO(alpha): Change button state in case of error
        }
        logEvent(following ? "OtherProfile_Unfollow" : "OtherProfile_Follow", {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: "otherprofile",
          platform: Platform.OS,
          target: following ? "unfollow" : "follow",
          action: "click",
          status: success ? "success" : "failure",
          [following ? "unfollowed" : "followed"]: handle,
          targetuser: handle,
          appVersion: myContext.appVersion,
        });
      },
    });
  };

  let blockFn = () => {
    if (myContext?.inflightBlocks[state.Id]) {
      console.log("WARNING!!! BLOCK IS INFLIGHT", state.Id);
      return;
    }
    myContext.inflightBlocks[state.Id] = true;
    let currentBlocking = blocking;
    setBlocking((prev) => {
      return !prev;
    });
    toggleBlock({
      blocking: currentBlocking,
      objectType: "User",
      target: state,
      refererActionId: null,
      hostUserId: state.Id,
      myContext: myContext,
      callback: ({ success, message, error }) => {
        delete myContext.inflightBlocks[state.Id];
      },
    });
  };

  const HeaderBar = () => {
    if (fromList) {
      return (
        <View
          style={{
            marginHorizontal: 10,
            flexDirection: "row",
            alignItems: "center",
            height: HEADER_HEIGHT,
            zIndex: 3000,
            backgroundColor: colors.background,
          }}
        >
          <Pressable onPress={navigation.goBack}>
            <LeftArrow tintColor={colors.primaryText} />
          </Pressable>
          <AvatarAndUserName />
          {blockFn &&
            (myContext.Id == state.Id
              ? null
              : myContext.actionsByUser?.["User"]?.["Block"]?.[state.Id] != null
              ? true
              : false) != null && <MenuButton />}
        </View>
      );
    } else {
      return (
        <View
          style={{
            flexDirection: "row",
            height: HEADER_HEIGHT,
            zIndex: 3000,
            backgroundColor: colors.background,
            paddingLeft: 16,
          }}
        >
          <AvatarAndUserName />
        </View>
      );
    }
  };

  const AvatarAndUserName = () => {
    const avatarTranslate = scrollY.interpolate({
      inputRange: [0, userInfoHeight],
      outputRange: [0, 25],
      extrapolate: "clamp",
    });

    const avatarOpacity = scrollY.interpolate({
      inputRange: [0, userInfoHeight / 2, userInfoHeight],
      outputRange: [0, 0.5, 1],
      extrapolate: "clamp",
    });
    return (
      <Animated.View
        style={[
          styles.avatarAndUserNameContainer,
          {
            transform: [
              {
                translateY: avatarTranslate,
              },
            ],
            opacity: avatarOpacity,
          },
        ]}
      >
        <Avatar
          item={state}
          style={[styles.avatar, { backgroundColor: colors.background }]}
          myContext={myContext}
        />
        <View style={{ marginLeft: 10 }}>
          <Text style={[styles.userNameText, { color: colors.primaryText }]}>
            {state?.name}
          </Text>
        </View>
      </Animated.View>
    );
  };

  const UserInfo = () => {
    const y = scrollY.interpolate({
      inputRange: [0, userInfoHeight],
      outputRange: [0, -userInfoHeight],
      extrapolateRight: "clamp",
    });

    return (
      <Animated.View
        style={{
          position: "absolute",
          top: insets.top + HEADER_HEIGHT,
          left: 0,
          right: 0,
          zIndex: 1000,
          width: "100%",
          backgroundColor: colors.background,
          paddingLeft: 20,
          paddingRight: 20,
          transform: [{ translateY: y }],
        }}
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout;
          setUserInfoHeight(height);
        }}
      >
        <ProfileCard
          item={state}
          follows={state.followings[myContext.Id] != null}
          followers={myContext.Id != state.Id ? state.mutualFollowers : []}
          facts={
            <View style={styles.factlist}>
              <Pressable
                style={styles.pressableContainer}
                onPress={() => {
                  navigation.push("UserList", {
                    Id: state.Id,
                    name: state.name,
                    handle: state.handle,
                    owner: state,
                    relationship: "Following",
                    refererActionId: null,
                    hostUserId: state.Id,
                    origin:
                      myContext.Id == state.Id
                        ? "MyProfile_Following"
                        : "OtherProfile_Following",
                  });
                  logEvent(
                    myContext?.Id == state.Id
                      ? "MyProfile_Following"
                      : "OtherProfile_Following",
                    {
                      userId: myContext?.Id,
                      username: myContext?.handle,
                      category:
                        myContext?.Id == state.Id
                          ? "myprofile"
                          : "otherprofile",
                      platform: Platform.OS,
                      action: "click",
                      target: "following",
                      targetuser: state.handle,
                      appVersion: myContext.appVersion,
                    }
                  );
                }}
              >
                <View style={styles.multipartFact}>
                  <Text
                    style={[
                      sharedStyles.coloredFact,
                      { color: colors.primaryText },
                    ]}
                  >
                    {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
                      "ShowProblems"
                    ] == null
                      ? Object.keys(state.followings).length
                      : state.numUserFollow}
                  </Text>
                  <Text
                    style={[sharedStyles.fact, { color: colors.primaryText }]}
                  >
                    {"Following"}
                  </Text>
                </View>
              </Pressable>
              <Pressable
                style={styles.pressableContainer}
                onPress={() => {
                  navigation.push("UserList", {
                    Id: state.Id,
                    name: state.name,
                    handle: state.handle,
                    owner: state,
                    relationship: "Followers",
                    refererActionId: null,
                    hostUserId: state.Id,
                    origin:
                      myContext.Id == state.Id
                        ? "MyProfile_Followers"
                        : "OtherProfile_Followers",
                  });
                  logEvent(
                    myContext?.Id == state.Id
                      ? "MyProfile_Followers"
                      : "OtherProfile_Followers",
                    {
                      userId: myContext?.Id,
                      username: myContext?.handle,
                      category:
                        myContext?.Id == state.Id
                          ? "myprofile"
                          : "otherprofile",
                      platform: Platform.OS,
                      action: "click",
                      target: "followers",
                      targetuser: state.handle,
                      appVersion: myContext.appVersion,
                    }
                  );
                }}
              >
                <View style={styles.multipartFact}>
                  <Text
                    style={[
                      sharedStyles.coloredFact,
                      { color: colors.primaryText },
                    ]}
                  >
                    {myContext.actionsByUser?.["Experiment"]?.["Join"]?.[
                      "ShowProblems"
                    ] == null
                      ? Object.keys(state.followers).length
                      : state.numFollow}
                  </Text>
                  <Text
                    style={[sharedStyles.fact, { color: colors.primaryText }]}
                  >
                    {"Followers"}
                  </Text>
                </View>
              </Pressable>
              <View style={{ flex: 1 }}>
                {displayedWebsite != "" && (
                  <Pressable
                    style={styles.pressableContainer}
                    onPress={() => {
                      clog("asked to open website", state.websites);
                      if (Platform.OS == "web") {
                        window.open(state.websites, "_blank");
                      } else {
                        setModalVisible(true);
                      }
                      logEvent(
                        myContext?.Id == state.Id
                          ? "MyProfile_Website"
                          : "OtherProfile_Website",
                        {
                          userId: myContext?.Id,
                          username: myContext?.handle,
                          category:
                            myContext?.Id == state.Id
                              ? "myprofile"
                              : "otherprofile",
                          platform: Platform.OS,
                          action: "click",
                          target: "content",
                          targetuser: state.handle,
                          appVersion: myContext.appVersion,
                        }
                      );
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <View style={sharedStyles.coloredFact}>
                        <Image
                          source={link}
                          style={{
                            height: 16,
                            width: 16,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: 15,
                            fontWeight: "700",
                            alignItems: "center",
                            justifyContent: "center",
                            color: colors.link,
                          }}
                          numberOfLines={1}
                          ellipsizeMode="tail"
                        >
                          {displayedWebsite}
                        </Text>
                      </View>
                    </View>
                  </Pressable>
                )}
              </View>
            </View>
          }
          myContext={myContext}
          showStatusMessage={showStatusMessage}
          colors={colors}
        />
        <View style={{ borderRadius: 8, overflow: "hidden" }}>
          {myContext.Id == state.Id &&
            timeStamp() > lastCTADismissal + 86400 && (
              <CTA
                context={myContext}
                timestamp={timeStamp()}
                updateDismissal={setLastCTADismissal}
                navigation={navigation}
                colors={colors}
              />
            )}
        </View>
      </Animated.View>
    );
  };

  const CustomAlert = () => {
    return (
      <View style={styles.alertContainer}>
        <Pressable
          onPress={() => {
            setStatusVisible(false);
          }}
        >
          <View
            style={[
              styles.alert,
              {
                backgroundColor: colors.primaryButtonBackground,
                borderColor: colors.secondaryButtonBorder,
              },
            ]}
          >
            <Text>{statusMessage}</Text>
          </View>
        </Pressable>
      </View>
    );
  };

  const ModalWebView = () => {
    return (
      <Modal
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <SafeAreaView style={{ flex: 1, backgroundColor: colors.background }}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
            }}
          >
            <View
              style={{
                backgroundColor: colors.background,
                height: 39,
                paddingVertical: 10,
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pressable
                onPress={() => {
                  clog("will try to open", state.websites);
                  setModalVisible(false);
                }}
              >
                <Image
                  source={cross}
                  style={{
                    marginRight: 20,
                    height: 19,
                    width: 17,
                    tintColor: colors.primaryText,
                  }}
                />
              </Pressable>
            </View>
            <WebView
              style={{ flex: 1 }}
              source={{ uri: state.websites }}
              ref={(r) => (webref = r)}
              originWhitelist={["*"]}
              javaScriptEnabled={true}
              domStorageEnabled={true}
            />
          </View>
        </SafeAreaView>
      </Modal>
    );
  };

  const onRefresh = () => {
    if (!refreshing) {
      //clog("refreshing data now");
      setRefreshing(true);
      getData(handle, Id).catch((err) => {
        console.log("error finding user: ", err);
      });
    } else {
      clog("refresh data in progress already");
    }
  };

  const renderCollectionsItem = ({ item, index }) => {
    return (
      <Pressable
        onPress={() => {
          //clog("pressed for content list", item.Id);
          navigation.push("ContentList", {
            Id: item.Id,
            name: item.name,
            description: item.description,
            owner: state,
            screenshotUrl: item.screenshotUrl,
          });
          logEvent(
            myContext?.Id == state.Id
              ? "MyProfile_Collection_Detail"
              : "OtherProfile_Collection_Detail",
            {
              userId: myContext?.Id,
              username: myContext?.handle,
              category:
                myContext?.Id == state.Id ? "myprofile" : "otherprofile",
              platform: Platform.OS,
              action: "click",
              target: "detail",
              targetuser: state.handle,
              appVersion: myContext.appVersion,
            }
          );
        }}
      >
        <List item={item} myContext={myContext} colors={colors} />
      </Pressable>
    );
  };

  const renderFollowButton = () => {
    const buttonTranslateY = scrollY.interpolate({
      inputRange: [0, userInfoHeight],
      outputRange: [0, -85],
      extrapolate: "clamp",
    });

    const buttonTranslateX = scrollY.interpolate({
      inputRange: [0, userInfoHeight],
      outputRange: [0, -25],
      extrapolate: "clamp",
    });

    const buttonScale = scrollY.interpolate({
      inputRange: [0, userInfoHeight],
      outputRange: [1, 0.75],
      extrapolate: "clamp",
    });

    let follows = state.followings[myContext.Id] != null;

    return (
      <Animated.View
        style={[
          styles.followButton,
          {
            top: 65 + insets.top,
            transform: [
              { scale: buttonScale },
              { translateY: buttonTranslateY },
              { translateX: buttonTranslateX },
            ],
          },
          following
            ? {
                color: colors.secondaryButtonText,
                borderColor: colors.secondaryButtonBorder,
                borderWidth: 1,
              }
            : {
                backgroundColor: colors.primaryButtonBackground,
                color: colors.primaryButtonText,
              },
        ]}
      >
        <Pressable onPress={followFn}>
          <Text
            style={[
              styles.followText,
              {
                color: following
                  ? colors.secondaryButtonText
                  : colors.primaryButtonText,
              },
            ]}
          >
            {following ? "Following" : follows ? "Follow back" : "Follow"}
          </Text>
        </Pressable>
      </Animated.View>
    );
  };

  const MenuButton = () => {
    return (
      <View style={styles.menuButton}>
        <Pressable onPress={() => menuRef.current?.open()}>
          <Image
            source={threeDots}
            style={{ height: 24, width: 24, tintColor: colors.primaryText }}
          />
        </Pressable>
      </View>
    );
  };

  const renderGearAndEditButton = () => {
    const buttonTranslate = scrollY.interpolate({
      inputRange: [0, userInfoHeight],
      outputRange: [0, -60],
      extrapolate: "clamp",
    });

    return (
      <Animated.View
        style={[
          styles.gearAndEditContainer,
          {
            top: 65 + insets.top,
            transform: [
              {
                translateY: buttonTranslate,
              },
            ],
          },
        ]}
      >
        <Pressable
          onPress={() =>
            navigation.push("Profile", {
              subsequent: true,
            })
          }
        >
          <Image
            source={edit}
            style={{
              marginRight: 26,
              height: 22,
              width: 20,
              tintColor: colors.primaryText,
            }}
          />
        </Pressable>

        <Pressable
          onPress={() => {
            navigation.push("Settings", {
              Id: myContext.Id,
            });
            logEvent("OwnProfile_Settings", {
              userId: myContext?.Id,
              username: myContext?.handle,
              category: "myprofile",
              platform: Platform.OS,
              action: "click",
              target: "settings",
              appVersion: myContext.appVersion,
            });
          }}
        >
          <Image
            source={settings}
            style={{
              marginRight: 26,
              height: 22,
              width: 20,
              tintColor: colors.primaryText,
            }}
          />
        </Pressable>
      </Animated.View>
    );
  };

  const ListEmptyComponent = () => {
    return (
      <View style={styles.containerListEmpty}>
        <Image
          resizeMode="cover"
          source={cloud1}
          style={{
            height: 85,
            width: 125,
          }}
        />
        <Text style={[styles.textListEmpty, { color: colors.primaryText }]}>
          {"Nothing here yet!"}
        </Text>
      </View>
    );
  };

  clog("CONTEXT", myContext);
  if (!state.Id || !state.handle) {
    return <View></View>;
  } else {
    // NOTE(alpha): Accesses key only
    return (
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          <SafeAreaView
            style={{
              backgroundColor: colors.background,
              zIndex: 2000,
            }}
          />
          <Blocker loading={loading} />
          {statusVisible && <CustomAlert />}
          {refreshing ? <ActivityIndicator /> : null}
          <HeaderBar />
          {followFn &&
            (myContext.Id == state.Id
              ? null
              : myContext.actionsByUser?.["User"]?.["Follow"]?.[state.Id] !=
                null
              ? true
              : false) != null &&
            renderFollowButton()}

          {handle == myContext.handle && renderGearAndEditButton()}

          <UserInfo />

          <Animated.FlatList
            data={state.lists}
            renderItem={({ item, index }) =>
              renderCollectionsItem({ item, index })
            }
            scrollToOverflowEnabled={true}
            scrollEventThrottle={16}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { y: scrollY } } }],
              {
                useNativeDriver: true,
              }
            )}
            ListHeaderComponent={() => {
              return (
                <View style={styles.titleContainer}>
                  <View
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: colors.smallDivider,
                      marginBottom: 16,
                      marginVertical: 8,
                    }}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={[styles.title, { color: colors.primaryText }]}>
                      {"Collections"}
                    </Text>

                    {handle == myContext.handle && (
                      <Pressable
                        style={styles.pressableContainer}
                        onPress={() => {
                          navigation.push("ContentListUpdate", {
                            Id: myContext.Id,
                          });
                          logEvent("MyProfile_CreateCollection", {
                            userId: myContext?.Id,
                            username: myContext?.handle,
                            category: "myprofile",
                            platform: Platform.OS,
                            action: "click",
                            target: "createcollection",
                            appVersion: myContext.appVersion,
                          });
                        }}
                      >
                        <Image
                          source={plus}
                          style={{
                            height: 19,
                            width: 17,
                            marginRight: 25,
                            tintColor: colors.primaryText,
                          }}
                        />
                      </Pressable>
                    )}
                  </View>
                </View>
              );
            }}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            contentContainerStyle={{
              paddingTop: userInfoHeight,
              minHeight: WINDOW_HEIGHT - userInfoHeight,
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index.toString()}
            ListFooterComponent={() =>
              Platform.OS !== "web" ? <View style={{ height: 80 }} /> : null
            }
            ListEmptyComponent={
              handle !== myContext.handle && <ListEmptyComponent />
            }
          />
          <ModalWebView />

          <Portal>
            <Modalize
              ref={menuRef}
              modalStyle={[
                styles.modalContainer,
                { backgroundColor: colors.background },
              ]}
              scrollViewProps={{ showsVerticalScrollIndicator: false }}
              withHandle={false}
              adjustToContentHeight={true}
              HeaderComponent={() => (
                <View style={styles.headerContainer}>
                  <CloseButton onPress={() => menuRef.current?.close()} />
                </View>
              )}
            >
              <View style={{ flex: 1 }}>
                <Pressable
                  onPress={() => {
                    menuRef.current?.close();
                    if (Platform.OS == "web") {
                      setShowAlert(true);
                    } else {
                      Alert.alert(
                        blocking
                          ? `Unblock ${state.name}`
                          : `Block ${state.name}`,
                        blocking
                          ? "They will now see your profile and activity on Village"
                          : "They won’t be able to find your profile or see your activity on Village",
                        [
                          {
                            text: "Cancel",
                            onPress: () => clog("Cancel Pressed"),
                            style: "cancel",
                          },
                          {
                            style: "destructive",
                            text: blocking ? "Unblock" : "Block",
                            onPress: () => {
                              blockFn();
                              setShowBlockPopUp(true);
                              popUpRef.current?.onAnimatedPopUp();
                            },
                          },
                        ]
                      );
                    }
                  }}
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                    paddingVertical: 17,
                  }}
                >
                  <View style={{ marginRight: 10 }}>
                    <Image
                      source={blocking ? user : userBlock}
                      style={{ width: 24, height: 24 }}
                    />
                  </View>

                  <Text
                    style={{ fontSize: 15, fontWeight: "700", lineHeight: 22 }}
                  >
                    {blocking ? "Unblock" : "Block"}
                  </Text>
                </Pressable>
                <View style={{ height: insets.bottom ? insets.bottom : 10 }} />
              </View>
            </Modalize>
          </Portal>

          {showBlockPopUp && (
            <PopUp
              ref={popUpRef}
              title={
                blocking ? `Blocked ${state.name}` : `Unblocked ${state.name}`
              }
              onClose={() => setShowBlockPopUp(false)}
              colors={colors}
            />
          )}
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title={blocking ? `Unblock ${state.name}` : `Block ${state.name}`}
            message={
              blocking
                ? "They will now see your profile and activity on Village"
                : "They won’t be able to find your profile or see your activity on Village"
            }
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            showConfirmButton={true}
            cancelText="Cancel"
            confirmText={blocking ? "Unblock" : "Block"}
            cancelButtonColor={colors.cancelButton}
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => {
              setShowAlert(false);
            }}
            onConfirmPressed={() => {
              blockFn();
              setShowAlert(false);
              setShowBlockPopUp(true);
              popUpRef.current?.onAnimatedPopUp();
            }}
          />
        </View>
      </View>
    );
  }
};

export default UserDetailsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  multipartFact: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: 19,
  },
  factlist: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 20,
    width: "100%",
    height: 29,
    justifyContent: "space-between",
  },
  pressableContainer: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
  },
  alertContainer: {
    height: 48,
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    position: "absolute",
    zIndex: 3000, // works on ios
    elevation: 3, // works on android
  },
  alert: {
    zIndex: 3, // works on ios
    elevation: 3, // works on android
    alignSelf: "center",
    width: "100%",
    height: 48,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "center",
  },
  titleContainer: {
    paddingHorizontal: 16,
    flexDirection: "column",
    marginTop: 16,
    marginBottom: 6,
  },
  title: {
    fontSize: 16,
    fontWeight: "700",
  },
  avatarAndUserNameContainer: {
    top: -25,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 5,
  },
  avatar: {
    height: 24,
    width: 24,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 36,
    alignContent: "center",
  },
  userNameText: {
    fontSize: 14,
    fontWeight: "400",
  },
  gearAndEditContainer: {
    right: 0,
    position: "absolute",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 90,
    height: 30,
    borderRadius: 20,
    zIndex: 10000,
  },
  followButton: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 11,
    borderRadius: 100,
    zIndex: 10000,
    right: 16,
  },
  followText: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: "bold",
  },
  menuButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  containerListEmpty: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textListEmpty: {
    fontSize: 15,
    marginTop: 16,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
  },
  modalContainer: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
  },
});
