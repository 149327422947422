import { villageColors } from "../utils/SharedStyles";

/*
  White: "rgb(255, 255, 255)",
  Accent: "rgb(255, 190, 24)",
  Stone: "rgb(243, 241, 242)",
  Canary: "rgb(255, 198, 53)",
  Raven: "rgb(22, 22, 22)",
  Raven75: "rgba(22, 22, 22, 0.75)",
  Raven50: "rgba(22, 22, 22, 0.5)",
  Raven15: "rgba(22, 22, 22, 0.15)",
  RavenMedium: "rgb(32,33,35)",
  RavenLight: "rgb(49,49,51)",
  White75: "rgba(255, 255, 255, 0.75)",
  White50: "rgba(255, 255, 255, 0.5)",
  White15: "rgba(255, 255, 255, 0.15)",
  Link: "rgb(66, 142, 255)",
  Error: "rgb(223, 74, 42)",
  Flicker: "rgb(255, 122, 92)",
  Transparent: "rgba(0,0,0,0)", 
  */

// Light theme colors
export const lightColors = {
  background: villageColors.White,
  header: villageColors.White,
  headerBlur: villageColors.White75,
  bottomNavBar: villageColors.White90,

  primaryText: villageColors.Raven,
  secondaryText: villageColors.Raven50,
  placeholderText: villageColors.Raven50,
  quoteText: villageColors.Raven50,

  primaryButtonBackground: villageColors.Raven,
  primaryButtonText: villageColors.White,
  disabledPrimaryButtonBackground: villageColors.Raven50,
  disabledPrimaryButtonText: villageColors.White50,
  secondaryButtonBorder: villageColors.Raven,
  secondaryButtonText: villageColors.Raven,

  textFieldBorder: villageColors.Raven50,

  secondaryBackground: villageColors.Stone,
  labelBackground: villageColors.Stone,
  filterBackground: villageColors.Stone,
  selectionBackground: villageColors.Stone,

  largeDivider: villageColors.Stone,
  smallDivider: villageColors.Raven15,

  conversationScreenBackground: villageColors.Stone,
  contentScreenBackground: villageColors.Stone,
  conversationBackground: villageColors.White,
  cardBackground: villageColors.Transparent, // ??
  contentCardBackground: villageColors.White, // ??
  messageBackground: villageColors.Stone,
  selectedBackground: villageColors.Stone,
  compactCardBackground: villageColors.White,
  commentBackground: villageColors.Stone,

  splashBackground: villageColors.Accent,

  newPostPlusButton: villageColors.Accent,
  activeTabUnderline: villageColors.Raven,
  notificationDot: villageColors.Accent,
  collectionIcon: villageColors.Accent,

  link: villageColors.Link,
  error: villageColors.Error,
  accent: villageColors.Accent,

  highlight: villageColors.Accent,
  textCardBackgrounds: [villageColors.lightOne, villageColors.lightTwo],

  cancelButton: villageColors.CancelButton,
  confirmButton: villageColors.ConfirmButton,

  sourceCreditButton: villageColors.Raven25,
  sourceCreditTitle: villageColors.Raven,

  notificationBackground: villageColors.White,
  notificationText: villageColors.Raven,

  footerButtons: villageColors.Raven50,
  topicPopUpBackground: villageColors.Raven90,
};

// Dark theme colors
export const darkColors = {
  background: villageColors.Raven,
  headerBlur: villageColors.Raven75,
  header: villageColors.Raven,
  bottomNavBar: villageColors.Raven90,

  newPostPlusButton: villageColors.Accent,
  activeTabUnderline: villageColors.White,
  notificationDot: villageColors.Accent,
  collectionIcon: villageColors.Accent,
  commentBackground: villageColors.RavenLight,
  secondaryBackground: villageColors.RavenLight,
  labelBackground: villageColors.Transparent, // ???
  filterBackground: villageColors.RavenLight, // ????
  largeDivider: villageColors.Raven,
  smallDivider: villageColors.White15,
  conversationScreenBackground: villageColors.Raven,
  contentScreenBackground: villageColors.Raven,
  conversationBackground: villageColors.RavenMedium,
  primaryText: villageColors.White,
  primaryButtonBackground: villageColors.White,
  primaryButtonText: villageColors.Raven,
  disabledPrimaryButtonBackground: villageColors.White50,
  disabledPrimaryButtonText: villageColors.Raven50,
  secondaryButtonBorder: villageColors.White,
  secondaryButtonText: villageColors.White,
  secondaryText: villageColors.White50,
  placeholderText: villageColors.White50,
  quoteText: villageColors.White50,
  textFieldBorder: villageColors.White50,
  cardBackground: villageColors.RavenMedium,
  contentCardBackground: villageColors.RavenMedium,
  messageBackground: villageColors.RavenMedium,
  selectedBackground: villageColors.RavenMedium,
  compactCardBackground: villageColors.RavenMedium,
  splashBackground: villageColors.Accent,
  link: villageColors.Link,
  error: villageColors.Error,
  accent: villageColors.Accent,
  selectionBackground: villageColors.Charcol,

  highlight: villageColors.Yellow,
  highlightedText: villageColors.Raven,
  textCardBackgrounds: [
    villageColors.darkOne,
    villageColors.darkTwo,
    villageColors.darkThree,
    villageColors.darkFour,
    villageColors.darkFive,
  ],
  cancelButton: villageColors.CancelButton,
  confirmButton: villageColors.ConfirmButton,

  sourceCreditButton: villageColors.White25,
  sourceCreditTitle: villageColors.White,

  notificationBackground: villageColors.Raven,
  notificationText: villageColors.White,

  footerButtons: villageColors.White50,
  topicPopUpBackground: villageColors.Raven90,
};
