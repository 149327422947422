import { API, graphqlOperation } from "aws-amplify";

import {
  addComment,
  getUserCounts,
  updateUserCounts,
  updateListCounts,
  getPinCounts,
  updatePinCounts,
  getCommentCounts,
  updateCommentCounts,
  getUrlCounts,
  updateUrlCounts,
  updateTopicCounts,
} from "../src/graphql/custom";
import { generateUniqueId } from "../utils/Id";
import {
  addActionByUser,
  removeActionByUser,
  performAction,
} from "../utils/Action";
import { addIds } from "../utils/IdList";
import { clog, elog } from "../utils/Log";

let opmap = {
  updateTopicCounts: updateTopicCounts,
  getUserCounts: getUserCounts,
  updateUserCounts: updateUserCounts,
  updateListCounts: updateListCounts,
  getPinCounts: getPinCounts,
  updatePinCounts: updatePinCounts,
  getCommentCounts: getCommentCounts,
  updateCommentCounts: updateCommentCounts,
  getUrlCounts: getUrlCounts,
  updateUrlCounts: updateUrlCounts,
};

let keymap = {
  User: "userId",
  List: "listId",
  Topic: "topicId",
  Pin: "pinId",
  Url: "urlId",
  Comment: "commentId",
  Source: "sourceId",
};

export async function addCommentForObject({
  objectId,
  objectType,
  commenterId,
  comment,
  curatorId,
  urlId,
  numCommentsOnObject,
  numCommentsCreated,
  numCommentRecursive,
  myContext,
  existingCommenterIds,
  callback,
  refererActionId,
  hostUserId,
}) {
  clog(
    "got asked to comment",
    comment,
    "on",
    objectId,
    "of type",
    objectType,
    "by",
    commenterId
  );
  let numCommentsOnUrl = 0;
  let newObject = null;
  let keyId = keymap[objectType];
  let Id = generateUniqueId();
  let details = {
    Id: Id,
    creatorId: commenterId,
    curatorId: commenterId,
    content: comment,
    objectId: objectId,
    objectType: objectType,
  };
  details[keyId] = objectId;
  if (objectType == "Url") {
    details["topLevel"] = true;
  }
  clog("creating comment with details", details);
  let payloads = [];
  try {
    let promises = [];
    promises.push(API.graphql(graphqlOperation(addComment, details)));
    payloads.push({ addComment: details });

    // Get current counter values so that we can update them appropriately
    promises.push(
      API.graphql(
        graphqlOperation(getUserCounts, {
          Id: commenterId,
        })
      )
    );
    payloads.push({
      getUserCounts: {
        Id: commenterId,
      },
    });

    let opname;

    if (objectType != "Url") {
      opname = "get" + "Url" + "Counts";
      promises.push(
        API.graphql(graphqlOperation(opmap[opname], { Id: urlId }))
      );
      payloads.push({
        [opmap[opname]]: { Id: urlId },
      });
    }

    if (commenterId != curatorId) {
      promises.push(
        API.graphql(
          graphqlOperation(getUserCounts, {
            Id: curatorId,
          })
        )
      );
      payloads.push({
        "curator:getUserCounts": {
          Id: curatorId,
        },
      });
    }

    let actorEngagedTopicIds = "";
    let urlTopicIds = "";
    clog("ALL PAYLOADS", payloads);
    // read all the counts and then update them
    let responses = await Promise.all(promises);
    responses.forEach((response) => {
      clog("RESPONSE FROM GET DATA", response);
      if (response?.data?.createComment) {
        newObject = response.data.createComment;
      } else if (response?.data?.getUser?.Id == commenterId) {
        numCommentsCreated = response?.data.getUser.numCommentCreate;
        actorEngagedTopicIds = response?.data.getUser.engagedTopicIds;
      } else if (
        response?.data?.getUser?.Id == curatorId &&
        commenterId != curatorId
      ) {
        numCommentRecursive = response.data.getUser.numCommentRecursive;
      } else if (response?.data?.getUrl) {
        numCommentsOnUrl = response.data.getUrl.numComment;
        existingCommenterIds = response.data.getUrl.commenterIds;
        urlTopicIds = response.data.getUrl.topicIds;
      }
    });

    opname = "get" + objectType + "Counts";

    const Id = objectType === "Comment" ? newObject.Id : objectId;
    const response = await API.graphql(
      graphqlOperation(opmap[opname], { Id: Id })
    );
    payloads.push({
      [opmap[opname]]: { Id: Id },
    });

    numCommentsOnObject = response.data?.["get" + objectType]?.numComment;

    promises = [];
    payloads = [];
    let updatedActorEngagedTopicIds = addIds(
      actorEngagedTopicIds,
      urlTopicIds.split(",")
    );
    promises.push(
      API.graphql(
        graphqlOperation(updateUserCounts, {
          Id: commenterId,
          numCommentCreate: numCommentsCreated + 1,
          engagedTopicIds: updatedActorEngagedTopicIds,
        })
      )
    );
    payloads.push({
      updateUserCounts: {
        Id: commenterId,
        numCommentCreate: numCommentsCreated + 1,
      },
    });

    opname = "update" + objectType + "Counts";
    let objectDetails = { Id: Id, numComment: numCommentsOnObject + 1 };
    if (objectType == "Url") {
      objectDetails["commenterIds"] = addIds(existingCommenterIds, [
        commenterId,
      ]);
    }
    clog("OBJECT DETAILS", objectDetails);
    promises.push(API.graphql(graphqlOperation(opmap[opname], objectDetails)));

    payloads.push({ [opmap[opname]]: objectDetails });

    if (objectType != "Url") {
      opname = "update" + "Url" + "Counts";
      let urlDetails = { Id: urlId, numComment: numCommentsOnUrl + 1 };
      urlDetails["commenterIds"] = addIds(existingCommenterIds, [commenterId]);
      clog("URL DETAILS", urlDetails);
      promises.push(API.graphql(graphqlOperation(opmap[opname], urlDetails)));
      payloads.push({ [opmap[opname]]: urlDetails });
    }

    if (commenterId != curatorId) {
      promises.push(
        API.graphql(
          graphqlOperation(updateUserCounts, {
            Id: curatorId,
            numCommentRecursive: numCommentRecursive + 1,
          })
        )
      );
      payloads.push({
        "curator:updateUserCounts": {
          Id: curatorId,
          numCommentRecursive: numCommentRecursive + 1,
        },
      });
    }

    let localCallback = (
      success,
      newRelationshipId,
      createdAt,
      responses,
      creationTS
    ) => {
      if (success) {
        let newAction = null;
        responses.forEach((response) => {
          clog("UPATE RESPONSE:", response);
          let createAction = response.data.createAction;
          if (createAction) {
            newAction = createAction;
          }
        });
        if (newObject) {
          newObject["actions"] = { items: [newAction] };
        }
        myContext["engagedTopicIds"] = updatedActorEngagedTopicIds;
        //clog("responses", responses);
        if (callback) {
          callback(
            success,
            newRelationshipId,
            newObject,
            createdAt,
            objectType,
            objectId,
            creationTS
          );
        }
      } else {
        if (callback) {
          callback(
            success,
            newRelationshipId,
            newObject,
            createdAt,
            objectType,
            objectId,
            creationTS
          );
        }
      }
    };

    performAction({
      objectType: "Comment",
      operation: "Create",
      objectId: Id,
      actorId: myContext.Id,
      relationshipId: null,
      objectCounter: 0,
      userCounter: numCommentsCreated,
      curatorId: curatorId,
      curatorCounter: numCommentRecursive,
      promises: promises,
      payloads: payloads,
      callback: localCallback,
      actionOnly: true,
      refererActionId: refererActionId,
      hostUserId: hostUserId,
      seq: null,
    });
  } catch (err) {
    console.log("ERROR", err);
    clog("error commenting on object...", err);
    elog(
      commenterId,
      "create comment",
      "creating comment",
      err.message,
      payloads
    );
  }
}

export const computeCommenterMessage = (pins, comments, myContext) => {
  let commenters = {};
  clog("PINS", pins);
  clog("COMMENTS", comments);
  pins?.forEach((p) => {
    let commentList = p?.comments?.items ? p?.comments?.items : p?.comments;
    commentList?.forEach((c) => {
      commenters[c.curatorId]++;
    });
  });

  comments?.forEach((c) => {
    commenters[c.curatorId]++;
    let commentList = c?.comments?.items ? c?.comments?.items : c?.comments;
    commentList?.forEach((c2) => {
      commenters[c2.curatorId]++;
    });
  });
  let knownCommenterIds = {};
  Object.keys(commenters).forEach((cId) => {
    // NOTE(alpha): Accesses key only
    if (myContext.actionsByUser?.["User"]?.["Follow"]?.[cId]) {
      knownCommenterIds[cId] = true;
    }
  });
  let selectedCommenters = [];
  let selectedCount = 0;
  Object.keys(knownCommenterIds)
    .sort((a, b) =>
      myContext.users[a].name < myContext.users[b].name ? -1 : 1
    )
    .forEach((cId) => {
      clog("Consider id", cId, "with details", myContext.users[cId]);
      if (selectedCount < 2 && myContext.users[cId]?.name) {
        selectedCommenters.push(myContext.users[cId]?.name);
        selectedCount++;
      }
    });

  let commenterCount = Object.keys(commenters).length;
  let selectedCommentersCount = Object.keys(selectedCommenters).length;
  let commenterMessage = "Be the first to comment";
  if (commenterCount > 0) {
    if (commenterCount > selectedCommentersCount) {
      let knownCommenterList = selectedCommenters.join(", ");
      let uncoveredCommenters = commenterCount - selectedCommentersCount;
      if (selectedCommentersCount > 0) {
        commenterMessage =
          knownCommenterList +
          " and " +
          String(uncoveredCommenters) +
          (uncoveredCommenters > 1 ? " villagers" : " villager");
      } else {
        commenterMessage =
          String(uncoveredCommenters) +
          (uncoveredCommenters > 1 ? " villagers" : " villager");
      }
    } else {
      let knownCommenterList = selectedCommenters.join(" and ");
      commenterMessage = knownCommenterList;
    }
  }
  return commenterMessage;
};
