import { API, graphqlOperation } from "aws-amplify";

import {
  addAction,
  removeAction,
  updateCommentCounts,
  updateListCounts,
  updatePinCounts,
  updateTopicCounts,
  updateUrlCounts,
  updateUserCounts,
  updateSourceCounts,
  updateExperimentCounts,
  updateGroupCounts,
  getCommentCounts,
  getListCounts,
  getPinCounts,
  getTopicCounts,
  getUrlCounts,
  getUserCounts,
  getSourceCounts,
  getExperimentCounts,
  getGroupCounts,
} from "../src/graphql/custom";
import { generateUniqueId } from "../utils/Id";
import { timeStamp } from "../utils/TimeStamp";
import { clog, elog } from "../utils/Log";
import { addIds, removeIds, generateMapFromIdString } from "./IdList";

let opmap = {
  updateTopicCounts: updateTopicCounts,
  updateUserCounts: updateUserCounts,
  updateListCounts: updateListCounts,
  updatePinCounts: updatePinCounts,
  updateCommentCounts: updateCommentCounts,
  updateUrlCounts: updateUrlCounts,
  updateSourceCounts: updateSourceCounts,
  updateExperimentCounts: updateExperimentCounts,
  updateGroupCounts: updateGroupCounts,
  getTopicCounts: getTopicCounts,
  getUserCounts: getUserCounts,
  getListCounts: getListCounts,
  getPinCounts: getPinCounts,
  getCommentCounts: getCommentCounts,
  getUrlCounts: getUrlCounts,
  getSourceCounts: getSourceCounts,
  getExperimentCounts: getExperimentCounts,
  getGroupCounts: getGroupCounts,
};

let keymap = {
  User: "userId",
  List: "listId",
  Topic: "topicId",
  Pin: "pinId",
  Url: "urlId",
  Comment: "commentId",
  Source: "sourceId",
  Experiment: "experimentId",
  Group: "groupId",
};

// TODO(alpha): Need to add createdAt from all call sites
export function addActionByUser(
  actionsByUser,
  objectType,
  operation,
  objectId,
  Id,
  object,
  refererActionId,
  hostUserId,
  createdAt
) {
  if (actionsByUser[objectType] == null) {
    actionsByUser[objectType] = {};
  }
  if (actionsByUser[objectType][operation] == null) {
    actionsByUser[objectType][operation] = {};
  }
  actionsByUser[objectType][operation][objectId] = {};
  actionsByUser[objectType][operation][objectId][Id] = {
    object: object,
    createdAt: createdAt,
  };
}

export function removeActionByUser(
  actionsByUser,
  objectType,
  operation,
  objectId
) {
  if (actionsByUser[objectType]?.[operation]?.[objectId]) {
    delete actionsByUser[objectType][operation][objectId];
  }
}

export async function performAction({
  objectType,
  operation,
  objectId,
  actorId,
  relationshipId,
  objectCounter,
  userCounter,
  curatorId,
  curatorCounter,
  promises,
  payloads,
  callback,
  actionOnly,
  refererActionId,
  hostUserId,
  seq,
  url,
  note,
}) {
  clog(
    "will perform action",
    operation,
    objectType,
    objectId,
    actorId,
    relationshipId,
    objectCounter,
    userCounter,
    curatorId,
    curatorCounter,
    promises,
    refererActionId,
    hostUserId
  );
  clog("arguments", arguments);
  if (
    promises &&
    promises.length > 0 &&
    (!payloads || payloads.length != promises.length)
  ) {
    clog("Did not set payload properly");
    elog(actorId, "create action", "creating action", payloads);
  }
  let success = false;
  let newRelationshipId = null;
  let createdAt = null;
  let creationTS = null;
  let responses = null;
  try {
    let actionDetails = {
      objectType: objectType,
      operation: operation,
      objectId: objectId,
      actorId: actorId,
    };
    let keyId = keymap[objectType];
    let Id = generateUniqueId();
    actionDetails["Id"] = Id;
    actionDetails[keyId] = objectId;
    if (refererActionId) {
      actionDetails["refererActionId"] = refererActionId;
    }
    if (hostUserId) {
      actionDetails["hostUserId"] = hostUserId;
    }
    if (operation == "View") {
      actionDetails["queryActorId"] = actorId;
    } else {
      actionDetails["mutationActorId"] = actorId;
    }
    actionDetails["actorIdOperationObjectType"] = [
      actionDetails.actorId,
      actionDetails.operation,
      actionDetails.objectType,
    ].join(":");
    actionDetails["actorIdOperationObjectId"] = [
      actionDetails.actorId,
      actionDetails.operation,
      actionDetails.objectId,
    ].join(":");
    actionDetails["objectIdOperation"] = [
      actionDetails.objectId,
      actionDetails.operation,
    ].join(":");
    actionDetails["creationTS"] = timeStamp();
    if (note) {
      actionDetails["note"] = note;
    }
    clog("will create action with Id", Id, "details", actionDetails);
    payloads.push({ "action:details": actionDetails });
    promises.push(API.graphql(graphqlOperation(addAction, actionDetails)));

    if (!actionOnly) {
      await generateCounterPromises(
        objectType,
        operation,
        objectId,
        actorId,
        objectCounter,
        userCounter,
        curatorId,
        curatorCounter,
        promises,
        payloads,
        true,
        seq,
        url
      );
    }
    payloads.push({ "will execute": { ready: true } });
    responses = await Promise.all(promises);
    responses.forEach((response) => {
      let createOutput = response.data.createAction;
      if (createOutput) {
        newRelationshipId = createOutput.Id; // This is the actionId
        createdAt = createOutput.createdAt;
        creationTS = createOutput.creationTS;
        clog("new Id is", newRelationshipId, "and creation TS", creationTS);
      }
    });
    clog("responses", responses);
    success = true;
  } catch (err) {
    console.log("error creating action...", err);
    elog(actorId, "create action", "creating action", err.message, payloads);
  }
  if (callback) {
    callback(success, newRelationshipId, createdAt, responses, creationTS);
  }
}

export async function undoAction({
  objectType,
  operation,
  objectId,
  actorId,
  relationshipId,
  objectCounter,
  userCounter,
  curatorId,
  curatorCounter,
  promises,
  payloads,
  callback,
  actionOnly,
  refererActionId,
  hostUserId,
  seq,
  url,
}) {
  clog("will remove action", operation, objectType, objectId, relationshipId);
  clog("arguments", arguments);

  let success = false;
  let responses = null;
  try {
    promises.push(
      API.graphql(
        graphqlOperation(removeAction, {
          Id: relationshipId,
        })
      )
    );
    payloads.push({ "undoaction:details": { Id: relationshipId } });
    if (!actionOnly) {
      await generateCounterPromises(
        objectType,
        operation,
        objectId,
        actorId,
        objectCounter,
        userCounter,
        curatorId,
        curatorCounter,
        promises,
        payloads,
        false,
        seq,
        url
      );
    }
    payloads.push({ "will execute": { ready: true } });
    responses = await Promise.all(promises);
    clog("responses", responses);
    success = true;
  } catch (err) {
    console.log("error removing action ...", err);
    elog(actorId, "remove action", "remove action", err.message, payloads);
  }
  if (callback) {
    callback(success, null, responses);
  }
}

async function generateCounterPromises(
  objectType,
  operation,
  objectId,
  actorId,
  objectCounter,
  userCounter,
  curatorId,
  curatorCounter,
  promises,
  payloads,
  increment,
  seq,
  url
) {
  clog("INCREMENT", increment, userCounter, curatorCounter, objectCounter);

  let localPromises = [];
  // Get current counter values so that we can update them appropriately
  localPromises.push(
    API.graphql(
      graphqlOperation(getUserCounts, {
        Id: actorId,
      })
    )
  );

  if (curatorId && actorId != curatorId) {
    localPromises.push(
      API.graphql(
        graphqlOperation(getUserCounts, {
          Id: curatorId,
        })
      )
    );
  }

  if (operation != "Create") {
    if (curatorId != objectId) {
      let opname = "get" + objectType + "Counts";
      localPromises.push(
        API.graphql(graphqlOperation(opmap[opname], { Id: objectId }))
      );
    }
  }

  try {
    // read all the counts and then update them
    payloads.push({ "will execute": { ready: true } });
    let responses = await Promise.all(localPromises);
    let actorData = null;
    let curatorData = null;
    let objectData = null;
    let objectPath = "get" + objectType;
    responses.forEach((response) => {
      clog("RESPONSE FROM GET DATA", response);
      if (response?.data?.getUser?.Id == actorId) {
        actorData = response.data.getUser;
      }
      if (response?.data?.getUser?.Id == curatorId) {
        curatorData = response.data.getUser;
      }
      if (response?.data?.getUser?.Id == objectId) {
        objectData = response.data.getUser;
      }
      if (response?.data?.[objectPath]) {
        objectData = response.data[objectPath];
      }
    });

    clog(
      "ActorData",
      actorData,
      "CuratorData",
      curatorData,
      "objectData",
      objectData
    );

    // User Details
    let fieldname = "num" + objectType + operation;
    let userDetails = { Id: actorId };
    userDetails[fieldname] = actorData[fieldname] + (increment ? 1 : -1);
    // If this is Topic Follow/Unfollow, need to update declaredTopicIds
    if (objectType == "Topic" && operation == "Follow") {
      userDetails["declaredTopicIds"] = increment
        ? addIds(actorData.declaredTopicIds, [objectId])
        : removeIds(actorData.declaredTopicIds, [objectId]);
    }

    if (url && url?.topicIds && operation == "View") {
      userDetails["consumedTopicIds"] = increment
        ? addIds(
            actorData.consumedTopicIds,
            Object.keys(generateMapFromIdString(url?.topicIds))
          )
        : removeIds(
            actorData.consumedTopicIds,
            Object.keys(generateMapFromIdString(url?.topicIds))
          );
    }
    if (
      url &&
      url?.topicIds &&
      operation == "Like" &&
      (objectType == "Pin" || objectType == "Comment")
    ) {
      userDetails["engagedTopicIds"] = increment
        ? addIds(
            actorData.engagedTopicIds,
            Object.keys(generateMapFromIdString(url?.topicIds))
          )
        : removeIds(
            actorData.engagedTopicIds,
            Object.keys(generateMapFromIdString(url?.topicIds))
          );
    }
    if (operation == "Follow" && objectType == "Topic") {
      userDetails["lastTopicUpdateTime"] = timeStamp();
    }
    clog("USER UPDATE PAYLOAD", userDetails, "Added", url?.topicIds);
    // Curator Details
    let recursiveFieldname = "num" + operation + "Recursive";
    let curatorDetails = {};
    if (curatorId && actorId != curatorId) {
      curatorDetails["Id"] = curatorId;
      curatorDetails[recursiveFieldname] =
        curatorData[recursiveFieldname] + (increment ? 1 : -1);
    } else {
      userDetails[recursiveFieldname] =
        actorData[recursiveFieldname] + (increment ? 1 : -1);
    }

    // Object Details
    let objectDetails = {};
    // do not need to update counter if creating a new item
    if (operation != "Create") {
      let objectFieldname = "num" + operation;
      if (curatorId && curatorId == objectId) {
        curatorDetails[objectFieldname] =
          objectData[objectFieldname] + (increment ? 1 : -1);
      } else {
        objectDetails["Id"] = objectId;
        objectDetails[objectFieldname] =
          objectData[objectFieldname] + (increment ? 1 : -1);
      }
    }

    clog("will perform user update with", userDetails);
    if (objectType == "List" && operation == "Create" && increment) {
      userDetails["nextListSeq"] = seq + 1;
    }
    promises.push(API.graphql(graphqlOperation(updateUserCounts, userDetails)));
    payloads.push({ "actor:updateUserCounts": userDetails });

    if (curatorDetails.Id) {
      clog("will perform curator update with", curatorDetails);
      promises.push(
        API.graphql(graphqlOperation(updateUserCounts, curatorDetails))
      );
      payloads.push({ "Curator:updateUserCounts": curatorDetails });
    }

    if (objectDetails.Id) {
      let opname = "update" + objectType + "Counts";
      clog("will perform", opname, "with", objectDetails);
      promises.push(
        API.graphql(graphqlOperation(opmap[opname], objectDetails))
      );
      payloads.push({ [opmap[opname]]: objectDetails });
    }
  } catch (err) {
    console.log("error", err);
    elog(
      actorId,
      "create action",
      "generateCounterPromises",
      err.message,
      payloads
    );
  }
}
