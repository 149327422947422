import React, { useEffect, useState, useRef } from "react";
import {
  Image,
  View,
  Platform,
  NativeModules,
  Text,
  Pressable,
  Linking,
  Modal,
} from "react-native";
import * as Notifications from "expo-notifications";
import Amplify, { Auth, Hub } from "aws-amplify";
import config from "./src/aws-exports";

import ProfileUpdateScreen from "./screens/ProfileUpdateScreen";
import TopicChooserScreen from "./screens/TopicChooserScreen";
import UserDetailsScreen from "./screens/UserDetailsScreen";
import GuestUserDetailsScreen from "./screens/GuestUserDetailsScreen";
import UserListScreen from "./screens/UserListScreen";
import CommonFeedScreen from "./screens/CommonFeedScreen";
import ItemDetailsScreen from "./screens/ItemDetailsScreen";
import GuestItemDetailsScreen from "./screens/GuestItemDetailsScreen";
import CommentScreen from "./screens/CommentScreen";
import SearchScreen from "./screens/SearchScreen";
import ContentListScreen from "./screens/ContentListScreen";
import GuestContentListScreen from "./screens/GuestContentListScreen";
import ContentListUpdateScreen from "./screens/ContentListUpdateScreen";
import CreatePinScreen from "./screens/CreatePinScreen";
import SettingsScreen from "./screens/SettingsScreen";
import ExperimentListScreen from "./screens/ExperimentListScreen";
import ExperimentUpdateScreen from "./screens/ExperimentUpdateScreen";
import GroupListScreen from "./screens/GroupListScreen";
import GroupUpdateScreen from "./screens/GroupUpdateScreen";
import GroupScreen from "./screens/GroupScreen";
import SignInScreen from "./screens/SignInScreen";
import SignUpScreen from "./screens/SignUpScreen";
import ConfirmSignUpScreen from "./screens/ConfirmSignUpScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import NotificationScreen from "./screens/NotificationScreen";
import UrlMetadataUpdateScreen from "./screens/UrlMetadataUpdateScreen";
import AppearanceScreen from "./screens/AppearanceScreen";
import WelcomeScreen from "./screens/WelcomeScreen";
import PermissionScreen from "./screens/PermissionScreen";
import OnboardingNotificationScreen from "./screens/OnboardingNotificationScreen";
import DailyBestScreen from "./screens/DailyBestScreen";
import FeedbackListScreen from "./screens/FeedbackListScreen";
import RequestListScreen from "./screens/RequestListScreen";
import ReportListScreen from "./screens/ReportListScreen";
import PlaybackScreen from "./screens/PlaybackScreen";

import * as Sentry from "sentry-expo";

import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";

import AppContext from "./components/AppContext";
import { initialUser } from "./utils/InitialValues";

import myvillage_active from "./assets/myvillage_active.png";
import myvillage_inactive from "./assets/myvillage_inactive.png";
import personSelected from "./assets/person-selected.png";
import plus from "./assets/plus.png";
import updateApp from "./assets/update-app.png";
import { clog } from "./utils/Log";

import ShareScreen from "./screens/ShareScreen";
import { ThemeProvider, useTheme } from "./theme";
import * as TaskManager from "expo-task-manager";

import { titleFontSize, titleFontWeight } from "./utils/SharedStyles";
import { StackActions } from "@react-navigation/routers";

import * as Haptics from "expo-haptics";
import DeviceInfo from "react-native-device-info";
import { refreshConfig } from "./utils/DataFetcher";
import { SafeAreaView } from "react-navigation";
import { Host } from "react-native-portalize";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import AccountInformationScreen from "./screens/AccountInformationScreen";
import DeleteAccountScreen from "./screens/DeleteAccountScreen";
import WebViewScreen from "./screens/WebViewScreen";
import AlertService from "./utils/AlertService";
import DropDownAlert from "./components/DropDownAlert";
import { SafeAreaProvider } from "react-native-safe-area-context";

async function managerAvailable() {
  const available = await TaskManager.isAvailableAsync();
  console.log("TASK MANAGER IS AVAILABLE", available);
  if (available) {
    const tasks = await TaskManager.getRegisteredTasksAsync();
    console.log("REGISTERED TASKS", tasks);
  }
}
managerAvailable();

Amplify.configure({
  ...config,
  Analytics: {
    disabled: true,
  },
});

Sentry.init({
  dsn: "https://f71070e24e4e4ce2b8c4278b9ef853dc@o1083491.ingest.sentry.io/6093207",
  enableInExpoDevelopment: true,
  tracesSampleRate: 0.5,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

let readableVersion = DeviceInfo.getReadableVersion();

console.log("VERSION", readableVersion);

const RootStack = createStackNavigator();
const Tab = createBottomTabNavigator();
const MyVillageStack = createStackNavigator();
const SearchStack = createStackNavigator();
const SettingsStack = createStackNavigator();
const NotificationStack = createStackNavigator();
const AuthenticationStack = createStackNavigator();
const { UIManager } = NativeModules;
const navigationRef = React.createRef();

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

let statePointer = null;

function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

function AuthenticationStackScreen() {
  const { colors } = useTheme();

  const headerStyle = {
    backgroundColor: colors.header,
    shadowColor: colors.header,
    borderBottomWidth: 0,
  };

  const headerTitleStyle = {
    fontSize: titleFontSize,
    fontWeight: titleFontWeight,
    maxWidth: 250,
    textAlign: "center",
    color: colors.primaryText,
  };

  return (
    <AuthenticationStack.Navigator
      initialRouteName="Welcome"
      screenOptions={{
        headerShown: true,
        headerShadowVisible: false,
        headerStyle: headerStyle,
        headerTitleStyle: headerTitleStyle,
        headerTitleAlign: "center",
      }}
    >
      <AuthenticationStack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={
          Platform.OS == "android"
            ? { headerShown: true, headerTitle: "" }
            : {
                headerShown: false,
              }
        }
      />
      <AuthenticationStack.Screen
        name="SignIn"
        component={SignInScreen}
        options={{
          title: "",
        }}
      />
      <AuthenticationStack.Screen
        name="SignUp"
        component={SignUpScreen}
        options={{
          title: "You’re moving in!",
        }}
      />
      <AuthenticationStack.Screen
        name="ConfirmSignUp"
        component={ConfirmSignUpScreen}
        options={{
          title: "Confirm your number",
        }}
      />
      <AuthenticationStack.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
        options={{
          title: "Reset your password",
        }}
      />
      <AuthenticationStack.Screen
        name="Permission"
        component={PermissionScreen}
        options={{
          headerShown: false,
        }}
      />
    </AuthenticationStack.Navigator>
  );
}

function VillageScreen(prop) {
  const { initialRouteName } = prop.route.params;
  const { colors } = useTheme();
  const headerStyle = {
    backgroundColor: colors.header,
    borderBottomWidth: 0,
    shadowColor: colors.header,
  };
  const headerTitleStyle = {
    alignItems: "flex-start",
    textAlign: "left",
    fontWeight: titleFontWeight,
    fontSize: titleFontSize,
    color: colors.primaryText,
  };
  return (
    <MyVillageStack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: headerStyle,
        headerTitleStyle: headerTitleStyle,
        headerTitleAlign: "left",
      }}
    >
      <MyVillageStack.Screen
        name="Village"
        component={CommonFeedScreen}
        options={{ headerShown: false }}
        initialParams={{ taskFromParams: "discover", ...prop.route.params }}
      />
      <MyVillageStack.Screen name="ItemDetails" component={ItemDetailsScreen} />
      <MyVillageStack.Screen name="Comments" component={CommentScreen} />
      <MyVillageStack.Screen
        name="Search"
        component={SearchStackScreen}
        options={{ headerShown: false }}
      />
      <MyVillageStack.Screen
        name="Playback"
        component={PlaybackScreen}
        options={{ headerShown: false }}
      />
      <MyVillageStack.Screen
        name="UserDetails"
        component={UserDetailsScreen}
        options={{
          title: "",
        }}
      />
      <MyVillageStack.Screen name="UserList" component={UserListScreen} />
      <MyVillageStack.Screen name="Settings" component={SettingsScreen} />
      <MyVillageStack.Screen name="Profile" component={ProfileUpdateScreen} />
      <MyVillageStack.Screen
        name="TopicChooser"
        component={TopicChooserScreen}
      />
      <MyVillageStack.Screen name="ContentList" component={ContentListScreen} />
      <MyVillageStack.Screen
        name="ContentListUpdate"
        component={ContentListUpdateScreen}
      />
      <MyVillageStack.Screen name="CreatePin" component={CreatePinScreen} />
      <MyVillageStack.Screen
        name="Notification"
        component={NotificationStackScreen}
        options={{ headerShown: false }}
      />
    </MyVillageStack.Navigator>
  );
}

function SearchStackScreen() {
  const { colors } = useTheme();
  const headerStyle = {
    backgroundColor: colors.header,
    borderBottomWidth: 0,
    shadowColor: colors.header,
  };
  const headerTitleStyle = {
    textAlign: "left",
    fontWeight: titleFontWeight,
    fontSize: titleFontSize,
    maxWidth: 250,
    color: colors.primaryText,
  };
  return (
    <SearchStack.Navigator
      initialRouteName="Search"
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: headerStyle,
        headerTitleStyle: headerTitleStyle,
        headerTitleAlign: "left",
      }}
    >
      <SearchStack.Screen
        name="Search"
        component={SearchScreen}
        initialParams={{ subsequent: true }}
        options={{ headerShown: false }}
      />
      <SearchStack.Screen name="ItemDetails" component={ItemDetailsScreen} />
      <SearchStack.Screen name="Comments" component={CommentScreen} />
      <SearchStack.Screen
        name="UserDetails"
        component={UserDetailsScreen}
        options={{
          title: "",
        }}
      />
      <SearchStack.Screen name="UserList" component={UserListScreen} />
      <SearchStack.Screen name="Settings" component={SettingsScreen} />
      <SearchStack.Screen name="Profile" component={ProfileUpdateScreen} />
      <SearchStack.Screen name="TopicChooser" component={TopicChooserScreen} />
      <SearchStack.Screen name="ContentList" component={ContentListScreen} />
      <SearchStack.Screen
        name="ContentListUpdate"
        component={ContentListUpdateScreen}
      />
      <SearchStack.Screen name="CreatePin" component={CreatePinScreen} />
      <SearchStack.Screen
        name="UrlMetadataUpdate"
        component={UrlMetadataUpdateScreen}
      />
    </SearchStack.Navigator>
  );
}

function SettingsStackScreen() {
  const { colors } = useTheme();
  const headerStyle = {
    backgroundColor: colors.header,
    borderBottomWidth: 0,
    shadowColor: colors.header,
  };
  const headerTitleStyle = {
    alignItems: "center",
    textAlign: "center",
    fontWeight: titleFontWeight,
    fontSize: titleFontSize,
    maxWidth: 250,
    color: colors.primaryText,
  };
  return (
    <SettingsStack.Navigator
      initialRouteName="UserDetails"
      screenOptions={{
        headerShown: true,
        headerShadowVisible: false,
        headerStyle: headerStyle,
        headerTitleStyle: headerTitleStyle,
        headerTitleAlign: "center",
      }}
    >
      <SettingsStack.Screen
        name="UserDetails"
        component={UserDetailsScreen}
        options={{
          title: "",
        }}
      />
      <SettingsStack.Screen name="UserList" component={UserListScreen} />
      <SettingsStack.Screen name="ContentList" component={ContentListScreen} />
      <SettingsStack.Screen
        name="ContentListUpdate"
        component={ContentListUpdateScreen}
      />
      <SettingsStack.Screen name="CreatePin" component={CreatePinScreen} />
      <SettingsStack.Screen
        name="ItemDetails"
        component={ItemDetailsScreen}
        options={{
          headerStyle: headerStyle,
        }}
      />
      <SettingsStack.Screen
        name="Comments"
        component={CommentScreen}
        options={{
          headerStyle: headerStyle,
          headerTitleStyle: {
            alignItems: "flex-start",
            textAlign: "left",
            fontWeight: titleFontWeight,
            fontSize: titleFontSize,
          },
          headerTitleAlign: "left",
        }}
      />
      <SettingsStack.Screen name="Settings" component={SettingsScreen} />
      <SettingsStack.Screen name="Appearance" component={AppearanceScreen} />
      <SettingsStack.Screen name="Profile" component={ProfileUpdateScreen} />
      <SettingsStack.Screen
        name="TopicChooser"
        component={TopicChooserScreen}
      />
      <SettingsStack.Screen
        name="Experiments"
        component={ExperimentListScreen}
      />
      <SettingsStack.Screen name="Feedbacks" component={FeedbackListScreen} />
      <SettingsStack.Screen name="Requests" component={RequestListScreen} />
      <SettingsStack.Screen name="Reports" component={ReportListScreen} />
      <SettingsStack.Screen
        name="ExperimentUpdate"
        component={ExperimentUpdateScreen}
      />
      <SettingsStack.Screen name="Groups" component={GroupListScreen} />
      <SettingsStack.Screen name="GroupUpdate" component={GroupUpdateScreen} />
      <SettingsStack.Screen name="Group" component={GroupScreen} />
      <SettingsStack.Screen
        name="UrlMetadataUpdate"
        component={UrlMetadataUpdateScreen}
      />
      <SettingsStack.Screen
        name="AccountInformation"
        component={AccountInformationScreen}
      />
      <SettingsStack.Screen name="WebViewScreen" component={WebViewScreen} />
      <SettingsStack.Screen
        name="DeleteAccount"
        component={DeleteAccountScreen}
      />
    </SettingsStack.Navigator>
  );
}

function NotificationStackScreen() {
  const { colors } = useTheme();
  const headerStyle = {
    backgroundColor: colors.header,
    borderBottomWidth: 0,
    shadowColor: colors.header,
  };

  const headerTitleStyle = {
    alignItems: "center",
    textAlign: "center",
    fontWeight: titleFontWeight,
    fontSize: titleFontSize,
    maxWidth: 250,
    color: colors.primaryText,
  };

  return (
    <NotificationStack.Navigator
      initialRouteName="Notifications"
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: headerStyle,
        headerTitleStyle: headerTitleStyle,
        headerTitleAlign: "center",
      }}
    >
      <NotificationStack.Screen
        name="Notifications"
        component={NotificationScreen}
        options={{
          headerShown: true, // change this to `false`
          headerTitleStyle: headerTitleStyle,
          headerStyle: headerStyle,
        }}
      />
      <NotificationStack.Screen
        name="ItemDetails"
        component={ItemDetailsScreen}
        options={{
          headerStyle: headerStyle,
        }}
      />
      <NotificationStack.Screen
        name="Comments"
        component={CommentScreen}
        options={{
          headerStyle: headerStyle,
          headerTitleStyle: {
            alignItems: "flex-start",
            textAlign: "left",
            fontWeight: titleFontWeight,
            fontSize: titleFontSize,
          },
          headerTitleAlign: "left",
        }}
      />
      <NotificationStack.Screen
        name="UserDetails"
        component={UserDetailsScreen}
        options={{
          headerStyle: headerStyle,
          title: "",
        }}
      />
      <NotificationStack.Screen name="UserList" component={UserListScreen} />
      <NotificationStack.Screen name="Settings" component={SettingsScreen} />
      <NotificationStack.Screen
        name="Profile"
        component={ProfileUpdateScreen}
      />
      <NotificationStack.Screen
        name="TopicChooser"
        component={TopicChooserScreen}
      />
      <NotificationStack.Screen
        name="ContentList"
        component={ContentListScreen}
      />
      <NotificationStack.Screen
        name="ContentListUpdate"
        component={ContentListUpdateScreen}
      />
      <NotificationStack.Screen name="CreatePin" component={CreatePinScreen} />
      <NotificationStack.Screen
        name="UrlMetadataUpdate"
        component={UrlMetadataUpdateScreen}
      />
    </NotificationStack.Navigator>
  );
}

const ModalScreen = () => <View />;

const TabScreen = ({ initialRouteName, params }) => {
  const { colors } = useTheme();
  let createPinStyle = {
    tabBarLabel: "",
    tabBarIcon: () => (
      <View
        style={{
          width: 56,
          height: 56,
          borderRadius: 56,
          backgroundColor: colors.newPostPlusButton,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          source={plus}
          style={{
            width: 20,
            height: 21,
            tintColor: colors.primaryText,
          }}
        />
      </View>
    ),
  };

  clog("statePointer", statePointer?.avatar);
  clog("URI", statePointer?.presignedUrls[statePointer?.avatar]);
  return (
    <Tab.Navigator
      initialRouteName={"My Village"}
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: colors.primaryText,
        tabBarInactiveTintColor: colors.primaryText,
        tabBarStyle: [
          {
            height: 70,
            paddingBottom: 10,
            backgroundColor: colors.bottomNavBar,
            borderTopWidth: 0,
            borderTopColor: "transparent",
            shadowRadius: 10,
            shadowColor: "rgba(0, 0, 0, 0.25)",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.8,
          },
          Platform.OS != "web"
            ? {
                elevation: 0,
                position: "absolute",
              }
            : {},
        ],
      }}
    >
      <Tab.Screen
        name="My Village"
        component={VillageScreen}
        options={{
          tabBarLabel: "",
          tabBarIcon: ({ focused }) => (
            <Image
              source={focused ? myvillage_active : myvillage_inactive}
              style={{
                width: 20,
                height: focused ? 23 : 22,
                opacity: focused ? 1.0 : 0.5,
                tintColor: colors.primaryText,
              }}
            />
          ),
        }}
        listeners={{
          tabPress: (e) => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
          },
        }}
        initialParams={{ initialRouteName, ...params }}
      />
      <Tab.Screen
        name="CreatePin"
        component={ModalScreen}
        options={createPinStyle}
        listeners={{
          tabPress: (e) => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            e.preventDefault();
            navigationRef.current?.navigate("CreatePinModal");
          },
        }}
      />
      <Tab.Screen
        name="My Page"
        component={SettingsStackScreen}
        options={{
          tabBarLabel: "",
          tabBarIcon: ({ focused }) =>
            statePointer &&
            statePointer.avatar &&
            statePointer?.presignedUrls[statePointer?.avatar] ? (
              <Image
                source={{
                  uri: statePointer?.presignedUrls[statePointer?.avatar].uri,
                  cache: "force-cache",
                }}
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 20,
                  opacity: focused ? 1.0 : 0.75,
                }}
              />
            ) : (
              <Image
                source={personSelected}
                style={{
                  width: 20,
                  height: 21,
                  opacity: focused ? 1.0 : 0.4,
                  tintColor: colors.primaryText,
                }}
              />
            ),
        }}
        listeners={{
          tabPress: (e) => {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
          },
        }}
      />
    </Tab.Navigator>
  );
};

function App() {
  const { colors } = useTheme();
  async function getSession() {
    try {
      const session = await Auth.currentSession();
      ////clog("got session", session);
      if (Platform.OS == "web") {
        const extensionId = "blpcfgokakmgnkcojhhkbfbldkacnbeo";
        chrome?.runtime?.sendMessage(extensionId, session, function (response) {
          //clog(response);
        });
      }
    } catch (err) {
      console.log("error cannot get session...", err);
    }
  }

  async function checkAuthentication() {
    try {
      const { username, attributes } = await Auth.currentAuthenticatedUser();
      if (username) {
        setState((prevState) => {
          return {
            ...prevState,
            authState: "loggedIn",
            authenticated: true,
            authchecked: true,
          };
        });
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            authState: "loggedOut",
            authenticated: false,
            authchecked: true,
          };
        });
      }
    } catch (err) {
      console.log("ERROR", err);
      setState((prevState) => {
        return {
          ...prevState,
          authState: "loggedOut",
          authenticated: false,
          authchecked: true,
        };
      });
    }
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      console.log("Auth event with payload", payload);
    });
    return unsubscribe;
  }, []);

  const initialState = {
    ...initialUser,
    authchecked: false,
    badgeDimension: "",
  };

  const [state, setState] = useState(initialState);
  const [notification, setNotification] = useState(false);
  const [initialRouteName, setInitialRouteName] = useState("My Village");

  const [numNotification, setNumNotification] = useState(0);
  const notificationListener = useRef();
  const responseListener = useRef();

  const [isBelowMinimumVersion, setIsBelowMinimumVersion] = useState(false);
  const [showUpdateAppPopup, setShowUpdateAppPopup] = useState(false);

  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  useEffect(() => {
    clog("CHECKING LAST NOTIFICATION", lastNotificationResponse);

    // Notification screen will properly redirect the user to the right screen
    // TODO(alpha): Consider moving the target to Feed screen in next pass
    if (
      statePointer &&
      lastNotificationResponse?.notification?.request?.content?.data &&
      !statePointer?.handledSomeNotifications
    ) {
      if (!statePointer?.handledSomeNotifications) {
        statePointer["handledSomeNotifications"] = true;
      }
      statePointer["notificationPayload"] =
        lastNotificationResponse.notification.request.content.data;
    }

    if (
      lastNotificationResponse &&
      lastNotificationResponse.notification.request.content &&
      lastNotificationResponse.actionIdentifier ===
        Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      console.log("NOTIFICATION WITH ALL DETAILS");
      setInitialRouteName("Notification");
    }
  }, [lastNotificationResponse]);

  useEffect(() => {
    refreshConfig({
      original: state,
      callback: ({ hasUpdate, belowMinimumVersion }) => {
        setShowUpdateAppPopup(hasUpdate);
        setIsBelowMinimumVersion(belowMinimumVersion);
      },
    });
  }, []);

  //Auth.signOut();
  //return <View></View>;
  clog("State before auth check", state);
  //console.log("BEFORE AUTH CHECK IN APP");
  useEffect(() => {
    clog("performed auth check");
    checkAuthentication();
    getSession();
  }, []);
  //console.log("DONE AUTH CHECK IN APP");
  statePointer = state;
  clog("state is", statePointer);
  clog("will show screen");

  const incrementCounter = () => {
    if (statePointer) {
      if (statePointer["badgeDimension"]) {
        statePointer["badgeDimension"]++;
      } else {
        statePointer["badgeDimension"] = 1;
      }
      console.log("Setting badgeDimension");
      setNumNotification(statePointer["badgeDimension"]);
    }
  };

  useEffect(() => {
    //console.log("WILL CHECK FOR NOTIFICATION SET UP");
    if (Platform.OS != "web") {
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: true,
        }),
      });

      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          clog("RECEIVED NOTIFICATION", notification);
          //alert("Received notification");
          setNotification(notification);
          //navigate("Search", { userName: "Lucy" });
          incrementCounter();
        });

      // This listener is fired whenever a notification is received while the app is in background
      const BACKGROUND_NOTIFICATION_TASK = "BACKGROUND-NOTIFICATION-TASK";
      TaskManager.defineTask(
        BACKGROUND_NOTIFICATION_TASK,
        ({ data, error, executionInfo }) => {
          if (error) {
            console.log("error occurred");
          }
          if (data) {
            incrementCounter();
          }
        }
      );
      Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          clog("HANDLED NOTIFICATION", response);
          if (statePointer) {
            statePointer["badgeDimension"] = 0;
            setNumNotification(statePointer["badgeDimension"]);
            console.log("Setting badgeDimension");

            clog(
              "NOTIFICATION DATA FOR ENGAGED NOTIFICATION",
              response?.notification?.request?.identifier,
              response?.notification?.request?.content?.data
            );

            //const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name;
            if (response?.notification?.request?.content?.data?.type == "Url") {
              navigationRef?.current?.dispatch(
                StackActions.push("Comments", {
                  url: {
                    Id: response?.notification?.request?.content?.data?.urlId,
                  },
                  curator: null,
                  refererActionId: null,
                  hostUserId: null,
                  origin: null,
                  notFolded: true,
                  incomplete: true,
                })
              );
            } else if (
              response?.notification?.request?.content?.data?.type == "User"
            ) {
              navigationRef.current?.dispatch(
                StackActions.push("UserDetails", {
                  Id: response?.notification?.request?.content?.data?.actorId,
                  handle:
                    statePointer?.users[
                      response?.notification?.request?.content?.data?.actorId
                    ]?.handle,
                  fromList: true,
                  refererActionId: null,
                  hostUserId: null,
                })
              );
              return;
            } else if (
              response?.notification?.request?.content?.data?.type ==
              "DailyBest"
            ) {
              navigationRef?.current?.dispatch(StackActions.push("DailyBest"));
            } else {
              console.log(
                "ERROR: Unknown notification destinations",
                response?.notification?.request?.content?.data?.type
              );
            }
          }
        });
    }
    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [state?.Id]);
  //console.log("DONE NOTIFICATION SET UP");
  /*const linking = {
    config: {
      Authentication: "",
      Profile: "profile",
      TopicChooser: "topicchooser",
      Search: "search",
      tab: {
        path: "tab",
        screens: {
          Discover: "discover",
          "My Village": "myvillage",
          Search: "search",
          Notification: "notification",
        },
      },
      GuestUserDetails: "user/:handle",
      GuestContentList: "list/:handle/:name?",
      GuestItemDetails: "item/:item",
    },
  };*/

  const UpdateAppPopup = () => {
    return (
      <Modal visible={showUpdateAppPopup} animationType={"fade"}>
        <SafeAreaView
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: colors.background,
              marginHorizontal: 32,
              borderRadius: 16,
              paddingHorizontal: 12,
              paddingVertical: 24,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <View style={{ marginBottom: 8 }}>
                <Image source={updateApp} style={{ height: 35, width: 25 }} />
              </View>

              <View style={{ marginBottom: 8 }}>
                <Text
                  style={{
                    fontWeight: "700",
                    fontSize: 18,
                    lineHeight: 28,
                    color: colors.primaryText,
                  }}
                >
                  {"Time to update Village"}
                </Text>
              </View>

              <Text
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  lineHeight: 22,
                  color: colors.secondaryText,
                }}
              >
                {
                  "We’ve built new things in Village! To have the best experience, please update this app."
                }
              </Text>
            </View>

            <Pressable
              style={({ pressed }) => ({
                paddingHorizontal: 12,
                paddingVertical: 11,
                borderRadius: 100,
                backgroundColor: colors.primaryButtonBackground,
                opacity: pressed ? 0.5 : 1,
              })}
              onPress={() => {
                Linking.canOpenURL(
                  "https://apps.apple.com/app/village-curated-content/id1594294893"
                )
                  .then((supported) => {
                    if (!supported) {
                      console.log("Unable to Open Url");
                    } else {
                      return Linking.openURL(
                        "https://apps.apple.com/app/village-curated-content/id1594294893"
                      );
                    }
                  })
                  .catch((err) => console.error("Unable to Open Url", err));
              }}
            >
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: 15,
                  lineHeight: 22,
                  color: colors.primaryButtonText,
                }}
              >
                {"Update now"}
              </Text>
            </Pressable>

            {!isBelowMinimumVersion && (
              <Pressable
                style={({ pressed }) => ({
                  marginTop: 8,
                  paddingHorizontal: 12,
                  paddingVertical: 11,
                  opacity: pressed ? 0.5 : 1,
                })}
                onPress={() => setShowUpdateAppPopup(false)}
              >
                <Text
                  style={{
                    fontWeight: "700",
                    fontSize: 15,
                    lineHeight: 22,
                    color: colors.primaryText,
                  }}
                >
                  {"Remind me later"}
                </Text>
              </Pressable>
            )}
          </View>
        </SafeAreaView>
      </Modal>
    );
  };

  const linking = {
    config: {
      screens: {
        Authentication: "",
        GuestUserDetails: "user/:handle/:Id?/:sharerId?",
        GuestContentList: "list/:handle/:name/:Id?/:sharerId?",
        GuestItemDetails: "item/:Id/:sharerId?",
      },
    },
  };

  if (!state.authchecked) {
    return <View></View>;
  } else {
    const headerStyle = {
      backgroundColor: colors.header,
      borderBottomWidth: 0,
      shadowColor: colors.header,
    };
    const headerTitleStyle = {
      alignItems: "center",
      textAlign: "center",
      fontWeight: titleFontWeight,
      fontSize: titleFontSize,
      maxWidth: 250,
      color: colors.primaryText,
    };
    return (
      <SafeAreaProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <AppContext.Provider value={state}>
            <ThemeProvider>
              <NavigationContainer
                //theme={chosenTheme}
                linking={linking}
                ref={navigationRef}
              >
                <Host>
                  <RootStack.Navigator
                    initialRouteName="Authentication"
                    screenOptions={{
                      headerShadowVisible: false,
                      headerStyle: headerStyle,
                      headerTitleStyle: headerTitleStyle,
                      headerTitleAlign: "center",
                    }}
                  >
                    <RootStack.Screen
                      name="Authentication"
                      component={AuthenticationStackScreen}
                      options={{
                        headerShown: false,
                        headerShadowVisible: false,
                      }}
                    />
                    <RootStack.Screen
                      name="Profile"
                      component={ProfileUpdateScreen}
                      options={{
                        headerShown: true,
                      }}
                    />
                    <RootStack.Screen
                      name="TopicChooser"
                      component={TopicChooserScreen}
                      options={{
                        headerShown: true,
                      }}
                    />
                    <RootStack.Screen
                      name="Search"
                      component={SearchStackScreen}
                      options={{
                        headerShown: true,
                      }}
                    />
                    <RootStack.Screen
                      name="OnboardingNotification"
                      component={OnboardingNotificationScreen}
                      options={{
                        title: "Stay updated",
                      }}
                    />
                    <RootStack.Screen
                      name="tab"
                      options={{ headerShown: false }}
                    >
                      {(props) => {
                        return (
                          <TabScreen
                            initialRouteName={initialRouteName}
                            params={props.route?.params}
                          />
                        );
                      }}
                    </RootStack.Screen>
                    <RootStack.Screen
                      name="GuestUserDetails"
                      component={GuestUserDetailsScreen}
                      options={{
                        headerShown: false, // change this to `false`
                      }}
                    />
                    <RootStack.Screen
                      name="GuestContentList"
                      component={GuestContentListScreen}
                      options={{
                        headerShown: false, // change this to `false`
                      }}
                    />
                    <RootStack.Screen
                      name="GuestItemDetails"
                      component={GuestItemDetailsScreen}
                      options={{
                        headerShown: false, // change this to `false`
                      }}
                    />
                    <RootStack.Screen
                      name={"Share"}
                      component={ShareScreen}
                      options={{ headerShown: false }}
                    />
                    <RootStack.Screen
                      name="DailyBest"
                      component={DailyBestScreen}
                      options={{ headerShown: false }}
                    />
                    <RootStack.Screen
                      name="Comments"
                      component={CommentScreen}
                    />
                    <RootStack.Screen
                      name="ItemDetails"
                      component={ItemDetailsScreen}
                    />
                    <RootStack.Screen
                      name="UrlMetadataUpdate"
                      component={UrlMetadataUpdateScreen}
                    />
                    <RootStack.Screen
                      name="UserDetails"
                      component={UserDetailsScreen}
                      options={{
                        title: "",
                      }}
                    />
                    <RootStack.Screen
                      name="CreatePinModal"
                      component={CreatePinScreen}
                      options={{
                        presentation: "transparentModal",
                        cardStyle: { backgroundColor: "rgba(22, 22, 22, 0.5)" },
                        headerShown: false,
                        gestureEnabled: false,
                      }}
                    />
                  </RootStack.Navigator>
                </Host>
              </NavigationContainer>

              <UpdateAppPopup />
              <DropDownAlert
                ref={(ref) => {
                  AlertService.setDropDown(ref);
                }}
                message={"Post uploaded"}
              />
            </ThemeProvider>
          </AppContext.Provider>
        </GestureHandlerRootView>
      </SafeAreaProvider>
    );
  }
}

export default App;
