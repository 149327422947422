import React, { forwardRef } from "react";
import { View, TextInput, StyleSheet, Text, Platform } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import { countriesData } from "../src/constants/countriesData";

const PhoneNumberInput = (props, ref) => {
  const {
    colors,
    countryCode,
    phone,
    onChangeText,
    onCodeChange,
    onBlur,
    onSubmitEditing,
  } = props;

  const countryCodes = countriesData.map((item) => {
    return {
      label: item.countryNameEn,
      value: `+${item.countryCallingCode}`,
      flag: item.flag,
    };
  });

  return (
    <View style={styles.container}>
      <View style={styles.countryCodeContainer}>
        <RNPickerSelect
          style={{
            viewContainer: styles.countryCodePicker,
            inputWeb: {
              backgroundColor: "transparent",
              color: colors.primaryText,
              borderWidth: 0,
            },
          }}
          placeholder={{}}
          value={countryCode}
          onValueChange={onCodeChange}
          items={countryCodes.map((country) => ({
            label:
              Platform.OS !== "web"
                ? `${country.flag} ${country.label} ${country.value}`
                : country.value,
            value: country.value,
          }))}
        >
          <Text style={{ fontSize: 15, color: colors.primaryText }}>
            {countryCode}
          </Text>
        </RNPickerSelect>
        <View
          style={[
            styles.verticalLine,
            { backgroundColor: colors.textFieldBorder },
          ]}
        />
      </View>

      <View style={styles.phoneNumberContainer}>
        <TextInput
          ref={ref}
          style={[
            styles.phoneNumberInput,
            { fontSize: 15, color: colors.primaryText },
          ]}
          placeholder={"Enter 10 digits to receive a confirmation code"}
          placeholderTextColor={colors.secondaryText}
          value={phone}
          onChangeText={onChangeText}
          keyboardType={"phone-pad"}
          returnKeyType={"done"}
          blurOnSubmit={false}
          maxLength={255}
          onSubmitEditing={onSubmitEditing}
          onBlur={onBlur}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderRadius: 8,
    overflow: "hidden",
    height: 48,
    flex: 1,
  },
  verticalLine: {
    height: "100%",
    width: 1,
  },
  countryCodeContainer: {
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  countryCodePicker: {
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  phoneNumberContainer: {
    flex: 5,
    marginLeft: 10,
  },
  phoneNumberInput: {
    flex: 1,
    padding: 10,
  },
});

export default forwardRef(PhoneNumberInput);
