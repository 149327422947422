/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSearchableArtifact = /* GraphQL */ `
  mutation CreateSearchableArtifact(
    $input: CreateSearchableArtifactInput!
    $condition: ModelSearchableArtifactConditionInput
  ) {
    createSearchableArtifact(input: $input, condition: $condition) {
      id
      name
      handle
      title
      objectType
      objectId
      userId
      listId
      urlId
      topicIds
      curatorIds
      commenterIds
      likerIds
      engagerIds
      viewerIds
      authorIds
      listIds
      typeIds
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      topics {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSearchableArtifact = /* GraphQL */ `
  mutation UpdateSearchableArtifact(
    $input: UpdateSearchableArtifactInput!
    $condition: ModelSearchableArtifactConditionInput
  ) {
    updateSearchableArtifact(input: $input, condition: $condition) {
      id
      name
      handle
      title
      objectType
      objectId
      userId
      listId
      urlId
      topicIds
      curatorIds
      commenterIds
      likerIds
      engagerIds
      viewerIds
      authorIds
      listIds
      typeIds
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      topics {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSearchableArtifact = /* GraphQL */ `
  mutation DeleteSearchableArtifact(
    $input: DeleteSearchableArtifactInput!
    $condition: ModelSearchableArtifactConditionInput
  ) {
    deleteSearchableArtifact(input: $input, condition: $condition) {
      id
      name
      handle
      title
      objectType
      objectId
      userId
      listId
      urlId
      topicIds
      curatorIds
      commenterIds
      likerIds
      engagerIds
      viewerIds
      authorIds
      listIds
      typeIds
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      topics {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      handle
      name
      firstName
      lastName
      headline
      bio
      websites
      location
      avatar
      avatarUrl
      token
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numPinRecursive
      numFollowRecursive
      numCommentRecursive
      numShareRecursive
      numCreateRecursive
      numJoinRecursive
      numReportRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numTopicView
      numTopicFollow
      numCommentView
      numCommentLike
      numCommentCreate
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      numGroupCreate
      nextListSeq
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      lastRecommendationCreationTime
      lastRecommendationExpansionTime
      lastRecommendationRefreshTime
      currentRecommendationBatchSequence
      currentRecommendationItemSequence
      currentRecommendationCount
      currentRecommendationExpansionCount
      currentRecommendationRefreshCount
      lastRecommendationNotificationTime
      actionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      mutationActionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      queryActionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      curatedGroups {
        items {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        nextToken
      }
      memberOfGroups {
        items {
          Id
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      lists {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        nextToken
      }
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      label
      numAvailableRecommendations
      lowestRecommendationScore
      lastNotificationPermissionRequestTime
      notificationPermissionRequestCount
      lastAppUpgradeRecommendationTime
      appUpgradeRecommendationCount
      lastTopicUpdateTime
      lastTopicUpdateRecommendationTime
      topicUpdateRecommendationCount
      chosenTheme
      savedListId
      savedList {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      wishList
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      handle
      name
      firstName
      lastName
      headline
      bio
      websites
      location
      avatar
      avatarUrl
      token
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numPinRecursive
      numFollowRecursive
      numCommentRecursive
      numShareRecursive
      numCreateRecursive
      numJoinRecursive
      numReportRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numTopicView
      numTopicFollow
      numCommentView
      numCommentLike
      numCommentCreate
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      numGroupCreate
      nextListSeq
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      lastRecommendationCreationTime
      lastRecommendationExpansionTime
      lastRecommendationRefreshTime
      currentRecommendationBatchSequence
      currentRecommendationItemSequence
      currentRecommendationCount
      currentRecommendationExpansionCount
      currentRecommendationRefreshCount
      lastRecommendationNotificationTime
      actionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      mutationActionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      queryActionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      curatedGroups {
        items {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        nextToken
      }
      memberOfGroups {
        items {
          Id
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      lists {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        nextToken
      }
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      label
      numAvailableRecommendations
      lowestRecommendationScore
      lastNotificationPermissionRequestTime
      notificationPermissionRequestCount
      lastAppUpgradeRecommendationTime
      appUpgradeRecommendationCount
      lastTopicUpdateTime
      lastTopicUpdateRecommendationTime
      topicUpdateRecommendationCount
      chosenTheme
      savedListId
      savedList {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      wishList
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      handle
      name
      firstName
      lastName
      headline
      bio
      websites
      location
      avatar
      avatarUrl
      token
      numView
      numFollow
      numShare
      numViewRecursive
      numLikeRecursive
      numPinRecursive
      numFollowRecursive
      numCommentRecursive
      numShareRecursive
      numCreateRecursive
      numJoinRecursive
      numReportRecursive
      numUserView
      numUserFollow
      numUserShare
      numListView
      numListLike
      numListFollow
      numListShare
      numListCreate
      numPinView
      numPinLike
      numPinCreate
      numUrlView
      numUrlContribute
      numUrlAuthor
      numTopicView
      numTopicFollow
      numCommentView
      numCommentLike
      numCommentCreate
      numSourceView
      numSourceLike
      numSourceFollow
      numExperimentJoin
      numExperimentCreate
      numGroupCreate
      nextListSeq
      topicIds
      declaredTopicIds
      curatedTopicIds
      consumedTopicIds
      engagedTopicIds
      viewedTopicIds
      visitedTopicIds
      lastRecommendationCreationTime
      lastRecommendationExpansionTime
      lastRecommendationRefreshTime
      currentRecommendationBatchSequence
      currentRecommendationItemSequence
      currentRecommendationCount
      currentRecommendationExpansionCount
      currentRecommendationRefreshCount
      lastRecommendationNotificationTime
      actionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      mutationActionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      queryActionsByUser {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      curatedGroups {
        items {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        nextToken
      }
      memberOfGroups {
        items {
          Id
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      lists {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        nextToken
      }
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      label
      numAvailableRecommendations
      lowestRecommendationScore
      lastNotificationPermissionRequestTime
      notificationPermissionRequestCount
      lastAppUpgradeRecommendationTime
      appUpgradeRecommendationCount
      lastTopicUpdateTime
      lastTopicUpdateRecommendationTime
      topicUpdateRecommendationCount
      chosenTheme
      savedListId
      savedList {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      wishList
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      implicit
      memberUsers {
        items {
          Id
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      memberGroups {
        items {
          Id
          id
          parentGroupId
          childGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      memberOfGroups {
        items {
          Id
          id
          parentGroupId
          childGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      implicit
      memberUsers {
        items {
          Id
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      memberGroups {
        items {
          Id
          id
          parentGroupId
          childGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      memberOfGroups {
        items {
          Id
          id
          parentGroupId
          childGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      implicit
      memberUsers {
        items {
          Id
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      memberGroups {
        items {
          Id
          id
          parentGroupId
          childGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      memberOfGroups {
        items {
          Id
          id
          parentGroupId
          childGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupUser = /* GraphQL */ `
  mutation CreateGroupUser(
    $input: CreateGroupUserInput!
    $condition: ModelGroupUserConditionInput
  ) {
    createGroupUser(input: $input, condition: $condition) {
      Id
      id
      groupId
      userId
      group {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupUser = /* GraphQL */ `
  mutation UpdateGroupUser(
    $input: UpdateGroupUserInput!
    $condition: ModelGroupUserConditionInput
  ) {
    updateGroupUser(input: $input, condition: $condition) {
      Id
      id
      groupId
      userId
      group {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupUser = /* GraphQL */ `
  mutation DeleteGroupUser(
    $input: DeleteGroupUserInput!
    $condition: ModelGroupUserConditionInput
  ) {
    deleteGroupUser(input: $input, condition: $condition) {
      Id
      id
      groupId
      userId
      group {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupGroup = /* GraphQL */ `
  mutation CreateGroupGroup(
    $input: CreateGroupGroupInput!
    $condition: ModelGroupGroupConditionInput
  ) {
    createGroupGroup(input: $input, condition: $condition) {
      Id
      id
      parentGroupId
      childGroupId
      parentGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      childGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupGroup = /* GraphQL */ `
  mutation UpdateGroupGroup(
    $input: UpdateGroupGroupInput!
    $condition: ModelGroupGroupConditionInput
  ) {
    updateGroupGroup(input: $input, condition: $condition) {
      Id
      id
      parentGroupId
      childGroupId
      parentGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      childGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupGroup = /* GraphQL */ `
  mutation DeleteGroupGroup(
    $input: DeleteGroupGroupInput!
    $condition: ModelGroupGroupConditionInput
  ) {
    deleteGroupGroup(input: $input, condition: $condition) {
      Id
      id
      parentGroupId
      childGroupId
      parentGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      childGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createList = /* GraphQL */ `
  mutation CreateList(
    $input: CreateListInput!
    $condition: ModelListConditionInput
  ) {
    createList(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      numView
      numLike
      numFollow
      numShare
      numComment
      numPins
      numReport
      seq
      nextPinSeq
      topicIds
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          Id
          id
          topicId
          listId
          inferred
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      createdAt
      updatedAt
    }
  }
`;
export const updateList = /* GraphQL */ `
  mutation UpdateList(
    $input: UpdateListInput!
    $condition: ModelListConditionInput
  ) {
    updateList(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      numView
      numLike
      numFollow
      numShare
      numComment
      numPins
      numReport
      seq
      nextPinSeq
      topicIds
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          Id
          id
          topicId
          listId
          inferred
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      createdAt
      updatedAt
    }
  }
`;
export const deleteList = /* GraphQL */ `
  mutation DeleteList(
    $input: DeleteListInput!
    $condition: ModelListConditionInput
  ) {
    deleteList(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      numView
      numLike
      numFollow
      numShare
      numComment
      numPins
      numReport
      seq
      nextPinSeq
      topicIds
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          Id
          id
          topicId
          listId
          inferred
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      createdAt
      updatedAt
    }
  }
`;
export const createPin = /* GraphQL */ `
  mutation CreatePin(
    $input: CreatePinInput!
    $condition: ModelPinConditionInput
  ) {
    createPin(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      content
      urlId
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      listId
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      numView
      numLike
      numComment
      numShare
      numReport
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      seq
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      reaction
      markup
      tldr
      createdAt
      updatedAt
    }
  }
`;
export const updatePin = /* GraphQL */ `
  mutation UpdatePin(
    $input: UpdatePinInput!
    $condition: ModelPinConditionInput
  ) {
    updatePin(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      content
      urlId
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      listId
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      numView
      numLike
      numComment
      numShare
      numReport
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      seq
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      reaction
      markup
      tldr
      createdAt
      updatedAt
    }
  }
`;
export const deletePin = /* GraphQL */ `
  mutation DeletePin(
    $input: DeletePinInput!
    $condition: ModelPinConditionInput
  ) {
    deletePin(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      content
      urlId
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      listId
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      numView
      numLike
      numComment
      numShare
      numReport
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      seq
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      reaction
      markup
      tldr
      createdAt
      updatedAt
    }
  }
`;
export const createUrl = /* GraphQL */ `
  mutation CreateUrl(
    $input: CreateUrlInput!
    $condition: ModelUrlConditionInput
  ) {
    createUrl(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      title
      authorId
      author {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      authorName
      sourceId
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        avatar
        avatarUrl
        pattern
        enableJavascript
        numView
        numFollow
        numLike
        numReport
        numUrls
        urls {
          nextToken
        }
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      uri
      snippet
      photo
      photoUrl
      type
      duration
      data
      topics {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      numView
      numShare
      numComment
      numLike
      numPins
      numReport
      numContribute
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      numCommentRecursive
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorIds
      commenterIds
      likerIds
      engagerIds
      viewerIds
      authorIds
      listIds
      topicIds
      groupIds
      creationTS
      updateTS
      dummy
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      tldr
      createdAt
      updatedAt
    }
  }
`;
export const updateUrl = /* GraphQL */ `
  mutation UpdateUrl(
    $input: UpdateUrlInput!
    $condition: ModelUrlConditionInput
  ) {
    updateUrl(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      title
      authorId
      author {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      authorName
      sourceId
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        avatar
        avatarUrl
        pattern
        enableJavascript
        numView
        numFollow
        numLike
        numReport
        numUrls
        urls {
          nextToken
        }
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      uri
      snippet
      photo
      photoUrl
      type
      duration
      data
      topics {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      numView
      numShare
      numComment
      numLike
      numPins
      numReport
      numContribute
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      numCommentRecursive
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorIds
      commenterIds
      likerIds
      engagerIds
      viewerIds
      authorIds
      listIds
      topicIds
      groupIds
      creationTS
      updateTS
      dummy
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      tldr
      createdAt
      updatedAt
    }
  }
`;
export const deleteUrl = /* GraphQL */ `
  mutation DeleteUrl(
    $input: DeleteUrlInput!
    $condition: ModelUrlConditionInput
  ) {
    deleteUrl(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      title
      authorId
      author {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      authorName
      sourceId
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        avatar
        avatarUrl
        pattern
        enableJavascript
        numView
        numFollow
        numLike
        numReport
        numUrls
        urls {
          nextToken
        }
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      uri
      snippet
      photo
      photoUrl
      type
      duration
      data
      topics {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      numView
      numShare
      numComment
      numLike
      numPins
      numReport
      numContribute
      numLongView
      numTotalView
      numLongVisit
      numTotalVisit
      numCommentRecursive
      pins {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorIds
      commenterIds
      likerIds
      engagerIds
      viewerIds
      authorIds
      listIds
      topicIds
      groupIds
      creationTS
      updateTS
      dummy
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      tldr
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      content
      topLevel
      numView
      numLike
      numComment
      numReport
      objectId
      objectType
      listId
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      pinId
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      urlId
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      commentId
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      markup
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      content
      topLevel
      numView
      numLike
      numComment
      numReport
      objectId
      objectType
      listId
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      pinId
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      urlId
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      commentId
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      markup
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      content
      topLevel
      numView
      numLike
      numComment
      numReport
      objectId
      objectType
      listId
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      pinId
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      urlId
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      commentId
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      comments {
        items {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      touch
      markup
      createdAt
      updatedAt
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      topLevel
      avatar
      avatarUrl
      numView
      numFollow
      numUrls
      urls {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      lists {
        items {
          Id
          id
          topicId
          listId
          inferred
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      parentTopicId
      parentTopic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      depth
      onboarding
      seq
      createdAt
      updatedAt
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      topLevel
      avatar
      avatarUrl
      numView
      numFollow
      numUrls
      urls {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      lists {
        items {
          Id
          id
          topicId
          listId
          inferred
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      parentTopicId
      parentTopic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      depth
      onboarding
      seq
      createdAt
      updatedAt
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      topLevel
      avatar
      avatarUrl
      numView
      numFollow
      numUrls
      urls {
        items {
          Id
          id
          topicId
          urlId
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      lists {
        items {
          Id
          id
          topicId
          listId
          inferred
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      parentTopicId
      parentTopic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      depth
      onboarding
      seq
      createdAt
      updatedAt
    }
  }
`;
export const createTopicUrl = /* GraphQL */ `
  mutation CreateTopicUrl(
    $input: CreateTopicUrlInput!
    $condition: ModelTopicUrlConditionInput
  ) {
    createTopicUrl(input: $input, condition: $condition) {
      Id
      id
      topicId
      urlId
      weight
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTopicUrl = /* GraphQL */ `
  mutation UpdateTopicUrl(
    $input: UpdateTopicUrlInput!
    $condition: ModelTopicUrlConditionInput
  ) {
    updateTopicUrl(input: $input, condition: $condition) {
      Id
      id
      topicId
      urlId
      weight
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTopicUrl = /* GraphQL */ `
  mutation DeleteTopicUrl(
    $input: DeleteTopicUrlInput!
    $condition: ModelTopicUrlConditionInput
  ) {
    deleteTopicUrl(input: $input, condition: $condition) {
      Id
      id
      topicId
      urlId
      weight
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTopicList = /* GraphQL */ `
  mutation CreateTopicList(
    $input: CreateTopicListInput!
    $condition: ModelTopicListConditionInput
  ) {
    createTopicList(input: $input, condition: $condition) {
      Id
      id
      topicId
      listId
      inferred
      weight
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTopicList = /* GraphQL */ `
  mutation UpdateTopicList(
    $input: UpdateTopicListInput!
    $condition: ModelTopicListConditionInput
  ) {
    updateTopicList(input: $input, condition: $condition) {
      Id
      id
      topicId
      listId
      inferred
      weight
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTopicList = /* GraphQL */ `
  mutation DeleteTopicList(
    $input: DeleteTopicListInput!
    $condition: ModelTopicListConditionInput
  ) {
    deleteTopicList(input: $input, condition: $condition) {
      Id
      id
      topicId
      listId
      inferred
      weight
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSource = /* GraphQL */ `
  mutation CreateSource(
    $input: CreateSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    createSource(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      avatar
      avatarUrl
      pattern
      enableJavascript
      numView
      numFollow
      numLike
      numReport
      numUrls
      urls {
        items {
          Id
          id
          sourceId
          urlId
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSource = /* GraphQL */ `
  mutation UpdateSource(
    $input: UpdateSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    updateSource(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      avatar
      avatarUrl
      pattern
      enableJavascript
      numView
      numFollow
      numLike
      numReport
      numUrls
      urls {
        items {
          Id
          id
          sourceId
          urlId
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSource = /* GraphQL */ `
  mutation DeleteSource(
    $input: DeleteSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    deleteSource(input: $input, condition: $condition) {
      Id
      id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      name
      description
      avatar
      avatarUrl
      pattern
      enableJavascript
      numView
      numFollow
      numLike
      numReport
      numUrls
      urls {
        items {
          Id
          id
          sourceId
          urlId
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSourceUrl = /* GraphQL */ `
  mutation CreateSourceUrl(
    $input: CreateSourceUrlInput!
    $condition: ModelSourceUrlConditionInput
  ) {
    createSourceUrl(input: $input, condition: $condition) {
      Id
      id
      sourceId
      urlId
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSourceUrl = /* GraphQL */ `
  mutation UpdateSourceUrl(
    $input: UpdateSourceUrlInput!
    $condition: ModelSourceUrlConditionInput
  ) {
    updateSourceUrl(input: $input, condition: $condition) {
      Id
      id
      sourceId
      urlId
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSourceUrl = /* GraphQL */ `
  mutation DeleteSourceUrl(
    $input: DeleteSourceUrlInput!
    $condition: ModelSourceUrlConditionInput
  ) {
    deleteSourceUrl(input: $input, condition: $condition) {
      Id
      id
      sourceId
      urlId
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      operation
      actorId
      userId
      listId
      pinId
      urlId
      commentId
      topicId
      sourceId
      experimentId
      groupId
      mutationActorId
      queryActorId
      refererActionId
      refererAction {
        Id
        id
        objectId
        objectType
        operation
        actorId
        userId
        listId
        pinId
        urlId
        commentId
        topicId
        sourceId
        experimentId
        groupId
        mutationActorId
        queryActorId
        refererActionId
        refererAction {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        hostUserId
        hostUser {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        creationTS
        actorIdOperationObjectType
        actorIdOperationObjectId
        objectIdOperation
        user {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        topic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        experiment {
          id
          Id
          creatorId
          curatorId
          acl
          name
          description
          numJoin
          createdAt
          updatedAt
        }
        group {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        actor {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        touch
        note
        createdAt
        updatedAt
      }
      hostUserId
      hostUser {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      creationTS
      actorIdOperationObjectType
      actorIdOperationObjectId
      objectIdOperation
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        avatar
        avatarUrl
        pattern
        enableJavascript
        numView
        numFollow
        numLike
        numReport
        numUrls
        urls {
          nextToken
        }
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      experiment {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        name
        description
        numJoin
        createdAt
        updatedAt
      }
      group {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      touch
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      operation
      actorId
      userId
      listId
      pinId
      urlId
      commentId
      topicId
      sourceId
      experimentId
      groupId
      mutationActorId
      queryActorId
      refererActionId
      refererAction {
        Id
        id
        objectId
        objectType
        operation
        actorId
        userId
        listId
        pinId
        urlId
        commentId
        topicId
        sourceId
        experimentId
        groupId
        mutationActorId
        queryActorId
        refererActionId
        refererAction {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        hostUserId
        hostUser {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        creationTS
        actorIdOperationObjectType
        actorIdOperationObjectId
        objectIdOperation
        user {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        topic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        experiment {
          id
          Id
          creatorId
          curatorId
          acl
          name
          description
          numJoin
          createdAt
          updatedAt
        }
        group {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        actor {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        touch
        note
        createdAt
        updatedAt
      }
      hostUserId
      hostUser {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      creationTS
      actorIdOperationObjectType
      actorIdOperationObjectId
      objectIdOperation
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        avatar
        avatarUrl
        pattern
        enableJavascript
        numView
        numFollow
        numLike
        numReport
        numUrls
        urls {
          nextToken
        }
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      experiment {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        name
        description
        numJoin
        createdAt
        updatedAt
      }
      group {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      touch
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      operation
      actorId
      userId
      listId
      pinId
      urlId
      commentId
      topicId
      sourceId
      experimentId
      groupId
      mutationActorId
      queryActorId
      refererActionId
      refererAction {
        Id
        id
        objectId
        objectType
        operation
        actorId
        userId
        listId
        pinId
        urlId
        commentId
        topicId
        sourceId
        experimentId
        groupId
        mutationActorId
        queryActorId
        refererActionId
        refererAction {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        hostUserId
        hostUser {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        creationTS
        actorIdOperationObjectType
        actorIdOperationObjectId
        objectIdOperation
        user {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        topic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        experiment {
          id
          Id
          creatorId
          curatorId
          acl
          name
          description
          numJoin
          createdAt
          updatedAt
        }
        group {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        actor {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        touch
        note
        createdAt
        updatedAt
      }
      hostUserId
      hostUser {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      creationTS
      actorIdOperationObjectType
      actorIdOperationObjectId
      objectIdOperation
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      list {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        numView
        numLike
        numFollow
        numShare
        numComment
        numPins
        numReport
        seq
        nextPinSeq
        topicIds
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        topics {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        createdAt
        updatedAt
      }
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      topic {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        topLevel
        avatar
        avatarUrl
        numView
        numFollow
        numUrls
        urls {
          nextToken
        }
        lists {
          nextToken
        }
        parentTopicId
        parentTopic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        actions {
          nextToken
        }
        depth
        onboarding
        seq
        createdAt
        updatedAt
      }
      source {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        avatar
        avatarUrl
        pattern
        enableJavascript
        numView
        numFollow
        numLike
        numReport
        numUrls
        urls {
          nextToken
        }
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      experiment {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        name
        description
        numJoin
        createdAt
        updatedAt
      }
      group {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      touch
      note
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      Id
      id
      recipientId
      actorId
      actionId
      targetId
      targetType
      operation
      userId
      urlId
      pinId
      commentId
      creationTS
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      action {
        Id
        id
        objectId
        objectType
        operation
        actorId
        userId
        listId
        pinId
        urlId
        commentId
        topicId
        sourceId
        experimentId
        groupId
        mutationActorId
        queryActorId
        refererActionId
        refererAction {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        hostUserId
        hostUser {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        creationTS
        actorIdOperationObjectType
        actorIdOperationObjectId
        objectIdOperation
        user {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        topic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        experiment {
          id
          Id
          creatorId
          curatorId
          acl
          name
          description
          numJoin
          createdAt
          updatedAt
        }
        group {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        actor {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        touch
        note
        createdAt
        updatedAt
      }
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      Id
      id
      recipientId
      actorId
      actionId
      targetId
      targetType
      operation
      userId
      urlId
      pinId
      commentId
      creationTS
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      action {
        Id
        id
        objectId
        objectType
        operation
        actorId
        userId
        listId
        pinId
        urlId
        commentId
        topicId
        sourceId
        experimentId
        groupId
        mutationActorId
        queryActorId
        refererActionId
        refererAction {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        hostUserId
        hostUser {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        creationTS
        actorIdOperationObjectType
        actorIdOperationObjectId
        objectIdOperation
        user {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        topic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        experiment {
          id
          Id
          creatorId
          curatorId
          acl
          name
          description
          numJoin
          createdAt
          updatedAt
        }
        group {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        actor {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        touch
        note
        createdAt
        updatedAt
      }
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      Id
      id
      recipientId
      actorId
      actionId
      targetId
      targetType
      operation
      userId
      urlId
      pinId
      commentId
      creationTS
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      action {
        Id
        id
        objectId
        objectType
        operation
        actorId
        userId
        listId
        pinId
        urlId
        commentId
        topicId
        sourceId
        experimentId
        groupId
        mutationActorId
        queryActorId
        refererActionId
        refererAction {
          Id
          id
          objectId
          objectType
          operation
          actorId
          userId
          listId
          pinId
          urlId
          commentId
          topicId
          sourceId
          experimentId
          groupId
          mutationActorId
          queryActorId
          refererActionId
          hostUserId
          creationTS
          actorIdOperationObjectType
          actorIdOperationObjectId
          objectIdOperation
          touch
          note
          createdAt
          updatedAt
        }
        hostUserId
        hostUser {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        creationTS
        actorIdOperationObjectType
        actorIdOperationObjectId
        objectIdOperation
        user {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        topic {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          topLevel
          avatar
          avatarUrl
          numView
          numFollow
          numUrls
          parentTopicId
          depth
          onboarding
          seq
          createdAt
          updatedAt
        }
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        experiment {
          id
          Id
          creatorId
          curatorId
          acl
          name
          description
          numJoin
          createdAt
          updatedAt
        }
        group {
          id
          Id
          creatorId
          acl
          curatorId
          name
          description
          implicit
          createdAt
          updatedAt
        }
        actor {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        touch
        note
        createdAt
        updatedAt
      }
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      comment {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        topLevel
        numView
        numLike
        numComment
        numReport
        objectId
        objectType
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        pinId
        pin {
          Id
          id
          creatorId
          acl
          curatorId
          content
          urlId
          listId
          numView
          numLike
          numComment
          numShare
          numReport
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          seq
          touch
          reaction
          markup
          tldr
          createdAt
          updatedAt
        }
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        commentId
        comment {
          Id
          id
          creatorId
          acl
          curatorId
          content
          topLevel
          numView
          numLike
          numComment
          numReport
          objectId
          objectType
          listId
          pinId
          urlId
          commentId
          touch
          markup
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        markup
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createExperiment = /* GraphQL */ `
  mutation CreateExperiment(
    $input: CreateExperimentInput!
    $condition: ModelExperimentConditionInput
  ) {
    createExperiment(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      description
      numJoin
      createdAt
      updatedAt
    }
  }
`;
export const updateExperiment = /* GraphQL */ `
  mutation UpdateExperiment(
    $input: UpdateExperimentInput!
    $condition: ModelExperimentConditionInput
  ) {
    updateExperiment(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      description
      numJoin
      createdAt
      updatedAt
    }
  }
`;
export const deleteExperiment = /* GraphQL */ `
  mutation DeleteExperiment(
    $input: DeleteExperimentInput!
    $condition: ModelExperimentConditionInput
  ) {
    deleteExperiment(input: $input, condition: $condition) {
      id
      Id
      creatorId
      creator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      curatorId
      curator {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      acl
      aces {
        items {
          id
          Id
          objectId
          scope
          capability
          principalId
          principalType
          principalUserId
          principalGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      description
      numJoin
      createdAt
      updatedAt
    }
  }
`;
export const createAce = /* GraphQL */ `
  mutation CreateAce(
    $input: CreateAceInput!
    $condition: ModelAceConditionInput
  ) {
    createAce(input: $input, condition: $condition) {
      id
      Id
      objectId
      scope
      capability
      principalId
      principalType
      principalUserId
      principalUser {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      principalGroupId
      principalGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAce = /* GraphQL */ `
  mutation UpdateAce(
    $input: UpdateAceInput!
    $condition: ModelAceConditionInput
  ) {
    updateAce(input: $input, condition: $condition) {
      id
      Id
      objectId
      scope
      capability
      principalId
      principalType
      principalUserId
      principalUser {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      principalGroupId
      principalGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAce = /* GraphQL */ `
  mutation DeleteAce(
    $input: DeleteAceInput!
    $condition: ModelAceConditionInput
  ) {
    deleteAce(input: $input, condition: $condition) {
      id
      Id
      objectId
      scope
      capability
      principalId
      principalType
      principalUserId
      principalUser {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      principalGroupId
      principalGroup {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        name
        description
        implicit
        memberUsers {
          nextToken
        }
        memberGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createError = /* GraphQL */ `
  mutation CreateError(
    $input: CreateErrorInput!
    $condition: ModelErrorConditionInput
  ) {
    createError(input: $input, condition: $condition) {
      id
      Id
      userID
      screen
      operation
      log
      createdAt
      updatedAt
    }
  }
`;
export const updateError = /* GraphQL */ `
  mutation UpdateError(
    $input: UpdateErrorInput!
    $condition: ModelErrorConditionInput
  ) {
    updateError(input: $input, condition: $condition) {
      id
      Id
      userID
      screen
      operation
      log
      createdAt
      updatedAt
    }
  }
`;
export const deleteError = /* GraphQL */ `
  mutation DeleteError(
    $input: DeleteErrorInput!
    $condition: ModelErrorConditionInput
  ) {
    deleteError(input: $input, condition: $condition) {
      id
      Id
      userID
      screen
      operation
      log
      createdAt
      updatedAt
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      Id
      userId
      handle
      type
      operation
      details
      createdAt
      updatedAt
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      Id
      userId
      handle
      type
      operation
      details
      createdAt
      updatedAt
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      Id
      userId
      handle
      type
      operation
      details
      createdAt
      updatedAt
    }
  }
`;
export const createRecommendation = /* GraphQL */ `
  mutation CreateRecommendation(
    $input: CreateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    createRecommendation(input: $input, condition: $condition) {
      id
      Id
      userId
      batchId
      sequence
      urlId
      score
      signals
      creationTS
      userIdBatchId
      userIdType
      type
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRecommendation = /* GraphQL */ `
  mutation UpdateRecommendation(
    $input: UpdateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    updateRecommendation(input: $input, condition: $condition) {
      id
      Id
      userId
      batchId
      sequence
      urlId
      score
      signals
      creationTS
      userIdBatchId
      userIdType
      type
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecommendation = /* GraphQL */ `
  mutation DeleteRecommendation(
    $input: DeleteRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    deleteRecommendation(input: $input, condition: $condition) {
      id
      Id
      userId
      batchId
      sequence
      urlId
      score
      signals
      creationTS
      userIdBatchId
      userIdType
      type
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVisit = /* GraphQL */ `
  mutation CreateVisit(
    $input: CreateVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    createVisit(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVisit = /* GraphQL */ `
  mutation UpdateVisit(
    $input: UpdateVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    updateVisit(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVisit = /* GraphQL */ `
  mutation DeleteVisit(
    $input: DeleteVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    deleteVisit(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVisitArchive = /* GraphQL */ `
  mutation CreateVisitArchive(
    $input: CreateVisitArchiveInput!
    $condition: ModelVisitArchiveConditionInput
  ) {
    createVisitArchive(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVisitArchive = /* GraphQL */ `
  mutation UpdateVisitArchive(
    $input: UpdateVisitArchiveInput!
    $condition: ModelVisitArchiveConditionInput
  ) {
    updateVisitArchive(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVisitArchive = /* GraphQL */ `
  mutation DeleteVisitArchive(
    $input: DeleteVisitArchiveInput!
    $condition: ModelVisitArchiveConditionInput
  ) {
    deleteVisitArchive(input: $input, condition: $condition) {
      Id
      id
      objectId
      objectType
      actorId
      type
      pinId
      urlId
      visitTS
      duration
      origin
      pin {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        content
        urlId
        url {
          Id
          id
          creatorId
          acl
          curatorId
          title
          authorId
          authorName
          sourceId
          uri
          snippet
          photo
          photoUrl
          type
          duration
          data
          numView
          numShare
          numComment
          numLike
          numPins
          numReport
          numContribute
          numLongView
          numTotalView
          numLongVisit
          numTotalVisit
          numCommentRecursive
          curatorIds
          commenterIds
          likerIds
          engagerIds
          viewerIds
          authorIds
          listIds
          topicIds
          groupIds
          creationTS
          updateTS
          dummy
          touch
          tldr
          createdAt
          updatedAt
        }
        listId
        list {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        numView
        numLike
        numComment
        numShare
        numReport
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        seq
        comments {
          nextToken
        }
        actions {
          nextToken
        }
        touch
        reaction
        markup
        tldr
        createdAt
        updatedAt
      }
      url {
        Id
        id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        curatorId
        curator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        title
        authorId
        author {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        authorName
        sourceId
        source {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          avatar
          avatarUrl
          pattern
          enableJavascript
          numView
          numFollow
          numLike
          numReport
          numUrls
          createdAt
          updatedAt
        }
        uri
        snippet
        photo
        photoUrl
        type
        duration
        data
        topics {
          nextToken
        }
        numView
        numShare
        numComment
        numLike
        numPins
        numReport
        numContribute
        numLongView
        numTotalView
        numLongVisit
        numTotalVisit
        numCommentRecursive
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        curatorIds
        commenterIds
        likerIds
        engagerIds
        viewerIds
        authorIds
        listIds
        topicIds
        groupIds
        creationTS
        updateTS
        dummy
        actions {
          nextToken
        }
        touch
        tldr
        createdAt
        updatedAt
      }
      actor {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      id
      Id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      id
      Id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      id
      Id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      Id
      id
      userId
      creationTS
      note
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      Id
      id
      userId
      creationTS
      note
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      Id
      id
      userId
      creationTS
      note
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      Id
      id
      userId
      type
      creationTS
      note
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      Id
      id
      userId
      type
      creationTS
      note
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      Id
      id
      userId
      type
      creationTS
      note
      user {
        id
        Id
        creatorId
        creator {
          id
          Id
          creatorId
          acl
          handle
          name
          firstName
          lastName
          headline
          bio
          websites
          location
          avatar
          avatarUrl
          token
          numView
          numFollow
          numShare
          numViewRecursive
          numLikeRecursive
          numPinRecursive
          numFollowRecursive
          numCommentRecursive
          numShareRecursive
          numCreateRecursive
          numJoinRecursive
          numReportRecursive
          numUserView
          numUserFollow
          numUserShare
          numListView
          numListLike
          numListFollow
          numListShare
          numListCreate
          numPinView
          numPinLike
          numPinCreate
          numUrlView
          numUrlContribute
          numUrlAuthor
          numTopicView
          numTopicFollow
          numCommentView
          numCommentLike
          numCommentCreate
          numSourceView
          numSourceLike
          numSourceFollow
          numExperimentJoin
          numExperimentCreate
          numGroupCreate
          nextListSeq
          topicIds
          declaredTopicIds
          curatedTopicIds
          consumedTopicIds
          engagedTopicIds
          viewedTopicIds
          visitedTopicIds
          lastRecommendationCreationTime
          lastRecommendationExpansionTime
          lastRecommendationRefreshTime
          currentRecommendationBatchSequence
          currentRecommendationItemSequence
          currentRecommendationCount
          currentRecommendationExpansionCount
          currentRecommendationRefreshCount
          lastRecommendationNotificationTime
          touch
          label
          numAvailableRecommendations
          lowestRecommendationScore
          lastNotificationPermissionRequestTime
          notificationPermissionRequestCount
          lastAppUpgradeRecommendationTime
          appUpgradeRecommendationCount
          lastTopicUpdateTime
          lastTopicUpdateRecommendationTime
          topicUpdateRecommendationCount
          chosenTheme
          savedListId
          wishList
          createdAt
          updatedAt
        }
        acl
        aces {
          nextToken
        }
        handle
        name
        firstName
        lastName
        headline
        bio
        websites
        location
        avatar
        avatarUrl
        token
        numView
        numFollow
        numShare
        numViewRecursive
        numLikeRecursive
        numPinRecursive
        numFollowRecursive
        numCommentRecursive
        numShareRecursive
        numCreateRecursive
        numJoinRecursive
        numReportRecursive
        numUserView
        numUserFollow
        numUserShare
        numListView
        numListLike
        numListFollow
        numListShare
        numListCreate
        numPinView
        numPinLike
        numPinCreate
        numUrlView
        numUrlContribute
        numUrlAuthor
        numTopicView
        numTopicFollow
        numCommentView
        numCommentLike
        numCommentCreate
        numSourceView
        numSourceLike
        numSourceFollow
        numExperimentJoin
        numExperimentCreate
        numGroupCreate
        nextListSeq
        topicIds
        declaredTopicIds
        curatedTopicIds
        consumedTopicIds
        engagedTopicIds
        viewedTopicIds
        visitedTopicIds
        lastRecommendationCreationTime
        lastRecommendationExpansionTime
        lastRecommendationRefreshTime
        currentRecommendationBatchSequence
        currentRecommendationItemSequence
        currentRecommendationCount
        currentRecommendationExpansionCount
        currentRecommendationRefreshCount
        lastRecommendationNotificationTime
        actionsByUser {
          nextToken
        }
        mutationActionsByUser {
          nextToken
        }
        queryActionsByUser {
          nextToken
        }
        actions {
          nextToken
        }
        curatedGroups {
          nextToken
        }
        memberOfGroups {
          nextToken
        }
        lists {
          nextToken
        }
        pins {
          nextToken
        }
        comments {
          nextToken
        }
        touch
        label
        numAvailableRecommendations
        lowestRecommendationScore
        lastNotificationPermissionRequestTime
        notificationPermissionRequestCount
        lastAppUpgradeRecommendationTime
        appUpgradeRecommendationCount
        lastTopicUpdateTime
        lastTopicUpdateRecommendationTime
        topicUpdateRecommendationCount
        chosenTheme
        savedListId
        savedList {
          Id
          id
          creatorId
          acl
          curatorId
          name
          description
          numView
          numLike
          numFollow
          numShare
          numComment
          numPins
          numReport
          seq
          nextPinSeq
          topicIds
          touch
          createdAt
          updatedAt
        }
        wishList
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
