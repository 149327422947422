import React, { useContext, useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  Pressable,
  Linking,
  Image,
  AppState,
} from "react-native";

import AppContext from "../components/AppContext";
import { villageColors } from "../utils/SharedStyles";
import { clog } from "../utils/Log";
import cross from "../assets/cross.png";
import bell from "../assets/bell.png";
import noBell from "../assets/noBell.png";
import arrow from "../assets/arrow.png";
import { registerAndUpdateNotificationToken } from "../utils/Notification";

const PermissionScreen = ({ route, navigation }) => {
  clog("navigation is in user details ", navigation);
  console.log("route in permission is ", route);
  let myContext = useContext(AppContext);
  clog("app context is", myContext);

  const appState = useRef(AppState.currentState);

  async function checkNotifications() {
    let askForPermission = await registerAndUpdateNotificationToken(myContext);
    if (!askForPermission) {
      console.log("HOORAY! Received permission");
    } else {
      console.log("SIGH! Still no permissions");
    }
    myContext["wentToSettings"] = false;
    navigation.replace(route?.params?.nextScreen);
  }

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        console.log("App has come to the foreground!");
        if (myContext.wentToSettings) {
          console.log("BACK FROM SETTINGS");
          checkNotifications();
        }
      }

      appState.current = nextAppState;
      clog("AppState", appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <SafeAreaView
      style={[
        styles.container,
        { backgroundColor: "rgba(0,0,0,0.4)" },
        {
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      ]}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            marginHorizontal: 12,
            borderRadius: 16,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Pressable
              onPress={() => {
                navigation.replace(route?.params?.nextScreen);
              }}
            >
              <View
                style={{
                  height: 40,
                  width: 40,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  source={cross}
                  style={{
                    height: 10,
                    width: 10,
                    tintColor: villageColors.Raven,
                  }}
                />
              </View>
            </Pressable>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{
                height: 29.63,
                width: 26.25,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 20,
              }}
            >
              <Image
                source={noBell}
                style={{
                  height: 29.63,
                  width: 26.25,
                }}
              />
            </View>
            <View
              style={{
                height: 14,
                width: 24,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 20,
              }}
            >
              <Image
                source={arrow}
                style={{
                  height: 14,
                  width: 24,
                }}
              />
            </View>
            <View
              style={{
                height: 30.87,
                width: 27.66,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                source={bell}
                style={{
                  height: 30.87,
                  width: 27.66,
                }}
              />
            </View>
          </View>
          <View
            style={{
              marginTop: 20,
              marginHorizontal: 0,
              //backgroundColor: "blue",
              width: "100%",
            }}
          >
            <Text
              style={[
                {
                  color: villageColors.Raven,
                  fontSize: 15,
                  fontWeight: "700",
                  textAlign: "center",
                },
              ]}
            >
              Stay updated with the best content
            </Text>
          </View>
          <View
            style={{
              marginTop: 20,
              marginHorizontal: 0,
              //backgroundColor: "blue",
              width: "100%",
            }}
          >
            <Text
              style={[
                {
                  color: villageColors.Raven,
                  fontSize: 15,
                  fontWeight: "400",
                  textAlign: "center",
                },
              ]}
            >
              You can grant Village notification permission from your device’s
              settings.
            </Text>
          </View>
          <Pressable
            onPress={() => {
              myContext["wentToSettings"] = true;
              Linking.openSettings();
            }}
            style={{ marginBottom: 24 }}
          >
            <View
              style={{
                backgroundColor: "black",
                borderRadius: 100,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingVertical: 8,
                paddingHorizontal: 12,
                marginTop: 16,
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "700",
                  color: "white",
                  //backgroundColor: "yellow",
                }}
              >
                Go to settings
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default PermissionScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  text: {
    fontSize: 15,
    marginBottom: 8,
  },
});
