import { Platform } from "react-native";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { saveExpoToken } from "../controllers/UserController";
import { clog } from "../utils/Log";
import { timeStamp } from "../utils/TimeStamp";
import { updateUserCounts } from "../src/graphql/custom";
import { API, graphqlOperation } from "aws-amplify";

export async function sendPushNotification(
  token,
  title,
  body,
  data,
) {
  let message = {
    to: "",
    sound: "default",
    "content-available": 1,
  };
  if (title) {
    message["title"] = title;
  }
  if (body) {
    message["body"] = body;
  }
  if (data) {
    message["data"] = data;
  }

  let tokens = token.split(",");
  // TODO(alpha): Throttle messaging when the number of recipients is high
  tokens.forEach((t) => {
    message.to = t;
    fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    });
  });
}

export async function registerForPushNotificationsAsync(myContext) {
  let token = null;
  let askForPermission = false;
  if (Device.isDevice) {
    if (Platform.OS != "web") {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      clog("FINAL NOTIFICATION STATUS", finalStatus);

      let currentTime = timeStamp();

      if (finalStatus !== "granted") {
        let newLastNotificationPermissionRequestTime = 0;
        let isUpdatedData = false;

        if (!myContext.notificationPermissionRequestCount) {
          myContext["notificationPermissionRequestCount"] = 0;
        }
        if (!myContext.notificationPermissionRequestCount) {
          myContext["lastNotificationPermissionRequestTime"] = currentTime;
        }

        if (myContext.notificationPermissionRequestCount === 0) {
          isUpdatedData = true;
          newLastNotificationPermissionRequestTime =
            myContext.notificationPermissionRequestCount + 1;

          askForPermission = true;
        } else {
          if (
            currentTime - myContext.lastNotificationPermissionRequestTime >
            30 * 86400
          ) {
            isUpdatedData = true;
            newLastNotificationPermissionRequestTime =
              myContext.notificationPermissionRequestCount + 1;

            askForPermission = true;
          } else {
            console.log(
              "WILL NOT ASK FOR NOTIFICATION PERMISSION BECAUSE ASKED",
              currentTime - myContext.lastNotificationPermissionRequestTime,
              "seconds ago"
            );
          }
        }

        if (isUpdatedData) {
          myContext["lastNotificationPermissionRequestTime"] = currentTime;
          myContext["notificationPermissionRequestCount"] =
            newLastNotificationPermissionRequestTime;

          if (myContext.Id) {
            let response = await API.graphql(
              graphqlOperation(updateUserCounts, {
                Id: myContext.Id,
                lastNotificationPermissionRequestTime: currentTime,
                notificationPermissionRequestCount:
                  newLastNotificationPermissionRequestTime,
              })
            );
            clog("RESPONSE", response);
          }
        }

        return { token: token, askForPermission: askForPermission };
      } else {
        if (myContext.notificationPermissionRequestCount > 0) {
          myContext["lastNotificationPermissionRequestTime"] = currentTime;
          myContext["notificationPermissionRequestCount"] = 0;

          if (myContext.Id) {
            let response = await API.graphql(
              graphqlOperation(updateUserCounts, {
                Id: myContext.Id,
                lastNotificationPermissionRequestTime: currentTime,
                notificationPermissionRequestCount: 0,
              })
            );
            clog("RESPONSE", response);
          }
        }
      }

      token = (
        await Notifications.getExpoPushTokenAsync("@shamimalpha/village-beta")
      ).data;
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "rgb(255,35,124)",
      });
    }
  } else {
    alert("Must use physical device for Push Notifications");
  }

  return { token: token, askForPermission: askForPermission };
}

export async function saveNotificationTokenIfNeededAsync(
  expoPushToken,
  myContext
) {
  if (expoPushToken && myContext?.Id) {
    if (myContext?.localToken != expoPushToken) {
      clog("WARNING: need to save expo token to backend", expoPushToken);
      saveExpoToken({
        Id: myContext.Id,
        localToken: expoPushToken,
        myContext: myContext,
        callback: ({ success, message, error }) => {
          if (!success) {
            clog("Failed to save expo token", message, error);
          } else {
            clog("Successfully saved expo token to backend");
          }
        },
      });
    } else {
      clog("ALREADY GOT EXPO TOKEN", myContext.token);
    }
  }
}

export async function registerAndUpdateNotificationToken(myContext) {
  let permission = false;
  try {
    if (!myContext?.localToken) {
      let { token, askForPermission } = await registerForPushNotificationsAsync(
        myContext
      );
      permission = askForPermission;
      if (token) {
        saveNotificationTokenIfNeededAsync(token, myContext);
      }
    }
  } catch (err) {
    console.log("Could not register for push notification", err);
  }
  return permission;
}
