import { clog } from "../utils/Log";

export function addIds(existingIds, newIds, weight = 1) {
  let existingCuratorIds = {};
  if (existingIds) {
    let tuples = existingIds.split(",");
    tuples.forEach((tuple) => {
      let parts = tuple.split(":");
      if (parts.length == 1) {
        existingCuratorIds[parts[0]] = 1;
      } else if (parts.length == 2) {
        existingCuratorIds[parts[0]] = Number(parts[1]);
      }
    });
  }
  newIds?.forEach((tuple) => {
    let parts = tuple.split(":");
    if (parts.length > 0) {
      let newId = parts[0];
      if (!existingCuratorIds[newId]) {
        existingCuratorIds[newId] = weight;
      } else {
        existingCuratorIds[newId] += weight;
      }
    }
  });
  let tuples = [];
  Object.keys(existingCuratorIds)
    .sort((a, b) => (a < b ? -1 : 1))
    .forEach((id) => {
      tuples.push(id + ":" + existingCuratorIds[id]);
    });
  let modifiedIds = tuples.join(",");
  clog("ADD IDS", existingIds, newIds, modifiedIds);
  return modifiedIds;
}

export function removeIds(existingIds, newIds, weight = 1) {
  let existingCuratorIds = {};
  if (existingIds) {
    let tuples = existingIds.split(",");
    tuples.forEach((tuple) => {
      let parts = tuple.split(":");
      if (parts.length == 1) {
        existingCuratorIds[parts[0]] = 1;
      } else if (parts.length == 2) {
        existingCuratorIds[parts[0]] = Number(parts[1]);
      }
    });
  }
  newIds?.forEach((tuple) => {
    let parts = tuple.split(":");
    if (parts.length > 0) {
      let newId = parts[0];
      if (existingCuratorIds[newId]) {
        if (existingCuratorIds[newId] == weight) {
          delete existingCuratorIds[newId];
        } else {
          existingCuratorIds[newId] -= weight;
        }
      }
    }
  });
  let tuples = [];
  Object.keys(existingCuratorIds)
    .sort((a, b) => (a < b ? -1 : 1))
    .forEach((id) => {
      tuples.push(id + ":" + existingCuratorIds[id]);
    });
  let modifiedIds = tuples.join(",");
  if (modifiedIds == null) {
    modifiedIds = "";
  }
  return modifiedIds;
}

export function generateIdStringFromMap(ids) {
  let tuples = [];
  Object.keys(ids)
    .sort((a, b) => (a < b ? -1 : 1))
    .forEach((id) => {
      tuples.push(id + ":" + ids[id]);
    });
  let modifiedIds = tuples.join(",");
  if (modifiedIds == null) {
    modifiedIds = "";
  }
  return modifiedIds;
}

export function generateMapFromIdStrings(idArray) {
  let allIds = {};
  idArray.forEach((idString) => {
    let ids = generateMapFromIdString(idString);
    Object.keys(ids).forEach((id) => {
      if (allIds[id]) {
        allIds[id] += ids[id];
      } else {
        allIds[id] = ids[id];
      }
    });
  });
  return allIds;
}

export function generateMapFromIdString(existingIds) {
  let ids = {};
  if (existingIds) {
    let tuples = existingIds.split(",");
    tuples.forEach((tuple) => {
      let parts = tuple.split(":");
      if (parts.length == 1) {
        ids[parts[0]] = 1;
      } else if (parts.length == 2) {
        ids[parts[0]] = Number(parts[1]);
      }
    });
  }
  return ids;
}

export function findMismatch(existingIds, replacementIds) {
  let oldIds = {};
  if (existingIds) {
    let tuples = existingIds.split(",");
    tuples.forEach((tuple) => {
      let parts = tuple.split(":");
      if (parts.length == 1) {
        oldIds[parts[0]] = 1;
      } else if (parts.length == 2) {
        oldIds[parts[0]] = Number(parts[1]);
      }
    });
  }
  let gone = {};
  let added = {};
  let mismatch = {};
  let someGone = false;
  let someAdded = false;
  let someMismatch = false;
  // find existing ids that are missing now
  Object.keys(oldIds).forEach((id) => {
    if (!replacementIds[id]) {
      gone[id] = oldIds[id];
      someGone = true;
    } else if (replacementIds[id] != oldIds[id]) {
      mismatch[id] = oldIds[id];
      someMismatch = true;
    }
  });
  Object.keys(replacementIds).forEach((id) => {
    if (!oldIds[id]) {
      added[id] = replacementIds[id];
      someAdded = true;
    }
  });
  if (someGone || someAdded || someMismatch) {
    console.log(
      "Found differences",
      "gone",
      gone,
      "added",
      added,
      "mismatch",
      mismatch
    );
  }
  return {
    match: !someGone && !someAdded && !someMismatch,
    gone: someGone,
    added: someAdded,
    mismatch: someMismatch,
    details: { gone: gone, added: added, mismatch: mismatch },
  };
}
