import React, { useEffect, useState } from "react";
import Constants from "expo-constants";
import * as Permissions from "expo-permissions";
import { Camera } from "expo-camera";
import { BarCodeScanner } from "expo-barcode-scanner";

import { View, Platform, StyleSheet } from "react-native";

import { clog } from "../utils/Log";

function QRScanner({ getter, setter, urlSetter }) {
  const hasCameraPermission = async () => {
    clog("will check media permissions");
    let permission = true;
    const { status } = await Camera.getCameraPermissionsAsync();
    if (status !== "granted") {
      if (Platform.OS === "ios" || Platform.OS == "android") {
        try {
          const { status } = await Camera.requestCameraPermissionsAsync();
          if (status !== "granted") {
            alert("Sorry, we need camera permissions to make this work!");
            permission = false;
          } else {
            clog("granted media permissions now!!");
            permission = true;
          }
        } catch (err) {
          console.log("permission check error");
          permission = false;
        }
      } else {
        clog("unknown permission", permission);
      }
    } else {
      clog("Media Permissions already granted");
      permission = true;
    }
    //permission = false;
    setter((oldValue) => {
      return { ...oldValue, hasPermission: permission };
    });
    setState((oldState) => {
      return { ...oldState, hasPermission: permission };
    });
  };

  useEffect(() => {
    hasCameraPermission();
  }, []);

  const [state, setState] = useState(getter());

  clog("has permission", getter().hasPermission, "scanned", getter().scanned);
  return (
    <View style={styles.container}>
      {getter().hasPermission && !getter().scanned && (
        <View style={styles.container}>
          <Camera
            type={Camera.Constants.Type.back}
            onBarCodeScanned={(...args) => {
              clog("camera scanned", args);
              const data = args[0].data;
              const type = args[0].type;
              setter((oldValue) => {
                return { ...oldValue, scanned: true, type: type, data: data };
              });
              if (data) {
                urlSetter(data);
              }
            }}
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            }}
            style={{ flex: 1 }}
          />
        </View>
      )}
    </View>
  );
}

export default QRScanner;

const styles = StyleSheet.create({
  container: {
    height: 200,
    width: 200,
    position: "relative",
    marginTop: 38,
  },
});
