import { API, graphqlOperation } from "aws-amplify";
import { listSources } from "../src/graphql/custom";
import { clog } from "../utils/Log";

export async function getSources({ myContext, callback }) {
  let status = { success: true };
  if (myContext.sources) {
    status["message"] = "reused from cache";
    status["sourceData"] = myContext.sources;
  } else {
    clog("will get source data");
    myContext["sources"] = {};
    try {
      let start = performance.now();
      let fetchContinue = true;
      let nextToken = null;
      do {
        const sourceData = await API.graphql(
          graphqlOperation(listSources, {
            numRequested: 1000,
            nextToken: nextToken,
          })
        );
        clog("sourceData:", sourceData);
        nextToken = sourceData?.data?.listSources?.nextToken;
        if (!nextToken) {
          fetchContinue = false;
        }
        sourceData.data.listSources.items.forEach((source) => {
          for (let index in source) {
            if (index.startsWith("num") && source[index] == null) {
              source[index] = 0;
            }
          }
          myContext.sources[source.Id] = source;
        });
      } while (fetchContinue);
      let fetchDone = performance.now();
      console.log("TIME: source data fetch", (fetchDone - start) / 1000);
      status["sourceData"] = myContext.sources;
      clog("ALL SOURCES", status.sourceData);
    } catch (err) {
      status.success = false;
      status["message"] = "failed to fetch source data";
      status["error"] = err;
      console.log("error fetching source data...", err);
    }
  }
  if (callback) {
    callback(status);
  }
  return status;
}
