import React from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import { clog } from "../utils/Log";

const FeedbackCard = ({ item, myContext, colors, navigation }) => {
  if (item == null) {
    return <View></View>;
  }
  console.log("will print feedback card for", item);
  return (
    <View
      style={[styles.item, { backgroundColor: colors.secondaryBackground }]}
    >
      <View style={{ flex: 1 }}>
        <Pressable
          onPress={() => {
            navigation.push("UserDetails", {
              Id: item?.userId,
              handle: item?.user?.handle,
              fromList: true,
            });
          }}
        >
          <View>
            <Text
              style={[styles.name, { color: colors.primaryText }]}
              numberOfLines={2}
              ellipsizeMode="tail"
            >
              {item?.user?.name}
            </Text>
          </View>
        </Pressable>
        <Text
          style={[styles.description, { color: colors.primaryText }]}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {item?.createdAt}
        </Text>
        <Text
          style={[styles.description, { color: colors.primaryText }]}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {item?.note}
        </Text>
      </View>
    </View>
  );
};

export default FeedbackCard;

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    fontSize: 16,
  },
  description: {
    fontSize: 10,
    width: 150,
  },
});
