import { API, graphqlOperation } from "aws-amplify";
import {
  userDetailsById2,
  actionsOnUser,
  followingUsers,
  visitsByUser,
  listConfigs,
  objectsActedOn,
  updateUserCounts,
  listTopics,
} from "../src/graphql/custom";

import { initialUser } from "./InitialValues";
import { batchPresign } from "./Photo.js";
import { clog, elog } from "../utils/Log";
import * as Analytics from "expo-firebase-analytics";
import { registerAndUpdateNotificationToken } from "../utils/Notification";
import { timeStamp } from "../utils/TimeStamp";
import { getMinimalUserByHandle } from "../controllers/UserController";
import {
  loadDataFromLocalStorage,
  saveDataToLocalStorage,
  removeDataFromLocalStorage,
} from "../utils/DataStorage";
import { Platform } from "react-native";

let objectMap = {
  User: "user",
  List: "list",
  Topic: "topic",
  Pin: "pin",
  Url: "url",
  Comment: "comment",
  Source: "source",
  Group: "group",
  Experiment: "experiment",
};

export async function getUserData(handle, presignedUrls = {}, topics = null) {
  let success = false;
  let fetchedData = { ...initialUser };
  let payloads = [];
  let beforeLookup = performance.now();
  let keyName = "savedUserIds";
  //await removeDataFromLocalStorage(keyName);
  const userIdsString = await loadDataFromLocalStorage(keyName);
  console.log("GOT FROM STORAGE", userIdsString);
  const userIds = userIdsString != null ? JSON.parse(userIdsString) : null;
  console.log("userIds", userIds);
  let userId = null;
  let avatar = null;
  if (userIds?.[handle]) {
    const { Id, avatar } = userIds[handle];
    if (Id) {
      userId = Id;
    }
    if (avatar) {
      let mayNeedLookUp = [avatar];
      batchPresign(mayNeedLookUp, presignedUrls, null);
    }
  }
  if (!userId) {
    try {
      let user = await getMinimalUserByHandle(handle);
      userId = user?.Id;
      avatar = user?.avatar;
      console.log("selected user id", userId);

      if (userId) {
        // create payload
        let payload = {};
        if (userIds) {
          payload = { ...userIds };
        }
        payload[handle] = {
          Id: userId,
          avatar: avatar,
        };
        console.log("WILL SAVE TO LOCAL", payload);
        await saveDataToLocalStorage(keyName, JSON.stringify(payload));
      }
    } catch (err) {
      console.log("ERROR: cannot fetch minimal user data", err);
    }
  }
  let lookupDone = performance.now();
  console.log("Lookup latency", (lookupDone - beforeLookup) / 1000);

  try {
    let promises = [];

    let start = performance.now();
    if (userId) {
      payloads.push({ userDetails2: { Id: userId } });
      promises.push(
        API.graphql(graphqlOperation(userDetailsById2, { Id: userId }))
      );
    }
    promises.push(
      API.graphql(
        graphqlOperation(listConfigs, { nextToken: null, numRequested: 5000 })
      )
    );
    if (!topics) {
      promises.push(
        API.graphql(graphqlOperation(listTopics, { numRequested: 1000 }))
      );
    }
    let responses = await Promise.all(promises);
    if (fetchedData.presignedUrls) {
      presignedUrls = fetchedData.presignedUrls;
    }
    let userData = null;
    let configData = null;
    let topicData = null;
    clog("RESPONSES", responses);
    responses.forEach((response) => {
      if (response?.data?.getUser) {
        userData = response;
      }
      if (response?.data?.listConfigs?.items) {
        configData = response;
      }
      if (response?.data?.listTopics?.items) {
        topicData = response;
      }
    });
    if (userData?.data?.getUser) {
      const data = userData.data.getUser;
      clog("retrieved data", data);
      const Id = data?.Id;

      if (data?.avatar) {
        let mayNeedLookUp = [data.avatar];
        await batchPresign(mayNeedLookUp, presignedUrls, null);
      }
      for (let index in data) {
        if (index.startsWith("num") && data[index] == null) {
          data[index] = 0;
        }
      }
      let signDone = performance.now();
      console.log("TIME: user sign", (signDone - fetchDone) / 1000);

      for (let index in data) {
        fetchedData[index] = data[index];
      }
      /*let activities = [];
      data?.actionsByUser?.items
        ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        ?.forEach((element) => {
          activities.push(element);
        });*/
      fetchedData[Id] = Id;
      fetchedData["handle"] = handle;
      fetchedData["presignedUrls"] = presignedUrls;
      await fetchVisitsIfNecessary(fetchedData);
      success = true;
      Analytics.setUserId(Id);
      Analytics.setUserProperties({
        handle: handle,
      });
    } else {
      fetchedData = { ...initialUser, handle: handle };
      success = true;
    }

    if (!topics) {
      topics = {};
    }
    topicData?.data?.listTopics?.items.forEach((topic) => {
      ////clog("topic handling now ", topic);
      for (let index in topic) {
        if (index.startsWith("num") && topic[index] == null) {
          topic[index] = 0;
        }
      }
      topic["numChildren"] = 0;
      topics[topic.Id] = topic;
    });
    fetchedData["topics"] = topics;

    payloads.push({ fetchDone: { fetched: true } });
    let fetchDone = performance.now();
    console.log("TIME: user fetch data", (fetchDone - start) / 1000);
    let config = {};
    if (configData?.data?.listConfigs?.items?.length > 0) {
      const data = configData?.data?.listConfigs?.items;
      data.forEach((c) => {
        if (c.type == "Integer" || c.type == "Float") {
          config[c.name] = Number(c.value);
        } else if (c.type == "Boolean") {
          config[c.name] = c.value == "true" ? true : false;
        } else {
          config[c.name] = c.value;
        }
      });
    }
    clog("CONFIG FROM CLOUD", config);
    clog("LOCAL CONFIG", fetchedData?.config);
    if (fetchedData?.config) {
      fetchedData["config"] = { ...fetchedData.config, ...config };
    } else {
      fetchedData["config"] = { ...config };
    }
    clog("UPDATED CONFIG", fetchedData.config);
  } catch (err) {
    console.log("error fetching data...", err);
    elog(handle, "data fetcher", "data fetch", err.message, payloads);
  }
  // Generate topic child counters
  if (fetchedData?.topics) {
    let numChildren = {};
    Object.values(fetchedData.topics).forEach((topic) => {
      if (topic.parentTopicId && topic.parentTopicId != "Topic066") {
        numChildren[topic.parentTopicId] = numChildren[topic.parentTopicId]
          ? numChildren[topic.parentTopicId] + 1
          : 1;
      }
    });
    Object.keys(numChildren).forEach((tid) => {
      fetchedData.topics[tid].numChildren = numChildren[tid];
    });
  }
  return { success: success, data: fetchedData };
}

export async function updateUserData(
  original,
  modifications,
  conclusion = null,
  onboarding = false
) {
  for (let index in modifications) {
    if (index != "version") {
      original[index] = modifications[index];
    }
  }
  let askForPermission = false;
  if (!onboarding) {
    askForPermission = await registerAndUpdateNotificationToken(original);
  }
  if (!original["version"]) {
    original["version"] = 1;
    clog("setting version to 1");
  }
  if (conclusion) {
    conclusion(askForPermission);
  }
}

export async function refreshUserData(original) {
  let { success, data } = await getUserData(
    original.handle,
    original.presignedUrls,
    original.topics
  );
  if (success) {
    await updateUserData(original, data);
  }
}

export function resetUserData(original) {
  for (let index in original) {
    delete original[index];
  }
}

export async function refreshConfig({ original, callback }) {
  console.log("WILL REFRESH CONFIG");
  try {
    let response = await API.graphql(
      graphqlOperation(listConfigs, { nextToken: null, numRequested: 5000 })
    );
    let configData = null;
    if (response?.data?.listConfigs?.items) {
      configData = response;
    }
    let config = {};
    if (configData?.data?.listConfigs?.items?.length > 0) {
      const data = configData?.data?.listConfigs?.items;
      data.forEach((c) => {
        if (c.type == "Integer" || c.type == "Float") {
          config[c.name] = Number(c.value);
        } else if (c.type == "Boolean") {
          config[c.name] = c.value == "true" ? true : false;
        } else {
          config[c.name] = c.value;
        }
      });
    }
    console.log("UPDATED CONFIG");
    if (original?.config) {
      original["config"] = { ...original.config, ...config };
    } else {
      original["config"] = { ...config };
    }
    clog("CONFIG", original?.config, original?.appVersion);

    if (Platform.OS !== "web") {
      let belowMinimumVersion = false;
      let hasUpdate = false;
      let isUpdatedData = false;

      let newAppUpgradeRecommendationCount = 0;
      let currentTime = timeStamp();

      const appUpgradeRecommendationCountString =
        await loadDataFromLocalStorage("appUpgradeRecommendationCount");
      const lastAppUpgradeRecommendationTimeString =
        await loadDataFromLocalStorage("lastAppUpgradeRecommendationTime");

      console.log(
        "GOT FROM STORAGE",
        appUpgradeRecommendationCountString,
        lastAppUpgradeRecommendationTimeString
      );
      const appUpgradeRecommendationCount =
        appUpgradeRecommendationCountString != null
          ? JSON.parse(appUpgradeRecommendationCountString)
          : 0;

      const lastAppUpgradeRecommendationTime =
        lastAppUpgradeRecommendationTimeString != null
          ? JSON.parse(lastAppUpgradeRecommendationTimeString)
          : currentTime;

      if (!original.lastAppUpgradeRecommendationTime) {
        original["lastAppUpgradeRecommendationTime"] =
          lastAppUpgradeRecommendationTime;
      }
      if (!original.appUpgradeRecommendationCount) {
        original["appUpgradeRecommendationCount"] =
          appUpgradeRecommendationCount;
      }

      if (original?.appVersion != original?.config?.prodAppVersion) {
        console.log(
          "App version mismatch",
          original?.appVersion,
          original?.config?.prodAppVersion
        );

        if (original?.appVersion && original?.config?.prodAppVersion) {
          if (
            original?.appVersion?.localeCompare(
              original?.config?.prodAppVersion
            ) < 0
          ) {
            if (original?.config?.minimumAppVersion) {
              if (
                original?.appVersion?.localeCompare(
                  original?.config?.minimumAppVersion
                ) < 0
              ) {
                hasUpdate = true;
                belowMinimumVersion = true;
                isUpdatedData = true;
                newAppUpgradeRecommendationCount = 0;

                console.log("Need to force upgrade to production version");
              } else {
                if (original.appUpgradeRecommendationCount === 0) {
                  newAppUpgradeRecommendationCount =
                    original.appUpgradeRecommendationCount + 1;

                  hasUpdate = true;
                  isUpdatedData = true;

                  console.log(
                    "Need to recommend upgrade to production version"
                  );
                } else {
                  if (
                    currentTime - original.lastAppUpgradeRecommendationTime >
                    30 * 86400
                  ) {
                    newAppUpgradeRecommendationCount =
                      original.appUpgradeRecommendationCount + 1;

                    hasUpdate = true;
                    isUpdatedData = true;

                    console.log(
                      "Need to recommend upgrade to production version"
                    );
                  }
                }
              }
            }
            callback({ hasUpdate, belowMinimumVersion });
          } else {
            isUpdatedData = true;
            newAppUpgradeRecommendationCount = 0;
          }
        }
      } else {
        if (original.appUpgradeRecommendationCount > 0) {
          newAppUpgradeRecommendationCount = 0;
          isUpdatedData = true;
        }
      }

      if (isUpdatedData) {
        original["appUpgradeRecommendationCount"] =
          newAppUpgradeRecommendationCount;
        original["lastAppUpgradeRecommendationTime"] = currentTime;

        if (original.Id) {
          let response = await API.graphql(
            graphqlOperation(updateUserCounts, {
              Id: original.Id,
              appUpgradeRecommendationCount: newAppUpgradeRecommendationCount,
              lastAppUpgradeRecommendationTime: currentTime,
            })
          );
          console.log("RESPONSE", response);
        }

        await saveDataToLocalStorage(
          "appUpgradeRecommendationCount",
          JSON.stringify(newAppUpgradeRecommendationCount)
        );
        await saveDataToLocalStorage(
          "lastAppUpgradeRecommendationTime",
          JSON.stringify(currentTime)
        );
      }
    }
  } catch (err) {
    console.log("ERROR: cannot fetch config data", err);
  }
}

export async function fetchFollowersIfNecessary(original) {
  let followers = {};
  if (
    !original.followerFetchTimestamp ||
    original.followerFetchTimestamp < timeStamp() - 1800
  ) {
    // need to fetch followers data
    clog("NEED TO FETCH FOLLOWERS");
    try {
      let start = performance.now();
      let fetchTimestamp = timeStamp();
      let promises = [];
      promises.push(
        API.graphql(
          graphqlOperation(followingUsers, {
            objectIdOperation: [original.Id, "Follow"].join(":"),
          })
        )
      );

      let responses = await Promise.all(promises);
      let fetchEnd = performance.now();
      clog("TIME= fetch", (fetchEnd - start) / 1000);
      start = performance.now();
      let followerResponse = null;
      responses.forEach((response) => {
        if (response?.data?.actionByObjectIdOperation?.items) {
          followerResponse = response?.data?.actionByObjectIdOperation?.items;
        }
      });

      if (followerResponse) {
        followerResponse.forEach((action) => {
          clog("FOLLOWING USER", action.actor);
          followers[action.actor.Id] = action.actor;
        });
        original["followers"] = followers;
        original["followerFetchTimestamp"] = fetchTimestamp;
        clog("UPDATED FOLLOWERS", followers);
      }
    } catch (err) {
      console.log("something went wrong", err);
    }
  } else {
    clog("DO NOT NEED TO FETCH FOLLOWERS");
  }
}

function populateVisits(visitList, config) {
  let visits = {};
  let longVisits = {};
  let views = {};
  let longViews = {};
  // Now convert visitList to visits and views
  visitList.forEach((v) => {
    if (v.type == null) {
      // visit
      if (v.urlId) {
        visits[v.urlId] = v.visitTS;
      }
      if (v.pinId) {
        visits[v.pinId] = v.visitTS;
      }
      if (v.duration >= config.longVisitThreshold) {
        if (v.urlId) {
          longVisits[v.urlId] = v.visitTS;
        }
        if (v.pinId) {
          longVisits[v.pinId] = v.visitTS;
        }
      }
    } else if (v.type == "View") {
      if (v.urlId) {
        views[v.urlId] = v.visitTS;
      }
      if (v.pinId) {
        views[v.pinId] = v.visitTS;
      }
      if (v.duration >= config.longViewThreshold) {
        if (v.urlId) {
          longViews[v.urlId] = v.visitTS;
        }
        if (v.pinId) {
          longViews[v.pinId] = v.visitTS;
        }
      }
    }
  });
  clog("visits", visits);
  clog("long visits", longVisits);
  clog("views", views);
  clog("long views", longViews);
  return {
    visits: visits,
    longVisits: longVisits,
    views: views,
    longViews: longViews,
  };
}

export async function fetchVisitsAndActionsForUser(userId, fetchedData) {
  let visitsContinue = true;
  let visitsNextToken = null;
  let actionsContinue = {
    List: { Create: true },
    Topic: { Follow: true },
    User: { Follow: true, Block: true },
    Experiment: { Join: true },
  };

  let actionsNextToken = {};
  Object.keys(actionsContinue).forEach((k) => {
    actionsNextToken[k] = {};
    Object.keys(actionsContinue[k]).forEach((k2) => {
      actionsNextToken[k][k2] = null;
    });
  });
  let onActionsContinue = true;
  let onActionsNextToken = null;

  const shouldContinue = (list) => {
    let should = false;
    Object.keys(list).forEach((k) => {
      Object.keys(list[k]).forEach((k2) => {
        if (list[k][k2]) {
          should = true;
        }
      });
    });
    return should;
  };
  let directActions = [];
  let directOnActions = [];
  let visitList = [];
  try {
    let promises = [];
    let start = performance.now();
    do {
      let bstart = performance.now();
      promises = [];
      if (visitsContinue) {
        promises.push(
          API.graphql(
            graphqlOperation(visitsByUser, {
              Id: userId,
              nextToken: visitsNextToken,
              numRequested: 5000,
            })
          )
        );
      }
      if (shouldContinue(actionsContinue)) {
        Object.keys(actionsContinue).forEach((k) => {
          Object.keys(actionsContinue[k]).forEach((k2) => {
            if (actionsContinue[k][k2]) {
              promises.push(
                API.graphql(
                  graphqlOperation(objectsActedOn, {
                    actorIdOperationObjectType: [userId, k2, k].join(":"),
                    nextToken: actionsNextToken[k][k2],
                    limit: 5000,
                  })
                )
              );
            }
          });
        });
      }
      if (onActionsContinue) {
        promises.push(
          API.graphql(
            graphqlOperation(actionsOnUser, {
              Id: userId,
              nextToken: onActionsNextToken,
              limit: 1000,
            })
          )
        );
      }
      let responses = await Promise.all(promises);
      let bfinish = performance.now();
      console.log("TIME= batch time", (bfinish - bstart) / 1000);
      clog("RESPONSES", responses);
      actionsContinue = {};
      actionsNextToken = {};
      responses.forEach((response) => {
        clog("RESPONSE", response);
        if (response?.data?.visitByUser) {
          if (!response.data.visitByUser.nextToken) {
            visitsContinue = false;
            console.log("done fetching visits");
          } else {
            visitsNextToken = response.data.visitByUser.nextToken;
          }
          response.data.visitByUser.items.forEach((url) => {
            clog(url.uri, url.topicIds);
            visitList.push(url);
          });
        }
        if (response?.data?.actionByActorIdOperationObjectType) {
          let objectType = null;
          let operation = null;
          response.data.actionByActorIdOperationObjectType.items.forEach(
            (action) => {
              if (!objectType) {
                objectType = action.objectType;
                operation = action.operation;
              }
              directActions.push(action);
            }
          );
          if (response.data.actionByActorIdOperationObjectType.nextToken) {
            if (!actionsContinue[objectType]) {
              actionsContinue[objectType] = {};
            }
            actionsContinue[objectType][operation] = true;
            if (!actionsNextToken[objectType]) {
              actionsNextToken[objectType] = {};
            }
            actionsNextToken[objectType][operation] =
              response.data.actionByActorIdOperationObjectType.nextToken;
          } else {
            console.log(
              "done fetching actions by user:",
              objectType,
              operation
            );
          }
        }
        if (response?.data?.actionOnObject) {
          if (!response.data.actionOnObject.nextToken) {
            console.log("done fetching actions on user");
            onActionsContinue = false;
          } else {
            onActionsNextToken = response.data.actionOnObject.nextToken;
          }
          response.data.actionOnObject.items.forEach((action) => {
            directOnActions.push(action);
          });
        }
      });
      clog("VISITS", visitList);
    } while (
      visitsContinue ||
      shouldContinue(actionsContinue) ||
      onActionsContinue
    );

    let fetchEnd = performance.now();
    console.log("TIME= fetch visit", (fetchEnd - start) / 1000);
    clog("ACTIONS BY USER DIRECTLY", directActions);
    clog("ACTIONS ON USER DIRECTLY", directOnActions);
    {
      let actionsByUser = {};
      let actions = {};
      if (!fetchedData?.users) {
        fetchedData["users"] = {};
      }
      directActions.forEach((element) => {
        //data.actionsByUser.items.forEach((element) => {
        if (!actionsByUser[element.objectType]) {
          actionsByUser[element.objectType] = {};
        }
        if (!actionsByUser[element.objectType][element.operation]) {
          actionsByUser[element.objectType][element.operation] = {};
        }
        if (
          !actionsByUser[element.objectType][element.operation][
            element.objectId
          ]
        ) {
          actionsByUser[element.objectType][element.operation][
            element.objectId
          ] = {};
        }

        let payload = {};
        if (
          !(
            element.objectType == "Topic" ||
            element.objectType == "Comment" ||
            element.objectType == "Pin"
          )
        ) {
          payload = {
            object: element[objectMap[element.objectType]],
            createdAt: element.createdAt,
          };
        }
        actionsByUser[element.objectType][element.operation][element.objectId][
          element.Id
        ] = payload;
        if (element.objectType == "User") {
          if (!fetchedData.users[element.objectId]) {
            fetchedData.users[element.objectId] = element.user;
          }
        }
      });
      clog("ALREADY FETCHED USER DATA", { ...fetchedData?.users });
      clog("EVENTUALLY FETCHED USER DATA", fetchedData?.users);
      if (!fetchedData?.users) {
        fetchedData["users"] = {};
      }
      directOnActions.forEach((element) => {
        //data.actions.items.forEach((element) => {
        if (!actions[element.objectType]) {
          actions[element.objectType] = {};
        }
        if (!actions[element.objectType][element.operation]) {
          actions[element.objectType][element.operation] = {};
        }
        actions[element.objectType][element.operation][element.actor.Id] =
          element.actor;
        clog("FETCHED USER", element.actor);
        if (!fetchedData.users[element.actor.Id]) {
          fetchedData.users[element.actor.Id] = element.actor;
        }
      });
      let activities = [];
      directActions
        ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        ?.forEach((element) => {
          activities.push(element);
        });
      fetchedData["actionsByUser"] = actionsByUser;
      fetchedData["actions"] = actions;
      fetchedData["activities"] = activities;
    }
    Object.keys(fetchedData?.actionsByUser).forEach((k) => {
      let key = k;
      Object.keys(fetchedData?.actionsByUser?.[key])?.forEach((k2) => {
        let key2 = k2;
        let values = Object.values(fetchedData?.actionsByUser?.[key]?.[key2]);
        console.log("actions by", key, key2, values?.length);
      });
    });
    Object.keys(fetchedData?.actions).forEach((k) => {
      let key = k;
      Object.keys(fetchedData?.actions?.[key])?.forEach((k2) => {
        let key2 = k2;
        let values = Object.values(fetchedData?.actions?.[key]?.[key2]);
        console.log("actions on", key, key2, values?.length);
      });
    });
    // Now convert visitList to visits and views
    return populateVisits(visitList, fetchedData.config);
  } catch (err) {
    console.log("ERROR: cannot visit data", err);
  }
}

export async function fetchVisitsForUser(Id, config) {
  let visitsContinue = true;
  let visitsNextToken = null;
  let promises = [];
  let visitList = [];
  try {
    let start = performance.now();
    do {
      promises = [];
      if (visitsContinue) {
        promises.push(
          API.graphql(
            graphqlOperation(visitsByUser, {
              Id: Id,
              nextToken: visitsNextToken,
              numRequested: 5000,
            })
          )
        );
      }
      let responses = await Promise.all(promises);
      //console.log("RESPONSES", responses);
      responses.forEach((response) => {
        clog("RESPONSE", response);
        if (response?.data?.visitByUser) {
          if (!response.data.visitByUser.nextToken) {
            visitsContinue = false;
          } else {
            visitsNextToken = response.data.visitByUser.nextToken;
          }
          response.data.visitByUser.items.forEach((url) => {
            clog(url.uri, url.topicIds);
            visitList.push(url);
          });
        }
      });
      clog("VISITS", visitList);
    } while (visitsContinue);
    let fetchEnd = performance.now();
    console.log("TIME= fetch visit", (fetchEnd - start) / 1000);
    // Now convert visitList to visits and views
    return populateVisits(visitList, config);
  } catch (err) {
    console.log("ERROR: cannot visit data", err);
  }
}

// TODO(alpha): See if we can fetch visits as part of user data fetch
// currently we don't have user Id, only handle.
export async function fetchVisitsIfNecessary(original) {
  if (
    !original.visitFetchTimestamp ||
    original.visitFetchTimestamp < timeStamp() - 86400
  ) {
    let fetchTimestamp = timeStamp();
    // Now convert visitList to visits and views
    let { visits, longVisits, views, longViews } =
      await fetchVisitsAndActionsForUser(original.Id, original);
    original["engagements"] = {
      visits: visits,
      longVisits: longVisits,
      views: views,
      longViews: longViews,
    };
    original["visitFetchTimestamp"] = fetchTimestamp;
  } else {
    clog("DO NOT NEED TO FETCH FOLLOWERS");
  }
}
