import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TextInput,
  Platform,
  Dimensions,
  Pressable,
  AppState,
  SafeAreaView,
} from "react-native";
import { ShareMenuReactView } from "react-native-share-menu";
import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";
import CustomButton from "../components/CustomButton";
import cross from "../assets/cross.png";
import sharenote from "../assets/sharenote.png";
import config from "../src/aws-exports";
import { createPin } from "../utils/CreatePin";
import { populateMetadata } from "../utils/PopulateMetadata";
import { generateUniqueId } from "../utils/Id";
import { clog } from "../utils/Log";
import { villageColors } from "../utils/SharedStyles";
import { getListByCuratorIdAndListNameMinimal } from "../src/graphql/custom";
import { colors } from "react-native-elements";
import { uploadPhoto } from "../utils/Photo";
import { getMinimalUserByHandle } from "../controllers/UserController";

Amplify.configure({
  ...config,
  Analytics: {
    disabled: true,
  },
});

const ShareScreen = ({ navigation, route, username, loading }) => {
  const MAX_LENGTH_NOTE = 250;

  const sharedExtraData = route?.params?.sharedExtraData;

  const [sharedData, setSharedData] = useState("");
  const [note, setNote] = useState("");

  const [loadingData, setLoadingData] = useState(false);

  const [urlId, setUrlId] = useState(generateUniqueId());
  const [title, setTitle] = useState("");
  const [snippet, setSnippet] = useState("");
  const [photo, setPhoto] = useState("");
  const [type, setType] = useState("");
  const [author, setAuthor] = useState("");
  const [metadataAvailable, setMetadataAvailable] = useState(false);

  const [statusVisible, setStatusVisible] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [urlAlertVisible, setUrlAlertVisible] = useState(false);

  const [list, setList] = useState();
  const [userData, setUserData] = useState();

  let listNumPins = null;
  let topicsOfList = null;

  useEffect(() => {
    if (sharedExtraData) {
      const url = findUrl(sharedExtraData);
      if (!url) {
        setUrlAlertVisible(true);
      } else {
        setSharedData(url);
        fetchText(url);
      }
    } else if (!sharedExtraData && Platform.OS === "ios") {
      ShareMenuReactView.data()
        .then(({ data: sharedData }) => {
          const url = findUrliOS(sharedData);
          if (!url) {
            setUrlAlertVisible(true);
          } else {
            setSharedData(url);
            fetchText(url);
          }
        })
        .catch((error) => clog("Error:", error));
    }
  }, []);

  useEffect(() => {
    if (Platform.OS === "ios") {
      // Listen for AppState memoryWarning event
      AppState.addEventListener("memoryWarning", (state) => {
        console.warn("Triggered memoryWarning. Initiating continueInApp");
        // call callback on warningEvent triggered

        ShareMenuReactView.data()
          .then(({ data: sharedData }) => {
            const url = findUrliOS(sharedData);
            console.warn({ url });
            ShareMenuReactView.continueInApp({ sharedData: url });
          })
          .catch((error) => clog("Error:", error));
      });

      return () => {
        AppState.remove("memoryWarning", () => {});
      };
    }
  }, []);

  useEffect(() => {
    getData();
  }, [username, loading]);

  const findUrliOS = (data: Array) => {
    let url = null;

    data.forEach((item) => {
      if (item.mimeType === "text/plain") {
        url = item.data.match(/\bhttps?:\/\/\S+/gi);
      }
    });

    return url ? url[0] : null;
  };

  const findUrl = (item) => {
    let url = null;

    if (Platform.OS === "ios") {
      url = item.extraData.sharedData.match(/\bhttps?:\/\/\S+/gi);
    } else if (item.mimeType === "text/plain") {
      url = item.data.match(/\bhttps?:\/\/\S+/gi);
    }

    return url ? url[0] : url;
  };

  const showStatusMessage = (message) => {
    setStatusMessage(message);
    setStatusVisible(true);
  };

  async function loadData(username) {
    let user = await getMinimalUserByHandle(handle);
    const curatorId = user?.Id;
    const listName = "Saved";

    setUserData({ ...user });
    const listData = await API.graphql(
      graphqlOperation(getListByCuratorIdAndListNameMinimal, {
        curatorId: curatorId,
        listName: listName,
      })
    );

    setList(listData?.data?.listByListNameCuratorId?.items?.[0]);
  }

  async function getData() {
    setLoadingData(true);
    try {
      if (Platform.OS === "ios" && !sharedExtraData) {
        if (username && !loading) {
          await loadData(username);
        }
      } else if (
        Platform.OS === "android" ||
        (Platform.OS === "ios" && sharedExtraData)
      ) {
        const user = await Auth.currentAuthenticatedUser().catch((error) =>
          console.log(error)
        );

        await loadData(user.username);
      }
    } catch (err) {
      console.log("ERROR:", err);
    }
    setLoadingData(false);
  }

  const fetchText = async (text) => {
    try {
      if (text != null && text != "") {
        const hostPattern = /http.*\/\/((www\.)?([^\/]*))/;
        let matches = text.match(hostPattern);
        if (matches) {
          populateMetadata(
            text,
            setTitle,
            setSnippet,
            setType,
            setPhoto,
            setAuthor,
            setMetadataAvailable
          );
        }
      }
    } catch (error) {
      clog("Error:", error);
    }
  };

  const createAPin = async () => {
    await createPin({
      myContext: userData,
      urlId: urlId,
      url: sharedData,
      content: note,
      title: title,
      type: type,
      author: author,
      snippet: snippet,
      photo: photo,
      curatorId: userData.Id,
      listId: list.Id,
      listNumPins: listNumPins,
      oldList: list,
      topicsOfList: topicsOfList,
      topicMap: {},
      topicOptions: null,
      uploadPhoto: (prefix, imageURI) => uploadPhoto(prefix, imageURI),
      callback: ({ success, message, error }) => {
        clog("BACK FROM PINNING", success, message, error);
        setLoadingData(false);
        if (success) {
          !sharedExtraData
            ? ShareMenuReactView.dismissExtension()
            : navigation.goBack();
        } else {
          showStatusMessage(<Message message={"Something went wrong"} />);
          clog("MESSAGE", message);
        }
      },
    });
  };

  const onPin = async () => {
    setLoadingData(true);
    createAPin().catch(() => setLoadingData(false));
  };

  const getRemainderOfCharacters = () => {
    return MAX_LENGTH_NOTE - note.length;
  };

  const Header = () => {
    return (
      <View style={styles.headerContainer}>
        <CustomButton
          icon={cross}
          imageStyle={[styles.crossButton, { tintColor: colors.primaryText }]}
          onPress={() => {
            !sharedExtraData
              ? ShareMenuReactView.dismissExtension()
              : navigation.pop();
          }}
        />

        <Text style={styles.textHeader}>{"Create a Pin"}</Text>

        <CustomButton
          title={"Pin"}
          style={[
            styles.pinButton,
            {
              backgroundColor:
                loadingData || loading
                  ? "rgba(122,60,227, 0.3)"
                  : villageColors.Button,
            },
          ]}
          titleStyle={
            loadingData || loading
              ? { color: "rgba(256, 256, 256, 0.3)" }
              : null
          }
          disabled={loadingData || loading}
          onPress={onPin}
        />
      </View>
    );
  };

  const Message = ({ message }) => {
    return (
      <View style={styles.messageContainer}>
        <Text style={styles.messageText}>{message}</Text>
      </View>
    );
  };

  const Alert = () => {
    return (
      <View
        style={[
          styles.alert,
          { backgroundColor: colors.primaryButtonBackground },
        ]}
      >
        <Pressable
          onPress={() => {
            setStatusVisible(false);
          }}
        >
          <View style={styles.alertContainer}>
            {statusMessage}
            <View style={{ marginLeft: 8 }}>
              <Image
                source={cross}
                style={[styles.imageAlert, { tintColor: colors.primaryText }]}
              />
            </View>
          </View>
        </Pressable>
      </View>
    );
  };

  const UrlAlert = () => {
    return (
      <View style={styles.containerAlert}>
        <View style={styles.notificationContainer}>
          <View style={{ marginHorizontal: 10 }}>
            <Text style={styles.message} numberOfLines={2}>
              {"You can't share text and images"}
            </Text>
            <View style={{ height: 15 }} />
            <View style={styles.separatorHorizontal} />
            <View style={styles.buttonContainer}>
              <CustomButton
                title={"OK"}
                titleStyle={[styles.buttonTitle, { fontWeight: "500" }]}
                style={styles.button}
                onPress={() => {
                  !sharedExtraData
                    ? ShareMenuReactView.dismissExtension()
                    : navigation.pop();
                }}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <Header />

      <View style={{ marginTop: 80 }}>
        <View style={styles.sharenoteContainer}>
          <Image source={sharenote} style={styles.sharenoteImage} />
          <Text style={styles.sharenote}>{"Sharenote"}</Text>
        </View>

        <TextInput
          value={note}
          onChangeText={setNote}
          placeholder={"What is this url about?"}
          maxLength={MAX_LENGTH_NOTE}
          multiline
          style={styles.textInput}
          placeholderTextColor={colors.secondaryText}
          textAlignVertical={"top"}
        />
        <Text style={styles.number}>{getRemainderOfCharacters()}</Text>
      </View>

      {statusVisible && <Alert />}
      {urlAlertVisible && <UrlAlert />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgb(40,40,40)",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 25,
    marginHorizontal: 25,
  },
  textHeader: {
    left: 20,
    fontSize: 20,
    fontWeight: "800",
    color: villageColors.Text,
  },
  crossButton: {
    width: 15,
    height: 15,
  },
  pinButton: {
    width: 60,
    alignItems: "center",
    paddingVertical: 9,
    borderRadius: 16,
    backgroundColor: villageColors.Button,
  },
  sharenoteContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 20,
  },
  sharenote: {
    fontSize: 15,
    color: villageColors.Text,
  },
  sharenoteImage: {
    width: 17,
    height: 17,
    marginRight: 10,
  },
  textInput: {
    flexDirection: "row",
    flexWrap: "wrap",
    minHeight:
      Platform.OS === "ios" ? "25%" : Dimensions.get("window").height * 0.25,
    marginHorizontal: 20,
    marginVertical: 10,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 16,
    backgroundColor: "rgb(46,46,46)",
    color: villageColors.Text,
    fontSize: 16,
  },
  number: {
    alignSelf: "flex-end",
    marginRight: 25,
    color: villageColors.Text,
  },
  alert: {
    top: 5,
    position: "absolute",
    alignSelf: "center",
    zIndex: 3, // works on ios
    elevation: 3, // works on android
    paddingVertical: 10,
    backgroundColor: villageColors.Text,
    borderWidth: 0.5,
    borderColor: "black",
    borderRadius: 8,
  },
  alertContainer: {
    marginHorizontal: 15,
    flexDirection: "row",
    justifyContent: "center",
  },
  imageAlert: {
    width: 8,
    height: 8,
    tintColor: "black",
  },
  messageContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  messageText: {
    fontSize: 16,
    color: "black",
  },
  containerAlert: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    zIndex: 3, // works on ios
    elevation: 3, // works on android
    backgroundColor: "rgba(60, 60, 60, 0.8)",
  },
  notificationContainer: {
    alignSelf: "center",
    top: "40%",
    width: 280,
    paddingTop: 20,
    backgroundColor: "rgba(242, 242, 242, 0.8)",
    borderRadius: 14,
    flexDirection: "column",
    justifyContent: "center",
  },
  message: {
    fontSize: 16,
    textAlign: "center",
  },
  separatorHorizontal: {
    backgroundColor: "rgba(60, 60, 67, 0.36)",
    height: 0.5,
  },
  buttonTitle: {
    color: "rgb(0,122,255)",
    fontSize: 16,
  },
  button: {
    paddingVertical: 10,
  },
  buttonContainer: { flexDirection: "row", justifyContent: "center" },
});

export default ShareScreen;
