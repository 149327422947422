import { updateTopicUpdateRecommendationCounters } from "../controllers/UserController";
import { clog } from "./Log";
import { timeStamp } from "./TimeStamp";
import { evaluateExperience } from "./Tracker";

export function recommendTopicUpdate({
  myContext,
  setTopicPopUpMessage,
  showTopicPopUp,
}) {
  let migration = false;
  let lowQuality = false;
  if (!myContext.lastTopicUpdateTime) {
    console.log("Last topic update missing");
    if (
      !myContext.lastTopicUpdateRecommendationTime ||
      (myContext.lastTopicUpdateRecommendationTime &&
        timeStamp() > myContext.lastTopicUpdateRecommendationTime + 7 * 86400)
    ) {
      migration = true;
      setTopicPopUpMessage("We have introduced new topics! ");
    }
  }
  if (!migration) {
    lowQuality = isLowQualityExperience(myContext);
    if (lowQuality) {
      console.log("PATH 2");
      setTopicPopUpMessage(
        "Updating followed topics might improve your experience! "
      );
    }
  }
  if (migration || lowQuality) {
    showTopicPopUp();
    updateTopicUpdateRecommendationCounters(myContext, null);
  }
}

export function isLowQualityExperience(myContext) {
  let lowQuality = false;
  let { longVisit, totalVisit } = evaluateExperience(myContext);
  clog("LONG", longVisit, "TOTAL", totalVisit);
  let quality = 1;
  if (totalVisit) {
    quality = longVisit / totalVisit;
  }
  if (
    quality < myContext.config.qualityAseessmentThreshold &&
    (!myContext.lastTopicUpdateTime ||
      (timeStamp() > myContext.lastTopicUpdateTime + 7 * 86400 &&
        (!myContext.lastTopicUpdateRecommendationTime ||
          (myContext.lastTopicUpdateRecommendationTime &&
            timeStamp() >
              myContext.lastTopicUpdateRecommendationTime + 7 * 86400))))
  ) {
    lowQuality = true;
  }
  return lowQuality;
}
