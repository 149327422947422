export function randomFromId(Id) {
  let hash = 0;

  if (Id.length == 0) return hash;

  for (let i = 0; i < Id.length; i++) {
    let char = Id.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

export function photoIdFromId(Id) {
  let rand = randomFromId(Id);
  let id = rand % 1000;
  if (id < 0) {
    id = 1000 + id;
  }
  return id;
}
