import React, { useEffect, useState, useContext, useRef } from "react";
import { View, Pressable, Platform } from "react-native";

import AppContext from "../components/AppContext";
import { initialUser } from "../utils/InitialValues";
import UserList from "../components/UserList";
import LeftArrow from "../components/LeftArrow";
import { clog } from "../utils/Log";
import { getUserListData } from "../controllers/UserListController";
import { timeStamp } from "../utils/TimeStamp";
import { logEvent } from "../utils/LogEvent";
import { useTheme } from "../theme";

const UserListScreen = ({ route, navigation }) => {
  async function getData(Id, relationship) {
    clog("GET DATA WITH", Id, "and", relationship);
    getUserListData({
      Id: Id,
      relationship: relationship,
      myContext: myContext,
      callback: ({ success, message, error, total, users }) => {
        if (success) {
          setState((currentState) => {
            return {
              ...currentState,
              Id: Id,
              relationship: relationship,
              total: total,
              users: users,
            };
          });
        } else {
          clog(message);
        }
      },
    });
  }

  const addUserFollowed = (userId) => {
    setState((previousState) => {
      ////clog("updated state");
      return {
        ...previousState,
        actionsByUser: myContext.actionsByUser,
        numUserFollow: myContext.numUserFollow,
      };
    });
  };

  const removeUserFollowed = (userId) => {
    setState((previousState) => {
      clog("Updating for relationship", previousState.relationship);
      clog("ORIGINAL LIST", previousState.users);
      let newState = {
        ...previousState,
        actionsByUser: myContext.actionsByUser,
        numUserFollow: myContext.numUserFollow,
      };
      if (
        myContext.Id == previousState.Id &&
        previousState.relationship === "Following"
      ) {
        let usersCopy = [];
        previousState.users.forEach((element) => {
          if (element.Id !== userId) {
            usersCopy.push(element);
          }
        });
        newState["users"] = usersCopy;
      }
      return newState;
    });
  };

  async function dataRefresh() {
    clog("CLICKED ON TAB");
    // do something
    logEvent(route.params.origin + "_View", {
      userId: myContext?.Id,
      username: myContext?.handle,
      platform: Platform.OS,
      category: myContext.Id == route.params.Id ? "myprofile" : "otherprofile",
      targetuser: route.params.handle,
      appVersion: myContext.appVersion,
    });
    if (
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
      getData(stateRef.current.Id, stateRef.current.relationship);
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
    }
  }

  const initialState = {
    ...initialUser,
    relationship: "",
    users: [],
    addFunction: null,
    removeFunction: null,
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  const { colors } = useTheme();
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  React.useLayoutEffect(() => {
    let headerTitleValue = "";
    if (state.relationship == "Following") {
      headerTitleValue = "Following";
    } else {
      headerTitleValue = "Followers";
    }
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: headerTitleValue,
    });
  }, [navigation, state]);

  useEffect(() => {
    if (!state.Id || !state.relationship) {
      const {
        Id,
        relationship,
        name,
        handle,
        owner,
        refererActionId,
        hostUserId,
        origin,
      } = route.params;
      if (!Id || !relationship) {
        return <View></View>;
      }
      if (myContext && (!state.addFunction || !state.removeFunction)) {
        setState((prevState) => {
          return {
            ...prevState,
            Id: Id,
            relationship: relationship,
            name: name,
            handle: handle,
            owner: owner,
            origin: origin,
            refererActionId: refererActionId,
            hostUserId: hostUserId,
            addFunction: addUserFollowed,
            removeFunction: removeUserFollowed,
          };
        });
      }
      clog("DISPLAY", relationship, "of", Id);
      getData(Id, relationship).catch((err) => {
        console.log("error finding user: ", err);
      });
    }
  }, [state.Id, state.relationship]);

  clog("STATE IS", state);
  if (state.Id) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: colors.background,
        }}
      >
        <View
          style={{
            flex: 1,
            width: "100%",
            maxWidth: 756,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <UserList
            state={state}
            myContext={myContext}
            navigation={navigation}
            refererActionId={state.refererActionId}
            hostUserId={state.hostUserId}
            listStyle={{
              flex: 1,
              //paddingBottom: 54,
            }}
            origin={state.origin}
            colors={colors}
            showFollowButton={true}
          />
        </View>
      </View>
    );
  } else {
    return <View></View>;
  }
};

export default UserListScreen;
