import React, { useState, useContext, useRef } from "react";
import {
  Alert,
  StyleSheet,
  Text,
  View,
  ScrollView,
  SafeAreaView,
  Image,
  Platform,
  TouchableOpacity,
  Pressable,
  Dimensions,
  TextInput,
} from "react-native";

import AppContext from "../components/AppContext";
import rightArrow from "../assets/right-arrow.png";
import { Auth } from "aws-amplify";
import AwesomeAlert from "react-native-awesome-alerts";
import LeftArrow from "../components/LeftArrow";
import { CommonActions } from "@react-navigation/native";
import { resetUserData } from "../utils/DataFetcher";
import {
  listUsersForReindexing,
  updateUserForReindexing,
  updateUrlForReindexing,
  listUrls,
  listPins,
  actionListing,
  userExists,
  removeAction,
  pinListing,
  listListing,
  removeList,
  removePin,
  patchAction,
  actionListingByUser,
  actionListingByUserWithDetails,
  listUrlsWithPinsAndComments,
  updateUrlCounts,
  updatePinCounts,
  listUsersMinimal,
  updateUserCounts,
  updateListCounts,
  listUsersWithListsAndPins,
  listUsersWithListsAndTopics,
  operationsOnObjectByUser,
  getPinsAndCommentsForUrl,
  objectsActedOn,
  recommendationsForUser,
  notificationCandidates,
  getSingleUrl,
  addNotificationRecommendation,
  visitsByUserComplete,
  archivedVisitsByUserComplete,
  addVisit,
  removeVisit,
  addVisitToArchive,
  removeVisitFromArchive,
  listVisits,
  listVisitArchives,
  updateTopicCounts,
  addFeedback,
} from "../src/graphql/custom";
import { updateUser } from "../src/graphql/mutations";

import { API, graphqlOperation } from "aws-amplify";
import { timeStampFromTimeString } from "../utils/TimeStamp";
import {
  addIds,
  generateIdStringFromMap,
  generateMapFromIdString,
  generateMapFromIdStrings,
} from "../utils/IdList";
import { clog, elog } from "../utils/Log";
import { MyVillageTest } from "../test/MyVillageTest";
import { timeStamp } from "../utils/TimeStamp";
import { saveDataToSharedStorage } from "../utils/DataStorage";
import { useTheme } from "../theme";
import { migrateNotificationData } from "../controllers/NotificationController";
import { sendPushNotification } from "../utils/Notification";
import { generateUniqueId } from "../utils/Id";
import { fetchVisitsForUser } from "../utils/DataFetcher";
import { summarizeViews } from "../utils/Tracker";
import {
  computeTopicScore,
  generateVector,
} from "../controllers/CommonFeedController";
import { Modalize } from "react-native-modalize";
import { Portal } from "react-native-portalize";
import backArrow from "../assets/back-arrow.png";
import ColoredButton from "../components/ColoredButton";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from "../src/constants/urls";
import { useSafeAreaInsets } from "react-native-safe-area-context";

let assignments = {};

const SettingsScreen = ({ route, navigation }) => {
  async function resendUsersToIndex() {
    try {
      const usersData = await API.graphql(
        graphqlOperation(listUsersForReindexing, {})
      );
      clog("DATA", usersData);
      if (usersData.data.listUsers.items.length > 0) {
        const data = usersData.data.listUsers.items;
        console.log("DATA", data);
        if (data) {
          let promises = [];
          data.forEach((user) => {
            clog("USER", user);
            promises.push(
              API.graphql(
                graphqlOperation(updateUserForReindexing, {
                  Id: user.Id,
                  touch: (user.touch ? user.touch : 0) + 1,
                })
              )
            );
          });
          let responses = await Promise.all(promises);
          clog("RESPONSES", responses);
        }
      }
    } catch (err) {
      console.log("Could not get data", err);
      elog(
        myContext.Id,
        "settings",
        "data fetch resendUsersToIndex",
        err.message
      );
    }
  }

  async function resendUrlsToIndex() {
    try {
      const urlsData = await API.graphql(graphqlOperation(listUrls, {}));
      if (urlsData.data.listUrls.items.length > 0) {
        const data = urlsData.data.listUrls.items;
        clog("DATA", data);
        if (data) {
          let promises = [];
          data.forEach((url) => {
            clog("URL", url);
            promises.push(
              API.graphql(
                graphqlOperation(updateUrlForReindexing, {
                  Id: url.Id,
                  touch: (url.touch ? url.touch : 0) + 1,
                })
              )
            );
          });
          let responses = await Promise.all(promises);
          clog("RESPONSES", responses);
        }
      }
    } catch (err) {
      console.log("Could not get data");
      elog(
        myContext.Id,
        "settings",
        "data fetch resendUrlsToIndex",
        err.message
      );
    }
  }

  async function recomputeUrlCounters() {
    try {
      let nextToken = null;
      do {
        let start = performance.now();
        const urlsData = await API.graphql(
          graphqlOperation(listUrls, nextToken ? { nextToken: nextToken } : {})
        );
        let fetchDone = performance.now();
        console.log("TIME: fetch", (fetchDone - start) / 1000);
        nextToken = urlsData?.data?.listUrls?.nextToken;
        if (urlsData.data.listUrls.items.length > 0) {
          const data = urlsData.data.listUrls.items;
          clog("DATA", urlsData);
          if (data) {
            let promises = [];
            for (let i = 0; i < data.length && i < 10000; i++) {
              let url = data[i];
              console.log("URL", url);
              promises.push(
                API.graphql(
                  graphqlOperation(getPinsAndCommentsForUrl, {
                    Id: url.Id,
                  })
                )
              );
            }
            let responses = await Promise.all(promises);
            clog("RESPONSES", responses);
            promises = [];
            responses.forEach((response) => {
              let url = response?.data?.getUrl;
              if (url) {
                let numPins = 0;
                let numContributions = 0;
                let numLikes = 0;
                let numComments = 0;
                let numViews = 0;
                let curators = {};
                let commenters = {};
                let viewers = {};
                let likers = {};

                url?.pins?.items?.forEach((pin) => {
                  numPins++;
                  if (pin?.list?.name && pin.list.name != "Saved") {
                    numContributions++;
                  }
                  curators[pin.curatorId] = curators[pin.curatorId]
                    ? curators[pin.curatorId] + 1
                    : 1;
                  pin?.actions?.items?.forEach((action) => {
                    if (action.operation == "Like") {
                      likers[action.actorId] = likers[action.actorId]
                        ? likers[action.actorId] + 1
                        : 1;
                      numLikes++;
                    }
                    if (action.operation == "View") {
                      viewers[action.actorId] = viewers[action.actorId]
                        ? viewers[action.actorId] + 1
                        : 1;
                      numViews++;
                    }
                  });
                  pin?.comments?.items?.forEach((comment) => {
                    numComments++;
                    commenters[comment.curatorId] = commenters[
                      comment.curatorId
                    ]
                      ? commenters[comment.curatorId] + 1
                      : 1;
                    comment?.actions?.items?.forEach((action) => {
                      if (action.operation == "Like") {
                        likers[action.actorId] = likers[action.actorId]
                          ? likers[action.actorId] + 1
                          : 1;
                        numLikes++;
                      }
                    });
                  });
                });
                url?.comments?.items?.forEach((comment) => {
                  numComments++;
                  commenters[comment.curatorId] = commenters[comment.curatorId]
                    ? commenters[comment.curatorId] + 1
                    : 1;
                  comment?.actions?.items?.forEach((action) => {
                    if (action.operation == "Like") {
                      likers[action.actorId] = likers[action.actorId]
                        ? likers[action.actorId] + 1
                        : 1;
                      numLikes++;
                    }
                  });
                  comment?.comments?.items?.forEach((c2) => {
                    numComments++;
                    commenters[c2.curatorId] = commenters[c2.curatorId]
                      ? commenters[c2.curatorId] + 1
                      : 1;
                    c2?.actions?.items?.forEach((action) => {
                      if (action.operation == "Like") {
                        likers[action.actorId] = likers[action.actorId]
                          ? likers[action.actorId] + 1
                          : 1;
                        numLikes++;
                      }
                    });
                  });
                });
                console.log(
                  "view",
                  numViews,
                  url.numView,
                  viewers,
                  url.viewerIds,
                  "like",
                  numLikes,
                  url.numLike,
                  likers,
                  url.likerIds,
                  "comment",
                  numComments,
                  url.numComment,
                  commenters,
                  url.commenterIds,
                  "curate",
                  curators,
                  url.curatorIds,
                  url.Id,
                  url
                );
                /*let mismatch = findMismatch(url.likerIds, likers);
                if (!mismatch.match) {
                  console.log(
                    "MISMATCH FOR LIKERS",
                    mismatch,
                    url.likerIds,
                    likers,
                    url
                  );
                }
                mismatch = findMismatch(url.commenterIds, commenters);
                if (!mismatch.match) {
                  console.log(
                    "MISMATCH FOR COMMENTERS",
                    mismatch,
                    url.commenterIds,
                    commenters,
                    url
                  );
                }
                mismatch = findMismatch(url.curatorIds, curators);
                if (!mismatch.match) {
                  console.log(
                    "MISMATCH FOR CURATORS",
                    mismatch,
                    url.curatorIds,
                    curators,
                    url
                  );
                }*/
                promises.push(
                  API.graphql(
                    graphqlOperation(updateUrlCounts, {
                      Id: url.Id,
                      numPins: numPins,
                      numContribute: numContributions,
                      numView: numViews,
                      numLike: numLikes,
                      numComment: numComments,
                      curatorIds: generateIdStringFromMap(curators),
                      commenterIds: generateIdStringFromMap(commenters),
                      likerIds: generateIdStringFromMap(likers),
                      viewerIds: generateIdStringFromMap(viewers),
                    })
                  )
                );
              }
            });
            responses = await Promise.all(promises);
            console.log("UPDATE RESPONSES", responses);
          }
        }
      } while (nextToken);
    } catch (err) {
      console.log("Could not get data", err);
      elog(
        myContext.Id,
        "settings",
        "data fetch resendUrlsToIndex",
        err.message
      );
    }
  }

  async function findInconsistencies() {
    let users = {};
    try {
      const actionData = await API.graphql(graphqlOperation(actionListing, {}));
      clog("ACTION DATA", actionData);
      let promises = [];
      if (actionData?.data?.listActions?.items?.length > 0) {
        const data = actionData.data.listActions.items;
        clog("DATA", data);
        if (data) {
          data.forEach((action) => {
            clog("ACTION", action);
            if (!users[action.actorId]) {
              users[action.actorId] = {};
            }
            users[action.actorId][action.Id] = true;
            if (action.objectType == "User") {
              if (!users[action.objectId]) {
                users[action.objectId] = {};
              }
              users[action.objectId][action.Id] = true;
            }
          });
        }
      }
      Object.keys(users).forEach((Id) => {
        promises.push(
          API.graphql(
            graphqlOperation(userExists, {
              Id: Id,
            })
          )
        );
      });
      let goodUsers = {};
      let responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("RESPONSE", response);
        if (response?.data?.getUser?.Id) {
          goodUsers[response.data.getUser.Id] = response.data.getUser.handle;
        }
      });
      let badActions = {};
      Object.keys(users).forEach((Id) => {
        if (!goodUsers[Id]) {
          clog("CANNOT FIND USER", Id, users[Id]);
          Object.keys(users[Id]).forEach((actionId) => {
            badActions[actionId] = true;
          });
        }
      });
      promises = [];
      Object.keys(badActions).forEach((actionId) => {
        clog("BAD ACTION", actionId);
        promises.push(
          API.graphql(
            graphqlOperation(removeAction, {
              Id: actionId,
            })
          )
        );
      });
      responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("ACTION DELETION RESPONSE", response);
      });

      // Now go through all the pins
      users = {};
      badPins = {};
      const pinData = await API.graphql(graphqlOperation(pinListing, {}));
      clog("PIN DATA", pinData);
      promises = [];
      if (pinData?.data?.listPins?.items?.length > 0) {
        const data = pinData.data.listPins.items;
        clog("DATA", data);
        if (data) {
          data.forEach((pin) => {
            clog("PIN", pin);
            if (pin.curatorId) {
              if (!users[pin.curatorId]) {
                users[pin.curatorId] = {};
              }
              users[pin.curatorId][pin.Id] = true;
            }
            if (pin.creatorId) {
              if (!users[pin.creatorId]) {
                users[pin.creatorId] = {};
              }
              users[pin.creatorId][pin.Id] = true;
            }
            if (pin.actions.items.length == 0) {
              clog("PIN WITHOUT ACTION", pin);
              badPins[pin.Id] = true;
            }
          });
        }
      }
      promises = [];
      Object.keys(badPins).forEach((pinId) => {
        clog("BAD PIN", pinId);
        promises.push(
          API.graphql(
            graphqlOperation(removePin, {
              Id: pinId,
            })
          )
        );
      });
      responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("PIN DELETION RESPONSE", response);
      });
      badPins = {};

      Object.keys(users).forEach((Id) => {
        if (!goodUsers[Id]) {
          promises.push(
            API.graphql(
              graphqlOperation(userExists, {
                Id: Id,
              })
            )
          );
        }
      });
      responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("RESPONSE", response);
        if (response?.data?.getUser?.Id) {
          goodUsers[response.data.getUser.Id] = response.data.getUser.handle;
        }
      });
      let badPins = {};
      Object.keys(users).forEach((Id) => {
        if (!goodUsers[Id]) {
          clog("CANNOT FIND USER", Id, users[Id]);
          Object.keys(users[Id]).forEach((pinId) => {
            badPins[pinId] = true;
          });
        }
      });
      Object.keys(badPins).forEach((pinId) => {
        clog("BAD PIN", pinId);
      });

      // Now go through all the lists
      users = {};
      const listData = await API.graphql(graphqlOperation(listListing, {}));
      clog("LIST DATA", listData);
      promises = [];
      if (listData?.data?.listLists?.items?.length > 0) {
        const data = listData.data.listLists.items;
        clog("DATA", data);
        if (data) {
          data.forEach((list) => {
            clog("LIST", list);
            if (list.curatorId) {
              if (!users[list.curatorId]) {
                users[list.curatorId] = {};
              }
              users[list.curatorId][list.Id] = true;
            }
            if (list.creatorId) {
              if (!users[list.creatorId]) {
                users[list.creatorId] = {};
              }
              users[list.creatorId][list.Id] = true;
            }
          });
        }
      }
      Object.keys(users).forEach((Id) => {
        if (!goodUsers[Id]) {
          promises.push(
            API.graphql(
              graphqlOperation(userExists, {
                Id: Id,
              })
            )
          );
        }
      });
      responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("RESPONSE", response);
        if (response?.data?.getUser?.Id) {
          goodUsers[response.data.getUser.Id] = response.data.getUser.handle;
        }
      });
      let badLists = {};
      Object.keys(users).forEach((Id) => {
        if (!goodUsers[Id]) {
          clog("CANNOT FIND USER", Id, users[Id]);
          Object.keys(users[Id]).forEach((listId) => {
            badLists[listId] = true;
          });
        }
      });
      promises = [];
      Object.keys(badLists).forEach((listId) => {
        clog("BAD LIST", listId);
        promises.push(
          API.graphql(
            graphqlOperation(removeList, {
              Id: listId,
            })
          )
        );
      });
      responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("LIST DELETION RESPONSE", response);
      });
    } catch (err) {
      console.log("Could not get data", err);
      elog(
        myContext.Id,
        "settings",
        "data fetch findInconsistencies",
        err.message
      );
    }
  }

  async function patchData() {
    try {
      const actionData = await API.graphql(
        graphqlOperation(actionListingByUser, {})
      );
      clog("ACTION DATA", actionData);
      let promises = [];
      let counter = 0;
      if (actionData?.data?.listUsers?.items?.length > 0) {
        const data = actionData.data.listUsers.items;
        clog("DATA", data);
        clog("FOUND", data.length, "ACTIONS TO INSPECT");
        if (data) {
          data.forEach((user) => {
            if (user) {
              user?.actionsByUser?.items?.forEach((action) => {
                clog("ACTION", action);
                if (
                  !action.creationTS ||
                  !action.actorIdOperationObjectId ||
                  !action.actorIdOperationObjectType ||
                  !action.objectIdOperation ||
                  (!action.mutationActorId && !action.queryActorId)
                ) {
                  let payload = {
                    Id: action.Id,
                    actorIdOperationObjectType: [
                      action.actorId,
                      action.operation,
                      action.objectType,
                    ].join(":"),
                    actorIdOperationObjectId: [
                      action.actorId,
                      action.operation,
                      action.objectId,
                    ].join(":"),
                    objectIdOperation: [action.objectId, action.operation].join(
                      ":"
                    ),
                    creationTS: timeStampFromTimeString(action.createdAt),
                  };
                  if (action.operation == "View") {
                    payload["queryActorId"] = action.actorId;
                  } else {
                    payload["mutationActorId"] = action.actorId;
                  }
                  clog("PAYLOAD", payload);
                  promises.push(
                    API.graphql(graphqlOperation(patchAction, payload))
                  );
                  counter++;
                }
              });
            }
          });
        }
      }
      clog("FOUND", counter, "ACTIONS TO UPDATE");

      let responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("ACTION PATCH RESPONSE", response);
      });
    } catch (err) {
      console.log("FAILED TO PATCH", err);
      elog(myContext.Id, "settings", "data fetch patchData", err.message);
    }
  }

  async function patchIds() {
    let urls = [];
    let users = [];
    try {
      let nextToken = null;
      do {
        let start = performance.now();
        const urlsData = await API.graphql(
          graphqlOperation(
            listUrlsWithPinsAndComments,
            nextToken ? { nextToken: nextToken } : {}
          )
        );
        let fetchDone = performance.now();
        console.log("TIME: fetch", (fetchDone - start) / 1000);
        nextToken = urlsData?.data?.listUrls?.nextToken;
        if (urlsData.data.listUrls.items.length > 0) {
          const data = urlsData.data.listUrls.items;
          clog("DATA", urlsData);
          if (data) {
            for (let i = 0; i < data.length && i < 10000; i++) {
              let url = data[i];
              urls.push(url);
            }
          }
        }
      } while (nextToken);
    } catch (err) {
      console.log("Could not get data", err);
      elog(myContext.Id, "settings", "data fetch patchIds", err.message);
    }

    try {
      let nextToken = null;
      do {
        let start = performance.now();
        const usersData = await API.graphql(
          graphqlOperation(
            listUsersWithListsAndTopics,
            nextToken ? { nextToken: nextToken } : {}
          )
        );
        let fetchDone = performance.now();
        console.log("TIME: fetch", (fetchDone - start) / 1000);
        nextToken = usersData?.data?.listUsers?.nextToken;
        if (usersData.data.listUsers.items.length > 0) {
          const data = usersData.data.listUsers.items;
          clog("DATA", usersData);
          if (data) {
            for (let i = 0; i < data.length && i < 10000; i++) {
              let user = data[i];
              users.push(user);
            }
          }
        }
      } while (nextToken);
    } catch (err) {
      console.log("Could not get data", err);
      elog(myContext.Id, "settings", "data fetch patchIds", err.message);
    }
    try {
      let promises = [];
      let oldUserTopicIds = {};
      let oldListTopicIds = {};
      let handles = {};
      let listNames = {};
      users.forEach((user) => {
        oldUserTopicIds[user.Id] = user.topicIds;
        handles[user.Id] = user.handle;
        user.lists.items.forEach((list) => {
          oldListTopicIds[list.Id] = list.topicIds;
          listNames[list.Id] = [user.handle, list.name].join(":");
        });
      });
      promises = [];
      let userTopics = {};
      let listTopics = {};
      urls.forEach((url) => {
        let curators = [];
        let commenters = [];
        let likers = [];
        let lists = [];
        url.comments.items.forEach((comment) => {
          commenters.push(comment.curator.Id);
          comment.actions.items.forEach((action) => {
            if (action.operation == "Like") {
              likers.push(action.actorId);
            }
          });
        });
        url.pins.items.forEach((pin) => {
          curators.push(pin.curator.Id);
          lists.push(pin.listId);
          if (!userTopics[pin.curator.Id]) {
            userTopics[pin.curator.Id] = {};
          }
          if (!listTopics[pin.listId]) {
            listTopics[pin.listId] = {};
          }
          let tuples = url.topicIds.split(",");
          tuples.forEach((tuple) => {
            let values = tuple.split(":");
            if (values?.length == 2) {
              let topicId = values[0];
              //let count = values[1];
              if (parseInt(values[1]) != 1) {
                console.log("PROBLEMATIC TOPICIds", url.topicIds, "for", url);
              }
              let count = 1; // each topic should count as one unit
              if (!userTopics[pin.curator.Id][topicId]) {
                userTopics[pin.curator.Id][topicId] = parseInt(count);
              } else {
                userTopics[pin.curator.Id][topicId] =
                  parseInt(userTopics[pin.curator.Id][topicId]) +
                  parseInt(count);
              }
              if (!listTopics[pin.listId][topicId]) {
                listTopics[pin.listId][topicId] = parseInt(count);
              } else {
                listTopics[pin.listId][topicId] =
                  parseInt(listTopics[pin.listId][topicId]) + parseInt(count);
              }
            }
          });
          pin.actions.items.forEach((action) => {
            if (action.operation == "Like") {
              likers.push(action.actorId);
            }
          });
        });
        clog(
          "IDS",
          url.uri,
          "Curators",
          curators,
          "Commenters",
          commenters,
          "Likers",
          likers,
          "lists",
          lists
        );
        let oldCuratorIds = null;
        if (url.curatorIds) {
          oldCuratorIds = addIds(url.curatorIds, []);
        }
        let newCuratorIds = null;
        if (curators.length > 0) {
          newCuratorIds = addIds(null, curators);
        }
        if (oldCuratorIds != newCuratorIds) {
          clog("BADCURATOR: old", oldCuratorIds, "new", newCuratorIds);
        } else {
          clog("GOODCURATOR:");
        }
        let oldCommenterIds = null;
        if (url.commenterIds) {
          oldCommenterIds = addIds(url.commenterIds, []);
        }
        let newCommenterIds = null;
        if (commenters.length > 0) {
          newCommenterIds = addIds(null, commenters);
        }
        if (oldCommenterIds != newCommenterIds) {
          clog("BADCOMMENTER: old", oldCommenterIds, "new", newCommenterIds);
        } else {
          clog("GOODCOMMENTER:");
        }
        let oldLikerIds = null;
        if (url.likerIds) {
          oldLikerIds = addIds(url.likerIds, []);
        }
        let newLikerIds = null;
        if (likers.length > 0) {
          newLikerIds = addIds(null, likers);
        }
        if (oldLikerIds != newLikerIds) {
          clog("BADLIKER: old", oldLikerIds, "new", newLikerIds);
        } else {
          clog("GOODLIKER:");
        }
        let oldListIds = null;
        if (url.listIds) {
          oldListIds = addIds(url.listIds, []);
        }
        let newListIds = null;
        if (lists.length > 0) {
          newListIds = addIds(null, lists);
        }
        if (oldListIds != newListIds) {
          clog("BADLIST: old", oldListIds, "new", newListIds);
        } else {
          clog("GOODLIST:");
        }
        if (
          url.pins.items.length != url.numPins ||
          url.comments.items.length != url.numComment ||
          oldCuratorIds != newCuratorIds ||
          oldCommenterIds != newCommenterIds ||
          oldLikerIds != newLikerIds ||
          oldListIds != newListIds
        ) {
          let changes = { Id: url.Id };
          if (url.pins.items.length != url.numPins) {
            changes["numPins"] = url.pins.items.length;
          }
          if (url.comments.items.length != url.numComment) {
            changes["numComment"] = url.comments.items.length;
          }
          if (oldCuratorIds != newCuratorIds) {
            changes["curatorIds"] = newCuratorIds;
          }
          if (oldCommenterIds != newCommenterIds) {
            changes["commenterIds"] = newCommenterIds;
          }
          if (oldLikerIds != newLikerIds) {
            changes["likerIds"] = newLikerIds;
          }
          if (oldListIds != newListIds) {
            changes["listIds"] = newListIds;
          }
          clog("CHANGES:", changes);
          promises.push(
            API.graphql(graphqlOperation(updateUrlCounts, changes))
          );
        }
      });
      let newUserTopicIds = {};
      Object.keys(userTopics).forEach((userId) => {
        let userTopicIds = [];
        Object.keys(userTopics[userId])
          .sort((a, b) => (a < b ? -1 : 1))
          .forEach((topicId) => {
            userTopicIds.push([topicId, userTopics[userId][topicId]].join(":"));
          });
        newUserTopicIds[userId] = userTopicIds.join(",");
        clog("USER", userId, "TOPIC IDS", userTopicIds.join(","));
      });
      let newListTopicIds = {};
      Object.keys(listTopics).forEach((listId) => {
        let listTopicIds = [];
        Object.keys(listTopics[listId])
          .sort((a, b) => (a < b ? -1 : 1))
          .forEach((topicId) => {
            listTopicIds.push([topicId, listTopics[listId][topicId]].join(":"));
          });
        newListTopicIds[listId] = listTopicIds.join(",");
        //clog("LIST", listId, "TOPIC IDS", listTopicIds.join(","));
      });
      Object.keys(oldUserTopicIds).forEach((userId) => {
        if (
          (oldUserTopicIds[userId] || newUserTopicIds[userId]) &&
          oldUserTopicIds[userId] != newUserTopicIds[userId]
        ) {
          console.log(
            "USER MISMATCH",
            handles[userId],
            userId,
            "OLD",
            oldUserTopicIds[userId],
            "NEW",
            newUserTopicIds[userId]
          );
          let changes = { Id: userId };
          changes["topicIds"] = newUserTopicIds[userId]
            ? newUserTopicIds[userId]
            : "";
          clog("USER CHANGES", changes);
          promises.push(
            API.graphql(graphqlOperation(updateUserCounts, changes))
          );
        }
      });
      Object.keys(oldListTopicIds).forEach((listId) => {
        if (
          (oldListTopicIds[listId] || newListTopicIds[listId]) &&
          oldListTopicIds[listId] != newListTopicIds[listId]
        ) {
          console.log(
            "LIST MISMATCH",
            listNames[listId],
            listId,
            "OLD",
            oldListTopicIds[listId],
            "NEW",
            newListTopicIds[listId]
          );
          let changes = { Id: listId };
          changes["topicIds"] = newListTopicIds[listId]
            ? newListTopicIds[listId]
            : "";
          clog("USER CHANGES", changes);
          promises.push(
            API.graphql(graphqlOperation(updateListCounts, changes))
          );
        }
      });
      let responses = await Promise.all(promises);
      clog("RESPONSES", responses);
      console.log("DONE PATCHING");
    } catch (err) {
      console.log("FAILED TO PATCH", err);
      elog(myContext.Id, "settings", "data fetch patchIds", err.message);
    }
  }

  async function patchStats() {
    try {
      let users = {};
      const userData = await API.graphql(
        graphqlOperation(listUsersMinimal, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        users[user.Id] = user;
      });

      const actionData = await API.graphql(
        graphqlOperation(actionListingByUserWithDetails, {})
      );
      clog("ACTION DATA", actionData);
      let promises = [];
      let counter = 0;
      let profileViews = {};
      let collectionViews = {};
      let pinViews = {};
      let saveFromCollections = {};
      let saveFromCuration = {};
      let saveFromAction = {};
      let commentDirect = {};
      let commentOnCuration = {};
      let commentOnAction = {};
      let pinLikes = {};
      let commentLikes = {};
      let actions = {};
      let needRefererResolution = {};
      let needCommentRefererResolution = {};
      if (actionData?.data?.listUsers?.items?.length > 0) {
        const data = actionData.data.listUsers.items;
        clog("DATA", data);
        clog("FOUND", data.length, "ACTIONS TO INSPECT");
        if (data) {
          data.forEach((user) => {
            if (user) {
              user?.actionsByUser?.items?.forEach((action) => {
                actions[action.Id] = action;
                //clog("ACTION", action);
                if (action.operation == "View" && action.objectType == "User") {
                  if (!profileViews[action.objectId]) {
                    profileViews[action.objectId] = 1;
                  } else {
                    profileViews[action.objectId]++;
                  }
                  //handles[action.objectId] = action.user.handle;
                }
                if (
                  action.operation == "View" &&
                  action.objectType == "List" &&
                  action.list
                ) {
                  if (!collectionViews[action.list.curatorId]) {
                    collectionViews[action.list.curatorId] = 1;
                  } else {
                    collectionViews[action.list.curatorId]++;
                  }
                }
                if (
                  action.operation == "View" &&
                  action.objectType == "Pin" &&
                  action.pin
                ) {
                  //clog("PIN VIEW", action);
                  if (!pinViews[action.pin.curatorId]) {
                    pinViews[action.pin.curatorId] = 1;
                  } else {
                    pinViews[action.pin.curatorId]++;
                  }
                }
                if (
                  action.operation == "Create" &&
                  action.objectType == "Pin"
                ) {
                  if (action.hostUserId) {
                    //clog("PIN CREATE WITH HOST", action);
                    if (!saveFromCollections[action.hostUserId]) {
                      saveFromCollections[action.hostUserId] = 1;
                    } else {
                      saveFromCollections[action.hostUserId]++;
                    }
                  }
                  if (action.refererActionId) {
                    //clog("PIN CREATE WITH REFERER", action);
                    needRefererResolution[action.Id] = true;
                  }
                  if (action.hostUserId && action.refererActionId) {
                    clog("PIN WITH BOTH HOST AND REFERER", action);
                  }
                }
                if (
                  action.operation == "Create" &&
                  action.objectType == "Comment"
                ) {
                  if (action.comment.objectType != "Url") {
                    clog("UNUSUAL COMMENT ACTION", action);
                  }
                  if (action.hostUserId && action.refererActionId) {
                    clog("COMMENT WITH BOTH HOST AND REFERER", action);
                    needCommentRefererResolution[action.Id] = true;
                  } else if (action.hostUserId) {
                    //clog("COMMENT CREATE WITH HOST", action);
                    if (action.actorId != action.hostUserId) {
                      commentDirect[action.hostUserId] = commentDirect[
                        action.hostUserId
                      ]
                        ? commentDirect[action.hostUserId] + 1
                        : 1;
                    }
                  } else if (action.refererActionId) {
                    //clog("COMMENT CREATE WITH REFERER", action);
                    needCommentRefererResolution[action.Id] = true;
                  } else {
                    clog("COMMENT WITH NEITHER HOST NOR REFERER", action);
                  }
                }
                if (action.operation == "Like") {
                  //clog("LIKE ACTION", action);
                  if (
                    action.objectType != "Pin" &&
                    action.objectType != "Comment"
                  ) {
                    clog("UNUSUAL LIKE ACTION", action);
                  }
                  let target = action[action.objectType.toLowerCase()];
                  if (target && action.actorId != target.curatorId) {
                    if (action.objectType == "Pin") {
                      pinLikes[target.curatorId] = pinLikes[target.curatorId]
                        ? pinLikes[target.curatorId] + 1
                        : 1;
                    } else if (action.objectType == "Comment") {
                      commentLikes[target.curatorId] = commentLikes[
                        target.curatorId
                      ]
                        ? commentLikes[target.curatorId] + 1
                        : 1;
                    }
                  }
                }
              });
            }
          });
        }
      }

      Object.keys(needRefererResolution).forEach((Id) => {
        let referedAction = actions[actions[Id].refererActionId];
        //clog("ORIGINAL", actions[Id], "Refers", referedAction);
        if (referedAction?.actorId) {
          if (
            referedAction.operation == "Create" &&
            referedAction.objectType == "Pin"
          ) {
            saveFromCuration[referedAction.actorId] = saveFromCuration[
              referedAction.actorId
            ]
              ? saveFromCuration[referedAction.actorId] + 1
              : 1;
          } else {
            //clog("UNUSUAL REFERAL", actions[Id], "to", referedAction);
            saveFromAction[referedAction.actorId] = saveFromAction[
              referedAction.actorId
            ]
              ? saveFromAction[referedAction.actorId] + 1
              : 1;
          }
        }
      });

      Object.keys(needCommentRefererResolution).forEach((Id) => {
        let referedAction = actions[actions[Id].refererActionId];
        if (!actions[Id].hostUserId) {
          if (referedAction) {
            //clog("ORIGINAL", actions[Id], "Refers", referedAction);
            if (
              referedAction.operation == "Create" &&
              referedAction.objectType == "Pin"
            ) {
              commentOnCuration[referedAction.actorId] = commentOnCuration[
                referedAction.actorId
              ]
                ? commentOnCuration[referedAction.actorId] + 1
                : 1;
            } else {
              /*clog(
                "REFERED ACTION NON PIN CREATION",
                actions[Id],
                referedAction
              );*/
              if (actions[Id].actorId != referedAction.actorId) {
                commentOnAction[referedAction.actorId] = commentOnAction[
                  referedAction.actorId
                ]
                  ? commentOnAction[referedAction.actorId] + 1
                  : 1;
              }
            }
          } else {
            clog("ERROR: missing action", actions[Id]);
          }
        } else {
          if (referedAction) {
            if (actions[Id].hostUserId != referedAction.actorId) {
              clog("ORIGINAL", actions[Id], "Refers", referedAction);
              if (actions[Id].actorId != referedAction.actorId) {
                commentOnAction[referedAction.actorId] = commentOnAction[
                  referedAction.actorId
                ]
                  ? commentOnAction[referedAction.actorId] + 1
                  : 1;
              }
            } else {
              if (actions[Id].actorId != referedAction.actorId) {
                commentOnCuration[referedAction.actorId] = commentOnCuration[
                  referedAction.actorId
                ]
                  ? commentOnCuration[referedAction.actorId] + 1
                  : 1;
              }
            }
          } else {
            clog("ERROR: missing action", actions[Id]);
          }
        }
        /*if (referedAction?.actorId) {
          if (
            referedAction.operation == "Create" &&
            referedAction.objectType == "Pin"
          ) {
            saveFromCuration[referedAction.actorId] = saveFromCuration[
              referedAction.actorId
            ]
              ? saveFromCuration[referedAction.actorId] + 1
              : 1;
          } else {
            //clog("UNUSUAL REFERAL", actions[Id], "to", referedAction);
            saveFromAction[referedAction.actorId] = saveFromAction[
              referedAction.actorId
            ]
              ? saveFromAction[referedAction.actorId] + 1
              : 1;
          }
        }*/
      });

      Object.keys(users)
        .sort((a, b) => (profileViews[a] > profileViews[b] ? -1 : 1))
        .forEach((Id) => {
          clog(
            //Id,
            users[Id].handle +
              "," +
              "profile" +
              "," +
              (profileViews[Id] ? profileViews[Id] : 0) +
              "," +
              "collection" +
              "," +
              (collectionViews[Id] ? collectionViews[Id] : 0) +
              "," +
              "pin" +
              "," +
              (pinViews[Id] ? pinViews[Id] : 0) +
              "," +
              "saveDirect" +
              "," +
              (saveFromCollections[Id] ? saveFromCollections[Id] : 0) +
              "," +
              "saveFromCuration" +
              "," +
              (saveFromCuration[Id] ? saveFromCuration[Id] : 0) +
              "," +
              "saveFromAction" +
              "," +
              (saveFromAction[Id] ? saveFromAction[Id] : 0) +
              "," +
              "commentDirect" +
              "," +
              (commentDirect[Id] ? commentDirect[Id] : 0) +
              "," +
              "commentOnCuration" +
              "," +
              (commentOnCuration[Id] ? commentOnCuration[Id] : 0) +
              "," +
              "commentOnAction" +
              "," +
              (commentOnAction[Id] ? commentOnAction[Id] : 0) +
              "," +
              "pinLikes" +
              "," +
              (pinLikes[Id] ? pinLikes[Id] : 0) +
              "," +
              "commentLikes" +
              "," +
              (commentLikes[Id] ? commentLikes[Id] : 0)
          );
          let profileView = profileViews[Id] ? profileViews[Id] : 0;
          let viewRecursive =
            profileView +
            (collectionViews[Id] ? collectionViews[Id] : 0) +
            (pinViews[Id] ? pinViews[Id] : 0);
          let pinRecursive =
            (saveFromCollections[Id] ? saveFromCollections[Id] : 0) +
            (saveFromCuration[Id] ? saveFromCuration[Id] : 0) +
            (saveFromAction[Id] ? saveFromAction[Id] : 0);
          let commentRecursive =
            (commentDirect[Id] ? commentDirect[Id] : 0) +
            (commentOnCuration[Id] ? commentOnCuration[Id] : 0) +
            (commentOnAction[Id] ? commentOnAction[Id] : 0);
          let likeRecursive =
            (pinLikes[Id] ? pinLikes[Id] : 0) +
            (commentLikes[Id] ? commentLikes[Id] : 0);
          let changed = false;
          let payload = { Id: Id };
          if (users[Id].numView != profileView) {
            payload["numView"] = profileView;
            changed = true;
          }
          if (users[Id].numViewRecursive != viewRecursive) {
            payload["numViewRecursive"] = viewRecursive;
            changed = true;
          }
          if (users[Id].numPinRecursive != pinRecursive) {
            payload["numPinRecursive"] = pinRecursive;
            changed = true;
          }
          if (users[Id].numCommentRecursive != commentRecursive) {
            payload["numCommentRecursive"] = commentRecursive;
            changed = true;
          }
          if (users[Id].numLikeRecursive != likeRecursive) {
            payload["numLikeRecursive"] = likeRecursive;
            changed = true;
          }
          if (changed) {
            promises.push(
              API.graphql(graphqlOperation(updateUserCounts, payload))
            );
            clog("CHANGES:", payload, "from", users[Id]);
          }
        });
      let responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("STAT PATCH RESPONSE", response);
      });
    } catch (err) {
      console.log("FAILED TO PATCH", err);
      elog(myContext.Id, "settings", "data fetch patchStats", err.message);
    }
  }

  async function patchSeqs() {
    try {
      let users = {};
      const userData = await API.graphql(
        graphqlOperation(listUsersWithListsAndPins, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        clog("USER", user);
        let maxListSeq = 0;
        let prevListSeq = -1;
        user.lists.items
          .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
          .forEach((list) => {
            if (list.seq < prevListSeq) {
              clog(
                "ERROR: newer created list with lower seq",
                list.seq,
                "vs",
                prevListSeq,
                list,
                user
              );
            }
            prevListSeq = list.seq;
            if (list.seq > maxListSeq) {
              maxListSeq = list.seq;
            }
            let maxPinSeq = 0;
            let prevPinSeq = -1;
            list.pins.items
              .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
              .forEach((pin) => {
                if (pin.seq < prevPinSeq) {
                  clog(
                    "ERROR: newer created pin with lower seq",
                    pin.seq,
                    "vs",
                    prevPinSeq,
                    pin,
                    list,
                    user
                  );
                }
                prevPinSeq = pin.seq;
                if (pin.seq > maxPinSeq) {
                  maxPinSeq = pin.seq;
                }
              });
            if (maxPinSeq >= list.nextPinSeq) {
              clog(
                "ERROR: next pin seq too low",
                list.nextPinSeq,
                "vs",
                maxPinSeq,
                list
              );
            }
          });
        if (maxListSeq >= user.nextListSeq) {
          clog(
            "ERROR: next list seq too low",
            user.nextListSeq,
            "vs",
            maxListSeq,
            user
          );
        }
      });
    } catch (err) {
      console.log("Cannot fetch user data", err);
    }
  }

  async function activityStats() {
    try {
      let users = {};
      let promises = [];
      const userData = await API.graphql(
        graphqlOperation(listUsersMinimal, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        users[user.Id] = user;
        promises.push(
          API.graphql(
            graphqlOperation(operationsOnObjectByUser, {
              pattern: [user.Id, "View", "User"].join(":"),
            })
          )
        );
        promises.push(
          API.graphql(
            graphqlOperation(operationsOnObjectByUser, {
              pattern: [user.Id, "View", "List"].join(":"),
            })
          )
        );
        promises.push(
          API.graphql(
            graphqlOperation(operationsOnObjectByUser, {
              pattern: [user.Id, "View", "Pin"].join(":"),
            })
          )
        );
        promises.push(
          API.graphql(
            graphqlOperation(operationsOnObjectByUser, {
              pattern: [user.Id, "Create", "Pin"].join(":"),
            })
          )
        );
        promises.push(
          API.graphql(
            graphqlOperation(operationsOnObjectByUser, {
              pattern: [user.Id, "Follow", "User"].join(":"),
            })
          )
        );
      });
      let recents = {};
      let oldests = {};
      let currentTime = timeStamp();
      let responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("ACTIVITY STATS RESPONSE", response);
        response.data.actionByActorIdOperationObjectType.items.forEach(
          (action) => {
            if (!recents[action.actorId]) {
              recents[action.actorId] = action.creationTS;
            } else if (recents[action.actorId] < action.creationTS) {
              recents[action.actorId] = action.creationTS;
            }
            if (!oldests[action.actorId]) {
              oldests[action.actorId] = action.creationTS;
            } else if (oldests[action.actorId] > action.creationTS) {
              oldests[action.actorId] = action.creationTS;
            }
          }
        );
      });
      Object.keys(users).forEach((userId) => {
        if (recents[userId]) {
          clog(
            //"USER",
            users[userId].handle +
              "," +
              //"MOST RECENT",
              recents[userId] +
              "," +
              //"Elapsed",
              (currentTime - recents[userId]) / 86400 +
              "," +
              (recents[userId] - oldests[userId]) / 86400
          );
        }
      });
    } catch (err) {
      console.log("Cannot fetch data", err);
    }
  }

  async function fixNames() {
    try {
      let users = {};
      let promises = [];
      const userData = await API.graphql(
        graphqlOperation(listUsersMinimal, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        users[user.Id] = user;
        let firstName = null;
        let lastName = null;
        if (user.name) {
          let nameParts = user.name.split(" ");
          if (nameParts.length) {
            firstName = nameParts[0];
            if (nameParts.length > 2) {
              nameParts.shift();
              lastName = nameParts.join(" ");
            } else {
              lastName = nameParts[1];
            }
          }
        }
        if (firstName != user.firstName || lastName != user.lastName) {
          let updatedUser = { Id: user.Id };
          if (firstName != user.firstName) {
            updatedUser["firstName"] = firstName;
          }
          if (lastName != user.lastName) {
            updatedUser["lastName"] = lastName;
          }
          console.log(
            "Update name",
            user.name,
            "first",
            user.firstName,
            "last",
            user.lastName,
            "modified first",
            firstName,
            "modified last",
            lastName
          );
          promises.push(
            API.graphql(graphqlOperation(updateUser, { input: updatedUser }))
          );
        }
      });
      let responses = await Promise.all(promises);
      responses.forEach((response) => {
        clog("UPDATE RESPONSE", response);
      });
    } catch (err) {
      console.log("Cannot fetch data", err);
    }
  }

  async function migrateNotifications() {
    try {
      let users = [];
      const userData = await API.graphql(
        graphqlOperation(listUsersMinimal, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        users.push(user);
      });

      for (let i = 0; i < users.length; i++) {
        console.log(
          "processing",
          i + 1,
          " ",
          users.handle,
          "out of",
          users.length,
          "users"
        );
        await migrateNotificationData({
          userId: users[i].Id,
          //userId: "e7836f6b-927d-4fe2-8fb4-14808d234c9d",
          callback: ({
            success,
            message,
            error,
            actionsOfInterest,
            activities,
            notifications,
          }) => {
            if (!success) {
              clog(message, error);
            } else {
              console.log("SUCCESS!! Notification Migration");
              console.log("ACTIONS OF INTEREST", actionsOfInterest);
              console.log("NOTIFICATIONS", notifications);
            }
          },
        });
      }
    } catch (err) {
      console.log("SOMETHING WENT WRONG", err);
    }
  }

  async function updateUrlTopicAssignments() {
    let urlsContinue = true;
    let urlsNextToken = null;
    let urls = {};
    try {
      let promises = [];
      do {
        promises = [];
        if (urlsContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listUrls, {
                nextToken: urlsNextToken,
                numRequested: 5000,
              })
            )
          );
        }
        let responses = await Promise.all(promises);
        //console.log("RESPONSES", responses);
        responses.forEach((response) => {
          clog("RESPONSE", response);
          if (response?.data?.listUrls) {
            if (!response.data.listUrls.nextToken) {
              urlsContinue = false;
            } else {
              urlsNextToken = response.data.listUrls.nextToken;
            }
            response.data.listUrls.items.forEach((url) => {
              urls[url.Id] = url;
            });
          }
        });
      } while (urlsContinue);
    } catch (err) {
      console.log("cannot fetch users, urls and pins");
    }
    console.log("URLS", Object.keys(urls).length);
    let topicCounts = {};
    Object.keys(urls).forEach((u) => {
      let url = urls[u];
      let topicIds = "";
      if (assignments[u]) {
        //console.log(url, assignments[u]);
        topicIds = assignments[u];
      } else {
        topicIds = url.topicIds;
        //console.log("EXISTING TOPIC IDS", topicIds);
      }
      if (topicIds) {
        let urlTopics = generateMapFromIdString(topicIds);
        Object.keys(urlTopics).forEach((tid) => {
          topicCounts[tid] = topicCounts[tid] ? topicCounts[tid] + 1 : 1;
          if (!myContext.topics[tid]) {
            console.log("unknown topic id", tid, "in", topicIds, "for", u);
          }
        });
      }
    });

    let urlKeys = Object.keys(urls);
    for (let i = 0; i < urlKeys.length; i++) {
      let url = urls[urlKeys[i]];
      clog("CONSIDER", url, assignments[url.Id]);
      if (assignments[url.Id] && assignments[url.Id] != url.topicIds) {
        console.log("NEED TO UPDATE TOPIC IDS FOR", url.Id);
        let response = await API.graphql(
          graphqlOperation(updateUrlCounts, {
            Id: url.Id,
            topicIds: assignments[url.Id],
          })
        );
        console.log("RESPONSE", response);
      }
    }

    let topicKeys = Object.keys(topicCounts).sort((a, b) =>
      topicCounts[b] > topicCounts[a] ? -1 : 1
    );
    for (let i = 0; i < topicKeys.length; i++) {
      let tid = topicKeys[i];
      console.log(tid, topicCounts[tid], myContext.topics[tid].name);
      let response = await API.graphql(
        graphqlOperation(updateTopicCounts, {
          Id: tid,
          numUrls: topicCounts[tid],
        })
      );
      clog("RESPONSE", response);
    }
  }

  async function expandToSubtopics() {
    console.log("ASKED TO EXPAND SUB TOPICS");
    console.log("topics", myContext.topics);
    let usersContinue = true;
    let usersNextToken = null;
    let users = [];
    let numTopicFollow = {};
    Object.keys(myContext.topics).forEach((topicId) => {
      numTopicFollow[topicId] = 0;
    });
    try {
      let promises = [];
      do {
        promises = [];
        if (usersContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listUsersMinimal, {
                nextToken: usersNextToken,
              })
            )
          );
        }
        let responses = await Promise.all(promises);
        //console.log("RESPONSES", responses);
        responses.forEach((response) => {
          clog("RESPONSE", response);
          if (response?.data?.listUsers) {
            if (!response.data.listUsers.nextToken) {
              usersContinue = false;
            } else {
              usersNextToken = response.data.listUsers.nextToken;
            }
            response.data.listUsers.items.forEach((user) => {
              users.push(user);
            });
          }
        });
      } while (usersContinue);
      clog("USERS", users);
      let expansions = {
        Topic002: 1,
      };
      let removes = {
        Topic124: 1,
      };
      for (let i = 0; i < users.length; i++) {
        let user = users[i];
        let followedTopics = generateMapFromIdStrings([user.declaredTopicIds]);
        let needExpansion = false;
        let followedTopicKeys = Object.keys(followedTopics);
        console.log("FOLLOWED TOPICS", followedTopics);
        for (let j = 0; j < followedTopicKeys.length; j++) {
          let topicId = followedTopicKeys[j];
          let topic = myContext.topics[topicId];
          if (!topic) {
            console.log("NO TOPIC FOR ID", topicId);
          }
          if (expansions[topicId]) {
            needExpansion = true;
            break;
          }
        }
        if (needExpansion) {
          let expandedTopics = {};
          Object.keys(myContext.topics).forEach((topicId) => {
            let topic = myContext.topics[topicId];
            clog("\tconsider", topic.name);
            if (followedTopics[topicId]) {
              expandedTopics[topicId] = 1;
              clog("\t\tadd", topic.name);
            } else if (
              topic.parentTopicId &&
              followedTopics[topic.parentTopicId] &&
              expansions[topic.parentTopicId]
            ) {
              expandedTopics[topicId] = 1;
              clog(
                "\t\texpand with",
                topic.name,
                "because of",
                myContext.topics[topic.parentTopicId].name
              );
            }
          });
          Object.keys(removes).forEach((tid) => {
            if (expandedTopics[tid]) {
              console.log("REMOVE topic", tid);
              delete expandedTopics[tid];
            }
          });
          console.log("EXPANDED TOPICS", expandedTopics);
          let expandedTopicIds = addIds("", Object.keys(expandedTopics));
          console.log(
            "EXPANDED TOPIC IDS",
            expandedTopicIds,
            "from",
            user.declaredTopicIds
          );
          let response = await API.graphql(
            graphqlOperation(updateUserCounts, {
              Id: user.Id,
              declaredTopicIds: expandedTopicIds,
              numTopicFollow: expandedTopicIds?.length
                ? expandedTopicIds?.length
                : 0,
            })
          );
          console.log("user response", i, "out of", users.length, response);
          Object.keys(expandedTopics).forEach((topicId) => {
            numTopicFollow[topicId] += 1;
          });
        } else {
          Object.keys(followedTopics).forEach((topicId) => {
            numTopicFollow[topicId] += 1;
          });
        }
        console.log(
          "USER",
          user.handle,
          "need expansion",
          needExpansion,
          user.declaredTopicIds
        );
      }
      let topicKeys = Object.keys(numTopicFollow);
      for (let t = 0; t < topicKeys.length; t++) {
        let topicId = topicKeys[t];
        console.log(
          myContext.topics[topicId].name,
          numTopicFollow[topicId],
          topicId
        );
        let response = await API.graphql(
          graphqlOperation(updateTopicCounts, {
            Id: topicId,
            numFollow: numTopicFollow[topicId],
          })
        );
        console.log("RESPONSE", response);
      }
    } catch (err) {
      console.log("cannot fetch urls and pins", err);
    }
  }

  async function fixTopicIds() {
    console.log("ASKED TO FIX TOPIC IDS");
    let pinsContinue = true;
    let pinsNextToken = null;
    let urlsContinue = true;
    let urlsNextToken = null;
    let usersContinue = true;
    let usersNextToken = null;
    let urls = {};
    let pins = {};
    let users = [];
    try {
      let promises = [];
      do {
        promises = [];
        if (pinsContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listPins, {
                nextToken: pinsNextToken,
              })
            )
          );
        }
        if (urlsContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listUrls, {
                nextToken: urlsNextToken,
                numRequested: 5000,
              })
            )
          );
        }
        if (usersContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listUsersMinimal, {
                nextToken: usersNextToken,
              })
            )
          );
        }
        let responses = await Promise.all(promises);
        //console.log("RESPONSES", responses);
        responses.forEach((response) => {
          clog("RESPONSE", response);
          if (response?.data?.listPins) {
            if (!response.data.listPins.nextToken) {
              pinsContinue = false;
            } else {
              pinsNextToken = response.data.listPins.nextToken;
            }
            response.data.listPins.items.forEach((pin) => {
              pins[pin.Id] = pin;
            });
          }
          if (response?.data?.listUrls) {
            if (!response.data.listUrls.nextToken) {
              urlsContinue = false;
            } else {
              urlsNextToken = response.data.listUrls.nextToken;
            }
            response.data.listUrls.items.forEach((url) => {
              urls[url.Id] = url;
            });
          }
          if (response?.data?.listUsers) {
            if (!response.data.listUsers.nextToken) {
              usersContinue = false;
            } else {
              usersNextToken = response.data.listUsers.nextToken;
            }
            response.data.listUsers.items.forEach((user) => {
              users.push(user);
            });
          }
        });
      } while (pinsContinue || urlsContinue || usersContinue);
    } catch (err) {
      console.log("cannot fetch users, urls and pins");
    }
    console.log("PINS", pins);
    console.log("URLS", urls);
    let visitCounts = {};
    let longVisitCounts = {};
    let viewCounts = {};
    let longViewCounts = {};
    try {
      let promises = [];
      for (let i = 0; i < users.length; i++) {
        console.log(
          "processing",
          i + 1,
          " ",
          users[i].handle,
          "out of",
          users.length,
          "users",
          users[i]
        );
        let response = await API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [users[i].Id, "Follow", "Topic"].join(
              ":"
            ),
          })
        );
        let followedTopics = [];
        response?.data?.actionByActorIdOperationObjectType?.items?.forEach(
          (item) => {
            if (item.objectId) {
              followedTopics.push(item.objectId);
            }
          }
        );
        let declaredTopicIds = addIds("", followedTopics);

        response = await API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [users[i].Id, "View", "Pin"].join(":"),
          })
        );
        let consumedTopics = {};
        response?.data?.actionByActorIdOperationObjectType?.items?.forEach(
          (item) => {
            if (item?.pin?.url?.topicIds) {
              let urlTopics = generateMapFromIdString(item?.pin?.url?.topicIds);
              Object.keys(urlTopics)?.forEach((topic) => {
                if (consumedTopics[topic]) {
                  consumedTopics[topic] += urlTopics[topic];
                } else {
                  consumedTopics[topic] = urlTopics[topic];
                }
              });
            }
          }
        );
        let consumedTopicIds = generateIdStringFromMap(consumedTopics);

        response = await API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [users[i].Id, "Create", "Pin"].join(
              ":"
            ),
          })
        );
        let curatedTopics = {};
        response?.data?.actionByActorIdOperationObjectType?.items?.forEach(
          (item) => {
            if (item?.pin?.url?.topicIds) {
              let urlTopics = generateMapFromIdString(item?.pin?.url?.topicIds);
              Object.keys(urlTopics)?.forEach((topic) => {
                if (curatedTopics[topic]) {
                  curatedTopics[topic] += urlTopics[topic];
                } else {
                  curatedTopics[topic] = urlTopics[topic];
                }
              });
            }
          }
        );
        let curatedTopicIds = generateIdStringFromMap(curatedTopics);

        let engagedTopics = {};
        response = await API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [users[i].Id, "Like", "Pin"].join(":"),
          })
        );
        response?.data?.actionByActorIdOperationObjectType?.items?.forEach(
          (item) => {
            if (item?.pin?.url?.topicIds) {
              let urlTopics = generateMapFromIdString(item?.pin?.url?.topicIds);
              Object.keys(urlTopics)?.forEach((topic) => {
                if (engagedTopics[topic]) {
                  engagedTopics[topic] += urlTopics[topic];
                } else {
                  engagedTopics[topic] = urlTopics[topic];
                }
              });
            }
          }
        );

        response = await API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [users[i].Id, "Like", "Comment"].join(
              ":"
            ),
          })
        );
        response?.data?.actionByActorIdOperationObjectType?.items?.forEach(
          (item) => {
            if (item?.comment) {
              let topicIds = "";
              if (item?.comment?.url) {
                topicIds = item.comment.url.topicIds;
              } else if (item?.comment?.pin?.url) {
                topicIds = item.comment.pin.url.topicIds;
              } else if (item?.comment?.comment?.url) {
                topicIds = item.comment.comment.url.topicIds;
              } else {
                console.log("CANNOT LOCATE URL FOR", item);
              }
              if (topicIds) {
                let urlTopics = generateMapFromIdString(topicIds);
                Object.keys(urlTopics)?.forEach((topic) => {
                  if (engagedTopics[topic]) {
                    engagedTopics[topic] += urlTopics[topic];
                  } else {
                    engagedTopics[topic] = urlTopics[topic];
                  }
                });
              }
            }
          }
        );
        response = await API.graphql(
          graphqlOperation(objectsActedOn, {
            actorIdOperationObjectType: [users[i].Id, "Create", "Comment"].join(
              ":"
            ),
          })
        );
        response?.data?.actionByActorIdOperationObjectType?.items?.forEach(
          (item) => {
            if (item?.comment) {
              let topicIds = "";
              if (item?.comment?.url) {
                topicIds = item.comment.url.topicIds;
              } else if (item?.comment?.pin?.url) {
                topicIds = item.comment.pin.url.topicIds;
              } else if (item?.comment?.comment?.url) {
                topicIds = item.comment.comment.url.topicIds;
              } else {
                console.log("CANNOT LOCATE URL FOR", item);
              }
              if (topicIds) {
                let urlTopics = generateMapFromIdString(topicIds);
                Object.keys(urlTopics)?.forEach((topic) => {
                  if (engagedTopics[topic]) {
                    engagedTopics[topic] += urlTopics[topic];
                  } else {
                    engagedTopics[topic] = urlTopics[topic];
                  }
                });
              }
            }
          }
        );
        let engagedTopicIds = generateIdStringFromMap(engagedTopics);
        let { visits, longVisits, views, longViews } = await fetchVisitsForUser(
          users[i].Id,
          myContext.config
        );
        let visitTopics = "";
        let viewTopics = "";
        let canUpdateUrlAndPin =
          !users[i].label?.match("test") &&
          !users[i].label?.match("insider") &&
          !users[i].handle?.match("test123");
        if (canUpdateUrlAndPin) {
          Object.keys(visits)?.forEach((Id) => {
            visitCounts[Id] = visitCounts[Id] ? visitCounts[Id] + 1 : 1;
            clog("visit by", users[i].handle, visitCounts[Id]);
          });
          Object.keys(views)?.forEach((Id) => {
            viewCounts[Id] = viewCounts[Id] ? viewCounts[Id] + 1 : 1;
          });
        }
        Object.keys(longVisits)?.forEach((Id) => {
          if (urls[Id]) {
            visitTopics = addIds(visitTopics, urls[Id]?.topicIds?.split(","));
          }
          if (canUpdateUrlAndPin) {
            longVisitCounts[Id] = longVisitCounts[Id]
              ? longVisitCounts[Id] + 1
              : 1;
            clog("long visit by", users[i].handle, longVisitCounts[Id]);
          }
        });
        Object.keys(longViews)?.forEach((Id) => {
          if (urls[Id]) {
            viewTopics = addIds(viewTopics, urls[Id]?.topicIds?.split(","));
          }
          if (canUpdateUrlAndPin) {
            longViewCounts[Id] = longViewCounts[Id]
              ? longViewCounts[Id] + 1
              : 1;
          }
        });
        console.log("Visited Topics", visitTopics);
        console.log("Viewed Topics", viewTopics);

        response = await API.graphql(
          graphqlOperation(updateUserCounts, {
            Id: users[i].Id,
            //declaredTopicIds: declaredTopicIds, // Stop depending on actions data for topic follow
            consumedTopicIds: consumedTopicIds,
            curatedTopicIds: curatedTopicIds,
            engagedTopicIds: engagedTopicIds,
            viewedTopicIds: viewTopics,
            visitedTopicIds: visitTopics,
          })
        );
        console.log("user response", response);
        console.log(
          //"declaredTopicIds",
          //declaredTopicIds,
          "Followed topics",
          followedTopics,
          "consumedTopicIds",
          consumedTopicIds,
          "consumed topics",
          consumedTopics,
          "curatedTopicIds",
          curatedTopicIds,
          "curated topics",
          curatedTopics,
          "engagedTopicIds",
          engagedTopicIds,
          "engaged topics",
          engagedTopics
        );
      }
      console.log("DONE UPDATING USERS");
      console.log("visit counts", visitCounts);
      console.log("long visit counts", longVisitCounts);
      console.log("View counts", viewCounts);
      console.log("Long view counts", longViewCounts);
      let keys = Object.keys(urls);
      let seq = 0;
      for (let Id of keys) {
        let response = await API.graphql(
          graphqlOperation(updateUrlCounts, {
            Id: Id,
            numLongView: longViewCounts[Id] ? longViewCounts[Id] : 0,
            numTotalView: viewCounts[Id] ? viewCounts[Id] : 0,
            numLongVisit: longVisitCounts[Id] ? longVisitCounts[Id] : 0,
            numTotalVisit: visitCounts[Id] ? visitCounts[Id] : 0,
          })
        );
        seq++;
        console.log("url response", seq, "out of", keys.length, response);
      }
      console.log("DONE UPDATING URLS");

      keys = Object.keys(pins);
      seq = 0;
      for (let Id of keys) {
        let response = await API.graphql(
          graphqlOperation(updatePinCounts, {
            Id: Id,
            numLongView: longViewCounts[Id] ? longViewCounts[Id] : 0,
            numTotalView: viewCounts[Id] ? viewCounts[Id] : 0,
            numLongVisit: longVisitCounts[Id] ? longVisitCounts[Id] : 0,
            numTotalVisit: visitCounts[Id] ? visitCounts[Id] : 0,
          })
        );
        seq++;
        console.log("pin response", seq, "out of", keys.length, response);
      }
      console.log("DONE UPDATING PINS");
    } catch (err) {
      console.log("SOMETHING WENT WRONG", err);
    }
  }

  async function executeInBatches(allPromises) {
    let promises = [];
    try {
      while (allPromises?.length) {
        promises.push(allPromises.shift());
        if (promises.length == 10) {
          console.log("Will run one batch");
          let responses = await Promise.all(promises);
          console.log("RESPONSES", responses);
          promises = [];
          console.log("WILL SLEEP FOR TEN SECOND");
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      }
      if (promises.length) {
        console.log("Will run last batch");
        let responses = await Promise.all(promises);
        console.log("RESPONSES", responses);
      }
    } catch (err) {
      console.log("ERROR executing in batch", err, err?.errors?.[0]);
    }
  }

  function behind(a, b) {
    if (a.visitTS != b.visitTS) {
      return a.visitTS < b.visitTS ? -1 : 1;
    }
    if (a.objectId != b.objectId) {
      return a.objectId.localeCompare(b.objectId);
    }
    if (a.urlId != b.urlId) {
      return a.urlId.localeCompare(b.urlId);
    }
    if (a.pinId != b.pinId) {
      return a.pinId.localeCompare(b.pinId);
    }
    if (a.type != b.type) {
      return a.type != "View" ? -1 : 1;
    }
    if (a.duration != b.duration) {
      return a.duration < b.duration ? -1 : 1;
    }
    return -1;
  }

  function decide(v, av, visits, longVisits, views, longViews, promises, aIds) {
    let newUrlVisit = v.type == null && !visits[v.urlId];
    let newUrlLongVisit =
      v.type == null &&
      !longVisits[v.urlId] &&
      v.duration >= myContext.config.longVisitThreshold;
    let newUrlView = v.type == "View" && !views[v.urlId];
    let newUrlLongView =
      v.type == "View" &&
      !longViews[v.urlId] &&
      v.duration >= myContext.config.longViewThreshold;
    let newPinVisit = v.type == null && !visits[v.pinId];
    let newPinLongVisit =
      v.type == null &&
      !longVisits[v.pinId] &&
      v.duration >= myContext.config.longVisitThreshold;
    let newPinView = v.type == "View" && !views[v.pinId];
    let newPinLongView =
      v.type == "View" &&
      !longViews[v.pinId] &&
      v.duration >= myContext.config.longViewThreshold;
    let retain = false;
    if (
      newUrlVisit ||
      newUrlLongVisit ||
      newPinVisit ||
      newPinLongVisit ||
      newUrlView ||
      newUrlLongView ||
      newPinView ||
      newPinLongView
    ) {
      retain = true;
    } else {
      // delete from visits
      promises.push(
        API.graphql(
          graphqlOperation(removeVisit, {
            Id: v.Id,
          })
        )
      );
    }
    if (newUrlVisit) {
      visits[v.urlId] = v.visitTS;
    }
    if (newUrlLongVisit) {
      longVisits[v.urlId] = v.visitTS;
    }
    if (newUrlView) {
      views[v.urlId] = v.visitTS;
    }
    if (newUrlLongView) {
      longViews[v.urlId] = v.visitTS;
    }
    if (newPinVisit) {
      visits[v.pinId] = v.visitTS;
    }
    if (newPinLongVisit) {
      longVisits[v.pinId] = v.visitTS;
    }
    if (newPinView) {
      views[v.pinId] = v.visitTS;
    }
    if (newPinLongView) {
      longViews[v.pinId] = v.visitTS;
    }
    let replace = false;
    let copy = false;
    if (av) {
      if (v.Id != av.Id) {
        replace = true;
        promises.push(
          API.graphql(
            graphqlOperation(removeVisitFromArchive, {
              Id: av.Id,
            })
          )
        );
        promises.push(API.graphql(graphqlOperation(addVisitToArchive, v)));
      }
    } else {
      copy = true;
      clog("WILL COPY", v.Id, "with", v);
      clog("KNOWN ID IN ARCHIVE", aIds[v.Id]);
      promises.push(API.graphql(graphqlOperation(addVisitToArchive, v)));
    }
    return { retain: retain, copy: copy, replace: replace };
  }

  async function adjustVisitArchive() {
    console.log("ASKED TO Adjust visit archive");
    try {
      let users = [];
      const userData = await API.graphql(
        graphqlOperation(listUsersMinimal, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        users.push(user);
      });
      /*users.unshift({
        Id: "e7836f6b-927d-4fe2-8fb4-14808d234c9d",
        //Id: "ba0d49a7-576e-458d-95de-539f33ca1a41",
        handle: "mover",
        //handle: "zztest100",
      }); */
      for (let i = 0; i < users.length; i++) {
        console.log(
          "processing",
          i + 1,
          " ",
          users[i].handle,
          "out of",
          users.length,
          "users",
          users[i]
        );

        let visitsContinue = true;
        let visitsNextToken = null;
        let archivedVisitsContinue = true;
        let archivedVisitsNextToken = null;
        let visitList = [];
        let archivedVisitList = [];
        try {
          let promises = [];
          let start = performance.now();
          do {
            promises = [];
            if (visitsContinue) {
              promises.push(
                API.graphql(
                  graphqlOperation(visitsByUserComplete, {
                    Id: users[i].Id,
                    nextToken: visitsNextToken,
                    numRequested: 5000,
                  })
                )
              );
            }
            if (archivedVisitsContinue) {
              promises.push(
                API.graphql(
                  graphqlOperation(archivedVisitsByUserComplete, {
                    Id: users[i].Id,
                    nextToken: archivedVisitsNextToken,
                    numRequested: 5000,
                  })
                )
              );
            }
            let responses = await Promise.all(promises);
            console.log("RESPONSES", responses);
            responses.forEach((response) => {
              clog("RESPONSE", response);
              if (response?.data?.visitByUser) {
                if (!response.data.visitByUser.nextToken) {
                  visitsContinue = false;
                } else {
                  visitsNextToken = response.data.visitByUser.nextToken;
                }
                response.data.visitByUser.items.forEach((url) => {
                  clog(url.uri, url.topicIds);
                  visitList.push(url);
                });
              }
              if (response?.data?.visitArchiveByUser) {
                if (!response.data.visitArchiveByUser.nextToken) {
                  archivedVisitsContinue = false;
                } else {
                  archivedVisitsNextToken =
                    response.data.visitArchiveByUser.nextToken;
                }
                response.data.visitArchiveByUser.items.forEach((url) => {
                  clog(url.uri, url.topicIds);
                  archivedVisitList.push(url);
                });
              }
            });
            clog("VISITS", visitList);
            //archivedVisitsContinue = false;
          } while (visitsContinue || archivedVisitsContinue);
          let finish = performance.now();
          console.log("FETCH TIME", finish - start);

          // now should properly sort visitList and archivedVisitsList

          visitList = visitList.sort((a, b) => {
            return behind(a, b);
          });
          archivedVisitList = archivedVisitList.sort((a, b) => {
            return behind(a, b);
          });

          let aIds = {};
          archivedVisitList.forEach((av) => {
            aIds[av.id] = av;
          });
          clog("VISITS", visitList, "ARCHIVED VISITS", archivedVisitList);
          let visits = {};
          let longVisits = {};
          let views = {};
          let longViews = {};
          let vDeletes = 0;
          let aCopies = 0;
          let aReplaces = 0;
          let vi = 0;
          let ai = 0;
          promises = [];
          for (
            vi = 0, ai = 0;
            vi < visitList.length && ai < archivedVisitList.length;

          ) {
            let v = visitList[vi];
            let a = archivedVisitList[ai];
            if (v.visitTS > a.visitTS) {
              ai++;
              clog("ARCHIVED VISIT", a.visitTS, a);
            } else if (v.visitTS < a.visitTS) {
              let { retain, copy, replace } = decide(
                v,
                null,
                visits,
                longVisits,
                views,
                longViews,
                promises,
                aIds
              );
              clog(
                "VISIT",
                "retain",
                retain,
                "copy",
                copy,
                "replace",
                replace,
                v
              );
              if (!retain) {
                vDeletes++;
              }
              if (copy) {
                aCopies++;
              }
              if (replace) {
                aReplaces++;
              }
              vi++;
            } else if (v.visitTS == a.visitTS) {
              if (
                v.type == a.type &&
                v.objectId == a.objectId &&
                v.urlId == a.urlId &&
                v.pinId == a.pinId
              ) {
                let { retain, copy, replace } = decide(
                  v,
                  a,
                  visits,
                  longVisits,
                  views,
                  longViews,
                  promises,
                  aIds
                );
                clog(
                  "BOTH",
                  v.visitTS,
                  "retain",
                  retain,
                  "copy",
                  copy,
                  "replace",
                  replace,
                  v,
                  a
                );
                if (!retain) {
                  vDeletes++;
                }
                if (copy) {
                  aCopies++;
                }
                if (replace) {
                  aReplaces++;
                }
                if (v.Id != a.Id) {
                  clog("ID MISMATCH");
                }
                ai++;
                vi++;
              } else {
                let order = behind(v, a);
                clog("BOTH CANDIDATE", order, v.visitTS, v, a);
                if (order < 0) {
                  let { retain, copy, replace } = decide(
                    v,
                    null,
                    visits,
                    longVisits,
                    views,
                    longViews,
                    promises,
                    aIds
                  );
                  clog(
                    "VISIT",
                    v.visitTS,
                    "retain",
                    retain,
                    "copy",
                    copy,
                    "replace",
                    replace,
                    v
                  );
                  if (!retain) {
                    vDeletes++;
                  }
                  if (copy) {
                    aCopies++;
                  }
                  if (replace) {
                    aReplaces++;
                  }
                  vi++;
                } else {
                  clog("ARCHIVED VISIT", a.visitTS, a);
                  ai++;
                }
              }
            } else {
              console.log("WEIRD SITUATION", v, a);
              break;
            }
          }
          if (vi < visitList.length - 1) {
            console.log(
              "NOT ALL VISITS CONSUMED",
              vi,
              visitList.length,
              visitList[vi]
            );
            for (let k = vi; k < visitList.length; k++) {
              let { retain, copy, replace } = decide(
                visitList[k],
                null,
                visits,
                longVisits,
                views,
                longViews,
                promises,
                aIds
              );
              clog(
                "VISIT",
                "retain",
                retain,
                "copy",
                copy,
                "replace",
                replace,
                visitList[k]
              );
              if (!retain) {
                vDeletes++;
              }
              if (copy) {
                aCopies++;
              }
              if (replace) {
                aReplaces++;
              }
            }
          }
          if (ai < archivedVisitList.length - 1) {
            console.log(
              "NOT ALL ARCHIVED VISITS CONSUMED",
              ai,
              archivedVisitList[ai]
            );
          }
          console.log(
            "DELETE FROM VISIT",
            vDeletes,
            "COPY TO ARCHIVE",
            aCopies,
            "REPLACE IN ARCHIVE",
            aReplaces
          );
          console.log("NUMBER OF PROMISES", promises.length);
          await executeInBatches(promises);
          console.log("DONE PROCESSING USER", users[i].handle);
          promises = [];
          if (visitList.length && archivedVisitList.length) {
            //break;
          }
        } catch (err) {
          console.log("ERROR: error getting visits", err);
          break;
        }
      }
    } catch (err) {
      console.log("SOMETHING WENT WRONG", err);
    }
  }

  function decideArchive(
    av,
    v,
    visits,
    longVisits,
    views,
    longViews,
    promises,
    Ids
  ) {
    let newUrlVisit = av.type == null && !visits[av.urlId];
    let newUrlLongVisit =
      av.type == null &&
      !longVisits[av.urlId] &&
      av.duration >= myContext.config.longVisitThreshold;
    let newUrlView = av.type == "View" && !views[av.urlId];
    let newUrlLongView =
      av.type == "View" &&
      !longViews[av.urlId] &&
      av.duration >= myContext.config.longViewThreshold;
    let newPinVisit = av.type == null && !visits[av.pinId];
    let newPinLongVisit =
      av.type == null &&
      !longVisits[av.pinId] &&
      av.duration >= myContext.config.longVisitThreshold;
    let newPinView = av.type == "View" && !views[av.pinId];
    let newPinLongView =
      av.type == "View" &&
      !longViews[av.pinId] &&
      av.duration >= myContext.config.longViewThreshold;
    let transfer = false;
    if (
      newUrlVisit ||
      newUrlLongVisit ||
      newPinVisit ||
      newPinLongVisit ||
      newUrlView ||
      newUrlLongView ||
      newPinView ||
      newPinLongView
    ) {
      transfer = true;
    }

    if (newUrlVisit) {
      visits[av.urlId] = av.visitTS;
    }
    if (newUrlLongVisit) {
      longVisits[av.urlId] = av.visitTS;
    }
    if (newUrlView) {
      views[av.urlId] = av.visitTS;
    }
    if (newUrlLongView) {
      longViews[av.urlId] = av.visitTS;
    }
    if (newPinVisit) {
      visits[av.pinId] = av.visitTS;
    }
    if (newPinLongVisit) {
      longVisits[av.pinId] = av.visitTS;
    }
    if (newPinView) {
      views[av.pinId] = av.visitTS;
    }
    if (newPinLongView) {
      longViews[av.pinId] = av.visitTS;
    }
    let replace = false;
    let copy = false;
    if (transfer) {
      if (v) {
        if (v.Id != av.Id) {
          replace = true;
          promises.push(
            API.graphql(
              graphqlOperation(removeVisit, {
                Id: v.Id,
              })
            )
          );
          promises.push(API.graphql(graphqlOperation(addVisit, av)));
        }
      } else {
        copy = true;
        clog("WILL COPY", av.Id, "with", av);
        clog("KNOWN ID IN ARCHIVE", Ids[av.Id]);
        promises.push(API.graphql(graphqlOperation(addVisit, av)));
      }
    }
    return { transfer: transfer, copy: copy, replace: replace };
  }

  async function moveFromArchiveToVisit() {
    console.log("ASKED TO move from archive to visit");
    try {
      let users = [];
      const userData = await API.graphql(
        graphqlOperation(listUsersMinimal, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        users.push(user);
      });
      /*users.unshift({
        Id: "e7836f6b-927d-4fe2-8fb4-14808d234c9d",
        //Id: "ba0d49a7-576e-458d-95de-539f33ca1a41",
        handle: "mover",
        //handle: "zztest100",
      }); */
      for (let i = 0; i < users.length; i++) {
        console.log(
          "processing",
          i + 1,
          " ",
          users[i].handle,
          "out of",
          users.length,
          "users",
          users[i]
        );

        let visitsContinue = true;
        let visitsNextToken = null;
        let archivedVisitsContinue = true;
        let archivedVisitsNextToken = null;
        let visitList = [];
        let archivedVisitList = [];
        try {
          let promises = [];
          let start = performance.now();
          do {
            promises = [];
            if (visitsContinue) {
              promises.push(
                API.graphql(
                  graphqlOperation(visitsByUserComplete, {
                    Id: users[i].Id,
                    nextToken: visitsNextToken,
                    numRequested: 5000,
                  })
                )
              );
            }
            if (archivedVisitsContinue) {
              promises.push(
                API.graphql(
                  graphqlOperation(archivedVisitsByUserComplete, {
                    Id: users[i].Id,
                    nextToken: archivedVisitsNextToken,
                    numRequested: 5000,
                  })
                )
              );
            }
            let responses = await Promise.all(promises);
            console.log("RESPONSES", responses);
            responses.forEach((response) => {
              clog("RESPONSE", response);
              if (response?.data?.visitByUser) {
                if (!response.data.visitByUser.nextToken) {
                  visitsContinue = false;
                } else {
                  visitsNextToken = response.data.visitByUser.nextToken;
                }
                response.data.visitByUser.items.forEach((url) => {
                  clog(url.uri, url.topicIds);
                  visitList.push(url);
                });
              }
              if (response?.data?.visitArchiveByUser) {
                if (!response.data.visitArchiveByUser.nextToken) {
                  archivedVisitsContinue = false;
                } else {
                  archivedVisitsNextToken =
                    response.data.visitArchiveByUser.nextToken;
                }
                response.data.visitArchiveByUser.items.forEach((url) => {
                  clog(url.uri, url.topicIds);
                  archivedVisitList.push(url);
                });
              }
            });
            clog("VISITS", visitList);
            //archivedVisitsContinue = false;
          } while (visitsContinue || archivedVisitsContinue);
          let finish = performance.now();
          console.log("FETCH TIME", finish - start);

          // now should properly sort visitList and archivedVisitsList

          visitList = visitList.sort((a, b) => {
            return behind(a, b);
          });
          archivedVisitList = archivedVisitList.sort((a, b) => {
            return behind(a, b);
          });

          let aIds = {};
          archivedVisitList.forEach((av) => {
            aIds[av.id] = av;
          });
          let Ids = {};
          visitList.forEach((v) => {
            Ids[v.id] = v;
          });
          clog("VISITS", visitList, "ARCHIVED VISITS", archivedVisitList);
          let visits = {};
          let longVisits = {};
          let views = {};
          let longViews = {};
          let vDeletes = 0;
          let aCopies = 0;
          let aReplaces = 0;
          let vi = 0;
          let ai = 0;
          promises = [];
          for (
            vi = 0, ai = 0;
            vi < visitList.length && ai < archivedVisitList.length;

          ) {
            let v = visitList[vi];
            let a = archivedVisitList[ai];
            if (v.visitTS > a.visitTS) {
              clog(
                "ARCHIVE VISIT",
                "transfer",
                transfer,
                "copy",
                copy,
                "replace",
                replace,
                v
              );
              let { transfer, copy, replace } = decideArchive(
                a,
                null,
                visits,
                longVisits,
                views,
                longViews,
                promises,
                Ids
              );

              if (copy) {
                aCopies++;
              }
              if (replace) {
                aReplaces++;
              }
              ai++;
              clog("ARCHIVED VISIT", a.visitTS, a);
            } else if (v.visitTS < a.visitTS) {
              vi++;
            } else if (v.visitTS == a.visitTS) {
              if (
                v.type == a.type &&
                v.objectId == a.objectId &&
                v.urlId == a.urlId &&
                v.pinId == a.pinId
              ) {
                let { transfer, copy, replace } = decideArchive(
                  a,
                  v,
                  visits,
                  longVisits,
                  views,
                  longViews,
                  promises,
                  Ids
                );
                clog(
                  "ARCHIVE VISIT",
                  "transfer",
                  transfer,
                  "copy",
                  copy,
                  "replace",
                  replace,
                  a
                );
                if (copy) {
                  aCopies++;
                }
                if (replace) {
                  aReplaces++;
                }
                if (v.Id != a.Id) {
                  clog("ID MISMATCH");
                }
                ai++;
                vi++;
              } else {
                let order = behind(v, a);
                clog("BOTH CANDIDATE", order, v.visitTS, v, a);
                if (order < 0) {
                  vi++;
                } else {
                  clog("ARCHIVED VISIT", a.visitTS, a);
                  let { transfer, copy, replace } = decideArchive(
                    a,
                    null,
                    visits,
                    longVisits,
                    views,
                    longViews,
                    promises,
                    Ids
                  );
                  clog(
                    "ARCHIVE VISIT",
                    "transfer",
                    transfer,
                    "copy",
                    copy,
                    "replace",
                    replace,
                    a
                  );
                  if (copy) {
                    aCopies++;
                  }
                  if (replace) {
                    aReplaces++;
                  }
                  ai++;
                }
              }
            } else {
              console.log("WEIRD SITUATION", v, a);
              break;
            }
          }
          if (vi < visitList.length - 1) {
            console.log(
              "NOT ALL VISITS CONSUMED",
              vi,
              visitList.length,
              visitList[vi]
            );
          }
          if (ai < archivedVisitList.length - 1) {
            console.log(
              "NOT ALL ARCHIVED VISITS CONSUMED",
              ai,
              archivedVisitList[ai]
            );
            for (let k = ai; k < archivedVisitList.length; k++) {
              let { transfer, copy, replace } = decideArchive(
                archivedVisitList[k],
                null,
                visits,
                longVisits,
                views,
                longViews,
                promises,
                Ids
              );
              clog(
                "ARCHIVE VISIT",
                "transfer",
                transfer,
                "copy",
                copy,
                "replace",
                replace,
                archivedVisitList[k]
              );
              if (copy) {
                aCopies++;
              }
              if (replace) {
                aReplaces++;
              }
            }
          }
          console.log("COPY TO VISIT", aCopies, "REPLACE IN VISIT", aReplaces);
          console.log("NUMBER OF PROMISES", promises.length);
          await executeInBatches(promises);
          console.log("DONE PROCESSING USER", users[i].handle);
          promises = [];
          if (visitList.length && archivedVisitList.length) {
            //break;
          }
        } catch (err) {
          console.log("ERROR: error getting visits", err);
          break;
        }
      }
    } catch (err) {
      console.log("SOMETHING WENT WRONG", err);
    }
  }

  async function computeVisitStats() {
    let visitsContinue = true;
    let visitsNextToken = null;
    let archivedVisitsContinue = true;
    let archivedVisitsNextToken = null;
    let visitList = [];
    let archivedVisitList = [];
    let viewList = [];
    let archivedViewList = [];
    try {
      let users = {};
      const userData = await API.graphql(
        graphqlOperation(listUsersMinimal, {})
      );
      clog("User DATA", userData);
      userData.data.listUsers.items.forEach((user) => {
        if (
          !user.label?.match("test") &&
          !user.label?.match("insider") &&
          !user.handle?.match("test123")
        ) {
          users[user.Id] = user;
        }
      });
      let promises = [];
      let start = performance.now();
      do {
        promises = [];
        if (visitsContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listVisits, {
                nextToken: visitsNextToken,
                numRequested: 5000,
              })
            )
          );
        }
        if (archivedVisitsContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listVisitArchives, {
                nextToken: archivedVisitsNextToken,
                numRequested: 5000,
              })
            )
          );
        }
        let responses = await Promise.all(promises);
        console.log("RESPONSES", responses);
        responses.forEach((response) => {
          clog("RESPONSE", response);
          if (response?.data?.listVisits) {
            if (!response.data.listVisits.nextToken) {
              visitsContinue = false;
            } else {
              visitsNextToken = response.data.listVisits.nextToken;
            }
            response.data.listVisits.items.forEach((url) => {
              clog(url.uri, url.topicIds);
              if (users[url.actorId]) {
                if (url.type == "View") {
                  viewList.push(url.duration);
                } else {
                  visitList.push(url.duration);
                }
              }
            });
          }
          if (response?.data?.listVisitArchives) {
            if (!response.data.listVisitArchives.nextToken) {
              archivedVisitsContinue = false;
            } else {
              archivedVisitsNextToken =
                response.data.listVisitArchives.nextToken;
            }
            response.data.listVisitArchives.items.forEach((url) => {
              clog(url.uri, url.topicIds);
              if (users[url.actorId]) {
                if (url.type == "View") {
                  archivedViewList.push(url.duration);
                } else {
                  archivedVisitList.push(url.duration);
                }
              }
            });
          }
        });
        clog("VISITS", visitList);
        //archivedVisitsContinue = false;
        //visitsContinue = false;
        //archivedVisitsContinue = false;
      } while (visitsContinue || archivedVisitsContinue);

      visitList = visitList.sort((a, b) => (a < b ? -1 : 1));
      archivedVisitList = archivedVisitList.sort((a, b) => (a < b ? -1 : 1));
      viewList = viewList.sort((a, b) => (a < b ? -1 : 1));
      archivedViewList = archivedViewList.sort((a, b) => (a < b ? -1 : 1));
      console.log("TOTAL VISIT", visitList.length);
      console.log("TOTAL ARCHIVED VISIT", archivedVisitList.length);
      console.log("TOTAL VIEW", viewList.length);
      console.log("TOTAL ARCHIVED VIEW", archivedViewList.length);
      let keys = [1];
      for (let k = 5; k < 100; k += 5) {
        keys.push(k);
      }
      keys.push(99);

      for (let j = 0; j < keys.length; j++) {
        let k = keys[j];
        let vLoc = Math.round((visitList.length * k) / 100);
        let aLoc = Math.round((archivedVisitList.length * k) / 100);
        let viewLoc = Math.round((viewList.length * k) / 100);
        let aViewLoc = Math.round((archivedViewList.length * k) / 100);
        if (vLoc >= visitList.length) {
          vLoc = visitList.length - 1;
        }
        if (aLoc >= archivedVisitList.length) {
          aLoc = archivedVisitList.length - 1;
        }
        if (viewLoc >= viewList.length) {
          viewLoc = viewList.length - 1;
        }
        if (aViewLoc >= archivedViewList.length) {
          aViewLoc = archivedViewList.length - 1;
        }
        console.log(
          k,
          visitList[vLoc],
          archivedVisitList[aLoc],
          viewList[viewLoc],
          archivedViewList[aViewLoc]
        );
      }
    } catch (err) {
      console.log("ERROR: problem fetching visits", err);
    }
  }

  async function updateUrlTopicIds() {
    console.log("ASKED TO UPDATE URL TOPIC IDS");
    let urls = [];
    try {
      let promises = [];
      for (let i = 0; i < urls.length; i++) {
        promises.push(
          API.graphql(
            graphqlOperation(updateUrlCounts, {
              Id: urls[i].Id,
              topicIds: urls[i].topicIds,
            })
          )
        );
      }
      let responses = await Promise.all(promises);
      console.log("RESPONSES", responses);
    } catch (err) {
      console.log("SOMETHING WENT WRONG", err);
    }
  }

  async function generateRecommendations(dryrun = true, numDays = 1) {
    console.log("ASKED TO GENERATE RECOMMENDATIONS");
    let usersContinue = true;
    let usersNextToken = null;
    let urlsContinue = true;
    let urlsNextToken = null;
    let promises = [];
    let users = [];
    let urls = [];
    let insiders = {};
    let notificationCutoff = timeStamp() - 3600;
    let currentTime = timeStamp();
    let insiderCount = 0;

    try {
      do {
        promises = [];
        if (usersContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(listUsersMinimal, {
                nextToken: usersNextToken,
              })
            )
          );
        }
        if (urlsContinue) {
          promises.push(
            API.graphql(
              graphqlOperation(notificationCandidates, {
                nextToken: urlsNextToken,
                numRequested: 5000,
              })
            )
          );
        }
        let responses = await Promise.all(promises);
        //console.log("RESPONSES", responses);
        responses.forEach((response) => {
          clog("RESPONSE", response);
          if (response?.data?.listUsers) {
            if (!response.data.listUsers.nextToken) {
              usersContinue = false;
            } else {
              usersNextToken = response.data.listUsers.nextToken;
            }
            response.data.listUsers.items.forEach((user) => {
              clog(user.handle, user.declaredTopicIds);
              if (user.label?.match("insider")) {
                insiders[user.Id] = true;
                insiderCount++;
              }
              if (
                user.token &&
                user.lastRecommendationNotificationTime < notificationCutoff
              ) {
                if (
                  user.handle == "jock" ||
                  user.handle == "lucy" ||
                  user.handle == "alpha" ||
                  !(
                    user.label?.match("test") ||
                    user.label?.match("insider") ||
                    user.handle?.match("test123")
                  )
                ) {
                  users.push(user);
                } else {
                  clog("SHOULD NOT RECOMMEND", user.handle);
                }
              }
            });
          }
          if (response?.data?.listUrls) {
            if (!response.data.listUrls.nextToken) {
              urlsContinue = false;
            } else {
              urlsNextToken = response.data.listUrls.nextToken;
            }
            response.data.listUrls.items.forEach((url) => {
              clog(url.uri, url.topicIds);
              if (url.creationTS >= currentTime - numDays * 86400) {
                urls.push(url);
              }
            });
          }
        });
      } while (usersContinue || urlsContinue);
      console.log("INSIDER COUNT", insiderCount);
      console.log("TOTAL URL COUNT", urls.length);
      // Score all urls
      let scores = [];

      urls.forEach((c) => {
        let participants = generateMapFromIdStrings([
          c.curatorIds,
          c.likerIds,
          c.commenterIds,
        ]);
        let cuparticipants = generateMapFromIdStrings([c.curatorIds]);
        let cparticipants = generateMapFromIdStrings([c.commenterIds]);
        Object.keys(cparticipants).forEach((u) => {
          if (cuparticipants[u]) {
            delete cparticipants[u];
          }
        });
        let lparticipants = generateMapFromIdStrings([c.likerIds]);
        Object.keys(lparticipants).forEach((u) => {
          if (cuparticipants[u]) {
            delete lparticipants[u];
          }
        });
        let vparticipants = generateMapFromIdStrings([c.viewerIds]);
        Object.keys(vparticipants).forEach((u) => {
          if (cuparticipants[u]) {
            delete vparticipants[u];
          }
        });

        clog("PARTICIPANTS", participants);

        let nparticipants = Object.keys(participants).length;
        let ncparticipants = Object.keys(cparticipants).length;
        let ncuparticipants = Object.keys(cuparticipants).length;
        let nlparticipants = Object.keys(lparticipants).length;
        let nvparticipants = Object.keys(vparticipants).length;
        if (!nparticipants) {
          nparticipants = 1;
        }
        if (!ncuparticipants) {
          ncuparticipants = 1;
        }
        let involved = {
          ...participants,
          ...cparticipants,
          ...cuparticipants,
          ...lparticipants,
          ...vparticipants,
        };
        let score =
          7.5 * (ncuparticipants - 1) +
          15 * nlparticipants +
          15 * ncparticipants +
          2.5 * nvparticipants +
          3.75 * (nparticipants - 1) +
          7.5 * (c.numPins > 1 ? Math.log10(c.numPins - 1) : 0) +
          7.5 * Math.log10(c.numLike ? c.numLike : 1) +
          6 * Math.log10(c.numComment ? c.numComment : 1) +
          2 * Math.log10(c.numView ? c.numView : 1);
        if (c.numTotalVisit >= 1) {
          score = ((c.numLongVisit - 0) / (c.numTotalVisit + 0)) * 100;
        } else {
          score = 0;
        }
        if (score >= myContext.config.dailyBestMinimumScoreThreshold) {
          let docTopics = generateMapFromIdStrings([c.topicIds]);
          let isNews = false;
          Object.keys(docTopics).forEach((t) => {
            if (myContext.topics[t]?.parentTopicId == "Topic057") {
              isNews = true;
            }
          });
          console.log("ORIGINAL SCORE", score);
          let age = currentTime - c.creationTS;
          let decayRate = myContext.config.scoringAssessmentDecay;
          if (isNews) {
            decayRate = myContext.config.scoringAssessmentDecayForNews;
            console.log("FASTER DECAY BECAUSE OF NEWS FOR", c.title, score);
          }
          score *= 1 - decayRate * Math.floor(age / 86400);
          console.log("FINAL SCORE", score);
          if (score < 0) {
            score = 0;
          }
          scores.push({
            ...c,
            score: score,
            numLongVisit: c.numLongVisit,
            numTotalVisit: c.numTotalVisit,
            involved: involved,
          });
        }
      });

      let urlDetails = {};
      let sent = 0;
      for (let i = 0; i < users.length; i++) {
        let data = [];
        let interestVector = generateVector(
          users[i].declaredTopicIds,
          myContext,
          true
        );
        let candidates = [
          ...scores.sort((a, b) =>
            a.score != b.score
              ? a.score > b.score
                ? -1
                : 1
              : a.numTotalVisit > b.numTotalVisit
              ? -1
              : 1
          ),
        ];
        for (let j = 0; j < candidates.length; j++) {
          let c = candidates[j];
          c["topicScore"] = computeTopicScore(
            c.topicIds,
            interestVector,
            myContext
          );
          c["compositeScore"] =
            c.score >= myContext.config.dailyBestMinimumScoreThreshold
              ? (c.score * c.topicScore.score) / 100
              : 0;
        }

        candidates = candidates.sort((a, b) =>
          a.compositeScore > b.compositeScore
            ? -1
            : a.compositeScore == b.compositeScore
            ? a.numTotalVisit > b.numTotalVisit
              ? -1
              : 1
            : 1
        );
        console.log(
          "HANDLING USER",
          users[i].handle,
          i + 1,
          "out of",
          users.length,
          "with",
          candidates
        );
        let oldRecommendationNextToken = null;
        let oldRecommendations = {};
        let oldRecommendationContinue = true;

        do {
          promises = [];
          if (oldRecommendationContinue) {
            // get all recommendations for the user
            promises.push(
              API.graphql(
                graphqlOperation(recommendationsForUser, {
                  userId: users[i].Id,
                  limit: 1000,
                  nextToken: oldRecommendationNextToken,
                })
              )
            );
          }
          try {
            let responses = await Promise.all(promises);
            clog("RESPONSES", responses);
            responses.forEach((response) => {
              if (response?.data?.recommendationByUserId) {
                response.data.recommendationByUserId?.items?.forEach(
                  (recommendation) => {
                    clog("RECOMMENDATION", recommendation.urlId);
                    oldRecommendations[recommendation.urlId] = true;
                  }
                );
                oldRecommendationNextToken =
                  response.data.recommendationByUserId.nextToken;
                if (!oldRecommendationNextToken) {
                  oldRecommendationContinue = false;
                }
              }
            });
          } catch (err) {
            console.log("CANNOT FETCH RECOMMENDATIONS", err);
          }
        } while (oldRecommendationContinue);

        const NUMBER_OF_PINS = 1;
        let countOfPin = 0;
        console.log("\thas", candidates.length, "candidates");
        for (let j = 0; j < candidates.length; j++) {
          let c = candidates[j];
          if (c.compositeScore <= 0) {
            console.log(
              "SCORE TOO LOW",
              c.title,
              c.score,
              c.topicScore,
              c.numLongVisit,
              c.numTotalVisit
            );
            continue;
          }
          let match = false;
          if (
            !oldRecommendations[c.Id] &&
            !c.involved[users[i].Id] &&
            c.score >= myContext.config.dailyBestMinimumScoreThreshold
          ) {
            if (c.topicScore.score > 0) {
              match = true;
            }
            if (!match) {
              console.log(
                "Not recommended because topic does not match",
                c.title,
                c.topicScore
              );
            }
          } else {
            if (c.score >= myContext.config.dailyBestMinimumScoreThreshold) {
              if (oldRecommendations[c.Id]) {
                console.log(
                  "Not recommended because already recommended",
                  c.title
                );
              } else if (c.involved[users[i].Id]) {
                console.log(
                  "Not recommended because the user is involved",
                  c.title
                );
              }
            }
          }
          if (match) {
            console.log("Recommend", c.title);
            if (!urlDetails[c.Id]) {
              let response = await API.graphql(
                graphqlOperation(getSingleUrl, {
                  Id: c.Id,
                })
              );
              if (response.data.getUrl) {
                let url = response.data.getUrl;
                urlDetails[url.Id] = url;
              }
              clog("LOOKED UP SOMETHING", urlDetails[c.Id]);
            }
            if (urlDetails[c.Id]) {
              let url = urlDetails[c.Id];
              let pin = null;
              let pins = url?.pins?.items;
              if (!pins) {
                pins = url?.pins;
              }
              if (pins) {
                pins.forEach((p) => {
                  if (insiders[p.curatorId] && p.content) {
                    if (!pin) {
                      pin = p;
                    } else if (p.createdAt < pin.createdAt) {
                      pin = p;
                    }
                  }
                });
              }
              clog("CONSIDERING PIN", pin, "INSIDER", insiders[pin?.curatorId]);
              if (pin) {
                clog("URL:", url);
                const score = parseInt(c.compositeScore * 1000, 10);
                if (!dryrun) {
                  promises = [];

                  API.graphql(
                    graphqlOperation(addNotificationRecommendation, {
                      Id: generateUniqueId(),
                      urlId: url.Id,
                      userId: users[i].Id,
                      creationTS: currentTime,
                      score: score,
                      userIdType: [users[i].Id, "Notification"].join(":"),
                    })
                  );

                  promises.push(
                    API.graphql(
                      graphqlOperation(updateUserCounts, {
                        Id: users[i].Id,
                        lastRecommendationNotificationTime: currentTime,
                      })
                    )
                  );
                  let responses = await Promise.all(promises);
                  clog("RESPONSE", responses);
                }
                data.push({ ...url, storedScore: score });
                countOfPin++;
                if (countOfPin === NUMBER_OF_PINS) break;
              } else {
                console.log(
                  "ERROR: CANNOT FIND PIN or PIN does not qualify",
                  url,
                  pin
                );
              }
            }
          }
        }
        let text = countOfPin ? data[0].title : "";
        console.log("TEXT\n" + text);
        if (!dryrun && countOfPin === 0) continue;

        if (!dryrun) {
          sendPushNotification(users[i]?.token, "Must-reads for you 🔥", text, {
            type: "DailyBest",
            Id: data[0].Id,
          });
        }
        if (countOfPin) {
          sent++;
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      console.log("SENT", sent, "out of", users.length);
      alert("sent to " + sent + " out of " + users.length + " candidates");
    } catch (err) {
      console.log("error", err);
    }
  }

  async function runTests() {
    await MyVillageTest({
      callback: ({ success, message, error }) => {
        if (!success) {
          clog(message, error);
        } else {
          clog("SUCCESS!! MyVillageTest");
        }
      },
    });
  }
  ////clog("navigation is in topic list screen ", navigation);
  ////clog("route is ", route);
  let myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const [showAlert, setShowAlert] = useState(false);
  const [feedback, setFeedback] = useState("");
  const { colors } = useTheme();
  const feedbackRef = useRef(null);

  const { width, height } = Dimensions.get("window");
  const insets = useSafeAreaInsets();

  clog("context in settings", myContext);
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <TouchableOpacity onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </TouchableOpacity>
        );
      },
      headerBackTitleVisible: false,
    });
  }, [navigation, colors]);
  let groupCreator = false;
  let experiment = false;
  myContext?.memberOfGroups?.items?.forEach((group) => {
    if (group.groupId == "GroupCreator") {
      groupCreator = true;
    } else if (
      group.groupId == "ExperimentCreator" ||
      group.groupId == "ExperimentViewer"
    ) {
      experiment = true;
    }
  });

  const closeFeedbackSheet = () => {
    feedbackRef.current?.close();
    setFeedback("");
  };

  const onSubmitFeedback = async () => {
    const response = await API.graphql(
      graphqlOperation(addFeedback, {
        Id: generateUniqueId(),
        userId: myContext.Id,
        note: feedback,
        creationTS: timeStamp(),
      })
    );
    console.log("Saved feedback", response);
    closeFeedbackSheet();
  };

  return (
    <SafeAreaView
      style={{
        backgroundColor: colors.background,
        flex: 1,
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <ScrollView style={styles.container}>
          <TouchableOpacity
            onPress={() =>
              navigation.push("Appearance", {
                subsequent: true,
              })
            }
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                Appearance
              </Text>
              <Image
                source={rightArrow}
                style={{ height: 13, width: 7, tintColor: colors.primaryText }}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              navigation.push("TopicChooser", {
                subsequent: true,
              })
            }
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                Topics
              </Text>
              <Image
                source={rightArrow}
                style={{ height: 13, width: 7, tintColor: colors.primaryText }}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => feedbackRef.current?.open()}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 25,
            }}
          >
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              {"Contact Us"}
            </Text>
            <Image
              source={rightArrow}
              style={{ height: 13, width: 7, tintColor: colors.primaryText }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS !== "web") {
                navigation.push("WebViewScreen", {
                  title: "Privacy Policy",
                  webUrl: PRIVACY_POLICY_URL,
                });
              } else {
                window.open(PRIVACY_POLICY_URL, "_blank");
              }
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                {"Privacy Policy"}
              </Text>
              <Image
                source={rightArrow}
                style={{ height: 13, width: 7, tintColor: colors.primaryText }}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS !== "web") {
                navigation.push("WebViewScreen", {
                  title: "Terms of Service",
                  webUrl: TERMS_OF_SERVICE_URL,
                });
              } else {
                window.open(TERMS_OF_SERVICE_URL, "_blank");
              }
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                {"Terms of Service"}
              </Text>
              <Image
                source={rightArrow}
                style={{ height: 13, width: 7, tintColor: colors.primaryText }}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.push("AccountInformation")}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                {"Account Information"}
              </Text>
              <Image
                source={rightArrow}
                style={{ height: 13, width: 7, tintColor: colors.primaryText }}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS == "web") {
                setShowAlert(true);
              } else {
                Alert.alert("", "Do you want to sign out?", [
                  {
                    text: "Cancel",
                    onPress: () => clog("Cancel Pressed"),
                    style: "cancel",
                  },
                  {
                    text: "Yes",
                    onPress: () => {
                      clog("OK Pressed");
                      resetUserData(myContext);
                      console.log("CONTEXT", myContext.usernameLocal);
                      myContext.authenticated = false;
                      try {
                        Auth.signOut();

                        // Store the credentials
                        saveDataToSharedStorage("credentials", undefined);
                      } catch (err) {
                        console.log("not authenticated", err);
                      }

                      navigation.dispatch(
                        CommonActions.reset({
                          index: 0,
                          routes: [{ name: "Authentication" }],
                        })
                      );
                    },
                  },
                ]);
              }
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                Sign Out
              </Text>
              <Image
                source={rightArrow}
                style={{ height: 13, width: 7, tintColor: colors.primaryText }}
              />
            </View>
          </TouchableOpacity>
          {myContext?.label?.includes("admin") && (
            <TouchableOpacity
              onPress={() => {
                clog("pressed on feedbacks");
                navigation.push("Feedbacks");
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Feedbacks
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {myContext?.label?.includes("admin") && (
            <TouchableOpacity
              onPress={() => {
                clog("pressed on requests");
                navigation.push("Requests");
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Requests
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {myContext?.label?.includes("admin") && (
            <TouchableOpacity
              onPress={() => {
                clog("pressed on reports");
                navigation.push("Reports");
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Reports
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {experiment && (
            <TouchableOpacity
              onPress={() => /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/ {
                clog("pressed on experiments");
                navigation.push("Experiments");
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Experiments
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {experiment && (
            <TouchableOpacity
              onPress={() => {
                summarizeViews(myContext);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Summarize activities
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                clog("pressed on groups");
                navigation.push("Groups");
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Groups
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                resendUsersToIndex();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Resend Users To Index
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                resendUrlsToIndex();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Resend Urls To Index
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                recomputeUrlCounters();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recompute url counters
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                findInconsistencies();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Find Inconsistencies
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                patchData();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Patch Data
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                patchIds();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Patch Ids
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                patchStats();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Patch Stats
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                /*navigation.push("TopicChooser", {
              subsequent: true,
            })*/
                patchSeqs();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Patch Seqs
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                activityStats();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Activity Stats
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                fixNames();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Fix Names
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                migrateNotifications();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Migrate Notification Data
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                expandToSubtopics();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Expand To Subtopics
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                updateUrlTopicAssignments();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Update Url Topic Assignments
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                fixTopicIds();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Fix Topic Ids
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                adjustVisitArchive();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Adjust Visit Archive
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                moveFromArchiveToVisit();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Move from Visit Archive
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                computeVisitStats();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Compute Visit Stats
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                updateUrlTopicIds();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Update Url Topic Ids
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(true, 1);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation dry run 1 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(false, 1);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation 1 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(true, 2);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation dry run 2 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(false, 2);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation 2 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(true, 3);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation dry run 3 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(false, 3);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation 3 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(true, 4);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation dry run 4 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(false, 4);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation 4 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(true, 5);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation dry run 5 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(false, 5);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation 5 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(true, 7);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation dry run 7 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(false, 7);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation 7 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(true, 14);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation dry run 14 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                generateRecommendations(false, 14);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Recommendation 14 day
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          {groupCreator && (
            <TouchableOpacity
              onPress={() => {
                runTests();
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Run Tests
                </Text>
                <Image
                  source={rightArrow}
                  style={{
                    height: 13,
                    width: 7,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
            </TouchableOpacity>
          )}
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title=""
            message={"Do you want to sign out?"}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            cancelText="Cancel"
            cancelButtonColor={colors.cancelButton}
            onCancelPressed={() => {
              clog("cancelled");
              setShowAlert(false);
            }}
            showConfirmButton={true}
            confirmText="Yes"
            confirmButtonColor={colors.confirmButton}
            onConfirmPressed={() => {
              clog("confirmed");
              setShowAlert(false);
              resetUserData(myContext);
              myContext.authenticated = false;
              try {
                Auth.signOut();
              } catch (err) {
                console.log("not authenticated", err);
              }
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Authentication" }],
                })
              );
            }}
          />

          <Portal>
            <Modalize
              ref={feedbackRef}
              withHandle={false}
              adjustToContentHeight={true}
              scrollViewProps={{ showsVerticalScrollIndicator: false }}
              modalStyle={[
                styles.modalContainer,
                { backgroundColor: colors.background, minHeight: height * 0.4 },
              ]}
              HeaderComponent={() => (
                <View style={styles.listHeaderContainer}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pressable onPress={closeFeedbackSheet}>
                      <Image
                        source={backArrow}
                        style={{
                          height: 24,
                          width: 24,
                          tintColor: colors.primaryText,
                        }}
                      />
                    </Pressable>

                    <View style={{ marginLeft: 12 }}>
                      <Text
                        style={[styles.title, { color: colors.primaryText }]}
                        numberOfLines={1}
                      >
                        {"Feedback"}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              FloatingComponent={() => (
                <View
                  style={{
                    backgroundColor: colors.background,
                    paddingHorizontal: 16,
                  }}
                >
                  <ColoredButton
                    onPress={onSubmitFeedback}
                    buttonStyle={[
                      styles.button,
                      { backgroundColor: colors.primaryButtonBackground },
                    ]}
                    text={"Submit"}
                    textStyle={[
                      styles.titleButton,
                      {
                        color: colors.primaryButtonText,
                      },
                    ]}
                  />
                  <View
                    style={{ height: insets.bottom ? insets.bottom : 10 }}
                  />
                </View>
              )}
              onClose={() => setFeedback("")}
            >
              <View style={{ paddingHorizontal: 16 }}>
                <TextInput
                  value={feedback}
                  onChangeText={(value) => setFeedback(value)}
                  style={{
                    minHeight: 180,
                    padding: 12,
                    borderWidth: 1,
                    borderRadius: 8,
                    borderColor: colors.smallDivider,
                    fontSize: 18,
                    fontWeight: "700",
                    lineHeight: 28,
                    color: colors.primaryText,
                    marginBottom: 20,
                  }}
                  placeholderTextColor={colors.placeholderText}
                  placeholder={
                    "What do you want to share with the Village team?"
                  }
                  multiline={true}
                />
              </View>
            </Modalize>
          </Portal>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default SettingsScreen;

const styles = StyleSheet.create({
  container: {
    marginLeft: 20,
    marginRight: 20,
  },
  modalContainer: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  listHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
    marginBottom: 16,
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 28,
  },
  button: {
    height: 54,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    marginTop: 12,
  },
  titleButton: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: "700",
    textAlign: "center",
  },
});
