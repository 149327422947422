import React, { useState, useContext } from "react";
import {
  Alert,
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Pressable,
  Image,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import AwesomeAlert from "react-native-awesome-alerts";
import { Auth } from "aws-amplify";

import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";
import eye from "../assets/eye.png";
import eyeClosed from "../assets/eye-closed.png";
import { sharedStyles } from "../utils/SharedStyles";
import ColoredButton from "../components/ColoredButton";
import { updateUserData, getUserData } from "../utils/DataFetcher";
import tree1 from "../assets/tree1.png";
import { clog } from "../utils/Log";
import { SignIn } from "../controllers/AuthController";
import { saveDataToSharedStorage } from "../utils/DataStorage";
import { useTheme } from "../theme";
import Blocker from "../components/Blocker";
import Splash from "../components/Splash";

const SignInScreen = ({ route, navigation }) => {
  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  async function fetchDataAndMove() {
    try {
      let { username, attributes } = await Auth.currentAuthenticatedUser();
      if (username == "guest") {
        clog("Came in with guest identity");
        myContext.authenticated = false;
        try {
          Auth.signOut();
        } catch (err) {
          console.log("not authenticated", err);
        }
        setUsername(""); // to force refresh
      }
      clog("myContext before data fetch", myContext, "username", username);
      //username = "ary";
      //username = "harish";
      //username = "nikhil";
      //username = "akuzolohe"; //Web3 | Growth | Research | Writing
      //username = "alexis"; //
      //username = "alexm"; //
      //username = "alexp01"; //Designing the future buildings, interactive products, and cities!
      //username = "anne"; //
      //username = "businesssummarizer"; //I summarize the latest in business content / news.
      //username = "daryllim"; //Into startups and VCs
      //username = "davidz"; //
      //username = "elijah"; //
      //username = "ella"; //
      //username = "evelyn11"; //
      //username = "fernando"; //Reader of all things strategy, business, and management. I'll share whatever I find most interesting with the rest of you Villagers :-).
      //username = "fitz"; //Putting out good vibes for the smarties on this app 🤟
      //username = "gabriela"; //
      //username = "jackiec"; //
      //username = "jackma"; //
      //username = "jamesc"; //Global growth stage investing
      //username = "jaxwu"; //Intrigued by the intersection of technology and art.
      //username = "jock"; //Thinking about thinking... :)
      //username = "jockz"; //
      //username = "johnl"; //
      //username = "julieparot"; //I share only the most interesting headlines / news in the tech industry 🫡
      //username = "kaywen"; //
      //username = "liamf"; //
      //username = "lilypatel"; //Entrepreneurial spirit with a keen eye for emerging trends in tech and startups.
      //username = "lisa"; //Student of history | Product & GTM | SaaS, Startups, and Tech
      //username = "lucy"; //I love exploring new products and cool technology. I’m always looking for inspiration from out-of-the-box strategies and ideas.
      //username = "lynnelynn"; //Launcher; Strategy & Ops; HIIT
      //username = "marcor"; //
      //username = "mayagupta"; //
      //username = "megan101"; //
      //username = "mnbvv"; //
      //username = "oliviachen"; //Explorer of trends. Not necessarily a follower but definitely a trend surfer 🫣🌊
      //username = "oliviataylor"; //
      //username = "peter"; //
      //username = "rani"; //All things VC 🚀
      //username = "roohikazi"; //
      //username = "skyjohn"; //
      //username = "summarizer"; //I summarize paid content from dozens of sources.
      myContext["fetching"] = true;
      setHeaderShown(false);
      let { success, data } = await getUserData(username);
      clog("success", success, "data", data);
      if (success) {
        updateUserData(myContext, data, (askForPermission) => {
          console.log("ask for permission", askForPermission);
          clog("my context", myContext);
          if (askForPermission) {
            if (!myContext.Id) {
              myContext.onboarding = true;
            }
            navigation.replace("Permission", {
              nextScreen: myContext.Id ? "tab" : "Profile",
            });
          } else {
            if (myContext.Id) {
              navigation.replace("tab");
            } else {
              myContext.onboarding = true;
              navigation.replace("Profile");
            }
          }
        });
      } else {
        clog("error, failed to fetch user data");
      }
    } catch (err) {
      console.log("not authenticated", err);
    }
    myContext["fetching"] = false;
  }

  const checkConditions = () => {
    clog(
      "usernameLocal",
      myContext.usernameLocal,
      "passwordLocal",
      myContext.passwordLocal
    );
    return myContext.usernameLocal && myContext.passwordLocal && !loading;
  };

  const updateConditions = () => {
    if (checkConditions()) {
      setConditionMet(true);
    } else {
      setConditionMet(false);
    }
  };

  const updateConditionsAndDismissKeyboard = () => {
    updateConditions();
    Keyboard.dismiss;
  };

  const onNext = () => {
    if (checkConditions()) {
      handleSubmit();
    }
  };

  async function handleSubmit() {
    let message = "";
    if (!checkConditions()) {
      message = "Must provide username and password";
    }
    if (message) {
      showMessage(message);
    } else {
      clog("WILL TRY TO SIGN IN");
      setLoading(true);
      myContext["fetching"] = true;
      try {
        await SignIn({
          username: myContext.usernameLocal,
          password: password,
          myContext: myContext,
          callback: ({ success, message, error, user }) => {
            setLoading(false);
            if (success) {
              // Store the credentials
              saveDataToSharedStorage("credentials", {
                username: myContext.usernameLocal,
                password: myContext.passwordLocal,
              });
              fetchDataAndMove();
              myContext["fetching"] = false;
            } else {
              myContext["fetching"] = false;
              showMessage(message);
            }
          },
        });
      } catch (err) {
        console.log("Error", err);
        myContext["fetching"] = false;
      }
    }
  }

  const myContext = useContext(AppContext);
  const { colors } = useTheme();
  const [username, setUsername] = useState(
    myContext?.username == null ? "" : myContext?.username
  );
  const [password, setPassword] = useState(
    myContext?.password == null ? "" : myContext?.password
  );
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [conditionMet, setConditionMet] = useState(checkConditions());
  const [headerShown, setHeaderShown] = useState(!myContext.authenticated);

  clog("CONDITION MET", conditionMet);
  //clog("NAVIGATION", navigation);
  React.useLayoutEffect(() => {
    let headerTitleValue = !myContext.authenticated ? "Sign in to Village" : "";
    let showBackButton = navigation.canGoBack();
    clog("Show back button", showBackButton);
    navigation.setOptions({
      headerLeft: () => {
        if (showBackButton) {
          return (
            <Pressable onPress={navigation.goBack}>
              <View style={{ marginLeft: 10 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        } else {
          return <View></View>;
        }
      },
      headerBackTitleVisible: showBackButton,
      headerTitle: headerTitleValue,
      headerShown: headerShown,
    });
  }, [navigation, colors, headerShown, myContext]);

  if (myContext.authenticated) {
    if (!myContext.fetching) {
      myContext["fetching"] = true;
      fetchDataAndMove();
    }
    return <Splash />;
  }
  clog("CURRENT COLORS", colors);

  return (
    <SafeAreaView
      style={[sharedStyles.container, { backgroundColor: colors.background }]}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <Blocker loading={loading} />
        <KeyboardAwareScrollView
          style={{
            width: "100%",
            backgroundColor: colors.background,
          }}
        >
          <View style={styles.inputContainer}>
            <Text
              style={[sharedStyles.textLabel, { color: colors.primaryText }]}
            >
              Village username
            </Text>
            <View
              style={[
                sharedStyles.textInputBox,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                  height: 48,
                },
              ]}
            >
              <TextInput
                style={[
                  sharedStyles.textInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                    height: 48,
                    borderWidth: 0,
                  },
                ]}
                autoCapitalize="none"
                value={username}
                maxLength={200}
                onChangeText={(newValue) => {
                  setUsername(newValue.toLowerCase());
                  myContext["usernameLocal"] = newValue.toLowerCase();
                  updateConditions();
                }}
                onBlur={() => {
                  updateConditionsAndDismissKeyboard();
                }}
                returnKeyType={"next"}
              />
            </View>
            <View style={{ marginTop: 16 }}>
              <Text
                style={[sharedStyles.textLabel, { color: colors.primaryText }]}
              >
                Password
              </Text>
              <View
                style={[
                  sharedStyles.textInputBox,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                    height: 48,
                  },
                ]}
              >
                <TextInput
                  secureTextEntry={passwordVisibility}
                  style={[
                    sharedStyles.textInput,
                    { color: colors.primaryText, borderWidth: 0 },
                  ]}
                  placeholder={"case sensitive"}
                  placeholderTextColor={colors.secondaryText}
                  value={password}
                  maxLength={255}
                  onChangeText={(newValue) => {
                    setPassword(newValue);
                    myContext["passwordLocal"] = newValue;
                    updateConditions();
                  }}
                  onBlur={() => {
                    updateConditionsAndDismissKeyboard();
                  }}
                  returnKeyType={"done"}
                  blurOnSubmit={false}
                  onSubmitEditing={onNext}
                />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: 24,
                    width: 24,
                    marginRight: 12,
                  }}
                >
                  <Pressable
                    onPress={() => {
                      clog("Pressed eye button");
                      setPasswordVisibility((prev) => {
                        return !prev;
                      });
                    }}
                  >
                    <Image
                      source={passwordVisibility ? eye : eyeClosed}
                      style={{
                        height: 13.5,
                        width: 19.5,
                        tintColor: colors.primaryText, // ????
                      }}
                    />
                  </Pressable>
                </View>
              </View>
            </View>
            <ColoredButton
              onPress={handleSubmit}
              buttonStyle={{
                alignItems: "center",
                justifyContent: "center",
                marginTop: 31,
                marginLeft: 10,
                marginRight: 10,
                height: 54,
                borderRadius: 16,
                backgroundColor: conditionMet
                  ? colors.primaryButtonBackground
                  : colors.disabledPrimaryButtonBackground,
              }}
              textStyle={{
                textAlign: "center",
                textAlignVertical: "center",
                fontSize: 15,
                fontWeight: "700",
                color: conditionMet
                  ? colors.primaryButtonText
                  : colors.disabledPrimaryButtonText,
              }}
              text={"Sign In"}
            ></ColoredButton>
            <TouchableOpacity
              style={{ alignItems: "center", marginTop: 16 }}
              onPress={() => {
                navigation.push("SignUp");
              }}
            >
              <View style={{ width: 358 }}>
                <Text
                  style={[styles.callToAction, { color: colors.primaryText }]}
                  suppressHighlighting={true}
                >
                  Don't have an account?
                  <Text
                    style={[
                      styles.callToActionEmphasized,
                      { color: colors.link },
                    ]}
                  >
                    {" "}
                    Sign up{" "}
                  </Text>
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ alignItems: "center", marginTop: 16 }}
              onPress={() => {
                navigation.push("ForgotPassword");
              }}
            >
              <View style={{ width: 358 }}>
                <Text
                  style={[styles.callToAction, { color: colors.primaryText }]}
                  suppressHighlighting={true}
                >
                  Forgot password?
                  <Text
                    style={[
                      styles.callToActionEmphasized,
                      { color: colors.link },
                    ]}
                  >
                    {" "}
                    Get confirmation code{" "}
                  </Text>
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <Image
            source={tree1}
            style={{ height: 391.48, width: 350, alignSelf: "center" }}
          />
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title=""
            message={alertMessage}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            cancelText="Ok"
            cancelButtonColor={colors.cancelButton}
            onCancelPressed={() => {
              setShowAlert(false);
            }}
          />
        </KeyboardAwareScrollView>
      </View>
    </SafeAreaView>
  );
};

export default SignInScreen;

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  callToAction: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
  callToActionEmphasized: {
    fontSize: 12,
    fontWeight: "600",
  },
});
