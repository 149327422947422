import React, { useEffect, useState, useContext, useRef } from "react";
import { View, Image, Pressable, Text } from "react-native";
import { API, graphqlOperation } from "aws-amplify";
import { listExperiments } from "../src/graphql/custom";
import AppContext from "../components/AppContext";
import { initialUser } from "../utils/InitialValues";
import ExperimentList from "../components/ExperimentList";
import { addActionByUser, removeActionByUser } from "../utils/Action";
import LeftArrow from "../components/LeftArrow";
import plus from "../assets/plus.png";
import { timeStamp } from "../utils/TimeStamp";
import { clog } from "../utils/Log";
import { useTheme } from "../theme";

const ExperimentListScreen = ({ route, navigation }) => {
  async function getData() {
    try {
      const experimentsData = await API.graphql(
        graphqlOperation(listExperiments, {})
      );
      clog("experiment data", experimentsData);
      if (experimentsData.data.listExperiments.items) {
        let experiments = experimentsData.data.listExperiments.items;
        let sortedExperiments = [];
        experiments
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .forEach((element) => {
            sortedExperiments.push(element);
          });

        setState((currentState) => {
          return {
            ...currentState,
            total: sortedExperiments.length,
            experiments: sortedExperiments,
            fetchTimeStamp: timeStamp(),
            version: myContext.version,
          };
        });
      }
    } catch (err) {
      console.log("error fetching experiments...", err);
    }
  }

  const addExperimentJoined = (
    experimentId,
    relationshipId,
    experiment,
    createdAt
  ) => {
    ////clog("started following", userId, relationshipId);
    addActionByUser(
      myContext.actionsByUser,
      "Experiment",
      "Join",
      experimentId,
      relationshipId,
      experiment,
      null,
      null,
      createdAt
    );
    myContext.numExperimentJoin++;
    myContext.version++;
    setState((previousState) => {
      ////clog("updated state");
      return {
        ...previousState,
        actionsByUser: myContext.actionsByUser,
        numExperimentJoin: myContext.numExperimentJoin,
      };
    });
  };

  const removeExperimentJoined = (experimentId, relationshipId, experiment) => {
    clog("stopped following", experimentId, relationshipId);
    removeActionByUser(
      myContext.actionsByUser,
      "Experiment",
      "Join",
      experimentId
    );
    myContext.numExperimentJoin--;
    myContext.version++;
    setState((previousState) => {
      clog("ORIGINAL LIST", previousState.experiments);
      let newState = {
        ...previousState,
        actionsByUser: myContext.actionsByUser,
        numExperimentJoin: myContext.numExperimentJoin,
      };
      return newState;
    });
  };

  const { colors } = useTheme();

  const initialState = {
    ...initialUser,
    experiments: [],
    addFunction: null,
    removeFunction: null,
    fetchTimeStamp: 0,
    version: 0,
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  let experimentCreator = false;
  myContext?.memberOfGroups?.items?.forEach((group) => {
    if (group.groupId == "ExperimentCreator") {
      experimentCreator = true;
    }
  });

  React.useLayoutEffect(() => {
    let headerTitleValue = "Experiments";
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View style={{ marginLeft: 10 }}>
              <LeftArrow tintColor={colors.primaryText} />
            </View>
          </Pressable>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: headerTitleValue,
    });
  }, [navigation, state]);

  useEffect(() => {
    if (myContext && (!state.addFunction || !state.removeFunction)) {
      setState((prevState) => {
        return {
          ...prevState,
          addFunction: addExperimentJoined,
          removeFunction: removeExperimentJoined,
        };
      });
    }
  }, []);

  async function dataRefresh() {
    clog("CLICKED ON TAB");
    // do something
    if (
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
      getData().catch((err) => {
        console.log("error getting experiment list ", err);
      });
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
    }
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: colors.background,
      }}
    >
      <View
        style={{ flex: 1, width: "100%", maxWidth: 756, paddingHorizontal: 20 }}
      >
        {experimentCreator && (
          <View
            style={{
              alignSelf: "flex-start",
            }}
          >
            <Pressable
              onPress={() => {
                navigation.push("ExperimentUpdate", {});
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 40,
                  marginBottom: 38,
                }}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    alignContent: "center",
                    alignItems: "center",
                    backgroundColor: colors.secondaryBackground,
                  }}
                >
                  <Image
                    source={plus}
                    style={{
                      height: 30,
                      width: 30,
                      marginTop: 5,
                      tintColor: colors.primaryText,
                    }}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      color: colors.primaryText,
                      fontSize: 16,
                      marginLeft: 9,
                    }}
                  >
                    Create a new experiment
                  </Text>
                </View>
              </View>
            </Pressable>
          </View>
        )}
        <ExperimentList
          state={state}
          myContext={myContext}
          navigation={navigation}
          refererActionId={state.refererActionId}
          hostUserId={state.hostUserId}
          colors={colors}
        />
      </View>
    </View>
  );
};

export default ExperimentListScreen;
