let _dropDown;

const setDropDown = (dropDown) => {
  _dropDown = dropDown;
};

const showDropDownAlert = () => {
  _dropDown.onOpen();
  setTimeout(() => _dropDown.onClose(), 5000);
};

export default {
  setDropDown,
  showDropDownAlert,
};
