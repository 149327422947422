import { API, graphqlOperation } from "aws-amplify";

import {
  contentOfInterest,
  followedUsers,
  followingUsers,
  addNotification,
  getNotifications,
  getMinimalNotifications,
  updateNotification,
  userMinimal,
} from "../src/graphql/custom";

import { batchPresign } from "../utils/Photo";
import { clog } from "../utils/Log";
import { adjustPinForRichShareNote } from "../utils/Compatibility";
import { generateUniqueId } from "../utils/Id";
import { timeStamp } from "../utils/TimeStamp";

export async function getNotificationData({ handle, myContext, callback }) {
  clog("will try to GETDATA for", handle);
  let status = { success: true };
  if (!handle) {
    status.success = false;
    status["message"] = "No handle provided";
    if (callback) {
      callback(status);
    }
    return status;
  }
  let actionsOfInterest = {};
  let mayNeedLookUp = {};

  try {
    let start = performance.now();
    let promises = [];

    promises.push(
      API.graphql(
        graphqlOperation(getNotifications, { recipientId: myContext.Id })
      )
    );

    let responses = await Promise.all(promises);
    let fetchEnd = performance.now();
    console.log("NOTIFICATION TIME= fetch", (fetchEnd - start) / 1000);
    start = performance.now();
    responses.forEach((response) => {
      if (response?.data?.notificationForRecipient) {
        clog(
          "Stored notifications",
          response?.data?.notificationForRecipient?.items
        );
        response?.data?.notificationForRecipient?.items?.forEach(
          (notification) => {
            if (
              myContext?.actionsByUser?.["User"]?.["Block"]?.[
                notification?.actorId
              ] ||
              myContext?.actions?.["User"]?.["Block"]?.[notification?.actorId]
            ) {
              console.log(
                "Skip notification because of actor id",
                notification
              );
              return;
            }
            if (
              notification.pin &&
              (myContext?.actionsByUser?.["User"]?.["Block"]?.[
                notification?.pin?.curatorId
              ] ||
                myContext?.actions?.["User"]?.["Block"]?.[
                  notification?.pin?.curatorId
                ])
            ) {
              console.log(
                "skip notification because of curator of pin",
                notification
              );
              return;
            }

            if (
              notification.comment &&
              (myContext?.actionsByUser?.["User"]?.["Block"]?.[
                notification?.comment?.curatorId
              ] ||
                myContext?.actions?.["User"]?.["Block"]?.[
                  notification?.comment?.curatorId
                ])
            ) {
              console.log(
                "skip notification because of curator of comment",
                notification
              );
              return;
            }
            if (
              notification.action &&
              myContext?.actionsByUser?.["User"]?.["Block"]?.[
                notification?.action?.actorId
              ]
            ) {
              console.log(
                "skip notification because of actor of action",
                notification
              );
              return;
            }
            if (!notification.action || !notification.actor) {
              clog("SKIP because not action or actor", notification);
              return;
            }
            if (notification?.actor?.Id == myContext.Id) {
              clog("IGNORE OWN ACTIONS", notification);
              return;
            }
            mayNeedLookUp[notification?.actor?.avatar] = true;
            clog("PROCESSING", notification);
            if (
              notification.targetType == "User" &&
              notification.operation == "Follow" &&
              notification.targetId == myContext.Id
            ) {
              actionsOfInterest[notification.actionId] = {
                action: notification?.action,
                target: notification?.action?.actor,
                type: "User",
                createdAt: notification.action.createdAt,
              };
            }
            if (
              notification.operation == "Save" &&
              notification.targetType == "Pin"
            ) {
              clog("Processing", notification);
              if (!notification.pin) {
                clog("SKIP because no pin", notification);
              } else {
                notification.pin["curator"] = notification.actor;
                notification.pin["curatorId"] = notification.actorId;
                notification.pin["createdAt"] = notification.createdAt;
                adjustPinForRichShareNote(
                  notification.pin,
                  myContext,
                  mayNeedLookUp
                );
                actionsOfInterest[notification.actionId] = {
                  action: notification.action,
                  target: notification.url,
                  justificationTarget: notification.pin,
                  createdAt: notification.action.createdAt,
                };
              }
            }
            if (
              (notification.operation == "Comment" ||
                notification.operation == "Mention") &&
              notification.comment
            ) {
              actionsOfInterest[notification.actionId] = {
                action: notification.action,
                target: notification.url,
                justificationTarget: notification.comment,
                mention: notification.operation == "Mention",
                createdAt: notification.action.createdAt,
              };
              mayNeedLookUp[notification?.url?.photo] = true;
            }
            if (
              notification.operation == "Like" &&
              notification.targetType == "Comment" &&
              notification.comment
            ) {
              actionsOfInterest[notification.actionId] = {
                action: notification.action,
                target: notification.url,
                justificationTarget: notification.comment,
                createdAt: notification.action.createdAt,
              };
            }
            if (
              notification.operation == "Like" &&
              notification.targetType == "Pin"
            ) {
              if (!notification.pin) {
                clog("SKIP because no pin", notification);
              } else {
                notification.pin["curator"] = notification.actor;
                notification.pin["curatorId"] = notification.actorId;
                adjustPinForRichShareNote(
                  notification.pin,
                  myContext,
                  mayNeedLookUp
                );
                actionsOfInterest[notification.actionId] = {
                  action: notification.action,
                  target: notification.url,
                  justificationTarget: notification.pin,
                  createdAt: notification.action.createdAt,
                };
              }
            }
          }
        );
      }
    });

    promises = [];

    clog("ALL ACTIONS OF INTEREST", actionsOfInterest);
    let needPatching = {};
    Object.values(actionsOfInterest).forEach((item) => {
      if (!myContext.users[item.action.actorId]) {
        needPatching[item.action.actorId] = true;
      }
    });
    promises = [];
    Object.keys(needPatching).forEach((Id) => {
      promises.push(
        API.graphql(
          graphqlOperation(userMinimal, {
            Id: Id,
          })
        )
      );
    });
    responses = await Promise.all(promises);
    responses.forEach((response) => {
      clog("RESPONSE", response);
      if (response?.data?.getUser?.Id) {
        let user = response?.data?.getUser;
        if (!myContext.users[user.Id]) {
          myContext.users[user.Id] = user;
        }
      }
    });
    clog("MAY NEED LOOKUP", mayNeedLookUp);
    let massageEnd = performance.now();
    console.log("TIME= massage", (massageEnd - start) / 1000);

    start = performance.now();
    await batchPresign(
      Object.keys(mayNeedLookUp),
      myContext.presignedUrls,
      null
    );
    let signEnd = performance.now();
    console.log("TIME= presign", (signEnd - start) / 1000);
    status["actionsOfInterest"] = Object.values(actionsOfInterest).sort(
      (a, b) => (a.action.createdAt > b.action.createdAt ? -1 : 1)
    );
    if (callback) {
      callback(status);
    }
    return status;
  } catch (err) {
    console.log("SOMETHING WENT WRONG", err);
    status.success = false;
    status["message"] = "error fetching data...";
    status.error = err;
    if (callback) {
      callback(status);
    }
    return status;
  }
}

export async function createNotification(data) {
  let payload = {};
  payload["Id"] = generateUniqueId();
  [
    "recipientId",
    "actorId",
    "actionId",
    "targetId",
    "targetType",
    "operation",
    "urlId",
    "pinId",
    "commentId",
    "creationTS",
  ].forEach((key) => {
    if (data[key]) {
      payload[key] = data[key];
    }
  });
  if (
    payload.operation == "Follow" &&
    payload.targetType == "User" &&
    data.userId
  ) {
    payload["userId"] = data.recipientId;
  }

  console.log("PAYLOAD TO SAVE", payload);
  try {
    await API.graphql(graphqlOperation(addNotification, payload));
  } catch (err) {
    console.log("Error creating notification", err);
  }
}

export async function migrateNotificationData({ userId, callback }) {
  let status = { success: true };
  let followings = {};
  let actionsOfInterest = {};
  let notifications = [];

  try {
    let start = performance.now();
    let filter = null;
    let disjuctions = [];
    let promises = [];
    disjuctions.push({ curatorIds: { match: userId } });
    disjuctions.push({ commenterIds: { match: userId } });
    disjuctions.push({ likerIds: { match: userId } });

    if (disjuctions.length) {
      filter = { filter: { or: disjuctions } };
    }
    promises.push(API.graphql(graphqlOperation(contentOfInterest, filter)));
    promises.push(
      API.graphql(
        graphqlOperation(followedUsers, {
          actorIdOperationObjectType: [userId, "Follow", "User"].join(":"),
        })
      )
    );
    console.log("KEY", [userId, "Follow", "User"].join(":"));
    promises.push(
      API.graphql(
        graphqlOperation(followingUsers, {
          objectIdOperation: [userId, "Follow"].join(":"),
        })
      )
    );
    promises.push(
      API.graphql(
        graphqlOperation(getMinimalNotifications, { recipientId: userId })
      )
    );
    let seenActions = {};
    let existingNotifications = {};
    let responses = await Promise.all(promises);
    let fetchEnd = performance.now();
    console.log("NOTIFICATION TIME= fetch", (fetchEnd - start) / 1000);
    start = performance.now();
    let followerResponse = null;
    let followingResponse = null;
    let urlsResponse = null;
    responses.forEach((response) => {
      if (response?.data?.actionByActorIdOperationObjectType?.items) {
        followingResponse =
          response?.data?.actionByActorIdOperationObjectType?.items;
        clog("FOLLOWING RESPONSE", followingResponse);
      }
      if (response?.data?.actionByObjectIdOperation?.items) {
        followerResponse = response?.data?.actionByObjectIdOperation?.items;
      }
      if (response?.data?.searchUrls?.items) {
        urlsResponse = response?.data?.searchUrls?.items;
      }
      if (response?.data?.notificationForRecipient) {
        console.log(
          "Stored notifications",
          response?.data?.notificationForRecipient?.items
        );
        response?.data?.notificationForRecipient?.items?.forEach(
          (notification) => {
            if (!existingNotifications[notification.actionId]) {
              existingNotifications[notification.actionId] = notification;
            } else {
              console.log(
                "FOUND MULTIPLE NOTIFICATION TIED TO THE SAME ACTION",
                notification,
                "vs",
                existingNotifications[notification.actionId]
              );
            }
          }
        );
      }
    });
    let users = {};
    if (followingResponse) {
      followingResponse.forEach((action) => {
        clog("FOLLOWED USER", action.user);
        followings[action.user.Id] = action.user;
        if (!users[action.user.Id]) {
          users[action.user.Id] = action.user;
        }
      });
    }

    if (followerResponse) {
      let followersSeen = {};
      console.log("FOLLOWING ALL", followerResponse);
      followerResponse
        .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
        .forEach((action) => {
          clog("FOLLOWING USER", action.actor);
          if (action?.actor?.Id && !followersSeen[action.actor.Id]) {
            followersSeen[action.actor.Id] = true;
            actionsOfInterest[action.Id] = {
              action: action,
              target: action.actor,
              type: "User",
            };
            if (!seenActions[action.Id]) {
              seenActions[action.Id] = true;
              notifications.push({
                actionId: action.Id,
                actorId: action.actor.Id,
                creationTS: action.creationTS,
                operation: "Follow",
                recipientId: userId,
                targetType: "User",
                targetId: userId,
                userId: userId,
                createdAt: action.createdAt,
              });
            } else {
              console.log("ERROR: seen action", action);
            }
          } else {
            console.log(
              "ALREADY SEEN",
              action.actor.handle,
              "at",
              action.createdAt
            );
          }
        });
    }

    if (urlsResponse) {
      urlsResponse.forEach((url) => {
        let curated = false;
        let commented = false;
        let liked = false;
        // Iterate over the all pins and comments to confirm validity
        let oldestTimestamp = null;
        url.pins.items?.forEach((pin) => {
          clog("Pin on underlying url", pin);
          if (pin.curator.Id == userId) {
            curated = true;
            clog("CURATED", url.Id);
            if (!oldestTimestamp) {
              oldestTimestamp = pin.createdAt;
            } else if (pin.createdAt < oldestTimestamp) {
              oldestTimestamp = pin.createdAt;
            }
          }
          pin.actions.items.forEach((pinAction) => {
            if (pinAction.actorId == userId) {
              if (pinAction.operation == "Like") {
                liked = true;
                clog("LIKED PIN", url.Id);
                if (!oldestTimestamp) {
                  oldestTimestamp = pinAction.createdAt;
                } else if (pinAction.createdAt < oldestTimestamp) {
                  oldestTimestamp = pinAction.createdAt;
                }
              }
            }
          });
          pin?.comments?.items?.forEach((comment) => {
            //clog("FOUND A COMMENT ON PIN", comment);
            if (comment.curator.Id == userId) {
              commented = true;
              clog("CREATED COMMENT ON PIN", url.Id);
              if (!oldestTimestamp) {
                oldestTimestamp = comment.createdAt;
              } else if (comment.createdAt < oldestTimestamp) {
                oldestTimestamp = comment.createdAt;
              }
            }
            comment.actions.items.forEach((commentAction) => {
              if (commentAction.actorId == userId) {
                if (commentAction.operation == "Like") {
                  liked = true;
                  clog("LIKED COMMENT ON PIN", url.Id);
                  if (!oldestTimestamp) {
                    oldestTimestamp = commentAction.createdAt;
                  } else if (commentAction.createdAt < oldestTimestamp) {
                    oldestTimestamp = commentAction.createdAt;
                  }
                }
              }
            });
          });
        });
        url.comments.items?.forEach((comment) => {
          if (comment.curator.Id == userId) {
            commented = true;
            clog("COMMENTED", url.Id);
            if (!oldestTimestamp) {
              oldestTimestamp = comment.createdAt;
            } else if (comment.createdAt < oldestTimestamp) {
              oldestTimestamp = comment.createdAt;
            }
          }
          comment.actions.items.forEach((commentAction) => {
            if (commentAction.actorId == userId) {
              if (commentAction.operation == "Like") {
                liked = true;
                clog("LIKED COMMENT", url.Id);
                if (!oldestTimestamp) {
                  oldestTimestamp = commentAction.createdAt;
                } else if (commentAction.createdAt < oldestTimestamp) {
                  oldestTimestamp = commentAction.createdAt;
                }
              }
            }
          });
          comment?.comments?.items?.forEach((c2) => {
            if (c2.curator.Id == userId) {
              commented = true;
              clog("CREATED COMMENT ON COMMENT", url.Id);
              if (!oldestTimestamp) {
                oldestTimestamp = c2.createdAt;
              } else if (c2.createdAt < oldestTimestamp) {
                oldestTimestamp = c2.createdAt;
              }
            }
            c2.actions.items.forEach((c2Action) => {
              if (c2Action.actorId == userId) {
                if (c2Action.operation == "Like") {
                  liked = true;
                  clog("LIKED COMMENT ON COMMENT", url.Id);
                  if (!oldestTimestamp) {
                    oldestTimestamp = c2Action.createdAt;
                  } else if (c2Action.createdAt < oldestTimestamp) {
                    oldestTimestamp = c2Action.createdAt;
                  }
                }
              }
            });
          });
        });

        if (curated || commented || liked) {
          url.comments.items?.forEach((comment) => {
            clog("Comment on underlying url", comment);
            if (
              followings[comment.curator.Id] ||
              comment.curator.Id == userId
            ) {
              //clog("Comment by somebody you follow");
              comment.actions.items.forEach((commentAction) => {
                if (commentAction.createdAt > oldestTimestamp) {
                  if (
                    followings[comment.curator.Id] &&
                    commentAction.operation == "Create"
                  ) {
                    clog("Relevant action", commentAction);
                    commentAction["actor"] = followings[commentAction.actorId];
                    actionsOfInterest[commentAction.Id] = {
                      action: commentAction,
                      target: url,
                      justificationTarget: comment,
                    };
                    if (!seenActions[commentAction.Id]) {
                      seenActions[commentAction.Id] = true;
                      notifications.push({
                        actionId: commentAction.Id,
                        actorId: commentAction.actor.Id,
                        creationTS: commentAction.creationTS,
                        operation: "Comment",
                        recipientId: userId,
                        targetType: "Url",
                        targetId: url.Id,
                        urlId: url.Id,
                        createdAt: commentAction.createdAt,
                      });
                    } else {
                      console.log("ERROR: already seen action", commentAction);
                    }
                  } else {
                    clog("Comment is not by somebody you follow");
                  }
                  if (
                    true &&
                    comment.curator.Id == userId &&
                    commentAction.operation == "Like" &&
                    commentAction.actorId != userId
                  ) {
                    if (followings[commentAction.actorId]) {
                      commentAction["actor"] =
                        followings[commentAction.actorId];
                    }
                    actionsOfInterest[commentAction.Id] = {
                      action: commentAction,
                      target: url,
                      justificationTarget: comment,
                    };
                    if (!seenActions[commentAction.Id]) {
                      seenActions[commentAction.Id] = true;
                      notifications.push({
                        actionId: commentAction.Id,
                        actorId: commentAction.actor.Id,
                        creationTS: commentAction.creationTS,
                        operation: "Like",
                        recipientId: userId,
                        targetType: "Comment",
                        targetId: comment.Id,
                        commentId: comment.Id,
                        urlId: url.Id,
                        createdAt: commentAction.createdAt,
                      });
                    } else {
                      console.log("ERROR: already seen action", commentAction);
                    }
                  }
                }
              });
            }
            comment?.comments?.items?.forEach((comment2) => {
              clog("Comment on underlying url", comment2);
              if (
                followings[comment2.curator.Id] ||
                comment2.curator.Id == userId
              ) {
                //clog("Comment by somebody you follow");
                comment2.actions.items.forEach((commentAction) => {
                  if (commentAction.createdAt > oldestTimestamp) {
                    if (
                      followings[comment2.curator.Id] &&
                      commentAction.operation == "Create"
                    ) {
                      clog("Relevant action", commentAction);
                      commentAction["actor"] =
                        followings[commentAction.actorId];
                      actionsOfInterest[commentAction.Id] = {
                        action: commentAction,
                        target: url,
                        justificationTarget: comment2,
                      };
                      if (!seenActions[commentAction.Id]) {
                        seenActions[commentAction.Id] = true;
                        notifications.push({
                          actionId: commentAction.Id,
                          actorId: commentAction.actor.Id,
                          creationTS: commentAction.creationTS,
                          operation: "Comment",
                          recipientId: userId,
                          targetType: "Comment",
                          targetId: comment.Id,
                          commentId: comment.Id,
                          urlId: url.Id,
                          createdAt: commentAction.createdAt,
                        });
                      } else {
                        console.log("ERROR: seen action", commentAction);
                      }
                    } else {
                      clog("Comment is not by somebody you follow");
                    }
                    if (
                      true &&
                      comment2.curator.Id == userId &&
                      commentAction.operation == "Like" &&
                      commentAction.actorId != userId
                    ) {
                      if (followings[commentAction.actorId]) {
                        commentAction["actor"] =
                          followings[commentAction.actorId];
                      }
                      actionsOfInterest[commentAction.Id] = {
                        action: commentAction,
                        target: url,
                        justificationTarget: comment2,
                      };
                      if (!seenActions[commentAction.Id]) {
                        seenActions[commentAction.Id] = true;
                        notifications.push({
                          actionId: commentAction.Id,
                          actorId: commentAction.actor.Id,
                          creationTS: commentAction.creationTS,
                          operation: "Like",
                          recipientId: userId,
                          targetType: "Comment",
                          targetId: comment2.Id,
                          commentId: comment2.Id,
                          urlId: url.Id,
                          createdAt: commentAction.createdAt,
                        });
                      } else {
                        console.log("ERROR: seen action", commentAction);
                      }
                    }
                  }
                });
              }
            });
          });
          let yourPin = null;
          url.pins.items?.forEach((pin) => {
            if (pin.curator.Id == userId) {
              yourPin = pin;
            }
          });
          url.pins.items?.forEach((pin) => {
            clog("Pin on underlying url", pin);
            if (
              (curated && followings[pin.curator.Id]) ||
              pin.curator.Id == userId
            ) {
              clog("Pin by somebody you follow");
              pin.actions.items.forEach((pinAction) => {
                if (pinAction.createdAt > oldestTimestamp) {
                  if (
                    curated &&
                    yourPin &&
                    followings[pin.curator.Id] &&
                    pinAction.operation == "Create"
                  ) {
                    clog("Relevant action", pinAction);
                    pinAction["actor"] = followings[pinAction.actorId];
                    actionsOfInterest[pinAction.Id] = {
                      action: pinAction,
                      target: url,
                      justificationTarget: pin,
                    };
                    if (!seenActions[pinAction.Id]) {
                      seenActions[pinAction.Id] = true;
                      notifications.push({
                        actionId: pinAction.Id,
                        actorId: pinAction.actorId,
                        creationTS: pinAction.creationTS,
                        operation: "Save",
                        recipientId: userId,
                        targetType: "Pin",
                        targetId: yourPin.Id,
                        pinId: yourPin.Id,
                        urlId: url.Id,
                        createdAt: pinAction.createdAt,
                      });
                      clog(
                        pinAction.actorId,
                        "SAVED",
                        url.uri,
                        "WITH",
                        pin.content
                      );
                    } else {
                      console.log("ERROR: seen action", pinAction);
                    }
                    clog(
                      "Pin is by somebody you follow and you have",
                      curated ? "curated" : "not curated"
                    );
                  } else {
                    clog("Pin is not by somebody you follow");
                  }
                  if (
                    true &&
                    pin.curator.Id == userId &&
                    pinAction.actorId != userId &&
                    pinAction.operation == "Like"
                  ) {
                    if (followings[pinAction.actorId]) {
                      pinAction["actor"] = followings[pinAction.actorId];
                    }
                    actionsOfInterest[pinAction.Id] = {
                      action: pinAction,
                      target: url,
                      justificationTarget: pin,
                    };
                    if (!seenActions[pinAction.Id]) {
                      seenActions[pinAction.Id] = true;
                      notifications.push({
                        actionId: pinAction.Id,
                        actorId: pinAction.actor.Id,
                        creationTS: pinAction.creationTS,
                        operation: "Like",
                        recipientId: userId,
                        targetType: "Pin",
                        targetId: pin.Id,
                        pinId: pin.Id,
                        urlId: url.Id,
                        createdAt: pinAction.createdAt,
                      });
                    } else {
                      console.log("ERROR: seen action", pinAction);
                    }
                  }
                }
              });
            }
            pin?.comments?.items?.forEach((comment2) => {
              clog("Comment on underlying url", comment2);
              if (
                followings[comment2.curator.Id] ||
                comment2.curator.Id == userId
              ) {
                //clog("Comment by somebody you follow");
                comment2.actions.items.forEach((commentAction) => {
                  if (commentAction.createdAt > oldestTimestamp) {
                    if (
                      followings[comment2.curator.Id] &&
                      commentAction.operation == "Create"
                    ) {
                      clog("Relevant action", commentAction);
                      commentAction["actor"] =
                        followings[commentAction.actorId];
                      actionsOfInterest[commentAction.Id] = {
                        action: commentAction,
                        target: url,
                        justificationTarget: comment2,
                      };
                      if (!seenActions[commentAction.Id]) {
                        seenActions[commentAction.Id] = true;
                        notifications.push({
                          actionId: commentAction.Id,
                          actorId: commentAction.actor.Id,
                          creationTS: commentAction.creationTS,
                          operation: "Comment",
                          recipientId: userId,
                          targetType: "Pin",
                          targetId: pin.Id,
                          pinId: pin.Id,
                          urlId: url.Id,
                          createdAt: commentAction.createdAt,
                        });
                      } else {
                        console.log("ERROR: seen action", commentAction);
                      }
                    } else {
                      clog("Comment is not by somebody you follow");
                    }
                    if (
                      true &&
                      comment2.curator.Id == userId &&
                      commentAction.operation == "Like" &&
                      commentAction.actorId != userId
                    ) {
                      if (followings[commentAction.actorId]) {
                        commentAction["actor"] =
                          followings[commentAction.actorId];
                      }
                      actionsOfInterest[commentAction.Id] = {
                        action: commentAction,
                        target: url,
                        justificationTarget: comment2,
                      };
                      if (!seenActions[commentAction.Id]) {
                        seenActions[commentAction.Id] = true;
                        notifications.push({
                          actionId: commentAction.Id,
                          actorId: commentAction.actor.Id,
                          creationTS: commentAction.creationTS,
                          operation: "Like",
                          recipientId: userId,
                          targetType: "Comment",
                          targetId: comment2.Id,
                          commentId: comment2.Id,
                          urlId: url.Id,
                          createdAt: commentAction.createdAt,
                        });
                      } else {
                        console.log("ERROR: seen action", commentAction);
                      }
                    }
                  }
                });
              }
            });
          });
        } else {
          clog(
            "BAD URL METADATA",
            url,
            "curated",
            curated,
            "commented",
            commented,
            "liked",
            liked
          );
        }
      });
    }

    let activities = [];

    clog("ALL ACTIONS OF INTEREST", actionsOfInterest);
    let massageEnd = performance.now();
    console.log("TIME= massage", (massageEnd - start) / 1000);

    status["actionsOfInterest"] = Object.values(actionsOfInterest).sort(
      (a, b) => (a.action.createdAt > b.action.createdAt ? -1 : 1)
    );
    status["notifications"] = notifications.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1
    );

    const diffNotifications = (a, b) => {
      //console.log("received", a, "and", b);
      let mismatch = false;
      let mismatchedFields = [];
      [
        "actionId",
        "actorId",
        "commentId",
        "creationTS",
        "operation",
        "pinId",
        "recipientId",
        "targetId",
        "targetType",
        "urlId",
        "userId",
      ].forEach((field) => {
        if (!a[field] && !b[field]) {
          // both missing
        } else if (a[field] != b[field]) {
          mismatch = true;
          mismatchedFields.push(field);
        } else {
          //console.log("\tIdentical", field, a[field]);
        }
      });
      return { mismatch: mismatch, mismatchedFields: mismatchedFields };
    };

    promises = [];
    status.notifications.forEach((notification) => {
      if (existingNotifications[notification.actionId]) {
        clog(
          "Notification exists with values",
          existingNotifications[notification.actionId]
        );
        let { mismatch, mismatchedFields } = diffNotifications(
          notification,
          existingNotifications[notification.actionId]
        );
        if (mismatch) {
          if (
            mismatchedFields.length == 1 &&
            mismatchedFields[0] == "operation" &&
            existingNotifications[notification.actionId].operation ==
              "Mention" &&
            notification.operation == "Comment"
          ) {
            console.log("IGNORING MENTION VS COMMENT MISMATCH");
          } else {
            console.log("mismatched fields are", mismatchedFields);
            console.log(
              "EXISTING",
              existingNotifications[notification.actionId],
              "NEW",
              notification
            );
            // SHOULD UPDATE THE DATA TO STORAGE
            let payload = {
              Id: existingNotifications[notification.actionId].Id,
            };
            mismatchedFields.forEach((field) => {
              payload[field] = notification[field];
            });
            console.log("WILL UPDATE WITH", payload);
            promises.push(
              API.graphql(graphqlOperation(updateNotification, payload))
            );
          }
        }
      } else {
        // SHOULD ADD THE DATA TO CLOUD
        notification["Id"] = generateUniqueId();
        promises.push(
          API.graphql(graphqlOperation(addNotification, notification))
        );
      }
    });
    responses = await Promise.all(promises);
    console.log("RESPONSES", responses);
    status["activities"] = activities;

    if (callback) {
      callback(status);
    }
    return status;
  } catch (err) {
    console.log("SOMETHING WENT WRONG", err);
    status.success = false;
    status["message"] = "error fetching data...";
    status.error = err;
    if (callback) {
      callback(status);
    }
    return status;
  }
}
