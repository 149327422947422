import React from "react";
import { View, Text, Image } from "react-native";
import { clog } from "../utils/Log";

const List = ({ item, myContext, colors }) => {
  clog("render list", item);
  if (item == null) {
    return <View></View>;
  }
  let photos = [];
  let i;
  if (
    myContext.actionsByUser?.["Experiment"]?.["Join"]?.["ShowProblems"] !=
      null ||
    item?.pins?.items?.length
  ) {
    for (i = 0; i < item?.pins?.items?.length && photos.length < 4; i++) {
      let url = item?.pins?.items?.[i]?.url;
      if (url) {
        let photo = null;
        if (url.photo && myContext.presignedUrls[url.photo]) {
          photo = myContext.presignedUrls[url.photo]?.uri;
        }
        if (!photo && url?.photoUrl) {
          photo = url.photoUrl;
        }
        if (photo && !item.screenshotUrl) {
          item.screenshotUrl = photo;
        }
        if (photo) {
          photos.push(photo);
        }
      }
    }
  }
  let topicIds = {};
  let topics = [];
  if (
    myContext.actionsByUser?.["Experiment"]?.["Join"]?.["ShowProblems"] !=
      null ||
    item?.pins?.items?.length
  ) {
    item?.pins?.items?.forEach((p) => {
      if (p?.url?.topicIds) {
        let tuples = p?.url?.topicIds.split(",");
        tuples.forEach((tuple) => {
          let values = tuple.split(":");
          if (values?.length == 2) {
            let topicId = values[0];
            let count = values[1];
            if (topicIds[topicId]) {
              topicIds[topicId] += parseInt(count);
            } else {
              topicIds[topicId] = parseInt(count);
            }
          }
        });
      }
    });
    // TODO(alpha): Investigate how topicIds are getting inaccurate for lists
    /*if (item?.topicIds != "") {
      let tuples = item.topicIds.split(",");
      tuples.forEach((tuple) => {
        let values = tuple.split(":");
        if (values?.length == 2) {
          let topicId = values[0];
          let count = values[1];
          topicIds[topicId] = count;
        }
      });
    }*/
  }
  clog("TOPIC IDS", topicIds);
  Object.keys(topicIds)
    ?.sort((a, b) => {
      let freqA = topicIds[a];
      let freqB = topicIds[b];
      return freqA != freqB ? (freqA > freqB ? -1 : 1) : a > b ? -1 : 1;
    })
    .map((key) => {
      if (myContext?.topics?.[key]) {
        topics.push({
          name: myContext.topics[key].name,
          frequency: topicIds[key],
          Id: key,
        });
      }
    });
  clog("TOPICS", topics);
  return (
    <View
      style={{
        flexDirection: "row",
        marginLeft: 16,
        marginTop: 10,
      }}
    >
      <View
        style={{
          width: 84,
          height: 84,
          flexDirection: "row",
          borderRadius: 10,
          overflow: "hidden",
          backgroundColor: colors.messageBackground,
        }}
      >
        <View style={{ flexDirection: "column" }}>
          {photos?.[0] != null && (
            <Image
              source={{
                uri: photos[0],
                cache: "force-cache",
              }}
              style={{
                width: 42,
                height: 42,
                resizeMode: "cover",
              }}
            />
          )}
          {photos?.[1] != null && (
            <Image
              source={{
                uri: photos[1],
                cache: "force-cache",
              }}
              style={{
                width: 42,
                height: 42,
                resizeMode: "cover",
              }}
            />
          )}
        </View>
        <View style={{ flexDirection: "column" }}>
          {photos?.[2] != null && (
            <Image
              source={{
                uri: photos[2],
                cache: "force-cache",
              }}
              style={{
                width: 42,
                height: 42,
                resizeMode: "cover",
              }}
            />
          )}
          {photos?.[3] != null && (
            <Image
              source={{
                uri: photos[3],
                cache: "force-cache",
              }}
              style={{
                width: 42,
                height: 42,
                resizeMode: "cover",
              }}
            />
          )}
        </View>
      </View>
      <View
        style={{
          flexDirection: "column",
          marginLeft: 17,
          marginTop: 16,
          marginRight: 16,
          flex: 1,
        }}
      >
        <Text
          style={{ fontSize: 16, color: colors.primaryText }}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {item?.name}
        </Text>
        <View style={{ flexDirection: "row", marginTop: 7 }}>
          {item?.topicIds != "" && topics?.[0] && (
            <Text
              style={{
                backgroundColor: colors.secondaryBackground,
                color: colors.primaryText,
                padding: 4,
                //alignSelf: "flex-start",
                borderRadius: 4,
              }}
            >
              {topics?.[0]?.name}
            </Text>
          )}
          {item?.topicIds != "" && topics?.[1] && (
            <Text
              style={{
                backgroundColor: colors.secondaryBackground,
                color: colors.primaryText,
                padding: 4,
                //alignSelf: "flex-start",
                borderRadius: 4,
                marginLeft: 4,
              }}
            >
              {topics?.[1]?.name}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default List;
