import React, { useContext, useEffect } from "react";
import {
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  Animated,
  Easing,
} from "react-native";
import { Auth } from "aws-amplify";

import AppContext from "../components/AppContext";
import { getUserData, updateUserData } from "../utils/DataFetcher";
import home1 from "../assets/home_1_1.png";
import cloud1 from "../assets/cloud_1.png";
import cloud2 from "../assets/cloud_2.png";
import villageLogo from "../assets/village-logo.png";
import { clog } from "../utils/Log";
import { useTheme } from "../theme";

const WelcomeScreen = ({ route, navigation }) => {
  clog("IN WELCOME SCREEN");

  const { colors } = useTheme();
  const myContext = useContext(AppContext);

  const opacityAnimation = new Animated.Value(0);
  const transformAnimation = new Animated.Value(0);
  const transformCloudLeft = new Animated.Value(0);
  const transformCloudRight = new Animated.Value(0);

  useEffect(() => {
    Animated.timing(opacityAnimation, {
      toValue: 1,
      delay: 500,
      duration: 1000,
      easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
      useNativeDriver: true,
    }).start();
    Animated.timing(transformAnimation, {
      toValue: -70,
      delay: 500,
      duration: 1200,
      easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
      useNativeDriver: true,
    }).start();
    Animated.timing(transformCloudLeft, {
      toValue: 110,
      delay: 500,
      duration: 1200,
      easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
      useNativeDriver: true,
    }).start();
    Animated.timing(transformCloudRight, {
      toValue: -170,
      delay: 500,
      duration: 1200,
      easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
      useNativeDriver: true,
    }).start();
  }, []);

  async function fetchDataAndMove() {
    try {
      let { username, attributes } = await Auth.currentAuthenticatedUser();
      if (username == "guest") {
        clog("Came in with guest identity");
        myContext.authenticated = false;
        try {
          Auth.signOut();
        } catch (err) {
          console.log("not authenticated", err);
        }
      }
      clog("myContext before data fetch", myContext, "username", username);
      //username = "ary";
      let start = performance.now();
      let { success, data } = await getUserData(username);
      let fetchDone = performance.now();
      console.log("TIME: user fetch overall", (fetchDone - start) / 1000);
      clog("success", success, "data", data);
      if (success) {
        updateUserData(myContext, data, (askForPermission) => {
          console.log("ask for permission", askForPermission);
          clog("my context", myContext);
          if (askForPermission) {
            if (!myContext.Id) {
              myContext.onboarding = true;
            }
            navigation.replace("Permission", {
              nextScreen: myContext.Id ? "tab" : "Profile",
            });
          } else {
            if (myContext.Id) {
              navigation.replace("tab");
            } else {
              myContext.onboarding = true;
              navigation.replace("Profile");
            }
          }
          setTimeout(function () {
            myContext["fetchingFromWelcome"] = false;
          }, 1000);
        });
      } else {
        clog("error, failed to fetch user data");
      }
    } catch (err) {
      console.log("not authenticated", err);
    }
  }

  clog("ROUTE IN WELCOME", route);
  clog("CONTEXT IN WELCOME", myContext);

  if (myContext.authenticated) {
    if (!myContext.fetchingFromWelcome) {
      myContext["fetchingFromWelcome"] = true;
      fetchDataAndMove();
    }
  }

  const Title = () => {
    return (
      <>
        <Text
          style={{
            fontSize: 16,
            fontWeight: "400",
            color: colors.secondaryText,
          }}
        >
          {"Welcome to"}
        </Text>
        <Image
          source={villageLogo}
          style={{
            height: 36.7,
            width: 227.23,
            tintColor: "rgb(251, 197, 59)",
            marginTop: 12,
            marginBottom: 37.3,
          }}
        />
      </>
    );
  };

  const Description = () => {
    return (
      <View>
        <Text
          style={[styles.description, { color: colors.primaryText }]}
          suppressHighlighting={true}
        >
          {" Discover the"}
          <Text
            style={[
              styles.descriptionEmphasized,
              { color: colors.primaryText },
            ]}
          >
            {" best content"}
          </Text>
        </Text>
        <Text style={[styles.description, { color: colors.primaryText }]}>
          {"in one place"}
        </Text>
      </View>
    );
  };

  const Buttons = () => {
    return (
      <Animated.View
        style={[styles.buttonsContainer, { opacity: opacityAnimation }]}
      >
        {!myContext.fetching && !myContext.authenticated && (
          <TouchableOpacity
            style={[
              styles.button,
              {
                height: 51,
                bottom: 71,
                borderRadius: 100,
                backgroundColor: colors.primaryButtonBackground,
              },
            ]}
            onPress={() => {
              navigation.push("SignUp");
            }}
          >
            <Text
              style={[
                styles.buttonTitle,
                {
                  color: colors.primaryButtonText,
                },
              ]}
            >
              {"New villager"}
            </Text>
          </TouchableOpacity>
        )}
        {!myContext.fetching && !myContext.authenticated && (
          <TouchableOpacity
            onPress={() => {
              console.log("CLICKED SIGN IN");
              navigation.push("SignIn");
            }}
            style={[
              styles.button,
              {
                height: 41,
                bottom: 20,
              },
            ]}
          >
            <Text
              style={[
                styles.buttonTitle,
                {
                  color: colors.primaryText,
                },
              ]}
            >
              {"Sign in"}
            </Text>
          </TouchableOpacity>
        )}
      </Animated.View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: colors.background,
      }}
    >
      <SafeAreaView
        style={{
          backgroundColor: colors.background,
        }}
      />
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <Animated.View
          style={{
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 16,
            opacity: opacityAnimation,
          }}
        >
          <Title />
          <Description />

          <Animated.View
            style={{
              position: "absolute",
              top: 150,
              left: -100,
              height: 160,
              width: 160,
              overflow: "hidden",
              transform: [
                {
                  translateX: transformCloudLeft,
                },
              ],
            }}
          >
            <Image
              resizeMode="cover"
              source={cloud1}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Animated.View>

          <Animated.View
            style={{
              top: 40,
              height: 650,
              width: 500,
              overflow: "hidden",
              transform: [
                {
                  translateY: transformAnimation,
                },
              ],
            }}
          >
            <Image
              resizeMode="cover"
              source={home1}
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
            />
          </Animated.View>

          <Animated.View
            style={{
              position: "absolute",
              bottom: 100,
              right: -200,
              height: 170,
              width: 170,
              overflow: "hidden",
              transform: [
                {
                  translateX: transformCloudRight,
                },
              ],
            }}
          >
            <Image
              resizeMode="cover"
              source={cloud2}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Animated.View>
        </Animated.View>

        <Buttons />
      </View>
    </View>
  );
};

export default WelcomeScreen;

const styles = StyleSheet.create({
  description: {
    fontSize: 20,
    fontWeight: "400",
    textAlign: "center",
  },
  descriptionEmphasized: {
    fontSize: 20,
    fontWeight: "600",
  },
  buttonsContainer: {
    position: "absolute",
    bottom: 20,
    alignSelf: "center",
  },
  button: {
    position: "absolute",
    width: 357,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonTitle: {
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
  },
});
