import React, { useState } from "react";
import { StyleSheet, Text, View, FlatList, Pressable } from "react-native";

import FeedbackCard from "./FeedbackCard";

const FeedbackList = ({
  state,
  myContext,
  navigation,
  renderHeader,
  refererActionId,
  hostUserId,
  colors,
}) => {
  const [loading, setLoading] = useState(false);

  const renderItem = ({ item }) => {
    return (
      <View
        style={[
          styles.feedback,
          { backgroundColor: colors.secondaryBackground },
        ]}
      >
        <View style={{ flex: 1, marginRight: 10 }}>
          <FeedbackCard
            item={item}
            myContext={myContext}
            colors={colors}
            navigation={navigation}
            fromList={true}
          />
        </View>
      </View>
    );
  };

  const sharedKeyExtractor = (item) => item.Id;
  console.log("feedback state", state);
  return (
    <FlatList
      title={state?.relationship}
      data={state?.feedbacks}
      renderItem={renderItem}
      keyExtractor={sharedKeyExtractor}
      extraData={state}
    />
  );
};

export default FeedbackList;

const styles = StyleSheet.create({
  feedback: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 16,
    paddingLeft: 20,
    paddingRight: 25,
    marginTop: 10,
    height: 69,
  },
  coloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
  },
  uncoloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
    borderWidth: 2,
  },
  text: { fontSize: 12 },
});
