import { React } from "react";
import { Platform, Share, View, Image, Text } from "react-native";
import check from "../assets/check.png";
import { villageColors } from "./SharedStyles";
import * as Clipboard from "expo-clipboard";
import { clog } from "../utils/Log";

export async function shareIfPossible(
  objectType,
  handle,
  name /* title for url */,
  Id,
  sharerId,
  sharerHandle,
  sharerName,
  alertMessage,
  ownerName,
  own,
  size
) {
  let url = "https://app.projectvillage.io/";
  let title = "";
  if (objectType == "List") {
    url =
      url +
      "list/" +
      handle +
      "/" +
      encodeURIComponent(name) +
      "/" +
      Id +
      "/" +
      sharerId;
    title =
      (own
        ? "Check out my collection: "
        : "Check out " + ownerName + "'s collection: ") +
      '"' +
      name +
      '"' +
      " with " +
      size +
      " contents on Village 🏘️: " +
      url;
  } else if (objectType == "User") {
    url = url + "user/" + handle + "/" + Id + "/" + sharerId;
    title =
      (own ? "Check out my profile" : "Check out " + ownerName + "'s profile") +
      " with " +
      size +
      " collections on Village 🏘️: " +
      url;
  } else if (objectType == "Item") {
    url = url + "item/" + Id + "/" + sharerId;
    title = "Check out " + '"' + name + '"' + " I found on Village 🏘️: " + url;
  }
  try {
    let content = {};
    let options = {};
    if (Platform.OS == "android") {
      content = { message: title, title: title };
      options = { dialogTitle: title };
    } else if (Platform.OS == "ios") {
      content = {
        message: title,
        // url: url
      };
      options = { subject: title, tintColor: villageColors.Button };
    } else if (Platform.OS == "web") {
      content = { message: url, title: title };
      options = { subject: title };
    }
    if (Platform.OS === "web") {
      await Clipboard.setStringAsync(title);
      if (alertMessage) {
        alertMessage(
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              paddingLeft: 12,
            }}
          >
            <Image
              source={check}
              style={{
                height: 24,
                width: 24,
                tintColor: "black",
                marginRight: 4,
              }}
            />
            <View style={{ flexDirection: "column", alignItems: "flex-start" }}>
              <Text style={{ fontSize: 16 }}>
                Sharing message copied to your clipboard
              </Text>
            </View>
          </View>
        );
      }
      return { success: true };
    }

    const result = await Share.share(content, options);
    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
        clog("Shared with", result.activityType);
      } else {
        // shared
        clog("shared", result);
      }
      // TODO(alpha): Create an action and update counters
    } else if (result.action === Share.dismissedAction) {
      // dismissed
      clog("sharing got dismissed");
    }
    return { success: true };
  } catch (error) {
    clog("sharing had error", error);
    return { success: false, message: error.message };
  }

  /*try {
      let canShare = await Sharing.isAvailableAsync();
      if (canShare) {
        let url = "https://app.projectvillage.io/";
        if (objectType == "List") {
          url =
            url + "list/" + handle + "/" + encodeURIComponent(name) + "/" + Id;
        }
        clog("can share", url);
        let outcome = await Sharing.shareAsync(url, {
          dialogTitle: url,
          mimeType: "text",
        });
        clog("share outcome", outcome);
      } else {
        clog("cannot share");
      }
    } catch (err) {
      console.log("error", err);
    }*/
}
