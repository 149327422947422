import React, { useState, useContext } from "react";
import {
  Alert,
  Image,
  Keyboard,
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";

import AwesomeAlert from "react-native-awesome-alerts";
import AppContext from "../components/AppContext";
import Blocker from "../components/Blocker";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { clog } from "../utils/Log";
import { createList, updateList } from "../controllers/ListController";
import { logEvent } from "../utils/LogEvent";
import cross from "../assets/cross.png";
import { useTheme } from "../theme";
import ColoredButton from "../components/ColoredButton";

function ContentListUpdateScreen({ route, navigation }) {
  async function createAList() {
    setLoading(true);
    createList({
      name: name,
      description: description,
      myContext: myContext,
      callback: ({ success, message, error, listId, list }) => {
        clog("FINISHED CREATING LIST", success, listId);
        setLoading(false);
        logEvent("MyProfile_CreateCollection_Create", {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: "myprofile",
          platform: Platform.OS,
          target: "collection",
          action: "click",
          status: success ? "success" : "failure",
          name: name,
          appVersion: myContext.appVersion,
        });
        if (success) {
          clog("Will go back after change", myContext);
          myContext["recentlyCreatedList"] = list;
          navigation.goBack();
        } else {
          showMessage("Failed to create Collection!");
        }
      },
    });
  }

  async function updateAList() {
    setLoading(true);
    console.log("Update collection", name, "with desc", description);
    updateList({
      listId: listId,
      name: name,
      description: description,
      myContext: myContext,
      callback: (success, message, error) => {
        setLoading(false);
        logEvent("MyProfile_CollectionDetail_Edit_Edit_Save", {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: "myprofile",
          platform: Platform.OS,
          target: "collection",
          action: "click",
          status: success ? "success" : "failure",
          name: name,
          appVersion: myContext.appVersion,
        });
        if (success) {
          navigation.goBack();
        } else {
          showMessage(message);
        }
      },
    });
  }

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed") },
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const { colors } = useTheme();

  const [name, setName] = useState(
    route?.params?.name == null ? "" : route.params.name
  );
  const [description, setDescription] = useState(
    route?.params?.description == null ? "" : route.params.description
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [conditionMet, setConditionMet] = useState(name != "");

  const handleSubmit = (collectionName) => {
    let message = "";
    console.log("NAME IS", collectionName);
    if (!collectionName) {
      message = "Must provide name";
    }
    if (message) {
      showMessage(message);
    } else {
      if (updating) {
        updateAList();
      } else {
        createAList();
      }
    }
  };

  let passedId = route?.params?.Id;

  if (myContext) {
    if (!passedId) {
      passedId = myContext?.Id;
    }
  }
  let updating = route?.params?.updating;
  let listId = route?.params?.listId;
  let origin = route?.params?.origin;

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <Pressable onPress={navigation.goBack}>
            <View
              style={{
                height: 25,
                width: 25,
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 12,
              }}
            >
              <Image
                source={cross}
                style={{
                  height: 10,
                  width: 10,
                  tintColor: colors.primaryText,
                }}
              />
            </View>
          </Pressable>
        );
      },
      headerBackTitleVisible: false,
      headerTitle: updating ? "Edit collection" : "New collection",
      headerRight: () => {
        return (
          <ColoredButton
            onPress={() => {
              console.log("NAME IS", name);
              handleSubmit(name);
            }}
            buttonStyle={{
              alignItems: "center",
              justifyContent: "center",
              //height: 54,
              marginRight: 16,
              padding: 10,
              borderRadius: 16,
              backgroundColor: conditionMet
                ? colors.primaryButtonBackground
                : colors.disabledPrimaryButtonBackground,
            }}
            textStyle={{
              textAlign: "center",
              textAlignVertical: "center",
              fontSize: 15,
              fontWeight: "700",
              color: conditionMet
                ? colors.primaryButtonText
                : colors.disabledPrimaryButtonText,
            }}
            text={updating != null ? "Update" : "Create"}
          ></ColoredButton>
        );
      },
    });
    logEvent(
      updating
        ? "MyProfile_UpdateCollection_View"
        : "MyProfile_CreateCollection_View",
      {
        userId: myContext?.Id,
        username: myContext?.handle,
        category: "myprofile",
        platform: Platform.OS,
        appVersion: myContext.appVersion,
      }
    );
  }, [navigation, name, description, colors]);

  if (!passedId) {
    return <View></View>;
  } else {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: colors.background,
          alignItems: "center",
        }}
      >
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          <Blocker loading={loading} />
          <KeyboardAwareScrollView>
            <View style={styles.inputContainer}>
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "400",
                  marginTop: 38,
                  marginBottom: 8,
                }}
              >
                Name*
              </Text>
              <TextInput
                style={[
                  styles.textInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                  },
                ]}
                placeholder="Like 'All-time favorites'"
                placeholderTextColor={colors.placeholderText}
                value={name}
                maxLength={200}
                onChangeText={(newValue) => {
                  console.log("Setting name to(" + newValue + ")");
                  setName((preValue) => {
                    console.log("Previous name", preValue);
                    return newValue;
                  });
                  if (newValue) {
                    setConditionMet(true);
                  } else {
                    setConditionMet(false);
                  }
                }}
                onBlur={Keyboard.dismiss}
              />
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 16,
                  fontWeight: "400",
                  marginTop: 24,
                  marginBottom: 8,
                }}
              >
                Description
              </Text>
              <TextInput
                style={[
                  styles.textAreaInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                  },
                ]}
                placeholder="What ties all of the content together?"
                placeholderTextColor={colors.placeholderText}
                value={description}
                maxLength={255}
                multiline={true}
                onChangeText={(newValue) => setDescription(newValue)}
                onBlur={Keyboard.dismiss}
              />
            </View>
            <AwesomeAlert
              show={showAlert}
              showProgress={false}
              title=""
              message={alertMessage}
              closeOnTouchOutside={true}
              closeOnHardwareBackPress={false}
              showCancelButton={true}
              cancelText="Ok"
              cancelButtonColor={colors.cancelButton}
              onCancelPressed={() => {
                setShowAlert(false);
              }}
            />
          </KeyboardAwareScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

export default ContentListUpdateScreen;

const styles = StyleSheet.create({
  inputContainer: {
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
  },
  textInput: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 47,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 16,
    borderWidth: 1,
  },
  textAreaInput: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 150,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    borderRadius: 16,
    borderWidth: 1,
    alignContent: "flex-start",
  },
});
