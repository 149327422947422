import { clog } from "../utils/Log";

export function findTopActions(item, myContext, hostUserId, screen) {
  clog("ASKED TO FIND TOP ACTIONS", item, hostUserId);
  let actions = pullActionsFromUrl(item, myContext);
  let detailedActions = addDetailsToActions(actions, myContext, hostUserId);
  let orderedActions = orderActions(detailedActions, screen);
  clog("ORDERED ACTIONS", { ...orderedActions }, "item", item);
  // find the highest priority action by the host and then chain back to the right viewed object
  let topAction = null;
  if (hostUserId) {
    for (let i = 0; orderedActions && i < orderedActions.length; i++) {
      let a = orderedActions[i];
      clog("CONSIDER", a, "TOP", topAction);
      if (
        !topAction &&
        a?.action?.actorId == hostUserId &&
        (a?.action?.objectType == "Pin" ||
          a?.action?.objectType == "User" ||
          a?.action?.objectType == "List" ||
          a?.action?.refererActionId)
      ) {
        topAction = a;
        break;
      }
    }
    clog("TOP ACTION", topAction);
    let done = false;
    while (!done && topAction) {
      if (
        topAction.action.objectType == "Pin" ||
        topAction.action.objectType == "User" ||
        topAction.action.objectType == "List"
      ) {
        topAction["selected"] = true;
        clog("Target is good!!!");
        done = true;
      } else if (topAction.action.refererActionId) {
        clog("Will need to follow chain");
        let targetActionId = topAction.action.referActionId;
        topAction = null;
        for (let i = 0; orderedActions && i < orderedActions.length; i++) {
          let a = orderedActions[i];
          if (a.action.actorId == targetActionId) {
            topAction = a;
            break;
          }
        }
      } else {
        clog("NO IDEA WHAT TO DO WITH the action", orderedActions);
        done = true;
      }
    }
  }
  // Now time to chain to the right object
  /*let dedupedActions = dedupActions(orderedActions);
  return dedupedActions; */
  return orderedActions;
}

export function annotateAction(action, myContext, hostUserId) {
  clog("ANNOTATE", action);
  let actions = [action];
  let detailedActions = addDetailsToActions(actions, myContext, hostUserId);
  let orderedActions = orderActions(detailedActions);
  clog("OUTPUT", orderActions);
  return orderedActions;
}

function pullActionsFromUrl(item) {
  let pins = item?.pins?.items ? item.pins.items : item?.pins;
  let comments = item?.comments?.items ? item.comments.items : item?.comments;
  let actions = [];
  pins?.forEach((p) => {
    //clog("FIND ACTIONS FOR PIN", p);
    p?.actions?.items?.forEach((a) => {
      actions.push({
        action: a,
        target: p,
      });
    });
    /*p?.comments?.items?.forEach((c) => {
      c?.actions?.items?.forEach((a) => {
        actions.push({
          action: a,
          target: c,
        });
      });
    });*/
  });
  comments?.forEach((c) => {
    //clog("FIND ACTIONS FOR COMMENT", c);
    c?.actions?.items?.forEach((a) => {
      actions.push({
        action: a,
        target: c,
      });
    });
    /*c?.comments?.items?.forEach((c2) => {
      c2?.actions?.items?.forEach((a) => {
        actions.push({
          action: a,
          target: c2,
        });
      });
    });*/
  });
  return actions;
}

export function addDetailsToActions(actions, myContext, hostUserId) {
  let detailedActions = [];
  actions?.forEach((a) => {
    if (a.action.operation != "View") {
      let details = detailsFromAction(a.action, myContext, hostUserId);
      detailedActions.push({
        action: a.action,
        target: a.target,
        details: details,
      });
    }
  });
  return detailedActions;
}

function orderActions(actions, screen) {
  clog("UNSORTED ACTIONS", "at", screen, "with", actions);
  let sortedActions = [];
  if (screen == "discover" || screen == "village") {
    actions
      .sort((a, b) => {
        if (a.details.testUser != b.details.testUser) {
          return a.details.testUser ? 1 : -1;
        }
        if (a.details.host != b.details.host) {
          return a.details.host ? -1 : 1;
        }
        if (a.details.followed != b.details.followed) {
          return a.details.followed ? -1 : 1;
        }
        if (a.details.pinCreate != b.details.pinCreate) {
          return a.details.pinCreate ? -1 : 1;
        }
        if (
          a.details.pinCreate &&
          b.details.pinCreate &&
          a.details.hasRichShareNote != b.details.hasRichShareNote
        ) {
          clog("RICH NOTE BASED SORTING", a.details, b.details);
          if (a.details.hasRichShareNote) {
            return -1;
          } else {
            return 1;
          }
        }
        if (
          a.details.pinCreate &&
          b.details.pinCreate &&
          a.details.hasNote != b.details.hasNote
        ) {
          clog("NOTE BASED SORTING", a.details, b.details);
          if (a.details.hasNote) {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.details.commentCreate != b.details.commentCreate) {
          return a.details.commentCreate ? -1 : 1;
        }
        if (a.details.pinLike != b.details.pinLike) {
          return a.details.pinLike ? -1 : 1;
        }
        if (a.details.commentLike != b.details.commentLike) {
          return a.details.commentLike ? -1 : 1;
        }
        if (a.details.createdAt != b.details.createdAt) {
          return a.details.createdAt < b.details.createdAt ? -1 : 1;
        }
        return a.action.Id < b.action.Id ? -1 : 1;
      })
      .forEach((element) => {
        sortedActions.push(element);
      });
  } else {
    actions
      .sort((a, b) => {
        if (a.details.host != b.details.host) {
          return a.details.host ? -1 : 1;
        }
        if (a.details.testUser != b.details.testUser) {
          return a.details.testUser ? 1 : -1;
        }
        if (a.details.self != b.details.self) {
          return a.details.self ? -1 : 1;
        }
        if (a.details.followed != b.details.followed) {
          return a.details.followed ? -1 : 1;
        }
        if (a.details.pinCreate != b.details.pinCreate) {
          return a.details.pinCreate ? -1 : 1;
        }
        if (
          a.details.pinCreate &&
          b.details.pinCreate &&
          a.details.hasRichShareNote != b.details.hasRichShareNote
        ) {
          clog("NOTE BASED SORTING", a.details, b.details);
          if (a.details.hasRichShareNote) {
            return -1;
          } else {
            return 1;
          }
        }
        if (
          a.details.pinCreate &&
          b.details.pinCreate &&
          a.details.hasNote != b.details.hasNote
        ) {
          clog("NOTE BASED SORTING", a.details, b.details);
          if (a.details.hasNote) {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.details.commentCreate != b.details.commentCreate) {
          return a.details.commentCreate ? -1 : 1;
        }
        if (a.details.pinLike != b.details.pinLike) {
          return a.details.pinLike ? -1 : 1;
        }
        if (a.details.commentLike != b.details.commentLike) {
          return a.details.commentLike ? -1 : 1;
        }
        if (a.details.createdAt != b.details.createdAt) {
          return a.details.createdAt > b.details.createdAt ? -1 : 1;
        }
        return a.action.Id < b.action.Id ? -1 : 1;
      })
      .forEach((element) => {
        sortedActions.push(element);
      });
  }
  clog("SORTED ACTIONS with", screen, sortedActions);
  return sortedActions;
}

function dedupActions(actions) {
  let dedupedActions = [];
  let seenTargets = {};
  if (actions) {
    actions.forEach((a) => {
      if (!seenTargets[a.target.Id]) {
        seenTargets[a.target.Id] = true;
        dedupedActions.push(a);
      }
    });
  }
  clog("DEDUPED ACTIONS", dedupedActions);
  return dedupedActions;
}

export function detailsFromAction(a, myContext, hostUserId) {
  clog("A is", a, "CONTEXT", myContext);
  let details = {
    host: a.actorId == hostUserId,
    self: a.actorId == myContext.Id,
    // TODO(alpha): Accesses key only
    followed:
      myContext.actionsByUser?.["User"]?.["Follow"]?.[a.actorId] != null,
    pinCreate: a.operation == "Create" && a.objectType == "Pin",
    commentCreate: a.operation == "Create" && a.objectType == "Comment",
    pinLike: a.peration == "Like" && a.objectType == "Pin",
    commentLike: a.operation == "Like" && a.objectType == "Comment",
    createdAt: a.createdAt,
    hasNote: a.hasNote,
    hasRichShareNote: a.hasRichShareNote,
    testUser: myContext.users?.[a.actorId]?.label?.match("test") ? true : false,
  };
  details["connected"] = details.self || details.followed || details.host;
  if (details.pinCreate) {
    clog("DETAILS", details.hasNote, a);
  }
  return details;
}

export function reorderActions(actions) {
  clog("UNSORTED ACTIONS", actions);
  let sortedActions = [];
  actions
    ?.sort((a, b) => {
      /*if (a?.details == null) {
        return 1;
      }
      if (b?.details == null) {
        return -1;
      }*/
      if (a.details.testUser != b.details.testUser) {
        return a.details.testUser ? 1 : -1;
      }
      if (a.details.pinCreate != b.details.pinCreate) {
        if (a.details.pinCreate && (a.details.self || a.details.followed)) {
          return -1;
        } else if (
          b.details.pinCreate &&
          (b.details.self || b.details.followed)
        ) {
          return 1;
        }
      }
      if (a.details.pinCreate && b.details.pinCreate) {
        if (a.details.self != b.details.self) {
          return a.details.self ? -1 : 1;
        }
        if (a.details.followed != b.details.followed) {
          return a.details.followed ? -1 : 1;
        }
      }
      if (a.details.hasRichShareNote != b.details.hasRichShareNote) {
        clog("NOTE BASED SORTING", a.details, b.details);
        if (a.details.hasRichShareNote) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a.details.hasNote != b.details.hasNote) {
        clog("NOTE BASED SORTING", a.details, b.details);
        if (a.details.hasNote) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a.details.createdAt != b.details.createdAt) {
        return a.details.createdAt < b.details.createdAt ? -1 : 1;
      }
      return a.action.Id < b.action.Id ? -1 : 1;
    })
    ?.forEach((element) => {
      sortedActions.push(element);
    });
  clog("RESORTED ACTIONS", sortedActions);
  return sortedActions;
}
