import React from "react";
import { View, Text, StyleSheet, Platform, Image } from "react-native";
import Avatar from "./Avatar";
import { shareIfPossible } from "../utils/ShareOut";
import { clog } from "../utils/Log";
import { logEvent } from "../utils/LogEvent";
import location from "../assets/location.png";

async function shareUser(
  handle,
  name,
  Id,
  sharerId,
  sharerHandle,
  sharerName,
  showStatusMessage,
  size
) {
  let { success, message } = await shareIfPossible(
    "User",
    handle,
    name,
    Id,
    sharerId,
    sharerHandle,
    sharerName,
    showStatusMessage,
    name,
    Id == sharerId,
    size
  );
  if (!success) {
    clog(message);
  }
  logEvent("Share_Profile", {
    userId: sharerId,
    username: sharerHandle,
    category: "profile",
    platform: Platform.OS,
    profile_id: Id,
    action: "click",
    target: "share",
    status: success ? "success" : "failure",
    appVersion: myContext.appVersion,
  });
}

const ProfileCard = ({
  item,
  facts,
  follows,
  followers,
  myContext,
  showStatusMessage,
  colors,
}) => {
  clog(
    "asked to render profile follows",
    follows,
    "followers",
    followers,
    item,
    facts
  );
  if (item == null) {
    return <View></View>;
  }
  clog("VIEWER", myContext.Id, "SEEING", item.Id);
  // construct the follow text
  let followText = "";
  if (followers?.length > 0) {
    followText = "Followed by " + followers[0]?.name;
    clog("FOLLOW TEXT 1", followText);
  }
  if (followers?.length > 2) {
    followText += ", " + followers[1]?.name;
    clog("FOLLOW TEXT 2", followText);
  } else if (followers?.length == 2) {
    followText += " and " + followers[1]?.name;
    clog("FOLLOW TEXT 3", followText);
  }
  if (followers?.length == 3) {
    followText += " and " + followers[2]?.name;
    clog("FOLLOW TEXT 4", followText);
  } else if (followers?.length > 3) {
    followText += ", " + followers[2]?.name;
    clog("FOLLOW TEXT 5", followText);
    followText +=
      " and " +
      String(followers?.length - 3) +
      (followers?.length - 3 == 1 ? " other" : " others");
    clog("FOLLOW TEXT 6", followText);
  }
  return (
    <View style={styles.item}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 12,
        }}
      >
        <Avatar
          item={item}
          style={{
            height: 72,
            width: 72,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 36,
            backgroundColor: colors.primaryButtonBackground,
            alignContent: "center",
          }}
          textstyle={{
            fontSize: 48,
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            color: colors.primaryButtonText,
          }}
          myContext={myContext}
        />
      </View>
      <View>
        <Text style={[styles.name, { color: colors.primaryText }]}>
          {item?.name}
        </Text>
        <View style={{ flexDirection: "row", marginTop: 2, marginBottom: 8 }}>
          <Text style={[styles.handle, { color: colors.secondaryText }]}>
            {"@" + item?.handle}
          </Text>
        </View>
        {true && facts}
        {item?.headline ? (
          <Text style={[styles.headline, { color: colors.primaryText }]}>
            {item?.headline}
          </Text>
        ) : null}
        {item?.location && (
          <View
            style={{ marginTop: 8, flexDirection: "row", alignItems: "center" }}
          >
            <Image
              source={location}
              style={{
                width: 17,
                height: 17,
                tintColor: colors.primaryText,
                marginRight: 2,
              }}
            />
            <Text
              style={{
                fontSize: 15,
                lineHeight: 22,
                color: colors.primaryText,
              }}
            >
              {item.location}
            </Text>
          </View>
        )}
        {followers != null && followers.length > 0 && (
          <View
            style={{
              flexDirection: "row",
              marginTop: 8,
            }}
          >
            {followers?.length > 0 && (
              <Avatar
                item={followers[0]}
                style={[styles.facepile, { color: colors.primaryText }]}
                textstyle={{
                  fontSize: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
                myContext={myContext}
              />
            )}
            {followers?.length > 1 && (
              <Avatar
                item={followers[1]}
                style={[styles.facepile, { color: colors.primaryText }]}
                textstyle={{
                  fontSize: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
                myContext={myContext}
              />
            )}
            {followers?.length > 2 && (
              <Avatar
                item={followers[2]}
                style={[styles.facepile, { color: colors.primaryText }]}
                textstyle={{
                  fontSize: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
                myContext={myContext}
              />
            )}
            {followers?.length > 0 && (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: colors.primaryText,
                    fontSize: 12,
                    fontWeight: "500",
                    marginLeft: 5,
                    flexWrap: "wrap",
                  }}
                >
                  {followText}
                </Text>
                <View style={{ width: 1 }}></View>
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default ProfileCard;

const styles = StyleSheet.create({
  item: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  name: {
    fontSize: 20,
    lineHeight: 28,
    fontWeight: "700",
  },
  handle: {
    fontSize: 14,
  },
  headline: {
    fontSize: 14,
    marginTop: 8,
  },
  facepile: {
    height: 21,
    width: 21,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    marginRight: 4,
  },
});
