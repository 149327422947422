import React from "react";
import { StyleSheet, Text, View } from "react-native";
import CustomButton from "./CustomButton";

import bold_icon from "../assets/bold.png";
import quoted from "../assets/quote.png";
import point_bullets from "../assets/point_bullets.png";
import image_component from "../assets/image_component.png";

import { villageColors } from "../utils/SharedStyles";
import { makeImage, makeQuote, makeText } from "../utils/ShareNote";
import { getLocalImage } from "../utils/LocalImage";

const ButtonBar = (props) => {
  const {
    myContext,
    richShareNote,
    colors,
    shareNoteEditorRef,
    bold,
    setBold,
    quote,
    setQuote,
    list,
    setList,
    length,
  } = props;

  return (
    <View
      style={{
        elevation: 0,
        shadowColor: "#000000",
        shadowOffset: { width: 0, height: -1 },
        shadowOpacity: 0.2,
        shadowRadius: 4,

        backgroundColor: colors.background,
        padding: 12,
        paddingBottom: 27,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <CustomButton
          style={[
            styles.buttons,
            {
              backgroundColor: bold ? colors.primaryButtonBackground : null,
              marginRight: 20,
            },
          ]}
          onPress={() =>
            setBold((prev) => {
              return !prev;
            })
          }
          icon={bold_icon}
          imageStyle={[
            styles.imageButton,
            {
              tintColor: bold ? colors.primaryButtonText : colors.primaryText,
            },
          ]}
        />

        <CustomButton
          style={[
            styles.buttons,
            {
              marginRight: 20,
              backgroundColor: quote ? colors.primaryButtonBackground : null,
            },
          ]}
          onPress={() => {
            setQuote((prev) => {
              return !prev;
            });
            if (!quote) {
              const text = makeQuote();
              let newData;

              // if the first text component is empty and the user creates a quote
              // then the text component will be deleted
              if (
                richShareNote?.data.length === 1 &&
                richShareNote?.data[0].type === "text" &&
                richShareNote?.data[0].content === null
              ) {
                newData = [text];
              } else {
                const data = richShareNote ? richShareNote.data : [];
                newData = [...data, text];
              }
              shareNoteEditorRef.current?.setNote(newData);
            }
          }}
          icon={quoted}
          imageStyle={[
            styles.imageButton,
            {
              tintColor: quote ? colors.primaryButtonText : colors.primaryText,
            },
          ]}
        />
        <CustomButton
          style={[
            styles.buttons,
            {
              marginRight: 20,
              backgroundColor: list ? colors.primaryButtonBackground : null,
            },
          ]}
          onPress={() => {
            setList((prev) => {
              return !prev;
            });
          }}
          icon={point_bullets}
          imageStyle={[
            styles.imageButton,
            {
              tintColor: list ? colors.primaryButtonText : colors.primaryText,
            },
          ]}
        />
        <Text
          style={{ fontSize: 12, lineHeight: 14.4, color: colors.primaryText }}
        >
          {length}
        </Text>
      </View>

      <CustomButton
        style={({ pressed }) => [
          {
            borderRadius: 100,
            justifyContent: "center",
            alignItems: "center",

            opacity: pressed ? 0.5 : 1,
          },
        ]}
        onPress={() => {
          console.log("Will upload image");
          getLocalImage(myContext, (success, keys, status) => {
            if (success) {
              console.log("uploaded with key", keys);
              let newData = richShareNote ? richShareNote.data : [];

              keys.forEach((key) => {
                const image = makeImage();
                newData = [...newData, { ...image, photo: key }];
              });

              const text = makeText();
              newData.push(text);
              shareNoteEditorRef.current?.setNote(newData);
            } else {
              console.log("did not updated because", status);
            }
          });
        }}
        icon={image_component}
        imageStyle={[styles.imageButton, { tintColor: colors.primaryText }]}
      />
    </View>
  );
};

export default ButtonBar;

const styles = StyleSheet.create({
  buttons: {
    width: 32,
    height: 32,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  editButton: {
    height: 32,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  imageButton: {
    width: 25,
    height: 25,
  },
});
