import { React } from "react";
import { Image, Pressable, StyleSheet } from "react-native";
import cross from "../assets/cross.png";
import { useTheme } from "../theme";

const CloseButton = (props) => {
  const { onPress, buttonStyle, imageStyle } = props;

  const { colors } = useTheme();

  return (
    <Pressable style={[styles.closeButton, buttonStyle]} onPress={onPress}>
      <Image
        source={cross}
        style={[
          styles.crossIcon,
          {
            tintColor: colors.primaryButtonBackground,
          },
          imageStyle,
        ]}
      />
    </Pressable>
  );
};

export default CloseButton;

const styles = StyleSheet.create({
  closeButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
  },
  crossIcon: {
    height: 12,
    width: 12,
    justifyContent: "center",
    alignItems: "center",
  },
});
