import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Pressable,
  Image,
  StyleSheet,
  ActivityIndicator,
  FlatList,
  Text,
  View,
  SafeAreaView,
  Platform,
  AppState,
} from "react-native";

import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";
import playIcon from "../assets/play.png";
import pauseIcon from "../assets/pause.png";

import Avatar from "../components/Avatar";

import { initialUser } from "../utils/InitialValues";

import { formatTime } from "../utils/TimeFormatting";
import { findTopActions, detailsFromAction } from "../utils/TopActions";
import { timeStamp } from "../utils/TimeStamp";
import { photoIdFromId } from "../utils/RandomFromId";
import { clog } from "../utils/Log";
import { getNotificationData } from "../controllers/NotificationController";
import { toggleFollow } from "../utils/Follow";
import { logEvent } from "../utils/LogEvent";
import { useTheme } from "../theme";
import ShareNote from "../components/ShareNote";
import { hashCode } from "../utils/Id";
import WebView from "react-native-webview";
import { Modal } from "react-native";
import cross from "../assets/cross.png";
import cloud1 from "../assets/cloud_1.png";
import logo from "../assets/logo.png";
import { API, graphqlOperation } from "aws-amplify";
import { dailyRecommendationsForUser } from "../src/graphql/custom";
import * as Speech from "expo-speech";
import { Audio } from "expo-av";
//import Predictions from "@aws-amplify/predictions";
import { PollyClient, SynthesizeSpeechCommand } from "@aws-sdk/client-polly";
import { fromCognitoIdentity } from "@aws-sdk/credential-provider-cognito-identity";

const PlaybackScreen = ({ route, navigation }) => {
  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const { colors } = useTheme();

  let items = route?.params?.items;
  console.log("RECEIVED", items);

  const appState = useRef(AppState.currentState);
  const [playing, setPlaying] = useState(false);

  clog("CONTEXT VERSION", myContext.version);
  clog("CONTEXT", myContext);
  myContext["content"] = "this is something to real aloud";
  myContext["contentIndex"] = 0;

  const canContinueSpeaking = () => {
    if (myContext.speechOngoing && myContext.contentIndex < items?.length - 1) {
      return true;
    }
    return false;
  };

  const sound = new Audio.Sound();

  const speak = async () => {
    myContext.speechOngoing = true;
    setPlaying(true);
    if (canContinueSpeaking()) {
      await continueSpeaking();
    } else {
      console.log(
        "Cannot speak",
        myContext.contentIndex,
        myContext.content?.length
      );
    }
  };

  const continueSpeaking = async () => {
    console.log(
      "Will speak",
      myContext.contentIndex,
      items[myContext.contentIndex]
    );
    if (items[myContext.contentIndex]?.pins?.[0]?.content) {
      let url =
        "https://villageaudio.s3.us-west-2.amazonaws.com/" +
        items[myContext.contentIndex].pins[0].Id +
        ".mp3";
      //const sound = new Audio.Sound();

      await sound.loadAsync({
        uri: url,
      });
      await sound.playAsync();
      myContext.contentIndex++;
      if (playing) {
        speak();
      }
    }
  };

  const pauseSpeaking = () => {
    myContext.speechOngoing = false;
    setPlaying(false);
    sound.stopAsync();
  };

  const handlePress = async () => {
    console.log("Pressed right button", myContext.speechOngoing);
    if (!myContext.speechOngoing) {
      await speak();
      setPlaying(true);
    } else {
      pauseSpeaking();
      setPlaying(false);
    }
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <Pressable style={{ paddingRight: 10 }} onPress={handlePress}>
          <Image
            source={!playing ? playIcon : pauseIcon}
            style={{
              height: 24,
              width: 24,
              tintColor: colors.primaryText,
              marginRight: 12,
            }}
          />
        </Pressable>
      </View>
    </View>
  );
};

export default PlaybackScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  coloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
  },
  uncoloredButton: {
    justifyContent: "center",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 104,
    height: 26,
    borderWidth: 2,
  },
  text: { fontSize: 12 },
  elapsed: { color: "rgb(173,173,173)", fontSize: 14 },
  containerListEmpty: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  textListEmpty: {
    fontSize: 15,
    marginTop: 16,
  },
});
