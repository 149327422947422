import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Dimensions,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
  Animated,
  Linking,
  Easing,
} from "react-native";
import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";
import SourceIcon from "../components/SourceIcon";
import { useTheme } from "../theme";
import { villageColors } from "../utils/SharedStyles";
import { findTopActions, reorderActions } from "../utils/TopActions";
import link from "../assets/link.png";
import heart from "../assets/heart.png";
import filledHeart from "../assets/filled_heart.png";
import share from "../assets/share.png";
import comment from "../assets/comment.png";
import check from "../assets/check.png";
import pin from "../assets/save.png";
import bookmarked from "../assets/bookmarked.png";
import downArrow from "../assets/downArrow.png";
import { ScrollView } from "react-native-gesture-handler";
import ShareNote from "../components/ShareNote";
import PinCurator from "../components/PinCurator";
import * as Haptics from "expo-haptics";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import PageIndicator from "../components/PageIndicator";
import { LinearGradient } from "expo-linear-gradient";
import ShareSheet from "../components/ShareSheet";
import * as Clipboard from "expo-clipboard";
import CustomCollapsible from "../components/CustomCollapsible";
import { shareIfPossible } from "../utils/ShareOut";
import { logEvent } from "../utils/LogEvent";
import { toggleLike } from "../utils/Like";
import { clog } from "../utils/Log";
import { deletePin, savePin } from "../controllers/PinController";
import LoadingDots from "../components/LoadingDots";
import { getUrl } from "../controllers/CommonFeedController";
import { API, graphqlOperation } from "aws-amplify";
import { dailyRecommendationsForUser } from "../src/graphql/custom";
import { trackViews } from "../utils/Tracker";
import { timeStamp } from "../utils/TimeStamp";
import { recommendTopicUpdate } from "../utils/RecommendTopicUpdate";
import TopicPopUp from "../components/TopicPopUp";
import TopicSheet from "../components/TopicSheet";
import { getTopics } from "../controllers/TopicController";
import { serializationDataOfTopics } from "../utils/SerializationDataOfTopics";

const Icon = ({ icon, iconOutline, iconColor, isActive, onPressButton }) => {
  const animationIcon = useRef(new Animated.Value(0)).current;
  const opacityIcon = new Animated.Value(isActive ? 0 : 3);

  const scaleIcon = animationIcon.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.7],
  });
  const filledIconOpacity = opacityIcon.interpolate({
    inputRange: [0, 0.5, 1, 1.5, 3],
    outputRange: [1, 0.8, 0.6, 0.4, 0],
  });
  const outlineIconOpacity = opacityIcon.interpolate({
    inputRange: [0, 0.8, 0.9, 1],
    outputRange: [0, 0, 0, 1],
  });

  const onPressIn = () => {
    Animated.spring(animationIcon, {
      toValue: 1,
      useNativeDriver: false,
    }).start();
  };

  const onPressOut = () => {
    Animated.spring(animationIcon, {
      toValue: 0,
      friction: isActive ? 7 : 2,
      useNativeDriver: false,
    }).start();

    //Change opacity
    Animated.spring(opacityIcon, {
      toValue: isActive ? 3 : 0,
      useNativeDriver: false,
    }).start();
  };

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        padding: 16,
      }}
    >
      <Pressable
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        onPress={onPressButton}
      >
        <Animated.Image
          source={icon}
          style={[
            {
              height: 24,
              width: 24,
              tintColor: iconColor,
              opacity: filledIconOpacity,
              transform: [{ scale: scaleIcon }],
            },
          ]}
        />
        <Animated.Image
          source={iconOutline}
          style={[
            {
              position: "absolute",
              height: 24,
              width: 24,
              tintColor: villageColors.White,
              opacity: outlineIconOpacity,
              transform: [{ scale: scaleIcon }],
            },
          ]}
        />
      </Pressable>
    </View>
  );
};

const DailyBestScreen = ({ route, navigation }) => {
  const myContext = useContext(AppContext);
  const [url, setUrl] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const insets = useSafeAreaInsets();
  const scrollX = useRef(new Animated.Value(0)).current;

  const { width, height } = Dimensions.get("window");
  const { colors } = useTheme();

  const HEADER_HEIGHT = 47;
  const MARGIN_FOR_NOTIFICATION = 75 + HEADER_HEIGHT;

  const notificationTransformRef = useRef(new Animated.Value(0)).current;
  const notificationOpacityRef = useRef(new Animated.Value(0)).current;
  const popUpTransformRef = useRef(new Animated.Value(0)).current;

  const animatedCurrent = useRef(Animated.divide(scrollX, width)).current;
  const [currentItem, setCurrentItem] = useState(null);

  const [topPin, setTopPin] = useState(null);
  const [liked, setLiked] = useState(false);
  const [hasBookmarked, setHasBookmarked] = useState(false);
  const [bookmarkedPin, setBookmarkedPin] = useState(null);

  const [loading, setLoading] = useState(false);

  const [footerHeight, setFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const [topicPopUpMessage, setTopicPopUpMessage] = useState(
    "Are you still interested in these notes? Mix it up! "
  );
  const [topicSheetVisible, setTopicSheetVisible] = useState(false);
  const [showTopicPopUp, setShowTopicPopUp] = useState(false);
  const [topics, setTopics] = useState(null);

  const PREVIEW_HEIGHT_AND_MARGINS = 200;

  const NOTE_HEIGHT =
    height -
    footerHeight -
    headerHeight -
    insets.top -
    PREVIEW_HEIGHT_AND_MARGINS;

  const shareRef = useRef(null);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  async function getTopicData() {
    clog("will get topic data");
    await getTopics({
      myContext: myContext,
      callback: ({ success, message, error, topicData }) => {
        if (success) {
          clog("SETTING TOPIC DATA TO", topicData);

          serializationDataOfTopics({
            data: topicData,
            onboarding: false,
            callback: (data) => setTopics(data),
          });
        } else {
          //clog("COULD NOT FETCH TOPICS");
        }
      },
    });
  }

  clog("ON DAILY BEST SCREEN");
  useEffect(() => {
    setLoading(true);
    getNotificationRecommendations();

    logEvent("On_Top_Content", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: "notification",
      platform: Platform.OS,
      action: "visit",
      target: "screen",
      appVersion: myContext.appVersion,
    });
  }, []);

  useEffect(() => {
    let _topPin = null;

    if (currentItem) {
      let pins = currentItem?.pins?.items;
      if (!pins) {
        pins = currentItem?.pins;
      }
      if (pins) {
        pins.forEach((p) => {
          if (!_topPin) {
            _topPin = p;
          } else {
            if (p.createdAt < _topPin.createdAt && p.content) {
              _topPin = p;
            }
          }
        });
      }

      let _liked = false;
      _topPin?.actions?.items?.forEach((a) => {
        if (a.operation == "Like" && a.actorId == myContext.Id) {
          _liked = true;
        }
      });

      setLiked(_liked);

      pins?.forEach((pin) => {
        if (pin?.curatorId === myContext.Id) {
          if (pin?.list?.name == "Saved") {
            setHasBookmarked(true);
            setBookmarkedPin(pin);
          } else {
            setHasBookmarked(false);
          }
        } else {
          setHasBookmarked(false);
        }
      });

      setTopPin(_topPin);
    } else {
      setCurrentItem(url[0]);
    }
  }, [currentItem, loading]);

  useEffect(() => {
    recommendTopicUpdate({
      myContext: myContext,
      setTopicPopUpMessage: setTopicPopUpMessage,
      showTopicPopUp: () => {
        setShowTopicPopUp(true);
        onAnimatedPopUp();
      },
    });
  }, []);

  useEffect(() => {
    if (!topics) {
      getTopicData();
    }
  }, [topics]);

  async function getNotificationRecommendations() {
    let promises = [];
    let _url = [];

    promises.push(
      API.graphql(
        graphqlOperation(dailyRecommendationsForUser, {
          userIdType: [myContext.Id, "Notification"].join(":"),
          limit: myContext.config.dailyBestCount,
          nextToken: null,
        })
      )
    );

    const responses = await Promise.all(promises);
    if (responses[0].data.recommendationByUserIdType.items.length >= 1) {
      const lastRecommendations =
        responses[0].data.recommendationByUserIdType.items;
      let currentTime = timeStamp();

      for (const item of lastRecommendations) {
        // do not fetch items that are too old
        if (item.creationTS > currentTime - myContext.config.dailyBestWindow) {
          promises.push(
            getUrl({
              Id: item.urlId,
              myContext: myContext,
              callback: ({ success, message, error, annotatedUrl }) => {
                if (success) {
                  let lurl = { ...url, ...annotatedUrl };
                  lurl["topActions"] = findTopActions(lurl, myContext);
                  lurl.topActions = reorderActions(lurl.topActions);
                  console.log("RERANKING ACTIONS", item.urlId);

                  lurl["score"] = item.score;
                  lurl["recommendationTS"] = item.creationTS;
                  _url.push(lurl);
                } else {
                  console.log({ message });
                }
              },
            })
          );
        }
      }

      await Promise.all(promises);

      let urlId = route?.params?.urlId;

      _url.sort((a, b) =>
        a.Id == urlId
          ? -1
          : b.Id == urlId
          ? 1
          : a.recommendationTS != b.recommendationTS
          ? a.recommendationTS > b.recommendationTS
            ? -1
            : 1
          : a.score != b.score
          ? a.score > b.score
            ? -1
            : 1
          : a.Id > b.Id
          ? -1
          : 1
      );
      setUrl(_url);
      setLoading(false);
    }
  }

  async function saveAPin() {
    myContext["urlEngagedWith"] = currentItem;

    savePin({
      url: currentItem,
      curator: myContext.users[topPin.curatorId],
      myContext: myContext,
      callback: ({ success, message, error, newObject }) => {
        delete myContext.inflightSaves[currentItem.Id];
      },
    });
  }

  async function deleteAPin() {
    myContext["urlEngagedWith"] = currentItem;
    clog("will try to delete", currentItem);
    if (!bookmarkedPin?.list?.Id) {
      console.log("ERROR: Cannot find bookmarked pin list", bookmarkedPin);
      delete myContext.inflightSaves[currentItem.Id];
      return;
    }
    deletePin({
      url: currentItem,
      pin: bookmarkedPin,
      listId: bookmarkedPin.list.Id,
      listNumPins: 1,
      oldList: bookmarkedPin.list,
      myContext: myContext,
      batch: false,

      stateSetter: () => {},
      callback: ({ success, message, error }) => {
        delete myContext.inflightSaves[currentItem.Id];
      },
    });
  }

  const Header = () => {
    return (
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
        colors={["rgba(22, 22, 22, 1)", "rgba(22, 22, 22, 0)"]}
        style={styles.linearGradient}
      >
        <View
          style={{ marginTop: insets.top }}
          onLayout={(event) => setHeaderHeight(event.nativeEvent.layout.height)}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 16,
            }}
          >
            <Pressable
              style={{ flex: 0.5 }}
              onPress={() => navigation.goBack()}
            >
              <LeftArrow />
            </Pressable>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  fontWeight: "700",
                  lineHeight: 22,
                  color: villageColors.White,
                }}
              >
                {"Must-reads for you 🔥"}
              </Text>
            </View>
            <View style={{ flex: 0.5 }}></View>
          </View>

          <PageIndicator
            count={url.length}
            current={animatedCurrent}
            color={villageColors.White}
            activeColor={villageColors.Accent}
          />
        </View>
      </LinearGradient>
    );
  };

  const ContentPreview = ({ item }) => {
    const topAction = item?.topActions[0]?.action;

    return (
      <Pressable
        onPress={() => {
          navigation.push("ItemDetails", {
            url: item,
            curator: myContext.users[topAction?.actorId],
            refererActionId: topAction?.Id,
            hostUserId: null,
          });
          logEvent("View_Top_Content_Click", {
            userId: myContext?.Id,
            username: myContext?.handle,
            category: "notification",
            platform: Platform.OS,
            action: "click",
            target: "content",
            url: item?.uri,
            status: "success",
            appVersion: myContext.appVersion,
          });
        }}
        style={[
          styles.contentPreviewContainer,
          {
            backgroundColor: villageColors.RavenMedium,
          },
        ]}
      >
        <View style={{ flex: 1, marginRight: 8 }}>
          <Text
            style={[
              styles.title,
              {
                color: villageColors.White,
              },
            ]}
            numberOfLines={3}
            ellipsizeMode={"tail"}
          >
            {item?.title}
          </Text>

          <View
            style={{
              flexDirection: "row",
              marginTop: 4,
            }}
          >
            <SourceIcon
              url={item?.uri}
              icon={
                item?.source?.avatar
                  ? myContext.presignedUrls[item.source.avatar]
                    ? myContext.presignedUrls[item.source.avatar]?.uri
                    : item?.source?.avatarUrl
                  : item?.source?.avatarUrl
              }
              style={{
                tintColor: villageColors.White50,
                marginRight: 4,
                height: 16,
                width: 16,
              }}
              textstyle={{
                fontSize: 16,
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
              }}
              showText={false}
            />

            <Text style={{ color: villageColors.White50, fontSize: 12 }}>
              {item?.sourceId}
            </Text>
          </View>
        </View>
        <View
          style={{
            alignSelf: "flex-start",
            width: 100,
            height: 64,
          }}
        >
          {item?.photoUrl ? (
            <Image
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 8,
                resizeMode: "cover",
              }}
              source={{
                uri: item?.photoUrl,
              }}
            />
          ) : (
            <View
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 8,
                backgroundColor: colors.contentScreenBackground,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: 24,
                  height: 24,
                  resizeMode: "cover",
                }}
                source={link}
              />
            </View>
          )}
        </View>
      </Pressable>
    );
  };

  const RenderItem = ({ item, index }) => {
    const [expanded, setExpanded] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const topAction = item?.topActions[0]?.action;

    const WIDTH_SHARENOTE = width > 556 ? 556 - 32 : width - 32;

    let _topPin = null;
    let pins = item?.pins?.items;
    if (!pins) {
      pins = item?.pins;
    }
    if (pins) {
      pins.forEach((p) => {
        if (!_topPin) {
          _topPin = p;
        } else {
          if (p.createdAt < _topPin.createdAt && p.content) {
            _topPin = p;
          }
        }
      });
    }

    let itemJSON;

    if (_topPin?.markup) {
      try {
        itemJSON = JSON.parse(_topPin.content);
      } catch (err) {
        console.log("error", err);
      }
    }

    return (
      <View
        style={{
          width: WIDTH_SHARENOTE,
          marginHorizontal: 16,
          maxWidth: 756,
        }}
      >
        <Pressable
          style={{
            overflow: "hidden",
            marginTop: 22,
            backgroundColor: villageColors.White,
            borderRadius: 16,
          }}
          onPress={() => {
            navigation.push("Comments", {
              url: item,
              curator: myContext.users[topAction?.actorId],
              refererActionId: topAction?.Id,
              hostUserId: null,
              notFolded: true,
            });
          }}
        >
          <View
            style={{
              position: "absolute",
              opacity: 0,
              width: WIDTH_SHARENOTE,
            }}
            onLayout={(event) => {
              if (event.nativeEvent.layout.height > NOTE_HEIGHT) {
                setShowMore(true);
              }
            }}
          >
            <View style={{ padding: 16 }}>
              <View style={{ marginBottom: 16 }}>
                <PinCurator
                  curatorId={_topPin.curatorId}
                  pin={_topPin}
                  navigation={navigation}
                  myContext={myContext}
                  colors={colors}
                  screen={"daily-best"}
                />
              </View>

              {_topPin?.markup ? (
                <ShareNote
                  json={itemJSON}
                  colors={colors}
                  myContext={myContext}
                  showExpandedNote={true}
                  isLargePrint={true}
                />
              ) : (
                <Text
                  style={{
                    color: colors.primaryText,
                    flex: 1,
                    flexWrap: "wrap",
                    fontSize: 18,
                    lineHeight: 28,
                  }}
                >
                  {_topPin?.content}
                </Text>
              )}
            </View>
          </View>

          <CustomCollapsible
            collapsed={!expanded}
            duration={150}
            collapsedHeight={NOTE_HEIGHT}
            style={{ width: WIDTH_SHARENOTE }}
          >
            <View style={{ padding: 16 }}>
              <View style={{ marginBottom: 16 }}>
                <PinCurator
                  curatorId={_topPin.curatorId}
                  pin={_topPin}
                  myContext={myContext}
                  colors={colors}
                  screen={"daily-best"}
                />
              </View>

              {_topPin?.markup ? (
                <ShareNote
                  json={itemJSON}
                  colors={colors}
                  myContext={myContext}
                  showExpandedNote={true}
                  isLargePrint={true}
                />
              ) : (
                <Text
                  style={{
                    color: colors.primaryText,
                    flex: 1,
                    flexWrap: "wrap",
                    fontSize: 18,
                    lineHeight: 28,
                  }}
                >
                  {_topPin?.content}
                </Text>
              )}
            </View>
          </CustomCollapsible>

          {showMore && (
            <Pressable
              onPress={() => {
                setExpanded(true);
                setShowMore(false);
                logEvent("View_Top_Content_Expand", {
                  userId: myContext?.Id,
                  username: myContext?.handle,
                  category: "notification",
                  platform: Platform.OS,
                  action: "click",
                  target: "content",
                  url: item?.uri,
                  appVersion: myContext.appVersion,
                });
              }}
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
              }}
            >
              <LinearGradient
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                colors={["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 0)"]}
                style={{
                  width: "100%",
                  height: 56,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  source={downArrow}
                  style={{
                    width: 10,
                    height: 5,
                    tintColor: villageColors.Raven,
                  }}
                />
              </LinearGradient>
            </Pressable>
          )}
        </Pressable>

        <ContentPreview item={item} />
      </View>
    );
  };

  const renderItem = useCallback(
    ({ item, index }) => <RenderItem item={item} index={index} />,
    [currentItem]
  );

  const renderHeader = useCallback(() => <Header />, [url]);

  const onPressLike = () => {
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    if (myContext?.inflightLikes[currentItem.Id]) {
      console.log("WARNING!!! LIKE IS INFLIGHT");
      return;
    }
    myContext.inflightLikes[currentItem.Id] = true;

    let currentLiked = liked;
    setLiked((prev) => {
      return !prev;
    });

    toggleLike({
      liked: currentLiked,
      objectType: "Pin",
      target: topPin,
      refererActionId: null,
      hostUserId: null,
      myContext: myContext,
      url: currentItem,
      callback: ({ success, message, error }) => {
        delete myContext.inflightLikes[currentItem.Id];
        if (success) {
          console.log("COMPLETED FORCED REFRESH");
        } else {
          clog("Could not execute like");
        }
      },
    });
  };

  const onPressComment = () => {
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    const topAction = currentItem?.topActions[0]?.action;

    navigation.push("Comments", {
      url: currentItem,
      curator: myContext.users[topAction?.actorId],
      refererActionId: topAction?.Id,
      hostUserId: null,
      notFolded: true,
    });
  };

  const onPressBookmark = () => {
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    if (myContext?.inflightSaves[currentItem.Id]) {
      console.log("WARNING!!! SAVE IS INFLIGHT");
      return;
    }
    myContext.inflightSaves[currentItem.Id] = true;
    clog("asked to create pin");
    let currentlyBookmarked = hasBookmarked;

    setHasBookmarked((currentState) => {
      return !currentState;
    });

    if (!currentlyBookmarked) {
      saveAPin();
    } else {
      clog("SHOULD DELETE THIS PIN");
      deleteAPin();
    }
  };

  const onPressShare = () => {
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    shareRef.current?.onOpen();
  };

  const onAnimatedNotification = () => {
    Animated.timing(notificationOpacityRef, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();

    Animated.timing(notificationTransformRef, {
      toValue: MARGIN_FOR_NOTIFICATION,
      duration: 800,
      useNativeDriver: true,
    }).start();
  };

  const onAnimatedPopUp = () => {
    Animated.timing(popUpTransformRef, {
      toValue: -120,
      duration: 500,
      easing: Easing.bezier(0.25, 0.46, 0.45, 0.94),
      useNativeDriver: true,
    }).start();
  };

  const showAlertMessage = (message) => {
    console.log("WILL SHOW MESSAGE", message);
    setAlertMessage(message);
    setAlertVisible(true);
    onAnimatedNotification();
    setTimeout(() => {
      notificationOpacityRef.setValue(0);
      notificationTransformRef.setValue(0);
      setAlertVisible(false);
    }, 3000);
  };

  const showCopyAlertMessage = () => {
    if (showAlertMessage) {
      showAlertMessage(
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
            paddingHorizontal: 12,
          }}
        >
          <Image
            source={check}
            style={{
              height: 24,
              width: 24,
              tintColor: colors.primaryText,
              marginRight: 4,
            }}
          />
          <View style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <Text
              style={{
                fontSize: 15,
                fontWeight: "700",
                lineHeight: 22,
                color: colors.primaryText,
              }}
            >
              {"Link copied"}
            </Text>
          </View>
        </View>
      );
    }
  };

  const copyPostLink = async () => {
    let _url = "https://app.projectvillage.io/";
    _url = _url + "item/" + topPin.Id + "/" + myContext.Id;
    await Clipboard.setStringAsync(_url);
    shareRef.current?.onClose();

    showCopyAlertMessage();
  };

  const copyOriginalLink = async () => {
    await Clipboard.setStringAsync(currentItem.uri);
    shareRef.current?.onClose();

    showCopyAlertMessage();
  };

  const openInBrowser = () => {
    Linking.canOpenURL(currentItem.uri)
      .then((supported) => {
        supported && Linking.openURL(currentItem.uri);
      })
      .catch(() => console.log("Failed to open url " + currentItem.uri));

    shareRef.current?.onClose();
  };

  const shareItem = async (
    handle,
    title,
    pinId,
    sharerId,
    sharerHandle,
    sharerName
  ) => {
    let { success, message } = await shareIfPossible(
      "Item",
      handle,
      title,
      pinId,
      sharerId,
      sharerHandle,
      sharerName,
      showAlertMessage
    );

    if (!success) {
      showAlertMessage(message);
    }
    logEvent("Share_Content", {
      userId: myContext?.Id,
      username: myContext?.handle,
      category: "content",
      platform: Platform.OS,
      pin_id: pinId,
      action: "click",
      target: "share",
      status: success ? "success" : "failure",
      appVersion: myContext.appVersion,
    });
    shareRef.current?.onClose();
  };

  const shareAction = (key) => {
    switch (key) {
      case "share":
        shareItem(
          myContext.users[topPin.curatorId].handle,
          currentItem.title,
          topPin.Id,
          myContext.Id,
          myContext.handle,
          myContext.name
        );
        break;
      case "copy_link":
        copyPostLink();

        break;
      case "copy_content_link":
        copyOriginalLink();

        break;
      case "open_in_browser":
        openInBrowser();
    }
  };

  const viewabilityConfig = {
    waitForInteraction: false,
    viewAreaCoveragePercentThreshold: 70,
  };

  const handleViewableItemsChanged = useRef(({ viewableItems, changed }) => {
    if (viewableItems[0]?.item) {
      setCurrentItem(viewableItems[0]?.item);
      logEvent("View_Top_Content", {
        userId: myContext?.Id,
        username: myContext?.handle,
        category: "notification",
        platform: Platform.OS,
        action: "click",
        target: "content",
        url: viewableItems[0].item?.uri,
        appVersion: myContext.appVersion,
      });
    }
    clog("VIEWABLE ITEMS", viewableItems);
    clog("CHANGED", changed);
    trackViews("Url", changed, myContext, "recommendation");
  });

  const extractKey = (item) => {
    //clog("will extract key from", item);
    let key = "";
    if (!item.Id) {
      if (item.url) {
        key = item.url.Id;
      }
    } else {
      key = item.Id;
    }
    if (!key) {
      clog("ERROR: no key from", item);
    }
    //clog("KEY", key);
    return key;
  };

  const ListEmptyComponent = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: 35,
        }}
      >
        <LoadingDots />
      </View>
    </View>
  );

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: villageColors.Raven,
        alignItems: "center",
      }}
    >
      {renderHeader()}
      <View
        style={{
          flex: 1,
          width: "100%",
          maxWidth: 756,
        }}
      >
        <ScrollView
          style={{ flex: 1, maxWidth: 756 }}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Animated.FlatList
            style={{ marginTop: headerHeight + insets.top }}
            contentContainerStyle={{ flexGrow: 1 }}
            viewabilityConfig={viewabilityConfig}
            onViewableItemsChanged={handleViewableItemsChanged.current}
            horizontal={true}
            pagingEnabled={Platform.OS !== "web"}
            showsHorizontalScrollIndicator={false}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { x: scrollX } } }],
              {
                useNativeDriver: true,
              }
            )}
            data={url}
            renderItem={renderItem}
            keyExtractor={extractKey}
            ListEmptyComponent={<ListEmptyComponent />}
          />

          <View
            style={styles.footer}
            onLayout={(event) =>
              setFooterHeight(event.nativeEvent.layout.height)
            }
          >
            <Icon
              icon={filledHeart}
              iconOutline={heart}
              iconColor={colors.error}
              isActive={liked}
              onPressButton={onPressLike}
              scrollX={scrollX}
            />
            <Pressable style={{ padding: 16 }} onPress={onPressComment}>
              <Image
                source={comment}
                style={{
                  width: 24,
                  height: 24,
                  tintColor: villageColors.White,
                }}
              />
            </Pressable>
            <Icon
              icon={bookmarked}
              iconOutline={pin}
              iconColor={villageColors.White}
              isActive={hasBookmarked}
              onPressButton={onPressBookmark}
              scrollX={scrollX}
            />

            <Pressable style={{ padding: 16 }} onPress={onPressShare}>
              <Image
                source={share}
                style={{
                  width: 24,
                  height: 24,
                  tintColor: villageColors.White,
                }}
              />
            </Pressable>
          </View>
        </ScrollView>

        {alertVisible && (
          <Animated.View
            style={[
              styles.notification,
              {
                opacity: notificationOpacityRef,
                transform: [
                  {
                    translateY: notificationTransformRef,
                  },
                ],
              },
            ]}
          >
            <Pressable
              onPress={() => {
                setAlertVisible(false);
              }}
            >
              <View
                style={[
                  styles.notificationView,
                  { backgroundColor: colors.notificationBackground },
                ]}
              >
                {alertMessage}
              </View>
            </Pressable>
          </Animated.View>
        )}

        <ShareSheet ref={shareRef} action={(key) => shareAction(key)} />

        {showTopicPopUp && (
          <TopicPopUp
            colors={colors}
            message={topicPopUpMessage}
            popUpTransformRef={popUpTransformRef}
            openTopicSheet={() => {
              setTopicSheetVisible(true);
              setShowTopicPopUp(false);
              popUpTransformRef.setValue(0);
            }}
            closePopUp={() => {
              setShowTopicPopUp(false);
              popUpTransformRef.setValue(0);
            }}
          />
        )}

        <TopicSheet
          visible={topicSheetVisible}
          setVisible={(value) => setTopicSheetVisible(value)}
          colors={colors}
          myContext={myContext}
          topicOptions={topics}
          dataRefresh={() => {}}
        />
      </View>
    </View>
  );
};

export default DailyBestScreen;

const styles = StyleSheet.create({
  contentPreviewContainer: {
    padding: 16,
    marginTop: 16,
    borderRadius: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: "700",
    flexWrap: "wrap",
  },
  footer: {
    marginHorizontal: 16,
    marginBottom: 26,
    marginTop: 16,
    paddingHorizontal: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  linearGradient: {
    paddingHorizontal: 16,
    width: "100%",
    position: "absolute",
    zIndex: 1000,
    paddingBottom: 23,
  },
  notification: {
    alignSelf: "center",
    position: "absolute",
    zIndex: 1000, // works on ios
    elevation: 3, // works on android
    shadowColor: "rgb(23,23,23)",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    borderRadius: 30,
  },
  notificationView: {
    paddingHorizontal: 15,
    zIndex: 3, // works on ios
    elevation: 3, // works on android
    height: 45,
    borderRadius: 30,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
