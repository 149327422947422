import { API, graphqlOperation } from "aws-amplify";
import {
  getPinsAndCommentsForUrl,
  visitsOfObject,
} from "../src/graphql/custom";
import { batchPresign } from "../utils/Photo";
import { batchLookupUsers } from "../utils/Cache";
import { clog, elog } from "../utils/Log";
import { adjustPinForRichShareNote } from "../utils/Compatibility";
import { cleanObject } from "./CommonFeedController";

export async function getUrlPinsAndComments({ Id, myContext, callback }) {
  let status = { success: true };
  clog("asked to get PIN and COMMENTS data for ", Id);
  if (!Id) {
    status.success = false;
    status["message"] = "No Id provided for url";
    if (callback) {
      callback(status);
    }
    return status;
  }
  try {
    let data = null;
    const urlData = await API.graphql(
      graphqlOperation(getPinsAndCommentsForUrl, { Id: Id })
    );
    clog("urlData:", urlData);
    data = urlData?.data?.getUrl;
    if (data) {
      data = cleanObject(data, myContext);
    }
    if (data) {
      clog("data is ", data);
      let unknownUserIds = {};
      let mayNeedLookUp = {};
      let url = {
        Id: data?.Id,
        title: data?.title,
        type: data?.type,
        uri: data?.uri,
        sourceId: data?.sourceId,
        source: data?.source,
        photo: data?.photo,
        photoUrl: data?.photoUrl,
      };
      url["comments"] = data?.comments?.items;
      url["pins"] = data?.pins?.items;
      url.pins.forEach((pin) => {
        adjustPinForRichShareNote(pin, myContext, mayNeedLookUp);
        if (!myContext.users[pin.curatorId]) {
          unknownUserIds[pin.curatorId] = true;
        }
        pin.comments?.items?.forEach((c2) => {
          if (!myContext.users[c2.curatorId]) {
            unknownUserIds[c2.curatorId] = true;
          }
        });
        pin?.actions?.items?.forEach((action) => {
          if (pin.content) {
            action["hasNote"] = true;
            if (pin.markup) {
              action["hasRichShareNote"] = true;
            } else {
              action["hasRichShareNote"] = false;
            }
          } else {
            action["hasNote"] = false;
            action["hasRichShareNote"] = false;
          }
        });
      });
      url.comments.forEach((comment) => {
        if (!myContext.users[comment.curatorId]) {
          unknownUserIds[comment.curatorId] = true;
        }
        comment.comments?.items?.forEach((c2) => {
          if (!myContext.users[c2.curatorId]) {
            unknownUserIds[c2.curatorId] = true;
          }
        });
      });
      if (url.source?.avatar) {
        mayNeedLookUp[url.source.avatar] = true;
      }
      await batchLookupUsers(
        Object.keys(unknownUserIds),
        myContext.users,
        null
      );
      Object.keys(unknownUserIds).forEach((Id) => {
        let user = myContext.users[Id];
        if (user) {
          mayNeedLookUp[user.avatar] = true;
        }
      });

      await batchPresign(
        Object.keys(mayNeedLookUp),
        myContext.presignedUrls,
        null
      );
      status["annotatedUrl"] = url;
      if (callback) {
        callback(status);
      }
      return status;
    } else {
      status.success = false;
      status["message"] = "Url not found";
      if (callback) {
        callback(status);
      }
      return status;
    }
  } catch (err) {
    status.success = false;
    status["message"] = "Error fetching url";
    if (callback) {
      callback(status);
    }
    console.log("error fetching pin...", err);
    elog(myContext.Id, "item details", "data fetch", err.message);
    return status;
  }
}

export async function visitsOfUrl({ Id, myContext, callback }) {
  let status = { success: true };
  clog("asked to get visits data for ", Id);
  if (!Id) {
    status.success = false;
    status["message"] = "No Id provided for url";
    if (callback) {
      callback(status);
    }
    return status;
  }
  //visitsOfObject;
  try {
    let data = null;
    // TODO(alpha): Need to use cursor to find all visits
    const urlData = await API.graphql(
      graphqlOperation(visitsOfObject, { objectId: Id })
    );
    clog("urlData:", urlData);
    data = urlData?.data?.visitOfObject;
    if (data) {
      clog("data is ", data);
      let visitors = {};
      let viewers = {};
      data?.items
        ?.sort((a, b) => (a.visitTS < b.visitTS ? -1 : 1))
        ?.forEach((v) => {
          clog("visit", v.visitTS);
          if (
            !v.actor.label?.match("insider") &&
            !v.actor.label?.match("test") &&
            !v.actor.handle?.match("test123")
          ) {
            if (v.type != "View") {
              if (
                !visitors[v.actor.handle] ||
                visitors[v.actor.handle] < v.duration
              ) {
                visitors[v.actor.handle] = v.duration;
              }
            } else {
              if (
                !viewers[v.actor.handle] ||
                viewers[v.actor.handle] < v.duration
              ) {
                viewers[v.actor.handle] = v.duration;
              }
            }
          }
        });
      let longVisits = [];
      let shortVisits = [];
      Object.keys(visitors)
        .sort((a, b) => (a < b ? -1 : 1))
        .forEach((v) => {
          if (visitors[v] >= myContext.config.longVisitThreshold) {
            longVisits.push(v);
          } else {
            shortVisits.push(v);
          }
        });
      let longViews = [];
      let shortViews = [];
      Object.keys(viewers)
        .sort((a, b) => (a < b ? -1 : 1))
        .forEach((v) => {
          if (viewers[v] >= myContext.config.longViewThreshold) {
            longViews.push(v);
          } else {
            shortViews.push(v);
          }
        });
      status.success = true;
      status["message"] = {
        longVisits: longVisits,
        shortVisits: shortVisits,
        longViews: longViews,
        shortViews: shortViews,
      };
      if (callback) {
        callback(status);
      }
      return status;
    } else {
      status.success = false;
      status["message"] = "Url not found";
      if (callback) {
        callback(status);
      }
      return status;
    }
  } catch (err) {
    console.log("Could not get visit data", err);
    status.success = false;
    status["message"] = "Error fetching url";
    if (callback) {
      callback(status);
    }
    return status;
  }
}
