import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { clog } from "../utils/Log";

const GroupCard = ({ item, myContext, colors }) => {
  if (item == null) {
    return <View></View>;
  }
  clog("will print card for", item);
  return (
    <View
      style={[styles.item, { backgroundColor: colors.secondaryBackground }]}
    >
      <View style={{ flex: 1 }}>
        <Text
          style={[styles.name, { color: colors.primaryText }]}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {item?.name}
        </Text>
        <Text
          style={[styles.description, { color: colors.primaryText }]}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {item?.description}
        </Text>
      </View>
    </View>
  );
};

export default GroupCard;

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    borderRadius: 8,
  },
  name: {
    fontSize: 16,
  },
  description: {
    fontSize: 10,
    opacity: 0.4,
    width: 150,
  },
});
