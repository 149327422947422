import React from "react";
import { View, Text, Pressable, Image, Platform } from "react-native";
import cross from "../assets/cross.png";
import { timeStamp } from "../utils/TimeStamp";

const CTA = ({ context, timestamp, updateDismissal, navigation, colors }) => {
  let title = "";
  let message = "";
  let image = " ";

  if (context?.Id) {
    if (!context.avatar) {
      title = "Add your photo";
      message = "Add a photo of you so villagers have a face to the name";
      image = "👤";
    } else if (!context.headline) {
      title = "Add your headline";
      message = "Write a brief intro for villagers to learn about who you are";
      image = "✍️";
    } else if (!context.websites) {
      title = "Add your website";
      message = "Share your websites and social media";
      image = "🔗";
    } else if (!context.location) {
      title = "Add your location";
      message = "Share the city you call home to encourage local community";
      image = "🏙";
    }
  }
  if (message) {
    return (
      <View
        style={{
          padding: 12,
          backgroundColor: colors.messageBackground,
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Text style={{ fontSize: 16, marginRight: 8 }}>{image}</Text>
            <View>
              <Text
                style={{
                  color: colors.primaryText,
                  fontSize: 14,
                  textAlign: "center",
                  flexWrap: "wrap",
                }}
              >
                {title}
              </Text>
            </View>
          </View>
          <Text
            style={{
              color: colors.secondaryText,
              fontSize: 12,
              flexWrap: "wrap",
            }}
          >
            {message}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 24,
          }}
        >
          <Pressable
            onPress={() => {
              navigation.push("Profile", {
                subsequent: true,
              });
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 12,
                paddingVertical: Platform.OS == "android" ? 0 : 8,
                width: Platform.OS == "android" ? 60 : 46,
                height: 30,
                backgroundColor: colors.primaryButtonBackground,
                borderRadius: 100,
              }}
            >
              <Text
                style={{
                  color: colors.primaryButtonText,
                  fontSize: 12,
                  fontWeight: "700",
                }}
              >
                Add
              </Text>
            </View>
          </Pressable>
          <Pressable
            onPress={() => {
              context["lastCTADismissal"] = timeStamp();
              updateDismissal(context["lastCTADismissal"]);
              console.log("Dismissal", context.lastCTADismissal);
            }}
          >
            <View
              style={{
                height: 24,
                width: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                source={cross}
                style={{
                  height: 10,
                  width: 10,
                  tintColor: colors.primaryText,
                }}
              />
            </View>
          </Pressable>
        </View>
      </View>
    );
  } else {
    return <View></View>;
  }
};

export default CTA;
