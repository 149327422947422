import { API, graphqlOperation } from "aws-amplify";

import { userMinimal } from "../src/graphql/custom";

export async function batchLookupUsers(Ids, users, callback) {
  let promises = [];
  let processing = {};
  Ids.forEach((Id) => {
    if (Id && !processing[Id] && !users[Id]) {
      promises.push(
        API.graphql(
          graphqlOperation(userMinimal, {
            Id: Id,
          })
        )
      );
      processing[Id] = 1;
    }
  });
  const responses = await Promise.all(promises);
  responses.forEach((response) => {
    if (response?.data?.getUser?.Id) {
      let user = response?.data?.getUser;
      users[user.Id] = user;
    }
  });
  if (callback) {
    callback();
  }
}
