import React from "react";
import { View, Text, StyleSheet } from "react-native";
import SourceIcon from "../components/SourceIcon";
import { clog } from "../utils/Log";

const ItemCompact = ({ item, myContext }) => {
  const renderItem = ({ item }) => {
    clog("ITEM:", item);
    let authorName = "";
    if (item.author?.name && item.author.name != "") {
      authorName = item.author.name;
    }
    if (!authorName && item.authorName != null && item.authorName) {
      authorName = item.authorName;
    }
    return (
      <View style={styles.item}>
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SourceIcon
            url={item.uri}
            icon={
              item?.source?.avatar
                ? myContext.presignedUrls[item.source.avatar]
                  ? myContext.presignedUrls[item.source.avatar].uri
                  : item?.source?.avatarUrl
                : item?.source?.avatarUrl
            }
            style={{
              height: 40,
              width: 40,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 40,
              backgroundColor: "white",
            }}
            textstyle={{
              fontSize: 16,
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          ></SourceIcon>
        </View>
        <View
          style={{
            flex: 1,
            marginLeft: 9,
            flexDirection: "column",
          }}
        >
          <Text style={styles.title} numberOfLines={2} ellipsizeMode="tail">
            {item.title}
          </Text>
          <View style={{ height: 2 }}></View>
          <View style={{ flexDirection: "row", alignContent: "center" }}>
            {authorName != "" && (
              <View style={{ alignItems: "center" }}>
                <Text
                  style={styles.snippet}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {authorName}
                </Text>
              </View>
            )}
            {authorName != "" && item.duration != 0 && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={styles.snippet} numberOfLines={1}>
                  {" · "}
                </Text>
                <Text
                  style={styles.snippet}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {item.duration + " minute"}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  return renderItem({ item });
};

export default ItemCompact;

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
  },
  title: {
    fontSize: 16,
    color: "white",
  },
  snippet: {
    fontSize: 16,
    color: "white",
    opacity: 0.4,
  },
});
