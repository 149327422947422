import React, { useEffect, useState, useContext } from "react";
import { StyleSheet, Text, Pressable, View, Platform } from "react-native";

import AppContext from "../components/AppContext";
import { initialUser } from "../utils/InitialValues";
import { sharedStyles } from "../utils/SharedStyles";
import LeftArrow from "../components/LeftArrow";
import { clog } from "../utils/Log";
import { getTopics } from "../controllers/TopicController";
import { useTheme } from "../theme";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { serializationDataOfTopics } from "../utils/SerializationDataOfTopics";
import TopicEditor from "../components/TopicEditor";
import { KeyboardSpacer } from "../utils/Keyboard";
import { CommonActions } from "@react-navigation/native";

function TopicChooserScreen({ route, navigation }) {
  async function getTopicData(onboarding) {
    clog("will get topic data");
    await getTopics({
      myContext: myContext,
      callback: ({ success, message, error, topicData }) => {
        if (success) {
          clog("SETTING TOPIC DATA TO", topicData);

          serializationDataOfTopics({
            data: topicData,
            onboarding: onboarding,
            callback: (topics) => {
              setTopicOptions(topics);
            },
          });
        } else {
          clog("COULD NOT FETCH TOPICS");
        }
      },
    });
  }

  const initialState = {
    ...initialUser,
  };
  const [topicOptions, setTopicOptions] = useState(null);
  const [phase, setPhase] = useState(0);
  const [state, setState] = useState(initialState);
  const [isChanged, setIsChanged] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [changedTopics, setChangedTopics] = useState({});

  const myContext = useContext(AppContext);
  const { colors } = useTheme();
  const insets = useSafeAreaInsets();

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  clog("navigation is in user details ", navigation);
  clog("route is ", route);
  clog("context is ", myContext);
  clog("declaredTopicIds", myContext?.declaredTopicIds);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  let subsequent = route?.params?.subsequent;

  useEffect(() => {
    if (myContext && !state.Id) {
      setState((prevState) => {
        return {
          ...prevState,
          Id: myContext.Id,
          numTopicFollow: myContext.numTopicFollow,
          actionsByUser: myContext.actionsByUser,
          wishList: myContext.wishList,
        };
      });
    }
  }, []);

  useEffect(() => {
    if (!topicOptions) {
      getTopicData(!subsequent);
    }
  }, []);

  const Header = () => {
    return (
      <>
        <View style={{ height: insets.top }} />
        <View style={{ paddingBottom: 4 }}>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Pressable
              style={{ flex: 0.5 }}
              onPress={() => {
                if (phase === 1) {
                  setPhase(0);
                } else {
                  if (isChanged) {
                    setIsShowAlert(true);
                  } else {
                    navigation.goBack();
                  }
                }
              }}
            >
              <View style={{ marginLeft: -6 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>

            <View style={{ flex: 1, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 18,
                  lineHeight: 28,
                  fontWeight: "700",
                  color: colors.primaryText,
                }}
              >
                {subsequent ? "Your Topics" : "Build your Village"}
              </Text>
            </View>

            <View style={{ flex: 0.5 }} />
          </View>

          <View style={{ alignItems: "center", marginHorizontal: 8 }}>
            <Text
              style={{
                fontSize: 15,
                lineHeight: 22,
                color: colors.primaryText,
                textAlign: "center",
              }}
            >
              {phase === 0
                ? "Pick topics you like to make it home."
                : "In your own words, what kind of content do you want to see in Village? Consider mentioning: publications, people, sites, places, niches, trends"}
            </Text>
          </View>
        </View>
      </>
    );
  };

  clog("STATE:", state);
  if (topicOptions) {
    ////clog("will create list with", topicOptions);
    return (
      <View
        style={[
          sharedStyles.container,
          { backgroundColor: colors.background, paddingHorizontal: 16 },
        ]}
      >
        <View
          style={{
            top: 0,
            position: "absolute",
            backgroundColor: colors.bottomNavBar,
            width: "100%",
          }}
        >
          <Header />
        </View>

        <View style={styles.contentContainer}>
          <View style={{ opacity: 0 }}>
            <Header />
          </View>
          <TopicEditor
            topicOptions={topicOptions}
            colors={colors}
            myContext={myContext}
            phase={phase}
            setPhase={setPhase}
            goBack={() => {
              if (subsequent) {
                navigation.goBack(2);
              } else {
                myContext.onboarding = false; // done with onboarding
                navigation.dispatch(
                  CommonActions.reset({
                    index: 0,
                    routes: [{ name: "tab", params: { onboarding: true } }],
                  })
                );
              }
            }}
            isShowAlert={isShowAlert}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            changedTopics={changedTopics}
          />
        </View>
        {Platform.OS !== "web" && <View style={{ height: 80 }} />}
        <KeyboardSpacer topSpacing={Platform.OS !== "android" ? -60 : -400} />
      </View>
    );
  } else {
    //clog("render empty screen");
    return <View></View>;
  }
}

export default TopicChooserScreen;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  contentContainer: {
    flex: 1,
    width: "100%",
    maxWidth: 756,
    alignItems: "center",
  },
});
