import { clog } from "../utils/Log";

export function timeStamp() {
  return Math.floor(Date.now() / 1000);
}

export function computeElapsedTime(creationTimeString, currentTime) {
  let creationTime = new Date(creationTimeString);
  let elapsedTime = Math.round(
    (currentTime.valueOf() - creationTime.valueOf()) / 1000
  );
  clog("elapsed time", elapsedTime);
  return elapsedTime;
}

export function timeStampFromTimeString(timeString) {
  let time = new Date(timeString);
  return Math.floor(time / 1000);
}
