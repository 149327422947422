import React from "react";
import { Image, View } from "react-native";

import leftArrow from "../assets/left-arrow.png";

const LeftArrow = ({ tintColor }) => {
  if (!tintColor) {
    tintColor = "white";
  }
  return (
    <View
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Image
        source={leftArrow}
        style={{ height: 15, width: 8, tintColor: tintColor }}
      />
    </View>
  );
};

export default LeftArrow;
