import { clog } from "../utils/Log";

export function formatTime(creationTimeString, currentTime) {
  clog("format time for", creationTimeString, "at", currentTime);
  if (creationTimeString == null) {
    return "0s";
  }
  let creationTime = new Date(creationTimeString);
  let elapsedTime = Math.round(
    (currentTime.valueOf() - creationTime.valueOf()) / 1000
  );
  clog("elapsed time", elapsedTime);
  let timeString = "";
  if (elapsedTime < 60) {
    let seconds = elapsedTime;
    if (seconds > 1) {
      timeString = seconds + "s";
    } else {
      // TODO(alpha): Hiding a bug here in callsite
      timeString = "0s";
    }
  } else if (elapsedTime < 3600) {
    let minutes = Math.round(elapsedTime / 60);
    timeString = minutes + "m";
  } else if (elapsedTime < 86400 - 3600) {
    let hours = Math.round(elapsedTime / 3600);

    timeString = hours + "h";
  } else if (elapsedTime < 7 * 86400) {
    let days = Math.round(elapsedTime / 86400);

    timeString = days + "d";
  } else {
    let weeks = Math.round(elapsedTime / 86400 / 7);
    timeString = weeks + "w";
  }
  clog("compute time string", timeString);
  return timeString;
}
