import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Text,
  View,
  Platform,
  StyleSheet,
  TextInput,
  Pressable,
  Animated,
  ActivityIndicator,
  FlatList,
  Alert,
} from "react-native";
import { clog } from "../utils/Log";
import Slide from "../components/Slide";
import CustomButton from "./CustomButton";

import bullet from "../assets/bullet.png";
import text_component from "../assets/text_component.png";
import text_subcomponent from "../assets/text_subcomponent.png";
import image_component from "../assets/image_component.png";
import quote from "../assets/quote.png";
import point_bullets from "../assets/point_bullets.png";
import trash from "../assets/trash.png";
import plus from "../assets/plus.png";
import cross from "../assets/cross.png";
import downArrow from "../assets/downArrow.png";
import { uploadPhoto } from "../utils/Photo";

import { villageColors } from "../utils/SharedStyles";
import { uploadPhotoWeb } from "../utils/PhotoWeb";
import ScalableImage from "./ScalableImage";
import { MENTION_FORMATTER } from "../utils/Mention";
import Collapsible from "react-native-collapsible";
import CustomCollapsible from "../components/CustomCollapsible";
import { handleVisible, handleInvisible } from "../utils/Tracker";
import { logEvent } from "../utils/LogEvent";
import { API, graphqlOperation } from "aws-amplify";
import { findUrl, getUrl } from "../src/graphql/custom";
import { LinearGradient } from "expo-linear-gradient";
import SourceIcon from "./SourceIcon";
import link from "../assets/link.png";
import { photoIdFromId } from "../utils/RandomFromId";
import { generateUniqueId } from "../utils/Id";
import { populateMetadata } from "../utils/PopulateMetadata";
import { saveUrl } from "../utils/CreatePin";
import { getLocalImage } from "../utils/LocalImage";
import AwesomeAlert from "react-native-awesome-alerts";

const Buttons = ({
  json,
  data,
  rowIndex,
  isEdit,
  onPressText,
  onPressQuote,
  onPressList,
  onPressEdit,
  onPressDelete,
  onPressTextCard,
  onPressImage,
}) => {
  const [showSubcomponents, setShowSubcomponents] = useState(false);

  const isDisabled = () => {
    return (
      data.type === "text" || data.type === "quote" || data.type === "image"
    );
  };

  return (
    <>
      <View style={{ marginTop: 10, flexDirection: "row" }}>
        <CustomButton
          style={({ pressed }) => [
            styles.editButton,
            {
              backgroundColor: showSubcomponents
                ? villageColors.Accent
                : villageColors.Stone,
              opacity: isDisabled() ? 0.5 : pressed ? 0.5 : 1,
              width: 32,
            },
          ]}
          onPress={() => setShowSubcomponents(!showSubcomponents)}
          icon={plus}
          imageStyle={{ width: 25, height: 25, tintColor: "black" }}
          disabled={isDisabled()}
        />
        <CustomButton
          style={({ pressed }) => [
            styles.editButton,
            {
              backgroundColor: isEdit
                ? villageColors.Accent
                : villageColors.Stone,
              opacity: pressed ? 0.5 : 1,
              paddingHorizontal: 10,
            },
          ]}
          onPress={() => onPressEdit(!isEdit)}
          title={"Edit"}
          titleStyle={{ color: "black" }}
        />
        <CustomButton
          style={({ pressed }) => [
            styles.editButton,
            {
              backgroundColor: pressed
                ? villageColors.Accent
                : villageColors.Stone,
              opacity: pressed ? 0.5 : 1,
              width: 32,
            },
          ]}
          onPress={() => onPressDelete(rowIndex)}
          icon={trash}
          imageStyle={{ width: 24, height: 24, tintColor: "black" }}
        />
      </View>

      {showSubcomponents && (
        <View
          style={{
            flexDirection: "row",
            marginVertical: 5,
          }}
        >
          <CustomButton
            style={({ pressed }) => [
              styles.editButton,
              {
                backgroundColor: pressed
                  ? villageColors.Accent
                  : villageColors.Stone,
                opacity: data.type === "bulletedList" ? 0.5 : pressed ? 0.5 : 1,
                width: 32,
              },
            ]}
            onPress={() => {
              if (data.type === "slidingPanel") {
                onPressTextCard(json, rowIndex);
              } else {
                onPressText(json, rowIndex);
              }
              setShowSubcomponents(false);
              !isEdit && onPressEdit(true);
            }}
            icon={
              data.type === "slidingPanel" ? text_component : text_subcomponent
            }
            imageStyle={styles.imageButton}
            disabled={data.type === "bulletedList"}
          />
          {data.type === "slidingPanel" && (
            <CustomButton
              style={({ pressed }) => [
                styles.editButton,
                {
                  backgroundColor: pressed
                    ? villageColors.Accent
                    : villageColors.Stone,
                  opacity: pressed ? 0.5 : 1,
                  width: 32,
                },
              ]}
              onPress={() => {
                onPressImage(json, rowIndex);
                setShowSubcomponents(false);
                !isEdit && onPressEdit(true);
              }}
              icon={image_component}
              imageStyle={styles.imageButton}
            />
          )}
          {data.type !== "slidingPanel" && (
            <>
              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    backgroundColor: pressed
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity:
                      data.type === "bulletedList" ? 0.5 : pressed ? 0.5 : 1,
                    width: 32,
                  },
                ]}
                onPress={() => {
                  onPressQuote(json, rowIndex);
                  setShowSubcomponents(false);
                  !isEdit && onPressEdit(true);
                }}
                icon={quote}
                imageStyle={styles.imageButton}
                disabled={data.type === "bulletedList"}
              />
              <CustomButton
                style={({ pressed }) => [
                  styles.editButton,
                  {
                    backgroundColor: pressed
                      ? villageColors.Accent
                      : villageColors.Stone,
                    opacity: pressed ? 0.5 : 1,
                    width: 32,
                  },
                ]}
                onPress={() => {
                  onPressList(json, rowIndex);
                  setShowSubcomponents(false);
                  !isEdit && onPressEdit(true);
                }}
                icon={point_bullets}
                imageStyle={styles.imageButton}
              />
            </>
          )}
        </View>
      )}
    </>
  );
};

const ShareNote = ({
  json,
  myContext,
  colors,
  showEditButtons,
  editNote,
  onPressText,
  onPressQuote,
  onPressList,
  onPressDelete,
  onPressTextCard,
  onPressImage,
  bold,
  medium,
  large,
  italic,
  highlight,
  list,
  quote,
  seed,
  navigation,
  contentModalFunction,
  showExpandedNote,
  onSubmit,
  setQuote,
  calculationOfLength,
  toggleTextOrList,
  setList,
  item,
  origin,
  isLargePrint,
  onLongPress,
}) => {
  if (!json) {
    return <View></View>;
  }

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [cardHeight, setCardHeight] = useState(180);

  const opacityCollapsed = useRef(new Animated.Value(1)).current;
  const opacityExpanded = useRef(new Animated.Value(0)).current;

  clog("CURRENT EFECTIVE WIDTH", width);

  clog("ASKED TO RENDER", json);
  let slideIndex = 0;
  const COLLAPSED_HEIGHT = 32;
  const MORE_BUTTON_HEIGHT = 20;
  const MARGIN_BOTTOM = 16;
  const SLIDE_PANEL_HEIGHT = 32;
  const NOTE_HEIGHT = 265;

  const previewComponent =
    json?.data?.length !== 1 &&
    json?.data?.[0]?.type === "text" &&
    json?.data?.[0]?.content === null
      ? json?.data?.[1]
      : json?.data?.[0];

  const isCard =
    previewComponent?.type === "slidingPanel" ||
    previewComponent?.type === "textCard" ||
    previewComponent?.type === "image";

  useEffect(() => {
    if (json?.data?.length === 1 && isCard) {
      setShowMore(false);
    }
  }, []);

  const renderNote = (data, key, isLess) => {
    clog("Render PART", data);

    if (data) {
      if (data.type == "text" || data.type == "quote") {
        return (
          <View key={key} style={{ marginVertical: 5 }}>
            <TextComponent
              json={data}
              rowIndex={key}
              colors={colors}
              isEdit={editNote}
              bold={bold}
              medium={medium}
              large={large}
              italic={italic}
              highlight={highlight}
              list={list}
              quote={quote}
              navigation={navigation}
              contentModalFunction={contentModalFunction}
              isLess={isLess}
              onSubmit={onSubmit}
              onPressDelete={onPressDelete}
              setQuote={setQuote}
              calculationOfLength={calculationOfLength}
              toggleTextOrList={toggleTextOrList}
              isLargePrint={isLargePrint}
            />
          </View>
        );
      } else if (data.type == "textCard") {
        slideIndex++;
        return (
          <View key={key} style={{ marginVertical: 5 }}>
            <TextCardWithButtons
              json={json}
              data={data}
              colors={colors}
              rowIndex={key}
              editCard={editNote && showEditButtons}
              onPressText={onPressText}
              onPressQuote={onPressQuote}
              onPressList={onPressList}
              onPressDelete={onPressDelete}
              bold={bold}
              italic={italic}
              highlight={highlight}
              seed={seed + slideIndex}
              isLargePrint={isLargePrint}
            />
          </View>
        );
      } else if (data.type == "image") {
        return (
          <View key={key} style={{ marginVertical: 5 }}>
            <ImageWithButtons
              json={json}
              data={data}
              rowIndex={key}
              colors={colors}
              width={width}
              showButtons={showEditButtons}
              isEdit={editNote}
              onPressDelete={onPressDelete}
              myContext={myContext}
              onSlide={false}
            />
          </View>
        );
      } else if (data.type == "slidingPanel") {
        clog("SLIDING PANEL WITH WIDTH", width);
        slideIndex++;
        return (
          <View key={key} style={{ marginVertical: 0 }}>
            <SlideWithButtons
              json={json}
              data={data}
              colors={colors}
              width={width}
              rowIndex={key}
              editCard={editNote}
              onPressText={onPressText}
              onPressQuote={onPressQuote}
              onPressList={onPressList}
              onPressDelete={onPressDelete}
              onPressTextCard={onPressTextCard}
              onPressImage={onPressImage}
              myContext={myContext}
              bold={bold}
              italic={italic}
              highlight={highlight}
              seed={seed + slideIndex}
              isLargePrint={isLargePrint}
            />
          </View>
        );
      } else if (data.type == "bulletedList") {
        return (
          <View key={key} style={{ marginVertical: 5 }}>
            <BulletedList
              json={data}
              rowIndex={key}
              colors={colors}
              isEdit={editNote}
              onPressDelete={onPressDelete}
              showButtons={editNote}
              bold={bold}
              italic={italic}
              highlight={highlight}
              list={list}
              isLess={isLess}
              onSubmit={onSubmit}
              calculationOfLength={calculationOfLength}
              toggleTextOrList={toggleTextOrList}
              setList={setList}
              isLargePrint={isLargePrint}
            />
          </View>
        );
      } else if (data.type == "urlList") {
        return (
          <View key={key} style={{}}>
            <UrlList
              json={data}
              rowIndex={key}
              isEdit={editNote}
              colors={colors}
              myContext={myContext}
              onPressDelete={onPressDelete}
            />
          </View>
        );
      }
    }
  };

  if (showExpandedNote) {
    return (
      <View
        style={{
          width: "100%",
        }}
        onLayout={(event) => {
          clog("LAYOUT FROM EVENT", event.nativeEvent.layout);
          if (event?.nativeEvent?.layout?.width) {
            setWidth(event.nativeEvent.layout.width);
          }
        }}
      >
        <View>
          {json.data?.map((c, rowIndex) => {
            return renderNote(c, rowIndex, false);
          })}
        </View>
      </View>
    );
  }

  const onPress = () => {
    setExpanded((prev) => {
      clog("Pressed button", prev, item.Id);
      if (item.expanded) {
        handleInvisible("Url", item.Id, item, myContext, origin);
        logEvent(
          origin == "myvillage" ? "MyVillage_Collapse_Note" : "Collapse_Note",
          {
            userId: myContext?.Id,
            username: myContext?.handle,
            category: origin,
            platform: Platform.OS,
            action: "collapse",
            target: "note",
            Id: item.Id,
            uri: item.uri,
            appVersion: myContext.appVersion,
          }
        );
      } else {
        handleVisible("Url", item.Id, item, myContext, origin);
        logEvent(
          origin == "myvillage" ? "MyVillage_Expand_Note" : "Expand_Note",
          {
            userId: myContext?.Id,
            username: myContext?.handle,
            category: origin,
            platform: Platform.OS,
            action: "expand",
            target: "note",
            Id: item.Id,
            uri: item.uri,
            appVersion: myContext.appVersion,
          }
        );
      }
      item["expanded"] = !prev;
      return !prev;
    });
  };

  if (origin === "myvillage" || origin === "discover" || origin === "search") {
    return (
      <Pressable
        onPress={() => {
          onPress();
          setShowMore(false);
        }}
        disabled={!showMore}
      >
        <View
          style={{
            position: "absolute",
            opacity: 0,
            width: "100%",
          }}
          onLayout={(event) => {
            clog("LAYOUT FROM EVENT", event.nativeEvent.layout.width);
            if (event?.nativeEvent?.layout?.width) {
              setWidth(event.nativeEvent.layout.width);
            }
            if (!expanded) {
              if (json?.data?.length === 1 && isCard) {
                setHeight(event.nativeEvent.layout.height);
              } else if (event.nativeEvent.layout.height <= NOTE_HEIGHT) {
                setShowMore(false);
                setHeight(event.nativeEvent.layout.height);
              } else {
                setShowMore(true);
                setHeight(event.nativeEvent.layout.height);
              }
            }
          }}
        >
          {json.data?.map((c, rowIndex) => {
            return renderNote(c, rowIndex);
          })}
        </View>

        <CustomCollapsible
          collapsed={!expanded}
          duration={150}
          enablePointerEvents={true}
          collapsedHeight={
            json?.data?.length === 1 && isCard
              ? height
              : height < NOTE_HEIGHT
              ? height
              : NOTE_HEIGHT
          }
          style={{ width: width }}
        >
          {json.data?.map((c, rowIndex) => {
            return renderNote(c, rowIndex);
          })}
        </CustomCollapsible>

        {showMore && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              bottom: 0,
            }}
          >
            <LinearGradient
              start={{ x: 0, y: 1 }}
              end={{ x: 0, y: 0 }}
              colors={["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 0)"]}
              style={{
                width: "100%",
                height: 56,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Image
                source={downArrow}
                style={{
                  marginBottom: 8,
                  width: 10,
                  height: 5,
                  tintColor: colors.primaryText,
                }}
              />
            </LinearGradient>
          </View>
        )}
      </Pressable>
    );
  }

  if (
    (json?.data?.length === 1 && previewComponent?.content === null) ||
    (json?.data?.length === 1 && previewComponent?.content === "")
  ) {
    return <></>;
  }

  const getMinHeightForCard =
    previewComponent?.type === "slidingPanel" ||
    previewComponent?.type === "image"
      ? width +
        (json?.data?.length !== 1 && MORE_BUTTON_HEIGHT) +
        (previewComponent?.type === "slidingPanel" && SLIDE_PANEL_HEIGHT) +
        MARGIN_BOTTOM
      : cardHeight + (json?.data?.length !== 1 && MORE_BUTTON_HEIGHT);

  return (
    <Pressable
      onPress={() => {
        Animated.timing(opacityCollapsed, {
          toValue: expanded ? 1 : 0,
          duration: expanded ? 100 : 300,
          useNativeDriver: true,
        }).start();
        Animated.timing(opacityExpanded, {
          toValue: expanded ? 0 : 1,
          duration: 200,
          useNativeDriver: true,
        }).start();

        onPress();
      }}
      style={{
        minHeight: isCard ? getMinHeightForCard : COLLAPSED_HEIGHT,
      }}
      disabled={(json?.data?.length === 1 && isCard) || !showMore}
    >
      <Animated.View
        style={{
          position: "absolute",
          width: "100%",
          opacity: opacityCollapsed,
        }}
        onLayout={(event) => {
          if (previewComponent?.type == "textCard") {
            setCardHeight(event.nativeEvent.layout.height);
          }
        }}
      >
        {json?.data?.length === 1 && !isCard && (
          <View
            style={{
              position: "absolute",
              opacity: 0,
              width: "100%",
            }}
            onLayout={(event) => {
              if (
                json?.data?.length === 1 &&
                event.nativeEvent.layout.height === COLLAPSED_HEIGHT
              ) {
                setShowMore(false);
              }
            }}
          >
            {renderNote(previewComponent, 0, false)}
          </View>
        )}
        <View style={!isCard && { marginRight: 40 }}>
          {renderNote(previewComponent, 0, true)}
        </View>
        {showMore && (
          <View style={{ alignItems: "flex-end" }}>
            <View
              style={[
                {
                  bottom:
                    previewComponent?.type === "quote" ||
                    previewComponent?.type === "bulletedList"
                      ? 12
                      : isCard
                      ? 0
                      : 7,
                },
                !isCard && { position: "absolute" },
              ]}
            >
              <Text
                style={{
                  color: colors.link,
                  fontSize: 15,
                  fontWeight: "600",
                }}
              >
                {"more"}
              </Text>
            </View>
          </View>
        )}
      </Animated.View>
      <Animated.View style={{ opacity: opacityExpanded }}>
        <Collapsible collapsed={!expanded} duration={150}>
          <Pressable
            onPress={() => {
              Animated.timing(opacityCollapsed, {
                toValue: expanded ? 1 : 0,
                duration: expanded ? 100 : 300,
                useNativeDriver: true,
              }).start();
              Animated.timing(opacityExpanded, {
                toValue: expanded ? 0 : 1,
                duration: 200,
                useNativeDriver: true,
              }).start();

              onPress();
            }}
            style={{
              width: "100%",
            }}
            onLongPress={() => {
              if (expanded && onLongPress) {
                onLongPress();
              }
            }}
            onLayout={(event) => {
              clog("LAYOUT FROM EVENT", event.nativeEvent.layout);
              if (event?.nativeEvent?.layout?.width) {
                setWidth(event.nativeEvent.layout.width);
              }
            }}
          >
            <Animated.View>
              {json.data?.map((c, rowIndex) => {
                return renderNote(c, rowIndex, false);
              })}
            </Animated.View>
          </Pressable>
        </Collapsible>
      </Animated.View>
    </Pressable>
  );
};

const FormattedText = ({
  data,
  style,
  onCard,
  inQuote,
  inBulletedList,
  inReverse,
  colors,
  navigation,
  contentModalFunction,
  isLess,
}) => {
  clog("ASKED FOR FORMATTED TEXT WITH data", data, "inReverse", inReverse);
  return (
    <Text style={[style, { flexWrap: "wrap" }]} numberOfLines={isLess ? 1 : 0}>
      {data?.map((c, rowIndex) => {
        clog("Render PART", c);
        if (c.type == "text") {
          return (
            <Text
              key={rowIndex}
              style={[
                style,
                c.bold ? { fontWeight: "700" } : {},
                c.italic ? { fontStyle: "italic" } : {},
                c.medium ? { fontSize: 18 } : {},
                c.large ? { fontSize: 20 } : {},
                c.highlight
                  ? [
                      {
                        backgroundColor: onCard ? colors.highlight : "yellow",
                      },
                      colors.highlightedText
                        ? { color: colors.highlightedText }
                        : {},
                    ]
                  : {},
              ]}
            >
              {inQuote || onCard || inBulletedList
                ? c.content
                : MENTION_FORMATTER(
                    c.content,
                    navigation,
                    contentModalFunction
                  )}
            </Text>
          );
        }
      })}
      {inQuote && <Text style={style}>{'"'}</Text>}
    </Text>
  );
};

const rangeToChars = (range) => {
  clog("INCOMING RANGE", range);
  let chars = [];
  range?.forEach((r) => {
    r?.content?.split("").forEach((c) => {
      chars.push({ ...r, content: c });
    });
  });
  return chars;
};

const charsToRange = (chars) => {
  let range = null;
  chars?.forEach((c) => {
    if (!range) {
      range = [];
      range.push({ ...c });
    } else {
      let prevItem = range[range.length - 1];
      if (
        ((prevItem.bold == null && !c.bold) || prevItem.bold == c.bold) &&
        ((prevItem.italic == null && !c.italic) ||
          prevItem.italic == c.italic) &&
        ((prevItem.highlight == null && !c.highlight) ||
          prevItem.highlight == c.highlight) &&
        ((prevItem.medium == null && !c.medium) ||
          prevItem.medium == c.medium) &&
        ((prevItem.large == null && !c.large) || prevItem.large == c.large)
      ) {
        prevItem.content = prevItem.content + c.content;
      } else {
        // create a new item
        range.push({ ...c });
      }
    }
  });
  return range;
};

const makeTextFromChar = (c, bold, italic, highlight, medium, large) => {
  let text = {
    type: "text",
    content: c,
  };
  if (bold) {
    text["bold"] = bold;
  }
  if (italic) {
    text["italic"] = italic;
  }
  if (highlight) {
    text["highlight"] = highlight;
  }
  if (medium) {
    text["medium"] = medium;
  }
  if (large) {
    text["large"] = large;
  }
  return text;
};

const handleChanges = (
  range,
  newText,
  bold,
  italic,
  highlight,
  medium,
  large
) => {
  clog("DIFF", range, "vs", newText);
  let oldChars = rangeToChars(range);
  let newChars = newText?.split("");
  let outputChars = [];
  clog("OLD CHARS", oldChars, "NEW CHARS", newChars);
  let front = -1;
  for (let i = 0; i < oldChars.length && i < newChars.length; i++) {
    if (oldChars[i].content == newChars[i]) {
      front = i;
    } else {
      break;
    }
  }
  let back = -1;
  for (
    let i = 0;
    i < oldChars.length &&
    i < newChars.length &&
    oldChars.length - 1 - i > front &&
    newChars.length - 1 - i > front;
    i++
  ) {
    if (
      oldChars[oldChars.length - 1 - i].content ==
      newChars[newChars.length - 1 - i]
    ) {
      back = i;
    } else {
      break;
    }
  }

  for (let i = 0; i <= front; i++) {
    outputChars.push(oldChars[i]);
  }

  clog("Agree till", front);
  clog("Backward match till", back);
  if (front == -1 && back != -1) {
    clog(
      "Changed at the front",
      oldChars.length - back - 1,
      newChars.length - back - 1
    );
    for (let i = oldChars.length - back - 1; i < oldChars.length; i++) {
      outputChars.push(oldChars[i]);
    }
    for (let i = newChars.length - back - 2; i >= 0; i--) {
      outputChars.unshift(
        makeTextFromChar(newChars[i], bold, italic, highlight, medium, large)
      );
    }
  } else if (front != -1 && back == -1) {
    clog(
      "change is at the back",
      front + 1,
      oldChars.length - 1,
      front + 1,
      newChars.length - 1
    );
    for (let i = front + 1; i <= newChars.length - 1; i++) {
      outputChars.push(
        makeTextFromChar(newChars[i], bold, italic, highlight, medium, large)
      );
    }
  } else if (front == -1 && back == -1) {
    clog("Change on both sides");
    for (let i = 0; i <= newChars.length - 1; i++) {
      outputChars.push(
        makeTextFromChar(newChars[i], bold, italic, highlight, medium, large)
      );
    }
  } else {
    clog(
      "change is in the middle",
      front + 1,
      oldChars.length - back - 2,
      front + 1,
      newChars.length - back - 2
    );
    // insert changes from middle
    for (let i = front + 1; i <= newChars.length - back - 2; i++) {
      outputChars.push(
        makeTextFromChar(newChars[i], bold, italic, highlight, medium, large)
      );
    }
    // put unchanged tail
    for (let i = oldChars.length - back - 1; i <= oldChars.length - 1; i++) {
      outputChars.push(oldChars[i]);
    }
  }
  return charsToRange(outputChars);
};

const PlainOrFormattedText = ({
  json,
  myContext,
  rowIndex,
  itemIndex,
  colors,
  isEdit,
  onCard,
  inQuote,
  inBulletedList,
  bold,
  medium,
  large,
  italic,
  highlight,
  list,
  navigation,
  contentModalFunction,
  isLess,
  onSubmit,
  onPressDelete,
  setIsFocusOnQuote,
  calculationOfLength,
  toggleTextOrList,
  setIsFocusOnList,
  isLargePrint,
}) => {
  const [value, setValue] = useState(json.content);
  const [data, setData] = useState(
    json.data == null || json.data == undefined ? null : json.data
  );
  const [hasData, setHasData] = useState(
    json.data == null || json.data == undefined ? false : true
  );
  const [formatted, setFormatted] = useState(
    bold || italic || highlight || medium || large || json?.data != null
  );
  const [currentSelection, setCurrentSelection] = useState(null);
  const [isFocus, setFocus] = useState(false);

  useEffect(() => {
    setFormatted(bold || italic || highlight || medium || large || hasData);
    clog(
      "BOLD",
      bold,
      "ITALIC",
      italic,
      "HIGHLIGHT",
      highlight,
      "MEDIUM",
      medium,
      "LARGE",
      large,
      "selection",
      currentSelection
    );
    if (currentSelection?.start != currentSelection?.end) {
      let chars = rangeToChars(
        data ? data : [{ type: "text", content: value }]
      );
      clog("CHARS IN EFFECT", chars);
      for (
        let i = currentSelection.start;
        chars[i] && i <= currentSelection.end;
        i++
      ) {
        if (bold) {
          chars[i]["bold"] = bold;
        } else if (chars[i].bold) {
          delete chars[i]["bold"];
        }
        if (italic) {
          chars[i]["italic"] = italic;
        } else if (chars[i].italic) {
          delete chars[i]["italic"];
        }
        if (highlight) {
          chars[i]["highlight"] = highlight;
        } else if (chars[i].highlight) {
          delete chars[i]["highlight"];
        }
        if (medium) {
          chars[i]["medium"] = medium;
        } else if (chars[i].medium) {
          delete chars[i]["medium"];
        }
        if (large) {
          chars[i]["large"] = large;
        } else if (chars[i].large) {
          delete chars[i]["large"];
        }
      }
      let range = charsToRange(chars);
      json.data = range;
      setData(range);
      if (!hasData) {
        setHasData(true);
      }
    }
  }, [bold, italic, highlight, medium, large, hasData]);

  useEffect(() => {
    if (isEdit && isFocus) {
      toggleTextOrList && toggleTextOrList(json, rowIndex, inBulletedList);
    }
  }, [list]);

  let placeholder = null;

  if (!json) {
    return <View></View>;
  }
  let style = onCard
    ? { fontSize: 18, lineHeight: 28 }
    : { fontSize: 15, lineHeight: 22 };

  style["color"] = colors.primaryText;

  if (json.style) {
    if (json.style.bold) {
      style["fontWeight"] = "700";
    }
    if (json.style.large) {
      style["fontSize"] = 20;
      style["marginBottom"] = 12;
    }
    if (json.style.medium) {
      style["fontSize"] = 18;
      style["marginBottom"] = 12;
    }
    if (false && json.style.reverse) {
      style["color"] = colors.background;
      style["paddingHorizontal"] = 12;
      style["paddingVertical"] = 4;

      if ((value === "" && isEdit) || value !== "") {
        style["backgroundColor"] = colors.primaryText;
      }
    }
    placeholder = " ";
  }
  if (inQuote) {
    style["fontSize"] = 15;
    style["lineHeight"] = 22;
    style["fontWeight"] = "700";
    style["color"] = colors.quoteText;
  }

  if (isLargePrint) {
    style["fontSize"] = 18;
    style["lineHeight"] = 28;
  }

  clog("PLAIN OR FORMATTED TEXT WITH", json, "isEdit", isEdit, "style", style);

  const _handleChange = (e) => {
    e.target.style.height = 0;
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <View style={Platform.OS === "web" && { flex: 1 }}>
      {isEdit ? (
        <>
          <TextInput
            selectionColor={colors.accent}
            style={[
              {
                color: colors.primaryText,
                flexWrap: "wrap",
                top: -7,
              },
              style,
              Platform.OS === "web" ? { outlineWidth: 0, minHeight: 100 } : {},
            ]}
            onChangeText={(newValue) => {
              json.content = newValue;
              if (formatted) {
                if (!json.data) {
                  json["data"] = [];
                  if (value != null && value.length > 0) {
                    json.data.push({
                      type: "text",
                      content: value,
                    });
                  }
                }
                let modified = handleChanges(
                  json.data,
                  newValue,
                  bold,
                  italic,
                  highlight,
                  medium,
                  large
                );
                json["data"] = modified;
                setData(json.data);
                setHasData(true);
              }
              setValue(newValue);
              calculationOfLength && calculationOfLength();
            }}
            onChange={Platform.OS === "web" && _handleChange}
            onSelectionChange={({ nativeEvent: { selection } }) => {
              setCurrentSelection(selection);
              clog("CURRENT SELECTION", selection);
            }}
            value={Platform.OS === "web" && value}
            multiline={true}
            editable={isEdit}
            placeholder={placeholder && placeholder}
            placeholderTextColor={colors.placeholderText}
            scrollEnabled={false}
            onSubmitEditing={() => onSubmit(rowIndex, inQuote, inBulletedList)}
            blurOnSubmit={inQuote || inBulletedList ? true : false}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Backspace" && !value) {
                inBulletedList
                  ? onPressDelete(rowIndex, itemIndex)
                  : onPressDelete(rowIndex);
              }
            }}
            onFocus={() => {
              if (inQuote) {
                setTimeout(() => setIsFocusOnQuote(true), 0);
              } else if (inBulletedList) {
                setIsFocusOnList(true);
              }
              setFocus(true);
            }}
            onBlur={() => {
              if (inQuote) {
                setTimeout(() => setIsFocusOnQuote(false), 300);
              } else if (inBulletedList) {
                setIsFocusOnList(false);
              }
              setFocus(false);
            }}
            autoFocus={true}
          >
            {data == null && value != null ? (
              <Text>
                {inQuote || onCard
                  ? value
                  : MENTION_FORMATTER(value, navigation, contentModalFunction)}
              </Text>
            ) : (
              data?.map((c, rowIndex) => {
                if (c.type == "text") {
                  return (
                    <Text
                      key={rowIndex}
                      style={[
                        style,
                        c.bold ? { fontWeight: "700" } : {},
                        c.italic ? { fontStyle: "italic" } : {},
                        c.medium ? { fontSize: 18 } : {},
                        c.large ? { fontSize: 20 } : {},
                        c.highlight
                          ? [
                              {
                                backgroundColor: onCard
                                  ? colors.highlight
                                  : "yellow",
                              },
                              colors.highlightedText
                                ? { color: colors.highlightedText }
                                : {},
                            ]
                          : {},
                      ]}
                    >
                      {inQuote || onCard
                        ? c.content
                        : MENTION_FORMATTER(
                            c.content,
                            navigation,
                            contentModalFunction
                          )}
                    </Text>
                  );
                }
              })
            )}
          </TextInput>
        </>
      ) : (
        <View>
          {data == null && value != null && (
            <Text
              style={[
                {
                  flexWrap: "wrap",
                },
                style,
              ]}
              numberOfLines={isLess ? 1 : 0}
            >
              {inQuote || onCard || inBulletedList
                ? value
                : MENTION_FORMATTER(value, navigation, contentModalFunction)}
              {inQuote && <Text style={style}>{'"'}</Text>}
            </Text>
          )}
          {data != null && (
            <View>
              <FormattedText
                data={data}
                style={style}
                onCard={onCard}
                inQuote={inQuote}
                inBulletedList={inBulletedList}
                inReverse={json?.style?.reverse}
                colors={colors}
                navigation={navigation}
                contentModalFunction={contentModalFunction}
                isLess={isLess}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const TextComponent = ({
  json,
  rowIndex,
  itemIndex,
  myContext,
  colors,
  isEdit,
  onCard,
  inQuote,
  inBulletedList,
  bold,
  medium,
  large,
  italic,
  highlight,
  list,
  quote,
  navigation,
  contentModalFunction,
  isLess,
  onSubmit,
  onPressDelete,
  setIsFocusOnQuote,
  setQuote,
  calculationOfLength,
  toggleTextOrList,
  setIsFocusOnList,
  isLargePrint,
}) => {
  if (!json) {
    return <View></View>;
  }

  clog(
    "CAME TO TEXT COMPONENT WITH",
    json,
    "isEdit",
    isEdit,
    "inQuote",
    inQuote
  );

  if (json.type == "text") {
    return (
      <PlainOrFormattedText
        json={json}
        rowIndex={rowIndex}
        itemIndex={itemIndex}
        colors={colors}
        isEdit={isEdit}
        onCard={onCard}
        inQuote={inQuote}
        inBulletedList={inBulletedList}
        bold={bold}
        medium={medium}
        large={large}
        italic={italic}
        highlight={highlight}
        list={list}
        navigation={navigation}
        contentModalFunction={contentModalFunction}
        isLess={isLess}
        onSubmit={onSubmit}
        onPressDelete={onPressDelete}
        setIsFocusOnQuote={setIsFocusOnQuote}
        calculationOfLength={calculationOfLength}
        toggleTextOrList={toggleTextOrList}
        setIsFocusOnList={setIsFocusOnList}
        isLargePrint={isLargePrint}
      />
    );
  } else if (json.type == "quote") {
    return (
      <Quote
        json={json}
        rowIndex={rowIndex}
        colors={colors}
        isEdit={isEdit}
        onCard={onCard}
        bold={bold}
        italic={italic}
        highlight={highlight}
        isLess={isLess}
        onSubmit={onSubmit}
        onPressDelete={onPressDelete}
        setQuote={setQuote}
        calculationOfLength={calculationOfLength}
        quote={quote}
        isLargePrint={isLargePrint}
      />
    );
  } else if (json.type == "bulletedList") {
    return (
      <BulletedList
        json={json}
        rowIndex={rowIndex}
        colors={colors}
        isEdit={isEdit}
        onCard={onCard}
        bold={bold}
        italic={italic}
        highlight={highlight}
        isLess={isLess}
        onPressDelete={onPressDelete}
        isLargePrint={isLargePrint}
      />
    );
  }
};

const BulletedList = ({
  json,
  rowIndex,
  myContext,
  colors,
  isEdit,
  onPressDelete,
  onCard,
  bold,
  italic,
  highlight,
  list,
  isLess,
  onSubmit,
  calculationOfLength,
  toggleTextOrList,
  setList,
  isLargePrint,
}) => {
  if (!json) {
    return <View></View>;
  }
  clog("ASKED TO RENDER", json.data);

  const [isFocusOnList, setIsFocusOnList] = useState(false);

  useEffect(() => {
    if (setList) {
      if (isFocusOnList) {
        setList(true);
      } else {
        setList(false);
      }
    }
  }, [isFocusOnList]);

  const renderItems = () => {
    if (!isLess) {
      return json.data?.map((c, index) => {
        clog("Render PART", c);
        if (c.type == "text") {
          return (
            <View
              key={index}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  height: "100%",
                  paddingTop: onCard ? 11 : 9,
                }}
              >
                <Image
                  source={bullet}
                  style={{
                    width: 6,
                    height: 6,
                    tintColor: colors.primaryText,
                  }}
                />
              </View>
              <View style={[{ paddingLeft: 16, flex: 1 }]}>
                <TextComponent
                  json={c}
                  rowIndex={rowIndex}
                  itemIndex={index}
                  colors={colors}
                  isEdit={isEdit}
                  onCard={onCard}
                  inBulletedList={true}
                  bold={bold}
                  italic={italic}
                  highlight={highlight}
                  list={list}
                  isLess={isLess}
                  onPressDelete={onPressDelete}
                  onSubmit={onSubmit}
                  calculationOfLength={calculationOfLength}
                  toggleTextOrList={toggleTextOrList}
                  setIsFocusOnList={setIsFocusOnList}
                  isLargePrint={isLargePrint}
                />
              </View>
            </View>
          );
        }
      });
    } else {
      return (
        <View
          key={0}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 5,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              height: "100%",
              paddingTop: onCard ? 11 : 9,
            }}
          >
            <Image
              source={bullet}
              style={{
                width: 6,
                height: 6,
                tintColor: colors.primaryText,
              }}
            />
          </View>
          <View
            style={[
              { paddingLeft: 16 },
              Platform.OS == "web" ? { flex: 1 } : {},
            ]}
          >
            <TextComponent
              json={json.data[0]}
              colors={colors}
              isEdit={isEdit}
              onCard={onCard}
              bold={bold}
              italic={italic}
              highlight={highlight}
              isLess={isLess}
            />
          </View>
        </View>
      );
    }
  };
  return (
    <View
      style={{
        width: "100%",
        //marginVertical: 8,
        //padding: 5,
      }}
    >
      {renderItems()}
    </View>
  );
};

const Quote = ({
  json,
  rowIndex,
  colors,
  isEdit,
  onCard,
  bold,
  italic,
  highlight,
  isLess,
  onSubmit,
  onPressDelete,
  setQuote,
  calculationOfLength,
  quote,
  isLargePrint,
}) => {
  const [attribution, setAttribution] = useState(
    json?.attribution?.content ? json.attribution.content : null
  );
  const [isFocusOnQuote, setIsFocusOnQuote] = useState(false);
  const [isFocusOnSource, setIsFocusOnSource] = useState(false);

  useEffect(() => {
    if (setQuote) {
      if (isFocusOnSource || isFocusOnQuote) {
        setQuote(true);
      } else if (!isFocusOnSource && !isFocusOnQuote) {
        setQuote(false);
      }
    }
  }, [isFocusOnSource, isFocusOnQuote]);

  useEffect(() => {
    if (isEdit) {
      if (!quote && (isFocusOnSource || isFocusOnQuote)) {
        onPressDelete(rowIndex);
      }
    }
  }, [quote]);

  if (!json) {
    return <View></View>;
  }
  clog("QUOTE WITH", json, "isEdit", isEdit);
  return (
    <>
      <View
        style={[
          {
            borderLeftWidth: onCard ? 0 : 2,
            borderLeftColor: villageColors.Canary,
            paddingLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
          },
          Platform.OS == "web" ? { flex: 1 } : {},
        ]}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 15,
              lineHeight: 22,
              fontWeight: "700",
              color: colors.quoteText,
            }}
          >
            {'"'}
          </Text>

          <View style={{ flexDirection: "column", flex: 1 }}>
            <View style={{ flexDirection: "row" }}>
              <TextComponent
                json={json.data}
                rowIndex={rowIndex}
                colors={colors}
                isEdit={isEdit}
                inQuote={true}
                bold={bold}
                italic={italic}
                highlight={highlight}
                isLess={isLess}
                onSubmit={onSubmit}
                onPressDelete={onPressDelete}
                setIsFocusOnQuote={setIsFocusOnQuote}
                calculationOfLength={calculationOfLength}
                isLargePrint={isLargePrint}
              />
              {isEdit && (
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    fontWeight: "700",
                    color: colors.quoteText,
                  }}
                >
                  {'"'}
                </Text>
              )}
            </View>

            {isEdit ? (
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    color: colors.quoteText,
                    marginTop: isEdit ? 15 : 8,
                  }}
                >
                  {"— "}
                </Text>
                <TextInput
                  selectionColor={colors.accent}
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    color: colors.quoteText,
                    marginTop: Platform.OS === "web" ? 15 : 8,
                  }}
                  placeholder={"Source (optional)"}
                  placeholderTextColor={colors.quoteText}
                  value={attribution === null ? "" : attribution}
                  onChangeText={(value) => {
                    setAttribution(value);
                    json.attribution.content = value;
                  }}
                  multiline={true}
                  editable={isEdit}
                  scrollEnabled={false}
                  onFocus={() => {
                    if (attribution === null && !json["attribution"]) {
                      json["attribution"] = { content: "", type: "text" };
                      setAttribution("");
                    }
                    setIsFocusOnSource(true);
                  }}
                  onBlur={() => setIsFocusOnSource(false)}
                />

                {!!attribution && (
                  <Pressable
                    style={{
                      marginLeft: 6,
                      height: 20,
                      width: 20,
                      backgroundColor: villageColors.Stone,
                      borderRadius: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "flex-end",
                    }}
                    onPress={() => {
                      setAttribution(null);
                      json["attribution"] = { content: "", type: "text" };
                    }}
                  >
                    <Image
                      source={cross}
                      style={{
                        width: 8,
                        height: 8,
                        tintColor: villageColors.Raven,
                      }}
                    />
                  </Pressable>
                )}
              </View>
            ) : attribution === null || attribution === "" || isLess ? null : (
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    color: colors.quoteText,
                    marginTop: json?.attribution?.content
                      ? isEdit
                        ? 15
                        : 8
                      : 0,
                  }}
                >
                  {"— "}
                </Text>
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    color: colors.quoteText,
                    marginTop: json?.attribution?.content ? 8 : 0,
                  }}
                >
                  {attribution}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

const TextCard = ({
  json,
  myContext,
  colors,
  index,
  showButtons,
  onPressList,
  onPressDelete,
  bold,
  italic,
  highlight,
  seed,
  isLargePrint,
}) => {
  if (!json) {
    return <View></View>;
  }
  clog("TEXT CARD RENDERING FOR", json);
  return (
    <View
      style={[
        {
          padding: 32,
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          backgroundColor:
            colors.textCardBackgrounds[
              seed % colors.textCardBackgrounds.length
            ],
          borderRadius: 16,
          minHeight: 180, //
        },
        json.style,
      ]}
    >
      {json.data?.map((t, tIndex) => {
        if (t.type === "bulletedList") {
          return (
            <BulletedListWithButtons
              key={tIndex}
              json={json}
              data={t}
              colors={colors}
              showButtons={showButtons}
              rowIndex={tIndex}
              onPressList={onPressList}
              onPressDelete={onPressDelete}
              onCard={true}
              bold={bold}
              italic={italic}
              highlight={highlight}
              isLargePrint={isLargePrint}
            />
          );
        } else {
          return (
            <View key={tIndex} style={{ width: "100%" }}>
              <TextComponentWithButtons
                key={tIndex}
                json={json}
                data={t}
                colors={colors}
                index={tIndex}
                onPressDelete={onPressDelete}
                showButtons={showButtons}
                onCard={true}
                bold={bold}
                italic={italic}
                highlight={highlight}
                isLargePrint={isLargePrint}
              />
            </View>
          );
        }
      })}
    </View>
  );
};

const TextComponentWithButtons = ({
  json,
  data,
  index,
  colors,
  showButtons,
  onPressDelete,
  onCard,
  inQuote,
  inBulletedList,
  bold,
  italic,
  highlight,
  navigation,
  contentModalFunction,
  isLess,
  isLargePrint,
}) => {
  const [isEdit, setIsEdit] = useState(true);
  clog(
    "TEXT COMPONENT WITH BUTTONS WITH",
    json,
    "isEdit",
    isEdit,
    "showButtons",
    showButtons
  );
  return (
    <>
      <View>
        <TextComponent
          json={data}
          colors={colors}
          isEdit={isEdit && showButtons}
          onCard={onCard}
          inQuote={inQuote}
          inBulletedList={inBulletedList}
          bold={bold}
          italic={italic}
          highlight={highlight}
          navigation={navigation}
          contentModalFunction={contentModalFunction}
          isLess={isLess}
          isLargePrint={isLargePrint}
        />
      </View>
      {showButtons && (
        <Buttons
          json={json}
          data={data}
          rowIndex={index}
          isEdit={isEdit}
          onPressDelete={onPressDelete}
          onPressEdit={(value) => setIsEdit(value)}
        />
      )}
    </>
  );
};

const TextCardWithButtons = ({
  json,
  data,
  colors,
  rowIndex,
  editCard,
  onPressText,
  onPressQuote,
  onPressList,
  onPressDelete,
  bold,
  italic,
  highlight,
  seed,
  isLargePrint,
}) => {
  const [isEdit, setIsEdit] = useState(true);

  return (
    <View>
      <TextCard
        json={data}
        colors={colors}
        index={rowIndex}
        showButtons={editCard && isEdit}
        onPressText={onPressText}
        onPressQuote={onPressQuote}
        onPressList={onPressList}
        onPressDelete={onPressDelete}
        bold={bold}
        italic={italic}
        highlight={highlight}
        seed={seed}
        isLargePrint={isLargePrint}
      />
      {editCard && (
        <Buttons
          json={json}
          data={data}
          rowIndex={rowIndex}
          isEdit={isEdit}
          onPressText={onPressText}
          onPressQuote={onPressQuote}
          onPressList={onPressList}
          onPressEdit={() => setIsEdit(!isEdit)}
          onPressDelete={onPressDelete}
        />
      )}
    </View>
  );
};

const BulletedListWithButtons = ({
  json,
  data,
  rowIndex,
  colors,
  showButtons,
  onPressList,
  onPressDelete,
  onCard,
  bold,
  italic,
  highlight,
  isLess,
  onSubmit,
  isLargePrint,
}) => {
  const [isEdit, setIsEdit] = useState(true);

  return (
    <>
      <BulletedList
        json={data}
        rowIndex={rowIndex}
        colors={colors}
        isEdit={isEdit && showButtons}
        onPressDelete={onPressDelete}
        showButtons={showButtons}
        onCard={onCard}
        bold={bold}
        italic={italic}
        highlight={highlight}
        isLess={isLess}
        onSubmit={onSubmit}
        isLargePrint={isLargePrint}
      />
      {showButtons && (
        <Buttons
          json={json}
          data={data}
          rowIndex={rowIndex}
          isEdit={isEdit}
          onPressList={onPressList}
          onPressEdit={() => setIsEdit(!isEdit)}
          onPressDelete={onPressDelete}
        />
      )}
    </>
  );
};

const SlideWithButtons = ({
  key,
  json,
  data,
  colors,
  width,
  rowIndex,
  editCard,
  onPressText,
  onPressQuote,
  onPressList,
  onPressDelete,
  onPressTextCard,
  onPressImage,
  myContext,
  bold,
  italic,
  highlight,
  seed,
  isLargePrint,
}) => {
  const [isEdit, setIsEdit] = useState(true);

  clog("SLIDING PANEL WITH WIDTH", width);

  const renderItem = ({ item, index }) => {
    clog("CHECK INDEX", index, "ITEM", item);
    return (
      <View
        key={key}
        style={[
          {
            flex: 1,
            height: editCard && isEdit ? "100%" : width,
            minHeight: width,
            width: width,
            backgroundColor:
              colors.textCardBackgrounds[
                seed % colors.textCardBackgrounds.length
              ],
            borderRadius: 16,
            overflow: "hidden",
            justifyContent: "center",
          },
        ]}
      >
        {item.type == "image" && (
          <ImageWithButtons
            key={index}
            json={data}
            data={item}
            rowIndex={index}
            colors={colors}
            width={width}
            showButtons={editCard && isEdit}
            onPressDelete={onPressDelete}
            myContext={myContext}
            onSlide={true}
            isEdit={isEdit}
          />
        )}
        {item.type == "textCard" && (
          <View
            style={{
              height: editCard && isEdit ? "100%" : width,
              width: width,
              backgroundColor:
                colors.textCardBackgrounds[
                  seed % colors.textCardBackgrounds.length
                ],
            }}
          >
            <TextCardWithButtons
              key={index}
              json={data}
              data={item}
              colors={colors}
              rowIndex={index}
              editCard={editCard && isEdit}
              onPressText={onPressText}
              onPressQuote={onPressQuote}
              onPressList={onPressList}
              onPressDelete={onPressDelete}
              bold={bold}
              italic={italic}
              highlight={highlight}
              seed={seed}
              isLargePrint={isLargePrint}
            />
          </View>
        )}
      </View>
    );
  };

  return (
    <>
      <Slide
        key={rowIndex}
        data={data.data}
        duration={10000}
        width={width}
        height={width}
        renderItem={renderItem}
        keyExtractor={(item, index) => {
          return index;
        }}
        autoscroll={false}
        currentIndexCallback={(index) => clog("Index", index)}
        onPress={(item) => clog("PRESSED", item)}
        indicator={true}
        indicatorStyle={{}}
        indicatorContainerStyle={{}}
        indicatorActiveColor={colors.primaryText}
        indicatorInactiveColor={colors.smallDivider}
        indicatorActiveWidth={6}
        seed={seed}
      />
      {editCard && (
        <Buttons
          json={json}
          data={data}
          rowIndex={rowIndex}
          isEdit={isEdit}
          onPressText={onPressText}
          onPressQuote={onPressQuote}
          onPressList={onPressList}
          onPressEdit={() => setIsEdit(!isEdit)}
          onPressDelete={onPressDelete}
          onPressTextCard={onPressTextCard}
          onPressImage={onPressImage}
        />
      )}
    </>
  );
};

const ImageWithButtons = ({
  json,
  data,
  rowIndex,
  colors,
  width,
  showButtons,
  isEdit,
  onPressDelete,
  myContext,
  onSlide,
}) => {
  const [showAlert, setShowAlert] = useState(false);

  const [value, setValue] = useState(data.photoUrl);
  const [imageUrl, setImageUrl] = useState(
    data?.photo && myContext.presignedUrls?.[data.photo]
      ? myContext.presignedUrls[data.photo]?.uri
      : data?.photoUrl
      ? data?.photoUrl
      : null
  );
  useEffect(() => {
    setImageUrl(
      data?.photo && myContext.presignedUrls?.[data.photo]
        ? myContext.presignedUrls[data.photo]?.uri
        : data?.photoUrl
        ? data?.photoUrl
        : null
    );
  }, [data.photo, data.photoUrl]);

  return (
    <View>
      <View style={{ marginBottom: onSlide ? 0 : 10 }}>
        {imageUrl !== null && (
          <View>
            <ScalableImage
              width={width}
              source={{ uri: imageUrl }}
              style={{
                borderRadius: 16,
                overflow: "hidden",
              }}
            />
            {isEdit && (
              <CustomButton
                style={({ pressed }) => [
                  {
                    right: 9,
                    top: 10,
                    position: "absolute",
                    height: 32,
                    width: 32,
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: villageColors.Raven50,
                    opacity: pressed ? 0.5 : 1,
                  },
                ]}
                onPress={() => {
                  if (Platform.OS !== "web") {
                    Alert.alert("Remove this image?", "", [
                      {
                        style: "cancel",
                        text: "Cancel",
                        onPress: () => {},
                      },
                      {
                        text: "Remove",
                        onPress: () => onPressDelete(rowIndex),
                      },
                    ]);
                  } else {
                    setShowAlert(true);
                  }
                }}
                icon={trash}
                imageStyle={{
                  width: 24,
                  height: 24,
                  tintColor: villageColors.White,
                }}
              />
            )}
          </View>
        )}
        {showButtons && isEdit && (
          <View
            style={{
              borderRadius: 16,
              borderWidth: 1,
              borderColor: colors.textFieldBorder,
              padding: 10,
              marginTop: 10,
            }}
          >
            <TextInput
              style={{
                fontSize: 15,
                color: colors.primaryText,
                lineHeight: 22,
                flexWrap: "wrap",
              }}
              value={value}
              onChangeText={(newValue) => {
                setValue(newValue);
                data["photoUrl"] = newValue;
              }}
              placeholder={"Paste image url"}
            />
          </View>
        )}
        {onSlide && showButtons && isEdit && (
          <Pressable
            onPress={() => {
              console.log("Will upload image");
              getLocalImage(myContext, (success, key, status) => {
                if (success) {
                  console.log("uploaded with key", key);
                  setImageUrl(myContext.presignedUrls[key]?.uri);
                  data["photo"] = key;
                } else {
                  console.log("did not updated because", status);
                }
              });
            }}
          >
            <View
              style={{
                backgroundColor: colors.primaryButtonBackground,
                padding: 8,
                marginTop: 8,
                borderRadius: 8,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                textAlignVertical: "center",
              }}
            >
              <Text
                style={{
                  color: colors.primaryButtonText,
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {"Upload image from device"}
              </Text>
            </View>
          </Pressable>
        )}
      </View>

      <AwesomeAlert
        show={showAlert}
        showProgress={false}
        title={"Remove this image?"}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText={"Cancel"}
        confirmText={"Remove"}
        confirmButtonColor={colors.cancelButton}
        onConfirmPressed={() => {
          onPressDelete(rowIndex);
          setShowAlert(false);
        }}
        onCancelPressed={() => {
          setShowAlert(false);
        }}
        onDismiss={() => setShowAlert(false)}
      />
    </View>
  );
};

const UrlList = ({ json, isEdit, colors, myContext, onPressDelete }) => {
  const [urls, setUrls] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [value]);

  async function getData() {
    setLoading(true);

    const lurl = [];

    for (const item of json.list) {
      if (item.Id) {
        let response = await API.graphql(
          graphqlOperation(getUrl, { Id: item.Id })
        );
        lurl.push(response?.data?.getUrl);
      } else if (item.url) {
        let response = await API.graphql(
          graphqlOperation(findUrl, { uri: item.url })
        );

        if (response.data.byUrl.items.length == 1) {
          lurl.push(response?.data?.byUrl?.items[0]);
          if (!item.Id) {
            item["Id"] = response?.data?.byUrl?.items[0].Id;
          }
        } else if (response.data.byUrl.items.length == 0) {
          let title,
            snippet,
            photo,
            type,
            author = "";

          if (item.url != null && item.url != "") {
            const hostPattern = /http.*\/\/(((www|vm)\.)?([^\/]*))/i;
            let matches = item.url.match(hostPattern);
            console.log("clipboard matches", matches);
            if (matches) {
              console.log("success with url in clipboard", item.url);

              await populateMetadata(
                item.url,
                (value) => (title = value),
                (value) => (snippet = value),
                (value) => (type = value),
                (value) => (photo = value),
                (value) => (author = value),
                () => {}
              );
            }

            let id = generateUniqueId();
            let promises = [];

            let urlObject = await saveUrl({
              url: item.url,
              promises,
              uploadPhoto: async (prefix, imageURI) => {
                if (Platform.OS === "web") {
                  return await uploadPhotoWeb(prefix, imageURI);
                } else {
                  return await uploadPhoto(prefix, imageURI);
                }
              },
              urlId: id,
              title,
              type,
              author,
              snippet,
              photo,
              curatorId: myContext.Id,
            });

            let responses = await Promise.all(promises);
            lurl.push(urlObject);

            item["id"] = responses[0]?.data?.createUrl?.Id;
          }
        }
      }
    }

    setUrls(lurl);
    setLoading(false);
  }

  const renderItem = ({ item }) => {
    let id = photoIdFromId(item.Id);
    let screenshotUrl = null;
    if (item?.photo) {
      if (myContext.presignedUrls[item.photo]) {
        screenshotUrl = myContext.presignedUrls[item.photo]?.uri;
      }
    }
    if (!screenshotUrl && item?.photoUrl) {
      screenshotUrl = item.photoUrl;
    }
    if (!screenshotUrl) {
      clog("ID for picsum", id);
      screenshotUrl = "https://picsum.photos/id/" + id + "/300/200";
    }
    if (item) {
      item.screenshotUrl = screenshotUrl;
    }

    return (
      <Pressable
        onPress={() => {}}
        style={[
          styles.contentPreviewContainer,
          {
            borderColor: colors.smallDivider,
          },
        ]}
      >
        <View style={{ flexDirection: "row", paddingBottom: 16 }}>
          <View style={{ flex: 1, marginRight: 8 }}>
            <Text
              style={[
                styles.title,
                {
                  color: colors.primaryText,
                },
              ]}
              numberOfLines={3}
              ellipsizeMode={"tail"}
            >
              {item?.title}
            </Text>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <SourceIcon
                url={item?.uri}
                icon={
                  item?.source?.avatar
                    ? myContext.presignedUrls[item.source.avatar]
                      ? myContext.presignedUrls[item.source.avatar]?.uri
                      : item?.source?.avatarUrl
                    : item?.source?.avatarUrl
                }
                style={{
                  marginRight: 4,
                  height: 16,
                  width: 16,
                }}
                textstyle={{
                  fontSize: 16,
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
                showText={false}
              />

              <Text style={{ color: colors.placeholderText, fontSize: 12 }}>
                {item?.sourceId}
              </Text>

              {item?.authorName != null && item?.authorName != "" && (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 4,
                  }}
                >
                  <Text
                    style={{
                      color: colors.placeholderText,
                      fontSize: 12,
                      marginRight: 4,
                    }}
                  >
                    {"·"}
                  </Text>

                  <Text
                    style={{
                      color: colors.placeholderText,
                      fontSize: 12,
                    }}
                    numberOfLines={1}
                  >
                    {item?.authorName?.split(",")?.[0]}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View
            style={{
              alignSelf: "flex-start",
              width: 100,
              height: 68,
            }}
          >
            {item?.screenshotUrl ? (
              <Image
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 8,
                  resizeMode: "cover",
                }}
                source={{
                  uri: item.screenshotUrl,
                }}
              />
            ) : (
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 8,
                  backgroundColor: colors.contentScreenBackground,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: 24,
                    height: 24,
                    resizeMode: "cover",
                  }}
                  source={link}
                />
              </View>
            )}
          </View>
        </View>
      </Pressable>
    );
  };

  return (
    <>
      {loading ? (
        <ActivityIndicator animating={loading} style={{ marginVertical: 16 }} />
      ) : (
        <FlatList data={urls} renderItem={renderItem} />
      )}

      {isEdit && (
        <View
          style={{
            borderRadius: 16,
            borderWidth: 1,
            borderColor: colors.textFieldBorder,
            padding: 10,
            marginTop: 10,
          }}
        >
          <TextInput
            style={{
              fontSize: 15,
              color: colors.primaryText,
              lineHeight: 22,
              flexWrap: "wrap",
            }}
            value={value}
            onChangeText={(newValue) => {
              let separateLines = newValue
                .split(/\r?\n|\r|\n/g)
                .filter(Boolean);

              json.list = separateLines.map((item) => {
                return {
                  url: item,
                };
              });

              setValue(newValue);
            }}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Backspace" && !value) {
                onPressDelete();
              }
            }}
            placeholder={"Paste url"}
            multiline={true}
          />
        </View>
      )}
    </>
  );
};

export default ShareNote;

const styles = StyleSheet.create({
  editButton: {
    height: 32,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  imageButton: {
    width: 25,
    height: 25,
  },
  sourceCreditButton: {
    alignSelf: "flex-start",
    marginTop: 10,
    marginLeft: 10,
    borderRadius: 25,
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
  sourceCreditTitle: {
    fontWeight: "700",
    fontSize: 12,
  },
  contentPreviewContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 16,
    borderBottomWidth: 1,
  },
  title: {
    fontSize: 18,
    lineHeight: 28,
    fontWeight: "700",
    flexWrap: "wrap",
  },
});
