import React, { useEffect, useState, useContext, useRef } from "react";
import {
  ActionSheetIOS,
  Alert,
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  FlatList,
  Image,
  Platform,
  Pressable,
} from "react-native";

import { API, graphqlOperation } from "aws-amplify";
import {
  getGroup,
  removePin,
  removeList,
  updateUrlForPinning,
  updateUserCounts,
  updateListCounts,
  addPin,
  removeAction,
} from "../src/graphql/custom";
import AppContext from "../components/AppContext";
import { sharedStyles } from "../utils/SharedStyles";
import Avatar from "../components/Avatar";
import pen from "../assets/pen.png";
import plus from "../assets/plus.png";
import AwesomeAlert from "react-native-awesome-alerts";
import MultiButtonAlert from "../utils/MultiButtonAlerts";
import Blocker from "../components/Blocker";
import { removeActionByUser, performAction, undoAction } from "../utils/Action";
import { generateUniqueId } from "../utils/Id";
import { batchPresign } from "../utils/Photo";
import { addIds, removeIds } from "../utils/IdList";
import { timeStamp } from "../utils/TimeStamp";
import LeftArrow from "../components/LeftArrow";
import UserList from "../components/UserList";
import NameCard from "../components/NameCard";
import { clog } from "../utils/Log";
import { useTheme } from "../theme";

const GroupScreen = ({ route, navigation }) => {
  async function getData(Id, name, owner) {
    clog("asked to get membership data for ", Id);
    if (!Id) {
      return;
    }
    try {
      const groupData = await API.graphql(
        graphqlOperation(getGroup, { Id: Id })
      );
      clog("groupData:", groupData);
      if (groupData?.data?.getGroup) {
        const data = groupData?.data?.getGroup;
        clog("data is ", data);
        let mayNeedLookUp = {};
        let memberUsers = [];
        data?.memberUsers?.items
          ?.sort((a, b) => (a.user.handle < b.user.handle ? 1 : -1))
          ?.forEach((element) => {
            memberUsers.push(element.user);
            if (element.user.avatar) {
              mayNeedLookUp[element.user.avatar] = true;
            }
          });

        await batchPresign(
          Object.keys(mayNeedLookUp),
          myContext.presignedUrls,
          null
        );

        fetchedGroup = {
          Id: Id,
          name: data?.name,
          owner: owner,
          description: data?.description,
          implicit: data?.implicit,
          users: memberUsers,
        };
        setState((currentState) => {
          ////clog("base state", currentState);
          return {
            ...currentState,
            Id: Id,
            name: data?.name,
            owner: owner,
            description: data?.description,
            implicit: data?.implicit,
            users: memberUsers,
            fetchTimeStamp: timeStamp(),
            version: myContext.version,
          };
        });
      }
    } catch (err) {
      console.log("error fetching group...", err);
    }
  }

  async function deleteAPin(directPinUrl) {
    clog("will try to delete", selectedPinUrl);
    if (directPinUrl == null) {
      directPinUrl = selectedPinUrl;
    }
    setLoading(true);
    try {
      let promises = [];
      let urlCounters = {};
      let url = directPinUrl;
      let pin = url?.pins?.[0];
      urlCounters["Id"] = url?.Id;
      urlCounters["numContribute"] = url.numContribute - 1;
      urlCounters["numPins"] = url.numPins - 1;

      let likerIds = [];
      let curatorIds = [];
      pin?.actions?.items?.forEach((action) => {
        if (action?.operation == "Like") {
          likerIds.push(action?.actorId);
        }
        if (action?.operation == "Create") {
          curatorIds.push(action?.actorId);
        }
        promises.push(
          API.graphql(
            graphqlOperation(removeAction, {
              Id: action?.Id,
            })
          )
        );
      });
      if (curatorIds.length) {
        urlCounters["curatorIds"] = removeIds(url?.curatorIds, curatorIds);
      }
      if (likerIds.length) {
        urlCounters["likerIds"] = removeIds(url?.likerIds, likerIds);
      }
      urlCounters["listIds"] = removeIds(url?.listIds, [state.Id]);

      promises.push(
        API.graphql(graphqlOperation(updateUrlForPinning, urlCounters))
      );

      // delte the Pin
      clog("will delete pin with", directPinUrl);
      promises.push(API.graphql(graphqlOperation(removePin, { Id: pin.Id })));

      // TODO(alpha): update topics for the list by recomputing topics based on remaining pins

      promises.push(
        API.graphql(
          graphqlOperation(updateListCounts, {
            Id: state.Id,
            numPins: state.NumPins - 1,
            topicIds: removeIds(state?.topicIds, url?.topicIds?.split(",")),
          })
        )
      );

      // update user stats
      ////clog("user Id", myContext.Id);
      promises.push(
        API.graphql(
          graphqlOperation(updateUserCounts, {
            Id: myContext.Id,
            numPinCreate: myContext.numPinCreate - 1,
            numUrlContribute: myContext.numUrlContribute - 1,
            numCreateRecursive: myContext.numCreateRecursive - 1,
            topicIds: removeIds(myContext.topicIds, url?.topicIds?.split(",")),
          })
        )
      );

      // TODO(alpha): Add ACTION for delete pin, delete url contribute
      const responses = await Promise.all(promises);
      clog("responses", responses);
      myContext["numPinCreate"]--;
      myContext["numUrlContribute"]--;
      myContext["numCreateRecursive"]--;
      myContext["topicIds"] = removeIds(
        myContext.topicIds,
        url.topicIds.split(",")
      );
      myContext.version++;
      setState((currentState) => {
        ////clog("base state", currentState);
        let remainingPins = [];
        currentState.pins.map((p) => {
          clog("consider pin", p);
          if (p.Id != pin.Id) {
            remainingPins.push(p);
          }
        });
        let remainingUrls = [];
        currentState.urls.map((u) => {
          clog("consider url", u);
          if (u.Id != url.Id) {
            remainingUrls.push(u);
          }
        });
        clog("remaining pins", remainingPins);
        clog("remaining urls", remainingUrls);
        return {
          ...currentState,
          total: currentState.total - 1,
          pins: remainingPins,
          urls: remainingUrls,
          topicIds: removeIds(
            currentState?.topicIds,
            url?.topicIds?.split(",")
          ),
        };
      });
      setLoading(false);
      return;
    } catch (err) {
      setLoading(false);
      showMessage("Failed to delete Pin!");
      console.log("error deleting pin...", err);
    }
  }

  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  const renderItem = ({ item }) => {
    let following = false;
    let relationshipId = null;
    // NOTE(alpha): Accesses relationship Id
    if (myContext.actionsByUser?.["User"]?.["Follow"]?.[item.Id]) {
      following = true;
      relationshipId = Object.keys(
        myContext.actionsByUser["User"]["Follow"][item.Id]
      )?.[0];
    }
    clog("item", item, "following", following);
    let fn = following ? undoAction : performAction;
    ////clog("state is", state);
    let updatefn = following ? state.removeFunction : state.addFunction;
    ////clog("log update function", updatefn);
    let callback = (modifiedRelationshipId, createdAt) => {
      if (following) {
        item.numFollow--;
      } else {
        item.numFollow++;
      }
      updatefn(item?.Id, modifiedRelationshipId, item, createdAt);
      setLoading(false);
      ////clog("app context is", myContext);
    };
    return (
      <View style={styles.followableUser}>
        <View style={{ flex: 1, marginRight: 10 }}>
          <Pressable
            onPress={() => {
              ////clog("pressed button for", item);
              //setUserData({ Id: item.Id, handle: item.handle });
              //setModalVisible(true);
              navigation.push("UserDetails", {
                Id: item?.Id,
                handle: item?.handle,
                fromList: true,
                refererActionId: null,
                hostUserId: null,
                item,
              });
            }}
          >
            <NameCard item={item} myContext={myContext} colors={colors} />
          </Pressable>
        </View>
        {item?.Id != myContext.Id && (
          <View style={{}}>
            <Pressable
              onPress={() => {
                setLoading(true);
                fn({
                  objectType: "User",
                  operation: "Follow",
                  objectId: item?.Id,
                  actorId: myContext.Id,
                  relationshipId: relationshipId,
                  objectCounter: item?.numFollow,
                  userCounter: myContext?.numUserFollow,
                  curatorId: item?.Id,
                  curatorCounter: item?.numFollowRecursive,
                  promises: [],
                  payloads: [],
                  callback: callback,
                  refererActionId: null,
                  hostUserId: null,
                  seq: null,
                });
              }}
            >
              <View
                style={
                  following ? styles.uncoloredButton : styles.coloredButton
                }
              >
                <Text
                  style={following ? styles.coloredText : styles.uncoloredText}
                >
                  {following ? "Following" : "Follow"}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
        {item?.Id == myContext.Id && (
          <View style={styles.invisibleButton}></View>
        )}
      </View>
    );
  };

  const sharedKeyExtractor = (item) => item?.Id;

  const showInfoMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setInfoMessage(message);
      setShowInfo(true);
    }
  };

  const showAlertMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  clog("navigation is in user details ", navigation);
  clog("route is ", route);
  let myContext = useContext(AppContext);
  clog("app context is", myContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const initialState = {
    Id: route?.params?.Id ? route.params.Id : "",
    name: route?.params?.name ? route.params.name : "",
    owner: route?.params?.owner ? route.params.owner : {},
    description: route?.params?.description ? route.params.description : "",
    pins: [],
    urls: [],
    topics: [],
    fetchTimeStamp: 0,
    version: 0,
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  const { colors } = useTheme();
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showCollectionAlert, setShowCollectionAlert] = useState(false);
  const [showCollectionConfirmationAlert, setShowCollectionConfirmationAlert] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedPinUrl, setSelectedPinUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  let fetchedGroup = null;

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  React.useLayoutEffect(() => {
    if (state.Id) {
      navigation.setOptions({
        headerLeft: () => {
          return (
            <Pressable onPress={navigation.goBack}>
              <View style={{ marginLeft: 10 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        },
        headerBackTitleVisible: false,
        headerTitle: state.name, // TODO: pull the list name
      });
    }
  }, [navigation, state]);

  clog("Updated value of context", myContext);

  async function dataRefresh() {
    const { Id, name, owner } = route.params;
    clog("CLICKED ON TAB with", route.params);

    // do something
    if (
      stateRef.current.Id != Id ||
      myContext.version != stateRef.current.version ||
      stateRef.current.fetchTimeStamp < timeStamp() - 1800
    ) {
      clog(
        "SHOULD FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef,
        "state Id",
        stateRef.current.Id,
        "Id",
        Id
      );
      getData(Id, name, owner).catch((err) => {
        console.log("error finding list data: ", err);
      });
    } else {
      clog(
        "SHOULD NOT FETCH DATA",
        stateRef.current.version,
        myContext.version,
        stateRef
      );
    }
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", dataRefresh);
    return unsubscribe;
  }, [navigation]);

  ////clog("will populate page with", state);
  ////clog("viewer Id", myContext.Id, "owner Id", state.owner.Id);
  if (state.Id) {
    ////clog("REFRESH: viewer Id", myContext.Id, "owner Id", state.owner.Id);
    clog("STATE", state);
    return (
      <SafeAreaView
        style={[sharedStyles.container, { backgroundColor: colors.background }]}
      >
        <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
          <View style={styles.container}>
            <Blocker loading={loading} />
            <UserList
              state={state}
              myContext={myContext}
              navigation={navigation}
              refererActionId={state.refererActionId}
              hostUserId={state.hostUserId}
              listStyle={{
                flex: 1,
                marginBottom: 54,
              }}
              colors={colors}
            ></UserList>
            {myContext.Id != state.owner.Id && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "center",
                  marginTop: 39,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: 6,
                    overflow: "hidden",
                    borderRadius: 10,
                  }}
                >
                  <Avatar
                    item={state.owner}
                    style={{
                      height: 20,
                      width: 20,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                      backgroundColor: "white",
                    }}
                    textstyle={{
                      fontSize: 10,
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                    }}
                    myContext={myContext}
                  ></Avatar>
                </View>
                <Text
                  style={{
                    color: "white",
                    fontSize: 16,
                  }}
                >
                  {state.owner.name}
                </Text>
              </View>
            )}
            {myContext.Id == state.owner.Id && (
              <View style={{ marginTop: 38 }}></View>
            )}
            <View style={{ marginBottom: 46 }}>
              <Text style={styles.description}>{state.description}</Text>
            </View>
            <FlatList
              style={styles.list}
              data={state.users}
              renderItem={renderItem}
              keyExtractor={sharedKeyExtractor}
              extraData={state}
            />
            {Platform.OS == "web" && (
              <AwesomeAlert
                show={showInfo}
                showProgress={false}
                title=""
                message={infoMessage}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                cancelText="Ok"
                cancelButtonColor={colors.cancelButton}
                onCancelPressed={() => {
                  setShowInfo(false);
                }}
              />
            )}
          </View>
        </View>
      </SafeAreaView>
    );
  } else {
    return <View></View>;
  }
};

export default GroupScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
  },
  list: {
    width: "100%",
  },
  followableUser: {
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
  },
  description: {
    fontSize: 16,
    opacity: 0.4,
    color: "white",
    alignSelf: "center",
    marginLeft: 57,
    marginRight: 57,
  },
});
