import React, { useState, useContext, useRef } from "react";
import {
  Alert,
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Pressable,
  Image,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import AwesomeAlert from "react-native-awesome-alerts";
import { Auth } from "aws-amplify";

import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";
import eye from "../assets/eye.png";
import eyeClosed from "../assets/eye-closed.png";
import construction from "../assets/construction.png";

import { sharedStyles } from "../utils/SharedStyles";
import ColoredButton from "../components/ColoredButton";
import { clog } from "../utils/Log";
import { saveDataToSharedStorage } from "../utils/DataStorage";
import { useTheme } from "../theme";
import Blocker from "../components/Blocker";
import PhoneNumberInput from "../components/PhoneNumberInput";

const SignUpScreen = ({ route, navigation }) => {
  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  async function handleSubmit() {
    let message = "";
    let success = false;
    if (
      !myContext.usernameLocal ||
      !myContext.passwordLocal ||
      !myContext.emailLocal ||
      !myContext.phoneLocal
    ) {
      message = "Username, password, email, and phone number are mandatory.";
    }
    if (message) {
      showMessage(message);
    } else {
      setLoading(true);
      clog("WILL TRY TO SIGN UP");
      myContext.username = myContext.usernameLocal;
      myContext.password = myContext.passwordLocal;
      myContext.email = myContext.emailLocal;
      myContext.phone = myContext.phoneLocal;
      myContext.countryCode = myContext.countryCodeLocal
        ? myContext.countryCodeLocal
        : DEFAULT_COUNTRY_CODE;

      const signup_info = {
        username: myContext.username,
        password: myContext.password,
        attributes: {
          phone_number: myContext.countryCode + myContext.phone,
          email: myContext.email,
        },
      };
      try {
        let data = await Auth.signUp(signup_info);
        saveDataToSharedStorage("credentials", { username, password });
        setLoading(false);
        clog(data);
        myContext.authState = "confirmSignUp";
        success = true;
        navigation.push("ConfirmSignUp");
      } catch (err) {
        if (err.code === "UsernameExistsException") {
          console.log("username already exists");
          showMessage("Username already exists");
        } else {
          console.log("Unknown error", err);
          showMessage("Could not sign up", err);
        }
        setLoading(false);
      }
    }
    clog("CONTEXT", myContext);
  }

  const checkUsernameConditions = () => {
    return myContext.usernameLocal ? true : false;
  };

  const checkPasswordConditions = () => {
    return myContext.passwordLocal?.length >= 8;
  };

  const checkEmailConditions = () => {
    return myContext.emailLocal && myContext.emailLocal?.match("@") == "@";
  };

  const checkPhoneConditions = () => {
    return myContext.phoneLocal && myContext.phoneLocal?.length >= 9;
  };

  const checkConditions = () => {
    return (
      checkUsernameConditions() &&
      checkPasswordConditions() &&
      checkEmailConditions() &&
      checkPhoneConditions() &&
      !loading
    );
  };

  const updateConditions = () => {
    if (checkConditions()) {
      setConditionMet(true);
    } else {
      setConditionMet(false);
    }
  };

  const updateConditionsAndDismissKeyboard = () => {
    updateConditions();
    Keyboard.dismiss;
  };

  const DEFAULT_COUNTRY_CODE = "+1";

  const myContext = useContext(AppContext);
  const { colors } = useTheme();
  const [username, setUsername] = useState(
    myContext?.username == null ? "" : myContext?.username
  );
  const [password, setPassword] = useState(
    myContext?.password == null ? "" : myContext?.password
  );
  const [email, setEmail] = useState(
    myContext?.email == null ? "" : myContext?.email
  );
  const [phone, setPhone] = useState(
    myContext?.phone == null ? "" : myContext?.phone
  );
  const [countryCode, setCountryCode] = useState(
    myContext?.countryCode == null
      ? DEFAULT_COUNTRY_CODE
      : myContext?.countryCode
  );
  const [loading, setLoading] = useState(false);
  const [conditionMet, setConditionMet] = useState(checkConditions());
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(true);

  const phoneRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();

  React.useLayoutEffect(() => {
    let showBackButton = navigation.canGoBack();
    clog("Show back button", showBackButton);
    navigation.setOptions({
      headerLeft: () => {
        if (showBackButton) {
          return (
            <Pressable onPress={navigation.goBack}>
              <View style={{ marginLeft: 10 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        } else {
          return <View></View>;
        }
      },
    });
  }, [navigation]);

  const onNext = () => {
    if (checkConditions()) {
      handleSubmit();
    } else {
      showMessage("Username, password, email, and phone number are mandatory.");
    }
  };
  clog(
    "CONDITION MET",
    conditionMet,
    "username",
    checkUsernameConditions(),
    "password",
    checkPasswordConditions(),
    "email",
    checkEmailConditions(),
    "phone",
    checkPhoneConditions()
  );

  const formatPhoneNumber = (text) => {
    const cleanedText = text.replace(/\D/g, "");
    let formattedPhoneNumber = "";
    for (let i = 0; i < cleanedText.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += cleanedText[i];
    }
    setPhone(formattedPhoneNumber);
  };

  return (
    <SafeAreaView
      style={[sharedStyles.container, { backgroundColor: colors.background }]}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <Blocker loading={loading} />
        <KeyboardAwareScrollView
          style={{
            width: "100%",
          }}
        >
          <View style={styles.inputContainer}>
            <Text
              style={{
                color: colors.secondaryText,
                fontSize: 15,
                textAlign: "center",
                paddingLeft: 24,
                paddingRight: 24,
                marginTop: 8,
              }}
            >
              Let’s register you as a villager before
            </Text>
            <Text
              style={{
                color: colors.secondaryText,
                fontSize: 15,
                textAlign: "center",
                paddingLeft: 24,
                paddingRight: 24,
                marginTop: 4,
              }}
            >
              you move in
            </Text>
            <TouchableOpacity
              style={{ alignItems: "center", marginTop: 16 }}
              onPress={() => {
                navigation.push("ConfirmSignUp");
              }}
            >
              <View style={{ width: 358 }}>
                <Text
                  style={[styles.callToAction, { color: colors.primaryText }]}
                  suppressHighlighting={true}
                >
                  Already started signing up?
                  <Text
                    style={[
                      styles.callToActionEmphasized,
                      { color: colors.link },
                    ]}
                  >
                    {" "}
                    Get confirmation code{" "}
                  </Text>
                </Text>
              </View>
            </TouchableOpacity>
            <View style={{ marginTop: 28 }}>
              <Text
                style={[sharedStyles.textLabel, { color: colors.primaryText }]}
              >
                Email
              </Text>
            </View>
            <View
              style={[
                sharedStyles.textInputBox,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                  height: 48,
                  marginBottom: 16,
                },
              ]}
            >
              <TextInput
                style={[
                  sharedStyles.textInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                    height: 48,
                    borderWidth: 0,
                  },
                ]}
                value={email}
                maxLength={200}
                placeholder={"example@village.com"}
                placeholderTextColor={colors.secondaryText}
                //keyboardType={"email-address"}
                autoCapitalize="none"
                onChangeText={(newValue) => {
                  setEmail(newValue);
                  myContext["emailLocal"] = newValue;
                  updateConditions();
                }}
                onBlur={() => {
                  updateConditionsAndDismissKeyboard();
                  if (myContext.emailLocal && !checkEmailConditions()) {
                    showMessage("Email format is invalid");
                  }
                }}
                returnKeyType={"next"}
                blurOnSubmit={false}
                onSubmitEditing={() => phoneRef.current.focus()}
              />
            </View>
            <View>
              <Text
                style={[sharedStyles.textLabel, { color: colors.primaryText }]}
              >
                Phone number
              </Text>

              <View
                style={[
                  sharedStyles.textInputBox,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                    height: 48,
                    marginBottom: 16,
                  },
                ]}
              >
                <PhoneNumberInput
                  ref={phoneRef}
                  colors={colors}
                  phone={phone}
                  countryCode={countryCode}
                  onChangeText={(newValue) => {
                    let cleaned = newValue.replace(/[^0-9]/g, "");
                    myContext["phoneLocal"] = cleaned;
                    formatPhoneNumber(cleaned);
                    updateConditions();
                  }}
                  onCodeChange={(value) => {
                    myContext["countryCodeLocal"] = value;
                    setCountryCode(value);
                  }}
                  onBlur={updateConditionsAndDismissKeyboard}
                  onSubmitEditing={() => usernameRef.current.focus()}
                />
              </View>
            </View>
            <Text
              style={[sharedStyles.textLabel, { color: colors.primaryText }]}
            >
              Village username
            </Text>
            <View
              style={[
                sharedStyles.textInputBox,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                  height: 48,
                  marginBottom: 16,
                },
              ]}
            >
              <TextInput
                ref={usernameRef}
                style={[
                  sharedStyles.textInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                    height: 48,
                    borderWidth: 0,
                  },
                ]}
                value={username}
                maxLength={200}
                autoCapitalize="none"
                placeholder={"Enter only alphanumeric characters"}
                placeholderTextColor={colors.secondaryText}
                onChangeText={(newValue) => {
                  let modifiedValue = newValue
                    .toLowerCase()
                    .replace(/[^a-z0-9]/gi, "");
                  setUsername(modifiedValue);
                  myContext["usernameLocal"] = modifiedValue;
                  updateConditions();
                }}
                onBlur={() => {
                  updateConditionsAndDismissKeyboard();
                }}
                returnKeyType={"next"}
                blurOnSubmit={false}
                onSubmitEditing={() => passwordRef.current.focus()}
              />
            </View>
            <Text
              style={[
                sharedStyles.textLabel,
                { color: colors.primaryText, borderColor: colors.background },
              ]}
            >
              Password
            </Text>
            <View
              style={[
                sharedStyles.textInputBox,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                  height: 48,
                },
              ]}
            >
              <TextInput
                ref={passwordRef}
                secureTextEntry={passwordVisibility}
                style={[
                  sharedStyles.textInput,
                  { color: colors.primaryText, borderWidth: 0 },
                ]}
                placeholder={"Enter at least 8 characters (case sensitive)"}
                placeholderTextColor={colors.secondaryText}
                value={password}
                maxLength={255}
                onChangeText={(newValue) => {
                  setPassword(newValue);
                  myContext["passwordLocal"] = newValue;
                  updateConditions();
                }}
                onBlur={() => {
                  updateConditionsAndDismissKeyboard();
                  if (!checkPasswordConditions()) {
                    showMessage("Password must be at least 8 characters");
                  }
                }}
                returnKeyType={"done"}
                blurOnSubmit={false}
                onSubmitEditing={onNext}
              />
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: 24,
                  width: 24,
                  marginRight: 12,
                }}
              >
                <Pressable
                  onPress={() => {
                    console.log("Pressed eye button");
                    setPasswordVisibility((prev) => {
                      return !prev;
                    });
                  }}
                >
                  <Image
                    source={passwordVisibility ? eye : eyeClosed}
                    style={{
                      height: 13.5,
                      width: 19.5,
                      tintColor: colors.primaryText,
                    }}
                  />
                </Pressable>
              </View>
            </View>
            <View style={{ height: 160, overflow: "hidden" }}>
              <Image
                source={construction}
                style={{
                  height: 240,
                  width: 380,
                  position: "absolute",
                  top: -40,
                }}
              ></Image>
            </View>
            <ColoredButton
              onPress={onNext}
              buttonStyle={
                conditionMet
                  ? {
                      alignItems: "center",
                      justifyContent: "center",
                      height: 43,
                      borderRadius: 100,
                      backgroundColor: colors.primaryButtonBackground,
                    }
                  : {
                      alignItems: "center",
                      justifyContent: "center",
                      height: 43,
                      borderRadius: 100,
                      backgroundColor: colors.disabledPrimaryButtonBackground,
                    }
              }
              textStyle={{
                textAlign: "center",
                textAlignVertical: "center",
                fontSize: 16,
                fontWeight: "700",
                color: conditionMet
                  ? colors.primaryButtonText
                  : colors.disabledPrimaryButtonText,
              }}
              text={"Next"}
            ></ColoredButton>
          </View>
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title=""
            message={alertMessage}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            cancelText="Ok"
            cancelButtonColor={colors.cancelButton}
            onCancelPressed={() => {
              setShowAlert(false);
            }}
          />
        </KeyboardAwareScrollView>
      </View>
    </SafeAreaView>
  );
};

export default SignUpScreen;

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
  },
  callToAction: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
  callToActionEmphasized: {
    fontSize: 12,
    fontWeight: "600",
  },
});
