import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Animated,
  Image,
  StyleSheet,
  View,
  Pressable,
  Platform,
  Text,
  SafeAreaView,
  Dimensions,
  FlatList,
  ScrollView,
  KeyboardAvoidingView,
  Keyboard,
} from "react-native";
import { SearchBar } from "react-native-elements";
import AppContext from "../components/AppContext";
import { sharedStyles } from "../utils/SharedStyles";
import UserList from "../components/UserList";
import ItemList from "../components/ItemList";

import LeftArrow from "../components/LeftArrow";
import villageInSearch from "../assets/village-in-search.png";
import searchIcon from "../assets/search.png";
import link from "../assets/link.png";
import cloud1 from "../assets/cloud_1.png";
import { CommonActions } from "@react-navigation/native";
import { clog } from "../utils/Log";
import { searchUsers, searchUrls } from "../controllers/SearchController";
import { refreshSingleUrl } from "../controllers/CommonFeedController";
import { logEvent } from "../utils/LogEvent";
import { useTheme } from "../theme";
import ColoredButton from "../components/ColoredButton";
import PopUp from "../components/PopUp";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import { findTopActions } from "../utils/TopActions";
import LoadingDots from "../components/LoadingDots";

const SearchScreen = ({ route, navigation }) => {
  const previousPattern = useRef("");

  const updateUserFollowed = (userId) => {
    setState((previousState) => {
      return {
        ...previousState,
        actionsByUser: myContext.actionsByUser,
        numUserFollow: myContext.numUserFollow,
      };
    });
  };

  async function search(pattern) {
    // Save the most recent version of the query, even if it's empty
    previousPattern.current = pattern;

    if (myContext.searchInflight) {
      console.log("Search is already inflight (" + pattern + ")");
      return;
    }

    if (pattern !== "" || (pattern === "" && !subsequent)) {
      myContext["searchInflight"] = true;

      if (pattern) {
        logEvent("Search", {
          userId: myContext?.Id,
          username: myContext?.handle,
          platform: Platform.OS,
          category: task ? (task == "village" ? "myvillage" : task) : "people",
          target: pattern,
          appVersion: myContext.appVersion,
        });
      }

      setLoading(true);

      try {
        await Promise.all([
          searchUsers({
            pattern: previousPattern.current, // Use the most recent version of the query
            includeSelf: subsequent,
            myContext: myContext,
            callback: ({ success, message, error, users }) => {
              if (success) {
                setState((prevState) => {
                  return { ...prevState, users: users };
                });
              } else {
                clog(message);
              }
            },
          }),
          searchUrls({
            pattern: previousPattern.current, // Use the most recent version of the query
            myContext: myContext,
            callback: ({ success, message, error, urls }) => {
              if (success) {
                setState((prevState) => {
                  return { ...prevState, urls: urls };
                });
                clog("MATCHED URLS", urls);
              } else {
                clog(message);
              }
            },
          }),
        ]);

        myContext["searchInflight"] = false;

        // Check if the pattern has changed during the inflight query
        if (previousPattern.current !== pattern) {
          // Run a new search with the updated pattern
          await search(previousPattern.current);
        }
      } catch (error) {
        console.log("Error: ", error);
        myContext["searchInflight"] = false;
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  const initialState = {
    panels: [],
    users: [],
    urls: [],
    addFunction: null,
    removeFunction: null,
  };
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  const { colors } = useTheme();

  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [webModalVisible, setWebModalVisible] = useState(false);
  const [webModalUrl, setWebModalUrl] = useState("");
  const [task, setTask] = useState(route?.params?.taskFromParams);
  const [showReportPopUp, setShowReportPopUp] = useState(false);
  const [messageReportPopUp, setMessageReportPopUp] = useState("");
  const [showNoResult, setShowNoResult] = useState(false);
  const [loadingEmptyList, setLoadingEmptyList] = useState(false);

  const [showOnlyOneTab, setShowOnlyOneTab] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "posts", title: "Posts" },
    { key: "villagers", title: "Villagers" },
  ]);

  const popUpRef = useRef(null);
  const notificationTransformRef = useRef(new Animated.Value(0)).current;
  const notificationOpacityRef = useRef(new Animated.Value(0)).current;
  const flatListRef = React.useRef();

  const myContext = useContext(AppContext);

  if (!myContext.handle) {
    navigation.replace("Authentication");
    return <View></View>;
  }

  const HEADER_HEIGHT = 47;
  const FILTER_BAR_HEIGHT = 0; // 50 if filters turn on or 0 if filters turn off

  const MARGIN_FOR_NOTIFICATION = 75 + HEADER_HEIGHT + FILTER_BAR_HEIGHT;

  const [isFocused, setFocused] = useState(false);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () =>
      setKeyboardVisible(true)
    );
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () =>
      setKeyboardVisible(false)
    );

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    if (state.users.length === 0 || state?.urls.length === 0) {
      setShowOnlyOneTab(true);
      if (state.users.length === 0) {
        setIndex(0);
      } else {
        setIndex(1);
      }
    } else {
      setShowOnlyOneTab(false);
    }
  }, [state?.users, state?.urls]);

  useEffect(() => {
    if (
      query !== "" &&
      state.users.length === 0 &&
      state?.urls.length === 0 &&
      !loading
    ) {
      setLoadingEmptyList(true);
      setTimeout(() => {
        setShowNoResult(true);
        setLoadingEmptyList(false);
      }, 2000);
    } else {
      setShowNoResult(false);
    }
  }, [query, state.users, state?.urls, loading]);

  const onAnimatedNotification = () => {
    Animated.timing(notificationOpacityRef, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();

    Animated.timing(notificationTransformRef, {
      toValue: MARGIN_FOR_NOTIFICATION,
      duration: 800,
      useNativeDriver: true,
    }).start();
  };

  const showAlertMessage = (message) => {
    console.log("WILL SHOW MESSAGE", message);
    setAlertMessage(message);
    setAlertVisible(true);
    onAnimatedNotification();
    setTimeout(() => {
      notificationOpacityRef.setValue(0);
      notificationTransformRef.setValue(0);
      setAlertVisible(false);
    }, 3000);
  };

  const contentModalFunction = (target) => {
    //clog("asked to open website", target);
    if (Platform.OS == "web") {
      window.open(target, "_blank");
    } else {
      setWebModalUrl(target);
      setWebModalVisible(true);
    }
  };

  let subsequent = route?.params?.subsequent;

  React.useLayoutEffect(() => {
    let show = !subsequent;
    if (show) {
      navigation.setOptions({
        headerLeft: () => {
          return (
            <Pressable onPress={navigation.goBack}>
              <View style={{ marginLeft: 10 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        },
        headerTitle: "Build your Village",
      });
    } else {
      navigation.setOptions({
        headerShown: false,
      });
    }
  }, [navigation, state, task]);

  useEffect(() => {
    //clog("will run effect for ", userId);
    if (myContext) {
      setState((prevState) => {
        return {
          ...prevState,
          addFunction: updateUserFollowed,
          removeFunction: updateUserFollowed,
        };
      });
    }

    if (!subsequent) {
      search("");
    }
  }, []);

  const searchFunction = (text) => {
    //clog("asked to search for", text);
    setQuery(text);
    myContext.query = text;
    search(text.toLowerCase());
  };

  const renderScene = SceneMap({
    posts: () => (
      <ItemList
        state={state}
        panels={state?.urls}
        navigation={navigation}
        myContext={myContext}
        task={task}
        forceRefresh={(element, callback) => {
          refreshSingleUrl(
            element,
            myContext,
            state.panels,
            ({ success, message, requestingTask, panels }) => {
              if (success) {
                setState((prevState) => {
                  return {
                    ...prevState,
                    panels: panels,
                    version: myContext.version,
                  };
                });
                if (element.Id == myContext.urlEngagedWith?.Id) {
                  console.log("Already refreshed engaged url");
                  myContext["urlEngagedWith"] = null;
                }
              }
              if (callback) {
                callback();
              }
            }
          );
        }}
        showAlertMessage={showAlertMessage}
        setLoading={setLoading}
        origin={"search"}
        contentModalFunction={contentModalFunction}
        ref={flatListRef}
        colors={colors}
        isExpanding={null}
        showJustification={true}
        onShowReportPopUp={(message) => {
          setMessageReportPopUp(message);
          setShowReportPopUp(true);
          popUpRef.current?.onAnimatedPopUp();
        }}
      />
    ),
    villagers: () => (
      <View
        style={{
          flex: 1,
          width: "100%",
          paddingHorizontal: 16,
          paddingBottom: subsequent ? 0 : 55,
        }}
      >
        <UserList
          state={state}
          myContext={myContext}
          navigation={navigation}
          refererActionId={null}
          hostUserId={null}
          listStyle={{
            flex: 1,
          }}
          extra={state.users}
          origin={"search"}
          colors={colors}
        />
      </View>
    ),
  });

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      style={{
        marginHorizontal: 16,
        elevation: 0,
        shadowOpacity: 0,
        backgroundColor: colors.background,
      }}
      indicatorStyle={{
        height: 2,
        backgroundColor: colors.activeTabUnderline,
      }}
      tabStyle={{ width: "auto" }}
      renderLabel={({ route, focused, color }) => (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            style={{
              color: colors.primaryText,
              fontSize: 15,
              fontWeight: focused ? "700" : "400",
              lineHeight: 22,
            }}
          >
            {route.title}
          </Text>
          <View
            style={{
              backgroundColor: colors.secondaryBackground,
              borderRadius: 100,
              paddingHorizontal: 8,
              paddingVertical: 5,
              marginLeft: 4,
            }}
          >
            <Text
              style={{
                color: colors.primaryText,
                fontSize: 15,
                fontWeight: "700",
                lineHeight: 22,
              }}
            >
              {route.key === "posts"
                ? state?.urls?.length
                : state?.users?.length}
            </Text>
          </View>
        </View>
      )}
    />
  );

  const renderSearchUrlResultList = ({ item }) => {
    const origin = "search";

    const onPress = () => {
      clog("Pressed on item with top action", item?.topActions?.[0]);
      let topAction = item?.justificationAction?.action;
      if (!item.topActions) {
        item["topActions"] = findTopActions(item, myContext, null);
        console.log("Updated top actions", item.topActions);
      }
      clog("URL DETAILS:", item);
      logEvent(
        origin == "myvillage"
          ? "MyVillage_Content"
          : origin == "discover"
          ? "Content"
          : "Feed_Search_Content",
        {
          userId: myContext?.Id,
          username: myContext?.handle,
          category: origin,
          platform: Platform.OS,
          action: "click",
          target: "content",
          url: item?.uri,
          appVersion: myContext.appVersion,
        }
      );
      navigation.push("ItemDetails", {
        url: item,
        curator: myContext.users[topAction?.actorId],
        refererActionId: topAction?.Id,
        hostUserId: null,
        origin: origin,
      });
    };

    return (
      <Pressable
        onPress={onPress}
        style={{
          marginTop: 16,
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <View style={{ marginRight: 10 }}>
          <Image
            style={{ width: 20, height: 20, tintColor: colors.primaryText }}
            source={link}
          />
        </View>
        <View style={{ flex: 1 }}>
          <Text
            style={{ fontSize: 15, lineHeight: 22, color: colors.primaryText }}
          >
            {item.title}
          </Text>
        </View>
      </Pressable>
    );
  };

  const renderContent = () => {
    if (showNoResult) {
      return (
        <View style={styles.containerListEmpty}>
          <Image
            resizeMode="cover"
            source={cloud1}
            style={{
              height: 85,
              width: 125,
            }}
          />
          <Text style={[styles.textListEmpty, { color: colors.primaryText }]}>
            {`No results for "${query}"`}
          </Text>
        </View>
      );
    }

    return (
      <>
        {isKeyboardVisible || (Platform.OS === "web" && isFocused) ? (
          <ScrollView
            style={{
              flex: 1,
              backgroundColor: colors.background,
              paddingHorizontal: 16,
              marginTop: 17,
            }}
            showsVerticalScrollIndicator={false}
          >
            <View>
              <Text
                style={{
                  color: colors.link,
                  fontSize: 15,
                  lineHeight: 22,
                  fontWeight: "700",
                }}
              >{`Search "${query}"`}</Text>
            </View>

            {loading || loadingEmptyList ? (
              <View
                style={{
                  flex: 1,
                  marginTop: 30,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 35,
                  }}
                >
                  <LoadingDots color={colors.accent} />
                </View>
              </View>
            ) : (
              <>
                <UserList
                  state={state}
                  myContext={myContext}
                  navigation={navigation}
                  refererActionId={null}
                  hostUserId={null}
                  extra={state.users}
                  origin={"search"}
                  colors={colors}
                />
                {state.users.length > 0 && state?.urls.length > 0 && (
                  <View
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: colors.smallDivider,
                      marginTop: 16,
                    }}
                  />
                )}

                <FlatList
                  data={state?.urls}
                  renderItem={renderSearchUrlResultList}
                />
              </>
            )}
          </ScrollView>
        ) : (
          <>
            {loading || loadingEmptyList ? (
              <View
                style={{
                  flex: 1,
                  marginTop: 30,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 35,
                  }}
                >
                  <LoadingDots color={colors.accent} />
                </View>
              </View>
            ) : (
              <TabView
                onIndexChange={setIndex}
                navigationState={{ index, routes }}
                renderScene={renderScene}
                renderTabBar={showOnlyOneTab ? () => null : renderTabBar}
                swipeEnabled={
                  Platform.OS !== "web" && !showOnlyOneTab ? true : false
                }
                initialLayout={{
                  height: 0,
                  width: Dimensions.get("window").width,
                }}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <View
      style={[sharedStyles.container, { backgroundColor: colors.background }]}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <SafeAreaView />
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
        >
          <View
            style={{
              width: "100%",
              paddingHorizontal: 16,
            }}
          >
            {!subsequent && (
              <View
                style={{
                  marginTop: 8,
                  alignContent: "center",
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                <Text
                  numberOfLines={2}
                  style={[styles.message, { color: colors.secondaryText }]}
                >
                  It can’t be a village without the people, right?
                </Text>
              </View>
            )}
            {subsequent && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Pressable onPress={navigation.goBack}>
                  <LeftArrow tintColor={colors.primaryText} />
                </Pressable>

                <View style={{ flex: 1 }}>
                  <SearchBar
                    searchIcon={
                      <View>
                        <Image
                          source={searchIcon}
                          style={{
                            width: 24,
                            height: 24,
                            tintColor: colors.placeholderText,
                          }}
                        />
                      </View>
                    }
                    placeholder={"Search Village"}
                    placeholderTextColor={colors.placeholderText}
                    onChangeText={(text) => searchFunction(text)}
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoFocus={true}
                    value={query}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    containerStyle={[
                      styles.containerStyle,
                      {
                        borderRadius: Platform.OS === "android" ? 29 : 0,
                        backgroundColor: colors.background,
                      },
                    ]}
                    inputContainerStyle={[
                      styles.inputContainerStyle,
                      {
                        backgroundColor: colors.background,
                        borderColor: colors.smallDivider,
                      },
                    ]}
                    inputStyle={[
                      styles.inputStyle,
                      {
                        color: colors.primaryText,
                      },
                    ]}
                    rightIconContainerStyle={
                      query && [
                        styles.rightIconContainerStyle,
                        {
                          backgroundColor: colors.secondaryBackground,
                        },
                      ]
                    }
                    leftIconContainerStyle={styles.leftIconContainerStyle}
                  />
                </View>
              </View>
            )}
          </View>

          {renderContent()}

          {false && (
            <Image source={villageInSearch} style={{ height: 85, width: 85 }} />
          )}
        </KeyboardAvoidingView>

        {!subsequent && (
          <ColoredButton
            onPress={() => {
              console.log("clicked button");
              myContext.onboarding = false; // done with onboarding
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "tab", params: { onboarding: true } }],
                })
              );
            }}
            buttonStyle={{
              height: 54,
              width: 357,
              borderRadius: 100,
              justifyContent: "center",
              alignSelf: "center",
              position: "absolute",
              bottom: 30,
              backgroundColor: colors.primaryButtonBackground,
            }}
            textStyle={{
              color: colors.primaryButtonText,
              fontSize: 16,
              fontWeight: "700",
              justifyContent: "center",
              textAlign: "center",
            }}
            text={"Enter your Village"}
          />
        )}
        {showReportPopUp && (
          <PopUp
            ref={popUpRef}
            title={messageReportPopUp}
            onClose={() => setShowReportPopUp(false)}
            colors={colors}
          />
        )}
      </View>
    </View>
  );
};

export default SearchScreen;

const styles = StyleSheet.create({
  message: {
    fontSize: 16,
    textAlign: "center",
  },
  containerListEmpty: {
    flex: 1,
    marginTop: 50,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  containerStyle: {
    justifyContent: "center",
    width: "100%",
    alignSelf: "center",
    borderRadius: 29,
    borderWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  inputContainerStyle: {
    borderWidth: 1,
    borderBottomWidth: 1,
    borderRadius: 100,
  },
  inputStyle: {
    height: 48,
    marginLeft: 8,
    fontSize: 15,
  },
  rightIconContainerStyle: {
    width: 30,
    height: 30,
    paddingRight: 0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
  },
  leftIconContainerStyle: {
    marginLeft: 12,
    paddingRight: 0,
  },
  textListEmpty: {
    fontSize: 15,
    lineHeight: 22,
  },
});
