import SharedGroupPreferences from "react-native-shared-group-preferences";
import { APP_GROUP_IDENTIFIER } from "../src/constants/appGroupIdentifier";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const saveDataToSharedStorage = async (key, data) => {
  try {
    await SharedGroupPreferences.setItem(key, data, APP_GROUP_IDENTIFIER);
  } catch (errorCode) {
    // errorCode 0 = There is no suite with that name
    console.log("ERROR: cannot save data", errorCode);
  }
};

export const loadDataFromSharedStorage = async (key) => {
  try {
    const loadedData = await SharedGroupPreferences.getItem(
      key,
      APP_GROUP_IDENTIFIER
    );

    return loadedData;
  } catch (errorCode) {
    // errorCode 0 = no group name exists. You probably need to setup your Xcode Project properly.
    // errorCode 1 = there is no value for that key
    console.log("ERROR: cannnot read data", errorCode);
  }
};

export const saveDataToLocalStorage = async (key, data) => {
  try {
    await AsyncStorage.setItem(key, data);
  } catch (errorCode) {
    console.log("ERROR: cannot save data", errorCode);
  }
};

export const loadDataFromLocalStorage = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      // value previously stored
    }
    return value;
  } catch (errorCode) {
    console.log("ERROR: cannnot read data", errorCode);
  }
};

export const removeDataFromLocalStorage = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (errorCode) {
    console.log("ERROR: cannot remove data", errorCode);
  }
};
