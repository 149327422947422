import React, { useState, useContext } from "react";
import {
  Alert,
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Pressable,
  Image,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import AwesomeAlert from "react-native-awesome-alerts";
import { Auth } from "aws-amplify";

import AppContext from "../components/AppContext";
import LeftArrow from "../components/LeftArrow";
import { sharedStyles, villageColors } from "../utils/SharedStyles";
import ColoredButton from "../components/ColoredButton";
import { updateUserData, getUserData } from "../utils/DataFetcher";
import village from "../assets/adaptive-village.png";
import { clog } from "../utils/Log";
import { SignIn } from "../controllers/AuthController";
import { useTheme } from "../theme";

const ForgotPasswordScreen = ({ route, navigation }) => {
  const showMessage = (message) => {
    if (Platform.OS != "web") {
      Alert.alert("", message, [
        {
          text: "OK",
          onPress: () => {
            //clog("OK Pressed")
          },
        },
      ]);
    } else {
      setAlertMessage(message);
      setShowAlert(true);
    }
  };

  async function fetchDataAndMove() {
    try {
      const { username, attributes } = await Auth.currentAuthenticatedUser();
      clog("myContext before data fetch", myContext);
      let { success, data } = await getUserData(username);
      clog("success", success, "data", data);
      if (success) {
        updateUserData(myContext, data, (askForPermission) => {
          console.log("ask for permission", askForPermission);
          clog("my context", myContext);
          if (askForPermission) {
            navigation.replace("Permission", {
              nextScreen: "tab",
            });
          } else {
            navigation.replace("tab");
          }
        });
      } else {
        clog("error, failed to fetch user data");
      }
    } catch (err) {
      console.log("not authenticated", err);
    }
  }

  const checkConditions = () => {
    return (
      myContext.usernameLocal &&
      (myContext.authState != "resetPassword" || myContext.passwordLocal)
    );
  };

  const updateConditions = () => {
    if (checkConditions()) {
      setConditionMet(true);
    } else {
      setConditionMet(false);
    }
  };

  const updateConditionsAndDismissKeyboard = () => {
    updateConditions();
    Keyboard.dismiss;
  };

  async function handleSubmit() {
    let message = "";
    let success = false;
    if (myContext.authState != "resetPassword") {
      if (!username) {
        message = "Must provide username";
      }
      if (message) {
        showMessage(message);
      } else {
        try {
          let data = await Auth.forgotPassword(username);
          myContext.authState = "resetPassword";
          setStage(2);
          success = true;
        } catch (err) {
          if (err.code == "UserNotFoundException") {
            console.log("cannot find user", username);
            message = "Unknown user " + username;
          } else {
            console.log("could not request code for password reset", err);
            message = "could not request code for password reset";
          }
          showMessage(message);
        }
      }
    } else {
      if (!password) {
        message = "Must provide new password";
      }
      if (message) {
        showMessage(message);
      } else {
        try {
          let data = await Auth.forgotPasswordSubmit(username, code, password);
          myContext.authState = "passwordChanged";
          success = true;
          clog("changed password successfully");
        } catch (err) {
          if (err.code === "PasswordResetRequiredException") {
            console.log("the user requires a new password");
            myContext.authState = "requireNewPassword";
            showMessage("Need to change password");
          } else if (err.code === "NotAuthorizedException") {
            console.log("Not authorized");
            showMessage("Incorrect username or code");
          } else if (err.code === "UserNotFoundException") {
            console.log("Not found");
            showMessage("User not found");
          } else {
            console.log("Unknown error");
            showMessage("Unknown error: " + err.code);
          }
        }
        if (success) {
          if (password) {
            // Let us try to login transparently
            clog("Will try to log in transparently");
            await SignIn({
              username: username,
              password: password,
              myContext: myContext,
              callback: ({ success, message, error, user }) => {
                if (success) {
                  setFetching(true);
                  fetchDataAndMove();
                } else {
                  showMessage(message);
                }
              },
            });
          } else {
            clog("Cannot find password. will send to sign in screen");
            navigation.push("SignIn");
          }
        }
      }
    }
  }

  const myContext = useContext(AppContext);
  const { colors } = useTheme();
  const [username, setUsername] = useState(
    myContext?.username == null ? "" : myContext?.username
  );
  const [password, setPassword] = useState(
    myContext?.password == null ? "" : myContext?.password
  );
  const [code, setCode] = useState("");
  const [conditionMet, setConditionMet] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [fetching, setFetching] = useState(false);
  const [stage, setStage] = useState(0);

  clog("NAVIGATION", navigation);
  React.useLayoutEffect(() => {
    let headerTitleValue =
      myContext.authState == "resetPassword"
        ? "Reset your password"
        : "Get confirmation code";
    let showBackButton = navigation.canGoBack();
    clog("Show back button", showBackButton);
    navigation.setOptions({
      headerLeft: () => {
        if (showBackButton) {
          return (
            <Pressable onPress={navigation.goBack}>
              <View style={{ marginLeft: 10 }}>
                <LeftArrow tintColor={colors.primaryText} />
              </View>
            </Pressable>
          );
        } else {
          return <View></View>;
        }
      },
      headerBackTitleVisible: showBackButton,
      headerTitle: headerTitleValue,
    });
  }, [navigation, stage]);

  return (
    <SafeAreaView
      style={[sharedStyles.container, { backgroundColor: colors.background }]}
    >
      <View style={{ flex: 1, width: "100%", maxWidth: 756 }}>
        <KeyboardAwareScrollView
          style={{
            width: "100%",
          }}
        >
          <View style={styles.inputContainer}>
            <Text
              style={[sharedStyles.textLabel, { color: colors.primaryText }]}
            >
              Village username
            </Text>
            <View
              style={[
                sharedStyles.textInputBox,
                {
                  color: colors.primaryText,
                  borderColor: colors.textFieldBorder,
                  height: 48,
                },
              ]}
            >
              <TextInput
                style={[
                  sharedStyles.textInput,
                  {
                    color: colors.primaryText,
                    borderColor: colors.textFieldBorder,
                    height: 48,
                    borderWidth: 0,
                  },
                ]}
                autoCapitalize="none"
                value={username}
                maxLength={200}
                onChangeText={(newValue) => {
                  setUsername(newValue.toLowerCase());
                  myContext["usernameLocal"] = newValue.toLowerCase();
                  updateConditions();
                }}
                onBlur={() => {
                  updateConditionsAndDismissKeyboard();
                }}
              />
            </View>
            {myContext.authState == "resetPassword" && (
              <View>
                <Text
                  style={[
                    sharedStyles.textLabel,
                    { color: colors.primaryText },
                  ]}
                >
                  New password
                </Text>
                <TextInput
                  secureTextEntry={true}
                  style={sharedStyles.textInput}
                  value={password}
                  maxLength={255}
                  onChangeText={(newValue) => setPassword(newValue)}
                  onBlur={Keyboard.dismiss}
                />
              </View>
            )}
            {myContext.authState == "resetPassword" && (
              <View>
                <Text
                  style={[
                    sharedStyles.textLabel,
                    { color: colors.primaryText },
                  ]}
                >
                  Confirmation Code
                </Text>
                <TextInput
                  //secureTextEntry={true}
                  style={sharedStyles.textInput}
                  value={code}
                  maxLength={255}
                  onChangeText={(newValue) => setCode(newValue)}
                  onBlur={Keyboard.dismiss}
                />
              </View>
            )}
            <ColoredButton
              onPress={handleSubmit}
              buttonStyle={{
                alignItems: "center",
                justifyContent: "center",
                marginTop: 31,
                marginLeft: 10,
                marginRight: 10,
                height: 54,
                borderRadius: 16,
                backgroundColor: conditionMet
                  ? colors.primaryButtonBackground
                  : colors.disabledPrimaryButtonBackground,
              }}
              textStyle={{
                textAlign: "center",
                textAlignVertical: "center",
                fontSize: 15,
                fontWeight: "700",
                color: conditionMet
                  ? colors.primaryButtonText
                  : colors.disabledPrimaryButtonText,
              }}
              text={"Request"}
            ></ColoredButton>
            <TouchableOpacity
              style={{ alignItems: "center", marginTop: 8 }}
              onPress={() => {
                navigation.push("SignUp");
              }}
            >
              <Text style={{ fontSize: 14, color: colors.primaryText }}>
                {"Don't have an account? sign up"}
              </Text>
            </TouchableOpacity>
          </View>
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title=""
            message={alertMessage}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            cancelText="Ok"
            cancelButtonColor={villageColors.CancelButton}
            onCancelPressed={() => {
              setShowAlert(false);
            }}
          />
        </KeyboardAwareScrollView>
      </View>
    </SafeAreaView>
  );
};

export default ForgotPasswordScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  inputContainer: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  textLabel: {
    fontSize: 16,
    color: "white",
    marginBottom: 8,
    marginTop: 8,
  },
  textInput: {
    height: 59,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: villageColors.InputBackground,
    borderRadius: 16,
  },
});
